import React from 'react'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import List from '@mui/material/List'
import useState from "react-usestateref";

const withMaterialDnD = (Component) => (props) => {
    const [dragOngoing, setDragOngoing] = useState(false)
    if (props.useDnD) {
        return (
            <>
                <DragDropContext
                    onDragStart={() => setDragOngoing(true)}
                    onDragEnd={(result) => {
                        props.handleOnDragEnd(result)
                        setDragOngoing(false)
                    }}
                >
                    <List
                        style={props.listStyle}
                        sx={{paddingTop: 0, paddingBottom: 0}}
                    >
                        <Droppable droppableId={props.droppableId}>
                            {(provided) => {
                                return (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {props.list.map((listProps, index) => {
                                            let draggable = props.dragCondition(
                                                listProps,
                                                index
                                            )
                                            return (
                                                <Draggable
                                                    key={listProps.key}
                                                    draggableId={listProps.id}
                                                    index={index}
                                                    isDragDisabled={draggable}
                                                >
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <div
                                                                key={listProps.key}
                                                                onContextMenu={(
                                                                    e
                                                                ) =>
                                                                    props.selectItem(
                                                                        e,
                                                                        {
                                                                            list: listProps,
                                                                            key: listProps.key,
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                <Component
                                                                    {...{
                                                                        dynamicIndex: index,
                                                                        ...listProps,
                                                                        ...props.dynamicProps,
                                                                    }}
                                                                />
                                                                {props?.bottomComponent && (index === props.list.length - 1) && !dragOngoing ? props.bottomComponent : null}
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )
                            }}
                        </Droppable>
                    </List>
                </DragDropContext>

            </>
        )
    } else {
        let selectItem = props.hasOwnProperty('selectItem')
            ? props.selectItem
            : () => {
            }
        return (
            <List style={props.listStyle}>
                {props.list.map((listProps, index) => {
                    return (
                        <div
                            key={listProps.key + props.droppableId + index}
                            onContextMenu={(e) =>
                                selectItem(e, {
                                    list: listProps,
                                    key: listProps.key,
                                })
                            }
                        >
                            <Component
                                key={listProps.key}
                                {...{dynamicIndex: index, ...listProps, ...props.dynamicProps}}
                            />
                        </div>
                    )
                })}
                {props.mockPadding ? props.mockPadding : null}
                {props?.bottomComponent ? props.bottomComponent : null}
            </List>
        )
    }
}

export default withMaterialDnD
