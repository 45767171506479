import {createCachedSlice} from "../cache/createCachedSlice.js";

const videoState = createCachedSlice({
  name: 'videoCall',
  initialState: {
    sourceId: "",
    sessionId: "",
    invites: [],
    lastRecievedInvite: "",
    recievedInvite: false,
    joinToken: "",
    maxSize: 0,
    callStarter: false,
    callPerms: {
      usesInviteTokens: false,
      callRole: "",
      canViewInvites: false,
      canCreateInvitesWithPerms: {result: false, callRole: ""},
    },
    videoDevices: [],
    currentVideoDevice: "",
    audioDevices: [],
    currentAudioDevice: ""
  },
  reducers: {

    startCall: {
      cached: false,
      parameters: ["sourceId", "sessionId", "invites", "joinToken", "callPerms", "canViewInvites", "canCreateInvitesWithPerms", "canDestroyInvitesWithPerms"],
      description: "",
      reduce: (state, action) => {
        return {
          ...state,
          sourceId: action.payload.sourceId,
          sessionId: action.payload.sessionId,
          invites: action.payload.invites,
          joinToken: action.payload.joinToken,
          maxSize: action.payload.maxSize,
          callPerms: action.payload.callPerms,
          callStarter: !state.callStarter
        };
      }
    },

    recieveCreatedInvite: {
      cached: false,
      parameters: ["invite"],
      description: "",
      reduce: (state, action) => {
        return {
          ...state,
          lastRecievedInvite: action.payload.invite,
          recievedInvite: !state.recievedInvite
        };
      }
    },

    recieveInvites: {
      cached: false,
      parameters: ["invites"],
      description: "",
      reduce: (state, action) => {
        return {
          ...state,
          invites: action.payload.invites
        };
      }
    },

    resetToDefaultState: {
      cached: false,
      parameters: [],
      description: "",
      reduce: (state, action) => {
        return {
          ...state,
          sourceId: "",
          sessionId: "",
          invites: [],
          lastRecievedInvite: "",
          recievedInvite: false,
          joinToken: "",
          maxSize: 0,
          callStarter: false,
          callPerms: {
            usesInviteTokens: false,
            callRole: "",
            canViewInvites: false,
            canCreateInvitesWithPerms: {result: false, callRole: ""},
          },
          devices: [],
          currentDevice: ""
        }
      }
    },

    addVideoDevices: {
      cached: false,
      parameters: ["devices"],
      description: "",
      reduce: (state, action) => {
        return {
          ...state,
          videoDevices: action.payload.devices
        }
      }
    },

    setVideoDevice: {
      cached: false,
      parameters: ["device"],
      description: "",
      reduce: (state, action) => {
        return {
          ...state,
          currentVideoDevice: action.payload.device
        }
      }
    },

    addAudioDevices: {
      cached: false,
      parameters: ["devices"],
      description: "",
      reduce: (state, action) => {
        return {
          ...state,
          audioDevices: action.payload.devices
        }
      }
    },

    setAudioDevice: {
      cached: false,
      parameters: ["device"],
      description: "",
      reduce: (state, action) => {
        return {
          ...state,
          currentAudioDevice: action.payload.device
        }
      }
    }
  }
})

export const videoActions = videoState.actions
export const videoCalls = videoState.reducer
export const videoSlice = videoState
