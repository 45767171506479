import React from 'react'
import { ButtonCustom, StyledDiv } from '../../../Styled'
import ProductCard from '../../../../_reusable/Cards/ProductCard/ProductCard'

const CustomerButtons = function (props) {
    return (
        <div>
            <ButtonCustom types={props.types} onClick={props.subscriptions}>
                Manage Transactions
            </ButtonCustom>
        </div>
    )
}

const CustomerItem = function (props) {
    // #
    return (
        <StyledDiv types={['catalogItem']}>
            <ProductCard {...props.product} />

            <StyledDiv types={['catalogItemButtons']}>
                <ButtonCustom
                    id={props.product.id}
                    types={['square']}
                    onClick={props.onClick}
                >
                    Buy
                </ButtonCustom>

                <ButtonCustom
                    id={props.product.id}
                    types={['square']}
                    onClick={props.reportProduct}
                >
                    Report this product
                </ButtonCustom>
            </StyledDiv>
        </StyledDiv>
    )
}

export { CustomerButtons, CustomerItem }
