import { useEffect, useState, useRef } from 'react'
import { Box, useTheme } from '@mui/material'
import { BadgeCustom, ButtonCustom } from '../../../Styled'
import { dispatchMappers } from '../../../../../redux/process/actionProcess'
import { statePropMapper } from '../../../../../redux/process/selectorProcess'
import { RA1, RS1 } from '../../../../../redux/actions'
import { SA1 } from '../../../../../hs/requestGlobals.mjs'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ListItemCustom, ListItemTextCustom } from '../../../Styled'
import { withAS } from '../../../../_contexts/withAppState'

const mapDispatchToProps = dispatchMappers([
    RA1._appState.changePage,
    SA1.watchThread,
])

const makeMapStateToProps = statePropMapper([
])

const ThreadLink = (props) => {
    const history = useHistory()
    // TODO: Update thread date when new thread date comes in.
    // useEffect(() => {
    //     if (props.notis[props.threadId]?.hasOwnProperty('lastReplyDate')) {
    //         props.dispatch.server.watchThread(
    //             props.serverId,
    //             props.channelId,
    //             props.threadTitle,
    //             props.notis[props.threadId].lastReplyDate,
    //             props.threadReplies,
    //             props.threadId)
    //     }
    // }, [props.notis])

    return (
        <ListItemCustom
            style={{
                marginTop: '10px',
                textOverflow: 'ellipses',
            }}
            onClick={() => {
                props.AS.nav.ops.changeToThread(props.serverId,props.channelId,props.threadId)

            }}>
            <ListItemTextCustom
                id={props.threadId}
                primary={
                    <BadgeCustom
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                        badgeContent={props?.notis[props.threadId]?.noti}
                        color="primary">
                        &nbsp;&nbsp;
                        <div>
                            {props.threadTitle}
                        </div>
                    </BadgeCustom>

                }
                secondary={
                    <div>
                        &nbsp;&nbsp;
                        {props.notis[props.threadId]?.hasOwnProperty('lastReplyDate') ?
                            props.notis[props.threadId].lastReplyDate :
                            props.threadDate}
                    </div>
                } />
        </ListItemCustom >
    )
}

// export default connect(null, null)
export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(ThreadLink))