import React from 'react'
import {connect} from 'react-redux'
import {ListItemCustom, ListItemTextCustom} from '../../../Styled'
import {withAS} from "../../../../_contexts/withAppState";
import {dispatchMappers} from "../../../../../redux/process/actionProcess";
import {RA1, RS1} from "../../../../../redux/actions";
import {SA1} from "../../../../../hs/requestGlobals.mjs";
import {statePropMapper} from "../../../../../redux/process/selectorProcess";

const mapDispatchToProps = dispatchMappers([RA1.notifications.clearNotifications])
const makeMapStateToProps = statePropMapper([])
import EmailIcon from '@mui/icons-material/Email';

const NotificationLink = function (props) {
    switch (props.type) {
        case "dm":
            const handleClick = () => {
                props.AS.nav.ops.setPage('dm')
                props.AS.nav.ops.setDMData({
                    otherUserId: props.otherUserId,
                    channel: props.channel,
                    profileCheck: false
                })
                props.dispatch.notifications.clearNotifications(props.channel)
            }
            return (
                <ListItemCustom
                    // types={["channel", "selectable"]}
                    types={['channelActive']}
                    key={props.channel}
                    button
                    listitemid={props.channel}
                    activeid={props.AS.nav.state?.dmData?.channel}
                    onClick={handleClick}
                >
                    <EmailIcon/>
                    <ListItemTextCustom
                        id={"NOTIF" + props.dynamicIndex}
                        primary={props.otherUserData.userName}
                        secondary={"(Unread Messages: " + props.num + ")"}
                    />
                </ListItemCustom>
                //types={[channelType]}
            )
        case "creatorPaidAppointmentBooked":
            return <div>creatorPaidAppointmentBooked</div>
        case "clientPaidAppointmentBooked":
            return <div>clientPaidAppointmentBooked</div>
        default:
            console.log(props)
            return null
    }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(NotificationLink))
