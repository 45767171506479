import Grid from "@mui/material/Grid";
import { ButtonCustom, StyledDiv, GridCustom, TypographyCustom, vDH } from "../../Styled";
import { BottomLeftHover } from "../../../_design/BottomHover";
import React from "react";
import { useTheme } from "@emotion/react";

const InfoModal = (props) => {
    const theme = useTheme()
    return (<>
        {/*<div style={{*/}
        {/*    position: "fixed",*/}
        {/*    backgroundColor: "white",*/}
        {/*    height: "100vh",*/}
        {/*    width: "100vw",*/}
        {/*    opacity: ".5",*/}
        {/*    backdropFilter: "blur(20px)",*/}
        {/*    zIndex: "40000",*/}
        {/*    top: "0px",*/}
        {/*    left: "0px"*/}
        {/*}}/>*/}
        <StyledDiv types={['_FVH']} style={{
            position: "fixed",
            width: (props?.modalWidth) ? props.modalWidth : "34vw",
            zIndex: "50000",
            top: "0px",
            left: "0px"
        }}>
            <Grid container spacing={0}>
                <Grid item xs={2} style={{ height: vDH(100), backgroundColor: "transparent" }} />
                <Grid types={['infoModal']} item xs={1} style={{ background: theme.palette.background.default }} />
                <Grid types={['infoModal']} item xs={8} style={{ background: theme.palette.background.default }}>
                    <StyledDiv
                    // types={['infoModal']}
                    // style={{ width: '80vw' }}
                    >

                        <div style={{
                            height: (props.hideIcon) ? vDH(5) : vDH(10)
                        }} />

                        {(!props.hideIcon) ?
                            <div style={{ position: "relative", height: vDH(10), "paddingLeft": "20px" }}>
                                {/*<div>(Temporary animation?)</div>*/}
                                {/*<div>*/}
                                <img style={{
                                    filter: "invert(1)",
                                    maxWidth: "100%",
                                    maxHeight: "100%"
                                }} src="https://i.ibb.co/wW1n6B1/templogo1.png" alt="PAA" border="0" />

                                {/*</div>*/}
                            </div> : <div></div>}

                        <div style={{
                            height: vDH(5)
                        }} />
                        <TypographyCustom
                            types={['communityHeader']}
                            noWrap={true}
                            style={(props.subtitle) ? { textAlign: 'center', width: '90%' } : {}}
                            variant={(props.subtitle) ? "h3" : "h4"}>
                            {props.title}
                        </TypographyCustom>

                        {(props.subtitle) ?
                            <TypographyCustom
                                types={['communityHeader']}
                                noWrap={true}
                                style={{ marginTop: '1em', textAlign: 'center', width: '90%' }}
                                variant="h5">
                                &emsp; {props.subtitle}
                            </TypographyCustom>
                            : <div></div>}

                        <div style={{ height: vDH(5) }}></div>
                        <div style={{ height: vDH(70), "paddingLeft": "20px" }}>
                            {props.children}
                        </div>
                        {/*<BottomLeftHover>*/}
                        {/*    <ButtonCustom onClick={() => setFirstTime(false)}>Close</ButtonCustom>*/}
                        {/*</BottomLeftHover>*/}
                    </StyledDiv>
                </Grid>
                <Grid types={['infoModal']} item xs={1} style={{ background: theme.palette.background.default }} />
            </Grid>
        </StyledDiv >
    </>)
}

export default InfoModal