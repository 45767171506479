import React, {useEffect, useState} from 'react'
import AuthContext from './context.js'
import {signInWithEmailAndPassword, getAuth, signOut, signInWithEmailLink, isSignInWithEmailLink} from 'firebase/auth'
import {connect} from 'react-redux'
import {initializeStore} from '../../../redux/actions'

const defaultAuth = {
    init: false,
    auth: false,
    displayName: null,
    email: null,
    emailVerified: null,
    photoURL: null,
    uuid: null,
    phoneNumber: null,
    providerData: null,
    token: null,
    logout: function () {
    },
    login: function () {
    },
    sendMessage: function () {
    },
    refreshToken: null,
}

let listener
const AuthProviderPre = (props) => {
    const [firebase, setFirebase] = useState(props.firebase)
    const [auth, setAuth] = useState(defaultAuth)
    const [authData, setAuthData] = useState(defaultAuth)
    const logout = (callback) => {
        signOut(firebase.auth)
            .then(() => callback(null))
            .catch((error) => callback(error))
        setAuth(defaultAuth)
        setTimeout(() => {
            console.log("blah")
        }, 300)
    }
    const login = (email, pass, callback, errorCallback) => {
        signInWithEmailAndPassword(firebase.auth, email, pass)
            .then((x) => {
                callback(null)
            })
            .catch(function (error) {
                errorCallback(error)
            })
    }
    const loginWithEmailLink = () => {
        const auth = getAuth();
        let email = window.localStorage.getItem('emailForSignIn');
        if (isSignInWithEmailLink(firebase.auth, window.location.href)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt('Please provide your email for confirmation');
            }
            // The client SDK will parse the code from the link for you.
            signInWithEmailLink(firebase.auth, email, window.location.href)
                .then((result) => {
                    // Clear email from storage.
                    window.localStorage.removeItem('emailForSignIn');
                    // You can access the new user via result.user
                    // Additional user info profile not available via:
                    // result.additionalUserInfo.profile == null
                    // You can check if the user is new or existing:
                    // result.additionalUserInfo.isNewUser
                })
                .catch((error) => {
                    // Some error occurred, you can inspect the code: error.code
                    // Common errors could be invalid email and invalid or expired OTPs.
                });
        }
    }
    const initAuthState = (user, accessToken) => {
        setAuth({
            auth: true,
            init: true,
            displayName: user.displayName,
            email: user.email,
            emailVerified: user.emailVerified,
            photoURL: user.photoURL,
            uuid: user.uid,
            phoneNumber: user.phoneNumber,
            providerData: user.providerData,
            token: accessToken,
        })
        setAuthData({
            uuid: user.uid,
            token: accessToken,
        })
        props.initializeStore({id: user.uid, token: accessToken})
    }
    const mountEffect = () => {
        listener = firebase.auth.onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken().then((accessToken) => {
                        initAuthState(user, accessToken)
                    }
                )
            } else {
                setAuth({
                    init: true,
                    auth: false,
                })
            }
        })
    }
    useEffect(mountEffect, [])
    // componentWillUnmount()
    // {
    //     this.listener();
    // }
    return (
        <AuthContext.Provider
            value={{
                auth,
                firebase,
                authFunctions: {
                    logout: (cbFunction) => logout(cbFunction),
                    login: (...args) => login(...args),
                    loginWithEmailLink: loginWithEmailLink
                },
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

const AuthProvider = connect(null, {initializeStore})(AuthProviderPre)
const withAuth = (Component) => (props) =>
    (
        <AuthContext.Consumer>
            {(value) => (
                <Component
                    auth={value.auth}
                    authFunctions={value.authFunctions}
                    firebase={value.firebase}
                    isAuthorized={value.auth.auth}
                    authData={{id: value.auth.uuid, token: value.auth.token}}
                    {...props}
                />
            )}
        </AuthContext.Consumer>
    )

export {withAuth, AuthProvider}
