export const testData = () => {

    // props.serverMeta.name
    // id = props.serverMeta.id
    // props._activeServer

    let serverMeta = {
        "dm": false,
        "stripeEnabled": true,
        "serverMeta": {
            "dm": false
        },
        "name": "uiserv",
        "id": "591616397794440192",
        "ownerId": "591616193875768320",
        "version": 10,
        "users": [
            {
                "id": "591616193875768320",
                "primaryRole": "591616397794440199",
                "roles": [
                    "591616397794440199"
                ]
            }
        ],
        "hiddenId": "591616397794440197",
        "avatarId": "https://paa-fiilestorage.s3.us-east-2.amazonaws.com/attachments/591616397794440192/592929892411357184/emoji.jpeg",
        "logId": "591616397794440193",
        "emoji": [],
        "generalId": "591616397794440196",
        "profileId": "591616397794440198",
        "notificationsId": "591616397794440195",
        "configId": "591616397794440194",
        "roles": [
            "591616397794440199",
            "591616397794440200",
            "591616397794440201",
            "592899875417602048",
            "591616397794440202"
        ],
        "channels": [
            "591616397794440193",
            "591616397794440194",
            "591616397794440195",
            "591616397794440196",
            "591616397794440197",
            "591616397794440198"
        ],
        "profile": "",
        "activeChannelInServer": "591616397794440196",
        "userMetas": [
            "591616193875768320_591616397794440192"
        ]
    }
    let name = serverMeta.name
    let id = serverMeta.id
    let avatar = serverMeta.avatarId

    let users = serverMeta.users.length
    let threads = 0  // TODO: implement
    let availabilities = 6 // Howto?
    let owner = 'biahote'

    // let ownerRoleId = serverMeta?.users?.find(u => u.id === serverMeta?.ownerId)?.primaryRole
    // let ownerColor = serverAbstractRoles[ownerRoleId]?.perms?.color
    let ownerColor = "#FF0000"

    // owner: serverMeta.ownerId 591616193875768320 -> 
    // serverMeta.users[serverMeta.ownerId].primaryRole -> get role color
    // somehow need to get user info
    //      RS1.getUser('user')?
    // somehow need to get user role color
    // somehow need to get # of threads
    //      RS1.getThreads('threads'),
    // somehow need to get # of availabilities
    //      RS1.getCalendar('calendar')


    let PROPS = {
        "dynamicIndex": 0,
        "id": "591616397794440192",
        "server": "591616397794440192",
        "isDM": false,
        "activeServer": "591616397794440192",
        "_activeServer": "591616397794440192",
        "_activeChannel": "591616397794440196",
        "_loaded": true,
        "_appState": {
            "alerts": 0,
            "recentServers": [
                "591616397794440192"
            ],
            "modalType": "none",
            "updateId": 3,
            "modalMenu": "none",
            "openMenu": "chat",
            "modalMenuOpen": false,
            "pageChangeBlocked": false,
            "blockedModal": "none",
            "roleStruct": {},
            "catalog": [
                {
                    "id": "592437850657836032",
                    "name": "Test Appt.",
                    "desc": "This is a test appt.",
                    "icon": "aa",
                    "type": "calendar",
                    "price": "1519"
                },
                {
                    "id": "592899875497293824",
                    "productMeta": {
                        "metaString": "Subbed",
                        "roleId": "592899875417602048"
                    },
                    "name": "Subscribe!",
                    "desc": "sub 4 accela",
                    "icon": "aa",
                    "type": "subscription",
                    "price": "499"
                }
            ]
        },
        "_userId": "591616193875768320",
        "dispatch": {
            "other": {},
            "_appState": {}
        }
    }

    return { name, id, avatar, users, threads, availabilities, owner, ownerColor, serverMeta, ...PROPS }
}