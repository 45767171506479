import React from 'react'
import { ButtonCustom } from '../../../../../Chat/Styled'
import { StyledDiv } from '../../../../../Chat/Styled'
import { dispatchMappers } from '../../../../../../redux/process/actionProcess'
import { RS1, RA1 } from '../../../../../../redux/actions'
import { connect } from 'react-redux'
import { statePropMapper } from '../../../../../../redux/process/selectorProcess'
import { embedProps, useTestData } from '../../util'
import { testData } from './test'
import { withAS } from '../../../../../_contexts/withAppState'

const makeMapStateToProps = statePropMapper([
    RS1.store('_activeChannel', '_activeChannel'),
    RS1.getServerAbstractRoles('serverAbstractRoles'),
    RS1.getUser('ownerData'),
    // RS1.getThreads('threads'),   // TODO: IMPLEMENT threads section of profile
    // RS1.getCalendar('calendar')  // TODO: IMPLEMENT - need to see calendar without it in state..
])

const computedTestData = testData()

const CommunityDataLayer = (props) => {

    // #
    let data = {
        id: props?.serverMeta?.id,
        name: props?.serverMeta?.name,
        avatar: '',

        users: props?.serverMeta?.users?.length,
        threads: 0, // TODO: IMPLEMENT
        availabilities: 0,

        owner: props.ownerData.userName,
        ownerColor: '',

        serverMeta: props?.serverMeta,
        activeServer: props.AS.activeServer,
        isDM: props?.isDM,

        setMenu: props?.setMenu,
        setPage: props.AS.nav.ops.setPage,
        changeActiveServer: props.AS.nav.ops.changeActiveServer,
    }

    // Getting avatar
    data.avatar = (props?.serverMeta?.avatarId && props?.server?.avatarId !== 'none') ?
        props.serverMeta.avatarId :
        `https://picsum.photos/seed/${data?.id}/800/800?blur=3`

    // getting community owner's role color
    let ownerRoleId = props?.serverMeta?.users?.find(u => u.id === props?.serverMeta?.ownerId)?.primaryRole
    data.ownerColor = props?.serverAbstractRoles.find(r => r.id === ownerRoleId)?.perms?.color

    data = useTestData(props, data, computedTestData)

    return embedProps(props, data)
}

export default connect(makeMapStateToProps, null)(withAS(CommunityDataLayer))
