//All of these should be put in redux eventually.

const { useEffect } = require('react')
const { useRef } = require('react')

export const hasModerator = function (userRoles, roleDict, size) {}

export function usePrevious(value) {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}

export function detectChanges(blah) {}

export const topRoleRank = function (userRoles, roleDict, size) {
    let topRank = size + 10
    let j = 0
    for (j = 0; j < userRoles.length; j++) {
        topRank = Math.min(roleDict[userRoles[j]].rank, topRank)
    }
    return topRank
}

export const checkPartialEquality = function (arr1, arr2, index) {
    let partialEquality = true
    let j = 0
    const bound = index + 1
    for (j = 0; j < bound; j++) {
        if (arr1[j] !== arr2[j]) {
            partialEquality = false
        }
    }
    return partialEquality
}
