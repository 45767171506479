export const ChatMessageStyleOverrides = (mode, palette) => {
    return {
        flexboxInnerAvi: (props) => ({
            flex: '1 1 0 auto',
            display: 'flex',
            '& > *': {
                margin: props.theme.spacing(1),
            },
        }),
        singleLineDiv: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '10px',
        },
        //======================//
        // FLEXBOX CHAT DISPLAY //
        //======================//
        flexboxOuterDiv: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            paddingLeft: '10px',
        },
        flexboxInnerColumns: {
            flex: '1 1 auto',
            justifyContent: 'flex-start',
            padding: '10px',

            display: 'flex column',
        },
        flexboxColumnText: {
            flex: '1 1 100%',
            wordBreak: 'break-word',
        },
        flexboxColumnHeader: {
            flex: '1 1 auto',
            // color: palette.text.secondary,
            color: palette.primary.main,
        },
        flexboxColumnImg: { flex: '1 1 0 auto' },
    }
}
