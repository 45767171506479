export const getThreadPropertiesFn = (threadsArray) => () => {
    let threadImgs = []
    for (let thread of threadsArray) {
        threadImgs.push({
            id: thread._id,
            pic: thread.files[0],
            text: thread?.threadTitle,
            uId: thread?.uId,
            date: new Date(thread?.createdAt)
                .toLocaleDateString('en-EN', {
                    day: '2-digit',
                    year: 'numeric',
                    month: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit'
                }),
            replies: thread.metaData?.threadReplies,
        })
    }

    return {threadImgs}
}