import { selectorMeta } from '../constants/selectorMeta.js'

export const statePropMappers = (
    memoizedSelectorObject,
    baseSelectorObject
) => {
    let memoizedSelectors = {}
    for (const key in memoizedSelectorObject) {
        if (memoizedSelectorObject.hasOwnProperty(key)) {
            //DO THIS BETTER.
            let cleanKey = key.replace('$', '')
            let cleanerKey = cleanKey.replace('$', '')
            let cleanestKey = cleanerKey.replace('$', '')
            memoizedSelectors[key] = selectorMeta[cleanestKey].operation(
                ...memoizedSelectorObject[key][1]
            )
        }
    }
    const returnFunction = () => {
        const mapStateToProps = (state, props) => {
            let stateToProps = {}
            for (const key2 in memoizedSelectors) {
                if (memoizedSelectors.hasOwnProperty(key2)) {
                    stateToProps[memoizedSelectorObject[key2][0]] =
                        memoizedSelectors[key2](state, props)
                }
            }
            for (const key3 in baseSelectorObject) {
                if (baseSelectorObject.hasOwnProperty(key3)) {
                    stateToProps[baseSelectorObject[key3]] = state[key3]
                }
            }
            return stateToProps
        }
        return mapStateToProps
    }
    return returnFunction
}

export const statePropMapper = (memoizedSelectorArray) => {
    let memoizedSelectors = []
    let storeKeys = []
    memoizedSelectorArray.map((selectorData) => {
        if (selectorData.isStore) {
            storeKeys.push(selectorData)
        } else {
            if (selectorData.argList === undefined) {
                memoizedSelectors.push({
                    ...selectorData,
                    operation: selectorMeta[selectorData.key].operation(),
                })
            } else {
                memoizedSelectors.push({
                    ...selectorData,
                    operation: selectorMeta[selectorData.key].operation(
                        ...selectorData.argList
                    ),
                })
            }
        }
    })

    const returnFunction = () => {
        const mapStateToProps = (state, props) => {
            let stateToProps = {}
            memoizedSelectors.map((selectorData) => {
                stateToProps[selectorData.propName] = selectorData.operation(
                    state,
                    props
                )
            })
            storeKeys.map((selectorData) => {
                stateToProps[selectorData.propName] = state[selectorData.key]
            })
            return stateToProps
        }
        return mapStateToProps
    }
    return returnFunction
}

export const selectorsForTesting = (memoizedSelectorArray) => {
    let memoizedSelectors = []
    let storeKeys = []
    memoizedSelectorArray.map((selectorData) => {
        if (selectorData.isStore) {
            storeKeys.push(selectorData)
        } else {
            if (selectorData.argList === undefined) {
                memoizedSelectors.push({
                    ...selectorData,
                    operation: selectorMeta[selectorData.key].operation(),
                })
            } else {
                memoizedSelectors.push({
                    ...selectorData,
                    operation: selectorMeta[selectorData.key].operation(
                        ...selectorData.argList
                    ),
                })
            }
        }
    })
    const returnFunction = (state, props) => {
        let stateToProps = {}
        memoizedSelectors.map((selectorData) => {
            stateToProps[selectorData.propName] = selectorData.operation(
                state,
                props
            )
        })
        storeKeys.map((selectorData) => {
            stateToProps[selectorData.propName] = state[selectorData.key]
        })
        return stateToProps
    }
    return returnFunction
}
