import React from 'react'
import { connect } from 'react-redux'
import { statePropMapper } from '../../../../../redux/process/selectorProcess.js'
import { dispatchMappers } from '../../../../../redux/process/actionProcess.js'
import { RA1, RS1 } from '../../../../../redux/actions.js'
import { ListItemCustom } from '../../../Styled'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import PeopleIcon from '@mui/icons-material/People'
import ListItemText from '@mui/material/ListItemText'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import ShieldIcon from '@mui/icons-material/Shield'
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined'
import ConstructionIcon from '@mui/icons-material/Construction'
import SubscriptionsIcon from '@mui/icons-material/Subscriptions'
import {getUserColors} from "../../../../../hooks";

const mapDispatchToProps = dispatchMappers([RA1._appState.openModalMenu])
const makeMapStateToProps = statePropMapper([
    RS1.getServer('serverData'),
    RS1.store('_appState', '_appState'),
])

const ManagementBarIcon = (props) => {
    switch (props.type) {
        case 'owner':
            return <AdminPanelSettingsIcon />
        case 'admin':
            return <ShieldIcon />
        case 'moderator':
            return <ShieldOutlinedIcon />
        case 'roleManagement':
            return <ConstructionIcon />
        case 'subscriber':
            return <SubscriptionsIcon />
        default:
            return <PeopleIcon />
    }
}

const ManagementBarLink = (props) => {
    const roleData = props.roleDict[props.linkLabel].perms
    const roleColor = props.roleDict[props.linkLabel].perms.color
    let type
    if (roleData.isSubscriberRole) {
        type = 'subscriber'
    }
    if (roleData.canManageRoles) {
        type = 'roleManagement'
    }
    if (roleData.isModerator) {
        type = 'moderator'
    }
    if (roleData.isAdmin) {
        type = 'admin'
    }
    if (roleData.isOwner) {
        type = 'owner'
    }
    const handleClick = () => {
        if (props.isRoleLink) {
            props.changeActivePage('roles')
            props.changeActiveRole(props.server, props.linkLabel)
        } else {
            props.changeActivePage(props.server, props.linkLabel)
        }
    }
    let color = getUserColors(roleColor)
    return (
        <ListItem key={props.channel} button onClick={handleClick}>
            <ManagementBarIcon type={type} />
            &nbsp; &nbsp;
            <ListItemText
                id={'MBL' + props.linkLabel}
                style={{ color: color}}
                primary={props.name}
            />
        </ListItem>
    )
}

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(ManagementBarLink)
