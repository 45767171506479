import React from 'react'
import Container from '@mui/material/Container'
import { makeStyles } from '@mui/styles'
import './landing.css'
import HeaderButtons from './Header/HeaderButtons'
import './Header/header.css'
import Ribbon from './Ribbon/index.js'
import { FirstRow, SecondRow, LastRow } from './FlexBox'
import TextGradient from './TextGradient'
import './FlexBox/flexbox.css'
import {homePageText} from "./homePageText";

// TODO:
//  get ribbons to work on firefox ( convert svg to base64 or to image..? ;-; )
//  consider purple theme for mobile
//  make mobile actualy go to full viewport... ughhh...
//  sleep so you can remember more stuff you need to do

const HomePage = function (props) {
    // const ribbonz = [
    //     { color: 'fb7', offset: 0.6, animation: 'move-forever-1' },
    //     { color: '206', offset: 0.7, animation: 'move-forever-1' },
    //     { color: 'c5a', offset: 0.6, animation: 'move-forever-2' },
    //     { color: '018', offset: 0.7, animation: 'move-forever-2' },
    // ]
    //
    // function generateRibbons(ribbonData) {
    //     let numRibbons = ribbonData.length
    //     let ribbonProps = { numRibbons: numRibbons }
    //     ribbonProps.ribbons = new Array(numRibbons).fill({})
    //
    //     for (let i = 0; i < numRibbons; i++) {
    //         let currentRibbon = {}
    //         currentRibbon['colors'] = [{}, {}, {}]
    //         currentRibbon.colors[0] = { value: '#0000', offset: 0 }
    //         currentRibbon.colors[1] = {
    //             value: '#0000',
    //             offset: ribbonData[i].offset,
    //         }
    //         currentRibbon.colors[2] = {
    //             value: '#' + ribbonData[i].color,
    //             offset: 1,
    //         }
    //         ribbonProps.ribbons[i] = currentRibbon
    //
    //         ribbonProps.ribbons[i].animation = {
    //             name: ribbonData[i].animation,
    //             delay: ribbonData[i].delay,
    //         }
    //     }
    //
    //     ribbonProps.numRibbons = 4
    //
    //     return ribbonProps
    // }

    return (
        <div className="landing-page test-box">
            <div className="header text-box">
                <Container disableGutters maxWidth={false}>
                    <div className="header-left">
                        {/*<div class="header-logo">*/}
                        {/*    /!* <img src="https://i.ibb.co/FnZw1t2/ingroupe.png" /> *!/*/}
                        {/*</div>*/}
                        <div className="header-text">{homePageText.name}</div>
                    </div>
                    <HeaderButtons />
                </Container>
            </div>

            <div className="header-gradient text-box"></div>
            <TextGradient />

            <div className="flexbox">
                <FirstRow />
                <SecondRow />
                <LastRow />
            </div>
        </div>
    )
}

export default HomePage
