import React, {useEffect, useState} from 'react'
import {RS1} from '../../../redux/actions.js'
import {connect} from 'react-redux'
import {API as APIHandler} from '../../../hs/api.js'
import 'react-contexify/dist/ReactContexify.css'
import {statePropMapper} from '../../../redux/process/selectorProcess.js'
import {withAuth} from '../../_contexts/withAuth/index.js'

const makeMapStateToProps = statePropMapper([
    RS1.store('apiResponses', 'apiResponses'),
])

const withAPI = function (WrappedComponent, apiId) {
    let Wrapper = function (props) {
        const [version, setVersion] = useState(0)
        const fieldChangeCreator = (dataChange, data) => (id) => (e) => {
            let newFormState = {...data}
            if (e?._isAMomentObject) {
                newFormState[id] = e
            } else {
                newFormState[id] = e.target.value
            }
            dataChange(newFormState)
        }
        const API = (handle) =>
            async function (...args) {
                let req =
                    APIHandler?.[handle] !== undefined
                        ? APIHandler[handle](apiId)(props.authData)(...args)
                        : console.error('Handle ' + handle + ' is not defined.')
                return req
            }
        const reset = () => {
            setVersion((version) => version + 1)
        }
        const responseHandler = function (
            response,
            callbackFunction,
            errorFunction
        ) {
            if (response.actionType !== 'client/error') {
                callbackFunction(response)
            } else {
                errorFunction(response)
            }
        }
        const errorData = (field) => (key) => {
            if (props.apiResponses?.[apiId]?.[key]?.type === 'client/error') {
                return props.apiResponses[apiId][key]['payload']
            }
            if (props.apiResponses?.[apiId]?.[key]?.type === 'validation') {
                let err = ''
                props.apiResponses[apiId][key].errs.map((valError) => {
                    if (valError.fields.includes(field)) {
                        err = valError.err
                    }
                })
                return err
            }
            return null
        }
        //The following is used for checking for a success follow-up for socket requests.
        const successStatus = (key) => {
            //#TODO: API Methods should be reworked so that they use something other than the actionType...
            let oldCondition =
                props.apiResponses?.[apiId]?.[key]?.actionType ===
                'client/success'
            let newCondition =
                props.apiResponses?.[apiId]?.[key]?.operationStatus ===
                'success'
            return oldCondition || newCondition
        }
        //The following is used for checking for a success follow-up for socket requests.
        const submittedStatus = (key) => {
            return (
                props.apiResponses?.[apiId]?.[key]?.actionType ===
                'client/submitted'
            )
        }
        const errorStatus = (field) => (key) => {
            if (props.apiResponses?.[apiId]?.[key]?.actionType === 'client/error') {
                if (field === "none") {
                    return true
                }
            }
            if (props.apiResponses?.[apiId]?.[key]?.type === 'client/error') {
                return true
            }
            if (props.apiResponses?.[apiId]?.[key]?.type === 'validation') {
                let errBool = false
                props.apiResponses[apiId][key]?.errs.map((valError) => {
                    if (valError.fields.includes(field)) {
                        errBool = true
                    }
                })
                return errBool
            }
            return false
        }

        const {apiResponses, dispatch, ...other} = props
        // const errorOccurred = (key) => props.apiResponses?.[apiId]?.[key]?.actionType === 'client/error'
        return (
            <WrappedComponent
                key={version}
                API={API}
                responseHandler={responseHandler}
                responseData={props.apiResponses[apiId]}
                fieldChangeCreator={fieldChangeCreator}
                forceReload={reset}
                // errorOccurred={errorOccurred}
                errorData={errorData}
                submittedStatus={submittedStatus}
                successStatus={successStatus}
                errorStatus={errorStatus}
                {...other}
            />
        )
    }
    return connect(makeMapStateToProps, null)(withAuth(Wrapper))
}

export default withAPI
