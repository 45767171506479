import React, { useEffect } from 'react'
import moment from 'moment'
import {
    BadgeCustom,
    ButtonCustom,
    AvatarCustom,
    StyledDiv,
    IconButtonCustom,
} from '../../Styled'
import ProductCard from '../../../_reusable/Cards/ProductCard/ProductCard'
import { useSocketRetrieve } from '../../../../hs/hooks'
import { RS1 } from '../../../../redux/actions'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import { connect } from 'react-redux'
import { statePropMapper } from '../../../../redux/process/selectorProcess'
import { dispatchMappers } from '../../../../redux/process/actionProcess'
import GenericCard from '../../../_reusable/Cards/UniversalCard'
import { withAS } from '../../../_contexts/withAppState'

// # ICONS
import AddCommentIcon from '@mui/icons-material/AddComment'
import ReplyIcon from '@mui/icons-material/Reply'
import RepeatIcon from '@mui/icons-material/Repeat'
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
import TagFacesIcon from '@mui/icons-material/TagFaces'
import AddReactionIcon from '@mui/icons-material/AddReaction'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import { Badge } from '@mui/material'
import {getUserColors} from "../../../../hooks";

const makeMapStateToProps = statePropMapper([
    RS1.store('_userSettings', '_userSettings'),
])
const mapDispatchToProps = dispatchMappers([SA1.getOneTimeData])

//TODO: TypographyCustom

const AvatarContainer = function (props) {
    return (
        <StyledDiv types={['singleLineDiv']}>
            <AvatarCustom
                types={['timelineAvatar']}
                variant={'circle'}
                alt={
                    props.user?.userName
                        ? props.user?.userName
                        : props.message.uId
                }
                src={props.user?.pictureId}
            />
        </StyledDiv>
    )
}

let textStylers = {
    rt: function (text, key) {
        return <span key={key + 'rt'}>{text}</span>
    },
    i: function (text, key) {
        return <i key={key + 'i'}>{text}</i>
    },
    b: function (text, key) {
        return <b key={key + 'b'}>{text}</b>
    },
    bi: function (text, key) {
        return (
            <b key={key + 'bi'}>
                <i key={key + 'bii'}>{text}</i>
            </b>
        )
    },
    u: function (text, key) {
        return <u key={key + 'u'}>{text}</u>
    },
    a: function (text, key) {
        return (
            <a
                key={key + 'a'}
                href={text}
                target="_blank"
                rel="noopener noreferrer"
            >
                {text}
            </a>
        )
    },
}

const TextContainer = function (props) {
    if (props.useProductCard) {
        return <ProductCard {...props.product} />
    }

    let items = []
    for (var i = 0; i < props.messageStyling.length; i++) {
        if (i - 1 >= 0 && props.messageStyling[i].append) {
            if (props.messageStyling[i].mode !== 'a') {
                items.push(
                    textStylers[props.messageStyling[i].mode](
                        items[i - 1],
                        props.mId + 'i'
                    )
                )
            } else {
                items.push(
                    textStylers[props.messageStyling[i].mode](
                        props.messageStyling[i].text,
                        props.mId + 'i'
                    )
                )
            }
            items.splice(i - 1, 1)
        } else {
            items.push(
                textStylers[props.messageStyling[i].mode](
                    props.messageStyling[i].text,
                    props.mId + 'i'
                )
            )
        }
    }
    return (
        <div key={props.mId + 'TC'} style={{ overflowWrap: 'break-word' }}>
            {' '}
            {items}{' '}
            {props.edited ? (
                <small style={{ color: '#5A5A5A' }}>&nbsp;&nbsp;(edited)</small>
            ) : null}{' '}
        </div>
    )
}

const DeletedMessage = function (props) {
    return (
        <div style={{ margin: 'auto' }}>
            <span style={{ color: '#5A5A5A' }}>{props.message}</span>
        </div>
    )
}

const ImageContainer = function (props) {
    if (props.files?.length > 0) {
        return (
            <div>
                <div style={{ height: '400px' }}>
                    <img style={{ height: '400px' }} src={props.files[0]} />
                </div>
            </div>
        )
    } else if (props.urls.length !== 0 && props.urls[0].image) {
        return (
            <div>
                <div style={{ height: '400px' }}>
                    <img
                        style={{ height: '400px' }}
                        src={props.urls[0].url}
                        alt={props.urls[0].url}
                    />
                </div>
            </div>
        )
    } else {
        return null
    }
}

const MessageHeader = function (props) {
    let color = getUserColors(props?.user?.color)
    return (
        <StyledDiv types={['timelineMessageHeader']}>
            <StyledDiv types={['timelineUsername']}>
                <b style={{ color: color}}>
                    {props.user?.userName
                        ? props.user.userName + ' '
                        : props.message.uId + ' '}
                </b>
            </StyledDiv>
            <StyledDiv types={['timelineDate']}>
                <span style={{ fontSize: '11px' }}>
                    {'(' + props.fullMessageDate + ')'}
                </span>
            </StyledDiv>
        </StyledDiv>
    )
}

const ReplyHeader = function (props) {
    return (
        <div className="reply-div recolor-item">
            <b style={{ color: '#8A8A8A' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌───&nbsp;
            </b>
            {!props.deleted ? (
                <b style={{ color: '#8A8A8A' }}>{props.userName}</b>
            ) : null}
            {props.deleted ? <b style={{ color: '#8A8A8A' }}>⚉</b> : null}
            <b>&nbsp;</b>
            <span style={{ color: '#6A6A6A' }}>{props.message}</span>
            {props.edited ? (
                <small style={{ color: '#5A5A5A' }}>&nbsp;&nbsp;(...)</small>
            ) : null}
        </div>
    )
}

const TimelineMessage = function (props) {
    let message = props.message
    let user = props.user
    let messageStyling = props.messageStyling
    let product = { ...message?.metaData?.product }
    let innerColumnsStyle = {}
    if (message?.metaData?.productId && message.message === 'hidden') {
        product.desc = product.description
        innerColumnsStyle = { maxWidth: '1000px' }
    }

    useEffect(() => {
        if (message?.metaData?.productId && message.message === 'hidden') {
            if (
                props?._userSettings?.transactions &&
                props?._userSettings?.transactions?.length > 0
            ) {
                props._userSettings.transactions.forEach((transaction) => {
                    if (transaction.product.id === message.metaData.productId) {
                        props.dispatch.server.getOneTimeData(
                            props.server,
                            props.channel,
                            transaction.product.id,
                            message._id
                        )
                    }
                })
            }
        }
    }, [props?._userSettings?.transactions])

    if (!message.deleted) {

        // if (props?.useCard) {
        return (
            <GenericCard
                message={message}
                messageStyling={messageStyling}
                user={user}
                product={product}
                innerColumnsStyle={innerColumnsStyle}
                urls={props.urls}

                replies={0}
                reactions={0}
                highlights={0}
                quotes={0}

                push={props.AS.push}

                cardType={'timelineMsg'}
                displayMode={'fullTotalDesktop'}
                cardTestMode={false}
            />
        )
        // }
        // else {
        //     return (
        //         <div
        //             className="recolor-item"
        //             style={{
        //                 marginLeft: '50px',
        //                 marginRight: '50px',
        //             }}
        //         >
        //             {props.message.reply?.isReply ? (
        //                 <ReplyHeader
        //                     userName={props.message.reply.user}
        //                     message={props.message.reply.message}
        //                     edited={props.message.reply.edited}
        //                     deleted={props.message.reply.deleted}
        //                 />
        //             ) : null}
        //             <StyledDiv types={['flexboxOuterDiv', 'timelineOuter']}>
        //                 <StyledDiv types={['timelineInner']}>
        //                     <StyledDiv types={['flexboxInnerAvi']}>
        //                         <AvatarContainer message={message} user={user} />
        //                     </StyledDiv>
        //                     <StyledDiv
        //                         types={['flexboxInnerColumns', 'timelineColumns']}
        //                     >
        //                         <StyledDiv types={['flexboxColumnHeader']}>
        //                             <MessageHeader
        //                                 fullMessageDate={fullMessageDate}
        //                                 message={message}
        //                                 user={user}
        //                             />
        //                         </StyledDiv>
        //                         <StyledDiv
        //                             types={[
        //                                 'flexboxColumnText',
        //                                 'timelineMessageBody',
        //                             ]}
        //                             style={innerColumnsStyle}
        //                         >
        //                             <TextContainer
        //                                 key={message.mId + 'E'}
        //                                 mId={message.mId + 'EA'}
        //                                 useProductCard={
        //                                     message?.metaData?.productId &&
        //                                     message.message === 'hidden'
        //                                 }
        //                                 messageStyling={messageStyling}
        //                                 edited={props.message.edited}
        //                                 product={{ ...product }}
        //                             />
        //                         </StyledDiv>
        //                     </StyledDiv>
        //                 </StyledDiv>
        //                 <StyledDiv types={['flexboxColumnImg']}>
        //                     <ImageContainer
        //                         files={message.files}
        //                         urls={props.urls}
        //                         message={message}
        //                     />
        //                 </StyledDiv>

        //                 {/* <StyledDiv types={['timelineButtons']}>
        //                 <IconButtonCustom
        //                     color="primary"
        //                     onClick={() => {
        //                     }}
        //                     size="small"
        //                 >
        //                     <BadgeCustom
        //                         types={['timelineBadge']}
        //                         badgeContent={15}
        //                         color="secondary"
        //                     >
        //                         <ChatBubbleOutlineIcon />
        //                     </BadgeCustom>
        //                 </IconButtonCustom>
        //                 <IconButtonCustom
        //                     // /!* TODO: clicking turns empty chat bubble into full chat bubble: <ChatBubbleIcon /> *!/
        //                     color="primary"
        //                     onClick={() => {
        //                     }}
        //                     size="small"
        //                 >
        //                     <BadgeCustom
        //                         types={['timelineBadge']}
        //                         badgeContent={10}
        //                         color="secondary"
        //                     >
        //                         <AddReactionIcon />
        //                     </BadgeCustom>
        //                 </IconButtonCustom>
        //                 <IconButtonCustom
        //                     // /!* TODO: turns into selected reaction upon post *!/ 
        //                     color="primary"
        //                     onClick={() => {
        //                     }}
        //                     size="small"
        //                 >
        //                     <BadgeCustom
        //                         types={['timelineBadge']}
        //                         badgeContent={4}
        //                         color="secondary"
        //                     >
        //                         <RepeatIcon />
        //                     </BadgeCustom>
        //                 </IconButtonCustom>
        //                 <IconButtonCustom
        //                     color="primary"
        //                     onClick={() => {
        //                     }}
        //                     size="small"
        //                 >
        //                     <BadgeCustom
        //                         types={['timelineBadge']}
        //                         badgeContent={1}
        //                         color="secondary"
        //                     >
        //                         <FormatQuoteIcon />
        //                     </BadgeCustom>
        //                 </IconButtonCustom>
        //             </StyledDiv> */}

        //             </StyledDiv>
        //         </div>
        //     )
        // }
    } else {
        return (
            <StyledDiv
                types={['flexboxOuterDiv']}
                style={{ justifyContent: 'center' }}
            >
                <StyledDiv types={['flexboxInnerColumns']}>
                    <StyledDiv types={['flexboxColumnText']}>&nbsp;</StyledDiv>
                    <StyledDiv
                        types={['flexboxColumnText']}
                        style={{ alignContent: 'flex-start' }}
                    >
                        <DeletedMessage message={props.message.message} />
                    </StyledDiv>
                </StyledDiv>
            </StyledDiv>
        )
    }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(TimelineMessage))
