import React from 'react'
import { ButtonCustom } from '../../../../../Chat/Styled'
import { StyledDiv } from '../../../../../Chat/Styled'
import { dispatchMappers } from '../../../../../../redux/process/actionProcess'
import { RS1, RA1 } from '../../../../../../redux/actions'
import { connect } from 'react-redux'
import { statePropMapper } from '../../../../../../redux/process/selectorProcess'
import { embedProps, useTestData } from '../../util'

const makeMapStateToProps = statePropMapper([
    RS1.store('_activeChannel', '_activeChannel'),
])

const SampleDataLayer = (props) => {
    // #
    let data = {
        image: props._activeChannel,
        title: 'titleHere',
        data: 'dataHere'
    }

    data = useTestData(props, data, {
        // image: 'https://upload.wikimedia.org/wikipedia/commons/1/15/Cat_August_2010-4.jpg',
        image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Oryctolagus_cuniculus_Rcdo.jpg/640px-Oryctolagus_cuniculus_Rcdo.jpg',
        title: 'The title goes hare',
        data: 'Data and statistics on various elements, such as hops per second, or leap length variance!'
        // data: 'Data and statistics on various elements, such as hops per second, or leap length variance! Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.'
    })

    return embedProps(props, data)
}

export default connect(makeMapStateToProps, null)(SampleDataLayer)
