import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {RA1, RS1} from '../../../../redux/actions.js'
import {SA1} from '../../../../hs/requestGlobals.mjs'
import {dispatchMappers} from '../../../../redux/process/actionProcess.js'
import SocketForm from '../../../_reusable/SocketForm/SocketForm'
import {statePropMapper} from '../../../../redux/process/selectorProcess'

const makeMapStateToProps = statePropMapper([
    RS1.getCalendar('calendar'),
])

const mapDispatchToProps = dispatchMappers([RA1.api.updateAPI, RA1._appState.closeModalMenu,SA1.backoutOfAppointment])

const BackoutOfAppointmentModal = function (props) {
    return (
        <SocketForm
            formMethod={'BackoutOfAppointment'}
            formDispatch={() => {
                props.dispatch.server.backoutOfAppointment(props.server,props.calendar.selected._id)
            }}
            socketForm
            onClose={props.closeModalMenu}
            onSuccess={() => null}
            description={''}
            formFields={[]}
            formButtons={[
                {
                    label: 'Cancel Booking',
                    onClick: () => {
                    },
                    icon: 'none',
                    isSubmitButton: true,
                    isCloseButton: false,
                },
                ,
                {
                    label: 'Exit',
                    onClick: () => {
                    },
                    icon: 'none',
                    isSubmitButton: false,
                    isCloseButton: true,
                },
            ]}
            formTypography={{label: 'Cancel Booking'}}
            successMessage={'Appointment cancelled.'}
        />
    )
}

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(BackoutOfAppointmentModal)
