import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RA1, RS1 } from '../../../../redux/actions.js'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import { dispatchMappers } from '../../../../redux/process/actionProcess.js'
import SocketForm from '../../../_reusable/SocketForm/SocketForm'
import { statePropMapper } from '../../../../redux/process/selectorProcess'
import withAPI from '../../../_wrappers/withAPI'
import useState from 'react-usestateref'

const makeMapStateToProps = statePropMapper([
    RS1.store('_appState', '_appState'),
    RS1.getServerAbstractRoles('serverAbstractRoles'),
])
const mapDispatchToProps = dispatchMappers([
    RA1.api.updateAPI,
    RA1._appState.updateAppState,
    SA1.createProduct,
])

const CreateProductModal = function (props) {
    // #["subscription","one-time","appointment","direct-message"]

    const recurringOptions = props._appState?.productData?.isOneTime
        ? [{ value: 'one-time', name: 'One-Time Paid Post' }]
        : [
            { value: 'calendar', name: 'Calendar' },
            { value: 'one-time', name: 'One-time' },
            { value: 'subscription', name: 'Subscription' },
            { value: 'paid-dm', name: 'Private Messages' },
        ]

    const formStateDefaults = { type: recurringOptions[0].value }
    const formFields = props._appState?.productData?.isOneTime
        ? [
            {
                id: 'name',
                typographyLabel: 'Name:', // TODO: Unnecessary for this application.
                require: true,
                page: 1,
            },
            {
                id: 'description',
                typographyLabel: 'Public message:',
                require: true,
                page: 1,
            },
            {
                id: 'type',
                type: 'selectGroup',
                typographyLabel: '',
                defaultIndex: 0,
                formatter: (obj) => obj?.name,
                list: recurringOptions,
                require: false,
                page: 1,
            },
            {
                id: 'price',
                typographyLabel: 'Price:',
                require: true,
                page: 1,
            },
            {
                id: 'warning',
                typographyLabel:
                    'We HIGHLY suggest that you do NOT use this as your primary form of interaction with the community. Type "YES" below if you have read this message.', // TODO: Unnecessary for this application.
                require: true,
                page: 1,
            },
        ]
        : [
            {
                id: 'name',
                typographyLabel: 'Name:',
                require: true,
                page: 1,
            },
            {
                id: 'description',
                typographyLabel: 'Description:',
                require: true,
                page: 1,
            },
            {
                id: 'type',
                type: 'selectGroup',
                typographyLabel: 'Appointment Type',
                defaultIndex: 0,
                formatter: (obj) => obj?.name,
                list: recurringOptions,
                require: false,
                page: 1,
            },
            {
                id: 'roleName',
                hideIf: (formState) => {
                    return formState.type !== "subscription"
                },
                typographyLabel: 'Role Name:',
                require: false,
                page: 1,
            },
            {
                id: 'price',
                typographyLabel: 'Price:',
                require: true,
                page: 1,
            },
        ]

    const formButtons = props._appState?.productData?.isOneTime
        ? [
            {
                label: 'Attach to Message',
                icon: 'add',
                isSubmitButton: true,
                isCloseButton: false,
            },
            {
                label: 'Cancel',
                icon: 'add',
                isSubmitButton: false,
                isCloseButton: true,
            },
        ]
        : [
            {
                label: 'Create',
                icon: 'add',
                isSubmitButton: true,
                isCloseButton: false,
            },
            {
                label: 'Cancel',
                icon: 'add',
                isSubmitButton: false,
                isCloseButton: true,
            },
        ]

    // #
    return (
        <SocketForm
            useConfirmationScreen={true}
            useMultipleFormPages={true}
            confirmationFunction={(obj) => {
            }}
            formMethod={'createProduct'}
            formStateDefaults={formStateDefaults}
            formDispatch={(obj) => {
                let copy = { ...obj }
                if (copy?.warning && copy?.warning !== 'YES') {
                    // TODO: doesn't work, then.
                    return null
                }
                let productPrice = copy.price
                if (productPrice.includes(".")) { productPrice = productPrice.split('.').join('') }

                props.dispatch.server.createProduct(
                    props.server,
                    copy.name,
                    copy.description,
                    'aa', //TODO: icon
                    copy.type,
                    productPrice,
                    copy.roleName
                )
                return null
            }}
            socketForm
            onClose={null}
            onSuccess={() => {
            }}
            description={'You may create a new product.'}
            formFields={formFields}
            formButtons={formButtons}
            formTypography={{ label: 'Create Product' }}
            successMessage={'Product created.'}
        />
    )
}

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(CreateProductModal)
