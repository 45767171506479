import react, { useState, useEffect } from 'react'
import { StyledDiv } from '../../Styled'
import { RS1 } from '../../../../redux/actions'
import { statePropMapper } from '../../../../redux/process/selectorProcess'
import { connect } from 'react-redux'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import { IconButton } from '@mui/material'
import { withAS } from '../../../_contexts/withAppState'

const makeMapStateToProps = statePropMapper([RS1.getServer('serverData')])

const BackgroundFlair = function (props) {
    return (
        <StyledDiv
            style={{ width: props.sidebarWidth, maxWidth: props?.desktop ? '300px' : '100vw' }}
            types={['backgroundFlair']}
        >
            <div>
                <div></div>
            </div>

            {props.serverData.bannerId ?
                <div style={{
                    backgroundSize: 'cover',
                    backgroundImage:
                        'linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0,0,0,1) calc(100% - 1.5em)), ' +
                        'url("' + props.serverData.bannerId + '")',
                }}></div> :
                <StyledDiv types={['backgroundFlairDefault']}></StyledDiv>
            }
        </StyledDiv >
    )
}

export default connect(makeMapStateToProps, null)(withAS(BackgroundFlair))
