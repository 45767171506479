import { StyledDiv } from "../../Chat/Styled";
import React from "react";

const BottomRightHover = (props) =>
    <div style={{ position: "relative", width: "100%" }}>
        <StyledDiv types={['_bottomRightHover']}>
            {props.children}
        </StyledDiv>
    </div>

const BottomLeftHover = (props) =>
    <div style={{ position: "relative", width: "100%" }}>
        <StyledDiv types={['_bottomLeftHover']}>
            {props.children}
        </StyledDiv>
    </div>

const TopRightHover = (props) =>
    <div style={{ position: "relative", width: "100%" }}>
        <StyledDiv types={['_topRightHover']}>
            {props.children}
        </StyledDiv>
    </div>

export { BottomRightHover, BottomLeftHover, TopRightHover }