import CellMeasurerCache from 'react-virtualized/dist/commonjs/CellMeasurer/CellMeasurerCache'

let shift = (arr, newElement) => {
    let newArr = []
    newArr.push(arr[1])
    // newArr.push(arr[2])
    newArr.push(newElement)
    return newArr
}
let cellPack = (rowIndex) => ({
    columnIndex: 0,
    rowIndex: rowIndex,
})

export class renderOperationsClass {
    constructor(cacheRef, gridRef, rowIndexRef, op, maxRegionSize) {
        this.cacheRef = cacheRef
        this.gridRef = gridRef
        this.rowIndexRef = rowIndexRef
        this.op = op
        this.maxRegionSize = maxRegionSize
    }

    createCache(messagesRef) {
        //#TODO: Deal with default   height issue on different devices
        this.cacheRef.current = new CellMeasurerCache({
            defaultHeight: 68,
            minHeight: 58,
            fixedWidth: true,
            keyMapper: (rowIndex) => {
                if (rowIndex === 0) {
                    return 'topPH'
                }
                if (rowIndex === messagesRef.current.size + 1) {
                    return 'bottomPH'
                }
                return messagesRef.current.get(rowIndex - 1)
            },
        })
    }

    cleanUp() {}

    initializeGrid(otherGridRef) {
        return (ref) => (otherGridRef.current = ref)
    }

    rowSizer(messagesRef) {
        return (rowIndex) => {
            let key
            if (
                rowIndex.index === 0 ||
                rowIndex.index === messagesRef.current.size + 1
            ) {
                if (rowIndex.index === 0) {
                    key = 'topPH'
                } else {
                    key = 'bottomPH'
                }
            } else {
                this.rowIndexRef.current = rowIndex.index
                key = messagesRef.current.get(rowIndex.index - 1)
            }
            return this.cacheRef.current._cellHeightCache.hasOwnProperty(key)
                ? this.cacheRef.current._cellHeightCache[key]
                : this.cacheRef.current.defaultHeight
        }
    }

    cellShiftOperation(shiftIndex) {
        this.op.cu.fin = true
        this.op.cu.finOp = () => null
        this.op.cu.temp = {}
        this.op.cu.init = true
        this.op.cu.initOp = () => null
        this.op.cu.op = () => {
            this.gridRef.current.scrollToCell(cellPack(shiftIndex))
        }
        this.op.cu.dataArray = [-3, -2]
        this.op.cu.post = () =>
            (this.op.cu.dataArray = shift(
                this.op.cu.dataArray,
                this.gridRef.current.state.scrollTop
            ))
        this.op.cu.cond = () => {
            return this.op.cu.dataArray[0] !== this.op.cu.dataArray[1]
        }
        // this.op.cu.dataArray = []
        // this.op.cu.post = () => this.op.cu.dataArray.push("1")
        // this.op.cu.cond = () => {
        //     return (this.op.cu.dataArray.length < 1)
        // }
    }

    cacheManagementPolicy() {
        return ({
            clearCache,
            resetCache,
            measureCells,
            recomputeGrid,
            messagesRef,
        }) => {
            this.op.cu.cache = (index) => {
                index === clearCache ? this.cacheRef.current.clearAll() : null
                index === resetCache ? this.createCache(messagesRef) : null
                index === measureCells
                    ? this.gridRef.current.measureAllCells()
                    : null
                index === recomputeGrid
                    ? this.gridRef.current.recomputeGridSize({
                          columnIndex: 0,
                          rowIndex: 0,
                      })
                    : null
            }
        }
    }

    measureAllCells() {
        this.gridRef.current.recomputeGridSize({ columnIndex: 0, rowIndex: 0 })
    }

    overscanAndScrollAdjustments(
        setOverscan,
        setAlignment,
        overscanChange,
        scrollAlignment
    ) {
        this.op.cu.fin = false
        this.op.cu.temp = {}
        this.op.cu.init = false
        this.op.cu.initOp = () => {
            if (!this.op.cu.init) {
                setOverscan((overscan) => {
                    this.op.cu.temp.oldOverscan = overscan
                    return overscanChange
                })
                setAlignment((alignment) => {
                    this.op.cu.temp.oldAlignment = alignment
                    return scrollAlignment
                })
                this.op.cu.init = true
            }
        }
        this.op.cu.finOp = () => {
            if (!this.op.cu.fin) {
                setOverscan(this.op.cu.temp.oldOverscan)
                setAlignment(this.op.cu.temp.oldAlignment)
                this.op.cu.fin = true
            }
        }
    }
}
