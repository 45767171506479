import { createSlice, createAction } from '@reduxjs/toolkit'
import { createCachedSlice } from '../cache/createCachedSlice.js'

const userMeta = createCachedSlice({
    name: 'userMetas',
    initialState: {},
    reducers: {
        addUserMeta: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                const { id, content } = action.payload
                return {
                    ...state,
                    [id]: action.payload,
                }
            },
        },
        addUserMetas: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                let newState = { ...state }
                for (let key in action.payload) {
                    if (action.payload.hasOwnProperty(key)) {
                        newState[key] = action.payload[key]
                    }
                }
                return newState
            },
        },
    },
})

const userMetaSlice = userMeta
const userMetaActions = userMeta.actions
const addUserMetas = userMeta.actions.addUserMetas
const userMetas = userMeta.reducer
export { userMetaSlice, addUserMetas, userMetas, userMetaActions }
