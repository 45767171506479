import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack.js'
import CheckIcon from '@mui/icons-material/Check'
import React from 'react'
import { dispatchMappers } from '../../../../../redux/process/actionProcess.js'
import { RA1 } from '../../../../../redux/actions.js'
import { connect } from 'react-redux'

const mapDispatchToProps = dispatchMappers([RA1._appState.updateAppState])

const ChannelConfigFooter = function (props) {
    return (
        <div>
            <div style={{ width: '48px' }}></div>
            <div style={{ width: '48px' }}></div>
            <IconButton
                color="primary"
                onClick={() => {
                    props.setMenu('community')
                    props.changePage('chat')
                }}
                size="large"
            >
                <ArrowBackIcon />
            </IconButton>
            <IconButton color="primary" onClick={() => { }} size="large">
                <CheckIcon />
            </IconButton>
        </div>
    )
}

export default connect(null, mapDispatchToProps)(ChannelConfigFooter)
