import React, { useEffect, useState } from 'react'
import { ButtonCustom, vDH } from '../../../Styled'
import { RS1, RA1 } from '../../../../../redux/actions'
import { SA1 } from '../../../../../hs/requestGlobals.mjs'
import { statePropMapper } from '../../../../../redux/process/selectorProcess'
import { dispatchMappers } from '../../../../../redux/process/actionProcess'
import { connect } from 'react-redux'
import { withAS } from "../../../../_contexts/withAppState";

const makeMapStateToProps = statePropMapper([
    RS1.store('_appState', '_appState'),
])

const mapDispatchToProps = dispatchMappers([
    RA1._appState.clearAccountLinkUrl,
    SA1.createPaymentAccount,
])

const AddPaymentCreds = function (props) {
    // #
    const [onboardingUrl, setOnboardingUrl] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    // #
    const isDisabled = () => !onboardingUrl || isLoading
    const onboardingHref = () => (isLoading ? '' : onboardingUrl)

    // #
    useEffect(() => {
        props.dispatch.server.createPaymentAccount(props.AS.activeServer)
    }, [])

    useEffect(() => {
        if (props._appState?.accountLinkUrl) {
            setIsLoading(true)
            setOnboardingUrl(props._appState?.accountLinkUrl)
            props.dispatch._appState.clearAccountLinkUrl()
            setIsLoading(false)
        }
    }, [props._appState])

    // #
    return (
        <div style={{ margin: '5%' }}>
            <div style={{ float: 'right' }}>
                <ButtonCustom types={[]} onClick={props.backButton}>
                    Back
                </ButtonCustom>
            </div>

            {/* TODO: nice feature: href button when !disabled  */}
            <div
                style={{
                    fontSize: '1.3em',
                    height: vDH(60),
                    display: 'flex',
                    flexDirection: 'column',
                    flexFlow: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <br />
                <br />

                <div>
                    <a target="_self" href={onboardingHref()}>
                        onboarding
                    </a>
                </div>
            </div>
        </div>
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(AddPaymentCreds))
