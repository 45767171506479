import React, { useState, useEffect } from 'react';
import { IconButtonCustom, StyledDiv, AvatarCustom } from '../Styled';
import { connect } from 'react-redux';
import { RS1 } from '../../../redux/actions';
import { statePropMapper } from '../../../redux/process/selectorProcess';
import { withAS } from '../../_contexts/withAppState';
// import {CreateFan} from './CreateFan';

// left chat icon
import MessageIcon from '@mui/icons-material/Message';
// user list
import Diversity1Icon from '@mui/icons-material/Diversity1';

// chat icon
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';

// notifications
import NotificationsIcon from '@mui/icons-material/Notifications';
// people
import PeopleIcon from '@mui/icons-material/People';

// To take the place of the old add circle
// friends
import AccountBoxIcon from '@mui/icons-material/AccountBox';
// thread view
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
// right chat icon
import CommentIcon from '@mui/icons-material/Comment';
// subscriptions
import LoyaltyIcon from '@mui/icons-material/Loyalty';
// calendar -- TODO: Need better icon.
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const makeMapStateToProps = statePropMapper([
    RS1.getServer('serverData'),
])

const AvatarContainer = function (props) {
    let imgUrl = `https://picsum.photos/seed/${props.server}/800/800?blur=3`
    if (props.serverData?.avatarId !== 'none') {
        imgUrl = props.serverData?.avatarId
    }

    return (
        <AvatarCustom
            types={['iconAvatar', 'mobileNavAvi']}
            variant="square"

            // sx={{ flex: '0 0 auto', width: '100%', height: 'auto' }}
            // types={['profileAvatar', 'curvedBorder']}

            alt={props?.serverData?.name ? props?.serverData?.name : 'error'} // TODO: FIX
            src={imgUrl}
        />
    )
}

const MobileNav = (props) => {
    const [leftBar, setLeftBar] = useState(false)
    const [rightBar, setRightBar] = useState(false)
    const [rightMenu, setRightMenu] = useState(0)
    const [ver, setVer] = useState(0)
    const closeSidebars = () => {
        props.setRightBar(false)
        props.toggleDrawer('right', false)()
        props.setLeftBar(false)
        props.toggleDrawer('left', false)()
    }
    useEffect(() => {
        if (props.AS.nav.state.forceSidebarClosed) {
            closeSidebars()
            props.AS.nav.ops.setForceSidebarClosed(false)
        }
    }, [props.AS.nav.state.forceSidebarClosed])

    return (
        <StyledDiv
            types={['mobileNavOuter']}
            style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                height: '4rem',
                zIndex: 2006,

                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                padding: '5px 0px',
            }}>

            <IconButtonCustom types={[props.leftBar && 'mobileNavButton', 'mobileNavAlign']}
                onClick={async () => {
                    if (props.page === 'profile') {
                        // TODO: ALICIA - IF ON PROFILE PAGE, LEAVE TO CHAT PAGE
                        // props.AS.nav.ops.changeOffProfilePage()
                    }
                    setTimeout(() => {
                        if (!props.leftBar) {
                            props.setLeftBar(true)
                            if (props.rightBar) {
                                props.setRightBar(false)
                                props.swapToDrawer('left')()
                            } else {
                                props.toggleDrawer('left', true)()
                            }
                        }
                    }, 120)


                }}>
                <Diversity1Icon />
                <span style={{ fontSize: '0.7rem', marginTop: '3px' }}>Spaces</span>
            </IconButtonCustom>

            <IconButtonCustom
                types={[props.page === 'profile'
                    && !props.leftBar
                    && !props.rightBar
                    && 'mobileNavButton',
                    'mobileNavAlign']}
                onClick={() => {
                    props.setRightBar(false)
                    props.toggleDrawer('right', false)()
                    props.setLeftBar(false)
                    props.toggleDrawer('left', false)()
                    setTimeout(() => {
                        props.AS.nav.ops.changeToProfilePageWithStateClear(props.server)
                    }, 30)
                }}>
                <AvatarContainer
                    serverData={props.serverData}
                    server={props.server} />
                <span style={{ fontSize: '0.7rem', marginTop: '3px' }}>Timeline</span>
            </IconButtonCustom>

            <IconButtonCustom
                types={[(props.page === 'chat' || props.page === 'threads')
                    && !props.leftBar
                    && !props.rightBar
                    && 'mobileNavButton',
                    'mobileNavAlign']}
                onClick={() => {
                    if (props.page === 'profile') {
                        props.AS.nav.ops.changeOffProfilePage()
                    }
                    closeSidebars()

                }}>
                <QuestionAnswerIcon style={{ color: 'inherit' }} />
                <span style={{ fontSize: '0.7rem', marginTop: '3px' }}>Chat</span>
            </IconButtonCustom>

            <IconButtonCustom
                types={[props.rightBar && props.rightMenu === 1 && 'mobileNavButton', 'mobileNavAlign']}
                onClick={() => {
                    if (props.rightMenu !== 1) {
                        props.setRightMenu(1)
                        props.setMobileNavThreadWatcher(true)
                    }

                    if (!props.rightBar) {
                        // open right bar
                        props.setRightBar(true)
                        if (props.leftBar) {
                            props.setLeftBar(false)
                            props.swapToDrawer('right')()
                        } else {
                            props.toggleDrawer('right', true)()
                        }
                    }
                }}>

                <NotificationsIcon style={{ color: 'inherit' }} />

                <span style={{ fontSize: '0.7rem', marginTop: '3px' }}>Updates</span>
            </IconButtonCustom>

            <IconButtonCustom
                types={[props.rightBar && props.rightMenu === 0 && 'mobileNavButton', 'mobileNavAlign']}
                onClick={() => {
                    if (props.rightMenu !== 0) {
                        // set right menu to 0
                        props.setRightMenu(0)
                        props.setMobileNavThreadWatcher(false)
                        if (props?.setMobileNavTabValue) {
                            props.setMobileNavTabValue(0)
                        }
                    }

                    if (!props.rightBar) {
                        // open right bar
                        props.setRightBar(true)
                        if (props.leftBar) {
                            props.setLeftBar(false)
                            props.swapToDrawer('right')()
                        } else {
                            props.toggleDrawer('right', true)()
                        }
                    }
                }}>
                {props.page === 'profile' ? <CalendarMonthIcon /> : <PeopleIcon />}

                <span style={{ fontSize: '0.7rem', marginTop: '3px' }}>{props.page === 'profile' ? 'Features' : 'People'}</span>
            </IconButtonCustom>

        </StyledDiv>
    )
}

export default connect(
    makeMapStateToProps, null
)(withAS(MobileNav))