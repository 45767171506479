import {combineReducers} from 'redux'
import {server} from './server'
import {client, clientSlice} from './client'
import {chatServers} from './chatServers'
import {channels, channelSlice} from './channels'
import {
    _temp,
    _userId,
    _loaded,
    _activeChannel,
    _servers,
    _serverIDList,
    _guestId,
    _lastUpdateType,
    _activeServer,
    _permDescriptions,
    _inServers,
    _trackedServerList,
} from './monostate/mono'
import {cacheMonitorReducer, cacheMonitorSlice} from './cacheMonitor.js'
import {chatInputs, chatInputSlice} from './chatInput.js'
import {videoCalls, videoSlice} from "./video.js"
import {messages} from './messages'
import {userMetas, userMetaSlice} from './userMeta'
import {users, userSlice} from './users'
import {
    abstractRoles,
    abstractRolesSlice,
    addAbstractRoles,
} from './abstractRoles'
import {_appState, appStateSlice} from './appState'
import {channelWindows, channelWindowSlice} from './channelWindow'
import {_userSettings, userSettingsSlice} from './userSettings'
import {messagesSlice} from './messages.js'
import {apiSlice, apiResponses} from './api.js'
import {notificationsSlice, notifications} from './notifications.js'
import {
    activeChannelSlice,
    activeServerSlice,
    inServersSlice,
    lastUpdateTypeSlice,
    loadedSlice,
    permDescriptionsSlice,
    serverIDListSlice,
    serversSlice,
    trackedServerListSlice,
    guestIdSlice,
    userIdSlice,
} from './monostate/mono.js'
import {calendar, calendarSlice} from './calendar'
import {management, managementSlice} from './management'
import {imageStore, imageStoreSlice} from './imageStore'
import {threads, threadSlice} from './threads'
import {products, productsSlice} from "./product";
import {integrationsReducer, integrationsSlice} from "./integrations";

export const sliceArray = [
    abstractRolesSlice,
    calendarSlice,
    appStateSlice,
    apiSlice,
    cacheMonitorSlice,
    channelSlice,
    channelWindowSlice,
    chatInputSlice,
    integrationsSlice,
    videoSlice,
    clientSlice,
    messagesSlice,
    productsSlice,
    managementSlice,
    serversSlice,
    imageStoreSlice,
    threadSlice,
    userMetaSlice,
    userSlice,
    userSettingsSlice,
    userIdSlice,
    lastUpdateTypeSlice,
    activeChannelSlice,
    activeServerSlice,
    trackedServerListSlice,
    notificationsSlice,
    permDescriptionsSlice,
    guestIdSlice,
    loadedSlice,
    serverIDListSlice,
    serversSlice,
    inServersSlice,
]

export const nonMonoSlices = [
    abstractRolesSlice,
    chatInputSlice,
    videoSlice,
    appStateSlice,
    calendarSlice,
    channelSlice,
    channelWindowSlice,
    apiSlice,
    clientSlice,
    productsSlice,
    managementSlice,
    integrationsSlice,
    imageStoreSlice,
    messagesSlice,
    threadSlice,
    notificationsSlice,
    serversSlice,
    userMetaSlice,
    userSlice,
    userSettingsSlice,
    userIdSlice,
]
export const reducerArray = sliceArray.map((slice) => slice.reducer)
export const combinedReducer = combineReducers({
    apiResponses,
    client,
    chatInputs,
    videoCalls,
    chatServers,
    server,
    channelWindows,
    channels,
    imageStore,
    calendar,
    integrations: integrationsReducer,
    notifications,
    abstractRoles,
    management,
    messages,
    threads,
    userMetas,
    users,
    _userSettings,
    _temp,
    _userId,
    cacheMonitorReducer,
    products,
    _loaded,
    _guestId,
    _activeChannel,
    _servers,
    _serverIDList,
    _lastUpdateType,
    _activeServer,
    _permDescriptions,
    _inServers,
    _trackedServerList,
    _appState,
})
