export const SearchStyleOverrides = (mode, palette) => {
    return {
        searchBarOuterDiv: {},
        searchSuggestionDiv: {},
        displayedConditionDiv: {},
        //=======================//
        // SEARCH RESULTS STYLES //
        //=======================//
        searchMessageDiv: {
            height: '10em',
            maxHeight: '10em',
            minHeight: '10em',
            marginTop: '10em',
            marginBottom: '10em',
            '& > *': {
                overflow: 'hidden',
                textOverflow: 'ellipses',
            },

            '& div': {
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'start',
                paddingLeft: '5px',
            },
        },
    }
}
