import React from 'react'
import {connect} from 'react-redux'
import {RA1, RS1} from '../../../../redux/actions.js'
import {SA1} from '../../../../hs/requestGlobals.mjs'
import {dispatchMappers} from '../../../../redux/process/actionProcess.js'
import SocketForm from '../../../_reusable/SocketForm/SocketForm'
import {statePropMapper} from '../../../../redux/process/selectorProcess'

const makeMapStateToProps = statePropMapper([
    RS1.getServer('serverData')
])
const mapDispatchToProps = dispatchMappers([
    RA1._appState.closeModalMenu,
    SA1.alphaCreateInvite,
])

const AlphaCreateInviteModal = function (props) {
    const dispatchMethod = ({inviteCode}) => {
        props.setProgress(true)
        props.dispatch.server.alphaCreateInvite(
            props.AS.activeServer,
            inviteCode,
            'alphaCreateInvite',
            'alphaCreateInvite'
        )
    }
    return (
        <SocketForm
            socketForm
            formMethod={'alphaCreateInvite'}
            formDispatch={dispatchMethod}
            onClose={props.closeModalMenu}
            onSuccess={() => props.setProgress(false)}
            onError={() => props.setProgress(false)}
            description={'Would you like to generate an alpha invite?'}
            formFields={[ {
                id: 'inviteCode',
                typographyLabel: 'Invite Code'
            }]}
            formButtons={[
                {label: 'Generate', icon: 'add', isSubmitButton: true}, {label: 'Cancel', isCloseButton: true}
            ]}
            formTypography={{label: 'Generate Alpha Invite'}}
            successMessage={'Invite created.'}
        />
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(AlphaCreateInviteModal)
