export const BadgeCustomStyle = (mode) => ({
    BadgeCustom: {
        defaultProps: {},
        styleOverrides:{"nothing":{}},altStyleOverrides: {
            root: {},
            timelineBadge: {
                '& span': { fontWeight: 'bold', height: '50%', weight: '50%' },
            },
        },
    },
})
