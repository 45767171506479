import React, { useEffect, useRef, useState as useStatePre } from 'react'
import ASContext from './context.js'
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux'
import { statePropMapper } from "../../../redux/process/selectorProcess";
import { getProfileContent, RA1, RS1 } from "../../../redux/actions";
import { dispatchMappers } from "../../../redux/process/actionProcess";
import { SA1 } from "../../../hs/requestGlobals.mjs";
import { Alert } from "@mui/lab";
import queryString from "querystring";
import { FULL_FRONTEND_URL, SITE_URL } from "../../../constants/env";
import { withAuth } from "../withAuth";
import { useQueryStrings } from "../../../hooks";
import { streams } from "../../Chat/Stream/stream-controller";
import { store } from "../../../redux/store";
// import {useLocation} from "react-router";

const makeMapStateToProps = statePropMapper([
    RS1.store('_appState', '_appState'),
    RS1.store('_loaded', '_loaded'),
    RS1.store('_userId', '_userId'),
    RS1.store('_guestId', '_guestId'),
    RS1.store('_activeServer', '_activeServer'),
    RS1.store('_activeChannel', '_activeChannel'),
    RS1.store('chatServers', 'chatServers'),
    RS1.store('serverList', '_trackedServerList'),
    RS1.getProfileMeta('profileMeta'),
])

const mapDispatchToProps = dispatchMappers([
    RA1._appState.updateAppState,
    SA1.joinServer,
    RA1.other.setActiveChannel,
    RA1.other.setActiveServer
])

const appStateLog = (message, secondary) => {
    console.log(
        '%c (App State Monitor)[' + message + ']',
        'background: #222; color: #C000C0',
        secondary
    )
    // try {
    //     throw new Error('App State Erroro');
    // } catch (e) {
    //     console.log("App State Error", e)
    //     // Expected output: Error: Parameter is not a number!
    // }


}
const useState = (x, u) => {
    const [y, z] = useStatePre(x)
    return [y, (w) => {
        appStateLog("setState[" + u + "]:" + w, w)
        z(w)
    }]
}
const initValues = queryString.parse(location.search)

const keyCheck = (array1, array2) => {
    let state = true
    if (array1.length !== array2.length) {
        state = false
    }
    array2.forEach((key) => {
        if (!array1.includes(key)) {
            state = false
        }
    })
    return state
}

const keyCleaner = (vals) => {
    const cleanedValues = {}
    Object.keys(vals).forEach((key) => {
        if (key === "") {
            return null
        }
        if (key[0] === "?") {
            if (vals[key] === 'true') {
                cleanedValues[key.substring(1)] = true
            } else if (vals[key] === 'false') {
                cleanedValues[key.substring(1)] = false
            } else {
                cleanedValues[key.substring(1)] = vals[key]
            }
            return null
        }
        if (vals[key] === 'true') {
            cleanedValues[key] = true
        } else if (vals[key] === 'false') {
            cleanedValues[key] = false
        } else {
            cleanedValues[key] = vals[key]
        }
        return null
    })
    const cleanedKeys = Object.keys(cleanedValues)
    return [cleanedKeys, cleanedValues]
}


const PreASProvider = (props) => {
    const history = useHistory()
    const [directiveIndex, setDirectiveIndex] = useState(0, 'initLoad')
    const [initLoad, setInitLoad] = useState(true, 'initLoad')
    const [multiStageTransition, setMultiStageTransition] = useState(false, 'multiStageTransition')
    const [activeServer, setActiveServer] = useState(props._activeServer, 'activeServer')
    const [mostRecentNonUserServer, setMostRecentNonUserServer] = useState(props._activeServer, 'mostRecentNonUserServer')
    const [activeChannel, setActiveChannel] = useState(props._activeChannel, 'activeChannel')
    const [serverChannelActivity, setServerChannelActivity] = useState({ [props._activeServer]: props._activeChannel }, 'serverChannelActivity')
    const [dmData, setDMData] = useState(null, 'dmData')
    const [page, setPageUnderlying] = useState('chat', 'page')
    const [forceSidebarClosed, setForceSidebarClosed] = useState(false, 'forceSidebarClosed')
    const [hideNavBar, setHideNavBar] = useState(false, 'hideNavBar')
    const [onProfile, setOnProfile] = useState(false, 'onProfile')
    const [menuL, setMenuLPre] = useState('community', 'menuL')
    const [menuR, setMenuR] = useState(null, 'menuR')
    const [modal, setModal] = useState(null, 'modal')
    const [displayErrorBanner, setDisplayErrorBanner] = useState(false, 'displayErrorBanner')
    const [videoOverlay, setVideoOverlay] = useState(false, 'videoOverlay')
    const [monitorModal, setMonitorModal] = useState(null, 'monitorModal')
    const [managementPage, setManagementPageUnderlying] = useState('settings', 'managementPage')
    const [useBlockedFunction, setUseBlockedFunction] = useState(false, 'useBlockedFunction')
    const [modalSuccess, setModalSuccess] = useState(null, 'modalSuccess')
    const [operationInProgress, setOperationInProgress] = useState(false, 'operationInProgress')
    const [modalType, setModalType] = useState(null, 'modalType')
    const [modalData, setModalData] = useState(null, 'modalData')
    const [modalIsOpen, setModalIsOpen] = useState(false, 'modalIsOpen')
    const [pageChangeBlocked, setPageChangeBlocked] = useState(null, 'pageChangeBlocked')
    const [selectedThread, setSelectedThread] = useState(null, 'selectedThread')
    const [singleThreadPage, setSingleThreadPage] = useState(false, 'singleThreadPage')
    const [videoStreamOptions, setVideoStreamOptions] = useState(null, 'videoStreamOptions')
    const [updateQueryString, setUpdateQueryStringUnderlying] = useState(false, 'updateQueryString')
    const [queryStringMutable, setQueryStringMutable] = useState(false, 'queryStringMutable')
    const [locationKeys, setLocationKeys] = useState([], 'locationKeys')
    const isGuest = props._userId === props._guestId

    useEffect(()=>{
        if(activeServer!==props._userId){
            setMostRecentNonUserServer(activeServer)
        }
    },[activeServer])
    const isMobile = () => (screen.width / screen.height) <= 4 / 3
    const setUpdateQueryString = (x) => {
        if (queryStringMutable) {
            console.log("updating query string...")
            setUpdateQueryStringUnderlying(x)
        }
    }
    useEffect(() => {
        const values = queryString.parse(location.search)
        if (updateQueryString) {
            let queryString = ""
            if (page === "profile") {
                queryString += 's=' + activeServer + "&"
                queryString += 'c=' + activeChannel + "&"
                queryString += "p=true&"
                if (selectedThread) {
                    queryString += 't=' + selectedThread + "&"
                }
            } else {
                queryString += 's=' + activeServer + "&"
                queryString += 'c=' + activeChannel + "&"
                if (page === "threads") {
                    queryString += 'th=true&'
                    if (selectedThread) {
                        queryString += 't=' + selectedThread + "&"
                    }
                }
            }
            if (queryStringMutable) {
                let searchString = '?' + queryString;
                if (history.location.search !== searchString) {
                    appStateLog("useEffect[Update Query String]", searchString)
                    history.push('/chat2' + searchString)
                }
            }
            console.log("setUpdateQueryString");
            setUpdateQueryStringUnderlying(false)
        }
    }, [updateQueryString, selectedThread, activeServer, activeChannel, onProfile, page])

    useEffect(() => {
        appStateLog("useEffect[_activeServer]")
        setActiveServer(props._activeServer)
        setUpdateQueryString(true)
    }, [props._activeServer])

    useEffect(() => {
        appStateLog("useEffect[_activeChannel]")
        setActiveChannel(props._activeChannel)
        setUpdateQueryString(true)
    }, [props._activeChannel])

    useEffect(() => {
        appStateLog("useEffect[selectedThread]")
        setUpdateQueryString(true)
    }, [selectedThread])

    const changeActiveChannel = (channel) => {
        props.dispatch.other.setActiveChannel({
            channelId: channel,
            serverId: activeServer,
        })
        setServerChannelActivity(old => {
            let newSCA = { ...old }
            newSCA[activeServer] = channel
            return newSCA
        })
    }

    const changeActiveServer = (server) => {
        props.dispatch.other.setActiveServer(server)
    }

    const changeActiveChannelAndActiveServer = (server, channel) => {
        props.dispatch.other.setActiveServer(server)
        props.dispatch.other.setActiveChannel({
            channelId: channel,
            serverId: server,
        })
    }

    const joinServer = (server) => {
        props.dispatch.server.joinServer(server)
        setTimeout(() => {
            window.location.origin + "/chat2?s=" + server + "&p=true"
        }, 400)
    }

    useEffect(() => {
        if (props._appState.modalMenu === 'error' && props._appState.modalMenuOpen) {
            appStateLog("useEffect[Error Display]")
            props.dispatch._appState.updateAppState({
                modalMenu: 'none',
                modalMenuOpen: false,
                errorSeverity: 0
            })
            if (props._appState.errorSeverity >= 4) {
                openModal("error")
            } else {
                setDisplayErrorBanner(true)
                setTimeout(() => {
                    setDisplayErrorBanner(false)
                }, 2000)
            }
        }
    }, [props._appState])

    const push = (str) => {
        if (!videoOverlay) {
            history.push(str)
        } else {
            setDisplayErrorBanner(true)
            setTimeout(() => {
                setDisplayErrorBanner(false)
            })
        }
    }

    const enableVideoOverlay = () => {
        setVideoOverlay(true)
    }
    const disableVideoOverlay = () => {
        setVideoOverlay(false)
    }
    const cleanAppState = () => {
        setManagementPageUnderlying('settings')
    }
    const openModal = (modalName) => {
        setModal(modalName)
        setModalIsOpen(true)
    }
    const openModalWithData = (modalName, modalData) => {
        setModal(modalName)
        setModalIsOpen(true)
        setModalData(modalData)
    }
    const moveToProgressModal = (modalName, modalSuccessData) => {
        setOperationInProgress(true)
        setMonitorModal(modalName)
        setModalSuccess(modalSuccessData)
    }
    const openInfoModal = (modalName) => {
        setModal(modalName)
        setModalType("info")
        setModalIsOpen(true)
    }
    const closeModal = () => {
        setModalType(null)
        setModalIsOpen(false)
    }
    const blockModal = () => {
        setPageChangeBlocked(true)
    }

    const unblockModal = () => {
        setPageChangeBlocked(false)
        setUseBlockedFunction(false)
    }
    const useBlockModalAction = () => {
        setUseBlockedFunction(true)
    }

    const setPage = (pageName, doNotChangeMenuL) => {
        if (!pageChangeBlocked) {
            cleanAppState(doNotChangeMenuL)
            setPageUnderlying(pageName)
            setTimeout(() => {
                console.log("App State setUpdateQueryString");
                setUpdateQueryString(true)
            }, 5)
        } else {
            openModal("change")
        }
    }

    const setManagementPage = (managementPageName) => {
        if (!pageChangeBlocked) {
            cleanAppState()
            setManagementPageUnderlying(managementPageName)
        } else {
            openModal("change")
        }
    }

    const changeToThread = (serverId, channelId, threadId, doNotChangeMenuL) => {
        props.dispatch.other.setActiveServer(serverId)
        setTimeout(() => {
            props.dispatch.other.setActiveChannel({
                channelId: channelId,
                serverId: serverId,
            })
        }, 5)
        setTimeout(() => {
            setPage('threads')
            setSingleThreadPage(true)
            setSelectedThread(threadId)
        }, 10)
    }

    const clearPreviousStateData = () => {
        props.dispatch._appState.updateAppState({ profileDataLoaded: false, profileMeta: {} })
        setSelectedThread(null)
        setSingleThreadPage(false)
        if (!isGuest) {
            setPage('chat')
        }
    }

    const getProfileContent = (serverId) => {
        props.getProfileContent(serverId)
    }

    const _changeToProfilePage = (server, channel, cleanedValues) => {
        changeActiveChannelAndActiveServer(server, channel)
        setTimeout(() => {
            props.dispatch._appState.updateAppState({
                profileDataLoaded: true,
                profileMeta: { server, channel }
            })
            setPage('profile')
            setMenuLPre("profile")
        }, 50)
        setTimeout(() => {
            if (cleanedValues['t']) {
                setSelectedThread(cleanedValues['t'])
                setSingleThreadPage(true)
            }
            setQueryStringMutable(true)
            setUpdateQueryString(true)
        }, 100)
        setTimeout(() => {
            setUpdateQueryString(true)
        }, 115)

    }

    const changeToProfilePage = (server) => {
        appStateLog("Change To Profile Page")
        setQueryStringMutable(false)
        if (props.chatServers.hasOwnProperty(server)) {
            const [cleanedKeys, cleanedValues] = keyCleaner(queryString.parse(history.location.search))
            if (props.chatServers[server].serverMeta.dm) {
                const pServer = props.chatServers[server].primaryServer
                if (props.chatServers.hasOwnProperty(pServer ? pServer : "nokey")) {
                    _changeToProfilePage(pServer, props.chatServers[pServer].profileId, cleanedValues)
                } else {
                    if (pServer) {
                        getProfileContent(pServer)
                    } else {
                        _changeToProfilePage(server, props.chatServers[server].profileId, cleanedValues)
                    }
                }
            } else {
                changeActiveChannelAndActiveServer(server, props.chatServers[server].profileId)
                _changeToProfilePage(server, props.chatServers[server].profileId.profileId, cleanedValues)
            }
        } else {
            getProfileContent(server)
        }
    }

    const changeToProfilePageWithStateClear = (server) => {
        clearPreviousStateData()
        changeToProfilePage(server)
    }
    const channelButtonClicked = (channel, threadSwitch) => {
        setPage('chat')
        changeActiveChannel(channel)
        if (threadSwitch) {
            setPage('threads')
        }
        if (isMobile()) {
            setForceSidebarClosed(true)
        }
    }

    const changeOffProfilePage = () => {
        history.goBack()
    }

    const goBackHistory = () => {
        history.goBack()
    }

    const forceRedirectToAuthIfNotLoggedIn = (offAuth, elseFn) => {
        if (offAuth) {
            history.push('/auth2')
            setQueryStringMutable(true)
        } else {
            elseFn()
        }
    }
    const queryStringProcessor = (vals, init, noDelay, offAuth) => {
        appStateLog("Query String Processor]")
        clearPreviousStateData()
        setQueryStringMutable(false)
        const [cleanedKeys, cleanedValues] = keyCleaner(vals)
        if (cleanedValues['s'] === 'undefined' || cleanedValues['s'] === 'false' || cleanedValues['c'] === 'undefined') {
            setQueryStringMutable(true)
            setUpdateQueryString(true)
            return null
        }

        const timeout = noDelay ? 1 : 200
        //Load the profile.
        if (keyCheck(cleanedKeys, ['join'])) {
            // setMultiStageTransition(true)
            setTimeout(() => {
                // changeToProfilePage(cleanedValues['join'])
            }, timeout)
        }
        //Load the video-debug mode.

        if (keyCheck(cleanedKeys, ['vid'])) {
            // setMultiStageTransition(true)
            setTimeout(() => {
                // changeToProfilePage(cleanedValues['join'])
            }, timeout)
        }

        if (keyCheck(cleanedKeys, [])) {
            setMultiStageTransition(true)
            setTimeout(() => {
                changeToProfilePage(props._userId)
            }, timeout)
        }
        if (keyCheck(cleanedKeys, ['s'])) {
            setMultiStageTransition(true)
            setTimeout(() => {
                changeToProfilePage(cleanedValues['s'])
                // changeActiveServer(cleanedValues['s'])
            }, timeout)
        }

        if (keyCheck(cleanedKeys, ['s', 'ft'])) {
            setMultiStageTransition(true)
            setTimeout(() => {
                openInfoModal("welcome")
                changeToProfilePage(cleanedValues['s'])
            }, timeout)
        }

        if (keyCheck(cleanedKeys, ['s', 'p'])) {
            setMultiStageTransition(true)
            setTimeout(() => {
                changeToProfilePage(cleanedValues['s'])
                // changeActiveServer(cleanedValues['s'])
            }, timeout)
        }

        if (keyCheck(cleanedKeys, ['s', 'ft', 'p'])) {
            setMultiStageTransition(true)
            setTimeout(() => {
                openInfoModal("welcome")
                changeToProfilePage(cleanedValues['s'])
            }, timeout)
        }

        if (keyCheck(cleanedKeys, ['c', 's'])) {
            forceRedirectToAuthIfNotLoggedIn(offAuth, () => {
                setMultiStageTransition(true)
                setTimeout(() => {
                    setMenuLPre("community")
                    setPage("chat")
                    changeActiveChannelAndActiveServer(cleanedValues['s'], cleanedValues['c'])
                    setTimeout(() => {
                        setQueryStringMutable(true)
                    }, 50)

                }, timeout)
            })
        }

        if (keyCheck(cleanedKeys, ['c', 's', 'p'])) {
            setMultiStageTransition(true)
            setTimeout(() => {
                changeToProfilePage(cleanedValues['s'])
            }, timeout)
        }

        if (keyCheck(cleanedKeys, ['c', 's', 'p', 't'])) {
            setMultiStageTransition(true)
            setTimeout(() => {
                changeToProfilePage(cleanedValues['s'])
            }, timeout)
        }


        if (keyCheck(cleanedKeys, ['c', 's', 'th'])) {
            forceRedirectToAuthIfNotLoggedIn(offAuth, () => {
                setMultiStageTransition(true)
                changeActiveChannelAndActiveServer(cleanedValues['s'], cleanedValues['c'])
                setTimeout(() => {
                    setQueryStringMutable(true)
                    setMenuLPre("community")
                    setPage("threads")
                }, timeout)
            })
        }

        if (keyCheck(cleanedKeys, ['c', 's', 'th', 't'])) {
            forceRedirectToAuthIfNotLoggedIn(offAuth, () => {
                setMultiStageTransition(true)
                changeToThread(cleanedValues['s'], cleanedValues['c'], cleanedValues['t'])
                setTimeout(() => {
                    setQueryStringMutable(true)
                }, timeout)
            })
        }


    }

    useEffect(() => {
        if (props.auth.init && initLoad) {
            if (props.auth.auth) {
                if (props._loaded) {
                    queryStringProcessor(initValues, true, false, false)
                    setTimeout(() => {
                        setMultiStageTransition(false)
                    }, 1000)
                    setInitLoad(false)
                }
            } else {
                queryStringProcessor(initValues, true, false, true)
                setTimeout(() => {
                    setMultiStageTransition(false)
                }, 1000)
                setInitLoad(false)
            }
        }
    }, [props._loaded, props.auth])

    // const readyToRenderChat = props._loaded === true && page !== undefined && activeChannel && true && !initLoad && queryStringMutable
    const readyToRenderChat = props._loaded === true && page !== undefined && activeChannel && true && !initLoad

    useEffect(() => {
        return history.listen(location => {
            if (history.action === 'PUSH') {
                setLocationKeys([location.key])
            }

            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    appStateLog("App State [Back/Forward useEffect](Forward Case)")
                    setLocationKeys(([_, ...keys]) => keys)
                    queryStringProcessor(queryString.parse(location.search), false, true)
                    // Handle forward event

                } else {
                    appStateLog("App State [Back/Forward useEffect]Backward Case)")
                    setLocationKeys((keys) => [location.key, ...keys])
                    queryStringProcessor(queryString.parse(location.search), false, true)

                }
            }
        })
    }, [locationKeys,])

    const setMenuL = (newMenu) => {
        setMenuLPre(newMenu)
    }

    useEffect(() => {
        if (props._appState.directives.length > directiveIndex) {
            appStateLog("useEffect[Server Response]")
            const mostRecentDirective = props._appState.directives[props._appState.directives.length - 1]
            switch (mostRecentDirective.type) {
                case "changeToProfile":
                    changeToProfilePage(mostRecentDirective.server)
                    break;
                case "switchServerTo":
                    changeActiveServer(mostRecentDirective.server)
                    break;
                default:
                    break;
            }
            setDirectiveIndex(props._appState.directives.length)
        }
    }, [props._appState.directives])

    const ASState = {
        directives: props._appState?.directives,
        threads: {
            state: {
                selectedThread,
                singleThreadPage,
            },
            ops: {
                setSelectedThread,
                setSingleThreadPage,
            }
        },
        modals: {
            state: {
                modal,
                modalIsOpen,
                modalData,
                modalType,
            },
            ops: {
                openModal,
                openInfoModal,
                closeModal,
                blockModal,
                unblockModal,
                useBlockModalAction,
                openModalWithData,
                moveToProgressModal,
            }
        },
        video: {
            state: {
                videoStreamOptions,
                videoOverlay
            },
            ops: {
                setVideoStreamOptions,
                enableVideoOverlay,
                disableVideoOverlay,
            }
        },
        nav: {
            state: {
                activeServer,
                activeChannel,
                userIsInActiveServer: props.serverList ? props.serverList.includes(activeServer) : false,
                menuL,
                menuR,
                isGuest,
                profileDataLoaded: props._appState?.profileDataLoaded,
                dmData,
                forceSidebarClosed,
                displayErrorBanner,
                page,
                onProfile,
                hideNavBar,
                multiStageTransition,
                readyToRenderChat,
                mostRecentNonUserServer,
            },
            ops: {
                setMenuL,
                setForceSidebarClosed,
                setMenuR,
                setHideNavBar,
                setOnProfile,
                joinServer,
                setPage,
                channelButtonClicked,
                changeActiveChannel,
                changeActiveServer,
                changeToProfilePage,
                changeToProfilePageWithStateClear,
                changeOffProfilePage,
                goBackHistory,
                changeToThread,
                changeActiveChannelAndActiveServer,
                setDMData,
            }
        },
        activeServer,
        activeChannel,
        modal,
        mobileMode: isMobile(),
        managementPage,
        operationInProgress,
        useBlockedFunction,
        useBlockModalAction,
        push,
        queryStringMutable,
        pageChangeBlocked,
        setManagementPage,
    }
    appStateLog("Full State", ASState)
    return (
        <ASContext.Provider value={ASState}>
            {props.children}
        </ASContext.Provider>
    )
}
const withAS = (Component) => (props) =>
(
    <ASContext.Consumer>
        {(value) => (
            <Component
                AS={value}
                {...props}
            />
        )}
    </ASContext.Consumer>
)

const ASProvider = connect(makeMapStateToProps, mapDispatchToProps)(withAuth(connect(null, { getProfileContent })(PreASProvider)))
export { withAS, ASProvider }