import React, { useEffect, useState } from 'react'
import { RA1, RS1 } from '../../../../redux/actions.js'
import { connect } from 'react-redux'
import {
    statePropMapper,
    statePropMappers,
} from '../../../../redux/process/selectorProcess.js'
import ChannelConfigAccordion from './ChanneConfigAccordion'
import Button from '@mui/material/Button'
import { StyledDiv, TextFieldCustom, TypographyCustom } from '../../Styled'
import { dispatchMappers } from '../../../../redux/process/actionProcess'
import { SA1 } from '../../../../hs/requestGlobals.mjs'

const makeMapStateToProps = statePropMapper([
    RS1.getUserTopRank('userTopRank'),
    RS1.getServerManager('manager'),
    RS1.getChannel('channelData'),
    RS1.store('_userId', '_userId'),
])
const mapDispatchToProps = dispatchMappers([
    SA1.changeChannelPermissionsLocal,
    RA1.management.buildOldChannelPermissions,
    RA1.management.changeChannelPermissions,
])

const ChannelConfigNav = function (props) {
    const [channelName, setChannelName] = useState(null)
    const [channelTopic, setChannelTopic] = useState(null)
    useEffect(() => {
        setChannelName(props?.channelData.channelName)
        setChannelTopic(props?.channelData.channelName)
        if (props.manager?.initialized) {
            props.dispatch.management.buildOldChannelPermissions(
                props.server,
                props.channelData
            )
        }
    }, [props.manager.initialized])

    const [expanded, setExpanded] = React.useState(false)
    const [channelPermTemplate, setChannelPermTemplate] = React.useState({})
    const updateChannelData = (e) => {
        props.dispatch.server.changeChannelPermissionsLocal(
            props.server,
            props.channel,
            JSON.stringify(props.manager.channelPermissionChanges),
            channelName,
            channelTopic
        )
    }
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }
    // //#TODO: Make sure the role name Everyone is forbidden to other roles
    //props.manager?.newChannelPermissions
    if (props.manager?.newChannelPermissions) {
        let roleAccordions = []
        for (const key in props.manager.roleStruct.roleDict) {
            if (props.manager.roleStruct.roleDict.hasOwnProperty(key)) {
                if (
                    props.manager.roleStruct.roleDict[key].rank >
                    props.userTopRank
                ) {
                    roleAccordions.push(
                        <div key={key}>
                            <ChannelConfigAccordion
                                panelKey={key}
                                server={props.server}
                                channelPermTemplate={channelPermTemplate}
                                changeChannelPermissions={
                                    props.dispatch.management
                                        .changeChannelPermissions
                                }
                                channelPerms={
                                    props.manager.newChannelPermissions
                                }
                                roleData={
                                    props.manager.roleStruct.roleDict[key]
                                }
                                expanded={expanded}
                                manager={props.manager}
                                handleChange={handleChange}
                            />
                        </div>
                    )
                }
            }
        }
        return [
            <StyledDiv
                types={['sidebarTopInternalHeader', 'rightSidebarHeader']}
                className={'sidebarTopInternalHeader'}
            >
                <TypographyCustom types={['generalHeader', 'rightSidebarHeader']} variant="h4">
                    Configure Channel
                </TypographyCustom>
            </StyledDiv>

            ,

            <StyledDiv
                types={['sidebarTopInternalMiddle']}
                className={'sidebarTopInternalMiddle'}
            >
                <TypographyCustom types={['generalHeader']} variant="h6">
                    Channel Name & Topic
                </TypographyCustom>
                <TextFieldCustom
                    types={[
                        '_sidebarPaddingLeft',
                        '_sidebarPaddingRight',
                        '_sidebarPaddingTop',
                    ]}
                    id={'channelName'}
                    size={'small'}
                    disabled={false}
                    onChange={(e) => {
                        setChannelName(e.target.value)
                    }}
                    defaultValue={props.channelData.channelName}
                    label={'Channel Name'}
                />
                <TextFieldCustom
                    types={[
                        '_sidebarPaddingLeft',
                        '_sidebarPaddingRight',
                        '_sidebarPaddingTop',
                    ]}
                    id={'channelTopic'}
                    size={'small'}
                    disabled={false}
                    onChange={(e) => {
                        setChannelTopic(e.target.value)
                    }}
                    defaultValue={props.channelData.channelTopic}
                    label={'Topic'}
                />
            </StyledDiv>

            ,

            <StyledDiv
                types={['sidebarTopInternalList']}
                className={'sidebarTopInternalList'}
            >
                <TypographyCustom types={['generalHeader']} variant="h6">
                    Permissions
                </TypographyCustom>
                <TypographyCustom
                    types={['sidebarSubtitle']}
                    variant="subtitle1"
                >
                    Adjust role specific settings.
                </TypographyCustom>
                <StyledDiv types={['sidebarTopInternalScroller']}
                    style={{ marginTop: 10 }}>
                    {roleAccordions}
                </StyledDiv>
                <Button onClick={updateChannelData}>
                    Update channel data
                </Button>
            </StyledDiv>
        ]
    } else {
        return null
    }
}
export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(ChannelConfigNav)
