import InfoModal from "../index";

const SetupPaymentsModal = (props) => {
    return (
        <InfoModal title={"Calendar Guide:"} {...props}>
            Your community server is currently not stripe enabled. To offer paid appointments to your community, you
            must create a Stripe Connect account and then create a
            Calendar product. Please follow the following instructions:
            <ul>
                <li>Visit the product the product section of management page of your communtiy</li>
                <li>Enroll in Stripe</li>
                <li>Create a calendar product</li>
            </ul>
        </InfoModal>)
}
export default SetupPaymentsModal