import React, {useEffect, useRef, useState} from 'react'
import {connect} from 'react-redux'
import UserMenu from './UserMenu/index.js'
import 'react-contexify/dist/ReactContexify.css'
import {useContextMenu} from 'react-contexify'
import {statePropMapper} from '../../../../redux/process/selectorProcess.js'
import {dispatchMappers} from '../../../../redux/process/actionProcess.js'
import withReactVirtualized from '../../../_wrappers/withReactVirtualized/index.js'
import Immutable from 'immutable'
import {RA1, RS1} from '../../../../redux/actions.js'
import {SA1} from '../../../../hs/requestGlobals.mjs'
import UserLink from './UserLink/index.js'
import List from '@mui/material/List'
import {StyledDiv, TypographyCustom, ListItemTextCustom, IconButtonCustom, ListItemCustom} from '../../Styled'
import LogoutIcon from '@mui/icons-material/Logout';

const MENU_ID = 'userMenu'

const makeMapStateToProps = statePropMapper([
    RS1.getServerRoles('serverRoles'),
    RS1.getUserBooleanPerm('isModerator', ['isModerator']),
    RS1.getUserBooleanPerm('isAdmin', ['isAdmin']),
    RS1.getUserBooleanPerm('canManageRoles', ['canManageRoles']),
    RS1.getUserMeta('userMeta'),
    RS1.getUserTopRank('userTopRank'),
    RS1.getChannelWindow('channelWindow'),
    RS1.getUserTopRank('userTopRank'),
    RS1.getUsersWithRole('users'),
    RS1.getServerAbstractRoles('serverAbstractRoles'),
    RS1.getUser('user'),
    RS1.store('allUsers', 'users'), //#TODO: Clean this up?
    RS1.getChannel('channelData'),
    RS1.getServer('serverData'),
])

const mapDispatchToProps = dispatchMappers([
    SA1.kickUser,
    SA1.updateSession,
    SA1.addFriend,
    SA1.banUser,
    SA1.addRoleToUser,
    SA1.removeRoleFromUser,
])

const VirtualizedUserLinkList = withReactVirtualized(UserLink)

function UserNav(props) {
    const [init, setInit] = useState(false)
    const [initCount, setInitCount] = useState(false)
    const [userList, setUserList] = useState([])
    const [selectedUser, setSelectedUser] = useState(props.user)
    const heightDivRef = useRef(null)
    const {show} = useContextMenu({
        id: MENU_ID,
    })

    function handleItemClick(event, data, command, other) {
        switch (command) {
            case 'kick':
                props.dispatch.server.kickUser(data.userId, data.server)
                break
            case 'ban':
                props.dispatch.server.banUser(data.userId, data.server)
                break
            case 'addRoleToUser':
                props.dispatch.server.addRoleToUser(
                    data.userId,
                    other,
                    data.server
                )
                break
            case 'viewProfile':
                props.dispatch.server.updateSession(
                    'loadUsersProfilePage',
                    data.userId,
                    props.allUsers[data.userId].profileId,
                    null,
                    '',
                    ''
                )
                break
            case 'removeRoleFromUser':
                props.dispatch.server.removeRoleFromUser(
                    data.userId,
                    other,
                    data.server
                )
                break
            case 'addFriend':
                props.dispatch.server.addFriend(
                    data.userId,
                    data.userId,
                    false,
                    false
                )
                break
            case 'block':
                props.dispatch.server.addFriend(
                    data.userId,
                    data.userId,
                    true,
                    false
                )
                break
        }
    }

    function displayMenu(e, userProps) {
        if (userProps.type !== "category") {
            setSelectedUser(userProps.userData)
            show(e)
        }
    }

    function buildUserList() {
        let serverRoles = props.serverAbstractRoles
        let userList = []
        serverRoles.forEach((serverRole) => {
            userList.push({
                type: 'category',
                id: serverRole.id,
                name: serverRole.role,
                roleData: serverRole,
            })
            props.users[serverRole.id].filter((userData) => {
                if (userData.status !== "offline") {
                    if (
                        props.channelData.viewableToRoles.some((r) =>
                            userData.roles.includes(r)
                        )
                    ) {
                        userList.push({
                            type: 'user',
                            userData: userData,
                            userId: userData.id,
                        })
                    } else {
                        return false
                    }
                }
            })
        })
        userList.push({
            type: 'category',
            id: "0",
            name: "Offline",
            roleData: "0",
        })
        serverRoles.forEach((serverRole) => {
            props.users[serverRole.id].filter((userData) => {
                if (userData.status === "offline") {
                    if (
                        props.channelData.viewableToRoles.some((r) =>
                            userData.roles.includes(r)
                        )
                    ) {
                        userList.push({
                            type: 'user',
                            userData: userData,
                            userId: userData.id,
                        })
                    } else {
                        return false
                    }
                }
            })
        })
        userList = userList.filter((role, index) => {
            if (index < userList.length - 1) {
                if (role.type === "category" && userList[index + 1].type === "category") {
                    return false
                } else {
                    return role
                }
            } else {
                return role
            }

        })
        return Immutable.List(userList)
    }

    const buildUserListInit = () => {
        if (!init && props._loaded) {
            let scopedUserList = buildUserList()
            setUserList(scopedUserList)
            setInit(true)
        }
    }
    const buildUserListVer = () => {
        let scopedUserList = buildUserList()
        setUserList(scopedUserList)
        setInit(true)
        setInitCount(1)
    }

    useEffect(buildUserListInit, [props._loaded, init])
    useEffect(buildUserListVer, [props.serverData.version])

    useEffect(() => {
        let interval = setInterval(() => {
            buildUserListVer()
        }, 1000);
        return () => clearInterval(interval);
    }, [buildUserListVer, props.users]);


    useEffect(() => {
        if (initCount < 3) {
            setInitCount(initCount + 1)
        }
    }, [initCount])
    //Probably a memory leak with the menu here -
    if (init) {
        return [
            // <StyledDiv
            //     key={"U1A"}
            //     types={['sidebarTopInternalHeader', 'rightSidebarHeader']}
            //     className={'sidebarTopInternalHeader'}
            // >
            //     <TypographyCustom
            //         types={['generalHeader', 'rightSidebarHeader']}
            //         variant="h5">
            //     </TypographyCustom>
            // </StyledDiv>

            ,

            <StyledDiv
                key={"U2A"}
                types={['sidebarTopInternalList']}
                className={'sidebarTopInternalList'}
            >
                <UserMenu
                    menuId={MENU_ID}
                    abstractRoles={props.serverAbstractRoles}
                    handleItemClick={handleItemClick}
                    server={props.server}
                    userId={selectedUser.id}
                    isModerator={props.isModerator}
                    isAdmin={props.isAdmin}
                    canManageRoles={props.canManageRoles}
                    clientUserMeta={props.userMeta}
                    clientTopRank={props.userTopRank}
                />
                <div ref={heightDivRef} style={{height: '80dvh'}}>
                    <StyledDiv ref={heightDivRef} types={['sidebarTopInternalScroller']}>
                        <List sx={{paddingTop: 0, paddingBottom: 0, overflow: 'hidden'}} style={{overflow: 'hidden'}}>
                            <VirtualizedUserLinkList
                                listData={userList}
                                height={heightDivRef?.current?.clientHeight}
                                selectItem={displayMenu}
                            />
                        </List>
                    </StyledDiv>
                </div>
            </StyledDiv>
        ]
    } else {
        return null
    }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(UserNav)
