export const ListItemIconCustomStyle = (mode, palette) => ({
    ListItemIconCustom: {
        defaultProps: {},
        styleOverrides: {"nothing": {}}, altStyleOverrides: {
            root: {},
            channel: {
                justifyContent: 'flex-end',
                color: 'inherit',
                // color: palette.text.flairSecondary,
                '&:hover': {color: palette.text.flair},
                '& .MuiSvgIcon-root': {transform: 'scale(.7)'}
            },

            subscriptionCancel: {
                flex: '1 0 20%',
                justifyContent: 'flex-end',
                color: palette.text.secondary,
                '&:hover': {color: 'rgb(255,255,255,.6)'},
                '& .MuiSvgIcon-root': {transform: 'scale(.7)'}
            },
            server: {},
            user: {},
        },
    },
})
