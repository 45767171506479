import React from 'react'
import { useDropzone } from 'react-dropzone'
import { useSendFile } from '../../../hs/hooks'

const withContentDropzone = (mode) => (WrappedComponent) => (props) => {

    const [sendFiles, storeFile, clearFiles, hasFiles, blobLinks] = useSendFile(
        props.user,
        props.storeLocation,
        props.imageStore,
        props.dispatch.imageStore.addImage,
        props.dispatch.imageStore.clear,
        props.dispatch.client.error
    )
    const onDrop = (acceptedFiles) => {
        if (mode === 'chat') {
            storeFile(acceptedFiles[0], acceptedFiles[0].name)
        } else {
            storeFile(acceptedFiles[0], acceptedFiles[0].name)
        }
    }
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    })
    return (
        <div style={{ height: '100%', width: '100%' }} {...getRootProps()}>
            <input disabled={true} {...getInputProps()} />
            <WrappedComponent
                sendFiles={sendFiles}
                clearFiles={clearFiles}
                hasFiles={hasFiles}
                storeFile={storeFile}
                blobLinks={blobLinks}
                {...props}
            />
        </div>
    )
}

export default withContentDropzone
