
import React, { useState, useRef } from "react"
import { StyledDiv, IconButtonCustom } from "../../../Styled";
import { useTheme } from '@mui/material';

import './Fan.css'

// image
import ImageIcon from '@mui/icons-material/Image';
// blog post / thread,
import LayersIcon from '@mui/icons-material/Layers';
// time line post,
import FeedIcon from '@mui/icons-material/Feed';
// subscriptions,
import LoyaltyIcon from '@mui/icons-material/Loyalty';
// calendar availability
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export const CreateFan = function (props) {
    const theme = useTheme()

    const handleClick = event => {
        props.fileInputRef.current.click();
    };

    // const handleImageButton = event => {
    //     const fileUploaded = event.target.files[0];
    //     handleFile(fileUploaded);
    // };

    return (
        <div className={"fanContainer" +
            (props?.fanMenu
                ? " animateFanMenuOpacity" : "")}>

            <div className={"mobileMockModal" +
                (props?.fanMenu
                    ? " animateMockModal" : "")} />

            <div style={{
                textAlign: 'center',
                position: 'absolute',
                zIndex: '200',
                height: '100dvh',
                bottom: '0',
                width: '100vw'
            }}>
                <div id="fanmenu">

                    <IconButtonCustom
                        className={"fan-menu-item fan3 " +
                            (props?.fanMenu
                                ? "doAnimation3" : "")}
                        types={['fanMenuItem', props?.fanMenu ? "doAnimation3" : ""]}
                        // onClick={handleClick}
                        onClick={props.handleImgClick}

                        style={{
                            backgroundColor: theme.palette.text.flair,
                            color: theme.palette.text.primary,
                            minHeight: 'fit-content',
                        }}>
                        <ImageIcon style={{ color: 'inherit' }} />
                        <input
                            type="file"
                            ref={props.fileInputRef}
                            style={{ display: 'none' }}
                            onChange={(e) => props.onFile(e)}
                            accept="image/*" />

                    </IconButtonCustom>

                    <IconButtonCustom
                        className={"fan-menu-item fan4 " +
                            (props?.fanMenu
                                ? "doAnimation4" : "")}
                        types={['fanMenuItem', props?.fanMenu ? "doAnimation4" : ""]}
                        style={{
                            backgroundColor: theme.palette.text.flair,
                            color: theme.palette.text.primary,
                            minHeight: 'fit-content',
                        }}
                        onClick={() => { }}>
                        <FeedIcon style={{ color: 'inherit' }} />
                    </IconButtonCustom>

                    <IconButtonCustom
                        className={"fan-menu-item fan5 " + (props?.fanMenu ? "doAnimation5" : "")}
                        types={['fanMenuItem', props?.fanMenu ? "doAnimation5" : ""]}
                        style={{
                            backgroundColor: theme.palette.text.flair,
                            color: theme.palette.text.primary,
                            minHeight: 'fit-content',
                        }}
                        onClick={() => { }}>
                        <LayersIcon style={{ color: 'inherit' }} />
                    </IconButtonCustom>

                    <IconButtonCustom
                        className={"fan-menu-item fan6 " + (props?.fanMenu ? "doAnimation6" : "")}
                        types={['fanMenuItem', props?.fanMenu ? "doAnimation6" : ""]}
                        style={{
                            backgroundColor: theme.palette.text.flair,
                            color: theme.palette.text.primary,
                            minHeight: 'fit-content',
                        }}
                        onClick={() => { }}>
                        <LoyaltyIcon style={{ color: 'inherit' }} />
                    </IconButtonCustom>

                    <IconButtonCustom
                        className={"fan-menu-item fan7 " + (props?.fanMenu ? "doAnimation7" : "")}
                        types={['fanMenuItem', props?.fanMenu ? "doAnimation7" : ""]}
                        style={{
                            backgroundColor: theme.palette.text.flair,
                            color: theme.palette.text.primary,
                            minHeight: 'fit-content',
                        }}
                        onClick={() => { }}>
                        <CalendarMonthIcon style={{ color: 'inherit' }} />
                    </IconButtonCustom>
                </div>
            </div>
        </div>
    )

}