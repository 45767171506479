
export const testData = () => {

    // props.product.productMeta.metaString = rolename
    // props.name
    // props.desc
    // props.icon?
    // props.price

    let product = {
        "id": "592899875497293824",
        "productMeta": {
            "metaString": "Subbed",
            "roleId": "592899875417602048"
        },
        "name": "Subscribe!name",
        "desc": "sub 4 accela ahits dtsnad aeud da",
        "icon": "aa",
        "type": "subscription",
        "price": "499"
    }
    let PROPS = {
        "serverData": {
            "dm": false,
            "stripeEnabled": true,
            "serverMeta": {
                "dm": false
            },
            "name": "uiserv",
            "id": "591616397794440192",
            "ownerId": "591616193875768320",
            "version": 9,
            "users": [
                {
                    "id": "591616193875768320",
                    "primaryRole": "591616397794440199",
                    "roles": [
                        "591616397794440199"
                    ]
                }
            ],
            "hiddenId": "591616397794440197",
            "avatarId": "none",
            "logId": "591616397794440193",
            "emoji": [],
            "generalId": "591616397794440196",
            "profileId": "591616397794440198",
            "notificationsId": "591616397794440195",
            "configId": "591616397794440194",
            "roles": [
                "591616397794440199",
                "591616397794440200",
                "591616397794440201",
                "592899875417602048",
                "591616397794440202"
            ],
            "channels": [
                "591616397794440193",
                "591616397794440194",
                "591616397794440195",
                "591616397794440196",
                "591616397794440197",
                "591616397794440198"
            ],
            "profile": "",
            "userMetas": [
                "591616193875768320_591616397794440192"
            ]
        },
        "userId": "591616193875768320",
        "server": "591616397794440192",
        "userSubscriptions": [],
        "subscriptions": [
            {
                "id": "592899875497293824",
                "productMeta": {
                    "metaString": "Subbed",
                    "roleId": "592899875417602048"
                },
                "name": "Subscribe!",
                "desc": "sub 4 accela",
                "icon": "aa",
                "type": "subscription",
                "price": "499"
            }
        ],
        "_appState": {
            "alerts": 0,
            "recentServers": [
                "591616397794440192"
            ],
            "modalType": "none",
            "updateId": 18,
            "modalMenu": "none",
            "openMenu": "chat",
            "modalMenuOpen": false,
            "pageChangeBlocked": false,
            "blockedModal": "none",
            "roleStruct": {},
            "catalog": [
                {
                    "id": "592437850657836032",
                    "name": "Test Appt.",
                    "desc": "This is a test appt.",
                    "icon": "aa",
                    "type": "calendar",
                    "price": "1519"
                },
                {
                    "id": "592899875497293824",
                    "productMeta": {
                        "metaString": "Subbed",
                        "roleId": "592899875417602048"
                    },
                    "name": "Subscribe!",
                    "desc": "sub 4 accela",
                    "icon": "aa",
                    "type": "subscription",
                    "price": "499"
                }
            ],
            "profileMeta": {
                "channel": "591616397794440198",
                "server": "591616397794440192"
            },
            "profileDataLoaded": true
        },
        "_userSettings": {
            "transactions": []
        },
        "dispatch": {
            "server": {}
        }
    }

    return { PROPS, ...product }
}