import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import EzVirtualizedList from '../../../../_reusable/EzVirtualizedList'
import { parseMessage } from '../../../ChatWindow/_lib/messageParser'
import { ChatMessageCustom } from '../../../../Styled'
import { ListItemCustom, TypographyCustom } from '../../../Styled'

const makeRenderRowMessage = (users, loadMessageOp) => {
    return function renderRowMessage(props) {
        let messageData = parseMessage(props.data[props.index].message)
        let messageStyling = messageData.styling
        let messageUrls = messageData.urls
        let user = users[props.data[props.index].uId]

        return (
            <ListItemCustom
                // types={[""]}
                // style={props.style}
                key={props.index}
                component="div"
                disablePadding
                onClick={() => {
                    loadMessageOp(props.data[props.index].mId)
                }}
            >
                <ChatMessageCustom
                    oldStyle={{}}
                    server={props.server}
                    message={props.data[props.index].message}
                    hightlightMessage={false}
                    messageStyling={messageStyling}
                    urls={messageUrls}
                    user={user}
                />
            </ListItemCustom>
        )
    }
}

const SearchResults = (props) => {
    const renderRowMessage = makeRenderRowMessage(
        props.users,
        props.loadMessageOp
    )
    const MessageList = EzVirtualizedList(renderRowMessage)

    //TODO: back button goes to previous block of msges, more button goes to next block
    return (
        <div>
            <div>Searched for {props.submittedValue}</div>
            <MessageList
                listHeight={500}
                listWidth={200}
                itemSize={100}
                data={props.messages}
            />
            <Button
                onClick={() => {
                    props.setMode('search')
                }}
            >
                Back
            </Button>
            <Button
                onClick={() => {
                    props.setMode('search')
                }}
            >
                More
            </Button>
        </div>
    )
}

const SearchDisplay = (props) => {
    const [viewMode, setViewMode] = useState('placeholder')

    useEffect(() => {
        if (props.mode === 'search') {
            setViewMode('placeholder')
            props.setSearchBarDisabled(false)
        } else if (props.messages) {
            setViewMode('searchresults')
            props.setSearchBarDisabled(false)
        } else {
            setViewMode('waiting')
            props.setSearchBarDisabled(true)
        }
    }, [props.mode, props.messages])

    switch (viewMode) {
        case 'placeholder':
            return (
                <TypographyCustom types={['communitySubtext']} variant="h6">
                    TODO: put smth here eventually, there's probably a feature we
                    can think of (common searches, which will be a paid feature?)
                </TypographyCustom>
            )
        case 'searchresults':
            return (
                <div>
                    <SearchResults
                        setMode={props.setMode}
                        submittedValue={props.submittedValue}
                        messages={props.messages}
                        users={props.users}
                        loadMessageOp={props.loadMessageOp}
                    />
                </div>
            )
        case 'waiting':
            return (
                <TypographyCustom types={['communitySubtext']} variant="h6">
                    TODO: put smth here eventually, there's probably a feature we
                    can think of (common searches, which will be a paid feature?)
                </TypographyCustom>
            )
    }
}

export default SearchDisplay
