import { PaperCustom, StyledDiv, TypographyCustom, vDH } from "../../Styled";
import SubscriptionList from "../../SubscriptionList";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withAS } from "../../../_contexts/withAppState";
import { dispatchMappers } from "../../../../redux/process/actionProcess";
import { RA1, RS1 } from "../../../../redux/actions";
import { statePropMapper } from "../../../../redux/process/selectorProcess";
import Box from "@mui/system/Box";
import { Tab, Tabs } from "@mui/material";
import CalendarChat from "../../ChatCalendar";
import { BottomRightHover } from "../../../_design/BottomHover";

const mapDispatchToProps = dispatchMappers([])
const makeMapStateToProps = statePropMapper([
    RS1.store('_userId', '_userId'),
    RS1.store('chatServers', 'chatServers'),
])

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const SubscriptionsNav = (props) => {
    const [tabValue, setTabValue] = useState(0)
    const serverData = props.chatServers[props.AS.activeServer]
    const isOwner = serverData.ownerId === props._userId
    let isDM = serverData.serverMeta.dm
    let isMember = false
    if (!props.AS.nav.state.isGuest) {
        isMember = serverData.users.map((user) => user.id).includes(props.userId)
    }
    useEffect(() => {
        setTabValue(props?.mobileNavTabValue)
    }, [props?.mobileNavTabValue])
    return (
        // <PaperCustom elevation={2} types={['sidebarPaper', 'microblogPart']}>
        <StyledDiv types={['microblogPart']}>
            {!isDM ?
                <div style={{
                    height: ((window.screen.width / window.screen.height) < (4 / 3))
                        ? 'calc(99dvh - 7em)'
                        : '99vh',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TypographyCustom
                                style={{ paddingLeft: "10px", paddingTop: "10px", }}>Features
                            </TypographyCustom>
                            <Tabs value={tabValue} onChange={(event, newValue) => setTabValue(newValue)}
                                aria-label="community features">
                                <Tab label="Upcoming Availabilities"
                                    sx={{ textTransform: "none" }} {...a11yProps(0)} />
                                <Tab label="Subscriptions"
                                    sx={{ textTransform: "none" }} {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                    </Box>
                    {/* TODO: IMPORTANT: MANUAL CALENDAR MNGMT */}


                    {tabValue === 0 ? <>
                        <CalendarChat serverId={props.AS.activeServer}
                            isMember={isMember}
                            isGuest={props.AS.nav.state.isGuest}
                            isOwner={isOwner}
                            openModalMenu={props.AS.modals.ops.openModal} />
                        <BottomRightHover>
                            <div />
                        </BottomRightHover>
                        <div />
                        {/* {!isOwner ? null :
                                    <Fab
                                        color="primary"
                                        aria-label="add"
                                        onClick={() => props.openModalMenu('gptAppointment')}
                                    >
                                        <AddIcon/>
                                    </Fab>} */}
                    </> : null}
                    {tabValue === 1 ?
                        <SubscriptionList
                            serverData={serverData}
                            userId={props.userId}
                            isOwner={isOwner}
                            openModalWithData={props.AS.modals.ops.openModalWithData}
                            server={props.AS.activeServer} /> : null}

                </div> : null}
        </StyledDiv>
        // </PaperCustom>
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(SubscriptionsNav))