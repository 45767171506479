import { react, useState } from 'react'
import { statePropMapper } from '../../../../redux/process/selectorProcess'
import { connect } from 'react-redux'
import { RA1, RS1 } from '../../../../redux/actions'
import { IconButtonCustom, vDH } from '../../Styled'
import { dispatchMappers } from '../../../../redux/process/actionProcess'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTheme } from '@mui/material'

const makeMapStateToProps = statePropMapper([])

const mapDispatchToProps = dispatchMappers([RA1._appState.closeModalMenu])

const ThreadImageViewer = function (props) {
    const [imgIndex, setImgIndex] = useState(props.AS.modals.state.modalData.imgIndex)
    const theme = useTheme()
    const decImgIndx = () => {
        setImgIndex(imgIndex - 1)
    }

    const incImgIndx = () => {
        setImgIndex(imgIndex + 1)
    }

    // TODO: button icons are not styled properly
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
        }}>
            <div style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginLeft: ((window.screen.width / window.screen.height) < (4 / 3)) ? "-50vw" : "-30vw",
            }}>
                {imgIndex != 0 &&
                    <IconButtonCustom
                        color="primary"
                        size={'large'}
                        onClick={decImgIndx}>
                        <NavigateBeforeIcon style={{ transform: 'scale(1.8)' }} />
                    </IconButtonCustom>
                }
            </div>

            <img style={{
                maxWidth: "80vw",
                maxHeight: ((window.screen.width / window.screen.height) < (4 / 3)) ? '70dvh' : '100vh',
                border:
                    theme.palette?.thread?.border &&
                    `2px solid ${theme.palette.text.flair}`,
            }} src={props.AS.modals.state.modalData?.images[imgIndex]} />

            <div style={{
                position: "absolute",
                top: "50%",
                right: "50%",
                marginRight: ((window.screen.width / window.screen.height) < (4 / 3)) ? "-50vw" : "-30vw",
            }}>
                {imgIndex != props.AS.modals.state.modalData?.images.length - 1 &&
                    <IconButtonCustom
                        color="primary"
                        size={'large'}
                        onClick={incImgIndx}>
                        <NavigateNextIcon style={{ transform: 'scale(1.8)' }} />
                    </IconButtonCustom>
                }
            </div>

        </div >
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(ThreadImageViewer)
