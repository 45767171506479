import { createSlice } from '@reduxjs/toolkit'

const cacheMonitor = createSlice({
    name: 'cacheMonitor',
    initialState: [],
    reducers: {
        updateCache: (state, action) => {
            cache.update(action)
            return [...state, action.payload]
        },
        updateCacheVersions: (state, action) => {
            cache.versionUpdate(action)
            return [...state, action.payload]
        },
    },
})

export const cacheMonitorSlice = cacheMonitor

export const cacheMonitorActions = cacheMonitor.actions
export const updateCache = cacheMonitor.actions.updateCache
export const updateCacheVersions = cacheMonitor.actions.updateCacheVersions
export const cacheMonitorReducer = cacheMonitor.reducer
