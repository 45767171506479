export const preprocessImage = async (imageUrl) => {
    const image = new Image()
    image.src = imageUrl
    return new Promise((resolve, reject) => {
        image.onload = function (ev) {
            //window.URL.revokeObjectURL(props.manager.imageUrl); // release memory
            const canvas = document.createElement('canvas')
            let max_size = 200 // TODO : pull max size from a site config
            canvas.width = max_size
            canvas.height = max_size
            const ctx = canvas.getContext('2d')
            ctx.drawImage(image, 0, 0, max_size, max_size)
            let newImageFile
            canvas.toBlob(function (blob) {
                resolve(blob)
            })
        }
    })
}
