import React from 'react'
import { ButtonCustom } from '../../../../../Chat/Styled'
import { StyledDiv } from '../../../../../Chat/Styled'
import { dispatchMappers } from '../../../../../../redux/process/actionProcess'
import { RS1, RA1 } from '../../../../../../redux/actions'
import { connect } from 'react-redux'
import { statePropMapper } from '../../../../../../redux/process/selectorProcess'
import { embedProps, useTestData } from '../../util'
import { testData } from './test'

const makeMapStateToProps = statePropMapper([
    RS1.store('_activeChannel', '_activeChannel'),
    RS1.getUser('user')
])

const computedTestData = testData()

const UserDataLayer = (props) => {
    // #
    let data = {
        id: props?.userData?.id,
        status: props?.user?.status,
        name: props?.userData?.userName,
        avatar: props?.user?.pictureId,
        flair: props?.user?.flair,
        userColor: props?.userData?.color,
    }

    data = useTestData(props, data, computedTestData)

    return embedProps(props, data)
}

export default connect(makeMapStateToProps, null)(UserDataLayer)
