import React from 'react'
import { ButtonCustom } from '../../../../../Chat/Styled'
import { StyledDiv } from '../../../../../Chat/Styled'
import { dispatchMappers } from '../../../../../../redux/process/actionProcess'
import { RS1, RA1 } from '../../../../../../redux/actions'
import { connect } from 'react-redux'
import { statePropMapper } from '../../../../../../redux/process/selectorProcess'
import { embedProps, useTestData } from '../../util'
import { testData } from './test'
import moment from "moment";

const computedTestData = testData()

const makeMapStateToProps = statePropMapper([
    RS1.store('_activeChannel', '_activeChannel'),
])

const MessageDataLayer = (props) => {

    let fullMessage
    if (props?.componentProps) {
        fullMessage = props.componentProps
    } else if (props?.message) {
        fullMessage = props.message
    } else {
        // TODO: TEST CODE REMOVE: change above else if to 'else', remove this default condition.
        fullMessage = computedTestData.message
    }

    let fullMessageDate = moment(fullMessage?.createdAt).format(
        'MMMM Do YYYY, h:mm:ss a'
    )

    let data = {
        message: fullMessage,
        fullMessageDate: fullMessageDate,
        messageStyling: fullMessage?.data?.styling,
        user: props.user,
        product: props.product,
        innerColumnsStyle: props.innerColumnsStyle,
        urls: fullMessage?.data?.urls,
        replies: props.replies,
        reactions: props.reactions,
        highlights: props.highlights,
        quotes: props.quotes,
        blogPost: fullMessage.blogPost,
        push: props.push,
        setPage: props.setPage,
        shiftToMessage: props.shiftToMessage,
        thread: fullMessage.thread,
        files: props?.message?.files,
        timelineOps: props.timelineOps,
        threadsArray: props.threadsArray,
        threadsWatched: props.threadsWatched,
        selectedThread: props.selectedThread,
        tId: fullMessage.tId,
        threadMsgs: props.threadMsgs,
        lastReply: props.lastReply,
        watchThatThread: props.watchThatThread,
        isReply: props?.isReply,
        likedByUser: props?.likedByUser,
        replyToMessage: props?.replyToMessage,
        replyClicked: props?.replyClicked,
        openImageViewer: props?.openImageViewer,
        theme: props?.theme,
    }

    data = useTestData(props, data, computedTestData)

    return embedProps(props, data)
}

export default connect(makeMapStateToProps, null)(MessageDataLayer)
