import React from 'react'

import RepeatIcon from '@mui/icons-material/Repeat'
import AddReactionIcon from '@mui/icons-material/AddReaction'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import LayersIcon from '@mui/icons-material/Layers'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'

import Tooltip from '@mui/material/Tooltip'
import ThreadCardMini from '../ThreadCardMini/ThreadCardMini'
import { ImageList, ImageListItem } from '@mui/material';

import {
    StyledDiv,
    AvatarCustom,
    IconButtonCustom
} from '../../../Chat/Styled'
import { getUserColors } from "../../../../hooks";
import { withAS } from "../../../_contexts/withAppState";

export const embedProps = (props, data) => {
    // # check to see if data satisfies requirements:

    // Object.keys(data).map((key) => {
    //     if (!Object.keys(props.requirements).includes(key)) {
    //         // # ERROR
    //     }
    // })

    // #
    return React.cloneElement(props.children, { displayMode: props.displayMode, ...data })
}

export const useTestData = (props, data, testData) => {
    if (props.cardTestMode) return testData
    return data
}

const AvatarContainerPre = (props) => {
    return (
        <StyledDiv types={['singleLineDiv',
            props?.chatmsg && !props?.fullmsg ? 'chatCardAvi' : '',
            props?.blogPost ? 'blogPostImg' : '',
            props?.chatmsg && props?.message?.thread && props?.fullmsg ? 'chatAviThreadMargin' : '']}>
            <IconButtonCustom onClick={() => {
                props.AS.nav.ops.changeToProfilePageWithStateClear(props.message.uId)
            }} style={{ width: 'fit-content', height: 'fit-content' }}>
                <AvatarCustom
                    types={[
                        props?.desktop ? 'timelineAvatarDesktop' : 'timelineAvatar',
                        props?.blogPost ? 'blogPostImg' : null,
                        props?.chatmsg && props?.desktop ? 'smallAvatar' : '']}
                    variant={'circle'}
                    alt={
                        props.user?.userName
                            ? props.user?.userName
                            : props.message.uId
                    }
                    src={props.user?.pictureId}
                />
            </IconButtonCustom>
        </StyledDiv>
    )
}
export const AvatarContainer = withAS(AvatarContainerPre)

export const MessageHeader = (props) => {
    let color = getUserColors(props?.user?.color)
    return (
        <StyledDiv types={[
            props.chatmsg ? 'chatMessageHeader' : 'timelineMessageHeader'
        ]} blogPost={props?.blogPost}>
            <StyledDiv types={[
                props.chatmsg ? 'chatMsgUsername' : 'timelineUsername',
                props.compressed ? 'compressedHeader' : null,
                props.blogPost ? 'blogPostUsername' : null]}>
                <b style={{
                    color: color,
                    fontSize: props.desktop ? '16px' : '12px'
                }}>
                    {props.user?.userName
                        ? props.user.userName + ' '
                        : props.message.uId + ' '}
                </b>
            </StyledDiv>
            <StyledDiv types={[props?.chatmsg ? 'chatMsgDate' : 'timelineDate']} style={{
                lineHeight: props?.blogPost ? '1.3' : '1.5',
                marginBottom: props?.chatmsg
                    ? props.desktop
                        ? '-3px'
                        : '-3.5px'
                    : props.desktop
                        ? '-4px'
                        : '-4.5px',
            }}>
                <span style={{
                    fontSize: props.desktop ? '11px' : '8.25px',
                }}>
                    {'(' + props.fullMessageDate + ')'}
                </span>

                &nbsp;&nbsp;&nbsp;&nbsp;
                {props.chatmsg && props.message.thread && props?.desktop ?
                    // TODO: props.desktop is temporary - undecided if we should include the thread button on mobile
                    <Tooltip placement="top"
                        title={(props.fullMsg && props.desktop) ?
                            <ThreadCardMini
                                threadId={props.message.tId}
                                server={props.message.sId}
                                channel={props.message.cId} />
                            : null}>
                        <span>
                            <IconButtonCustom
                                aria-label={'bla'}
                                types={['threadConnector']}
                                size="small"
                                onClick={() => {
                                    props.push('/chat2?gotoThread=' + props.message.tId)
                                    props.setPage('threads')
                                }}
                                sx={{
                                    transform: 'scale(0.8)',
                                }}>
                                <LayersIcon />
                            </IconButtonCustom>
                        </span>
                    </Tooltip> :
                    ''}
            </StyledDiv>
        </StyledDiv>
    )
}

export const ImageContainer = function (props) {
    let src = ''
    let alt = ''

    if (props.files?.length > 0) {
        src = props.files[0]

    } else if (props.urls.length !== 0 && props.urls[0].image) {

        alt = props.urls[0].url
        src = props.urls[0].url
    } else {
        return null
    }

    if (props.files?.length > 0 && props?.chatmsg && props?.fullmsg && props?.desktop) {

        let items = [];

        for (let i = 0; i < props.files.length; i++) {
            if (props.files.length === 1) {
                items.push(
                    <ImageListItem key={props.files[i]} cols={4} rows={3}>
                        <img src={props.files[i]} />
                    </ImageListItem>
                )
            } else {
                if (i === 0) {
                    items.push(
                        <ImageListItem key={props.files[i]} cols={2} rows={3}>
                            <img src={props.files[i]} />
                        </ImageListItem>
                    )
                } else {
                    items.push(
                        <ImageListItem key={props.files[i]} cols={2} rows={1}>
                            <img src={props.files[i]} />
                        </ImageListItem>
                    )
                }
            }
        }

        return (
            <ImageList sx={{ width: 550, height: 400 }} variant="quilted" cols={5} rowHeight={130}>
                {items}
            </ImageList>
        )
    }

    if (props.half) {
        return <img alt={alt} src={src} />
    }

    return (
        <StyledDiv types={['timelineImg']}>
            <StyledDiv types={['cardImgContainer']}>
                <img alt={alt} src={src} />
            </StyledDiv>
        </StyledDiv>
    )
}

export const ThreadImageContainer = function (props) {
    const limited = {
        maxWidth: '7.5vw', //151.344 x 113.641
        maxHeight: '15vw'
    }
    const unlimited = {
        maxWidth: '100%',
        maxHeight: 'auto',
        objectFit: 'contain',
    }
    const imgStyle = {
        border:
            props.theme.palette?.thread?.border &&
            `1px solid ${props.theme.palette.text.flair}`,
        borderRadius: !props.theme.palette?.thread?.border && '15px 0px 15px 0px',

        width: '100%',
        height: '100%',
    }

    if (props.files?.length > 0) {
        //     border: props.theme.palette?.thread?.border && `1px solid ${props.theme.palette.text.flair}`
        return (
            <div style={props?.useUnlimited ? unlimited : limited}>
                <img
                    style={props?.useUnlimited ? {
                        ...unlimited,
                        ...imgStyle
                    } : {
                        ...limited,
                        ...imgStyle
                    }}
                    onClick={props.imageClicked}
                    src={props.files[0]} //TODO: TEMP
                />
            </div>
        )
    } else {
        return null
    }
}

export const ChatReplyHeader = function (props) {
    return (
        // <div className="reply-div recolor-item" style={{
        //     color: '#6A6A6A',
        //     whiteSpace: 'nowrap',
        //     textOverflow: 'ellipsis',
        //     overflow: 'hidden'
        // }}>
        <div className="" style={{
            color: '#6A6A6A',
            height: "1.5em",
            // whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        }}>
            <b style={{ color: '#8A8A8A' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌───&nbsp;
            </b>
            {!props.deleted ? (
                <b style={{ color: '#8A8A8A' }}>{props.userName}</b>
            ) : null}
            {props.deleted ? <b style={{ color: '#8A8A8A' }}>⚉</b> : null}
            <b>&nbsp;</b>
            <span style={{ color: '#6A6A6A' }}>{props.message}</span>
            {props.edited ? (
                <small style={{ color: '#5A5A5A' }}>&nbsp;&nbsp;(...)</small>
            ) : null}
        </div>
    )
}

export const ReplyHeader = function (props) {
    return (
        <StyledDiv types={['recolorItem']} className="reply-div"
            onClick={() => {
                // props.replyClicked(props.messageObj?.reply?.mData)
            }}>
            <b style={{ color: '#8A8A8A' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌───&nbsp;
            </b>
            {!props.deleted ? (
                <b style={{ color: '#8A8A8A' }}>{props.userName}</b>
            ) : null}
            {props.deleted ? <b style={{ color: '#8A8A8A' }}>⚉</b> : null}
            <b>&nbsp;</b>
            <span style={{ color: '#6A6A6A' }}>{props.message}</span>
            {props.edited ? (
                <small style={{ color: '#5A5A5A' }}>&nbsp;&nbsp;(...)</small>
            ) : null}
        </StyledDiv>
    )
}

let textStylers = {
    rt: function (text, key) {
        return <span key={key + 'rt'}>{text}</span>
    },
    i: function (text, key) {
        return <i key={key + 'i'}>{text}</i>
    },
    b: function (text, key) {
        return <b key={key + 'b'}>{text}</b>
    },
    bi: function (text, key) {
        return (
            <b key={key + 'bi'}>
                <i key={key + 'bii'}>{text}</i>
            </b>
        )
    },
    u: function (text, key) {
        return <u key={key + 'u'}>{text}</u>
    },
    a: function (text, key) {
        return (
            <a
                key={key + 'a'}
                href={text}
                target="_blank"
                rel="noopener noreferrer"
            >
                {text}
            </a>
        )
    },
}

export const TextContainer = function (props) {
    if (props?.useProductCard) {
        return <ProductCard {...props.product} />
    }
    let items = []
    for (var i = 0; i < props.messageStyling.length; i++) {
        if (i - 1 >= 0 && props.messageStyling[i].append) {
            if (props.messageStyling[i].mode !== 'a') {
                items.push(
                    textStylers[props.messageStyling[i].mode](
                        items[i - 1],
                        props.mId + 'i'
                    )
                )
            } else {
                items.push(
                    textStylers[props.messageStyling[i].mode](
                        props.messageStyling[i].text,
                        props.mId + 'i'
                    )
                )
            }
            items.splice(i - 1, 1)
        } else {
            items.push(
                textStylers[props.messageStyling[i].mode](
                    props.messageStyling[i].text,
                    props.mId + 'i'
                )
            )
        }
    }
    // TODO: removed the first extra space to allow smooth newlines; this may need to be added back.
    return (
        <StyledDiv key={props.mId + 'TC'}
            types={['messageTextStyle',
                (props.fullMsg) ? '' :
                    (props.half) ? 'oneLineClamp' :
                        'twoLineClamp']}
            desktop={props.desktop}>
            {/* {' '} */}
            {props.thread ? props.thread.text : items}{' '}
            {props.edited ? (
                <small style={{ color: '#5A5A5A' }}>&nbsp;&nbsp;(edited)</small>
            ) : null}{' '}
        </StyledDiv>
    )
}

export const TimelineButtons = (props) => {
    const fontSize = (props.desktop) ? '13.33px' : '10px'
    const iconSize = (props?.compressed) ? '0.6' : '0.8'
    return (
        <StyledDiv types={['timelineButtons']}
            fullMsg={props?.fullMsg}
            style={{ fontSize }}>
            <StyledDiv style={{ width: 'fit-content', flex: '0 1 auto' }}>
                <IconButtonCustom
                    color="primary"
                    onClick={() => {
                        props.timelineOps.setSingleThreadPage(true)
                        props.timelineOps.setSelectedThread(props.tId)
                        if (props.replyToMessage) {
                            props.replyToMessage()
                        }
                    }}
                    size="small"
                    style={{
                        transform: 'scale(' + iconSize + ')',
                        padding: '0px',
                        paddingTop: '3px',
                    }}>
                    <ChatBubbleOutlineIcon style={{}} />
                </IconButtonCustom>
                <span style={{ paddingLeft: '0.5em' }}>
                    {props.replies}
                </span>
            </StyledDiv>
            <StyledDiv style={{ width: 'fit-content', flex: '0 1 auto' }}>
                <IconButtonCustom
                    // /!* TODO: clicking turns empty chat bubble into full chat bubble: <ChatBubbleIcon /> *!/
                    color="primary"
                    onClick={() => {
                        props.timelineOps.likeMessage(props.mId)
                    }}
                    size="small"
                    style={{
                        transform: 'scale(' + iconSize + ')',
                        padding: '0px',
                        paddingTop: '3px',
                    }}
                >
                    {props?.likedByUser ?
                        <FavoriteIcon /> :
                        <FavoriteBorderIcon />
                    }
                </IconButtonCustom>
                <span style={{ paddingLeft: '0.5em' }}>
                    {props.reactions}
                </span>
            </StyledDiv>
            <StyledDiv style={{ width: 'fit-content', flex: '0 1 auto' }}>
                <IconButtonCustom
                    // /!* TODO: turns into selected reaction upon post *!/ 
                    color="primary"
                    onClick={() => {
                    }}
                    size="small"
                    style={{
                        transform: 'scale(' + iconSize + ')',
                        padding: '0px',
                        paddingTop: '3px',
                    }}
                >
                    <RepeatIcon />
                </IconButtonCustom>
                <span style={{ paddingLeft: '0.5em' }}>
                    {props.highlights}
                </span>
            </StyledDiv>
            <StyledDiv style={{ width: 'fit-content', flex: '0 1 fit-content' }}>
                <IconButtonCustom
                    color="primary"
                    onClick={() => {
                    }}
                    size="small"
                    style={{
                        transform: 'scale(' + iconSize + ')',
                        padding: '0px',
                        paddingTop: '3px',
                    }}
                >
                    <FormatQuoteIcon />
                </IconButtonCustom>
                <span style={{ paddingLeft: '0.5em' }}>
                    {props.quotes}
                </span>
            </StyledDiv>
        </StyledDiv>
    )
}