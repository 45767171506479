import { schema } from 'normalizr'
import Immutable from 'immutable'

const id = new schema.Entity('id')
const rank = new schema.Entity('rank')
const roleName = new schema.Entity('roleName')
const activeChannel = new schema.Entity('activeChannel')

const channelWindowProcessor = function (id, messages) {
    return {
        id: id,
        storedChannelWindowData: {},
        dataStored: false,
        dataLoaded: messages.length !== 0,
        messages: Immutable.List(messages),
        messagesOther: Immutable.List([]),
        allMessages: Immutable.List(messages),
        updates: [],
        updateRequired: false,
        updateId: 0,
        activeMode: 'main',
    }
}

const userMetaProcessor = function (id, users) {
    let userMetas = users.map((user) => {
        return {
            id: user.id + '_' + id,
            roles: user.roles,
        }
    })
    return userMetas
}

const abstractRole = new schema.Entity('abstractRoles', {
    id: id,
    rank: rank,
    roleName: roleName,
})
const userMetas = new schema.Entity('userMetas', {
    id: id,
    roles: [abstractRole],
})
//
// const user = new schema.Entity('users', {}, {
//     idAttribute: (value, parent, key) => {
//         return value.id
//     },
// });

const user = new schema.Entity('users', {
    id: id,
})
const channelWindows = new schema.Entity('channelWindows', { id: id })

export const channel = new schema.Entity(
    'channels',
    {
        id: id,
        channelWindow: channelWindows,
    },
    {
        idAttribute: 'id',
        processStrategy: (value, parent, key) => {
            return {
                ...value,
                channelWindow: channelWindowProcessor(value.id, value.messages),
            }
        },
    }
)
const message = new schema.Entity(
    'messages',
    {
        _id: id,
        uId: user,
    },
    {
        idAttribute: '_id',
        processStrategy: (value, parent, key) => {
            return {
                ...value,
                id: value._id,
            }
        },
    }
)

export const server = new schema.Entity(
    'servers',
    {
        id: id,
        roles: [abstractRole],
        userMetas: [userMetas],
    },
    {
        processStrategy: (value, parent, key) => {
            return {
                ...value,
                userMetas: userMetaProcessor(value.id, value.users),
            }
        },
    }
)

const session2 = new schema.Entity(
    'session',
    {
        activeChannel: activeChannel,
        Servers: [server],
        Channels: [channel],
        Messages: [message],
        Users: [user],
    },
    {
        idAttribute: 'loaded',
    }
)

export { session2 }
