export const useVirtualizedControls = (virtualizedListRef)=>{
    const scrollToTop = () => {
        virtualizedListRef.current.scrollToTop()
        // topRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    const scrollToBottom = () => {
        virtualizedListRef.current.scrollToBottom()
        // botRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    return {scrollToTop,scrollToBottom}
}