import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { dispatchMappers } from '../../../../redux/process/actionProcess'
import { RA1, RS1 } from '../../../../redux/actions'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    Fab,
} from '@mui/material'
import { ButtonCustom, StyledDiv, TypographyCustom } from '../../Styled'
import AddIcon from '@mui/icons-material/Add'
import { statePropMapper } from '../../../../redux/process/selectorProcess'
import PriceTable from './PriceTable'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { BottomRightHover } from "../../../_design/BottomHover";
import { withAS } from "../../../_contexts/withAppState";

const makeMapStateToProps = statePropMapper([
    RS1.store('_appState', '_appState'),
    RS1.store('_userSettings', '_userSettings'),
    RS1.getServer('serverData'),
])
const mapDispatchToProps = dispatchMappers([
    SA1.getProductsByServer,
    SA1.getTransactions,
    SA1.getServerTransactions,
    RA1._appState.openModalMenu,
    RA1._appState.blockModal,
    SA1.createPaymentAccount,
    SA1.updateAccountInfo
])

const typeDictionary = {
    "one-time": "One-time",
    "calendar": "Calendar",
    "appointment": "Appointment",
    "subscription": "Subscription",
    "paid-dm": "Paid-dm"
}
const ProductAccordion = (props) => {
    const pType = props?.product?.type
    const pName = props?.product?.name
    return (
        <div style={{ paddingTop: '4px' }}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <TypographyCustom>
                        {typeDictionary[pType ? pType : "unknown"]} (Product name: {pName})
                    </TypographyCustom>
                </AccordionSummary>
                <AccordionDetails>
                    <PriceTable {...props} />
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

const Products = (props) => {
    let serverData = props.manager.serverData
    /*    const [formState, setFormState, formStateRef, fieldChange] = useFields({})
        const [inputProps, updateAPI] = useAPI(fieldChange, props)
        const blockModal = useBlockModal(
            props.dispatch._appState.blockModal,
            props.dispatch.server.updateRoles,
            [props.server, props.manager.roleStructModified])
        useFormMonitor(formState, (key) => {
            props.dispatch.management.modifyRoleStruct(...formState[key])
            blockModal()
            let newFormState = {...formState}
            delete newFormState[key]
            setFormState(newFormState)
        })
        let activeRole = props.manager.selectedRole*/
    useEffect(() => {
        props.dispatch.server.getProductsByServer(props.server)
    }, [])
    useEffect(() => {
        props.dispatch.server.getProductsByServer(props.server)
    }, [props.serverData.version])

    if (!serverData.stripeEnabled) {
        return (
            <div>
                <StyledDiv types={['managementPageOuterDiv', '_FVH']}>
                    <StyledDiv types={['_centeredVH']}>
                        <Card sx={{ maxWidth: 345 }}>
                            <CardActionArea>
                                <CardContent>
                                    <TypographyCustom
                                        gutterBottom
                                        variant="h5"
                                        component="div"
                                    >
                                        Server is not Stripe enabled.
                                    </TypographyCustom>
                                    <TypographyCustom
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        By enabling payment functionality on
                                        your server...
                                    </TypographyCustom>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                {props._appState?.accountLinkUrl ? (
                                    <ButtonCustom
                                        size="small"
                                        color="primary"
                                        onClick={() => {
                                            window.open(
                                                props._appState.accountLinkUrl,
                                                '_blank'
                                            )
                                        }}
                                    >
                                        Stripe Onboarding
                                    </ButtonCustom>
                                ) : (
                                    <ButtonCustom
                                        size="small"
                                        color="primary"
                                        onClick={() => {
                                            props.dispatch.server.createPaymentAccount(
                                                props.server
                                            )
                                            // props.dispatch.server.updateAccountInfo(
                                            //     props.server
                                            // )
                                        }}
                                    >
                                        Enroll today
                                    </ButtonCustom>
                                )}
                            </CardActions>
                        </Card>
                    </StyledDiv>
                </StyledDiv>
            </div>
        )
    } else {
        return (
            <div>
                <StyledDiv
                    types={['_FVH']}
                    style={{
                        overflowY: 'auto',
                        paddingLeft: '30px',
                        paddingRight: '30px',
                    }}>
                    <TypographyCustom gutterBottom variant="h4" component="div">
                        All Products
                    </TypographyCustom>
                    {props._appState?.catalog
                        ? props._appState.catalog.map((product, index) => (
                            <ProductAccordion id={"accord" + product.id + index} key={"accord" + product.id + index} product={product} {...props} />
                        ))
                        : null}
                </StyledDiv>
                <BottomRightHover>
                    <Fab
                        color="primary"
                        aria-label="add"
                        onClick={() => props.AS.modals.ops.openModal('createProduct')}
                    >
                        <AddIcon />
                    </Fab>
                </BottomRightHover>
            </div>
        )
    }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(Products))
