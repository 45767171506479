import React from 'react'
import {connect} from 'react-redux'
import {RA1, RS1} from '../../../../redux/actions.js'
import {dispatchMappers} from '../../../../redux/process/actionProcess.js'
import SocketForm from '../../../_reusable/SocketForm/SocketForm'
import {
    statePropMapper,
} from '../../../../redux/process/selectorProcess'

const makeMapStateToProps = statePropMapper([
    RS1.store('_appState', '_appState'),
])

const mapDispatchToProps = dispatchMappers([
    RA1._appState.closeBlockedModalMenu,
])

const ChangeModal = function (props) {
    const dispatchMethod = () => {
        props.AS.modals.ops.useBlockModalAction()
    }
    return (
        <SocketForm
            formMethod={'updateRoles'}
            formDispatch={dispatchMethod}
            socketForm
            onClose={() => {
                props.AS.modals.ops.unblockModal()
                props.closeModalMenu()
            }}
            onSuccess={() => {
                props.AS.modals.ops.unblockModal()
                props.closeModalMenu()
            }}
            description={
                'Would you like to save the changes?'
            }
            formFields={[]}
            formButtons={[
                {label: 'Save', icon: 'add', isSubmitButton: true},
                {label: 'Cancel', isCloseButton: true}]}
            formTypography={{label: 'Save Changes'}}
            successMessage={'Roles changed.'}
        />
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(ChangeModal)
