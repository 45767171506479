import { react, useState, useEffect } from 'react'
import { statePropMapper } from '../../../../redux/process/selectorProcess'
import { connect } from 'react-redux'
import { RA1, RS1 } from '../../../../redux/actions'
import { IconButtonCustom } from '../../Styled'
import { dispatchMappers } from '../../../../redux/process/actionProcess'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTheme } from '@mui/material'
import SingleThreadMessageList from '../../ThreadView/SingleThread/SingleThreadMessageList'
import { useHistory, useLocation } from 'react-router-dom'

const makeMapStateToProps = statePropMapper([
    RS1.store('_appState', '_appState'),
])

const mapDispatchToProps = dispatchMappers([RA1._appState.closeModalMenu])

// const mapDispatchToProps = dispatchMappers([RA1._appState.closeModalMenu])

const ThreadReplyViewer = function (props) {

    const [selectedThread, setSelectedThread] = useState('')

    // #
    // useEffect(() => {
    //     if (location.search &&
    //         location.search.split('?')[1] &&
    //         location.search.split('?')[1].split('=')[1] &&
    //         location.search.split('?')[1].split('=')[0] === 'thread') {
    //         setSelectedThread(location.search.split('?')[1].split('=')[1])
    //     }
    // }, [location])



    if (
        props.AS.threads.state.selectedThread
        // selectedThread
    ) {
        return (
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
            }}>
                <SingleThreadMessageList
                    server={props.AS.activeServer}
                    threadMsgs={props.AS.modals.state.modalData.viewedReplies}
                    selectedThread={
                        props.AS.threads.state.selectedThread
                        // selectedThread
                    }
                    {...props} />
            </div >
        )
    } else {
        return null
    }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(ThreadReplyViewer)
