export const hexToRgb = (hex) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        }
        : null
}

export const hexToFormattedRgb = (hex) => {
    let modifiedHex = hex
    if (hex.length === 4) {
        modifiedHex = hex
            .split('')
            .map((item) => {
                if (item === '#') {
                    return item
                }
                return item + item
            })
            .join('')
    }
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(modifiedHex)
    return (
        parseInt(result[1], 16) +
        ',' +
        parseInt(result[2], 16) +
        ',' +
        parseInt(result[3], 16)
    )
}

export const generateGradient = (color) => {
    // linear-gradient(to top,
    //     #000a 33%,
    //     #0008 33%, #0008 37%,
    //     #0006 37%, #0006 41%,
    //     #0004 41%, #0004 45%,
    //     #0002 45%, #0002 49%,
    //     #0000 49% palette.background.secondary?
    if (!color.charAt(0) === '#') {
        color = rgbaToHex(color)
    }
    if (color.length < 7) {
        color = makeHexBig(color)
    }
    if (color.length > 7) {
        color = color.splice(0, 6)
    }

    let gradient = 'linear-gradient(to top,'
    gradient += color + 'a0 33%,'
    gradient += color + '80 33%,' + color + '80 37%,'
    gradient += color + '60 37%)'
    return gradient
}

export const makeHexBig = (smol) => {
    if (smol.length >= 7) {
        return smol
    }
    let parts = smol.match(/(?!^).{1,1}/g)
    let big = '#' + parts.join('0') + '0'
    return big
}

export const rgbaToHex = (rgba) => {
    let hex = '#'
    let deciValues = rgba.split(',')
    if (deciValues.length !== 4) {
        return rgba
    }
    deciValues[0] = deciValues[0].slice(5)
    deciValues[3] = deciValues[3].slice(0, 4)

    deciValues.forEach((value, index) => {
        let out = parseFloat(value)
        if (index === 3) {
            out = Math.floor(out * 255)
        }
        hex += out.toString(16)
    })
    return hex
}

export const rgbaToOpaque = (rgba) => {
    // TODO: elegantly handle rgba, rgb, #xxxx, #xxx, #xxxxxxxx, #xxxxxx

    let hex = '#'
    let deciValues = rgba.split(',')
    if (deciValues.length !== 4) {
        return rgba
    }
    deciValues[0] = deciValues[0].slice(5)
    deciValues[3] = deciValues[3].slice(0, 4)
    deciValues.pop()

    deciValues.forEach((value, index) => {
        let out = parseFloat(value)
        if (index === 3) {
            out = Math.floor(out * 255)
        }
        hex += out.toString(16)
    })
    return hex
}
