import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { RS1, RA1 } from '../../../../redux/actions'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import { dispatchMappers } from '../../../../redux/process/actionProcess'
import { statePropMapper } from '../../../../redux/process/selectorProcess'
import { ButtonCustom, StyledDiv } from '../../../Chat/Styled'
import { useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { trigger } from '../../../../events'
import ThreadRetriever from './ThreadRetriever.js'
import moment from 'moment'
import {JLog} from "../../../../hs/journey";

const makeMapStateToProps = statePropMapper([
    RS1.getThreads('threads'),
])

const mapDispatchToProps = dispatchMappers([SA1.updateSession])

const MessageHeader = function (props) {

    let color = props.theme.palette.text.secondary
    let backupColor = props.theme.palette.text.flair

    return (
        <StyledDiv types={['threadFlexboxColumnHeader']}>
            <div>
                <b
                    style={{
                        color: color.startsWith('rgba') ? backupColor : color,
                    }}
                >
                    {props.user?.userName ? props.user.userName : props.message.uId}
                    &emsp;
                </b>
                <span style={{ fontSize: '11px' }}>
                    {'(' + props.fullMessageDate + ')'}
                </span>
            </div>
        </StyledDiv>
    )
}

const ThreadCardMini = (props) => {
    const theme = useTheme()
    // message will be 'undefined' if not found in props.threads, causing the client to crash - fixed with useEffects below
    let message = props.threads.filter(thread => props.threadId === thread._id)[0]

    const [loaded, setLoaded] = useState(false)

    const [userColor, setUserColor] = useState(null)
    const [tmpUser, setTmpUser] = useState(null)
    const [tmpUserMeta, setTmpUserMeta] = useState(null)
    const [tmpServerAbstractRoles, setTmpServerAbstractRoles] = useState(null)

    let innerColumnsStyle = { whiteSpace: 'pre-wrap' }

    let fullMessageDate = message?.createdAt ? moment(message.createdAt).format(
        'MMMM Do YYYY, h:mm:ss a'
    ) : ''

    // # Need to reload threads if any thread is 'reload', otherwise the thread won't be found and message will be 'undefined'
    useEffect(() => {
        if (props.threads.includes('reload')) {
        } else {
            setLoaded(true)
        }
    }, [props.threads, loaded])
    useEffect(() => {
        if (props.threads.includes('reload')) {
            props.dispatch.server.updateSession(
                'loadThreads',
                props.server,
                props.channel,
                null,
                '',
                ''
            )
        } else {
            setLoaded(true)
        }
    }, [])

    if (!tmpUser || !tmpUserMeta || !tmpServerAbstractRoles || !userColor) {

        JLog.threads.warn("BIG ISSUE BIG ISSUE")()
        JLog.threads.log(props.threads)()
        JLog.threads.log(props.threadId)()
        JLog.threads.log(message)()

        return (
            message?._id ?
                <ThreadRetriever
                    setTmpUser={setTmpUser}
                    setUserColor={setUserColor}
                    setTmpUserMeta={setTmpUserMeta}
                    setTmpServerAbstractRoles={setTmpServerAbstractRoles}
                    selectedThread={message._id}
                    server={message.sId}
                    channel={message.cId}
                    userId={message.uId} />
                :
                <div></div>
        )
    } else {
        let user = { ...tmpUser }
        user.color = userColor

        // let product = { ...message?.metaData?.product }
        // if (message?.metaData?.productId && message.message === 'hidden') {
        //     product.desc = product.description
        // }

        if (message.deleted) {
            return (
                <StyledDiv
                    types={['threadFlexboxOuterDiv']}
                    style={{ justifyContent: 'center' }}
                >
                    <StyledDiv types={['threadFlexboxInnerColumns']}>
                        <StyledDiv types={['threadFlexboxColumnText']}>
                            &nbsp;
                        </StyledDiv>
                    </StyledDiv>
                </StyledDiv>
            )
        } else {
            let fileStyle = {
                width: '100%',
                background: theme.palette.background.primary,
                padding: '3px',
                borderRadius: '8px'
            }

            JLog.threads.warn("MESSAGE.MESSAG")()
            JLog.threads.log(message.message.split('\n'))()
            JLog.threads.log(theme.palette)()

            return (

                <StyledDiv
                    types={['threadFlexboxOuterDiv']}
                    style={{
                        flexDirection: 'column',
                        width: '100%',
                        overflow: 'hidden',
                        paddingLeft: 0
                    }}>
                    <StyledDiv types={['threadTooltipHeader']}>
                        View Thread:
                    </StyledDiv>
                    <StyledDiv
                        style={fileStyle}
                        types={['threadFlexboxInnerColumns', 'threadDecorBordered']}>
                        <MessageHeader
                            theme={theme}
                            fullMessageDate={fullMessageDate}
                            message={message}
                            user={user}
                        />
                        <StyledDiv
                            types={['threadFlexboxColumnText']}
                            style={innerColumnsStyle}>
                            {message.threadTitle}
                        </StyledDiv>
                    </StyledDiv>
                </StyledDiv>
            )
        }
    }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(ThreadCardMini)