import React, { useState } from 'react'
import { Menu, Item, Separator, Submenu } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import { connect } from 'react-redux'
import { dispatchMappers } from '../../../../redux/process/actionProcess.js'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import { RS1 } from '../../../../redux/actions.js'
import { statePropMapper } from '../../../../redux/process/selectorProcess.js'
import { trigger } from '../../../../events.js'
import { MenuCustom } from '../../Styled'

const makeMapStateToProps = statePropMapper([
    RS1.getUserBooleanPerm('isAdmin', ['isAdmin']),
    RS1.getUserBooleanPerm('isModerator', ['isModerator']),
    RS1.getServerUsersOb('users'),
    RS1.store('_userId', '_userId'),
])

const mapDispatchToProps = dispatchMappers([SA1.deleteMessage])

function MessageMenu(props) {
    if (props?.selectedItem && !props.selectedItem.deleted) {
        const hasDeletePerm =
            props.isModerator ||
            props.isAdmin ||
            props._userId === props.selectedItem.uId
        const hasEditPerm = props._userId === props.selectedItem?.uId
        let user = props.users[props.selectedItem?.uId]
        return (
            <MenuCustom id={props.menuId}>
                {hasDeletePerm ? (
                    <Item
                        onClick={() => {
                            props.dispatch.server.deleteMessage(
                                props.selectedItem.sId,
                                props.selectedItem.mId
                            )
                        }}
                    >
                        Delete Message
                    </Item>
                ) : null}
                {hasEditPerm ? (
                    <Item
                        onClick={() => {
                            trigger('setChatInput:edit', {
                                sId: props.selectedItem.sId,
                                mId: props.selectedItem.mId,
                                message: props.selectedItem.message,
                            })
                        }}
                    >
                        Edit Message
                    </Item>
                ) : null}
                {/*<Item onClick={props.handleItemClick}>Add to Highlights</Item>*/}
                {!props.reverseMode? <Item
                    onClick={() => {
                        trigger('setChatInput:reply', {
                            isReply: true,
                            message: props.selectedItem.message,
                            user: user.userName,
                            edited: props.selectedItem.edited,
                            attachment: props.selectedItem.files > 0,
                            mData: {
                                uId: props.selectedItem.uId,
                                mId: props.selectedItem.mId,
                                cId: props.selectedItem.cId,
                                sId: props.selectedItem.sId,
                            },
                        })
                    }}
                >
                    Reply
                </Item>:null}
                {/*{!props.reverseMode? <Item*/}
                {/*    onClick={() => {*/}
                {/*        trigger('setChatInput:quote', {*/}
                {/*            message: props.selectedItem.message,*/}
                {/*        })*/}
                {/*    }}*/}
                {/*>*/}
                {/*    Quote*/}
                {/*</Item>:null}*/}
                {/*<Separator />*/}
                {/*<Item disabled>Disabled</Item>*/}
                {/*<Separator />*/}
                {/*<Submenu*/}
                {/*    label="React"*/}
                {/*    horizontalDisplay={'default'}*/}
                {/*    defaultArrowDirection={'right'}*/}
                {/*>*/}
                {/*    <Item onClick={props.handleItemClick}>Sub Item 1</Item>*/}
                {/*    <Item onClick={props.handleItemClick}>Sub Item 2</Item>*/}
                {/*</Submenu>*/}
            </MenuCustom>
        )
    } else {
        return null
    }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(MessageMenu)
