import React, { useState } from 'react'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import { connect } from 'react-redux'
import { RA1, RS1 } from '../../../../redux/actions'
import ImageDropzone from '../../../_reusable/ImageDropzone'
import { StyledDiv, TypographyCustom } from '../../Styled'
import Button from '@mui/material/Button'
import { IntegrableAccordionWrap } from '../../../Integrable/Accordion'
import { dispatchMappers } from '../../../../redux/process/actionProcess'
import { statePropMapper } from '../../../../redux/process/selectorProcess'
import { withAS } from "../../../_contexts/withAppState";
import { FULL_FRONTEND_URL } from "../../../../constants/env";

const makeMapStateToProps = statePropMapper([
    RS1.store('imageStore', 'imageStore'),
    RS1.store('user', '_userId'),
])
const mapDispatchToProps = dispatchMappers([
    RA1._appState.openModalMenu,
    SA1.addBanner,
    SA1.addAvatar,
    SA1.modifyServer,
])

const GeneralManagement = (props) => {
    const [avatarKey, setAvatarKey] = useState(0)
    const [bannerKey, setBannerKey] = useState(0)
    const addServerBanner = (e) => {
        e.preventDefault()
        props.dispatch.server.modifyServer(e.target[0].value, props.server)
    }
    const bannerSendFunction = (fileToken) => {
        props.dispatch.server.addBanner(props.server, fileToken)
    }
    const avatarSendFunction = (fileToken) => {
        props.dispatch.server.addAvatar(props.server, fileToken)
    }
    return (
        <StyledDiv types={['managementPageOuterDiv', '_FVH']}
            style={{
                height: 'calc(100dvh - 6.4em)',
                marginTop: '3.2em'
            }}>
            <TypographyCustom gutterBottom variant="h4" component="div">
                Server Management
            </TypographyCustom>
            {/*<div style={{height: "20vh", width: "20vh"}}>*/}
            {/*    Current server avatar*/}
            {/*    <img src={imageAddress} alt={imageAddress}/>*/}
            {/*</div>*/}
            <IntegrableAccordionWrap name={'General Settings'}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => props.AS.modals.ops.openModal('updateServerName')}
                >
                    Change Server Name
                </Button>
            </IntegrableAccordionWrap>
            <IntegrableAccordionWrap name={'Server Avatar'}>
                <ImageDropzone
                    key={'serverAvatar' + avatarKey}
                    label={''}
                    description={'Drag and drop a new server avatar'}
                    storeLocation={'Avatar' + props.server}
                    fileName={'serverAvatar'}
                    sendFunction={avatarSendFunction}
                    user={props.user}
                    completeOperation={setAvatarKey}
                    defaultImage={
                        FULL_FRONTEND_URL + 'placeholder-image.jpg'
                    }
                    aspectRat={4 / 4}
                    useCropper={true}
                />
            </IntegrableAccordionWrap>
            <IntegrableAccordionWrap name={'Server Banner'}>
                <ImageDropzone
                    key={'serverBanner' + bannerKey}
                    label={'Server Banner'}
                    description={'Drag and drop a new server banner'}
                    storeLocation={'Banner' + props.server}
                    fileName={'serverBanner'}
                    user={props.user}
                    sendFunction={bannerSendFunction}
                    completeOperation={setBannerKey}
                    defaultImage={FULL_FRONTEND_URL + 'placeholder-image.jpg'}
                    aspectRat={4 / 8}
                    useCropper={true}
                />
            </IntegrableAccordionWrap>
        </StyledDiv>
    )
}

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(withAS(GeneralManagement))
