import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack.js'
import VideocamIcon from '@mui/icons-material/Videocam'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import React from 'react'
import { dispatchMappers } from '../../../../../redux/process/actionProcess.js'
import { RA1, RS1 } from '../../../../../redux/actions.js'
import { connect } from 'react-redux'
import AddIcon from '@mui/icons-material/Add'
import { streams } from "../../../Stream/stream-controller.js";
import { statePropMapper, statePropMappers } from "../../../../../redux/process/selectorProcess.js";
import { withAS } from "../../../../_contexts/withAppState";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";

const makeMapStateToProps = statePropMapper([
    RS1.store("_userId", "_userId"),
    RS1.getUserBooleanPerm('isAdmin', ['isAdmin']),
])

const mapDispatchToProps = dispatchMappers([RA1._appState.updateAppState])

const CommunityNavFooter = function (props) {
    if (props.AS.mobileMode) {
        return (<div></div>)
    }
    else {
        return (
            <div>
                {/*{props.isAdmin ? null : <div style={{width: '48px'}}></div>}*/}
                {/*<div style={{width: '48px'}}></div>*/}
                <IconButton
                    color="primary"
                    onClick={() => {
                        props.AS.nav.ops.setMenuL('manage')
                        props.AS.nav.ops.setPage('manage')
                    }}
                    size="large"
                >
                    <SettingsApplicationsIcon />
                </IconButton>
                {/* <IconButton
                    color="primary"
                    onClick={() => {
                        streams.startCallWithoutInvite(props._userId, "userSession");
                        props.AS.nav.ops.setPage('stream')
                    }}
                    size="large"
                >
                    <VideocamIcon />
                </IconButton> */}
                {/*{props.isAdmin ? <IconButton*/}
                {/*    color="primary"*/}
                {/*    onClick={() => props.AS.modals.ops.openModal("channel")}*/}
                {/*    size="large"*/}
                {/*>*/}
                {/*    <AddIcon/>*/}
                {/*</IconButton> : null}*/}
                <div style={{ width: '48px' }}></div>
            </div>
        )
    }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(CommunityNavFooter))
