import React from 'react'
import {withAuth} from "../../_contexts/withAuth";
import {ButtonCustom} from "../../Chat/Styled";
import {FULL_FRONTEND_URL} from "../../../constants/env";

const BackButton = (props) => {
    return (
        <ButtonCustom
            variant="contained"
            color="primary"
            onClick={(e) => {
                if (props.auth?.auth) {
                    props.authFunctions.logout(props.forceReload)
                }
                setTimeout(()=>{window.location = FULL_FRONTEND_URL}, 500)


            }}
        >
            Back
        </ButtonCustom>
    )
}

export default withAuth(BackButton)
