import React, { useEffect } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PaymentForm from '../PaymentForm'
import { PaymentElement } from '@stripe/react-stripe-js'

//TODO: Make ABSOLUTELY SURE that we're getting a new, clean PaymentIntent EVERY TIME we click "buy"
// on the product list.
//TODO: may need _activeServer and _activeChannel to record what the transaction is for on the backend.
//TODO: utilize options.appearance={theme:'stripe'}

const stripePromise = loadStripe(
    'pk_test_51KattNCilZgi7JBWkDXqlkijVrnisrifNT372Z7LDn0lbfAjW3dIhmf2FKyBg4TyJce0Jw8YV5lgiAyL3YXN8Imd00lFKGrHqD'
)

const ElementPayment = function (props) {
    return (
        <div>
            <Elements options={props.options} stripe={stripePromise}>
                <PaymentForm
                    submit={props.submit}
                    onSuccess={props.onSuccess}
                />
                <br />
            </Elements>
        </div>
    )
}

export default ElementPayment
