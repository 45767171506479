import React, { useEffect, useState, useRef } from 'react'
import ChatInputContainer from '../../ChatWindow/ChatInputContainer'
import { RS1 } from '../../../../redux/actions'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import { dispatchMappers } from '../../../../redux/process/actionProcess'
import { connect } from 'react-redux'
import ThreadMessage from './ThreadMessage'
import withReactVirtualized from '../../../_wrappers/withReactVirtualized'
import SingleThreadMenu from './SingleThreadMenu'
import { useContextMenu } from 'react-contexify'
import { TopRightHover, BottomRightHover } from '../../../_design/BottomHover'
import { useTheme } from '@mui/material'
import { ButtonCustom, PaperCustom, StyledDiv, vDH } from '../../Styled'
import { useHistory, useLocation } from 'react-router-dom'
import { IconButtonCustom } from '../../Styled'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import { useVirtualizedControls } from "../../../_wrappers/withReactVirtualized/utilityHooks";
import GenericCard from "../../../_reusable/Cards/UniversalCard";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { trigger } from '../../../../events'
import { withAS } from '../../../_contexts/withAppState'
import { statePropMapper } from '../../../../redux/process/selectorProcess'

const makeMapStateToProps = statePropMapper([
    RS1.store('_activeChannel', '_activeChannel'),
])

const makeDispatchToProps = dispatchMappers([
    SA1.updateSession,
    SA1.watchThread,
    SA1.deleteMessage,
])

const TimelineMessage = (props) => {
    const mes = props.componentProps.message
    let user = props?.passdownProps?.threadSystem.users[mes.uId]

    // const openReplyViewer = (AS, history, location, message, threadSystem) => (replies) => {

    //     if (location.search) {
    //     }
    //     let newLocation = location.pathname + location.search + '&thread=' + message.tId
    //     history.push(newLocation)
    //     AS.modals.ops.openModalWithData('threadreplyviewer', {
    //         viewedReplies: replies,
    //         selectedThread: message,
    //     })
    //     // props.dispatch._appState.updateAppState({
    //     //     modalMenu: 'threadreplyviewer',
    //     //     modalMenuOpen: true,
    //     //     viewedReplies: replies,
    //     //     selectedThread: null,
    //     // })
    // }

    const replyToMessage = () => {
        trigger('setChatInput:reply', {
            isReply: true,
            message: mes.message,
            user: user.userName,
            edited: mes.edited,
            attachment: mes.files > 0,
            mData: {
                uId: mes.uId,
                mId: mes.mId,
                cId: mes.cId,
                sId: mes.sId,
            },
        })
    }

    return <GenericCard
        isReply={mes._id !== mes.tId}
        message={mes}
        user={user}
        product={null}
        innerColumnsStyle={null}
        replies={
            mes?.metaData?.threadReplies ?
                mes?.metaData?.threadReplies :
                mes.reply?.hasReplies?.length > 0 ?
                    mes.reply?.hasReplies?.length :
                    0
        }
        reactions={mes?.reply?.likedBy ? mes?.reply?.likedBy.length : 0}
        highlights={0}
        quotes={0}
        push={props.passdownProps.AS.push}
        cardType={'timelineMsg'}
        timelineOps={props.passdownProps?.threadSystem}
        displayMode={props?.passdownProps?.desktop ? 'fullTotalDesktop' : 'fullTotal'}
        cardTestMode={false}
        likedByUser={mes?.likedByUser}
        replyToMessage={replyToMessage}
    // replyClicked={openReplyViewer(
    //     props.passdownProps.AS,
    //     props.passdownProps.history,
    //     props.passdownProps.location,
    //     props.message,
    //     props.passdownProps.threadSystem)}
    />
}

const BlogMessage = (props) => {
    let user = props?.passdownProps?.threadSystem.users[props.componentProps.userId]
    const mes = props.componentProps.message

    const getImgs = (imgidx) => {
        let images = []
        let imgIndex = 0

        let fileCounter = 0
        props?.passdownProps?.threadSystem?.threadMsgs?.map((threadItem, index) => {
            let message = threadItem.message

            if (message?.files?.length != 0) {
                if (imgidx > index) {
                    imgIndex += 1;
                }
                message.files.forEach((file) => {
                    images.push(file)
                    if (imgidx > index) {
                        fileCounter += 1
                    }
                })
            }
        })
        return { images, imgIndex, fileCounter }
    }

    const openImageViewer = () => {
        let { images, imgIndex, fileCounter } = getImgs(props.msgIndx) // TODO: check
        let calculatedImgIdx = fileCounter > 0 ? imgIndex + fileCounter - 1 : imgIndex

        props.passdownProps.AS.modals.ops.openModalWithData('threadimgviewer', { // TODO: check
            images: images,
            imgIndex: (props.isModal) ? calculatedImgIdx - 1 : calculatedImgIdx
        })
    }

    return <GenericCard
        message={props.componentProps.message}
        user={user}
        product={null}
        innerColumnsStyle={null}
        replies={props?.componentProps?.metaData?.threadReplies}
        reactions={mes?.reply?.likedBy ? mes?.reply?.likedBy.length : 0}
        highlights={0}
        quotes={0}
        push={props.passdownProps.AS.push}
        cardType={'blogMsg'}
        timelineOps={props.passdownProps?.threadSystem}
        displayMode={props?.passdownProps?.desktop ? 'fullTotalDesktop' : 'fullTotal'}
        cardTestMode={false}
        likedByUser={mes?.likedByUser}
        openImageViewer={openImageViewer}
        theme={props?.passdownProps?.theme}
    />
}

const VirtualizedBlogMessageList = withReactVirtualized(BlogMessage)
const VirtualizedTimelineMessageList = withReactVirtualized(TimelineMessage)
const VirtualizedThreadMessageList = withReactVirtualized(ThreadMessage)
const MENU_ID = 'singleThreadMenu'

const SingleThread = (props) => {
    const virtualizedList = useRef(null)
    const controls = useVirtualizedControls(virtualizedList)
    const theme = useTheme()
    const history = useHistory()
    const location = useLocation()
    const topRef = useRef()
    const botRef = useRef()
    const { show } = useContextMenu({
        id: MENU_ID,
    })

    function displayMenu(e, threadMsgProps) {
        props.threadSystem.setSelectedThreadMsg(threadMsgProps)
        show(e)
    }

    // TODO:
    // imgboard jump to replies
    // omnichan/dashchan-style modal reply rabbithole viewer
    // modal menu for adding things as opposed to chatbar-style "add thread"
    // edit notification, when edit msg, show edited & timestamp
    // TODO: v important, for deletemessage want image link *wiped* from message & from aws server..
    // replace with link to something else, like default "x" or smth

    // useEffect(() => {
    //     history.push('/chat2?thread=' + props.threadSystem.selectedThread)
    // }, [])
    const watchThatThread = () => {

        let unwatch = false
        const idsOfThreadsWatched = props.threadSystem.threadsWatched.map(thread => thread.threadId)

        if (idsOfThreadsWatched.includes(props.threadSystem.selectedThread)) {
            unwatch = true
        }

        props.dispatch.server.watchThread(
            props.server,
            props.channel,
            unwatch,
            props.threadSystem.selectedThread)
    }


    useEffect(() => {
        // TODO: ENSURE ALL EDGE CASES ARE COVERED
        // this removes a thread from being watched if it has been deleted. 
        let threadIsWatched = props?.threadSystem?.threadsWatched?.filter(thread => thread.threadId === props.threadSystem.selectedThread)[0]
        let thisThreadExists = props?.threadSystem?.threads?.filter(thread => thread.mId === props.threadSystem.selectedThread).length
        if (threadIsWatched && (props?.threadSystem?.threads?.length > 0 && !thisThreadExists)) {
            props.dispatch.server.watchThread(
                props.server,
                props._activeChannel,
                true,
                props.threadSystem.selectedThread)
        }
    }, [props.threadSystem.selectedThreadIsWatched])
    if (props.standardThreadView) {
        if (props.threadSystem.threadMsgs.size > 0) {
            return (
                <div>
                    <SingleThreadMenu
                        menuId={MENU_ID}
                        userId={props.userId}
                        server={props.server}
                        message={props.threadSystem.selectedThreadMsg} />

                    <StyledDiv
                        types={['singleThreadWrapper']}
                        style={{
                            height: '100%', transform: 'translate(0, 0)',
                            backgroundColor: theme.palette.background.primary,
                        }}>

                        <div>
                            <StyledDiv
                                types={['singleThreadOuter', '_FVH']}
                                style={{
                                    maxWidth: 'calc(100vw - 26em)',
                                    minWidth: ((window.screen.width / window.screen.height) < (4 / 3))
                                        ? '100%'
                                        : '10px'
                                }}>

                                <div ref={topRef}></div>
                                <StyledDiv types={['singleThread']}>
                                    <VirtualizedBlogMessageList
                                        listData={props.threadSystem.threadMsgs}
                                        virtualizedList={virtualizedList}
                                        height={((window.screen.width / window.screen.height) < (4 / 3)) ? `calc(${vDH(93)})` : vDH(93)}
                                        passdownProps={{
                                            threadSystem: props.threadSystem,
                                            AS: props.AS,
                                            location: location,
                                            history: history,
                                            fakeProps: props,
                                            theme: theme,
                                            desktop: props?.desktop
                                        }}
                                        style={{
                                            width: "100%",
                                            marginTop: `calc(${vDH(3)} + 3em)`,
                                            marginBottom: '5em',
                                            margin: '3em',
                                            height: "fit-content",
                                        }}
                                        desktop={props?.desktop}
                                        topDummyMessage={<div style={{ height: '3em' }}></div>}
                                        dummyMessage={<div style={{ height: '3em' }}></div>}
                                        selectItem={displayMenu}
                                    />
                                    {/* <VirtualizedThreadMessageList
                                        listData={props.threadSystem.threadMsgs}
                                        virtualizedList={virtualizedList}
                                        height={vDH(100)}
                                        passdownProps={{ threadSystem: props.threadSystem, AS: props.AS }}
                                        style={{
                                            width: "100%",
                                            marginTop: `calc(${vDH(3)} + 3em)`,
                                            marginBottom: '5em',
                                            margin: '3em',
                                            height: "fit-content"
                                        }}
                                        selectItem={displayMenu}
                                    /> */}
                                </StyledDiv>
                            </StyledDiv>

                            <TopRightHover>
                                <ButtonCustom
                                    types={['square']}
                                    onClick={() => {
                                        props.threadSystem.setSelectedThread(null)
                                        props.threadSystem.setSingleThreadPage(false)
                                    }}
                                    style={{
                                        position: 'fixed',
                                        right: '5vw',
                                        top: '1.5em',
                                    }}>
                                    BACK
                                </ButtonCustom>
                            </TopRightHover>
                            <StyledDiv style={{
                                marginRight:
                                    ((window.screen.width / window.screen.height) < (4 / 3)) ? '2.2em' : '3em'
                            }}>
                                <BottomRightHover style={{}}>
                                    <div style={{
                                        paddingBottom:
                                            ((window.screen.width / window.screen.height) < (4 / 3)) ? 'calc(80px + 3em)' : "80px"
                                    }}>
                                        <IconButtonCustom
                                            color="primary"
                                            size="small"
                                            onClick={watchThatThread}>
                                            {(props.threadSystem.selectedThreadIsWatched) ? <StarIcon /> : <StarBorderIcon />}
                                        </IconButtonCustom>
                                        {/*
                                    <ButtonCustom types={['square']} onClick={controls.scrollToTop}>TOP</ButtonCustom>
                                    <ButtonCustom types={['square']}
                                        onClick={controls.scrollToBottom}>BOTTOM</ButtonCustom> */}
                                    </div>
                                </BottomRightHover>
                            </StyledDiv>
                        </div>

                        <div style={{ alignSelf: 'flex-start' }}>
                            <ChatInputContainer
                                server={props.server}
                                channel={props.channel}
                                sendFiles={props.sendFiles}
                                clearFiles={props.clearFiles}
                                hasFiles={props.hasFiles}
                                storeFile={props.storeFile}
                                blobLinks={props.blobLinks}
                                user={props.userId}
                                writeToThread={props.standardThreadView}
                                writeToProfile={!props.standardThreadView}
                                threadView={true}
                                singleThread={true}
                                threadId={props.threadSystem.selectedThread} />
                        </div>
                    </StyledDiv>
                </div>
            )
        } else {
            // props.threadSystem.watchThatThread()
            return (<div>No messages!</div>)
        }
    } else {
        return (
            <StyledDiv style={{ height: ((window.screen.width / window.screen.height) < (4 / 3)) ? `calc(100% - 3em)` : '100%' }}>

                <SingleThreadMenu
                    menuId={MENU_ID}
                    userId={props.userId}
                    server={props.server}
                    message={props.threadSystem.selectedThreadMsg} />
                <div style={{
                    height: '100%', transform: 'translate(0, 0)',
                    backgroundColor: theme.palette.background.primary,
                }}>
                    <VirtualizedTimelineMessageList
                        listData={props.threadSystem.threadMsgs}
                        virtualizedList={virtualizedList}
                        height={((window.screen.width / window.screen.height) < (4 / 3)) ? `calc(${vDH(93)})` : vDH(93)}
                        passdownProps={{
                            AS: props.AS,
                            location: location,
                            history: history,
                            threadSystem: props.threadSystem,
                            fakeProps: props,
                            desktop: props?.desktop
                        }}
                        style={{
                            width: "100%",
                            marginTop: `calc(${vDH(3)} + 3em)`,
                            marginBottom: '5em',
                            margin: '3em',
                            height: "fit-content",
                        }}
                        desktop={props?.desktop}
                        topDummyMessage={<div style={{ height: props?.desktop ? '3em' : '0.5em' }}></div>}
                        dummyMessage={<div style={{ height: '3em' }}></div>}
                        selectItem={displayMenu}
                    />
                    <PaperCustom id={'rabbit'} types={['timelineBackButton']} elevation={1}>
                        <IconButtonCustom
                            onClick={() => {
                                props.threadSystem.setSelectedThread(null)
                                props.threadSystem.setSingleThreadPage(false)
                            }}>
                            <ArrowBackIcon />
                        </IconButtonCustom>
                    </PaperCustom>
                    <div style={{
                        alignSelf: 'flex-start',
                    }}>
                        <ChatInputContainer
                            server={props.server}
                            channel={props.channel}
                            sendFiles={props.sendFiles}
                            clearFiles={props.clearFiles}
                            hasFiles={props.hasFiles}
                            storeFile={props.storeFile}
                            blobLinks={props.blobLinks}
                            user={props.userId}
                            writeToThread={props.standardThreadView}
                            writeToProfile={!props.standardThreadView}
                            threadView={true}
                            singleThread={true}
                            threadId={props.threadSystem.selectedThread}
                        />
                    </div>
                </div>
            </StyledDiv>)
    }
}


export default connect(
    makeMapStateToProps,
    makeDispatchToProps
)(withAS(SingleThread))
