export const BoxCustomStyle = (mode, palette) => ({
    BoxCustom: {
        defaultProps: {},
        styleOverrides:{"nothing":{}},altStyleOverrides: {
            user: {},
            modalMenu: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 'auto',
                background: palette.background.default,
                border: '1px solid #444',
                borderRadius: '14px 0px 14px 0px',
                boxShadow: 20,
                p: 0,
            },
        },
    },
})
