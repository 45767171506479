import Identicon from 'react-identicons'
import React from 'react'
import { TypographyCustom } from '../../../Styled'

export const SearchHeader = (props) => {
    return (
        <div>
            <Identicon string={'WAFAF'} size={60} bg={'#f0f0f0'} />
            <TypographyCustom types={['communitySubtext']} variant="h6">
                Search
            </TypographyCustom>
        </div>
    )
}
