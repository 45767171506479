import React from 'react'
import ChatWindow from '../../ChatWindow'
import { vDH } from '../../Styled'

class Config extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div
                className="column"
                style={{
                    height: `calc(${vDH(95)} - 6.4em)`,
                    marginTop: '3.2em',
                    width: '95%',
                }}
            >
                <ChatWindow
                    key={this.props.serverData.configId}
                    inputEnabled={false}
                    passedHeight={vDH(85)}
                    userId={this.props.userId}
                    server={this.props.activeServer}
                    channel={this.props.serverData.configId}
                />
            </div>
        )
    }
}

export default Config
