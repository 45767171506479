import React from 'react'
import {connect} from 'react-redux'
import {RA1, RS1} from '../../../../redux/actions.js'
import {SA1} from '../../../../hs/requestGlobals.mjs'
import {dispatchMappers} from '../../../../redux/process/actionProcess.js'
import SocketForm from '../../../_reusable/SocketForm/SocketForm'
import {statePropMapper} from '../../../../redux/process/selectorProcess'
import {FULL_FRONTEND_URL} from "../../../../constants/env";

const makeMapStateToProps = statePropMapper([])
const RedirectAuthModal = function (props) {
    const dispatchMethod = ({channelName}) => {
        window.location = FULL_FRONTEND_URL + "auth2"
    }
    return (
        <SocketForm
            formMethod={'noneIsUsed'}
            formDispatch={dispatchMethod}
            socketForm
            onClose={props.closeModalMenu}
            onSuccess={() => null}
            description={'Would you like to sign-up for the PAA alpha?'}
            formFields={[]}
            formButtons={[
                {label: 'Join', icon: 'add', isSubmitButton: true},
                {label: 'Cancel', icon: 'add', isCloseButton: true},
            ]}
            formTypography={{label: 'Register Account'}}
            successMessage={'Account Registered'}
        />
    )
}

export default connect(makeMapStateToProps, null)(RedirectAuthModal)
