import React from 'react'
import {
    ListItemAvatarCustom,
    ListItemButtonCustom,
    ListItemTextCustom,
    AvatarCustom
} from '../../../Styled'
import { connect } from 'react-redux'
import { statePropMapper } from '../../../../../redux/process/selectorProcess'
import { RS1 } from '../../../../../redux/actions'

const makeMapStateToProps = statePropMapper([RS1.getUser('user')])

const UserLink = function (props) {
    if (props.type === 'category') {
        return (
            <ListItemButtonCustom button="false" types={['roleCategory']}>
                <ListItemTextCustom
                    types={['roleCategory']}
                    id={'LBC' + props.name}
                    primary={props.name}
                />
            </ListItemButtonCustom>
        )
    } else if (props.type === 'user') {
        return (
            <ListItemButtonCustom types={['user']} status={props.user.status}>
                <ListItemAvatarCustom types={['user']}
                    status={props.user.status}>
                    <AvatarCustom
                        types={['sidebarAvatar']}
                        // sx={{  }}
                        alt={props.userData.userName}
                        src={props.user.pictureId}
                    />
                </ListItemAvatarCustom>
                &nbsp;
                <ListItemTextCustom
                    types={['user']}
                    id={props.userData.id}
                    primary={props.userData.userName}
                    userdata={props.userData}
                    status={props.user.status}
                />
            </ListItemButtonCustom>
        )
    } else {
        return null
    }
}

export default connect(makeMapStateToProps, null)(UserLink)
