import { createCachedSlice } from '../cache/createCachedSlice.js'

const userSettings = createCachedSlice({
    name: '_userSettings',
    initialState: {},
    reducers: {
        updateUserSettings: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                const settings = { ...action.payload.userSettings }
                return settings
            },
        },
        updateAndSendUserSettings: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                const settings = { ...action.payload.userSettings }
                return settings
            },
        },
        updateNotifications: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                let newState = { ...state }
                //add me
                return newState
            },
        },
        processSubscriptions: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                // const settings = {...action.payload.userSettings}
                let newState = { ...state }
                newState.subscriptionList = action.payload.subscriptionList
                return newState
            },
        },
        processTransactions: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                // const settings = {...action.payload.userSettings}
                let newState = { ...state }
                newState.transactions = action.payload.transactions
                return newState
            },
        },
    },
})

export const userSettingsSlice = userSettings
export const userSettingsActions = userSettings.actions
export const _userSettings = userSettings.reducer
