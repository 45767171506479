import React from 'react'
import { connect } from 'react-redux'
import { RA1, RS1 } from '../../../../redux/actions.js'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import { dispatchMappers } from '../../../../redux/process/actionProcess.js'
import SocketForm from '../../../_reusable/SocketForm/SocketForm'
import { statePropMapper } from '../../../../redux/process/selectorProcess'

const makeMapStateToProps = statePropMapper([])
const mapDispatchToProps = dispatchMappers([
    SA1.addRole,
])

const AddRoleModal = function (props) {
    const dispatchMethod = ({ roleName }) => {
        props.dispatch.server.addRole(props.AS.activeServer, roleName,'addRole','addRole')
    }
    return (
        <SocketForm
            formMethod={'addRole'}
            formDispatch={dispatchMethod}
            socketForm
            onClose={props.closeModalMenu}
            onSuccess={() => {}}
            description={'Would you like to create a new role?'}
            formFields={[{ id: 'roleName', typographyLabel: 'Role Name' }]}
            formButtons={[
                { label: 'Create', icon: 'add', isSubmitButton: true },
            ]}
            formTypography={{ label: 'Add Role' }}
            successMessage={'Role Created.'}
        />
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(AddRoleModal)
