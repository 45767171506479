import React from 'react'
import {connect} from 'react-redux'
import {RA1} from '../../../../redux/actions.js'
import {SA1} from '../../../../hs/requestGlobals.mjs'
import {dispatchMappers} from '../../../../redux/process/actionProcess.js'
import SocketForm from '../../../_reusable/SocketForm/SocketForm'
import {store} from "../../../../redux/store";

const mapDispatchToProps = dispatchMappers([
    RA1.api.updateAPI,
    SA1.bugReport
])


const BugReportModal = function (props) {
    return (
        <SocketForm
            useConfirmationScreen={false}
            formMethod={'bugReport'}
            formDispatch={({name, notes, hasDump, bugType, severity}) => {
                const useDump = !!hasDump
                let computedDumpStr = ""
                if (useDump) {
                    computedDumpStr = JSON.stringify(store.getState())
                }
                var nVer = navigator.appVersion;
                var nAgt = navigator.userAgent;
                var browserName = navigator.appName;
                var fullVersion = '' + parseFloat(navigator.appVersion);
                var majorVersion = parseInt(navigator.appVersion, 10);
                var nameOffset, verOffset, ix;

// In Opera, the true version is after "OPR" or after "Version"
                if ((verOffset = nAgt.indexOf("OPR")) != -1) {
                    browserName = "Opera";
                    fullVersion = nAgt.substring(verOffset + 4);
                    if ((verOffset = nAgt.indexOf("Version")) != -1)
                        fullVersion = nAgt.substring(verOffset + 8);
                }
// In MS Edge, the true version is after "Edg" in userAgent
                else if ((verOffset = nAgt.indexOf("Edg")) != -1) {
                    browserName = "Microsoft Edge";
                    fullVersion = nAgt.substring(verOffset + 4);
                }
// In MSIE, the true version is after "MSIE" in userAgent
                else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
                    browserName = "Microsoft Internet Explorer";
                    fullVersion = nAgt.substring(verOffset + 5);
                }
// In Chrome, the true version is after "Chrome"
                else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
                    browserName = "Chrome";
                    fullVersion = nAgt.substring(verOffset + 7);
                }
// In Safari, the true version is after "Safari" or after "Version"
                else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
                    browserName = "Safari";
                    fullVersion = nAgt.substring(verOffset + 7);
                    if ((verOffset = nAgt.indexOf("Version")) != -1)
                        fullVersion = nAgt.substring(verOffset + 8);
                }
// In Firefox, the true version is after "Firefox"
                else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
                    browserName = "Firefox";
                    fullVersion = nAgt.substring(verOffset + 8);
                }
// In most other browsers, "name/version" is at the end of userAgent
                else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
                    (verOffset = nAgt.lastIndexOf('/'))) {
                    browserName = nAgt.substring(nameOffset, verOffset);
                    fullVersion = nAgt.substring(verOffset + 1);
                    if (browserName.toLowerCase() == browserName.toUpperCase()) {
                        browserName = navigator.appName;
                    }
                }
// trim the fullVersion string at semicolon/space if present
                if ((ix = fullVersion.indexOf(";")) != -1)
                    fullVersion = fullVersion.substring(0, ix);
                if ((ix = fullVersion.indexOf(" ")) != -1)
                    fullVersion = fullVersion.substring(0, ix);

                majorVersion = parseInt('' + fullVersion, 10);
                if (isNaN(majorVersion)) {
                    fullVersion = '' + parseFloat(navigator.appVersion);
                    majorVersion = parseInt(navigator.appVersion, 10);
                }
                let OSName = "Unknown OS";
                if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
                if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
                if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
                if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";
                props.dispatch.server.bugReport(name, notes, useDump, computedDumpStr, bugType, severity, {
                    browser: browserName,
                    browserVersion: fullVersion,
                    userAgent: navigator.userAgent,
                    os: OSName,
                }, 'bugReport', 'bugReport')
            }}
            socketForm
            onClose={props.closeModalMenu}
            onSuccess={props.closeModalMenu}
            description={'Do you wish to submit a bug report?'}
            formFields={[
                {
                    id: 'name',
                    typographyLabel: 'Name',
                    label: '',
                    require: true,
                    page: 0,
                },
                {
                    id: 'notes',
                    typographyLabel: 'Notes',
                    label: '',
                    require: true,
                    page: 0,
                },
                {
                    id: 'severity',
                    type: 'selectGroup',
                    typographyLabel: 'Severity',
                    formatter: (obj) => obj?.name,
                    defaultIndex: 0,
                    list: [
                        {value: "1 - Minor", name: "1 - Minor"},
                        {value: "2 - Moderate", name: "2 - Moderate"},
                        {value: "Moderate/High", name: "3 - Moderate/High"},
                        {value: "4 - High", name: "4 - High"},
                        {value: "5 - Severe", name: "5 - Severe"},
                    ],
                    requireError: 'Please choose a severity level.',
                    require: true,
                    page: 0,
                },
                {
                    id: 'bugType',
                    type: 'selectGroup',
                    typographyLabel: 'Bug Type',
                    formatter: (obj) => obj?.name,
                    defaultIndex: 0,
                    list: [
                        {value: "UI/UX", name: "UI/UX"},
                        {value: "Functionality", name: "Functionality"},
                        {value: "Video", name: "Video"},
                    ],
                    requireError: 'Please choose a bug type.',
                    require: true,
                    page: 0,
                },
                {
                    id: 'hasDump',
                    type: 'checkBoxSingle',
                    label: 'Include state dump?',
                    typographyLabel: '',
                    require: false,
                    page: 0,
                },
            ]}
            formButtons={[
                {
                    label: 'Create',
                    icon: 'add',
                    isSubmitButton: true,
                    isCloseButton: false,
                },
            ]}
            formTypography={{label: 'Bug Report'}}
            successMessage={'Bug report submitted.'}
        />
    )
}

export default connect(null, mapDispatchToProps)(BugReportModal)
