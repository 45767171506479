export const IconButtonCustomStyle = (mode, palette) => ({
    IconButtonCustom: {
        defaultProps: {},
        styleOverrides: { "nothing": {} }, altStyleOverrides: {
            root: {},
            channel: {},
            server: {},
            user: {},
            threadConnector: {
                transform: 'scale(0.8)',
                color: palette.text.flair,
                '&:hover': {}
            },
            mobileNavButton: {
                color: palette.text.flair,
            },
            mobileNavAlign: {
                flexDirection: 'column',
                width: '4rem',
            },
            friendsNav: {
                color: palette.text.secondary,
                transform: 'scale(.7)',
                marginLeft: 0,
                marginRight: 0,
            },
            fanMenuItem: {
                color: palette.text.primary,
                transition: '.5s',
            },
            threadCompressed: {
                padding: '0px 5px'
            },
            markdownButton: {
                margin: '0px 10px'
            },
            profileChatButton: {
                borderRadius: '15px 0px 15px 0px',
                border: `1px solid ${palette.text.flair}`,
                backgroundColor: palette.background.secondary
            },
            nohover: {
                '&:hover': { background: 'transparent' }
            },

            // ===== FAN MENU ANIMATIONS ==== //

            // 0.25 is the time for the last animation to finish. Only change this value.
            doAnimation7: // 0 delay, first animation.
                { transitionDelay: `${((0.25 / 4) * 0)}s` },
            doAnimation6: // 1 step delay
                { transitionDelay: `${((0.25 / 4) * 1)}s` },
            doAnimation5: // 2 step delay
                { transitionDelay: `${((0.25 / 4) * 2)}s` },
            doAnimation4: // 3 step delay
                { transitionDelay: `${((0.25 / 4) * 3)}s` },
            doAnimation3: // 4 step delay, or, 0.25s delay. last animation.
                { transitionDelay: `${((0.25 / 4) * 4)}s` },

        },
    },
})
