// import './wdyr.js';
import React, { useRef, useState, useEffect } from 'react'
import Chat from './Components/Chat'
import Auth2 from './Components/Authentication'
import MBProfilePage from './Components/Chat/Microblog'
import StreamPage from "./Components/Chat/Stream";
import ModalMenuV2 from "./Components/Chat/ModalMenuV2";
import Hidden from "./Components/Chat/Hidden";
import { connect } from "react-redux";
import { RS1 } from "./redux/actions";
import './App.css'
import * as ROUTES from './constants/routes'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import HomePage from './Components/Home/'
import { ButtonCustom, StyledDiv } from './Components/Chat/Styled'
import { statePropMapper } from "./redux/process/selectorProcess";
import { useLocation } from "react-router";
import Verify from "./verify";
import CardDevelopmentView from './Components/_reusable/Cards/UniversalCard/development';

const makeMapStateToProps = statePropMapper([
    RS1.store('_loaded', '_loaded'),
    RS1.store('_activeServer', '_activeServer'),
])

function FlowHandler(props) {
    return <></>
}

function App(props) {
    const chatWindowRef = useRef(null)
    return (
        <StyledDiv types={['_FVH']}>
            <div id="topLevelChat"
                style={{
                    position: 'absolute',
                    top: 0,
                    height: 'fit-content'
                }} />
            <StreamPage windowRef={chatWindowRef} />
            {props._loaded ? <Hidden server={props._activeServer} /> : null}
            {props._loaded ? <ModalMenuV2 /> : null}
            <FlowHandler />
            <Switch>
                <Route path={ROUTES.PROFILE}>
                    <MBProfilePage />
                </Route>
                <Route path={ROUTES.CHAT2}>
                    <Chat windowRef={chatWindowRef} />
                </Route>
                <Route path={ROUTES.AUTH2}>
                    <Auth2 />
                </Route>
                <Route path={ROUTES.VERIFY}>
                    <Verify />
                </Route>

                {/* TODO: TEST CODE REMOVE */}
                <Route path={ROUTES.CARDDEV}>
                    <CardDevelopmentView />
                </Route>
                {/* TEST CODE END */}

                <Route path={'/'}>
                    <HomePage />
                </Route>
            </Switch>
        </StyledDiv >
    )
}

export default connect(
    makeMapStateToProps,
    null
)(App)
