import { useEffect, useReducer, useRef, useState } from 'react'
import queryString from "querystring";
import {useTheme} from "@mui/joy";

export function defaultColorsForUsers(color,palette){
    let newColor
    switch(color){
        case '#010000':
            newColor = palette?.text?.flair
            break;
        case '#020000':
            newColor = palette?.text?.flair
            break;
        case '#030000':
            newColor = palette?.text?.flair
            break;
        case '#040000':
            newColor = palette?.text?.flair
            break;
        default:
            newColor = color
            break;
    }
    return newColor
}
export function getUserColors(color) {
    let theme = useTheme()
    return defaultColorsForUsers(color, theme.palette)
}


export function useContingentEffect(effect, deps, refreshDeps) {
    let refreshNeeded = useRef({ current: false })
    useEffect(() => {
        refreshNeeded.current = true
    }, [refreshDeps])
    useEffect(() => {
        if (refreshNeeded.current) {
            effect()
            refreshNeeded.current = false
        }
    }, [deps])
}

export function useQueryStrings(operations, conditions, repeatable) {
    const [specialCodeExecuted, setSpecialCodeExecuted] = useState({})
    const values = queryString.parse(location.search)
    useEffect(() => {
        Object.keys(operations).map((key, i) => {
            if ((values[key] !== undefined) && (specialCodeExecuted[key] !== true)) {
                console.error("FART")
                operations[key](values[key], conditions)
                if (!repeatable) {
                    setSpecialCodeExecuted((codesExec) => ({ ...codesExec, [key]: true }))
                }
                // setSpecialCodeExecuted((codesExec) => ({ ...codesExec, key: true }))
            }
        })
    }, [...conditions, values])
    return specialCodeExecuted
}
