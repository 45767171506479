import { createCachedSlice } from '../cache/createCachedSlice.js'

const channel = createCachedSlice({
    name: 'channels',
    initialState: {},
    reducers: {
        updateThreadOrder: {
            cached: false,
            parameters: ['channelId', 'threadOrder'],
            description: '',
            reduce: (state, action) => {
                const { channelId, threadOrder } = action.payload
                let oldServerState = { ...state[channelId] }
                oldServerState.activeThreads = threadOrder
                return {
                    ...state,
                    [channelId]: oldServerState,
                }
            },
        },
        addChannel: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                //Payload arguments
                const { id, content } = action.payload
                return {
                    ...state,
                    [id]: action.payload,
                }
            },
        },
        addChannels: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                //Payload arguments
                let newState = { ...state }
                for (let key in action.payload) {
                    if (action.payload.hasOwnProperty(key)) {
                        newState[key] = action.payload[key]
                    }
                }
                return newState
            },
        },
    },
})

const channelSlice = channel
const channelActions = channel.actions
const addChannels = channel.actions.addChannels
const channels = channel.reducer
export { channelSlice, addChannels, channelActions, channels }
