import React, { useState } from 'react'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import { connect } from 'react-redux'
import { RA1, RS1 } from '../../../../redux/actions'
import ImageDropzone from '../../../_reusable/ImageDropzone'
import { ButtonCustom, StyledDiv, TypographyCustom } from '../../Styled'
import { IntegrableTable } from '../../../Integrable/Table'
import { statePropMapper } from '../../../../redux/process/selectorProcess'
import { dispatchMappers } from '../../../../redux/process/actionProcess'

const makeMapStateToProps = statePropMapper([
    RS1.getServer('serverData'),
    RS1.store('imageStore', 'imageStore'),
    RS1.store('user', '_userId'),
])
const mapDispatchToProps = dispatchMappers([
    RA1._appState.openModalMenu,
    SA1.addEmoji,
    SA1.modifyServer,
])

const rows = ['Emoji Key:', '', 'Delete?']
const EmojiManagement = (props) => {
    const [emojiKey, setEmojiKey] = useState(0)
    const sendFunction = (fileToken) => {
        props.dispatch.server.addEmoji(props.server, 'blah', fileToken)
    }
    const emoji = props.serverData.emoji
    return (
        <StyledDiv types={['managementPageOuterDiv', '_FVH']}>
            <TypographyCustom gutterBottom variant="h4" component="div">
                Emoji Management
            </TypographyCustom>
            <div>
                <ImageDropzone
                    key={'serverEmoji' + emojiKey}
                    label={'Emoji'}
                    storeLocation={'Emoji' + props.server}
                    user={props.user}
                    description={'Drag and drop a new server emoji'}
                    fileName={'serverEmoji'}
                    sendFunction={sendFunction}
                    completeOperation={setEmojiKey}
                    defaultImage={''}
                    aspectRat={4 / 4}
                    useCropper={true}
                />
            </div>
            <div>
                <IntegrableTable
                    tableName={'Emoji'}
                    rowHeaders={['Emoji Key:', '', 'Delete?']}
                    rowData={emoji}
                    tableFormatter={[
                        (emojiData) => emojiData.name,
                        (emojiData) => (
                            <img
                                src={emojiData.fileData[0]}
                                alt={emojiData.fileData[0]}
                                width="60"
                                height="60"
                            />
                        ),
                        (emojiData) => (
                            <ButtonCustom onClick={() => { }}>
                                Delete Emoji
                            </ButtonCustom>
                        ),
                    ]}
                />
            </div>
        </StyledDiv>
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(EmojiManagement)
