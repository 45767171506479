import { useEffect } from "react";

export const useUpdateWatchedThreads = ({ threadsWatched, selectedThread, props }) => {
    // useEffect(() => {
    //     if (threadsWatched.filter(threadData => threadData.threadId ===
    //         selectedThread).length > 0) {
    //         props.dispatch.server.watchThread(
    //             props.server,
    //             props.channel,
    //             false,
    //             props.selectedThread)
    //     }
    // }, [props.threadMsgs.length])
    useEffect(() => {
        if (threadsWatched.filter(threadData => threadData.threadId ===
            selectedThread).length > 0) {
            props.dispatch.server.watchThread(
                props.server,
                props.channel,
                false,
                selectedThread)
        }
    }, [selectedThread])
}