import React, { useEffect } from 'react'
import { withAS } from "../../_contexts/withAppState";
import { dispatchMappers } from "../../../redux/process/actionProcess";
import { SA1 } from "../../../hs/requestGlobals.mjs";
import { RA1, RS1 } from "../../../redux/actions";
import { connect } from 'react-redux'
import { statePropMapper } from "../../../redux/process/selectorProcess";
import List from "@mui/material/List";
import { ListItemCustom, ListItemIconCustom, ListItemTextCustom } from "../Styled";
import AddIcon from "@mui/icons-material/Add";
import GenericCard from '../../_reusable/Cards/UniversalCard'
// import ViewListIcon from "@mui/icons-material/ViewList";

const makeMapStateToProps = statePropMapper([
    RS1.store('_appState', '_appState'),
    RS1.store('_userSettings', '_userSettings'),
    RS1.getServer('serverData'),
    RS1.getServerProductsSubscriptionsOwned("userSubscriptions", "userSubscriptions"),
    RS1.getServerProductsSubscriptions("subscriptions", "subscriptions")
])
const mapDispatchToProps = dispatchMappers([
    SA1.getProductsByServer,

])

const SubscriptionCard = (props) => {
    // console.log(props)
    return (
        <GenericCard
            cardType={'subscription'}
            displayMode={'halfDesktop'}
            product={props?.product}
            server={props?.server}   // TODO: temp code
            cardTestMode={false} />
    )
}

const SubscriptionButton = function (props) {
    let price = props.product.price
    const priceLen = props.product.price.length
    const displayPrice = "$" + price.slice(0, priceLen - 2) + "." + price.slice(-2)
    const onClick = () => {
        if (!props.isOwner) {
            props.openModalWithData('paymentModal', {
                selectedProduct: props.product,
                isCreator: false,
            })
        }
    }
    return (
        <ListItemCustom key={props.index} types={['channelActive']} button onClick={onClick}
            listitemid={props.index}>
            <ListItemTextCustom
                id={props.index + "SBLITC"}
                primary={props.product.name}
                secondary={displayPrice}
                types={['subscriptionButtonText1']}
            />
            <ListItemIconCustom types={['channel']}>
                {!props.isOwner ?
                    <AddIcon size={"small"} /> : null}
            </ListItemIconCustom>
        </ListItemCustom>
    )
}

const SubscriptionList = function (props) {
    return (
        <List>
            {props.subscriptions.map((product, index) =>
                <div id={"sub_product" + index} key={"sub_product" + index}
                    style={{
                        // maxWidth: '300px',
                        // padding: '10px'
                    }}>
                    {/* <SubscriptionButton product={product} index={index} {...props} /> */}
                    <SubscriptionCard product={product} index={index} {...props} />
                </div>)}
        </List>
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(SubscriptionList))
