export const watchThread = ({ props }) => () => {
    let threadDate = props.threadMsgs[0].metaData.hasOwnProperty('lastReplyDate') ?
        props.threadMsgs[0].metaData.hasOwnProperty.lastReplyDate :
        new Date(props.threadMsgs[0].createdAt)
            .toLocaleDateString('en-EN', {
                day: '2-digit',
                year: 'numeric',
                month: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            })
    let replies = props.threads.filter(thrd => thrd._id === props.selectedThread)[0].metaData.threadReplies
    props.dispatch.server.watchThread(
        props.threadMsgs[0].sId,
        props.threadMsgs[0].cId,
        false,
        props.selectedThread)
}