import { createCachedSlice } from '../cache/createCachedSlice.js'

const chatInput = createCachedSlice({
    name: 'chatInputs',
    initialState: {},
    reducers: {
        addImage: {
            cached: false,
            parameters: ['channelId', 'image', 'url'],
            description: '',
            reduce: (state, action) => {
                const { channelId, image, url } = action.payload
                if (state.hasOwnProperty(channelId)) {
                    let modifiedMessage = { ...state[channelId] }
                    modifiedMessage.image = image
                    modifiedMessage.url = url
                    modifiedMessage.hasImage = true
                    return {
                        ...state,
                        [channelId]: modifiedMessage,
                    }
                } else {
                    return {
                        ...state,
                        [channelId]: {
                            message: '',
                            image: image,
                            url: url,
                            hasImage: true,
                        },
                    }
                }
            },
        },
        addChatInput: {
            cached: false,
            parameters: ['channelId', 'message'],
            description: '',
            reduce: (state, action) => {
                const { channelId, message } = action.payload
                if (state.hasOwnProperty(channelId)) {
                    let modifiedMessage = { ...state[channelId] }
                    modifiedMessage.message = message
                    return {
                        ...state,
                        [channelId]: modifiedMessage,
                    }
                } else {
                    return {
                        ...state,
                        [channelId]: { message: message },
                    }
                }
            },
        },
        clear: {
            cached: false,
            parameters: ['channelId'],
            description: '',
            reduce: (state, action) => {
                const { channelId } = action.payload
                return {
                    ...state,
                    [channelId]: { message: '' },
                }
            },
        },
        removeImage: {
            cached: false,
            parameters: ['channelId'],
            description: '',
            reduce: (state, action) => {
                const { channelId } = action.payload
                if (state.hasOwnProperty(channelId)) {
                    let modifiedMessage = { ...state[channelId] }
                    modifiedMessage.image = null
                    modifiedMessage.url = null
                    modifiedMessage.hasImage = false
                    return {
                        ...state,
                        [channelId]: modifiedMessage,
                    }
                } else {
                    return {
                        ...state,
                        [channelId]: {
                            message: '',
                            url: null,
                            image: null,
                            hasImage: false,
                        },
                    }
                }
            },
        },
    },
})

export const chatInputActions = chatInput.actions
export const chatInputs = chatInput.reducer
export const chatInputSlice = chatInput
