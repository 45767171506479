export const SIGNUP = "SIGN UP"
export const SIGNIN = "SIGN IN"
export const ENV = 'dev'
export const DEVELOPMENT_SERVER_ID = "00001111000"
export const DEPLOYMENT_STAGE = "alpha"
export const FRONTEND_URL =
    ['development', 'test'].includes(process.env.NODE_ENV)
        ? document.location.host.split(':')[0] + ':3000/'
        : process.env.REACT_APP_FRONTEND_URL
export const BASE_URL = ['remotebackend'].includes(process.env.REACT_APP_ENV)
    ? 'paa-backend-node-staging.azurewebsites.net'
    : ['development', 'test'].includes(process.env.NODE_ENV)
        ? document.location.host.split(':')[0] + ':8080/'
        : process.env.REACT_APP_BACKEND_URL
export const HTTP_PROTOCOL =
    ['development', 'test'].includes(process.env.NODE_ENV) ? 'http://' : 'https://'
export const WS_PROTOCOL =
    ['development', 'test'].includes(process.env.NODE_ENV) ? 'ws://' : 'wss://'

export const FULL_FRONTEND_URL = HTTP_PROTOCOL + FRONTEND_URL
export const SITE_URL = ['remotebackend'].includes(process.env.REACT_APP_ENV)
    ? 'https://paa-backend-node-staging.azurewebsites.net/'
    : HTTP_PROTOCOL + BASE_URL
export const WS_URL = ['remotebackend'].includes(process.env.REACT_APP_ENV)
    ? 'https://paa-backend-node-staging.azurewebsites.net/'
    : WS_PROTOCOL + BASE_URL
const firebase_client_key = JSON.parse(process.env?.REACT_APP_FIREBASE_CLIENT ? process.env?.REACT_APP_FIREBASE_CLIENT : "{}")
export const FB_CONFIG = !['development', 'test'].includes(process.env.NODE_ENV) ? firebase_client_key : {
    apiKey: 'AIzaSyDIAKx2TXhpbZkyJNQrQc_1B1vNhIsdP6E',
    authDomain: 'hs7996.firebaseapp.com',
    projectId: 'hs7996',
    storageBucket: 'hs7996.appspot.com',
    messagingSenderId: '699668061325',
    appId: '1:699668061325:web:8fb9bc6e58fc57084616c4',
    measurementId: 'G-LVXY0WE09Y',
}
