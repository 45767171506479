import React, { useEffect, useCallback, useState, useRef } from "react";

import IconButton from "@mui/material/IconButton";
import Brightness1Icon from '@mui/icons-material/Brightness1';

export const VideoErrorDiv = (props) => {

    return (
        <div id="videoErrorDiv"
            style={{
                pointerEvents: "none",
                width: "100%",
                height: "100%",
                alignContent: "center",
                padding: "6px"
            }}>
            videoErrorDiv
            <div style={{
                height: "6%",
                color: "white",
                textAlign: "center",
                padding: "6px 6px 7px 6px",
                borderRadius: "10px",
                display: "inline-block"
            }}>
                <IconButton
                    color='error'
                >
                    <Brightness1Icon />
                </IconButton>
                {props.lastErrorContent}
            </div>
        </div>
    )
}