import React from 'react'
import { ButtonCustom } from '../../../Chat/Styled'
import { StyledDiv } from '../../../Chat/Styled'
import { dispatchMappers } from '../../../../redux/process/actionProcess'
import { RA1 } from '../../../../redux/actions'
import { connect } from 'react-redux'

const mapDispatchToProps = dispatchMappers([RA1._appState.updateAppState])

const ProductCard = function (props) {
    // # props is product, requires desc, id, price, inBuyMenu, isCreator
    // # optionally icon, name
    const buyProduct = () => {
        // store selected product in appstate,
        // get openModalMenu dispatch
        // execute openModalMenu
        props.dispatch._appState.updateAppState({
            modalMenu: 'paymentModal',
            modalMenuOpen: true,
            selectedProduct: {
                id: props.id,
                name: props.name,
                desc: props.desc,
                price: props.price,
                icon: props.icon,
            },
            isCreator: props.isCreator,
        })
    }

    return (
        <StyledDiv types={['productCard']}>
            {/*{props.icon && (*/}
            {/*    <StyledDiv types={['productCardIcon']}>*/}
            {/*        <img*/}
            {/*            src={props.icon}*/}
            {/*            style={{*/}
            {/*                width: '60px',*/}
            {/*                height: '60px',*/}
            {/*                objectFit: 'cover',*/}
            {/*            }}*/}
            {/*        />*/}
            {/*    </StyledDiv>*/}
            {/*)}*/}

            <StyledDiv types={['productCardText']}>
                {props.name && (
                    <StyledDiv types={['productName']}>{props.name}</StyledDiv>
                )}
                <StyledDiv types={['productDesc']}>{props.desc}</StyledDiv>
            </StyledDiv>

            <StyledDiv types={['productCardButton']}>
                {props.inBuyMenu ? (
                    <StyledDiv types={['productCardPrice']}>
                        {(parseInt(props.price) / 100).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                    </StyledDiv>
                ) : (
                    <ButtonCustom
                        id={props.id}
                        types={['square', 'productButton']}
                        onClick={buyProduct}
                    >
                        {(parseInt(props.price) / 100).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                    </ButtonCustom>
                )}
            </StyledDiv>
        </StyledDiv>
    )
}

export default connect(null, mapDispatchToProps)(ProductCard)
