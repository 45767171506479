import { createCachedSlice } from '../cache/createCachedSlice.js'

const api = createCachedSlice({
    name: 'api',
    initialState: {},
    reducers: {
        updateAPI: {
            cached: false,
            parameters: ['key', 'responseKey', 'data'],
            description: '',
            reduce: (state, action) => {
                let { key, responseKey, data } = action.payload
                let apiData = { ...state[key] }
                apiData.loaded = true
                apiData[responseKey] = data
                return { ...state, [key]: apiData }
            },
        },
        createKey: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                let { key } = action.payload
                return { ...state, [key]: { loaded: false } }
            },
        },
    },
})

export const apiActions = api.actions
export const apiResponses = api.reducer
export const apiSlice = api
