export const ListItemCustom = (mode, palette) => ({
    ListItemCustom: {
        defaultProps: {
            button: true,
            disableRipple: true,
        },
        styleOverrides:{"nothing":{}},altStyleOverrides: {
            root: {
                // color: 'darkslategray',
                paddingTop: '1px',
                fontSize: '12px',
                paddingBottom: '1px',
            },
            channelActive: (props) => {
                if (props.activeid === props.listitemid) {
                    return {
                        borderRadius: ("true" === props.premium) ? '10px 0px 10px 0px' : '0px',
                        border: ("true" === props.premium) ?
                            '1px solid ' + palette.text.flair :
                            '0px solid black',
                        color: palette.text.primary,
                        '&:hover': { backgroundColor: 'rgb(124,124,124,.1)' }
                    }
                } else {
                    return {
                        borderRadius: ("true" === props.premium) ? '10px 0px 10px 0px' : '0px',
                        border: ("true" === props.premium) ?
                            '1px solid ' + palette.text.flair :
                            '0px solid black',
                        color: palette.text.secondary,
                        '&:hover': { backgroundColor: 'rgb(124,124,124,.1)' },
                    }
                }
            },
            ownedSubscriptionEntry: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            },

            logoutButton: {
                background: palette.background.secondary,
                borderRadius: '10px 0px 10px 0px',
                color: palette.text.secondary,
                // border: `1px solid ${palette.text.flair}`
            },

            profileBtn: {
                '&:hover': {
                    backgroundColor: palette.background.flairSecondary ?
                        palette.background.flairSecondary :
                        'rgba(0, 0, 0, 0.04)'
                },
            },
            communityNavProfileButton: {
                color: palette.text.primary,
                backgroundColor: palette.background.flair,
                // '&:hover': {color: "red", zIndex: 5000}
                // '&:hover': {backgroundColor: "pink"}
            },
            channel: {
                '& .MuiListItemText-root': {
                    paddingLeft: '20px',
                    // backgroundColor:"red",
                    // '&:hover': {color: "red", zIndex: 5000}
                },
                // '&:hover': {backgroundColor: "pink"}
            },
            server: {
                '& .MuiListItemText-root': {
                    paddingLeft: '20px',
                    // backgroundColor:"red",
                    // '&:hover': {color: "red", zIndex: 5000}
                },
                marginTop: '2px',
                marginBottom: '2px',
            },
            user: {
                fontSize: '10px',
            },
            roleCategory: {
                color: palette.action.disabled,
                marginTop: '30px',
                marginBottom: '30px',
                fontSize: '16px',
            },
            friendsNav: {
                // borderBottom: '1px solid ' + palette.text.secondary,
                paddingTop: '5px',
                paddingBottom: '5px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
            },



            // Card styles:
            generalCard: {
                backgroundColor: palette.background.secondary,
                borderBottom: '1px solid ' + palette.text.secondary,
            },
            fullCard: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',

                padding: '1em',
                borderRadius: "60px 0% 60px 0%",
            },
            compressedCard: {
                position: 'absolute',
                top: '0',
                width: '100vw',
                height: '5em',
                padding: '0.5em',

                display: 'flex',
                flexDirection: 'row',
            },
            community: {
                flexDirection: 'row',
                alignItems: 'center',
            },
            communityHalfPadding: { paddingLeft: '1em' },
            communityPadding: {
                paddingLeft: '2em',
                paddingRight: '2em',
            },
            communityCompressed: { borderRadius: '0px' },
            tighterCurves: { borderRadius: "30px 0% 30px 0%" },
        },
    },
})
