import {useEffect} from "react";

export class journeyClass {
    constructor() {
        this.journeys = {}
    }

    addUpdate(name, label, data) {
        if (this.journeys[name].enabled) {
            const oldJourneyArray = this.journeys[name].journeyArray
            this.journeys[name].journeyArray = [...oldJourneyArray, {time: Date.now(), label, data}]
        }
    }

    newJourney(name, formatter, enabled) {
        this.journeys[name] = {
            formatter: formatter,
            journeyArray: [],
            enabled,
        }
    }

    replay(name) {

    }

}

const journeyTracker = new journeyClass()

function useJourney(journeyName, deps) {
    useEffect(() => {
        journeyTracker.addUpdate(journeyName, "useJourney-event", deps)
    }, deps)
    return null
}


const buildJLog = (knownJourneys) => {
    let JLog = {}
    const logTypes = ["log", "error", "info", "warn"]
    for (const journey of knownJourneys) {
        JLog[journey] = {}
    }
    for (const journey of knownJourneys) {
        for (const logType of logTypes) {
            JLog[journey][logType] = function () {
                return (msg) => {
                    journeyTracker.addUpdate(journey, `log-event[${logType}]`, msg)
                    const formatter = journeyTracker.journeys[journey].formatter
                    return console[logType].bind(window.console, formatter(msg, logType))
                }
            }();
        }
    }
    return JLog
}
const knownJourneys = ["threads", "redux", "video"]
journeyTracker.newJourney("redux", (msg, type) => msg, true)
journeyTracker.newJourney("video", (msg, type) => msg, true)
journeyTracker.newJourney("threads", (msg, type) => msg, true)
const JLog = buildJLog(knownJourneys)

console.log("-----Team Note-----")
console.log("A. All usages of the console in the frontend must be rewritten to use our custom journey system.")
console.log("B. To do this, you must import {JLog} from '...somePath.../journey.js' and use it as follows:'.")
console.log("---")
console.log('... JLog.threads.log("A log...")()')
JLog.threads.log("A log...")()
console.log("---")
console.log('... JLog.threads.error("An error...")()')
JLog.threads.error("An error...")()
console.log("---")
console.log('... JLog.threads.info("Some info...")()')
JLog.threads.info("Some info...")()
console.log("---")
console.log('... JLog.threads.warn("A warning...")()')
JLog.threads.warn("A warning...")()
console.log(journeyTracker)
console.log("-----End Team Note-----")

export {journeyTracker, JLog, useJourney}