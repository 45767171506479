export function parseMessage(message) {
    //definitions for all of the regexs

    var returnData = {};
    returnData.styling = [];
    returnData.urls = [];
    returnData.styling.push({ mode: 'rt', text: message })

    // const reg_italics = /(?<!\*)(\*)([^*]*)((?<!\*)\*(?!\*))(?!\*)/g
    // const reg_bold = /(?<!\*)(\*\*)([^*]*)((?<!\*)\*\*(?!\*))(?!\*)/g
    // const reg_boldItalics = /(?<!\*)(\*\*\*)([^*]*)((?<!\*)\*\*\*(?!\*))(?!\*)/g
    //
    // const reg_underline = /(?<!__)(__)([^*]*)((?<!__)__(?!__))(?!__)/g
    //
    // //url parsing regexs
    // const reg_https =
    //     /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/g
    // const reg_image = /\.(jpeg|jpg|gif|png|JPEG|JPG|GIF|PNG)$/g
    //
    // //setting up data to be processed
    // var returnData = {}
    // returnData.styling = []
    // returnData.urls = []
    //
    // var testMessage = message.slice()
    //
    // const specialStrings = []
    //
    // //find all special strings and push them into the specialStrings list
    // var array
    // while ((array = reg_italics.exec(testMessage)) !== null) {
    //     specialStrings.push({
    //         mode: 'i',
    //         text: array[2],
    //         start: array.index,
    //         end: reg_italics.lastIndex,
    //     })
    // }
    //
    // var array
    // while ((array = reg_bold.exec(testMessage)) !== null) {
    //     specialStrings.push({
    //         mode: 'b',
    //         text: array[2],
    //         start: array.index,
    //         end: reg_bold.lastIndex,
    //     })
    // }
    //
    // var array
    // while ((array = reg_boldItalics.exec(testMessage)) !== null) {
    //     specialStrings.push({
    //         mode: 'bi',
    //         text: array[2],
    //         start: array.index,
    //         end: reg_boldItalics.lastIndex,
    //     })
    // }
    //
    // var array
    // while ((array = reg_underline.exec(testMessage)) !== null) {
    //     specialStrings.push({
    //         mode: 'u',
    //         text: array[2],
    //         start: array.index,
    //         end: reg_underline.lastIndex,
    //     })
    // }
    //
    // var array
    // while ((array = reg_https.exec(testMessage)) !== null) {
    //     specialStrings.push({
    //         mode: 'a',
    //         text: array[0],
    //         start: array.index,
    //         end: reg_https.lastIndex,
    //     })
    //     if (array[0].match(reg_image)) {
    //         returnData.urls.push({ url: array[0], image: true })
    //     } else {
    //         returnData.urls.push({ url: array[0], image: false })
    //     }
    // }
    //
    // //build the return data
    // //check to see if there were any special strings
    // if (specialStrings.length !== 0) {
    //     //sort our special strings based on the starting index
    //     specialStrings.sort((a, b) => a.start - b.start)
    //
    //     //add any opening regular text (if there is any)
    //     if (testMessage.slice(0, specialStrings[0].start) !== '')
    //         returnData.styling.push({
    //             mode: 'rt',
    //             append: false,
    //             text: testMessage.slice(0, specialStrings[0].start),
    //         })
    //
    //     //loop through all of our special strings
    //     for (var i = 0; i < specialStrings.length; i++) {
    //         //check to see if the current special string is appended onto the last one
    //         if (
    //             i - 1 >= 0 &&
    //             specialStrings[i].start >= specialStrings[i - 1].start &&
    //             specialStrings[i].end <= specialStrings[i - 1].end
    //         ) {
    //             //if it is, fix the previous one (there is a bug here, if there is additional text within the previous one it will be removed)
    //             returnData.styling[returnData.styling.length - 1].text =
    //                 specialStrings[i].text
    //             //and then push the appended string onto the return data list
    //             returnData.styling.push({
    //                 mode: specialStrings[i].mode,
    //                 append: true,
    //                 text: specialStrings[i].text,
    //             })
    //
    //             //add any regular text between special strings
    //             if (i + 1 < specialStrings.length) {
    //                 if (
    //                     testMessage.slice(
    //                         specialStrings[i - 1].end,
    //                         specialStrings[i + 1].start
    //                     ) !== ''
    //                 )
    //                     returnData.styling.push({
    //                         mode: 'rt',
    //                         append: false,
    //                         text: testMessage.slice(
    //                             specialStrings[i - 1].end,
    //                             specialStrings[i + 1].start
    //                         ),
    //                     })
    //             } else {
    //                 if (testMessage.slice(specialStrings[i - 1].end) !== '')
    //                     returnData.styling.push({
    //                         mode: 'rt',
    //                         append: false,
    //                         text: testMessage.slice(specialStrings[i - 1].end),
    //                     })
    //             }
    //         } else {
    //             //it wasn't an appended string, so just push the new strings onto the list
    //             returnData.styling.push({
    //                 mode: specialStrings[i].mode,
    //                 append: false,
    //                 text: specialStrings[i].text,
    //             })
    //             //add any regular text between special strings
    //             if (i + 1 < specialStrings.length) {
    //                 if (
    //                     testMessage.slice(
    //                         specialStrings[i].end,
    //                         specialStrings[i + 1].start
    //                     ) !== ''
    //                 )
    //                     returnData.styling.push({
    //                         mode: 'rt',
    //                         append: false,
    //                         text: testMessage.slice(
    //                             specialStrings[i].end,
    //                             specialStrings[i + 1].start
    //                         ),
    //                     })
    //             } else {
    //                 if (testMessage.slice(specialStrings[i].end) !== '')
    //                     returnData.styling.push({
    //                         mode: 'rt',
    //                         append: false,
    //                         text: testMessage.slice(specialStrings[i].end),
    //                     })
    //             }
    //         }
    //     }
    // } else {
    //     //there weren't any special strings, so just send the regular text along
    //     returnData.styling.push({ mode: 'rt', text: testMessage })
    // }

    return returnData
}
