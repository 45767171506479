import React from "react"

import { StyledDiv, ButtonCustom, TypographyCustom, AvatarCustom, ListItemButtonCustom } from "../../../../../Chat/Styled"
import { Style } from "@mui/icons-material"
import { textAlign } from "@mui/system"
import { IconButton } from "@mui/material"

import { AvatarContainer, MessageHeader, TextContainer, ChatReplyHeader, ImageContainer } from "../../util"

// import { ButtonCustom } from '../../../Chat/Styled'
// import { StyledDiv } from '../../../Chat/Styled'
// import { dispatchMappers } from '../../../../redux/process/actionProcess'
// import { RA1 } from '../../../../redux/actions'
// import { connect } from 'react-redux'

const ChatMessageFull = function (props) {
    return (
        <StyledDiv
            types={[
                'recolorItem',
                (props.fullMsg) ? '' : 'generalCard',
                'fullCard',
                'messageCard',
                (props.fullMsg) ? 'featurefulCard' : 'tighterCurves',
                'chatMsgPadding']}>
            {props.fullMsg &&
                props.message.reply?.isReply ? (
                <div onClick={() => { props.shiftToMessage(props.message.reply) }}>
                    <ChatReplyHeader
                        userName={props.message.reply.user}
                        message={props.message.reply.message}
                        edited={props.message.reply.edited}
                        deleted={props.message.reply.deleted}
                        desktop={props.desktop}
                        half={false} />
                </div>
            ) : null}
            <StyledDiv types={['flexboxOuterDiv']}>
                <StyledDiv types={['flexboxInnerAvi']}>
                    <AvatarContainer
                        user={props.user}
                        message={props.message}
                        chatmsg={true}
                        push={props.push}
                        fullmsg={true}
                        desktop={props?.desktop} />
                </StyledDiv>
                <StyledDiv types={['flexboxInnerColumns']}>
                    <StyledDiv types={['flexboxColumnHeader']}>
                        <MessageHeader
                            user={props.user}
                            message={props.message}
                            fullMessageDate={props.fullMessageDate}
                            push={props.push}
                            setPage={props.setPage}
                            chatmsg={true}
                            fullMsg={props.fullMsg}
                            desktop={props.desktop}
                            half={false} />
                    </StyledDiv>
                    <StyledDiv
                        types={['flexboxColumnText']}
                        style={props.innerColumnsStyle}>

                        <TextContainer
                            key={props.message.mId + 'E'}
                            mId={props.message.mId + 'EA'}
                            useProductCard={
                                props.message?.metaData?.productId &&
                                props.message.message === 'hidden'
                            }
                            messageStyling={props.messageStyling}
                            edited={props.message.edited}
                            product={{ ...props.product }}

                            desktop={props.desktop}
                            fullMsg={props.fullMsg}
                            half={false} />
                    </StyledDiv>
                    <StyledDiv types={['flexboxColumnImg']}>
                        <ImageContainer
                            files={props.message.files}
                            urls={props.urls}
                            message={props.message}
                            desktop={props.desktop}
                            chatmsg={true}
                            fullmsg={true}
                            half={false} />
                    </StyledDiv>
                </StyledDiv>
            </StyledDiv>
        </StyledDiv>
    )
}

const ChatMessageCardDisplay = function (props) {

    // Every card display must have n different modes.
    // Which mode is rendered is determined by a switch.
    // However, these modes must be the same across all cards.
    // Suggested modes: full-desktop, half-desktop, compressed-desktop, full, compressed

    // #
    switch (props.displayMode) {
        case 'fullDesktop':
            return (
                <ChatMessageFull
                    desktop={true}
                    fullMsg={false}
                    {...props} />
            )
        case 'fullTotalDesktop':
            return (
                <ChatMessageFull
                    desktop={true}
                    fullMsg={true}
                    {...props} />
            )
        case 'halfDesktop':
            return (
                <StyledDiv className="recolor-item"
                    types={['generalCard',
                        'messageCard',
                        'halfCard']}>
                    <StyledDiv types={['flexboxOuterDiv', 'timelineOuter', 'halfCardWidth']}>
                        <StyledDiv types={['timelineInner', 'halfCardWidth']}>
                            <StyledDiv types={['cardImage', 'halfImage']}>
                                <ImageContainer
                                    files={props.message.files}
                                    urls={props.urls}
                                    message={props.message}
                                    half={true} />
                            </StyledDiv>
                            <StyledDiv types={['flexboxInnerColumns', 'timelineColumns', 'halfCardWidth', 'halfText']}>
                                <StyledDiv types={['flexboxColumnHeader']}>
                                    <MessageHeader
                                        user={props.user}
                                        message={props.message}
                                        fullMessageDate={props.fullMessageDate}
                                        desktop={true}
                                        half={true} />
                                </StyledDiv>
                                <StyledDiv types={[
                                    'flexboxColumnText',
                                    'timelineMessageBody',
                                    'fixTimelineMsgBody']}
                                    style={props.innerColumnsStyle}>
                                    <TextContainer
                                        key={props.message.mId + 'E'}
                                        mId={props.message.mId + 'EA'}
                                        useProductCard={
                                            props.message?.metaData?.productId &&
                                            props.message.message === 'hidden'}
                                        fullMsg={false}
                                        messageStyling={props.messageStyling}
                                        edited={props.message.edited}
                                        product={{ ...props.product }}
                                        desktop={true}
                                        half={false} />
                                </StyledDiv>
                            </StyledDiv>
                        </StyledDiv>

                    </StyledDiv>
                </StyledDiv>
            )
        case 'compressedDesktop':
            return (
                <StyledDiv className="recolor-item"
                    types={['generalCard',
                        'compressedDesktop',
                        'compressedTimeline']}>
                    <StyledDiv types={['flexboxOuterDiv', 'timelineOuter', 'halfCardWidth']}>
                        <StyledDiv types={['timelineInner', 'halfCardWidth']}>
                            <StyledDiv types={['flexboxInnerColumns', 'timelineColumns', 'halfCardWidth', 'halfText']}>
                                <StyledDiv types={['flexboxColumnHeader']}>
                                    <MessageHeader
                                        user={props.user}
                                        message={props.message}
                                        fullMessageDate={props.fullMessageDate}
                                        desktop={false}
                                        half={false}
                                        compressed={true} />
                                </StyledDiv>
                                <StyledDiv types={[
                                    'flexboxColumnText',
                                    'timelineMessageBody',
                                    'fixTimelineMsgBody']}
                                    style={props.innerColumnsStyle}>
                                    <TextContainer
                                        key={props.message.mId + 'E'}
                                        mId={props.message.mId + 'EA'}
                                        useProductCard={
                                            props.message?.metaData?.productId &&
                                            props.message.message === 'hidden'}
                                        fullMsg={false}
                                        messageStyling={props.messageStyling}
                                        edited={props.message.edited}
                                        product={{ ...props.product }}
                                        desktop={false}
                                        half={false} />
                                </StyledDiv>
                            </StyledDiv>
                        </StyledDiv>

                    </StyledDiv>
                </StyledDiv>
            )
        case 'fullTotal': // mobile
            return (
                <ChatMessageFull
                    desktop={false}
                    fullMsg={true}
                    {...props} />
            )
        case 'full':
            return (
                <ChatMessageFull
                    desktop={false}
                    fullMsg={false}
                    {...props} />
            )
        default:
            return (
                <StyledDiv className="recolor-item"
                    types={['generalCard',
                        'compressedCard',
                        'compressedTimeline']}>
                    <StyledDiv types={['flexboxOuterDiv', 'timelineOuter', 'halfCardWidth']}>
                        <StyledDiv types={['timelineInner', 'halfCardWidth']}>
                            <StyledDiv types={['flexboxInnerColumns', 'timelineColumns', 'halfCardWidth', 'halfText']}>
                                <StyledDiv types={['flexboxColumnHeader']}>
                                    <MessageHeader
                                        user={props.user}
                                        message={props.message}
                                        fullMessageDate={props.fullMessageDate}
                                        desktop={false}
                                        half={false}
                                        compressed={true} />
                                </StyledDiv>
                                <StyledDiv types={[
                                    'flexboxColumnText',
                                    'timelineMessageBody',
                                    'fixTimelineMsgBody']}
                                    style={props.innerColumnsStyle}>
                                    <TextContainer
                                        key={props.message.mId + 'E'}
                                        mId={props.message.mId + 'EA'}
                                        useProductCard={
                                            props.message?.metaData?.productId &&
                                            props.message.message === 'hidden'}
                                        fullMsg={false}
                                        messageStyling={props.messageStyling}
                                        edited={props.message.edited}
                                        product={{ ...props.product }}
                                        desktop={false}
                                        half={false} />
                                </StyledDiv>
                            </StyledDiv>
                        </StyledDiv>

                    </StyledDiv>
                </StyledDiv>
            )
    }
}

export const chatMessageCardPackage = {
    requirements: [
        'displayMode',
        'message',
        'fullMessageDate',
        'messageStyling',
        'user',
        'product',
        'innerColumnsStyle',
        'urls',
        'replies',
        'reactions',
        'highlights',
        'quotes',
        'push',
        'setPage',
        'shiftToMessage'
    ],
    CardDisplay: ChatMessageCardDisplay
}