import React, { useState } from "react";
import { Select, MenuItem, Stack, Slider } from "@mui/material";
import GenericCard from ".";
import { UniversalCardObject } from ".";
import { StyledDiv } from "../../../Chat/Styled";

const CardDevelopmentView = (props) => {

    // #
    const msq = 8
    const startsize = 50
    const cardTypes = Object.keys(UniversalCardObject)

    // #
    const [cardType, setCardType] = useState(cardTypes[0])
    const [displayMode, setDisplayMode] = useState('full')
    const [containerWidth, setContainerWidth] = useState(startsize)
    const [containerHeight, setContainerHeight] = useState(startsize)

    // #
    const handleCardChange = (event) => {
        setCardType(event.target.value)
    }
    const handleDisplayChange = (event) => {
        setDisplayMode(event.target.value)
    }
    const containerWidthChange = (event) => {
        setContainerWidth(event.target.value)
    }
    const containerHeightChange = (event) => {
        setContainerHeight(event.target.value)
    }


    return (
        <StyledDiv types={['cardTestBackground']}
            style={{
                height: '100%',
                paddingTop: '3em',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
            }}>
            <div style={{
                backgroundColor: '#008888',
                width: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <div style={{
                    width: 'fit-content',
                    display: 'flex',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '2em'
                    }}>
                        <span>Card Type</span>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={cardType}
                            label="Card Type"
                            onChange={handleCardChange}>
                            {cardTypes.map((cardType) =>
                                <MenuItem value={cardType}>{cardType}</MenuItem>
                            )}
                        </Select>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '2em'
                    }}>
                        <span>Display Mode</span>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={displayMode}
                            label="Display Mode"
                            onChange={handleDisplayChange}>
                            <MenuItem value={'full'}>Full Mobile</MenuItem>
                            <MenuItem value={'fullTotal'}>Full Message-View Mobile</MenuItem>
                            <MenuItem value={'compressed'}>Compressed Mobile</MenuItem>
                            <MenuItem value={'fullDesktop'}>Full Desktop</MenuItem>
                            <MenuItem value={'fullTotalDesktop'}>Full Message-View Desktop</MenuItem>
                            <MenuItem value={'halfDesktop'}>Half Desktop</MenuItem>
                            <MenuItem value={'compressedDesktop'}>Compressed Desktop</MenuItem>
                        </Select>
                    </div>
                </div>
                <div style={{
                    paddingLeft: '2em',
                    paddingRight: '2em',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    Width: {containerWidth * msq}px
                    <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                        <Slider aria-label="Width" value={containerWidth} onChange={containerWidthChange} />
                    </Stack>
                    Height: {containerHeight * msq}px
                    <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                        <Slider aria-label="Height" value={containerHeight} onChange={containerHeightChange} />
                    </Stack>
                </div>
            </div>

            <div style={{
                marginTop: '2em',
                // backgroundColor: '#880088',
                height: containerHeight * msq + 'px',
                width: containerWidth * msq + 'px',

                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start'
            }}>
                <GenericCard
                    cardType={cardType}
                    displayMode={displayMode}
                    server={'551905564528464896'}   // TODO: temp code
                    serverId={'551905564528464896'} // temp
                    userId={'551905043436524544'}     // temp
                    cardTestMode={true} />
            </div>
        </StyledDiv>
    )
}

export default CardDevelopmentView