import React from 'react'
import { connect } from 'react-redux'
import {RA1, RS1 } from '../../../redux/actions'
import { SA1 } from '../../../hs/requestGlobals.mjs'
import { useState, useRef } from 'react'
import { withAuth } from '../../_contexts/withAuth'
import { useEffect } from 'react'
import { dispatchMappers } from '../../../redux/process/actionProcess'
import { statePropMapper } from '../../../redux/process/selectorProcess.js'
import { withDesignAndState } from '../../_contexts/withDesignAndState'
import { StyledDiv} from '../Styled'
import Hidden from '../Hidden'
import { withAS } from "../../_contexts/withAppState";
import { useTheme } from '@mui/material'
import ThreadViewLoader from "../ThreadView/loader";

const mapDispatchToProps = dispatchMappers([
    RA1._appState.updateAppState,
    RA1.management.initManagement,
    SA1.getProfile,
    RA1._appState.openModalMenu,
    SA1.getProductsByServer,
    RA1._appState.openInfoModal,
])

const makeMapStateToProps = statePropMapper([
    RS1.getDMMeta('dmMeta'),
    RS1.store('_loaded', '_loaded'),
    RS1.store('_userId', '_userId'),
    RS1.store('_servers', '_servers'),
    RS1.store('_temp', '_temp'),
    RS1.store('allUsers', 'users'),
    RS1.store('_appState', '_appState'),
    RS1.store('chatServers', 'chatServers'),
])

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function MicroblogLayout(props) {
    const serverData = props.chatServers[props.AS.activeServer]
    const serverId = props.AS.activeServer
    const { palette } = useTheme()
    const [tabValue, setTabValue] = useState(0)
    const [firstTime, setFirstTime] = useState(false)
    const profileString = serverData.profile
    const isOwner = serverData.ownerId === props.userId
    const serverName = serverData.name
    let userData = {}
    const borderColor = "1px solid rgba(64 64 64 / 60%)"
    let isMember = false
    let isDM = serverData.serverMeta.dm
    if (isDM) {
        userData = props.allUsers[serverId]
    }
    let numOfUsers = serverData.users.length
    if (!props.AS.nav.state.isGuest) {
        isMember = serverData.users.map((user) => user.id).includes(props.userId)
    }
    useEffect(() => {
        if (!isDM) {
            props.getProductsByServer(serverId)
        }
    }, [])
    useEffect(() => {
        document.title = 'PAA (Alpha) -' + serverName;
    }, [])
    useEffect(() => {
        if (isMember && (serverId !== props.activeServer) && !isDM) {
            props.setActiveServer(serverId)
            props.getProductsByServer(serverId)
        }
    }, [isMember, props.activeServer])

    return (
        <StyledDiv types={['microblogInner']}>
            <StyledDiv types={['fixFocusBackground', '_FVH']} style={{
                flex: 'auto',
                // padding: '0px 4em',
                backgroundColor: palette.background.primary,
            }}>
                <ThreadViewLoader
                    key={props.AS.activeChannel}
                    server={props.AS.activeServer}
                    userId={props.userId}
                    standardThreadView={false}
                    user={props.userId}
                    AS={props.AS}
                    storeLocation={props.AS.activeChannel}
                    channel={props.AS.activeChannel}
                    desktop={props?.desktop}
                />
            </StyledDiv>
            {/* <StyledDiv style={{
                flex: '0 0 1.5em', backgroundColor: palette.background.primary
            }}></StyledDiv> */}
        </StyledDiv>
    )
}

function MBProfilePage(props) {
    const [redirect, setRedirect] = useState(null)
    const originRef = useRef({})
    let page = props.AS.nav.state.page
    let profileDataLoaded = props._appState.profileDataLoaded

    // useEffect(() => {
    //     originRef.current.server = props.AS.activeServer
    //     props.AS.nav.ops.setOnProfile(true)
    //     originRef.current.channel = props.AS.activeChannel
    //     return () => {
    //         props.AS.nav.ops.changeActiveServer(originRef.current.server)
    //         props.AS.nav.ops.changeActiveChannel(originRef.current.channel)
    //         props.AS.nav.ops.setOnProfile(false)
    //         props.dispatch._appState.updateAppState({profileDataLoaded: false})
    //     }
    // }, [])
    //
    // useEffect(() => {
    //     originRef.current.server = props.AS.activeServer
    //     originRef.current.channel = props.AS.activeChannel
    // }, [props._loaded])
    //

    useEffect(() => {
        if (redirect !== null) {
            props.dispatch._appState.updateAppState({ profileDataLoaded: false })
            props.AS.push(redirect)
        }
    }, [redirect])

    if (props._loaded === true && profileDataLoaded) {
        return (
            <div>
                <Hidden server={props.AS.activeServer} />
                <MicroblogLayout
                    AS={props.AS}
                    activeServer={props.AS.activeServer}
                    server={props.AS.activeServer}
                    activeChannel={props.AS.activeChannel}
                    userId={props._userId}
                    dmMeta={props.dmMeta}
                    viewingMode={props.viewingMode}
                    chatServers={props.chatServers}
                    setRedirect={setRedirect}
                    openModalMenu={props.AS.modals.ops.openModal}
                    openInfoModal={props.AS.modals.ops.openInfoModal}
                    allUsers={props.allUsers}
                    setActiveServer={props.AS.nav.ops.changeActiveServer}
                    page={page}
                    getProductsByServer={props.dispatch.server.getProductsByServer}
                />
            </div>
        )
    } else {
        return null
    }
}

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(withAuth(withDesignAndState(withAS(MBProfilePage))))
