import { createSlice, createAction } from '@reduxjs/toolkit'
import { setActiveChannel, setActiveServer, setServers } from './monostate/mono'
import { createCachedSlice } from '../cache/createCachedSlice.js'
import { clearNotifications } from './actions'

const chatServer = createCachedSlice({
    name: 'chatServers',
    initialState: {},
    reducers: {
        disableChatServer: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                const { id } = action.payload
                let oldServerState = { ...state }
                delete oldServerState[id]
                return oldServerState
            },
        },
        modifyProfile: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                const id = action.payload.profile.id
                let oldSubstate = { ...state[id], profile: action.payload.profile.profile }
                if (action.payload.profile?.threadsWatched) {
                    oldSubstate = { ...oldSubstate, threadsWatched: action.payload.profile.threadsWatched }
                }
                return { ...state, [id]: oldSubstate }
            },
        },
        recomputeServerList: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                let newServerList = []
                let key = ''
                for (key in state) {
                    newServerList.push(key)
                }
                action.asyncDispatch(setServers(newServerList))
                return {
                    ...state,
                }
            },
        },
        modifyChatServer: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                const { id } = action.payload
                const newServerState = { ...action.payload }
                let oldServerState = { ...state[id] }
                for (const key in newServerState) {
                    if (newServerState.hasOwnProperty(key)) {
                        oldServerState[key] = newServerState[key]
                    }
                }
                return {
                    ...state,
                    [id]: oldServerState,
                }
            },
        },
        addChatServerPartial: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                const { chatServer, serverId, preservedFields } = action.payload
                let oldServer = { ...state[serverId] }
                for (const keptField of preservedFields) {
                    chatServer[keptField] = oldServer[keptField]
                }
                return {
                    ...state,
                    [serverId]: chatServer,
                }
            },
        },
        addChatServer: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                const { id, content } = action.payload
                return {
                    ...state,
                    [id]: action.payload,
                }
            },
        },
        addChatServers: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                //Payload arguments
                let newState = { ...state }
                for (let key in action.payload) {
                    if (action.payload.hasOwnProperty(key)) {
                        newState[key] = action.payload[key]
                    }
                }
                return newState
            },
        },
    },
    extraReducers: {
        [setActiveServer]: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                const serverId = action.payload
                let server = { ...state[serverId] }
                if (server?.activeChannelInServer) {
                    action.asyncDispatch(
                        setActiveChannel({
                            channelId: server.activeChannelInServer,
                            serverId: serverId,
                        })
                    )
                } else {
                    action.asyncDispatch(
                        setActiveChannel({
                            channelId: server.profileId,
                            serverId: serverId,
                        })
                    )
                }
                return {
                    ...state,
                }
            },
        },
        [setActiveChannel]: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                if (typeof action.payload === 'string') {
                    return {
                        ...state,
                    }
                } else {
                    const { channelId, serverId } = action.payload
                    let server = { ...state[serverId] }
                    server.activeChannelInServer = channelId
                    return {
                        ...state,
                        [serverId]: server,
                    }
                }
            },
        },
    },
})

export const chatServesActions = chatServer.actions
export const recomputeServerList = chatServer.actions.recomputeServerList
export const addChatServerPartial = chatServesActions.addChatServerPartial
export const addChatServer = chatServer.actions.addChatServer
export const addChatServers = chatServer.actions.addChatServers
export const disableChatServer = chatServer.actions.disableChatServer
export const modifyChatServer = chatServer.actions.modifyChatServer
export const chatServers = chatServer.reducer
export const chatServersSlice = chatServers
