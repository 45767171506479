import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { RS1, RA1 } from '../../../../../redux/actions'
import { dispatchMappers } from '../../../../../redux/process/actionProcess'
import { statePropMapper } from '../../../../../redux/process/selectorProcess'
import { ButtonCustom, StyledDiv, vDH } from '../../../Styled'
import { useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import { trigger } from '../../../../../events'
import { Parser } from './parser.js'
import { withAS } from '../../../../_contexts/withAppState'
import { useHistory } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import moment from 'moment'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '10px',
    textAlign: 'center',
    borderRadius: 0,
}))

const makeMapStateToProps = statePropMapper([
    RS1.getUserMeta('userMeta'),
    RS1.getUser('userData'),
    RS1.getServerAbstractRoles('serverAbstractRoles'),
])

const mapDispatchToProps = dispatchMappers([
    RA1._appState.updateAppState,
])

const ReplyHeader = function (props) {
    return (
        <StyledDiv types={['threadFlexboxColumnHeader']}
            style={{ width: (props?.isModal) ? '20vw' : '40vw' }}>
            <ButtonCustom
                style={{
                    textTransform: 'none',
                    flex: '0 1 min-content',
                    padding: 0,
                    justifyContent: 'flex-start',
                    fontSize: '11px',
                    width: 'fit-content',
                }}
                onClick={() => { props.openReplyViewer(props.fullreply) }}>
                <div className="reply-div recolor-item" style={{
                    whiteSpace: 'nowrap',
                    color: '#6A6A6A',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                }}>
                    <b style={{ color: '#8A8A8A' }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌───&nbsp;
                    </b>
                    {!props.deleted ? (
                        <b style={{ color: '#8A8A8A' }}>{props.userName}</b>
                    ) : null}
                    {props.deleted ? <b style={{ color: '#8A8A8A' }}>⚉</b> : null}
                    <b>&nbsp;</b>
                    <span style={{ color: '#6A6A6A' }}>{props.message}</span>
                    {props.edited ? (
                        <small style={{ color: '#5A5A5A' }}>&nbsp;&nbsp;(...)</small>
                    ) : null}
                </div>
            </ButtonCustom>
        </StyledDiv>
    )
}

const ImageContainer = function (props) {
    const limited = {
        maxWidth: '7.5vw', //151.344 x 113.641
        maxHeight: '15vw'
    }
    const unlimited = {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
    }

    if (props.files?.length > 0) {
        //     border: props.theme.palette?.thread?.border && `1px solid ${props.theme.palette.text.flair}`
        return (
            <div style={limited}>
                <img
                    style={{
                        ...limited,
                        border:
                            props.theme.palette?.thread?.border &&
                            `1px solid ${props.theme.palette.text.flair}`,

                        width: '100%',
                        height: '100%',
                    }}
                    onClick={props.imageClicked}
                    src={props.files[0]} //TODO: TEMP
                />
            </div>
        )
    } else {
        return null
    }
}

const MessageHeader = function (props) {
    let color = props.theme.palette.text.secondary
    let backupColor = props.theme.palette.text.flair

    return (
        <StyledDiv types={['threadFlexboxColumnHeader']}
            style={{ width: (props?.isModal) ? 'fit-content' : '40vw' }}>
            <div>
                <b
                    style={{
                        color: color.startsWith('rgba') ? backupColor : color,
                    }}
                >
                    {props.user?.userName ? props.user.userName : props.message.uId}
                    &emsp;
                </b>
                <span style={{ fontSize: '11px' }}>
                    {'(' + props.fullMessageDate + ')'}
                </span>
            </div>
            <div>

                {/* TODO: in reply modal view, have 'jump to message' button */}
                &emsp;
                <ButtonCustom
                    style={(!props.isModal) ? {} : {
                        fontSize: '11px',
                        margin: '0px'
                    }}
                    onClick={() => {
                        trigger('setChatInput:reply', {
                            isReply: true,
                            message: props.message.message,
                            user: props.user.userName,
                            edited: props.message.edited,
                            attachment: props.message.files > 0,
                            mData: {
                                uId: props.message.uId,
                                mId: props.message.mId,
                                cId: props.message.cId,
                                sId: props.message.sId,
                            },
                        })
                    }}>Reply</ButtonCustom>
                &emsp;
                <ButtonCustom
                    style={(!props.isModal) ? {} : {
                        fontSize: '11px',
                        margin: '0px'
                    }}
                    onClick={() => {
                        trigger('setChatInput:reply', {
                            isReply: true,
                            message: props.message.message,
                            user: props.user.userName,
                            edited: props.message.edited,
                            attachment: props.message.files > 0,
                            mData: {
                                uId: props.message.uId,
                                mId: props.message.mId,
                                cId: props.message.cId,
                                sId: props.message.sId,
                            },
                        })
                        trigger('setChatInput:quote', {
                            message: '>' + props.message.message + '\n',
                        })
                    }}>Quote</ButtonCustom>
            </div>
        </StyledDiv>
    )
}

const ThreadMessage = (props) => {
    const theme = useTheme()
    const [shownReplies, setShownReplies] = useState([])
    let message = props.message
    let user = { ...props.userData }

    const history = useHistory()

    const openReplyViewer = (replies) => {
        history.push('/chat2?thread=' + props.message.tId)
        props.AS.modals.ops.openModalWithData('threadreplyviewer', {
            viewedReplies: replies,
            selectedThread: props.message,
        })
        // props.dispatch._appState.updateAppState({
        //     modalMenu: 'threadreplyviewer',
        //     modalMenuOpen: true,
        //     viewedReplies: replies,
        //     selectedThread: null,
        // })
    }

    const openImageViewer = () => {

        let { images, imgIndex } = props.getImgs(props.msgIndx)

        props.AS.modals.ops.openModalWithData('threadimgviewer', {
            images: images,
            imgIndex: (props.isModal) ? imgIndex - 1 : imgIndex
        })
        // props.dispatch._appState.updateAppState({
        //     modalMenu: 'threadimgviewer',
        //     modalMenuOpen: true,
        //     images: images,
        //     imgIndex: (props.isModal) ? imgIndex - 1 : imgIndex,
        // })
    }

    useEffect(() => {
        let tmpReplies = []
        props?.replies?.map((reply, i) => {
            if (i < 2) { tmpReplies.push(reply) }
        });
        setShownReplies(tmpReplies)
    }, [props.replies])

    let userMeta = props.userMeta
    user.color = props.serverAbstractRoles.find(
        (role) => role.id === userMeta.roles[0]
    ).perms.color

    let product = { ...message?.metaData?.product }
    let innerColumnsStyle = {
        whiteSpace: 'pre-wrap',
        width: (!props.isModal) ? '40vw' : '100%'
    }

    if (message?.metaData?.productId && message.message === 'hidden') {
        product.desc = product.description
    }

    let fullMessageDate = moment(message.createdAt).format(
        'MMMM Do YYYY, h:mm:ss a'
    )

    if (message.deleted) {
        return (
            <StyledDiv
                types={['threadFlexboxOuterDiv']}
                style={{
                    justifyContent: 'center',
                    paddingTop: (props.index !== 0) ? 0 :
                        (props.isModal) ? "10px" : `calc(${vDH(3)} + 3em)`,
                    // paddingLeft: (props?.isModal) ? 0 : '10px'
                }}
            >
                <StyledDiv types={['threadFlexboxInnerColumns']}>
                    <StyledDiv types={['threadFlexboxColumnText']}>
                        &nbsp;
                    </StyledDiv>
                </StyledDiv>
            </StyledDiv>
        )
    } else {
        let fileStyle = {
            marginLeft: '2em',
            width: (!props.isModal) ? '10%' : '65%'
        }
        // paddingTop: (props.index == 0) ? "calc(3vh + 3em)" : "0em",
        return (
            <StyledDiv types={['singleThreadOuterWrapper']}
                style={{
                    width: (!props?.isModal) ? '100%' : '45vw',
                    backgroundColor: (props.isOP) ? 'none' : (props.isModal) ? props.theme.palette.background.primary : 'none',
                    paddingTop:
                        ((props.index <= 0) ?
                            (props?.isModal ? 30 : `calc(${vDH(3)} + 3em)`) :
                            (message?.files?.length ? 10 : 0)),
                }}>
                <StyledDiv
                    types={['threadMessageWrapper']}
                    style={{ marginLeft: (props?.isModal) ? 0 : "3em", width: (props?.isModal) ? '80%' : 'fit-content' }}>

                    <StyledDiv
                        types={['threadFlexboxOuterDiv']}
                        style={{ flexDirection: 'row' }}>
                        <StyledDiv types={['threadFlexboxColumnImg']}>
                            {message?.files?.length ? (
                                <ImageContainer
                                    imageClicked={openImageViewer}
                                    getImgs={props.getImgs}
                                    imgIndx={props.msgIndx}
                                    theme={theme}
                                    files={message.files}
                                    urls={props.urls}
                                    message={message}
                                />
                            ) : null}
                        </StyledDiv>

                        <StyledDiv
                            style={fileStyle}
                            types={['threadFlexboxInnerColumns']}>

                            {(message.reply?.isReply && (!props?.isModal || (props?.numMessages === 1) || (props?.isOP))) ? (
                                <ReplyHeader
                                    userName={message.reply.user}
                                    message={message.reply.message}
                                    edited={message.reply.edited}
                                    deleted={message.reply.deleted}
                                    isModal={props?.isModal}
                                    fullreply={props?.repliedTo}
                                    openReplyViewer={openReplyViewer}
                                />
                            ) : null}
                            <MessageHeader
                                theme={theme}
                                fullMessageDate={fullMessageDate}
                                message={message}
                                user={user}
                                isModal={props?.isModal}
                            />
                            <StyledDiv
                                types={['threadFlexboxColumnText']}
                                style={innerColumnsStyle}
                            >
                                <Parser text={message.message} />
                            </StyledDiv>
                        </StyledDiv>
                    </StyledDiv>
                </StyledDiv>


                {(shownReplies.length > 0) ?
                    <div style={{
                        flex: '0 1 auto',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignContent: 'center',
                        overflowX: 'hidden',
                        overflowY: 'hidden',
                        fontSize: '0.7em',
                        paddingTop: '0.4em',
                        paddingLeft: '1em'
                    }}>

                        {!props.isModal ?
                            <div style={{ flex: '0 1 auto', paddingTop: '0.2em' }}>
                                Replies:
                            </div> : null}

                        {!props.isModal ?
                            shownReplies.map((reply) =>
                                <ButtonCustom
                                    style={{
                                        textTransform: 'none',
                                        flex: '0 1 min-content',
                                        padding: 0,
                                        justifyContent: 'flex-start',
                                        fontSize: '11px',
                                        width: 'fit-content',
                                    }}
                                    onClick={() => { openReplyViewer([reply]) }}>
                                    {moment(reply.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                                </ButtonCustom>)
                            : null}
                        <ButtonCustom
                            style={{
                                textTransform: (!props?.isModal) ? 'none' : 'uppercase',
                                flex: '0 1 min-content',
                                padding: 0,
                                justifyContent: 'flex-start',
                                alignContent: 'center',
                                fontSize: '11px',
                                width: 'fit-content',
                                marginTop: (!props?.isModal) ? 0 : '2px',
                                marginLeft: (!props?.isModal) ? 0 : '2em',
                            }}
                            onClick={() => { openReplyViewer(props?.replies) }}>
                            {!props.isModal ? 'show more' : 'show replies'}
                        </ButtonCustom>
                    </div> : null
                }

            </StyledDiv >
        )
    }
}

// 
export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(ThreadMessage))
