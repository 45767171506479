import { useState, useEffect, useRef } from "react";
import InfoModal from "../index";
import { TypographyCustom, StyledDiv } from "../../../Styled";
import { Tooltip } from "@mui/material";

const WelcomeInfoModal = (props) => {

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);
    const [fontLineHeight, setFontLineHeight] = useState(1)
    const [dimensions, setDimensions] = useState([0, 0])
    const columnOneRef = useRef(null)
    const columnTwoRef = useRef(null)
    const columnThreeRef = useRef(null)

    useEffect(() => {
        // retrieving the line height attribute from a column,
        // since text formatting is the same across columns
        let textHeight = window.
            getComputedStyle(columnOneRef.current, null).
            getPropertyValue("line-height")
        // extracting the integer value from this attribute
        textHeight = parseInt(textHeight.substring(0, textHeight.length - 2))

        setFontLineHeight(textHeight)
    }, [])

    useEffect(() => {
        let newDimensions = [...dimensions]

        newDimensions[0] = Math.max(
            Math.ceil(columnOneRef.current.offsetHeight / fontLineHeight),
            Math.ceil(columnTwoRef.current.offsetHeight / fontLineHeight),
            Math.ceil(columnThreeRef.current.offsetHeight / fontLineHeight))

        newDimensions[1] = Math.max(
            columnOneRef.current.offsetWidth,
            columnTwoRef.current.offsetWidth,
            columnThreeRef.current.offsetWidth)

        setDimensions(newDimensions)

    }, [columnOneRef?.current?.offsetHeight,
    columnTwoRef?.current?.offsetHeight,
    columnThreeRef?.current?.offsetHeight])

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <InfoModal
            title={"Welcome!"}
            subtitle={"Here are some things you can do with our platform..."}
            modalWidth={'90%'} hideIcon={true} {...props}>

            <StyledDiv types={['welcomeModalContainer']}>
                <StyledDiv types={['welcomeModalColumn']}>
                    <TypographyCustom
                        types={['welcomeModalHeader']}
                        noWrap={true}
                        variant="h4">
                        Cultivate
                    </TypographyCustom>

                    <TypographyCustom types={['welcomeModalCaption']}
                        dimensions={dimensions}
                        fontLineHeight={fontLineHeight}
                        window={windowSize}>
                        <span ref={columnOneRef}>
                            Interact with your users using our chat integration! Develop a community that will remain active even during content downtime.
                        </span>
                    </TypographyCustom>

                    {/* <Tooltip placement="left" title={
                        <img width="300%" src="/chat.png" />
                    }>
                        <span> */}
                    <StyledDiv types={['welcomeModalImage']}>
                        <img width="100%" src="/chat.png" />
                    </StyledDiv>
                    {/* </span>
                    </Tooltip> */}
                </StyledDiv>
                <StyledDiv types={['welcomeModalColumn']}>
                    <TypographyCustom
                        types={['welcomeModalHeader']}
                        noWrap={true}
                        variant="h4">
                        Monetize
                    </TypographyCustom>


                    <TypographyCustom types={['welcomeModalCaption']}
                        dimensions={dimensions}
                        fontLineHeight={fontLineHeight}
                        window={windowSize}>
                        <span ref={columnTwoRef}>
                            Schedule and book appointments, engage with your community, and manage subscriptions to premium content, all from the profile page!
                        </span>
                    </TypographyCustom>

                    {/* <Tooltip placement="left" title={
                        <img width="300%" src="/profile.png" />
                    }>
                        <span> */}
                    <StyledDiv types={['welcomeModalImage']} style={{ marginTop: '5%' }}>
                        <img width="100%" src="/profile.png" />
                    </StyledDiv>
                    {/* </span>
                    </Tooltip> */}
                </StyledDiv>
                <StyledDiv types={['welcomeModalColumn']}>
                    <TypographyCustom
                        types={['welcomeModalHeader']}
                        noWrap={true}
                        variant="h4">
                        Enrich
                    </TypographyCustom>

                    <TypographyCustom types={['welcomeModalCaption']}
                        dimensions={dimensions}
                        fontLineHeight={fontLineHeight}
                        window={windowSize}>
                        <span ref={columnThreeRef}>
                            Whether you're writing blog posts, discussing assignments, or simply creating a moodboard, threads allow you to develop curated discourse.
                        </span>
                    </TypographyCustom>


                    {/* <Tooltip placement="left" title={
                        <img width="300%" src="/threads.png" />
                    }>
                        <span> */}
                    <StyledDiv types={['welcomeModalImage']}>
                        <img width="100%" src="/threads.png" />
                    </StyledDiv>
                    {/* </span>
                    </Tooltip> */}
                </StyledDiv>
            </StyledDiv>
            {/* Welcome to the PAA closed alpha test. You have already been automatically added you
            to our private development community
            server. There you can interact with us and provide product feedback to help us build
            and release the strongest product possible. We deeply appreciate your participation. */}
        </InfoModal >)
}
export default WelcomeInfoModal