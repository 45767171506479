import React from "react"

import { Style } from "@mui/icons-material"

import {
    StyledDiv,
    ButtonCustom,
    TypographyCustom,
    IconButtonCustom,
    BadgeCustom,
    AvatarCustom,
    ListItemButtonCustom
} from "../../../../../Chat/Styled"
import {
    AvatarContainer,
    MessageHeader,
    TextContainer,
    ReplyHeader,
    ImageContainer,
    ThreadImageContainer,
    TimelineButtons
} from "../../util"
import { getUserColors } from "../../../../../../hooks";
import { MarkdownContainer } from "../../../../Markdown";

const BlogMessageFull = (props) => {

    let color = getUserColors(props?.user?.color)
    if (props.message.deleted) {
        return <div
            style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            Deleted Message
        </div>
    }
    return (
        <StyledDiv
            types={[
                'recolorItem',
                (props.fullMsg) ? '' : 'generalCard',
                'fullCard',
                'messageCard',
                (props.fullMsg) ? 'featurefulCard' : 'tighterCurves',
                'timelinePadding',
                (props.desktop) ? 'blogMessageContainer' : 'blogMessageMobile']}>
            {(props.fullMsg &&
                props?.message?.reply?.isReply) ? (
                <ReplyHeader
                    userName={props.message.reply.user}
                    message={props.message.reply.message}
                    edited={props.message.reply.edited}
                    deleted={props.message.reply.deleted}
                    messageObj={props?.message}
                // replyClicked={props?.replyClicked}
                />
            ) : null}

            <StyledDiv types={['flexboxOuterDiv', 'timelineOuter', props.desktop ? null : 'blogMobilePaddingLeft']}>
                <StyledDiv types={['timelineInner', 'threadBorder']}
                    hasFiles={props?.message?.files?.length}>
                    {/* <StyledDiv types={['threadFlexboxColumnImg', 'threadBlogImg']}>

                    </StyledDiv> */}
                    <StyledDiv types={['flexboxInnerColumns', 'threadFlexboxInnerColumns']}
                        style={{
                            marginLeft: (props.desktop) ? '2em' : '0em',
                            width: (!props.isModal) ? '10%' : '65%'
                        }}
                        fullMsg={props?.fullMsg}>
                        <StyledDiv types={['flexboxColumnHeader']}>
                            {props?.message?.files?.length ? (
                                <ThreadImageContainer
                                    imageClicked={props?.openImageViewer}
                                    getImgs={props.getImgs}
                                    imgIndx={props.msgIndx}
                                    theme={props?.theme}
                                    files={props?.message.files}
                                    urls={props.urls}
                                    useUnlimited={true}
                                    message={props?.message}
                                />
                            ) : null}

                            {props.isReply ? null :
                                <TypographyCustom
                                    variant="h3" types={['blogTitle']}>{props?.message?.threadTitle}</TypographyCustom>}
                            <StyledDiv types={['blogPostHeader']}>

                                <MessageHeader
                                    user={props.user}
                                    message={props.message}
                                    fullMessageDate={props.fullMessageDate}
                                    desktop={props.desktop}
                                    blogPost={true} />
                            </StyledDiv>

                        </StyledDiv>
                        <StyledDiv types={[
                            'flexboxColumnText',
                            'timelineMessageBody',
                            'fixTimelineMsgBody']}
                            style={props.innerColumnsStyle}>
                            <MarkdownContainer message={props.message.message} />
                        </StyledDiv>
                        {props.fullMsg ?
                            <TimelineButtons
                                isReply={props.isReply}
                                likedByUser={props?.likedByUser}
                                fullMsg={props.fullMsg}
                                tId={props.tId} mId={props.message.mId}
                                timelineOps={props.timelineOps}
                                replies={props.replies}
                                reactions={props.reactions}
                                highlights={props.highlights}
                                quotes={props.quotes}
                                desktop={props.desktop}
                                replyToMessage={props.replyToMessage} />
                            : null
                        }
                    </StyledDiv>
                </StyledDiv>

                {!props.fullMsg ?
                    <TimelineButtons
                        isReply={props.isReply}
                        likedByUser={props?.likedByUser}
                        fullMsg={props.fullMsg}
                        tId={props.tId} mId={props.message.mId}
                        timelineOps={props.timelineOps}
                        replies={props.replies}
                        reactions={props.reactions}
                        highlights={props.highlights}
                        quotes={props.quotes}
                        desktop={props.desktop}
                        replyToMessage={props.replyToMessage} />
                    : null
                }

            </StyledDiv>
        </StyledDiv>
    )
}


const BlogMessageCardDisplay = function (props) {

    // Every card display must have n different modes.
    // Which mode is rendered is determined by a switch.
    // However, these modes must be the same across all cards.
    // Suggested modes: full-desktop, half-desktop, compressed-desktop, full, compressed

    // #
    switch (props.displayMode) {
        case 'fullTotalDesktop':
            return (
                <BlogMessageFull
                    desktop={true}
                    fullMsg={true}
                    {...props} />
            )
        case 'fullDesktop':
            return (
                <BlogMessageFull
                    desktop={true}
                    fullMsg={false}
                    {...props} />
            )
        case 'halfDesktop':
            return (
                <StyledDiv className="recolor-item"
                    types={['generalCard',
                        'messageCard',
                        'halfCard']}>
                    <StyledDiv types={['flexboxOuterDiv', 'timelineOuter', 'halfCardWidth']}>
                        <StyledDiv types={['timelineInner', 'halfCardWidth']}>
                            <StyledDiv types={['cardImage', 'halfImage']}>
                                <ImageContainer
                                    files={props.message.files}
                                    urls={props.urls}
                                    message={props.message}
                                    half={true} />
                            </StyledDiv>
                            <StyledDiv types={['flexboxInnerColumns', 'timelineColumns', 'halfCardWidth', 'halfText']}>
                                <StyledDiv types={['flexboxColumnHeader']}>
                                    <MessageHeader
                                        user={props.user}
                                        message={props.message}
                                        fullMessageDate={props.fullMessageDate}
                                        desktop={true}
                                        half={true} />
                                </StyledDiv>
                                <StyledDiv types={[
                                    'flexboxColumnText',
                                    'timelineMessageBody',
                                    'fixTimelineMsgBody']}
                                    style={props.innerColumnsStyle}>
                                    <TextContainer
                                        key={props.message.mId + 'E'}
                                        mId={props.message.mId + 'EA'}
                                        useProductCard={
                                            props.message?.metaData?.productId &&
                                            props.message.message === 'hidden'}
                                        fullMsg={false}
                                        messageStyling={props.messageStyling}
                                        edited={props.message.edited}
                                        product={{ ...props.product }}
                                        desktop={true}
                                        half={true} />
                                </StyledDiv>
                                <TimelineButtons
                                    timelineOps={props.timelineOps}
                                    replies={props.replies}
                                    tId={props.tId} mId={props.message.mId}
                                    reactions={props.reactions}
                                    highlights={props.highlights}
                                    quotes={props.quotes}
                                    desktop={true}
                                    half={true} />
                            </StyledDiv>
                        </StyledDiv>

                    </StyledDiv>
                </StyledDiv>
            )
        case 'compressedDesktop':
            return (
                <StyledDiv className="recolor-item"
                    types={['generalCard',
                        'compressedDesktop',
                        'compressedTimeline']}>
                    <StyledDiv types={['flexboxOuterDiv', 'timelineOuter', 'halfCardWidth']}>
                        <StyledDiv types={['timelineInner', 'halfCardWidth']}>
                            <StyledDiv types={['flexboxInnerColumns', 'timelineColumns', 'halfCardWidth', 'halfText']}>
                                <StyledDiv types={['flexboxColumnHeader']}>
                                    <MessageHeader
                                        user={props.user}
                                        message={props.message}
                                        fullMessageDate={props.fullMessageDate}
                                        desktop={false}
                                        half={false}
                                        compressed={true} />
                                </StyledDiv>
                                <StyledDiv types={[
                                    'flexboxColumnText',
                                    'timelineMessageBody',
                                    'fixTimelineMsgBody']}
                                    style={props.innerColumnsStyle}>
                                    <TextContainer
                                        key={props.message.mId + 'E'}
                                        mId={props.message.mId + 'EA'}
                                        useProductCard={
                                            props.message?.metaData?.productId &&
                                            props.message.message === 'hidden'}
                                        fullMsg={false}
                                        messageStyling={props.messageStyling}
                                        edited={props.message.edited}
                                        product={{ ...props.product }}
                                        desktop={false}
                                        half={true} />
                                </StyledDiv>
                                <TimelineButtons
                                    tId={props.tId} mId={props.message.mId}
                                    timelineOps={props.timelineOps}
                                    replies={props.replies}
                                    reactions={props.reactions}
                                    highlights={props.highlights}
                                    quotes={props.quotes}
                                    desktop={false}
                                    half={false}
                                    compressed={true} />
                            </StyledDiv>
                        </StyledDiv>

                    </StyledDiv>
                </StyledDiv>
            )
        case 'fullTotal': // mobile
            return (
                <BlogMessageFull
                    desktop={false}
                    fullMsg={true}
                    {...props} />
            )
        case 'full':
            return (
                <BlogMessageFull
                    desktop={false}
                    fullMsg={false}
                    {...props} />
            )
        default:
            return (
                <StyledDiv className="recolor-item"
                    types={['generalCard',
                        'compressedCard',
                        'compressedTimeline']}>
                    <StyledDiv types={['flexboxOuterDiv', 'timelineOuter', 'halfCardWidth']}>
                        <StyledDiv types={['timelineInner', 'halfCardWidth']}>
                            <StyledDiv types={['flexboxInnerColumns', 'timelineColumns', 'halfCardWidth', 'halfText']}>
                                <StyledDiv types={['flexboxColumnHeader']}>
                                    <MessageHeader
                                        user={props.user}
                                        message={props.message}
                                        fullMessageDate={props.fullMessageDate}
                                        desktop={false}
                                        half={false}
                                        compressed={true} />
                                </StyledDiv>
                                <StyledDiv types={[
                                    'flexboxColumnText',
                                    'timelineMessageBody',
                                    'fixTimelineMsgBody']}
                                    style={props.innerColumnsStyle}>
                                    <TextContainer
                                        key={props.message.mId + 'E'}
                                        mId={props.message.mId + 'EA'}
                                        useProductCard={
                                            props.message?.metaData?.productId &&
                                            props.message.message === 'hidden'}
                                        fullMsg={false}
                                        messageStyling={props.messageStyling}
                                        edited={props.message.edited}
                                        product={{ ...props.product }}
                                        desktop={false}
                                        half={true} />
                                </StyledDiv>
                                <TimelineButtons
                                    replies={props.replies}
                                    reactions={props.reactions}
                                    highlights={props.highlights}
                                    timelineOps={props.timelineOps}
                                    quotes={props.quotes}
                                    tId={props.tId} mId={props.message.mId}
                                    desktop={false}
                                    half={false}
                                    compressed={true} />
                            </StyledDiv>
                        </StyledDiv>

                    </StyledDiv>
                </StyledDiv>
            )
    }
}

export const blogMessageCardPackage = {
    requirements: ['displayMode',
        'message',
        'fullMessageDate',
        'messageStyling',
        'user',
        'product',
        'innerColumnsStyle',
        'urls',
        'replies',
        'reactions',
        'highlights',
        'quotes',
        'push'
    ],
    CardDisplay: BlogMessageCardDisplay
}