import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import GroupIcon from '@mui/icons-material/Group'
import ListAltIcon from '@mui/icons-material/ListAlt'
import WatchIcon from '@mui/icons-material/Watch';
import { dispatchMappers } from '../../../redux/process/actionProcess.js'
import { RA1, RS1 } from '../../../redux/actions.js'
import { connect } from 'react-redux'
import UserNav from './UserNav/index.js'
import ThreadWatcherFooter from './ThreadWatcherNav/ThreadWatcherFooter/index.js'
import FriendNavFooter from './FriendsNav/FriendFooter'
import FriendsNav from './FriendsNav'
import SearchNav from './SearchNav'
import { Search } from '@mui/icons-material'
import { StyledDiv } from '../Styled'
import { SA1 } from '../../../hs/requestGlobals.mjs'
import { Menu, MenuItem } from '@mui/material'
import Brightness1Icon from '@mui/icons-material/Brightness1'
import { statePropMapper } from '../../../redux/process/selectorProcess'
import ThreadWatcherNav from './ThreadWatcherNav/index.js'
import { withAS } from "../../_contexts/withAppState";
import { withAuth } from '../../_contexts/withAuth/index.js'
import SubscriptionsNav from "./SubscriptionsNav";
import NotificationsNav from "./NotificationsNav";
import UniversalSidebarFooter from "../UniversalSidebarFooter";

const mapDispatchToProps = dispatchMappers([
    SA1.updateStatus,
    RA1._appState.changePage,
])
const makeMapStateToProps = statePropMapper([
    RS1.store('_appState', '_appState'),
    RS1.getUser('user'),
])
//showRightSidebar
let iconColors = {
    busy: 'red',
    away: 'orange',
    offline: 'grey',
    online: 'green',
}
const SidebarRightMenu = function (props) {
    const [anchorEl, setAnchorEl] = useState(null)
    const [status, setStatus] = useState(props.user.status)
    useEffect(() => {
        setStatus(props.user.status)
    }, [props.user.status])
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = (statusArg) => () => {
        if (statusArg !== 'none') {
            props.dispatch.server.updateStatus(statusArg)
            setStatus(statusArg)
            setAnchorEl(null)
        } else {
            setAnchorEl(null)
        }
    }

    return (
        <div>
            <IconButton
                onClick={handleClick}
                style={{ color: iconColors[status] }}
                size="large"
            >
                <Brightness1Icon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose('none')}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose('online')}>Online</MenuItem>
                <MenuItem onClick={handleClose('away')}>Away</MenuItem>
                <MenuItem onClick={handleClose('busy')}>Busy</MenuItem>
                <MenuItem onClick={handleClose('offline')}>Offline</MenuItem>

            </Menu>
            {/*<IconButton*/}
            {/*    color="primary"*/}
            {/*    onClick={() => {*/}
            {/*        props.setMenu('search')*/}
            {/*    }}*/}
            {/*    size="large"*/}
            {/*>*/}
            {/*    <Search />*/}
            {/*</IconButton>*/}

            {((window.screen.width / window.screen.height) < (4 / 3))
                ? null
                : <IconButton
                    color="primary"
                    onClick={() => {
                        props.setMenu('thread')
                    }}
                    size="large">
                    <WatchIcon />
                </IconButton>}

            <IconButton
                color="primary"
                onClick={() => {
                    props.setMenu('user')
                }}
                size="large"
            >
                <ListAltIcon />
            </IconButton>
            <IconButton
                color="primary"
                onClick={() => {
                    props.setMenu('friend')
                }}
                size="large"
            >
                <GroupIcon />
            </IconButton>
        </div>
    )
}

const SidebarRight = function (props) {
    const [activeServer, setActiveServer] = useState(props.server)
    const [menu, setMenu] = useState('user')
    const [friendNavMode, setFriendNavMode] = useState('friends')
    const changePage = props.AS.nav.ops.setPage

    let isInActiveServer = props.AS.nav.state.userIsInActiveServer

    if (activeServer !== props.server) {
        setActiveServer(props.server)
        setMenu('user')
    }
    useEffect(() => {
        if (props.mobileNavThreadWatcher) {
            setMenu('thread')
        } else {
            setMenu('user')
        }
    }, [props.mobileNavThreadWatcher])

    let DisplayedMenu = null
    let DisplayedFooter = null
    if (menu === 'user' && isInActiveServer) {
        DisplayedMenu = <UserNav {...props} />
        DisplayedFooter = null
    }
    if (menu === 'search' && isInActiveServer) {
        DisplayedMenu = <SearchNav {...props} />
        DisplayedFooter = null
    }
    if (menu === 'thread') {
        DisplayedMenu = <NotificationsNav {...props} />
        DisplayedFooter = null
    }
    if (menu === 'friend' && isInActiveServer) {
        const { server, ...otherProps } = props
        DisplayedMenu = <FriendsNav server={props.userId}{...otherProps} />
        DisplayedFooter = null
    }
    if (props.AS.nav.state.page === 'profile' || !isInActiveServer) {
        return (
            <StyledDiv types={['sidebarWhole']} side={'right'}
                style={{
                    height: (props.AS.mobileMode) ? 'calc(99dvh-4em)' : '99dvh',
                }}>
                {props.mobileNavThreadWatcher
                    ? <ThreadWatcherNav {...props} />
                    : <SubscriptionsNav mobileNavTabValue={0} />}
            </StyledDiv>
        )
    } else {
        let buttonsRendered = ["status", "people", "friends"]
        if (!props.AS.mobileMode) {
            buttonsRendered.push("notifications")
        }
        return (
            <StyledDiv types={['sidebarWhole']} side={'right'}>
                {DisplayedMenu}
                <StyledDiv
                    types={['sidebarFooter']}
                    displayedFooter={DisplayedFooter}>
                    {DisplayedFooter}
                </StyledDiv>
                <StyledDiv types={['sidebarBottomMenu']}>
                    {props.AS.mobileMode && menu === 'thread'
                        ? null
                        : <UniversalSidebarFooter
                            userData={props.allUsers?.[props._userId]}
                            setMenu={setMenu}
                            renderButtons={buttonsRendered}
                            adminOrAbove={props.isAdmin || props.isOwner}
                            {...props}
                        />}
                </StyledDiv>
            </StyledDiv>
        )
    }
}
export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(withAuth(withAS(SidebarRight)))