import React from "react"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"
import remarkGfm from 'remark-gfm'

const markdownComponents = {
    p({ node, inline, className, children, ...props }) {
        if (node.children[0]?.tagName === "img") {
            const image = node.children[0]
            const metastring = image.properties?.title
            const alt = image.properties?.alt
            const src = image.properties?.src
            const hasCaption = metastring?.toLowerCase().includes('{caption:')
            const caption = metastring?.match(/{caption: (.*?)}/)?.pop()

            return (
                <figure className="postImgWrapper"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                    <img alt={alt}
                        title={metastring}
                        src={src}
                        style={{ width: '100%' }} />
                    {hasCaption ?
                        <figcaption className="caption"
                            aria-label={caption}
                            styling={{ marginTop: '1em' }}>
                            {caption}
                        </figcaption> : null}
                </figure>
            )
        }
        return <p>{children}</p>
    }
}

export const MarkdownContainer = (props) => {

    return (
        <ReactMarkdown
            components={markdownComponents}
            remarkPlugins={[remarkGfm]}>
            {props.message}
        </ReactMarkdown>
    )
}