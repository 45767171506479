import * as React from 'react'
import Box from '@mui/material/Box'
import { FixedSizeList } from 'react-window'

export default (renderRow) =>
    function VirtualizedList(props) {
        return (
            <Box sx={{ width: props.listWidth, height: props.listHeight }}>
                <FixedSizeList
                    height={props.listHeight}
                    width={props.listWidth}
                    itemSize={props.itemSize}
                    itemCount={props.data.length}
                    overscanCount={0}
                    itemData={props.data}
                >
                    {renderRow}
                </FixedSizeList>
            </Box>
        )
    }
