import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RA1, RS1 } from '../../../../redux/actions.js'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import { dispatchMappers } from '../../../../redux/process/actionProcess.js'
import SocketForm from '../../../_reusable/SocketForm/SocketForm'
import { statePropMapper } from '../../../../redux/process/selectorProcess'

const makeMapStateToProps = statePropMapper([
    RS1.getServerAbstractRoles('serverAbstractRoles'),
    RS1.getCalendar('calendar'),
])
const mapDispatchToProps = dispatchMappers([
    RA1.api.updateAPI,
    SA1.approveAppointment,
])

const ApproveAppointmentModal = function (props) {
    return (
        <SocketForm
            initialScreen={false}
            useConfirmationScreen={true}
            useMultipleFormPages={true}
            confirmationFunction={(obj) => { }}
            formMethod={'approveAppointment'}
            formDispatch={(obj) => {
                obj.calendarId = props?.calendar?.selected?._id
                obj.serverId = props.AS.activeServer
                props.dispatch.server.approveAppointment(
                    obj.serverId,
                    obj.calendarId,
                    obj.status
                )
            }}
            socketForm
            onClose={props.closeModalMenu}
            onSuccess={props.closeModalMenu}
            description={'Would you like to confirm this appointment?'}
            formFields={[]}
            formButtons={[
                {
                    label: 'Approve',
                    icon: 'add',
                    mutateFormState: (formState) => {
                        let newFormState = { ...formState }
                        newFormState.status = true
                        return newFormState
                    },
                    isSubmitButton: true,
                    isCloseButton: false,
                },
                {
                    label: 'Decline',
                    icon: 'add',
                    mutateFormState: (formState) => {
                        let newFormState = { ...formState }
                        newFormState.status = false
                        return newFormState
                    },
                    isSubmitButton: true,
                    isCloseButton: false,
                },
                {
                    label: 'Cancel',
                    icon: 'add',
                    isSubmitButton: false,
                    isCloseButton: true,
                },
            ]}
            formTypography={{ label: 'Approve appointment' }}
            successMessage={'Appointment confirmed.'}
        />
    )
}

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(ApproveAppointmentModal)
