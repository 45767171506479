import { cache } from './cache.js'
import { cacheUpdate } from './index.js'

const CACHE_DISABLED = true

export const cachedSelect = (options) => (state, props) => {
    if (options.cached === false || CACHE_DISABLED) {
        return options.select(state, props)
    } else {
        let orderedCacheIds = options.orderedCacheKeys.map((key) => props[key])
        let isUpdateRequired = false
        if (options.hasOwnProperty('updateWhen')) {
            isUpdateRequired = cache.isUpdateRequired(
                orderedCacheIds,
                options.label,
                options.updateWhen
            )
        } else {
            isUpdateRequired = cache.isUpdateRequired(
                orderedCacheIds,
                options.label
            )
        }
        if (isUpdateRequired) {
            let result = options.select(state, props)
            cacheUpdate(
                options.orderedCacheKeys,
                orderedCacheIds,
                result,
                options.label
            )
            return result
        } else {
            return cache.getCacheData(orderedCacheIds, options.label)
        }
    }
}
