import React from 'react'
import { connect } from 'react-redux'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
} from '@mui/material'
import {
    AccordionCustom,
    AccordionDetailsCustom,
    AccordionSummaryCustom, TypographyCustom,
} from '../../../Styled'

const ChannelConfigAccordion = function (props) {
    let formControlLabels = []
    for (const key in props.manager.channelPermTemplate) {
        if (props.manager.channelPermTemplate.hasOwnProperty(key)) {
            formControlLabels.push(
                <FormControlLabel
                    key={props.roleData.id + key}
                    control={
                        <Checkbox
                            onChange={(e) =>
                                props.changeChannelPermissions(
                                    props.server,
                                    key,
                                    props.roleData.id
                                )
                            }
                            checked={props.channelPerms[key].includes(
                                props.roleData.id
                            )}
                        />
                    }
                    label={props.manager.channelPermTemplate[key].shortDesc}
                />
            )
        }
    }

    return (
        <AccordionCustom
            types={['sidebarAccordion']}
            expanded={props.expanded === props.panelKey}
            onChange={props.handleChange(props.panelKey)}
        >
            <AccordionSummaryCustom
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <TypographyCustom sx={{ width: '33%', flexShrink: 0 }}>
                    {props.roleData.role}
                </TypographyCustom>
            </AccordionSummaryCustom>
            <AccordionDetailsCustom>
                <FormGroup>{formControlLabels}</FormGroup>
            </AccordionDetailsCustom>
        </AccordionCustom>
    )
}

export default ChannelConfigAccordion
