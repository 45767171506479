export const TimelineMessageStyleOverrides = (mode, palette) => {
    return {
        timelineContainer: {
            height: '100%',
        },

        timelineOuter: {
            flexDirection: 'column',
            marginBottom: 7,
            '& > *': {
                flex: '0 0 auto',
            },
        },
        timelineButtons: (props) => ({
            flex: '1 0 auto',
            width: '100%',
            minWidth: '80%',
            // border: '1px solid magenta',
            display: 'flex',
            justifyContent: props?.fullMsg ? 'space-between' : 'space-around',
            alignItems: 'space-between',
            paddingTop: props?.fullMsg ? '15px' : 0,
            paddingBottom: '10px',
            '& > *': {
                flex: '1 0 auto',
                minWidth: '15%',
                display: 'inline-block',
                alignSelf: 'flex-end',
            },
        }),
        timelineInner: {
            width: '100%',
            display: 'flex',
        },
        timelineColumns: (props) => ({
            flex: '1 1 100%',
            paddingBottom: props?.fullMsg ? 0 : '10px',
        }),

        timelineMessageHeader: (props) => ({
            width: '100%',
            display: 'flex',
            justifyContent: props?.blogPost ? 'flex-start' : 'space-between',
            alignItems: 'stretch',
            '& > *': props?.blogPost ? {
                flex: '0 1 auto',
                padding: '0px 10px 0px 0px',
            } : { flex: '1 0 auto' },
        }),
        timelineUsername: { marginBottom: 5 },
        timelineDate: { textAlign: 'right' }, //TODO: change date to 'days ago'
        timelineMessageBody: {
            display: 'flex column',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            '& > *': {
                paddingBottom: '0.5em',
                flex: '1 0 auto',
            },
        },

        sidebarTimelineProfile: {
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            padding: 0,
            paddingBottom: '30px',
            '& > *': {
                flex: '1 1 auto',
            },
        },
        profileColumns: {
            flex: '1 0 auto',
            display: 'flex column',
            // '& > *': {
            //     flex: '1 1 auto',
            //     marginBottom: 10,
            // },
        },
        profileAviBorder: {
            marginLeft: '20px',
            marginBottom: '1em',
            width: '70%',
        },
        // profileAviBorder: { border: `1px solid ${palette.text.secondary}` },
        profileAvi: {
            width: '80%',
            // justifyContent: 'flex-end',
            justifyContent: 'center',
        },
        profileHeader: {
            textAlign: 'center',
            '& > *': {
                color: palette.text.flair,
                fontWeight: 'bold',
                position: 'relative',
                display: 'inline-block',
                // marginBottom: 5,
                // '&:after': {
                //     borderBottom: `1px solid ${palette.text.flair}`,
                //     position: 'absolute',
                //     left: 0,
                //     bottom: -2,
                //     width: '100%',
                //     content: '""',
                // },
            },
        },
        profileSite: {},
        profileBio: { width: "100%" },
        profileStats: {
            flex: '1 0 auto',
            minWidth: '80%',
            paddingLeft: '20%',
            '& > table': {
                borderCollapse: 'collapse',
                marginBottom: '10px',
                width: '80%',
                tableLayout: 'fixed',
                '& th, td': {
                    padding: 3,
                    textAlign: 'center',
                    // textAlign: "left",
                    borderBottom: '1px solid #444',
                },
                '& th': {
                    borderBottom: `1px solid ${palette.text.secondary}`,
                    color: palette.text.secondary,
                },
            },
        },
    }
}
