import {normalize} from 'normalizr'
import {session2} from '../schema/schemaProcess'
import {
    addChatServerPartial,
    disableChatServer,
    modifyChatServer,
    recomputeServerList,
} from '../chatServers'
import {batchLoad} from '../client'
import {allMonoSlices} from '../monostate/mono'
import {postAppStateDirective, updateAppState} from '../appState'
import {updateSession} from "../actions";

class updateDualClass {
    constructor() {
    }

    preUnit = (fields, data, policy, action) => {
        return typeof fields === 'object' && fields.type === 'state'
            ? fields
            : {
                fields,
                data: data ? data : {},
                policy: policy ? policy : '',
                nextPolicy: [],
                action: action,
                type: 'state',
            }
    }
    nextPolicy = (monad) => {
        let newMonad = {...monad}
        let [nextPolicy, ...remainingPolicies] = newMonad.policy.split('=>')
        newMonad.policy = remainingPolicies.join('=>')
        newMonad.nextPolicy = nextPolicy.split(':')
        return newMonad //
    }
    load = {
        omit: (monad) => {
            let newMonad = {...monad}
            const keysToOmit = newMonad.nextPolicy[2].split(',')
            let x = {...newMonad.data}
            keysToOmit.forEach((key) => {
                delete x[key]
            })
            batchLoad(newMonad.action, x)
            newMonad.nextPolicy = []
            return newMonad
        },
        profilePage: (monad) => {
            let newMonad = {...monad}
            const keysToOmit = newMonad.nextPolicy[2].split(',')
            let x = {...newMonad.data}
            keysToOmit.forEach((key) => {
                delete x[key]
            })
            batchLoad(newMonad.action, x)
            newMonad.action.asyncDispatch(
                updateAppState({
                    profileMeta: {
                        channel: newMonad.fields.original.switchChannelTo,
                        server: newMonad.fields.original.switchServerTo,
                    },
                })
            )
            newMonad.action.asyncDispatch(
                updateAppState({profileDataLoaded: true})
            )

            newMonad.action.asyncDispatch(
                postAppStateDirective({
                    type: "changeToProfile",
                    server: newMonad.fields.original.switchServerTo,
                    channel: newMonad.fields.original.switchChannelTo
                })
            )

            newMonad.policy = ''
            newMonad.nextPolicy = []
            return newMonad
        },
        mutate: (monad) => {
            let newMonad = {...monad}
            let serverId = newMonad.fields.serverId
            newMonad.action.asyncDispatch(
                modifyChatServer(newMonad?.data?.servers?.[serverId])
            )
            newMonad.nextPolicy = []
            return newMonad
        },
        replace: (monad) => {
            let newMonad = {...monad}
            let serverId = newMonad.fields.serverId
            newMonad.action.asyncDispatch(
                addChatServerPartial({
                    chatServer: newMonad?.data?.servers?.[serverId],
                    serverId: serverId,
                    preservedFields: newMonad.original.updateMeta
                        ?.preservedFields
                        ? newMonad.original.updateMeta.preservedFields
                        : null,
                })
            )
            newMonad.nextPolicy = []
            return newMonad
        },
        others: (monad) => {
            let newMonad = {...monad}
            const sessionData = {...newMonad.data['session'][true]}
            for (const key in allMonoSlices) {
                if (
                    allMonoSlices.hasOwnProperty(key) &&
                    sessionData.hasOwnProperty(key)
                ) {
                    newMonad.action.asyncDispatch(
                        allMonoSlices[key].actions.set(sessionData[key])
                    )
                }
            }
            newMonad.nextPolicy = []
            return newMonad
        },
        disable: (monad) => {
            let newMonad = {...monad}
            newMonad.action.asyncDispatch(
                disableChatServer({id: newMonad.fields.original.disableId})
            )
            newMonad.nextPolicy = []
            return newMonad
        },
    }
    switchTo = {
        server: (monad) => {
            let newMonad = {...monad}
            if (
                newMonad.fields.original?.switchServerTo &&
                newMonad.fields.asyncExtra._activeServer !==
                newMonad.fields.original.switchServerTo
            ) {
                newMonad.action.asyncDispatch(
                    allMonoSlices['activeServer'].actions.set(
                        newMonad.fields.original.switchServerTo
                    )
                )
                newMonad.action.asyncDispatch(
                    postAppStateDirective({
                        type: "switchServerTo",
                        server: newMonad.fields.original.switchServerTo
                    })
                )
            }
            newMonad.nextPolicy = []
            return newMonad
        },
        channel: (monad) => {
            let newMonad = {...monad}
            if (
                newMonad.fields.original?.switchChannelTo &&
                newMonad.fields.asyncExtra._activeChannel !==
                newMonad.fields.original.switchChannelTo
            ) {
                newMonad.action.asyncDispatch(
                    allMonoSlices['activeChannel'].actions.set(
                        newMonad.fields.original.switchChannelTo
                    )
                )

                postAppStateDirective({
                    type: "switchChannelTo",
                    channel: newMonad.fields.original.switchChannelTo
                })

            }
            newMonad.nextPolicy = []
            return newMonad
        },
    }
    fe = {
        //#TODO: The following must be reworked.
        switchAwayRoles: (monad) => {
            let newMonad = {...monad}
            if (newMonad.fields.asyncExtra.channelData) {
                if (
                    newMonad.fields.asyncExtra._activeChannel ===
                    newMonad.fields.channelId
                ) {
                    let channelVisible = false
                    let userMetaRoles =
                        newMonad.data.userMetas[
                            newMonad.fields.asyncExtra.userMetaId
                            ]?.roles
                    userMetaRoles.map((role) => {
                        if (
                            newMonad.fields.asyncExtra.channelData.viewableToRoles.includes(
                                role
                            )
                        ) {
                            channelVisible = true
                        }
                    })
                    if (!channelVisible) {
                        newMonad.action.asyncDispatch(
                            allMonoSlices['activeChannel'].actions.set(
                                newMonad.fields.asyncExtra.channelData
                                    .notificationsId
                            )
                        )

                        newMonad.action.asyncDispatch(
                            postAppStateDirective({
                                type: "switchChannelTo",
                                server: newMonad.fields.asyncExtra.channelData.notificationsId,
                                note: "notificationFallback"
                            })
                        )
                    }
                }
            }
            newMonad.nextPolicy = []
            return newMonad
        },
        switchAway: (monad) => {
            let newMonad = {...monad}
            if (
                newMonad.fields.asyncExtra._activeServer ===
                newMonad.fields.original.disableId
            ) {
                newMonad.action.asyncDispatch(
                    allMonoSlices['activeServer'].actions.set(
                        newMonad.fields.asyncExtra.userServer.id
                    )
                )
                newMonad.action.asyncDispatch(
                    allMonoSlices['activeChannel'].actions.set(
                        newMonad.fields.asyncExtra.userServer.notificationsId
                    )
                )

                newMonad.action.asyncDispatch(
                    postAppStateDirective({
                        type: "switchAway",
                        server: newMonad.fields.asyncExtra.userServer.id,
                        channel: newMonad.fields.asyncExtra.userServer.notificationsId,
                        note: "switchAwayFallback?"
                    })
                )
            }
            newMonad.nextPolicy = []
            return newMonad
        },

        forward: (monad) => {
            let newMonad = {...monad}
            let command = newMonad?.nextPolicy[2]
            if (command === 'recompute') {
                newMonad.action.asyncDispatch(recomputeServerList(null))
            }
            newMonad.nextPolicy = []
            return newMonad
        },
        channelReload: (monad) => {
            let newMonad = {...monad}
            let command = newMonad?.nextPolicy[2]
            newMonad.action.asyncDispatch(updateSession({
                handler: "serverHandler",
                command: "updateSession",
                data: {
                    type: "channelUpdate",
                    serverId: newMonad.fields.serverId,
                    channelId: newMonad.fields.channelId
                }
            }))
            newMonad.nextPolicy = []
            return newMonad
        },
        send: (monad) => {
            let newMonad = {...monad}
            let command = newMonad?.nextPolicy[2]
            if (command === 'recompute') {
                newMonad.action.asyncDispatch(recomputeServerList(null))
            }
            newMonad.nextPolicy = []
            return newMonad
        },
        incrementComponentVersion: (monad) => {
            let newMonad = {...monad}
            let command = newMonad?.nextPolicy[2]
            command = command + 'Version'
            let preparedPayload = {}
            const compVer = newMonad.fields.asyncExtra._appState?.[command]
            preparedPayload[command] = compVer ? compVer + 1 : 1
            newMonad.action.asyncDispatch(updateAppState(preparedPayload))
            newMonad.nextPolicy = []
            return newMonad
        },
    }
    monadUpdate = (action, mode) => {
        let sessionMonad = this.preUnit(
            {
                channelId: action?.payload?.channelId,
                serverId: action?.payload?.serverId,
                asyncExtra: action?.payload?.asyncExtra,
                original: action?.payload?.loadedData,
            },
            normalize(action.payload.loadedData, session2).entities,
            action.payload.clientPolicy,
            action
        )
        while (sessionMonad.policy.length > 0) {
            sessionMonad = this.nextPolicy(sessionMonad)
            sessionMonad =
                this?.[sessionMonad.nextPolicy[0]]?.[
                    sessionMonad.nextPolicy[1]
                    ](sessionMonad)
        }
    }
}

const updateDual = new updateDualClass()
export default updateDual
