import React from 'react'
import ReactDOM from 'react-dom'
import {
    userIdSlice,
    lastUpdateTypeSlice,
    activeChannelSlice,
    activeServerSlice,
    trackedServerListSlice,
    permDescriptionsSlice,
    loadedSlice,
    serverIDListSlice,
    serversSlice,
    inServersSlice,
} from './redux/reducers/monostate/mono.js'
import './index.css'
import 'font-awesome/css/font-awesome.min.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { store } from './redux/store'
import { ContextWrapper } from './contextWrapper'
import Firebase from './Components/_contexts/util'
import {journeyTracker} from "./hs/journey";

console.log(process.env)

ReactDOM.render(
    <ContextWrapper firebase={new Firebase()} store={store}>
        <App />
    </ContextWrapper>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
