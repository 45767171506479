import React from 'react'
import {connect} from 'react-redux'
import {RA1, RS1} from '../../../../redux/actions.js'
import {dispatchMappers} from '../../../../redux/process/actionProcess.js'
import SocketForm from '../../../_reusable/SocketForm/SocketForm'
import {
    statePropMapper,
} from '../../../../redux/process/selectorProcess'
import {withAS} from "../../../_contexts/withAppState";

const makeMapStateToProps = statePropMapper([
    RS1.store('_appState', '_appState'),
    RS1.store('_temp', '_temp'),
])

const mapDispatchToProps = dispatchMappers([RA1._appState.closeModalMenu])

const ErrorModal = function (props) {
    const dispatchMethod = () => {
        let roleStruct = {...props._appState.roleStruct}
        props.dispatch.server.updateRoles(props.AS.activeServer, roleStruct)
        props.AS.modals.ops.unblockModal()
    }
    const errorMessage = props?._temp?.errs?.[0]?.message ? props?._temp?.errs?.[0]?.message : props?._temp?.errs?.[0]?.err
    return (
        <SocketForm
            formMethod={'updateRoles'}
            formDispatch={props.closeModalMenu}
            socketForm
            onClose={props.closeModalMenu}
            onSuccess={() => {
            }}
            description={'Error Message: ' + errorMessage}
            formFields={[]}
            formButtons={[
                {label: 'Ignore', icon: 'add', isSubmitButton: true},
            ]}
            formTypography={{
                label: 'Error (Type: ' + props?._temp?.type + ')',
            }}
            successMessage={'Error.'}
        />
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(ErrorModal))
