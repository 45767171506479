import React from 'react'
import { connect } from 'react-redux'
import { RA1 } from '../../../redux/actions.js'
import { StyledGrid } from './styles'
import { RS1 } from '../../../redux/actions.js'
import { SA1 } from '../../../hs/requestGlobals.mjs'
import { regionControllerClass } from './_lib/chatRegion'
import { renderOperationsClass } from './_lib/renderOperations'
import { useEffect, useLayoutEffect, useCallback } from 'react'
import useState from 'react-usestateref'
import withMenu from '../../_wrappers/withMenu/index.js'
import ChatMessage from './ChatMessage'
import { dispatchMappers } from '../../../redux/process/actionProcess.js'
import { statePropMapper } from '../../../redux/process/selectorProcess.js'
import * as _ from 'underscore'
import withContentDropzone from '../../_wrappers/withContentDropzone/index.js'
import { parseMessage } from './_lib/messageParser'
import { HeightIndicator } from './HeightIndicator'
import { BufferLower } from './BufferLower'
import { BufferUpper } from './BufferUpper'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import MessageMenu from './MessageMenu'
import CellMeasurer from 'react-virtualized/dist/commonjs/CellMeasurer/CellMeasurer.js'
import { Fab, Fade } from '@mui/material'
import ChatInputContainer from './ChatInputContainer'
import { StyledDiv, vDH } from '../Styled'
import TimelineMessage from './TimelineMessage'
import { BottomRightHover } from "../../_design/BottomHover";
import { withAS } from "../../_contexts/withAppState";
import { useTheme } from '@mui/material'
import BlogInput from "../ChatInputs/BlogInput";

const makeMapStateToProps = statePropMapper([
    RS1.store('imageStore', 'imageStore'),
    RS1.getChannelWindow('channelWindow'),
    RS1.getChannelMessages('messages'),
    RS1.getServerUsersOb('users'),
    RS1.store('_loaded', '_loaded'),
])
const mapDispatchToProps = dispatchMappers([
    RA1.imageStore.addImage,
    RA1.imageStore.clear,
    RA1.client.error,
    RA1.channelWindows.storeChannelWindowData,
    RA1.channelWindows.mergeOtherIntoMain,
    SA1.messageLoad,
    SA1.getTransactions,
    RA1.chatInputs.addImage,
])

let cacheRef = { current: {} }
let heightRef = {
    current: {
        viewportHeight: 0,
        offsetHeight: 0,
        visibleCells: {},
        lastCellRendered: 0,
    },
}
let gridRef = { current: {} }
let rowIndexRef = { current: {} }
let op = { cu: {} }
let RO = { c: {} }
let RC = { c: {} }

const MessageContainer = (props) => {
    if (props.reverseMode) {
        return <TimelineMessage {...props} />
    } else {
        return <ChatMessage {...props} />
    }
}

function ChatWindow(props) {
    const theme = useTheme()
    const [inverseMode, setInverseMode, inverseModeRef] = useState(true)
    const [messages, setMessages, messagesRef] = useState('main')
    const [forceUpdate, setForceUpdate] = useState(0)
    const [regionMode, setRegionMode, regionModeRef] = useState('main')
    const [hightlightIndex, setHightlightIndex, hightlightIndexRef] =
        useState(-1)
    const [updateId, setUpdateId, updateIdRef] = useState(
        props.channelWindow.updateId
    )
    const [initializedCount, setInitializedCount, initializedCountRef] =
        useState(0)
    const [initialized, setInitialized, initializedRef] = useState(false)
    const [gridInitialized, setGridInitialized, gridInitializedRef] =
        useState(false)
    const [
        operationInProgress,
        setOperationInProgress,
        operationInProgressRef,
    ] = useState(false)
    const [firstJumpComplete, setFirstJumpComplete, firstJumpCompleteRef] =
        useState(false)
    const [operationCounter, setOperationCounter, operationCounterRef] =
        useState(0)
    const [blockScrollOps, setBlockScrollOps, blockScrollOpsRef] =
        useState(false)
    const [overscan, setOverscan, overscanRef] = useState(15)
    const [alignment, setAlignment, alignmentRef] = useState('end')
    const [
        scrollStatusPackage,
        setScrollStatusPackage,
        scrollStatusPackageRef,
    ] = useState({})
    const [callbackOperation, setCallbackOperation, callbackOperationRef] =
        useState({})
    const [
        blockScrollStatusUpdates,
        setBlockScrollStatusUpdates,
        blockScrollStatusUpdatesRef,
    ] = useState(false)

    const cellRenderer = ({
        columnIndex,
        key,
        parent,
        rowIndex,
        style,
        isVisible,
    }) => {
        let AR = RC.c.activeRegion(regionModeRef.current)
        if (isVisible) {
            // RC.c.storeBottomVisibleRow(rowIndex)
            heightRef.current.lastCellRendered = rowIndex
        }
        if (rowIndex === 0) {
            return (
                <CellMeasurer
                    cache={cacheRef.current}
                    columnIndex={columnIndex}
                    key={key}
                    parent={parent}
                    rowIndex={rowIndex}>
                    {({ measure, registerChild }) =>
                        <div ref={registerChild} key={key} style={style} onLoad={measure}>
                            <HeightIndicator
                                isVisibile={isVisible}
                                flip={updateScrollStatusDEB}
                                reverseMode={props.reverseMode}
                                isBottom={false}
                                isTop={
                                    0 === rowIndex &&
                                    AR.firstInit &&
                                    !blockScrollOpsRef.current
                                }
                            />
                            <BufferUpper
                                reverseMode={props.reverseMode}
                                height={vDH(7)}
                            />
                        </div>
                    }
                </CellMeasurer>
            )
        } else if (rowIndex === messagesRef.current.size + 1) {
            return (
                <CellMeasurer
                    cache={cacheRef.current}
                    columnIndex={columnIndex}
                    key={key}
                    parent={parent}
                    rowIndex={rowIndex}
                >
                    {({ measure, registerChild }) =>
                        <div ref={registerChild} key={key} style={style} onLoad={measure}>
                            <HeightIndicator
                                isVisibile={isVisible}
                                flip={updateScrollStatusDEB}
                                reverseMode={props.reverseMode}
                                rowIndex={rowIndex}
                                isBottom={
                                    rowIndex === messagesRef.current.size + 1 &&
                                    AR.firstInit &&
                                    !blockScrollOpsRef.current
                                }
                                isTop={false}
                            />
                            <BufferLower
                                height={vDH(7)}
                                reverseMode={props.reverseMode}
                            />
                        </div>
                    }
                </CellMeasurer>
            )
        } else {
            let messageId = messagesRef.current.get(rowIndex - 1)
            let previousUserId = '-1'
            if (rowIndex > 2) {
                let previousMessageId = messagesRef.current.get(rowIndex - 2)
                previousUserId = props.messages[previousMessageId].uId
            }
            if (!props?.messages?.[messageId]) {
                console.log('LOADING ERROR')
            }
            let message = props.messages[messageId]
            let user = props.users[message.uId]

            return (
                <CellMeasurer
                    cache={cacheRef.current}
                    columnIndex={columnIndex}
                    key={key}
                    parent={parent}
                    rowIndex={rowIndex}
                >
                    {({ measure, registerChild }) =>
                        <div
                            ref={registerChild}
                            key={key}
                            style={{ ...style, borderBottom: props.reverseMode ? "1px solid rgb(100 100 100 / 40%)" : null }}
                            onContextMenu={(e) => props.selectItem(e, message, props.reverseMode)}
                            onLoad={measure}>
                            <MessageContainer
                                // oldStyle={style}
                                server={props.server}
                                message={message}
                                channel={props.channel}
                                previousUserId={previousUserId}
                                rowIndex={rowIndex}
                                shiftToMessage={shiftToMessage}
                                reverseMode={props.reverseMode}
                                hightlightMessage={
                                    hightlightIndexRef.current === rowIndex
                                }
                                desktop={props?.desktop}
                                messageStyling={message?.data?.styling}
                                urls={message?.data?.urls}
                                user={user}
                            />
                        </div>
                    }
                </CellMeasurer>
            )
        }
    }

    // *** SCROLL OPERATION USE EFFECT***
    //The following useEffect is the fundamental operation loop for performing scroll management operations
    //on the ChatWindow. Shifting the position of the scroll requires a number of operations to be carried
    //out over several render cycles - including cache dumping, multiple scroll jumps, adjustment of grid parameters, etc.
    //The relevant details of which operations are needed depend on the specific use, but this loop abstracts the behavior.
    //When operationCounterRef.current < 0, this is the initialization phase of the operation - and deals with cache and
    //cell measurement issues. For operationCounterRef.current > 0, the actual operation is carried out.
    // -- op.cu?.initOp() is the first operation performed, and it is only executed once.
    // -- op.cu?.cache(x) executes the cache management function that as been to assigned to run when x = operationCounterRef.current
    // -- op.cu?.cond() is the condition needed for the main loop (sometimes the same operation must be performed multiple times until
    //      it reaches a stable state)
    // -- op.cu?.op() is the scroll operation
    // -- op.cu?.post() is performed after the scroll operation, often updating the conditions that op.cu?.cond() checks
    // -- op.cu?.term() and op.cu?.finOp() are executed upon operation completion.
    useEffect(() => {
        if (operationInProgressRef.current && !op.cu?.init) {
            op.cu?.initOp()
        }
        if (
            operationInProgressRef.current &&
            operationCounterRef.current <= -1
        ) {
            op.cu?.cache(operationCounterRef.current)
            setOperationCounter(operationCounterRef.current + 1)
        }
        if (
            operationInProgressRef.current &&
            operationCounterRef.current >= 0
        ) {
            if (op.cu?.cond()) {
                op.cu?.op()
                op.cu?.post()
                setOperationCounter(operationCounterRef.current + 1)
            } else {
                op.cu?.term()
                op.cu?.finOp()
                op.cu = {}
                firstJumpCompleteRef.current === false
                    ? setFirstJumpComplete(true)
                    : null
                setOperationInProgress(false)
            }
        }
    }, [operationInProgress, operationCounter])

    /**
     * Test!
     */
    useEffect(() => {
        props.dispatch.server.getTransactions(props.server, true, 'one-time')
        setTimeout(() => {
            cacheRef.current.clearAll()
        }, 500)
    }, [])

    // This is a callback used by regionControllerClass to instruct the chatWindow to begin preparing
    // a scroll operation.
    const controllerCallbackHandler = (callbackData) => {
        setCallbackOperation(callbackData)
    }


    // *** SCROLL OPERATION CALLBACK HANDLER***
    //The following useEffect generates the operation to be performed in the fundamental operation loop.
    // --- RO.c.cellShiftOperation(rowIndex) generates an operation shifting to a given rowIndex
    // --- RO.c.cacheManagementPolicy()({
    //                         clearCache: -A,
    //                         resetCache: -B,
    //                         measureCells: -C,
    //                         recomputeGrid: -D,
    //                         messages: AR.activeMessages
    //                     })
    //      stipulates the cache management operations to be performed during the fundamental operation loop.
    //      for example, clearCache will be executed at when the operationLoopCounter is = -A... etc
    // --- RO.c.overscanAndScrollAdjustment(setOverscan, setAlignment, tempOverscan, tempAlignment)
    //      changes the props of the Grid in preparation for the scroll operation during the initial phase
    //      of the operation loop.
    // ---  op.cu.term = Y sets a termination function for the completion of the scroll operation.
    // --- setOperationCounter(index) sets the initial index of the fundamental operation loop. If we have set
    //      clearCache: Y as above in the cacheManagementPolicy, tt must be set <= Y for clearCache to execute, etc.
    // --- setOperationInProgress(true) prevents any further operations from executing well the fundamental loop is happening.
    // --- setBlockScrollOps(false) blocks any updates to chatRegions scroll controller during the operation.
    useEffect(() => {
        let AR = RC.c.activeRegion(regionModeRef.current)
        if (!operationInProgressRef.current) {
            switch (callbackOperationRef.current.callbackType) {
                case 'refresh':
                    cacheRef.current.clearAll()
                    setForceUpdate(x => x + 1)
                    break
                case 'shiftToRowIndex':
                    if (props.reverseMode) {
                        setMessages(AR.activeMessages.reverse())
                    } else {
                        setMessages(AR.activeMessages)
                    }
                    setBlockScrollOps(true)
                    RO.c.cellShiftOperation(
                        callbackOperationRef.current.rowIndex - 1 >= 0 ? callbackOperationRef.current.rowIndex - 1 : 0
                    )
                    RO.c.overscanAndScrollAdjustments(
                        setOverscan,
                        setAlignment,
                        50,
                        callbackOperationRef.current.alignmentData
                    )
                    RO.c.cacheManagementPolicy()({
                        recomputeGrid: -1,
                        messages: AR.activeMessages,
                    })
                    op.cu.term = () => {
                        setBlockScrollOps(false)
                        if (callbackOperationRef.current?.otherCallback) {
                            callbackOperationRef.current?.otherCallback?.()
                        }
                    }
                    setOperationInProgress(true)
                    setOperationCounter(-1)
                    break
                case 'shiftTopPosition':
                    break
                case 'shiftOldest':
                    break
                case 'noOp':
                    break
                default:
            }
        } else {
        }
    }, [callbackOperation])

    useEffect(() => {
        if (regionMode === 'other') {
            let AR = RC.c.activeRegion(regionMode)
            setMessages(AR.activeMessages)
        }
    }, [regionMode])

    // *** CHANNEL WINDOW UPDATE HANDLER***
    //This useEffect watches for updates to the channelWindow reducer in the redux store, and feeds them into
    //regionController.
    useEffect(() => {
        if (props.channelWindow.updateId !== updateIdRef.current) {
            if (props.channelWindow.switchId === props.channelWindow.updateId) {
                RC.c.changeActiveRegion(
                    props.channelWindow,
                    setRegionMode,
                    setBlockScrollOps
                )
            } else {
                RC.c.processUpdateData(props.channelWindow)
            }
            setUpdateId(props.channelWindow.updateId)
        }
    }, [props.channelWindow.updateId])

    // *** SCROLL MONITOR***
    //This useEffect and the functions below it are designed to watch user scrolling behavior and trigger various operations
    //in the regionController based on specific behaviors.
    useEffect(() => {
        let topOrBottom = ['top', 'bottom'].includes(
            scrollStatusPackageRef.current.updateType
        )
        const timer = setTimeout(() => {
            if (blockScrollStatusUpdatesRef.current && topOrBottom) {
                setBlockScrollStatusUpdates(false)
            }
        }, 10)
        if (topOrBottom && !blockScrollOpsRef.current) {
            if (!blockScrollStatusUpdatesRef.current) {
                setBlockScrollStatusUpdates(true)
                RC.c.updateScrollStatus(scrollStatusPackageRef.current)
            }
        } else if (!blockScrollOpsRef.current) {
            RC.c.updateScrollStatus(scrollStatusPackageRef.current)
        }
        return () => clearTimeout(timer)
    }, [scrollStatusPackage])

    const updateScrollStatus = (updateType, newScrollTop) => {
        if (firstJumpCompleteRef.current) {
            let AR = RC.c.activeRegion(regionModeRef.current)
            RC.c.storeChannelWindowData()
            if (
                AR.hasEnoughMessages &&
                AR.firstInit &&
                initializedCountRef.current === 5
            ) {
                RC.c.storeChannelWindowData()

                setScrollStatusPackage({ updateType, newScrollTop })
            }
        }
    }

    const shiftToMessage = (messageReply) => {
        setBlockScrollStatusUpdates(true)
        RC.c.shiftToMessage(messageReply.mData.mId)
    }
    const updateScroll = (scrollProps) =>
        updateScrollStatus('scroll', scrollProps.scrollTop)
    const updateScrollStatusDEB = useCallback(
        _.debounce(updateScrollStatus, 200),
        []
    )
    const updateScrollThrottled = useCallback(_.debounce(updateScroll, 200), [])
    // *** INITIALIZATION USE EFFECT ***
    //The following hooks are used on initialization
    useLayoutEffect(() => {
        //In the first stage of initialization, we prepare the region controller and relevant variables.
        if (!initialized && initializedCountRef.current === 0) {
            cacheRef = { current: {} }
            heightRef = {
                current: {
                    viewportHeight: 0,
                    offsetHeight: 0,
                    visibleCells: {},
                    lastCellRendered: 0,
                },
            }
            gridRef = { current: {} }
            rowIndexRef = { current: {} }
            op = { cu: {} }
            RO = { c: {} }
            RC = { c: {} }
            let currentMode = props.channelWindow.activeMode
            RC.c = new regionControllerClass(
                props.userId,
                props.channelWindow.messages, //FIX THIS!
                props.channelWindow,
                'main',
                props.server,
                props.channel,
                rowIndexRef,
                props.dispatch.channelWindows,
                props.dispatch.server,
                props.dispatch.server.messageLoad,
                setRegionMode,
                controllerCallbackHandler,
                props.reverseMode
            )
            RC.c.initialization(currentMode)
            setRegionMode(currentMode)
            let activeReg = RC.c.activeRegion(currentMode)
            setMessages(activeReg.activeMessages)

            RO.c = new renderOperationsClass(
                cacheRef,
                gridRef,
                rowIndexRef,
                op,
                RC.c.maxRegionSize
            )
            RO.c.createCache(messagesRef)
            setInitializedCount(3)
            setInitialized(true)
        }
        if (
            initialized &&
            !gridInitializedRef.current &&
            initializedCountRef.current > 2
        ) {
            if (!gridInitializedRef.current) {
                if (gridRef.current !== null) {
                    setGridInitialized(true)
                    setInitializedCount(5)
                    let activeReg = RC.c.activeRegion(regionModeRef.current)
                    setInitializedCount(5)
                    RC.c.initJumpToStoredLocation()
                } else {
                    setGridInitialized(false)
                }
            }
        }
    }, [initialized, initializedCount])

    const [videoElementHeight, setVideoElementHeight] = useState(vDH(45))
    const changeVideoElementHeight = (input) => {
        setVideoElementHeight(vDH(input))
    }

    const placeholderHeight = props.AS.video.state.videoOverlay ? videoElementHeight : vDH(0)
    // const chatHeight = !props.AS.video.state.videoOverlay ? '100vh' : '55vh'
    const chatHeight = ((window.screen.width / window.screen.height) < (4 / 3)) ? `calc(${vDH(100)} - 6.4em)` : vDH(100)
    return props._loaded === true && initialized ? (
        <>
            <div style={{
                position: 'absolute',
                top: 0,
                height: placeholderHeight,
                backgroundColor: 'transparent',
            }} />
            <div style={{
                height: chatHeight,
                marginTop: ((window.screen.width / window.screen.height) < (4 / 3)) ? '3.2em' : 0,
                transform: 'translate(0, 0)'
            }}>
                <StyledDiv types={['chatWindowDiv']}>
                    <Fade in={firstJumpComplete}>
                        <div style={{ height: '100%' }}>
                            <AutoSizer
                                onResize={() => {
                                    cacheRef.current.clearAll()
                                }}
                            >
                                {({ width, height }) => {
                                    let actualWidth = width
                                    let actualHeight = height
                                    if (process.env.NODE_ENV === 'test') {
                                        actualWidth = window.innerWidth
                                        actualHeight = window.innerHeight
                                    }
                                    heightRef.current.viewportHeight = height
                                    return (
                                        <StyledGrid
                                            ref={RO.c.initializeGrid(gridRef)}
                                            cellRenderer={cellRenderer}
                                            columnCount={1}
                                            columnWidth={props.reverseMode ? actualWidth :
                                                (props?.desktop) ? actualWidth - 50 : actualWidth}
                                            height={actualHeight}
                                            onScroll={updateScrollThrottled}
                                            rowHeight={RO.c.rowSizer(messagesRef)}
                                            overscanRowCount={overscanRef.current}
                                            rowCount={messages.size + 2}
                                            scrollToAlignment={alignmentRef.current}
                                            deferredMeasurementCache={
                                                cacheRef.current
                                            }
                                            forceUpdate={forceUpdate}
                                            width={props.reverseMode ? actualWidth + 20 : actualWidth}
                                        />
                                    )
                                }}
                            </AutoSizer>
                        </div>
                    </Fade>
                    <BottomRightHover>
                        {RC.c.mode === 'other' ? (
                            <Fab
                                color="secondary"
                                aria-label="edit"
                                size="small"
                                onClick={() => {
                                    RC.c.adaptiveRegionSwitch(null, false)
                                }}
                            >
                                <ArrowDownwardIcon />
                            </Fab>
                        ) : null}
                    </BottomRightHover>
                </StyledDiv>
                <div>
                    {props.inputEnabled ? (
                        <ChatInputContainer
                            server={props.server}
                            channel={props.channel}
                            user={props.userId}
                            sendFiles={props.sendFiles}
                            clearFiles={props.clearFiles}
                            hasFiles={props.hasFiles}
                            storeFile={props.storeFile}
                            blobLinks={props.blobLinks}
                        />
                    ) : null}
                </div>
            </div>
        </>
    ) : null
}

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(withContentDropzone('chat')(withMenu(withAS(ChatWindow), MessageMenu)))
