import React, { useState, useEffect } from 'react'
import { SA1 } from '../../../../../hs/requestGlobals.mjs'
import { connect } from 'react-redux'
import { dispatchMappers } from '../../../../../redux/process/actionProcess'
import { RS1 } from '../../../../../redux/actions'
import { statePropMapper } from '../../../../../redux/process/selectorProcess'
import { ButtonCustom } from '../../../Styled'
import ProductPreview from '../../ProductPreview'
import {withAS} from "../../../../_contexts/withAppState";

//TODO: use individual servers from server selection screen evenually.
//TODO: have different buttons for each type of transaction, then buttons for enabled/disabled
const makeMapStateToProps = statePropMapper([
    RS1.store('_userSettings', '_userSettings'),
])

const mapDispatchToProps = dispatchMappers([
    SA1.getTransactions,
    SA1.deleteSubscription,
])

const Transactions = function (props) {
    // #
    const [isLoading, setIsLoading] = useState(true)
    // const [oneTimes, setOneTimes] = useState([])
    // const [subscriptions, setSubscriptions] = useState([])
    // const [directMessages, setDirectMessages] = useState([])
    // const [appointments, setAppointments] = useState([])
    const [transactions, setTransactions] = useState({})
    const transactionLabels = {
        subscription: 'Subscription',
        'one-time': 'One-Time Payment',
        'direct-message': 'Paid Private Message',
        appointment: 'Appointment',
    }

    // #
    const sortTransactions = (transactionsList) => {
        let sortedTransactions = {}

        transactionsList.forEach((transaction) => {
            let type = transaction.transactionType

            if (Object.keys(transactionLabels).includes(type)) {
                if (!Array.isArray(sortedTransactions[type])) {
                    sortedTransactions[type] = []
                }
                sortedTransactions[type].push(transaction)
            }
        })

        return { sortedTransactions }
    }

    // #
    useEffect(() => {
        props.dispatch.server.getTransactions(props.AS.activeServer, true, null)
    }, [])

    useEffect(() => {
        if (props._userSettings.transactions) {
            // TODO: organize by transaction type.
            // const sortedTransactions = sortTransactions(props._userSettings.transactions)
            const { sortedTransactions } = sortTransactions(
                props._userSettings.transactions
            )
            setTransactions(sortedTransactions)

            // TODO: get product by id & use that to get products names & such.

            setIsLoading(false)
        }
    }, [props?._userSettings?.transactions])

    // #
    const cancelSubscription = (subscriptionId) => async () => {
        props.dispatch.server.deleteSubscription(subscriptionId)
    }

    // TODO: list transactions with data about each transaction next to it;
    // TODO: consider separating 'subscriptions' from rest of transactions.
    // #
    return (
        <div>
            <div style={{ float: 'right' }}>
                <ButtonCustom types={[]} onClick={props.backButton}>
                    Back to Shop
                </ButtonCustom>
            </div>

            {!isLoading && (
                <div>
                    {Object.keys(transactions).map((transactionType) => {
                        const label = transactionLabels[transactionType]
                        return (
                            <div key={transactionType}>
                                <span style={{ fontSize: '1.5em' }}>
                                    {label}s:
                                </span>
                                <br />
                                <br />

                                <ol>
                                    {transactions[transactionType].map(
                                        (transaction) => {
                                            //TODO: when transactionType is subscription,
                                            // create an array of subscriptionIds.
                                            const product = transaction.product
                                            return (
                                                <li key={transaction.id}>
                                                    <div
                                                        style={{
                                                            width: 'fit-content',
                                                            height: 'fit-content',
                                                            paddingLeft: '1em',
                                                            paddingRight: '1em',
                                                            marginLeft: '2em',
                                                            backgroundColor:
                                                                'gray',
                                                        }}
                                                    >
                                                        <ProductPreview
                                                            {...product}
                                                        />
                                                    </div>

                                                    <div>
                                                        <span>
                                                            {label} status:{' '}
                                                            {
                                                                transaction.transactionStatus
                                                            }
                                                        </span>
                                                    </div>

                                                    {transactionType ===
                                                        'subscription' && (
                                                        <ButtonCustom
                                                            types={[]}
                                                            onClick={cancelSubscription(
                                                                transaction.id
                                                            )}
                                                        >
                                                            cancel subscription
                                                            for {product.name}
                                                        </ButtonCustom>
                                                    )}
                                                </li>
                                            )
                                        }
                                    )}
                                </ol>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(Transactions))
