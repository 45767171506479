import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import React, { useEffect, useState } from 'react'
import { Pagination } from '@mui/lab'
import { TypographyCustom } from '../../../Styled'
import { usePages } from '../../../../../hs/hooks'
import transactions from '../../../Payment/Customer/Transactions'

let tableKeys = {
    buyerId: 'Buyer',
    transactionStatus: 'Status',
    transactionType: 'Type',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
}

const UnderlyingTable = (props) => {
    const [setPageNumber, totalPages, truncatedArray] = usePages(
        props.transactions,
        10
    )
    return (
        <>
            <TypographyCustom gutterBottom variant="h5" component="div">
                Recent Transactions
            </TypographyCustom>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {Object.keys(tableKeys).map((key) => (
                                <TableCell>{tableKeys[key]}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {truncatedArray.map((transaction,index) => (
                            <TableRow
                                id={props.product.id+"_"+index+"_"+"id"}
                                key={props.product.id+"_"+index}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}
                            >
                                {Object.keys(tableKeys).map((key) => (
                                    <TableCell>{transaction[key]}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                <Pagination count={totalPages} onChange={setPageNumber} />
            </div>
        </>
    )
}

const PriceTable = (props) => {
    useEffect(() => {
        if (props.userId == props.serverData.ownerId) {
            props.dispatch.server.getServerTransactions(props.server, true, null)
        } else {
            props.dispatch.server.getTransactions(props.server, true, null)
        }
    }, [])

    if (props._userSettings.transactions) {
        let transactions = props._userSettings.transactions.filter(
            (transaction) => transaction.product.id === props.product.id
        )
        return <UnderlyingTable transactions={transactions} {...props} />
    } else {
        return null
    }
}

export default PriceTable
