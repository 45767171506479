import React, { useState } from 'react'
import HSInput from '../../_reusable/Inputs/HSInput.js'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import BackButton from '../BackButton'
import { registerSuccessUrl } from "../url";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { BoxCustom } from "../../Chat/Styled";
import { CircularProgress } from "@mui/material";
import BackToAuthButton from "../BackToAuthButton";
import { StyledDiv, TypographyCustom } from '../../Chat/Styled'

const LostPassword = function (props) {
    const [formState, setFormState, formStateRef] = useState({})
    const fieldChange = props.fieldChangeCreator(setFormState, formState)
    const [showProgress, setShowProgress] = useState(false)
    const [complete, setComplete] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(false)

    function resetPassword(e) {
        e.preventDefault()
        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formState.email)) {
            setInvalidEmail(true)
            return null
        }
        let submissionData = { ...formState }
        submissionData.resetType = 'default'
        setShowProgress(true)
        props
            .API('passwordReset')(submissionData)
            .then((res) => {
                props.responseHandler(
                    res,
                    (res) => {
                        setShowProgress(false)
                        setComplete(true)
                    },
                    (error) => {
                        setShowProgress(false)
                        setComplete(true)
                    }
                )
            })
    }

    let inputProps = {
        fieldChange: fieldChange,
        errorData: props.errorData,
        errorStatus: props.errorStatus,
        method: 'lostPasswordEmail',
    }
    if (invalidEmail) {
        inputProps = {
            fieldChange: fieldChange,
            errorData: () => () => "Please enter a valid email.",
            errorStatus: () => () => true,
            method: 'lostPasswordEmail'
        }
    }

    if (complete) {
        return (
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h5" component="h5">
                        If a matching account was found an email will be sent to {formState.email} to allow you to reset your password.
                    </Typography>
                </Grid>
                <Grid item>
                    <BackButton auth={props.auth} forceReload={props.forceReload} />
                </Grid>
                <Grid item>
                    <BackToAuthButton />
                </Grid>
            </Grid>)
    }

    if (showProgress) {
        return (
            <BoxCustom style={{ width: 'auto', backgroundColor: "rgba(255, 255, 255, 0.1)" }} types={['modalMenu']}>
                <CircularProgress />
            </BoxCustom>)
    }

    return (
        <StyledDiv types={['signupFlexboxOuter', 'signupContainer']}>
            <StyledDiv>
                <TypographyCustom variant="h5" component="h5">
                    Please enter your email.
                </TypographyCustom>
            </StyledDiv>
            <StyledDiv>
                <form
                    onSubmit={(e) => {
                        resetPassword(e)
                    }}
                >
                    <HSInput id={'email'} label={'Email'} {...inputProps} />
                </form>
            </StyledDiv>
            <StyledDiv types={['signupFlexboxOuter', 'signupButtons']}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                        resetPassword(e)
                    }}
                    style={{
                        width: 'fit-content'
                    }}>
                    Reset password
                </Button>

                <BackToAuthButton />
            </StyledDiv>
        </StyledDiv>
    )
}

export default LostPassword
