import moment from 'moment'

export const tmpMessage = {
    "_id": "551905564801094656",
    "uId": "551905043436524544",
    "mId": "551905564801094656",
    "cId": "551905564528464902",
    "sId": "551905564528464896",
    "tId": "none",
    "thread": true,
    "files": [
        'https://bestfriends.org/sites/default/files/2023-02/Victory3427MW_Social.jpg'
    ],
    "type": "system",
    "mb": {
        "likes": 0
    },
    "threadTitle": "",
    "message": "**balio**\n\n*hutsoa*\n\n~~aoeie~~\n\nuheoas\n\n-----------\n\n# ueaoi\n\n[uetaso](U)\n\n> ueoai\n\n`ueoa`\n\n```\nfdsaf\n\n```\n\n<!-- fdsaf -->\n\n![image](https://i.ytimg.com/vi/l9Mp0iOFVuc/hqdefault.jpg)\n\n- fdsa\n- fdsa\n- fdsa\n\n1. fdsaf\n2. fdsa\n3. fdsaa\n\n- [x] fsda\n- [ ] fdsa\n- [ ] fdsa",
    "createdAt": "2023-03-03T23:15:40.557Z",
    "deleted": false,
    "reply": {
        "hasReplies": [],
        "isReply": false
    },
    "id": "551905564801094656"
}

export const testData = () => {

    let urls = []
    let message = {
        "_id": "551905564801094656",
        "uId": "551905043436524544",
        "mId": "551905564801094656",
        "cId": "551905564528464902",
        "sId": "551905564528464896",
        "tId": "none",
        "thread": true,
        "files": [
            'https://bestfriends.org/sites/default/files/2023-02/Victory3427MW_Social.jpg'
        ],
        "type": "system",
        "mb": {
            "likes": 0
        },
        "threadTitle": "",
        "message": "**balio**\n\n*hutsoa*\n\n~~aoeie~~\n\nuheoas\n\n-----------\n\n# ueaoi\n\n[uetaso](U)\n\n> ueoai\n\n`ueoa`\n\n```\nfdsaf\n\n```\n\n<!-- fdsaf -->\n\n![image](https://i.ytimg.com/vi/l9Mp0iOFVuc/hqdefault.jpg)\n\n- fdsa\n- fdsa\n- fdsa\n\n1. fdsaf\n2. fdsa\n3. fdsaa\n\n- [x] fsda\n- [ ] fdsa\n- [ ] fdsa",
        "createdAt": "2023-03-03T23:15:40.557Z",
        "deleted": false,
        "reply": {
            "hasReplies": [],
            "isReply": false
        },
        "id": "551905564801094656"
    }
    let fullMessageDate = moment(message.createdAt).format(
        'MMMM Do YYYY, h:mm:ss a'
    )
    let user = {
        "id": "551905043436524544",
        "status": "online",
        "profileId": "551905043528799237",
        "pictureId": "",
        "userName": "lain.iwakura",
        "color": "#FF0000",
        "primaryRole": "551905564528464903",
        "roles": [
            "551905564528464903"
        ]
    }
    let messageStyling = [
        {
            "mode": "rt",
            "text": "**balio**\n\n*hutsoa*\n\n~~aoeie~~\n\nuheoas\n\n-----------\n\n# ueaoi\n\n[uetaso](U)\n\n> ueoai\n\n`ueoa`\n\n```\nfdsaf\n\n```\n\n<!-- fdsaf -->\n\n![image](https://i.ytimg.com/vi/l9Mp0iOFVuc/hqdefault.jpg)\n\n- fdsa\n- fdsa\n- fdsa\n\n1. fdsaf\n2. fdsa\n3. fdsaa\n\n- [x] fsda\n- [ ] fdsa\n- [ ] fdsa",
        }
    ]
    let product = {}
    let innerColumnsStyle = {}
    let thread = {
        "id": "553115424211668992",
        "pic": "https://paa-fiilestorage.s3.us-east-2.amazonaws.com/attachments/551905564528464900/553115424211668992/1546445844407.jpg",
        "text": "\"><blah>",
        "uId": "551906790607410176",
        "date": "03/07/2023, 02:23 AM",
        "replies": 5
    }
    let files = [
        'https://bestfriends.org/sites/default/files/2023-02/Victory3427MW_Social.jpg'
    ]
    let threadsArray = [
        {
            "_id": "577106584060519424",
            "uId": "562107925016826880",
            "mId": "577106584060519424",
            "cId": "551905564528464900",
            "sId": "551905564528464896",
            "tId": "577106584060519424",
            "thread": true,
            "files": [
                "https://paa-fiilestorage.s3.us-east-2.amazonaws.com/attachments/551905564528464900/577106584060519424/1564760007489.jpg"
            ],
            "type": "user",
            "mb": {
                "likes": 0
            },
            "metaData": {
                "threadReplies": 0
            },
            "threadTitle": "blah",
            "message": "blahbalh",
            "createdAt": "2023-05-12T12:15:33.528Z",
            "deleted": false,
            "reply": {
                "mData": {
                    "uId": "",
                    "mId": "",
                    "cId": "",
                    "sId": ""
                },
                "hasReplies": [
                    "577107601586086912"
                ],
                "isReply": false,
                "message": "",
                "user": "",
                "edited": false,
                "deleted": false,
                "attachment": false
            },
            "id": "577106584060519424"
        },
        {
            "_id": "560170549516512256",
            "uId": "551905043436524544",
            "mId": "560170549516512256",
            "cId": "551905564528464900",
            "sId": "551905564528464896",
            "tId": "560170549516512256",
            "thread": true,
            "files": [
                "https://paa-fiilestorage.s3.us-east-2.amazonaws.com/attachments/551905564528464900/560170549516512256/bikechan-stuffed-toy.gif"
            ],
            "type": "user",
            "mb": {
                "likes": 0
            },
            "metaData": {
                "threadReplies": 0
            },
            "threadTitle": "something new",
            "message": "this is a new thread",
            "createdAt": "2023-03-26T18:37:48.407Z",
            "deleted": false,
            "reply": {
                "mData": {
                    "uId": "",
                    "mId": "",
                    "cId": "",
                    "sId": ""
                },
                "hasReplies": [],
                "isReply": false,
                "message": "",
                "user": "",
                "edited": false,
                "deleted": false,
                "attachment": false
            },
            "id": "560170549516512256"
        },
        {
            "_id": "553115424211668992",
            "uId": "551906790607410176",
            "mId": "553115424211668992",
            "cId": "551905564528464900",
            "sId": "551905564528464896",
            "tId": "553115424211668992",
            "thread": true,
            "files": [
                "https://paa-fiilestorage.s3.us-east-2.amazonaws.com/attachments/551905564528464900/553115424211668992/1546445844407.jpg"
            ],
            "type": "user",
            "mb": {
                "likes": 0
            },
            "metaData": {
                "threadReplies": 5,
                "lastReplyDate": "03/07/2023, 02:25:02 AM"
            },
            "threadTitle": "\"><blah>",
            "message": "xss!\"><blah>",
            "createdAt": "2023-03-07T07:23:14.660Z",
            "deleted": false,
            "reply": {
                "mData": {
                    "uId": "",
                    "mId": "",
                    "cId": "",
                    "sId": ""
                },
                "hasReplies": [
                    "553115653359079424",
                    "553115880375783424"
                ],
                "isReply": false,
                "message": "",
                "user": "",
                "edited": false,
                "deleted": false,
                "attachment": false
            },
            "id": "553115424211668992"
        },
        {
            "_id": "552034060573113344",
            "uId": "551906790607410176",
            "mId": "552034060573113344",
            "cId": "551905564528464900",
            "sId": "551905564528464896",
            "tId": "552034060573113344",
            "thread": true,
            "files": [
                "https://paa-fiilestorage.s3.us-east-2.amazonaws.com/attachments/551905564528464900/552034060573113344/1529044646501.jpg"
            ],
            "type": "user",
            "mb": {
                "likes": 0
            },
            "metaData": {
                "threadReplies": 22,
                "lastReplyDate": "03/07/2023, 02:03:50 AM"
            },
            "threadTitle": "ueaoi",
            "message": "ueoai",
            "createdAt": "2023-03-04T07:46:17.618Z",
            "deleted": false,
            "reply": {
                "mData": {
                    "uId": "",
                    "mId": "",
                    "cId": "",
                    "sId": ""
                },
                "hasReplies": [],
                "isReply": false,
                "message": "",
                "user": "",
                "edited": false,
                "deleted": false,
                "attachment": false
            },
            "id": "552034060573113344"
        },
        {
            "_id": "552071737026830336",
            "uId": "551905043436524544",
            "mId": "552071737026830336",
            "cId": "551905564528464900",
            "sId": "551905564528464896",
            "tId": "552071737026830336",
            "thread": true,
            "files": [
                "https://paa-fiilestorage.s3.us-east-2.amazonaws.com/attachments/551905564528464900/552071737026830336/giphy.gif"
            ],
            "type": "user",
            "mb": {
                "likes": 0
            },
            "metaData": {
                "threadReplies": 3,
                "lastReplyDate": "03/04/2023, 05:16:14 AM"
            },
            "threadTitle": "new threade",
            "message": "new therad!!",
            "createdAt": "2023-03-04T10:16:00.909Z",
            "deleted": false,
            "reply": {
                "mData": {
                    "uId": "",
                    "mId": "",
                    "cId": "",
                    "sId": ""
                },
                "hasReplies": [],
                "isReply": false,
                "message": "",
                "user": "",
                "edited": false,
                "deleted": false,
                "attachment": false
            },
            "id": "552071737026830336"
        },
        {
            "_id": "552033947062664192",
            "uId": "551906790607410176",
            "mId": "552033947062664192",
            "cId": "551905564528464900",
            "sId": "551905564528464896",
            "tId": "552033947062664192",
            "thread": true,
            "files": [
                "https://paa-fiilestorage.s3.us-east-2.amazonaws.com/attachments/551905564528464900/552033947062664192/1676925413641251.png"
            ],
            "type": "user",
            "mb": {
                "likes": 0
            },
            "metaData": {
                "threadReplies": 1,
                "lastReplyDate": "03/04/2023, 05:08:08 AM"
            },
            "threadTitle": "ueoai",
            "message": "ueoai",
            "createdAt": "2023-03-04T07:45:50.850Z",
            "deleted": false,
            "reply": {
                "mData": {
                    "uId": "",
                    "mId": "",
                    "cId": "",
                    "sId": ""
                },
                "hasReplies": [],
                "isReply": false,
                "message": "",
                "user": "",
                "edited": false,
                "deleted": false,
                "attachment": false
            },
            "id": "552033947062664192"
        }
    ]
    let threadsWatched = [
        {
            "serverId": "551905564528464896",
            "channelId": "551905564528464900",
            "threadTitle": "cyberdecks",
            "threadDate": "03/03/2023, 07:42 PM",
            "threadReplies": 36,
            "threadId": "551927417892492288"
        },
        {
            "serverId": "551905564528464896",
            "channelId": "551905564528464900",
            "threadTitle": "ueaoi",
            "threadDate": "03/04/2023, 02:46 AM",
            "threadReplies": 22,
            "threadId": "552034060573113344"
        },
        {
            "serverId": "551905564528464896",
            "channelId": "551905564528464900",
            "threadTitle": "ueohtaisdntai",
            "threadDate": "03/03/2023, 07:39 PM",
            "threadReplies": 2,
            "threadId": "551926699345302528"
        },
        {
            "serverId": "551905564528464896",
            "channelId": "566636967466243072",
            "threadTitle": "adeoadoaeidaoei",
            "threadDate": "04/20/2023, 06:04 PM",
            "threadReplies": 0,
            "threadId": "569282291130064896"
        }
    ]
    let selectedThread = "553115424211668992"

    let threadMsgs = [
        {
            "_id": "553115424211668992",
            "uId": "551906790607410176",
            "mId": "553115424211668992",
            "cId": "551905564528464900",
            "sId": "551905564528464896",
            "tId": "553115424211668992",
            "thread": true,
            "files": [
                "https://paa-fiilestorage.s3.us-east-2.amazonaws.com/attachments/551905564528464900/553115424211668992/1546445844407.jpg"
            ],
            "type": "user",
            "mb": {
                "likes": 0
            },
            "metaData": {
                "threadReplies": 5,
                "lastReplyDate": "03/07/2023, 02:25:02 AM"
            },
            "threadTitle": "\"><blah>",
            "message": "xss!\"><blah>",
            "createdAt": "2023-03-07T07:23:14.660Z",
            "deleted": false,
            "reply": {
                "mData": {
                    "uId": "",
                    "mId": "",
                    "cId": "",
                    "sId": ""
                },
                "hasReplies": [
                    "553115653359079424",
                    "553115880375783424"
                ],
                "isReply": false,
                "message": "",
                "user": "",
                "edited": false,
                "deleted": false,
                "attachment": false
            },
            "id": "553115424211668992"
        },
        {
            "_id": "553115653359079424",
            "uId": "551906790607410176",
            "mId": "553115653359079424",
            "cId": "551905564528464900",
            "sId": "551905564528464896",
            "tId": "553115424211668992",
            "thread": true,
            "files": [],
            "type": "user",
            "mb": {
                "likes": 0
            },
            "metaData": {},
            "threadTitle": "",
            "message": "blah",
            "createdAt": "2023-03-07T07:24:08.152Z",
            "deleted": false,
            "reply": {
                "mData": {
                    "uId": "551906790607410176",
                    "mId": "553115424211668992",
                    "cId": "551905564528464900",
                    "sId": "551905564528464896"
                },
                "hasReplies": [
                    "553115726046367744",
                    "554882170286157824"
                ],
                "isReply": true,
                "message": "xss!\"><blah>",
                "user": "arisu.mizuki",
                "attachment": false
            },
            "id": "553115653359079424"
        },
        {
            "_id": "553115726046367744",
            "uId": "551906790607410176",
            "mId": "553115726046367744",
            "cId": "551905564528464900",
            "sId": "551905564528464896",
            "tId": "553115424211668992",
            "thread": true,
            "files": [],
            "type": "user",
            "mb": {
                "likes": 0
            },
            "threadTitle": "",
            "message": "\"><blah>",
            "createdAt": "2023-03-07T07:24:25.482Z",
            "deleted": false,
            "reply": {
                "mData": {
                    "uId": "551906790607410176",
                    "mId": "553115653359079424",
                    "cId": "551905564528464900",
                    "sId": "551905564528464896"
                },
                "hasReplies": [],
                "isReply": true,
                "message": "blah",
                "user": "arisu.mizuki",
                "attachment": false
            },
            "id": "553115726046367744"
        },
        {
            "_id": "553115765183418368",
            "uId": "551906790607410176",
            "mId": "553115765183418368",
            "cId": "551905564528464900",
            "sId": "551905564528464896",
            "tId": "553115424211668992",
            "thread": true,
            "files": [],
            "type": "user",
            "mb": {
                "likes": 0
            },
            "threadTitle": "",
            "message": "ueao",
            "createdAt": "2023-03-07T07:24:34.813Z",
            "deleted": false,
            "reply": {
                "mData": {
                    "uId": "",
                    "mId": "",
                    "cId": "",
                    "sId": ""
                },
                "hasReplies": [],
                "isReply": false,
                "message": "",
                "user": "",
                "edited": false,
                "deleted": false,
                "attachment": false
            },
            "id": "553115765183418368"
        },
        {
            "_id": "553115826088906752",
            "uId": "551906790607410176",
            "mId": "553115826088906752",
            "cId": "551905564528464900",
            "sId": "551905564528464896",
            "tId": "553115424211668992",
            "thread": true,
            "files": [],
            "type": "user",
            "mb": {
                "likes": 0
            },
            "threadTitle": "",
            "message": "euao",
            "createdAt": "2023-03-07T07:24:49.334Z",
            "deleted": false,
            "reply": {
                "mData": {
                    "uId": "",
                    "mId": "",
                    "cId": "",
                    "sId": ""
                },
                "hasReplies": [],
                "isReply": false,
                "message": "",
                "user": "",
                "edited": false,
                "deleted": false,
                "attachment": false
            },
            "id": "553115826088906752"
        },
        {
            "_id": "553115880375783424",
            "uId": "551906790607410176",
            "mId": "553115880375783424",
            "cId": "551905564528464900",
            "sId": "551905564528464896",
            "tId": "553115424211668992",
            "thread": true,
            "files": [],
            "type": "user",
            "mb": {
                "likes": 0
            },
            "metaData": {},
            "threadTitle": "",
            "message": "this xss?\"><xssbunny>",
            "createdAt": "2023-03-07T07:25:02.277Z",
            "deleted": false,
            "reply": {
                "mData": {
                    "uId": "551906790607410176",
                    "mId": "553115424211668992",
                    "cId": "551905564528464900",
                    "sId": "551905564528464896"
                },
                "hasReplies": [
                    "554881494701223936",
                    "554882228574400512"
                ],
                "isReply": true,
                "message": "xss!\"><blah>",
                "user": "arisu.mizuki",
                "attachment": false
            },
            "id": "553115880375783424"
        },
        {
            "_id": "555758014390688768",
            "uId": "551905043436524544",
            "mId": "555758014390688768",
            "cId": "551905564528464900",
            "sId": "551905564528464896",
            "tId": "553115424211668992",
            "thread": true,
            "files": [],
            "type": "user",
            "mb": {
                "likes": 0
            },
            "threadTitle": "",
            "message": "messag",
            "createdAt": "2023-03-14T14:23:56.115Z",
            "deleted": false,
            "reply": {
                "mData": {
                    "uId": "",
                    "mId": "",
                    "cId": "",
                    "sId": ""
                },
                "hasReplies": [],
                "isReply": false,
                "message": "",
                "user": "",
                "edited": false,
                "deleted": false,
                "attachment": false
            },
            "id": "555758014390688768"
        },
        {
            "_id": "557017971084994560",
            "uId": "551905043436524544",
            "mId": "557017971084994560",
            "cId": "551905564528464900",
            "sId": "551905564528464896",
            "tId": "553115424211668992",
            "thread": true,
            "files": [],
            "type": "user",
            "mb": {
                "likes": 0
            },
            "threadTitle": "",
            "message": "blah\n>greentext\n!!!header!!!\n`code`\n{link:http://google.com}\n>>reference\nblah\n",
            "createdAt": "2023-03-18T01:50:33.200Z",
            "deleted": false,
            "reply": {
                "mData": {
                    "uId": "",
                    "mId": "",
                    "cId": "",
                    "sId": ""
                },
                "hasReplies": [],
                "isReply": false,
                "message": "",
                "user": "",
                "edited": false,
                "deleted": false,
                "attachment": false
            },
            "id": "557017971084994560"
        }
    ]
    let lastReply = threadMsgs.at(-1)

    return {
        message, fullMessageDate, messageStyling,
        user, product, innerColumnsStyle, urls,
        thread, files, threadsArray, threadsWatched,
        selectedThread, userData: user, threadMsgs, lastReply,
        replies: 11, reactions: 22, highlights: 33, quotes: 44,
        isReply: false, likedByUser: false, theme: {},
        push: () => { }, setPage: () => { }, shiftToMessage: () => { },
        watchThatThread: () => { }, replyToMessage: () => { },
        openImageViewer: () => { },
    }
}