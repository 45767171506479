import React from "react";
import Avatar from "@mui/material/Avatar";
import { ButtonCustom, StyledDiv, AvatarCustom, TypographyCustom } from "../Styled";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export const MessageLeft = (props) => {
    const message = props.message ? props.message : "";
    const photoURL = props.photoURL ? props.photoURL : "";
    const displayName = props.displayName ? props.displayName : "";

    const handleAccept = () => {
        // props.AS.modals.ops.openModal('gptConfirm')
        props.gptConfirmRequest(props.serverId, props.appointmentResponse?.actions, props.appointmentResponse?.feLog ? props.appointmentResponse?.feLog : [], false)
    }
    const handleDeny = () => {
        // props.AS.modals.ops.openModal('gptConfirm')
        props.gptConfirmRequest(props.serverId, props.appointmentResponse?.actions, props.appointmentResponse?.feLog ? props.appointmentResponse?.feLog : [], true)
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>

            {/* <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                paddingBottom: '0.5em',
                marginRight: '0.5em',
                width: 'fit-content',
            }}>
                <AvatarCustom
                    alt={'GPT'}
                    src={'https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/ChatGPT_logo.svg/1200px-ChatGPT_logo.svg.png'}
                    style={{
                        bottom: 0,
                        transform: 'scale(0.6)',
                        // width: '60%', height: '60%',
                    }} />
            </div> */}

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginBottom: '10px' }}>
                <StyledDiv types={['messageRow']}>
                    <StyledDiv style={{ display: 'flex', flexDirection: 'row' }}>
                        <StyledDiv types={['messageOrange']}>
                            {props.isConfirm && props.isLast ? <div>
                                {props.array.map((message) => <span>{message}<br></br></span>)
                                }
                                <ButtonCustom onClick={handleAccept}><CheckCircleIcon/> </ButtonCustom>
                                <ButtonCustom onClick={handleDeny}> <CancelIcon/> </ButtonCustom>
                            </div>:
                            props.isConfirm ?
                                <div>{props.array.map((message) => <span>{message}<br></br></span>)}</div>
                            : <div>
                                <p>{message}</p>
                            </div>}
                        </StyledDiv>
                    </StyledDiv>
                </StyledDiv>
                <TypographyCustom
                    types={['virtualAssistantName']}>
                    CalendarGPT
                </TypographyCustom>
            </div>
        </div>
    );
};
//avatarが右にあるメッセージ（自分）
export const MessageRight = (props) => {
    const message = props.message ? props.message : "";
    return (
        <StyledDiv types={['messageRowRight']}>
            <StyledDiv types={['messageBlue']}>
                <p>{message}</p>
            </StyledDiv>
        </StyledDiv>
    );
};
