export const LANDING = '/'
export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const APPOINTMENT = '/apt/:id'
export const USER = '/u/:id'
export const HOME = '/home'
export const CHAT = '/chat'
export const VERIFY = '/verify'
export const PROFILE = '/p'
export const AUTH2 = '/auth2'
export const CHAT2 = '/chat2'
// export const VERIFY = '/verify/:mode?/:oobCode?'
export const TEST = '/TEST'
export const ACCOUNT = '/account'
export const ADMIN = '/admin'
export const PASSWORD_FORGET = '/pw-forget'

// TODO: TEST CODE REMOVE:
export const CARDDEV = '/carddevelopment'
