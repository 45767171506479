import React, { useEffect } from 'react'
import moment from 'moment'
import { AvatarCustom, ListItemButtonCustom, StyledDiv } from '../../Styled'
import ProductCard from '../../../_reusable/Cards/ProductCard/ProductCard'
import { useSocketRetrieve } from '../../../../hs/hooks'
import { RS1, RA1 } from '../../../../redux/actions'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import { connect } from 'react-redux'
import { statePropMapper } from '../../../../redux/process/selectorProcess'
import { dispatchMappers } from '../../../../redux/process/actionProcess'
import { withAS } from '../../../_contexts/withAppState'
import LayersIcon from '@mui/icons-material/Layers'
import { IconButtonCustom } from '../../Styled'
import Tooltip from '@mui/material/Tooltip';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import ThreadCardMini from '../../../_reusable/Cards/ThreadCardMini/ThreadCardMini'
import IconButton from "@mui/material/IconButton";
import { getUserColors } from "../../../../hooks";
import GenericCard from '../../../_reusable/Cards/UniversalCard'

const makeMapStateToProps = statePropMapper([
    RS1.store('_userSettings', '_userSettings'),
])

const mapDispatchToProps = dispatchMappers([
    RA1._appState.changePage,
    SA1.getOneTimeData,
])

const AvatarContainer = function (props) {
    return (
        <StyledDiv types={['singleLineDiv']}>
            <IconButton onClick={() => {
                props.AS.nav.ops.changeToProfilePageWithStateClear(props.message.uId)
            }}>
                <AvatarCustom
                    types={['smallAvatar']}
                    variant="circle"
                    alt={
                        props.user?.userName
                            ? props.user?.userName
                            : props.message.uId
                    }
                    src={props.user?.pictureId}
                />
            </IconButton>
        </StyledDiv>
    )
}

let textStylers = {
    rt: function (text, key) {
        return <span key={key + 'rt'}>{text}</span>
    },
    i: function (text, key) {
        return <i key={key + 'i'}>{text}</i>
    },
    b: function (text, key) {
        return <b key={key + 'b'}>{text}</b>
    },
    bi: function (text, key) {
        return (
            <b key={key + 'bi'}>
                <i key={key + 'bii'}>{text}</i>
            </b>
        )
    },
    u: function (text, key) {
        return <u key={key + 'u'}>{text}</u>
    },
    a: function (text, key) {
        return (
            <a
                key={key + 'a'}
                href={text}
                target="_blank"
                rel="noopener noreferrer"
            >
                {text}
            </a>
        )
    },
}

const TextContainer = function (props) {
    if (props.useProductCard) {
        return <ProductCard {...props.product} />
    }
    if (props.blogPost) {
        let color = getUserColors(props?.user?.color)
        return <span>(<b style={{ color: color }}>
            {props.user?.userName
                ? props.user.userName + ' '
                : props.message.uId + ' '}
        </b> has made a new longform thread.)</span>
    }
    let items = []
    for (var i = 0; i < props.messageStyling.length; i++) {
        if (i - 1 >= 0 && props.messageStyling[i].append) {
            if (props.messageStyling[i].mode !== 'a') {
                items.push(
                    textStylers[props.messageStyling[i].mode](
                        items[i - 1],
                        props.mId + 'i'
                    )
                )
            } else {
                items.push(
                    textStylers[props.messageStyling[i].mode](
                        props.messageStyling[i].text,
                        props.mId + 'i'
                    )
                )
            }
            items.splice(i - 1, 1)
        } else {
            items.push(
                textStylers[props.messageStyling[i].mode](
                    props.messageStyling[i].text,
                    props.mId + 'i'
                )
            )
        }
    }
    // TODO: removed the first extra space to allow smooth newlines; this may need to be added back.
    return (
        <div key={props.mId + 'TC'}
            style={{
                overflowWrap: 'break-word',
                whiteSpace: 'pre-wrap',
            }}>
            {/* {' '} */}
            {items}{' '}
            {props.edited ? (
                <small style={{ color: '#5A5A5A' }}>&nbsp;&nbsp;(edited)</small>
            ) : null}{' '}
        </div>
    )
}

const DeletedMessage = function (props) {
    return (
        <div style={{ margin: 'auto' }}>
            <span style={{ color: '#5A5A5A' }}>{props.message}</span>
        </div>
    )
}

const ImageContainer = function (props) {
    let items = [];
    for (let i = 0; i < props.files.length; i++) {
        if (props.files.length === 1) {
            items.push(
                <ImageListItem key={props.files[i]} cols={4} rows={3}>
                    <img src={props.files[i]} />
                </ImageListItem>
            )
        } else {
            if (i === 0) {
                items.push(
                    <ImageListItem key={props.files[i]} cols={2} rows={3}>
                        <img src={props.files[i]} />
                    </ImageListItem>
                )
            } else {
                items.push(
                    <ImageListItem key={props.files[i]} cols={2} rows={1}>
                        <img src={props.files[i]} />
                    </ImageListItem>
                )
            }
        }
    }
    if (props.files?.length > 0) {
        if (props.files[0] !== "loadingImagePlaceholder") {
            return (
                <ImageList sx={{ width: 550, height: 400 }} variant="quilted" cols={5} rowHeight={130}>
                    {items}
                </ImageList>
            )
        } else {
            return (
                <div>
                    <div>
                        <img style={{ height: '400px' }}
                            src={"https://i.pinimg.com/originals/90/80/60/9080607321ab98fa3e70dd24b2513a20.gif"} />
                    </div>
                </div>
            )
        }
    } else if (props.urls.length !== 0 && props.urls[0].image) {
        return (
            <div>
                <div style={{ height: '400px' }}>
                    <img
                        style={{ height: '400px' }}
                        src={props.urls[0].url}
                        alt={props.urls[0].url}
                    />
                </div>
            </div>
        )
    } else {
        return null
    }
}

const MessageHeader = function (props) {
    let color = getUserColors(props?.user?.color)
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
                <b style={{ color: color }}>
                    {props.user?.userName
                        ? props.user.userName + ' '
                        : props.message.uId + ' '}
                </b>
                <span style={{ fontSize: '11px' }}>
                    {'(' + props.fullMessageDate + ')'}
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                {props.message.thread ?
                    // <Tooltip placement="top"
                    //          title={
                    //              <ThreadCardMini
                    //                  threadId={props.message.tId}
                    //                  server={props.message.sId}
                    //                  channel={props.message.cId}/>
                    //          }>
                    <span>
                        <IconButtonCustom
                            aria-label={'bla'}
                            types={['threadConnector']}
                            size="small"
                            onClick={() => {
                                props.AS.nav.ops.changeToThread(props.message.sId, props.message.cId, props.message.tId)
                            }}>
                            <LayersIcon />
                        </IconButtonCustom>
                    </span>
                    // </Tooltip>
                    :
                    ''}
            </div>
            {/* <div>
                {props.message.thread ?
                    <div onClick={() => {
                        props.AS.push('/chat2?gotoThread=' + props.message.tId)
                        props.dispatch._appState.changePage('threads')
                    }}
                        style={{
                            fontSize: '11px',
                            // textDecoration: 'underline'
                        }}>
                        <LayersIcon />
                    </div> :
                    ''}
            </div> */}
        </div>
    )
}

const ReplyHeader = function (props) {
    return (
        // <div className="reply-div recolor-item" style={{
        //     color: '#6A6A6A',
        //     whiteSpace: 'nowrap',
        //     textOverflow: 'ellipsis',
        //     overflow: 'hidden'
        // }}>
        <div className="" style={{
            color: '#6A6A6A',
            height: "1.5em",
            // whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        }}>
            <b style={{ color: '#8A8A8A' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;┌───&nbsp;
            </b>
            {!props.deleted ? (
                <b style={{ color: '#8A8A8A' }}>{props.userName}</b>
            ) : null}
            {props.deleted ? <b style={{ color: '#8A8A8A' }}>⚉</b> : null}
            <b>&nbsp;</b>
            <span style={{ color: '#6A6A6A' }}>{props.message}</span>
            {props.edited ? (
                <small style={{ color: '#5A5A5A' }}>&nbsp;&nbsp;(...)</small>
            ) : null}
        </div>
    )
}

const ChatMessage = function (props) {
    let message = props.message
    let user = props.user

    let messageStyling = props.messageStyling
    let product = { ...message?.metaData?.product }
    let innerColumnsStyle = {}

    if (message?.metaData?.productId && message.message === 'hidden') {
        product.desc = product.description
        innerColumnsStyle = { maxWidth: '1000px' }
    }

    useEffect(() => {
        if (message?.metaData?.productId && message.message === 'hidden') {
            if (
                props?._userSettings?.transactions &&
                props?._userSettings?.transactions?.length > 0
            ) {
                props._userSettings.transactions.forEach((transaction) => {
                    if (transaction.product.id === message.metaData.productId) {
                        props.dispatch.server.getOneTimeData(
                            props.server,
                            props.channel,
                            transaction.product.id,
                            message._id
                        )
                    }
                })
            }
        }
    }, [props?._userSettings?.transactions])
    let fullMessageDate = moment(message.createdAt).format(
        'MMMM Do YYYY, h:mm:ss a'
    )
    if (!message.deleted) {
        return (
            <GenericCard
                message={message}
                messageStyling={messageStyling}
                user={user}
                product={product}
                innerColumnsStyle={innerColumnsStyle}
                urls={props.urls}

                replies={0}
                reactions={0}
                highlights={0}
                quotes={0}

                push={props.AS.push}

                cardType={'chatMsg'}
                displayMode={props?.desktop ? 'fullTotalDesktop' : 'fullTotal'}
                cardTestMode={false}
            />
        )
        // return (
        //     //TODO: Was previously StyledDiv, trying ListItemButtons for the hover & pond splash click
        //     <StyledDiv types={['recolorItem']}>
        //         {props.message.reply?.isReply ? (
        //             <div
        //                 onClick={() => {
        //                     props.shiftToMessage(props.message.reply)
        //                 }}
        //             >
        //                 <ReplyHeader
        //                     userName={props.message.reply.user}
        //                     message={props.message.reply.message}
        //                     edited={props.message.reply.edited}
        //                     deleted={props.message.reply.deleted}
        //                 />
        //             </div>
        //         ) : null}
        //         <StyledDiv types={['flexboxOuterDiv']}>
        //             <StyledDiv types={['flexboxInnerAvi']}>
        //                 <AvatarContainer AS={props.AS} message={message} user={user} />
        //             </StyledDiv>
        //             <StyledDiv types={['flexboxInnerColumns']}>
        //                 <StyledDiv types={['flexboxColumnHeader']}>
        //                     <MessageHeader
        //                         AS={props.AS}
        //                         fullMessageDate={fullMessageDate}
        //                         message={message}
        //                         user={user}
        //                         dispatch={props.dispatch}
        //                     />
        //                 </StyledDiv>
        //                 <StyledDiv
        //                     types={['flexboxColumnText']}
        //                     style={innerColumnsStyle}
        //                 >
        //                     <TextContainer
        //                         key={message.mId + 'E'}
        //                         mId={message.mId + 'EA'}
        //                         useProductCard={
        //                             message?.metaData?.productId &&
        //                             message.message === 'hidden'
        //                         }
        //                         messageStyling={messageStyling}
        //                         user={user}
        //                         edited={props.message.edited}
        //                         fullMessage={props.message}
        //                         blogPost={props.message.blogPost}
        //                         product={{ ...product }}
        //                     />
        //                 </StyledDiv>
        //                 <StyledDiv types={['flexboxColumnImg']}>
        //                     {!props.message.blogPost ? <ImageContainer
        //                         files={message.files}
        //                         urls={props.urls}
        //                         message={message}
        //                     /> : null}
        //                 </StyledDiv>
        //             </StyledDiv>
        //         </StyledDiv>
        //     </StyledDiv>
        // )
    } else {
        return (
            <StyledDiv
                types={['flexboxOuterDiv']}
                style={{ justifyContent: 'center' }}>
                <StyledDiv types={['flexboxInnerColumns']}>
                    <StyledDiv types={['flexboxColumnText']}>&nbsp;</StyledDiv>
                    <StyledDiv
                        types={['flexboxColumnText']}
                        style={{ alignContent: 'flex-start' }}>
                        <DeletedMessage message={props.message.message} />
                    </StyledDiv>
                </StyledDiv>
            </StyledDiv>
        )
    }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(ChatMessage))
