import React from 'react'
import {connect} from 'react-redux'
import {RA1, RS1} from '../../../../redux/actions.js'
import {SA1} from '../../../../hs/requestGlobals.mjs'
import {dispatchMappers} from '../../../../redux/process/actionProcess.js'
import SocketForm from '../../../_reusable/SocketForm/SocketForm'
import {statePropMapper} from '../../../../redux/process/selectorProcess'

const makeMapStateToProps = statePropMapper([
    RS1.getServer('serverData'),
    RS1.getServerProductsSubscriptions("subscriptions", "subscriptions")
])
const mapDispatchToProps = dispatchMappers([
    RA1._appState.closeModalMenu,
    SA1.createChannel,
])

const ChannelModal = function (props) {
    const dispatchMethod = ({channelName,premiumChannel,restrictToRoles}) => {
        props.setProgress(true)
        props.dispatch.server.createChannel(
            props.AS.activeServer,
            channelName,
            !!premiumChannel,
            restrictToRoles,
            'junk1',
            'junk2'
        )
    }
    let formFields = [
        {
            id: 'channelName',
            typographyLabel: 'Channel Name'
        },
    ]
    if (props.serverData.stripeEnabled && props.subscriptions && props.subscriptions.length > 0) {
        const subscriptionList = props.subscriptions.map((sub) => ({
            checkBoxText: sub.name,
            checkBoxId: sub.productMeta.roleId  ,
        }))
        formFields = [...formFields,
            {
                id: 'premiumChannel',
                type: 'checkBoxSingle',
                label: 'Subscriber Only',
                defaultValue:false,
                typographyLabel: '',
                require: false,
                page: 1,
            },
            {
                id: 'restrictToRoles',
                type: 'checkBoxGroup',
                hideIf: (formState) =>
                    formState.premiumChannel !== true,
                typographyLabel: 'Restrict to subscription roles:',
                defaultIndex: 0,
                list: subscriptionList,
                require: false,
                page: 1,
            }]
    }

    return (
        <SocketForm
            socketForm
            formMethod={'createChannel'}
            formDispatch={dispatchMethod}
            onClose={props.closeModalMenu}
            onSuccess={() => props.setProgress(false)}
            onError={() => props.setProgress(false)}
            description={'Would you like to add a channel to your community?'}
            formFields={formFields}
            formButtons={[
                {label: 'Create', icon: 'add', isSubmitButton: true}, {label: 'Cancel', isCloseButton: true}
            ]}
            formTypography={{label: 'Create Channel'}}
            successMessage={'Channel Created.'}
        />
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(ChannelModal)
