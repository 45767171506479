import React from 'react'
import { statePropMapper } from '../../../redux/process/selectorProcess'
import { RS1 } from '../../../redux/actions'
import { connect } from 'react-redux'
import UserSettingsPage from './UserSettings'

const ManageSelfPage = (props) => {
    // switch (props.manager.managementPage) {
    //     // case "settings":
    //     //     return <GeneralManagement {...props}/>
    //     // case "emoji":
    //     //     return <EmojiManagement {...props}/>
    //     // case "log":
    //     //     return <Log {...props}/>
    //     // case "config":
    //     //     return <Config {...props}/>
    //     // case "products":
    //     //     return <Products {...props}/>
    //     // case "bans":
    //     //     return <BanManagement {...props}/>
    //     // case "roles":
    //     //     return <RolePerms {...props}/>
    //     // case "integrations":
    //     //     return <IntegrationsManagement  {...props}/>
    //     // default:
    //     //     return <GeneralManagement {...props} />
    // }
    return (
        <div>
            <UserSettingsPage {...props} />
        </div>
    )
}

export default connect(null, null)(ManageSelfPage)
