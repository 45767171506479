import { defaultColorsForUsers } from '../../hooks'
import { makeHexBig } from '../util'

export const TypographyCustomStyle = (mode, palette) => ({
    TypographyCustom: {
        defaultProps: {},
        styleOverrides:{"nothing":{}},altStyleOverrides: {
            root: {},
            socketForm: {
                // alignSelf: "flex-end",
                paddingBottom: '6px',
            },
            virtualAssistantName: {
                paddingTop: '0.2em',
                fontSize: '0.8em',
                color: palette.text.secondary,
            },
            rightSidebarHeader: {
                textAlign: 'center',
                // alignSelf: 'flex-start',
            },
            generalHeader: {
                alignSelf: 'flex-end',
                paddingBottom: '6px',
                textTransform: 'capitalize',
            },
            calendarButtonTypographySubtext: {
                paddingLeft: mode.leftBarPadding,
                paddingBottom: '0px',
                paddingTop: '0px',
            },
            sidebarSubtitle: {
                alignSelf: 'flex-end',
                paddingLeft: mode.leftBarPadding,
                paddingBottom: '6px',
            },
            //TODO: cardPrimary & cardSecondary for server/product/user cards
            communityHeader: {
                width: '100%',
                alignSelf: 'flex-end',
                color: palette.text.cardPrimary,
                padding: '0px 12px',
                letterSpacing: '0.0em',
            },

            welcomeModalHeader: {
                width: '100%',
                alignSelf: 'flex-end',
                color: palette.text.cardPrimary,
                paddingLeft: '17px',
                letterSpacing: '0.0em',
                marginBottom: '0.5em'
            },
            welcomeModalCaption: (props) => {

                const getMinHeight = (dimensions, fontLineHeight) => {
                    if (!dimensions || !fontLineHeight) {
                        return '6em'
                    } else {
                        return dimensions[0] * fontLineHeight
                    }
                }

                return {
                    minHeight: getMinHeight(props?.dimensions, props?.fontLineHeight),
                    height: 'fit-content',
                }
            },
            blogTitle: {
                color: palette.text.primary,
                margin: '30px 0px'
            },
            blogPostAuthorSpan: {
                marginRight: '10px',
                color: palette.text.secondary
            },
            communitySubtext: {
                paddingLeft: mode.leftBarPadding,
                paddingTop: '10px',
            },
            communityOwner: (props) => ({
                fontSize: '0.8em',
                color: palette.text.secondary,
                '& > b': {
                    color: defaultColorsForUsers(props.ownerColor, palette)
                },
            }),

            userCardUserName: (props) => ({
                color: props?.userColor
            }),
        },
    },
})
