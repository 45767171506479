import { WifiProtectedSetupSharp } from '@mui/icons-material'
import React, { useState, useEffect } from 'react'

const ProductFields = function (props) {
    // #
    const [values, setValues] = useState({
        name: '',
        desc: '',
        icon: '',
        type: '',
        price: '',
    })

    // #
    const fieldChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
        props.fieldChange(e.target.name, e.target.value)
    }

    // #
    useEffect(() => {
        switch (props.formType) {
            case 'edit':
                setValues({ ...props.product })
                break
            case 'delete':
                break
        }
    }, [])

    // #
    return (
        <div>
            <label>Product Name:</label>
            <br />
            <input
                id={props.id}
                name={'name'}
                value={values.name}
                onChange={fieldChange}
            ></input>
            <br />

            <label>Product Description:</label>
            <br />
            <textarea
                id={props.id}
                name={'desc'}
                value={values.desc}
                onChange={fieldChange}
            ></textarea>
            <br />

            <label>Icon:</label>
            <br />
            <input
                id={props.id}
                name={'icon'}
                value={values.icon}
                onChange={fieldChange}
            ></input>
            <br />

            <label>Product Type:</label>
            <br />
            <select
                id={props.id}
                name={'type'}
                value={values.type}
                onChange={fieldChange}
            >
                <option value="subscription">Subscription</option>
                <option value="one-time">One-Time Payment</option>
                <option value="appointment">Appointment or Event</option>
                <option value="direct-message">Paid Private Message</option>
            </select>
            <br />

            <label>Price:</label>
            <br />
            <input
                id={props.id}
                name={'price'}
                value={values.price}
                onChange={fieldChange}
            ></input>
            <br />
        </div>
    )
}

export default ProductFields