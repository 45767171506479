import React from 'react'
import { connect } from 'react-redux'
import { RA1, RS1 } from '../../redux/actions'
import { SA1 } from '../../hs/requestGlobals.mjs'
import { withAuth } from '../_contexts/withAuth/index.js'
import { useEffect } from 'react'
import { dispatchMappers } from '../../redux/process/actionProcess'
import { statePropMapper } from '../../redux/process/selectorProcess.js'
import { withDesignAndState } from '../_contexts/withDesignAndState'
import { useQueryStrings } from "../../hooks";
import { withAS } from "../_contexts/withAppState";
import { ChatLayout } from "./layout";
import { streams } from "./Stream/stream-controller.js";

const mapDispatchToProps = dispatchMappers([
    RA1._appState.changePage,
    RA1.management.initManagement,
    SA1.getProfile,
    SA1.joinServer,
    RA1._appState.updateAppState,
    RA1._appState.openModalMenu,
    SA1.getProductsByServer,
    RA1._appState.openInfoModal,
])
const makeMapStateToProps = statePropMapper([
    RS1.getOpenMenu('openMenu'),
    RS1.getDMMeta('dmMeta'),
    RS1.store('_loaded', '_loaded'),
    RS1.store('_userId', '_userId'),
    RS1.store('_servers', '_servers'),
    RS1.store('_temp', '_temp'),
    RS1.store('allUsers', 'users'),
    RS1.store('_appState', '_appState'),
    RS1.store('chatServers', 'chatServers'),
])

function ChatPage(props) {
    let page = props.AS.nav.state.page
    const codes = useQueryStrings(
        {
            '?join': (code, conditions) => {
                if (conditions[0] && conditions[1]) {
                    props.dispatch.server.joinServer(code)
                }
            },
            '?vid': (code, conditions) => {
                //alert(code)
                setTimeout(() => {
                    let sourceId = code.split(":")[0];
                    let finalInvite = code.split(":")[1];
                    streams.startCallWithInvite(sourceId, finalInvite);
                }, 1000 * 2)

            },
        },
        [props._loaded, page !== undefined], false)

    useEffect(() => {
        document.title = 'PAA (Alpha) Main';
    }, [])

    useEffect(() => {
        if (props.AS.activeServer && props._loaded) {
            props.dispatch.management.initManagement(props.AS.activeServer)
        }
    }, [props.AS.activeServer, props._loaded])

    return props.AS.nav.state.readyToRenderChat ? (
        <ChatLayout
            windowRef={props.windowRef}
            AS={props.AS}
            activeServer={props.AS.activeServer}
            server={props.AS.activeServer}
            activeChannel={props.AS.activeChannel}
            userId={props._userId}
            dmMeta={props.dmMeta}
            viewingMode={props.viewingMode}
            page={page}
            chatServers={props.chatServers}
            openModalMenu={props.AS.modals.ops.openModal}
            openInfoModal={props.AS.modals.ops.openInfoModal}
            allUsers={props.allUsers}
            setActiveServer={props.AS.nav.ops.changeActiveServer}
            getProductsByServer={props.dispatch.server.getProductsByServer}
        />) : null
}

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(withAuth(withDesignAndState(withAS(ChatPage))))
