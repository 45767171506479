import React, { Component, useCallback } from 'react'
import { useEffect, useRef } from 'react'
import useState from 'react-usestateref'
import List from 'react-virtualized/dist/commonjs/List'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import CellMeasurer from 'react-virtualized/dist/commonjs/CellMeasurer/CellMeasurer.js'
import CellMeasurerCache from 'react-virtualized/dist/commonjs/CellMeasurer/CellMeasurerCache.js'
import { styled } from '@mui/material/styles'
import { scrollbarConfig } from '../../../theme/globals'

const createCache = (props) => {
    let cache = new CellMeasurerCache({
        minHeight: 20,
        fixedWidth: true,
        keyMapper: (rowIndex) => rowIndex,
    })
    return cache
}

// let cacheRef = {}
const StyledList = styled(List)(({ theme }) => {
    return { ...scrollbarConfig(theme.palette) }
})
const withReactVirtualized = (Component) => (props) => {
    const gridRef = useRef(null)
    const cacheRef = useRef(null)
    const winNum = 10
    const [initStatus, setInitStatus, initStatusRef] = useState(0)
    const [windowData, setWindowData, windowDataRef] = useState({
        top: 0,
        bottom: 2 * winNum,
    })
    const [fullList, setFullList, fullListRef] = useState(props.listData)
    const [size, setSize, sizeRef] = useState(props.listData.size)

    if (props.hasOwnProperty("virtualizedList")) {
        if (props?.virtualizedList.hasOwnProperty("current")) {
            if (gridRef.hasOwnProperty("current")) {
                props.virtualizedList.current = {
                    scrollToBottom: () => gridRef.current.scrollToRow(props.listData.size - 1),
                    scrollToTop: () => gridRef.current.scrollToRow(0),
                    scrollToRow: (index) => gridRef.current.scrollToRow(index)
                }
            }
        }
    }

    function clearCache() {
        if (gridRef.current !== null) {
            cacheRef.current.clearAll()
            cacheRef.current = createCache(props)
            gridRef.current.recomputeRowHeights(0)
        }
    }

    useEffect(() => {
        setFullList(props.listData)
        setSize(props.listData.size)
        // clearCache()
    }, [props.listData, props.listData.size])

    function rowRenderer({ key, parent, index, style }) {
        let componentProps = fullListRef.current.get(windowData.top + index)
        return (
            <CellMeasurer
                cache={cacheRef.current}
                columnIndex={0}
                key={key}
                parent={parent}
                rowIndex={index}
            >
                {({ measure, registerChild }) =>
                    <div ref={registerChild} style={style} key={key} onLoad={measure}
                        onContextMenu={(e) => props.selectItem(e, componentProps)}>
                        {props?.topDummyMessage && (index === 0) ?
                            props?.topDummyMessage :
                            null}
                        <Component passdownProps={props.passdownProps}
                            componentProps={componentProps} {...componentProps} />
                        {props?.dummyMessage && (index === props?.listData?.size - 1) ?
                            props?.dummyMessage :
                            null}
                    </div>}
            </CellMeasurer>
        )
    }

    useEffect(() => {
        if (initStatus === 0) {
            cacheRef.current = createCache(props)
        }
        if (initStatus < 5) {
            clearCache()
            setInitStatus((initStatus) => initStatus + 1)
        }
        if (initStatusRef.current === 5) {
            gridRef.current.measureAllRows()
            setInitStatus((initStatus) => initStatus + 1)
        }
        if (initStatusRef.current === 6) {
            gridRef.current.recomputeRowHeights(0)
        }
        if (initStatusRef.current === 7) {
            gridRef.current.recomputeRowHeights(0)
        }
    }, [initStatus])

    function bindListRef(ref) {
        gridRef.current = ref
    }

    if (initStatus > 2) {
        return (
            <div style={{ height: props.height, overflowX: 'hidden' }}>
                <AutoSizer onResize={() => {
                    cacheRef.current.clearAll()
                }}>
                    {({ width, height }) => {
                        return (
                            <StyledList
                                ref={bindListRef}
                                style={{
                                    position: "fixed",
                                    // backgroundColor: "red",
                                    width: props?.desktop ? "73vw" : "100%",
                                    zIndex: "1003",
                                    overflowX: 'hidden'
                                }}
                                rowRenderer={rowRenderer}
                                height={height}
                                rowHeight={cacheRef.current.rowHeight}
                                overscanRowCount={5}
                                scrollToAlignment={'end'}
                                rowCount={size}
                                deferredMeasurementCache={cacheRef.current}
                                width={width}
                            />
                        )
                    }}
                </AutoSizer>
            </div>
        )
    } else {
        return null
    }
}

export default withReactVirtualized
