import React from 'react'
import { ButtonCustom, StyledDiv } from '../../../Styled'
import ProductCard from '../../../../_reusable/Cards/ProductCard/ProductCard'

const CreatorButtons = function (props) {
    // #
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <ButtonCustom types={props.types} onClick={props.addPaymentCreds}>
                Add Payment Credentials
            </ButtonCustom>

            <ButtonCustom types={props.types} onClick={props.addProduct}>
                New Product
            </ButtonCustom>
        </div>
    )
}

const CreatorItem = function (props) {
    // #
    return (
        <StyledDiv types={['catalogItem']}>
            <ProductCard isCreator={true} {...props.product} />

            <StyledDiv types={['catalogItemButtons']}>
                <ButtonCustom
                    id={props.product.id}
                    types={['square']}
                    onClick={props.onClick}
                >
                    Edit
                </ButtonCustom>

                <ButtonCustom
                    id={props.product.id}
                    types={['square']}
                    onClick={props.deleteProduct}
                >
                    Delete
                </ButtonCustom>
            </StyledDiv>
        </StyledDiv>
    )
}

export { CreatorButtons, CreatorItem }
