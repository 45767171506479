import { createSelector as cS } from 'reselect'
import { cachedSelect } from '../cache/cachedSelect.js'
import _ from 'lodash'
import { chatServers } from '../reducers/chatServers'

const createSelector = function (metaData, ...args) {
    let tempStore = null
    return (state, props) => {
        let newOne = cS(...args)(state, props)
        // let x = _.isEqual(newOne,tempStore)
        if (_.isEqual(newOne, tempStore)) {
            return tempStore
        } else {
            tempStore = newOne
            return tempStore
        }
    }
}

const arrayToJson = function (array, id) {
    let json = array.reduce((json, value, key) => {
        json[value[id]] = value
        return json
    }, {})
    return json
}

/*
 * Convention: Atomic selectors should always start with _. These will never be referenced directly.
 * In contrast, memoized selectors should never start with _. This will be accessed through the reference object.
 *
 * Furthermore, no selector should have a key "store"
 * */
export const selectorMeta = {
    _getServer: {
        type: 'atomic',
        name: '_getServer',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state.chatServers[props.server],
        }),
    },
    _getServerProducts: {
        type: 'atomic',
        name: '_getServerProducts',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state.products[props.server],
        }),
    },
    _getServerProductsSubscriptions: {
        type: 'atomic',
        name: '_getServerProductsSubscriptions',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => {
                if (state.products[props.server]) {
                    return state.products[props.server].filter((product) => product.type === "subscription")
                } else {
                    return []
                }
            },
        }),
    },
    _getOpenMenu: {
        type: 'atomic',
        name: '_getOpenMenu',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state._appState?.openMenu,
        }),
    },

    _getDMMeta: {
        type: 'atomic',
        name: '_getDMMeta',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state._appState?.dmMeta,
        }),
    },
    _getProfileMeta: {
        type: 'atomic',
        name: '_getProfileMeta',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state._appState?.profileMeta,
        }),
    },
    _getSocketResponses: {
        type: 'atomic',
        name: '_getSocketResponses',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state) => state.apiResponses.socket,
        }),
    },
    _getAPIResponse: {
        type: 'atomic',
        name: '_getAPIResponse',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state.apiResponses[props.apiId],
        }),
    },
    _getServerManager: {
        type: 'atomic',
        name: '_getServerManager',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state.management[props.server],
        }),
    },
    _getChannel: {
        type: 'atomic',
        name: '_getChannel',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state.channels[props.channel],
        }),
    },
    _getCalendar: {
        type: 'atomic',
        name: '_getChannel',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state.calendar[props.serverId],
        }),
    },
    _getAllChannels: {
        type: 'atomic',
        name: '_getAllChannels',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state.channels,
        }),
    },
    _getChatInput: {
        type: 'atomic',
        name: '_getChatInput',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state.chatInputs[props.channel],
        }),
    },
    _getChannelWindow: {
        type: 'atomic',
        name: '_getChannelWindow',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state.channelWindows[props.channel],
        }),
    },
    _getAbstractRole: {
        type: 'atomic',
        name: '_getAbstractRole',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state.abstractRoles[props.serverRole],
        }),
    },
    _getTrackedServerList: {
        type: 'atomic',
        name: '_getTrackedServerList',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state._servers,
        }),
    },
    _getAbstractRoles: {
        type: 'atomic',
        name: '_getAbstractRoles',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state.abstractRoles,
        }),
    },
    _getUser: {
        type: 'atomic',
        name: '_getUser',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state.users[props.userId],
        }),
    },
    _getMessagesLength: {
        type: 'atomic',
        name: '_getMessagesLength',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => Object.values(state.messages).length,
        }),
    },
    _getThreadsWatched: {
        type: 'atomic',
        name: '_getThreadsWatched',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => {
                if (state?.chatServers[state._userId]?.threadsWatched) {
                    return state.chatServers[state._userId].threadsWatched.filter(threadData => threadData.channelId === props.channel)
                } else {
                    return []
                }
            },
        }),
    },
    _getAllThreadsWatched: {
        type: 'atomic',
        name: '_getAllThreadsWatched',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state.chatServers[state._userId].threadsWatched,
        }),
    },
    _getOtherUser: {
        type: 'atomic',
        name: '_getOtherUser',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state.users[props.otherUserId],
        }),
    },
    _getUsers: {
        type: 'atomic',
        name: '_getUsers',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state.users,
        }),
    },
    _getMessages: {
        type: 'atomic',
        name: '_getUsers',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state.messages,
        }),
    },
    _getUserMeta: {
        type: 'atomic',
        name: '_getUserMeta',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state.userMetas[props.userId + '_' + props.server],
        }),
    },
    _getAppState: {
        type: 'atomic',
        name: '_getAppState',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state._appState,
        }),
    },
    _getMessage: {
        type: 'atomic',
        name: '_getMessage',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state.messages[props.messageJSON],
        }),
    },
    _getUserFromMeta: {
        type: 'atomic',
        name: '_getUserFromMeta',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) =>
                state.users[
                props.userMeta.id.substr(0, props.userMeta.id.indexOf('_'))
                ],
        }),
    },
    _getDMChannelPlusUserData: {
        type: 'atomic',
        name: '_getDMChannelPlusUserData',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => {
                let DMChannelPlusUserData = { userId: state._userId }
                if (props.channelType === 'dm') {
                    let otherUserIdArray = state.channels[
                        props.channel
                    ].channelMeta.users.filter((e) => e !== state._userId)
                    DMChannelPlusUserData.otherUser =
                        state.users[otherUserIdArray[0]]
                    return DMChannelPlusUserData
                }
                return DMChannelPlusUserData
            },
        }),
    },
    _getServerAbstractRoles: {
        type: 'atomic',
        name: '_getServerAbstractRoles',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => state.chatServers[props.server].roles,
        }),
    },
    _getMessageAndUser: {
        type: 'atomic',
        name: '_getMessageAndUser',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => {
                let userMetaId =
                    state.messages[props.message].uId + '_' + props.server
                let userMeta = state.userMetas[userMetaId]
                let roles = {}
                userMeta.roles.map((role) => {
                    roles[role] = state.abstractRoles[role]
                })
                return {
                    message: state.messages[props.message],
                    user: state.users[state.messages[props.message].uId],
                    userMeta: userMeta,
                    userRoles: roles,
                    userRolesList: userMeta.roles,
                }
            },
        }),
    },
    _getServerUsersOb: {
        type: 'atomic',
        name: '_getServerUsersOb',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => {
                let serverUsers = {}
                const server = state.chatServers[props.server]
                server.users.map((user) => {
                    let userData = {}
                    userData = { ...state.users[user.id] }
                    userData.color =
                        state.abstractRoles[user.primaryRole].perms.color
                    userData.primaryRole = user.primaryRole
                    userData.roles = user.roles
                    serverUsers[user.id] = userData
                })
                return serverUsers
            },
        }),
    },
    _getChannelMessages: {
        type: 'atomic',
        name: '_getChannelMessages',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) =>
                state.channelWindows[props.channel].allMessages,
        }),
    },
    _getOwnUsername: {
        type: 'atomic',
        name: '_getOwnUsername',
        cached: false,
        cacheKeys: [],
        operation: cachedSelect({
            cached: false,
            label: '',
            orderedCacheKeys: [],
            select: (state, props) => {
                return state?.users[state?._userId]?.userName
            }
        }),
    },
    getMessageAndUser: {
        type: 'memoized',
        name: 'getMessageAndUser',
        make: 'makeGetMessageAndUser',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getMessageAndUser.operation],
                (userAndMessage) => {
                    return userAndMessage
                }
            )
        },
    },
    getSocketResponses: {
        type: 'memoized',
        name: 'getSocketResponses',
        make: 'makeGetSocketResponses',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getSocketResponses.operation],
                (socketResponses) => socketResponses
            )
        },
    },
    getUserFromMeta: {
        type: 'memoized',
        name: 'getUserFromMeta',
        make: 'makeGetUserFromMeta',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getUserFromMeta.operation],
                (user) => {
                    return user
                }
            )
        },
    },
    getChannelMessages: {
        type: 'memoized',
        name: 'getChannelMessages',
        make: 'makeGetChannelMessages',
        operation: function () {
            return createSelector(
                this,
                [
                    selectorMeta._getChannelMessages.operation,
                    selectorMeta._getMessages.operation,
                ],
                (channelMessageArray, messages) => {
                    let channelMessages = {}
                    channelMessageArray.map((message) => {
                        channelMessages[message] = messages[message]
                    })
                    return channelMessages
                }
            )
        },
    },
    getChatInput: {
        type: 'memoized',
        name: 'getChatInput',
        make: 'makeGetChatInput',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getChatInput.operation],
                (chatInput) => {
                    return chatInput
                }
            )
        },
    },
    getMessage: {
        type: 'memoized',
        name: 'getMessage',
        make: 'makeGetMessage',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getMessage.operation],
                (message) => {
                    return message
                }
            )
        },
    },
    getAppState: {
        type: 'memoized',
        name: 'getAppState',
        make: 'makeGetAppState',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getAppState.operation],
                (channel) => {
                    return channel
                }
            )
        },
    },
    getOrderedServerList: {
        type: 'memoized',
        name: 'getOrderedServerList',
        make: 'makeGetOrderedServerList',
        operation: function () {
            return createSelector(
                this,
                [
                    selectorMeta._getTrackedServerList.operation,
                    (state) => state.chatServers,
                ],
                (trackedServerList, chatServers) => {
                    let newTSL = [...trackedServerList]
                    // Object.keys(chatServers).forEach((key) => {
                    //     let serverId = chatServers[key].id
                    //     if (!newTSL.includes(serverId)) {
                    //         newTSL.push(serverId)
                    //     }
                    // })
                    return newTSL
                }
            )
        },
    },
    getThreads: {
        type: 'memoized',
        name: 'getThreads',
        make: 'makeGetThreads',
        operation: function () {
            return createSelector(
                this,
                [
                    selectorMeta._getChannel.operation,
                    selectorMeta._getMessages.operation,
                    selectorMeta._getMessagesLength.operation,
                ],
                (channel, messages, length) => {
                    let threads = channel?.activeThreads?.map((messageId) => {
                        if (!messages.hasOwnProperty(messageId)) {
                            return 'reload'
                        } else {
                            return messages[messageId]
                        }
                    })
                    return threads ? threads : []
                }
            )
        },
    },
    getChannel: {
        type: 'memoized',
        name: 'getChannel',
        make: 'makeGetChannel',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getChannel.operation],
                (channel) => {
                    return channel
                }
            )
        },
    },
    getOpenMenu: {
        type: 'memoized',
        name: 'getOpenMenu',
        make: 'makeGetOpenMenu',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getOpenMenu.operation],
                (openMenu) => {
                    return openMenu
                }
            )
        },
    },
    getSelectedThread: {
        type: 'memoized',
        name: 'getSelectedThread',
        make: 'makeGetSelectedThreads',
        operation: function () {
            return createSelector(
                this,
                [
                    (state, props) => {
                        return props?.AS?.threads?.state?.selectedThread ? props?.AS?.threads?.state?.selectedThread : props.selectedThread
                    },
                    selectorMeta._getMessages.operation,
                ],
                (selectedThread, messages) => {
                    return Object.values(messages).filter(
                        (message) => message.tId === selectedThread
                    )
                }
            )
        },
    },
    getDMMeta: {
        type: 'memoized',
        name: 'getDMMeta',
        make: 'makeGetDMMeta',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getDMMeta.operation],
                (openMenu) => {
                    return openMenu
                }
            )
        },
    },
    getProfileMeta: {
        type: 'memoized',
        name: 'getProfileMeta',
        make: 'makeGetProfileMeta',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getProfileMeta.operation],
                (profileMeta) => {
                    return profileMeta
                }
            )
        },
    },
    getServerManager: {
        type: 'memoized',
        name: 'getServerManager',
        make: 'makeGetServerManager',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getServerManager.operation],
                (serverManager) => {
                    return serverManager
                }
            )
        },
    },
    getCalendar: {
        type: 'memoized',
        name: 'getCalendar',
        make: 'makeGetCalendar',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getCalendar.operation],
                (calendar) => {
                    return calendar
                }
            )
        },
    },
    getDMChannelPlusUserData: {
        type: 'memoized',
        name: 'getDMChannelPlusUserData',
        make: 'makeGetDMChannelPlusUserData',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getDMChannelPlusUserData.operation],
                (dmChannelPlusUserData) => {
                    return dmChannelPlusUserData
                }
            )
        },
    },
    getServerUsers: {
        type: 'memoized',
        name: 'getServerUsers',
        make: 'makeGetServerUsers',
        dependsOn: ['props.userId', 'props.server'],
        operation: function () {
            return createSelector(
                this,
                [
                    selectorMeta._getServer.operation,
                    selectorMeta._getUsers.operation,
                    selectorMeta._getAbstractRoles.operation,
                ],
                (server, users, abstractRoles) => {
                    let serverUsers = []
                    server.users.map((user) => {
                        let userData = {}
                        userData = { ...users[user.id] }
                        userData.color =
                            abstractRoles[user.primaryRole].perms.color
                        userData.primaryRole = user.primaryRole
                        userData.roles = user.roles
                        serverUsers.push(userData)
                    })
                    return serverUsers
                }
            )
        },
    },
    getVisibleChannels: {
        type: 'memoized',
        name: 'getVisibleChannels',
        make: 'makeGetVisibleChannels',
        dependsOn: ['props.userId', 'props.server'],
        operation: function () {
            return createSelector(
                this,
                [
                    selectorMeta._getUserMeta.operation,
                    selectorMeta._getServer.operation,
                    selectorMeta._getAllChannels.operation,
                    selectorMeta._getServerAbstractRoles.operation,
                    selectorMeta._getAbstractRoles.operation,
                ],
                (userMeta, server, allChannels, serverAbstractRoles, abstractRoles) => {
                    let allChannelsVisible = false

                    let channelList = [...server.channels]
                    let visibleChannels = []
                    if (userMeta === undefined) {
                        return visibleChannels
                    } else {
                        userMeta.roles.forEach(roleId => {
                            if (abstractRoles[roleId].perms.isAdmin || abstractRoles[roleId].perms.isOwner) {
                                allChannelsVisible = true
                            }
                        })

                        channelList.map((channel) => {
                            userMeta.roles.map((role) => {
                                if (
                                    allChannels[
                                        channel
                                    ].viewableToRoles.includes(role) ||
                                    allChannels[channel]?.channelMeta?.dm ||
                                    allChannels[channel]?.channelMeta?.profile ||
                                    allChannelsVisible
                                ) {
                                    //#TODO: should probably remove the dm check here.
                                    if (!visibleChannels.includes(channel)) {
                                        visibleChannels.push(channel)
                                    }
                                }
                            })
                        })
                    }
                    return visibleChannels
                }
            )
        },
    },
    getUserTopRank: {
        type: 'memoized',
        name: 'getUserTopRank',
        make: 'makeGetUserTopRank',
        operation: function () {
            return createSelector(
                this,
                [
                    selectorMeta._getUserMeta.operation,
                    selectorMeta._getServerAbstractRoles.operation,
                    selectorMeta._getAbstractRoles.operation,
                ],
                (userMeta, serverRoles, abstractRoles) => {
                    const serverAbstractRolesArray = serverRoles.map(
                        (role) => abstractRoles[role]
                    )
                    if (userMeta === undefined) {
                        return 5
                    }
                    const userRoles = userMeta.roles
                    const serverAbstractRoles = arrayToJson(
                        serverAbstractRolesArray,
                        'id'
                    )
                    let topRank = serverAbstractRolesArray.length + 10
                    let j = 0
                    for (j = 0; j < userRoles.length; j++) {
                        topRank = Math.min(
                            serverAbstractRoles[userRoles[j]].rank,
                            topRank
                        )
                    }
                    return topRank
                }
            )
        },
    },
    getUserBooleanPerm: {
        type: 'memoized',
        name: 'getUserBooleanPerm',
        make: 'makeGetUserBooleanPerm',
        operation: function (perm) {
            return createSelector(
                this,
                [
                    selectorMeta._getUserMeta.operation,
                    selectorMeta._getServerAbstractRoles.operation,
                    selectorMeta._getAbstractRoles.operation,
                ],
                (userMeta, serverRoles, abstractRoles) => {
                    if (userMeta === undefined) {
                        return true
                    }
                    const userRoles = userMeta.roles
                    const serverAbstractRolesArray = serverRoles.map(
                        (role) => abstractRoles[role]
                    )
                    const serverAbstractRoles = arrayToJson(
                        serverAbstractRolesArray,
                        'id'
                    )
                    const userAbstractRoles = userRoles.map(
                        (role) => serverAbstractRoles[role]
                    )
                    let permStatus = false
                    userAbstractRoles.map((abstractRole) => {
                        if (abstractRole.perms[perm]) {
                            permStatus = true
                        }
                    })
                    return permStatus
                }
            )
        },
    },
    getUser: {
        type: 'memoized',
        name: 'getUser',
        make: 'makeGetUser',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getUser.operation],
                (user) => {
                    return user
                }
            )
        },
    },
    getMessagesLength: {
        type: 'memoized',
        name: 'getMessagesLength',
        make: 'makeGetMessagesLength',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getMessagesLength.operation],
                (length) => {
                    return length
                }
            )
        },
    },
    getThreadsWatched: {
        type: 'memoized',
        name: 'getThreadsWatched',
        make: 'makeGetThreadsWatched',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getThreadsWatched.operation,
                selectorMeta._getMessagesLength.operation],
                (threadsWatched, length) => {
                    return threadsWatched
                }
            )
        },
    },
    getAllThreadsWatched: {
        type: 'memoized',
        name: 'getAllThreadsWatched',
        make: 'makeGetThreadsWatched',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getAllThreadsWatched.operation,
                selectorMeta._getMessagesLength.operation],
                (threadsWatched, length) => {
                    return threadsWatched
                }
            )
        },
    },
    getOtherUser: {
        type: 'memoized',
        name: 'getOtherUser',
        make: 'makeGetOtherUser',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getOtherUser.operation],
                (user) => {
                    return user
                }
            )
        },
    },
    getUserMeta: {
        type: 'memoized',
        name: 'getUserMeta',
        make: 'makeGetUserMeta',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getUserMeta.operation],
                (user) => {
                    return user
                }
            )
        },
    },
    getServer: {
        type: 'memoized',
        name: 'getServer',
        make: 'makeGetServer',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getServer.operation],
                (server) => {
                    return server
                }
            )
        },
    },
    getServerProducts: {
        type: 'memoized',
        name: 'getServerProducts',
        make: 'makeGetServerProducts',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getServerProducts.operation],
                (serverProducts) => {
                    return serverProducts
                }
            )
        },
    },
    getServerProductsSubscriptions: {
        type: 'memoized',
        name: 'getServerProductsSubscriptions',
        make: 'makeGetServerProductsSubscriptions',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getServerProductsSubscriptions.operation],
                (serverProductsSubscriptions) => {
                    return serverProductsSubscriptions
                }
            )
        },
    },
    getServerProductsSubscriptionsOwned: {
        type: 'memoized',
        name: 'getServerProductsSubscriptionsOwned',
        make: 'makeGetServerProductsSubscriptionsOwned',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getServerProductsSubscriptions.operation, selectorMeta._getUserMeta.operation],
                (serverProductsSubscriptions, userMeta) => {
                    let ownedSubscriptions = []
                    if (userMeta?.roles) {
                        serverProductsSubscriptions.forEach((sub) => {
                            if (userMeta.roles.includes(sub.productMeta.roleId)) {
                                ownedSubscriptions = [...ownedSubscriptions, sub.id]
                            }
                        })
                    }
                    return ownedSubscriptions
                }
            )
        },
    },
    getOwnUsername: {
        type: 'memoized',
        name: 'getOwnUsername',
        make: 'makeGetOwnUsername',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getOwnUsername.operation],
                (username) => {
                    return username
                }
            )
        },
    },
    getUsersOnServer: {
        type: 'memoized',
        name: 'getUsersOnServer',
        make: 'makeGetUsersOnServer',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getServer.operation],
                (server) => {
                    return server.users
                }
            )
        },
    },
    getChannelWindow: {
        type: 'memoized',
        name: 'getChannelWindow',
        make: 'makeGetChannelWindow',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getChannelWindow.operation],
                (channelWindow) => {
                    return channelWindow
                }
            )
        },
    },
    getServerRoles: {
        type: 'memoized',
        name: 'getServerRoles',
        make: 'makeGetServerRoles',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getServer.operation],
                (server) => {
                    const roles = server.roles
                    return roles
                }
            )
        },
    },
    getServerAbstractRoles: {
        type: 'memoized',
        name: 'getServerAbstractRoles',
        make: 'makeGetServerAbstractRoles',
        operation: function () {
            return createSelector(
                this,
                [
                    selectorMeta._getServerAbstractRoles.operation,
                    selectorMeta._getAbstractRoles.operation,
                ],
                (serverRoles, abstractRoles) => {
                    const serverAbstractRoles = serverRoles.map(
                        (role) => abstractRoles[role]
                    )
                    return serverAbstractRoles
                }
            )
        },
    },
    getServerUsersOb: {
        type: 'memoized',
        name: 'getServerUsersOb',
        make: 'makeGetServerUsersOb',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getServerUsersOb.operation],
                (serverUsers) => {
                    return serverUsers
                }
            )
        },
    },
    getUsersWithRole: {
        type: 'memoized',
        name: 'getUsersWithRole',
        make: 'makeGetUsersWithRole',
        operation: function () {
            return createSelector(
                this,
                [
                    selectorMeta._getServer.operation,
                    selectorMeta.getServerUsers.operation(),
                ],
                (server, serverUsers) => {
                    let usersWithRole = {}
                    server.roles.map((role) => {
                        usersWithRole[role] = []
                    })
                    serverUsers.map((user) => {
                        usersWithRole[user.primaryRole].push(user)
                    })
                    return usersWithRole
                }
            )
        },
    },
    getAbstractRole: {
        type: 'memoized',
        name: 'getAbstractRole',
        make: 'makeGetAbstractRole',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getAbstractRole.operation],
                (abstractRole) => {
                    const role = abstractRole
                    return role
                }
            )
        },
    },
    getOnServer: {
        type: 'memoized',
        name: 'getOnServer',
        make: 'makeGetOnServer',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getServer.operation],
                (server) => {
                    return server.users
                }
            )
        },
    },
    getAPIResponse: {
        type: 'memoized',
        name: 'getAPIResponse',
        make: 'makeGetAPIResponse',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getAPIResponse.operation],
                (APIResponse) => {
                    return APIResponse
                }
            )
        },
    },
    getChannels: {
        type: 'memoized',
        name: 'getChannels',
        make: 'makeGetChannels',
        operation: function () {
            return createSelector(
                this,
                [selectorMeta._getServer.operation],
                (server) => {
                    if (server !== undefined) {
                        return server.channels
                    } else {
                        return null
                    }
                }
            )
        },
    },
}
