import React from 'react'
import ChatWindow from '../../ChatWindow'
import { vDH } from '../../Styled'

const Log = function (props) {
    return (
        <div style={{ height: `calc(${vDH(95)} - 6.4em)` }}>
            <ChatWindow
                key={props.serverData.logId}
                inputEnabled={false}
                passedHeight={vDH(85)}
                userId={props.userId}
                server={props.activeServer}
                channel={props.serverData.logId}
            />
        </div>
    )
}

export default Log
