import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {RS1} from '../../../redux/actions.js'
import {dispatchMappers} from '../../../redux/process/actionProcess.js'
import {statePropMapper} from '../../../redux/process/selectorProcess.js'
import ChatWindow from './index'
import {SA1} from '../../../hs/requestGlobals.mjs'

const makeMapStateToProps = statePropMapper([
    RS1.getChannelWindow('channelWindow'),
    RS1.getChannel('channelData'),
])
const mapDispatchToProps = dispatchMappers([SA1.updateSession])
const ChatWindowLoader = (props) => {
    const [initialLoad, setInitialLoad] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [windowKey, setWindowKey] = useState('K0' + props.channel)
    useEffect(() => {
        if (props.channelWindow.allMessages.size === 0 && !initialLoad) {
            if (props.userProfilePage) {
                if (props.userProfileThread === null) {
                    props.dispatch.server.updateSession(
                        'loadUsersProfilePage',
                        props.server,
                        props.channel,
                        null,
                        '',
                        ''
                    )
                } else {
                    props.dispatch.server.updateSession(
                        'loadUsersProfilePageSingleThread',
                        props.server,
                        props.channel,
                        null,
                        '',
                        '',
                        props.threadId
                    )
                }
            } else {
                props.dispatch.server.updateSession(
                    'loadChannel',
                    props.server,
                    props.channel,
                    null,
                    '',
                    ''
                )
            }
            setInitialLoad(true)
        }
        if (
            !loaded &&
            initialLoad &&
            props.channelWindow.allMessages.size > 0
        ) {
            setWindowKey((windowKey) => windowKey + 'KCW')
            setLoaded(true)
        }
    }, [props.channelWindow, initialLoad, loaded])
    useEffect(() => {
        if (
            props.channelWindow.allMessages.size > 0 &&
            !initialLoad &&
            !loaded
        ) {
            setLoaded(true)
            setInitialLoad(true)
        }
    }, [])
    if (loaded) {
        const {channelWindow, channelData, ...otherProps} = props
        return <ChatWindow key={windowKey} {...otherProps} />
    } else {
        return null
    }
}
export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(ChatWindowLoader)
