import React from 'react'
import {ButtonCustom} from "../../Chat/Styled";

const BackToAuthButton = (props) => {
    return (
        <ButtonCustom
            variant="contained"
            color="primary"
            onClick={(e) => {
                window.location = window.location.origin + "/auth2"
            }}
        >
            Sign In
        </ButtonCustom>
    )
}

export default BackToAuthButton
