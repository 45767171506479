import React, { useEffect, useState } from 'react'
import { CheckboxCustom, FormControlLabelCustom, StyledDiv, TextFieldCustom, TypographyCustom } from '../../Chat/Styled'
import DateTimePicker from '@mui/lab/DateTimePicker'
import { Calendar } from "react-multi-date-picker"
import {
    Checkbox, Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select, TextField,
    InputAdornment
} from '@mui/material'
import { ColorPicker } from 'mui-color'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from "@mui/x-date-pickers";
import Grid from "@mui/material/Grid";

const CalendarPicker = function (props) {
    const [value, setValue] = useState([])
    let currentTimestamp = new Date().getTime()
    const errorStatus = props.errorStatus(props.id)(props.method)
    const errorData = props.errorData(props.id)(props.method)
    return (<div>
        <Divider />
        <Calendar
            multiple
            style={{ border: "0px" }}
            shadow={false}
            value={value}
            minDate={new Date().getTime()}
            maxDate={new Date().setDate(180)}
            onChange={(newValue) => {
                setValue(newValue)
                props.fieldChangeWrapped({
                    target: {
                        value: newValue.map((selected) => ({
                            day: selected.day,
                            month: selected.month,
                            year: selected.year,
                            str: "" + selected.year + "." + String(selected.month.number).padStart(2, '0') + "." + String(selected.day).padStart(2, '0')
                        }))
                    }
                })
            }}
        />
        <Divider />
    </div>
    )
}
const TimePick = function (props) {
    let currentTimestamp = new Date().getTime()
    const [value, setValue] = useState(null)
    const errorStatus = props.errorStatus(props.id)(props.method)
    const errorData = props.errorData(props.id)(props.method)
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
                label="Choose a time."
                value={value}
                views={['hours', 'minutes']}
                onChange={(newValue) => {
                    setValue(newValue)
                    props.fieldChangeWrapped({ target: { value: newValue } })
                }}
                renderInput={(params) => <TextField {...params}
                    style={{ width: 250 }}
                    error={errorStatus}
                    helperText={errorStatus ? errorData : null}
                    variant={"standard"} />}
            /></LocalizationProvider>
    )
}

const DTPicker = function (props) {
    const [value, setValue] = useState('')
    let currentTimestamp = new Date().getTime()
    const errorStatus = props.errorStatus(props.id)(props.method)
    const errorData = props.errorData(props.id)(props.method)
    return (<DateTimePicker
        id={props.id}
        inputFormat={value === '' ? '' : null}
        renderInput={(otherProps) => {
            let modifiedProps = {
                ...otherProps,
                error: errorStatus,
                helperText: errorStatus ? errorData : null,
                types: props.types,
            }
            return <TextFieldCustom {...modifiedProps} />
        }}
        shouldDisableDate={(date) => date.valueOf() < currentTimestamp}
        label={props.typographyLabel}
        variant="standard"
        value={value}
        onChange={(newValue) => {
            setValue(newValue)
            props.fieldChangeWrapped(newValue)
        }}
    />)
}

const SelectGroup = function (props) {
    const [selectStore, setSelectStore] = useState(props.otherData.list)
    const [selectIndex, setSelectIndex] = useState(props.otherData.defaultIndex)
    const handleChange = (id) => (e, index) => {
        setSelectIndex(index)
        props.fieldChangeWrapped({
            target: { value: selectStore[index].value },
        })
    }
    useEffect(() => {
        props.fieldChangeWrapped({
            target: { value: selectStore[props.otherData.defaultIndex].value },
        })
    }, [])
    return (
        <FormControl variant="standard" sx={{ minWidth: 120 }} size="small">
            <InputLabel id={props.typographyLabel + props.id}>
                {props.typographyLabel}
            </InputLabel>
            <Select
                id={props.typographyLabel + props.id + "sel"}
                style={{ width: 250 }}
                value={selectStore[selectIndex].value}
                // labelId="demo-simple-select-standard-label"
                // id="demo-simple-select-standard"
                label={props.typographyLabel}
            >
                {selectStore.map((listData, index) => {
                    return (
                        <MenuItem
                            id={props.typographyLabel + props.id + index}
                            key={'MI' + props.typographyLabel + props.id + index}
                            name={'MIN' + listData.value}
                            value={listData.value}
                            onClick={(e) => handleChange(props.id)(e, index)}
                        >
                            {props.otherData.formatter(listData)}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

const CheckboxGroup = function (props) {
    const [cbInputStore, setCbInputStore] = useState([])
    const handleChange = (id) => (e) => {
        if (cbInputStore.includes(id)) {
            setCbInputStore(cbInputStore.filter((otherId) => otherId !== id))
        } else {
            setCbInputStore([id, ...cbInputStore])
        }
    }
    useEffect(() => {
        props.fieldChangeWrapped({ target: { value: cbInputStore } })
    }, [cbInputStore])

    return (
        <StyledDiv types={['socketFormCheckBoxGroup']}>
            <FormGroup>
                {props.otherData.list.map((listData) => {
                    return (
                        <FormControlLabel
                            key={'CB' + listData.checkBoxId}
                            control={
                                <Checkbox
                                    onChange={handleChange(listData.checkBoxId)}
                                />
                            }
                            label={listData.checkBoxText}
                        />
                    )
                })}
            </FormGroup>
        </StyledDiv>
    )
}

const WithBigHeader = function (props) {
    //TODO: this is where we do the thing with the thing...
    // types, add roleSelectorBody and roleSelectorInput as the types
    // then under body, onhover reveal input.
    if (props?.typographyLabel) {
        return (
            <StyledDiv types={props.typographyTypes.inputBody}>
                <StyledDiv types={props.typographyTypes.inputHeader}>
                    <TypographyCustom
                        types={['socketForm']}
                        variant={'subtitle2'}
                    >
                        {props.typographyLabel}
                    </TypographyCustom>
                </StyledDiv>
                <StyledDiv types={props.typographyTypes.innerBody}>
                    {props.children}
                </StyledDiv>
            </StyledDiv>
        )
    } else {
        return (
            <StyledDiv types={props.typographyTypes.inputBody}>
                {props.children}
            </StyledDiv>
        )
    }
}

const socketFormTypographyTypes = {
    inputHeader: ['socketFormInputHeader'],
    typography: ['socketForm'],
    innerBody: ['socketFormInputInnerBody'],
    inputBody: ['socketFormInputBody'],
}
const HSInput = function (props) {
    const disabled = !!props.disabled
    const errorStatus = props.errorStatus(props.id)(props.method)
    const errorData = props.errorData(props.id)(props.method)
    const [checkboxState, setCheckboxState] = useState(props.value)
    let typographyTypes = props.hasOwnProperty('typographyTypes')
        ? props.typographyTypes
        : socketFormTypographyTypes
    let fieldChangeWrapped = props.fieldChange(props.id)
    if (props?.customFieldChange) {
        fieldChangeWrapped = (e) => {
            props.fieldChange(props.id)({
                target: {
                    value: props.customFieldChange(
                        e.target.value,
                        props.customized
                    ),
                },
            })
        }
    }
    switch (props.type) {
        case 'time':
            return (
                <TimePick fieldChangeWrapped={fieldChangeWrapped} {...props} />
            )
        case 'calendar':
            return (
                <CalendarPicker fieldChangeWrapped={fieldChangeWrapped} {...props} />
            )
        case 'dateTimePicker':
            return (
                <DTPicker fieldChangeWrapped={fieldChangeWrapped} {...props} />
            )
        case 'date':
            return (
                <WithBigHeader
                    typographyLabel={props.typographyLabel}
                    typographyTypes={typographyTypes}
                >
                    <TextFieldCustom
                        id={props.id}
                        disabled={disabled}
                        style={{ width: 250 }}
                        onChange={fieldChangeWrapped}
                        type={'date'}
                        defaultValue="2001-01-01"
                        label={props.label}
                        error={props.error}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        types={props.types}
                    />
                </WithBigHeader>
            )
        case 'checkBoxSingle':
            return (
                <WithBigHeader
                    typographyLabel={props.typographyLabel}
                    typographyTypes={typographyTypes}
                >
                    <FormControlLabelCustom
                        key={'CSB' + props.id}
                        checked={!!checkboxState}
                        control={
                            <CheckboxCustom
                                checked={props.value}
                                onChange={(e) => {
                                    fieldChangeWrapped({
                                        target: { value: e.target.checked },
                                    })
                                    setCheckboxState(e.target.checked)
                                }}
                            />
                        }
                        label={props.label}
                    />
                </WithBigHeader>
            )
        case 'checkBoxGroup':
            return (
                <WithBigHeader
                    typographyLabel={props.typographyLabel}
                    typographyTypes={typographyTypes}
                >
                    <CheckboxGroup
                        fieldChangeWrapped={fieldChangeWrapped}
                        {...props}
                    />
                </WithBigHeader>
            )
        case 'color':
            // pass 'flexPositionObject' to colorpicker
            // { outer: cssForOuterFlexboxDiv, left: leftInnerCss,  middle: middleInnerCss, right: rightInnerCss }
            return (
                <WithBigHeader
                    typographyLabel={props.typographyLabel}
                    typographyTypes={typographyTypes}
                >
                    <ColorPicker
                        size={'small'}  
                        value={props.value}
                        flexPositionObject={props.flexPositionObject}
                        renderInput={(otherProps) => {
                            return (
                                <TextFieldCustom
                                    types={props.colorFieldTypes}
                                    size={'small'}
                                    sx={{ width: '100%' }}
                                    {...otherProps}
                                />
                            )
                        }}
                        onChange={(e) => {
                            fieldChangeWrapped({
                                target: { value: '#' + e.hex },
                            })
                        }}
                    />
                </WithBigHeader>
            )
        case 'selectGroup':
            return (
                // <WithBigHeader typographyLabel={props.typographyLabel} typographyTypes={typographyTypes}>
                <SelectGroup
                    fieldChangeWrapped={fieldChangeWrapped}
                    {...props}
                />
                // </WithBigHeader>
            )
        default:
            return (
                <div>
                    <TextFieldCustom
                        id={props.id}
                        multiline={props.type === 'multiline'}
                        disabled={disabled}
                        style={{ width: 250 }}
                        onChange={fieldChangeWrapped}
                        label={props.typographyLabel}
                        type={props.type}
                        defaultValue={props?.value ? props.value : ''}
                        variant="standard"
                        maxRows={5}
                        InputProps={(props.id === 'price')
                            ? {
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                pattern: "[0-9]*\\\.[0-9]{2}"
                            }
                            : {}}
                        error={errorStatus}
                        // fullWidth={
                        //     props.hasOwnProperty('fullWidth')
                        //         ? props.fullWidth
                        //         : true
                        // }
                        helperText={errorStatus ? errorData : null}
                        types={props.types}
                    />
                </div>
            )
    }
}

export default HSInput
