import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { initializeApp } from 'firebase/app'
import firebase from 'firebase/compat/app'
import { FB_CONFIG } from '../../../constants/env'
class Firebase {
    constructor() {
        this.app = initializeApp(FB_CONFIG)
        this.auth = getAuth(this.app)
        firebase.initializeApp(FB_CONFIG)
        console.log(this.auth)
    }
    doSignInWithEmailAndPasswordPromise = (email, password) =>
        new Promise((resolve, reject) => {
            const auth = getAuth()
            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    // Signed in
                    const user = userCredential.user
                    resolve(user)
                    // ...
                })
                .catch((error) => {
                    reject(error)
                })
        })
    doSignInWithEmailAndPassword = (email, password) => {
        const auth = getAuth()
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user
                // ...
            })
            .catch((error) => {
                const errorCode = error.code
                const errorMessage = error.message
            })
    }
}
export default Firebase
