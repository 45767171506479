import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import React from 'react'
import { Pagination } from '@mui/lab'
import { usePages } from '../../../hs/hooks'

export const IntegrableTable = (props) => {
    const [setPageNumber, totalPages, truncatedArray] = usePages(
        props.rowData,
        10
    )
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {props.rowHeaders.map((header, index) => (
                                <TableCell key={props.tableName + index + 'h'}>
                                    {header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {truncatedArray.map((rowData, index) => (
                            <TableRow
                                key={props.tableName + index}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}
                            >
                                {props.tableFormatter.map((fn, innerIndex) => (
                                    <TableCell
                                        key={
                                            props.tableName +
                                            index +
                                            '_' +
                                            innerIndex
                                        }
                                    >
                                        {fn(rowData, index)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {totalPages > 1 ? (
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Pagination count={totalPages} onChange={setPageNumber} />
                </div>
            ) : null}
        </>
    )
}
