import React from 'react'
import { ListItemCustom, ListItemIconCustom, ListItemTextCustom } from '../../../Styled'
import { withAS } from "../../../../_contexts/withAppState";
import { useTheme } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

const ProfileLink = function (props) {
    // const theme = useTheme()
    return (<ListItemCustom
        types={['profileBtn', 'communityNavProfileButton']}
        key={"profileButtonTwa"}
        button
        onClick={() => props.AS.nav.ops.changeToProfilePageWithStateClear(props.serverId)}
        listitemid={"profile11"}
        activeid={"profile22"}
        style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}
    >
        <ListItemTextCustom
            id={"profileButtonText"}
            types={['profileButtonText']}
            primary={"Community Profile"}
            style={{ margin: '0.8em 0px 0.7em' }}
        />
        <div style={{
            margin: '3px 14px 0px 0px',
        }}>
            <HomeIcon />
        </div>
    </ListItemCustom>)

}

export default withAS(ProfileLink)
