import {ThemeProvider} from '@mui/material/styles'
import {Provider} from 'react-redux'
import AdapterMoment from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import React from 'react'
import {AuthProvider} from './Components/_contexts/withAuth'
import {designAndStateProvider} from './Components/_contexts/withDesignAndState'
import {CssBaseline} from '@mui/material'
import {ASProvider} from "./Components/_contexts/withAppState";
import {BrowserRouter as Router} from "react-router-dom";
import {useHistory} from "react-router-dom";

const AppWrapper = (props) => {
    return (
        <ThemeProvider theme={props.theme}>
            <CssBaseline/>
            {props.children}
        </ThemeProvider>
    )
}
const WrappedThemeProvider = designAndStateProvider(AppWrapper)

export const ContextWrapper = (props) => {
    const history = useHistory()
    return (
        <Provider store={props.store}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <WrappedThemeProvider>
                    <Router>
                        <AuthProvider firebase={props.firebase}>
                            <ASProvider>

                                {props.children}

                            </ASProvider>
                        </AuthProvider>
                    </Router>
                </WrappedThemeProvider>
            </LocalizationProvider>
        </Provider>
    )
}

export const ContextWrapperTest = (Component) => (props) => {
    return (
        <Provider store={props.store}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <WrappedThemeProvider>
                    <ASProvider>
                        <AuthProvider firebase={props.firebase}>
                            <Component/>
                        </AuthProvider>
                    </ASProvider>
                </WrappedThemeProvider>
            </LocalizationProvider>
        </Provider>
    )
}
