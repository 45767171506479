import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { Menu, MenuItem } from "@mui/material";
import WatchIcon from "@mui/icons-material/Watch";
import ListAltIcon from "@mui/icons-material/ListAlt";
import GroupIcon from "@mui/icons-material/Group";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AvatarCustom } from "../Styled";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import NotificationsIcon from '@mui/icons-material/Notifications';

const StatusMenu = (props) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [status, setStatus] = useState(props.user.status)
    let iconColors = {
        busy: 'red',
        away: 'orange',
        offline: 'grey',
        online: 'green',
    }
    useEffect(() => {
        setStatus(props.user.status)
    }, [props.user.status])

    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = (statusArg) => () => {
        if (statusArg !== 'none') {
            props.dispatch.server.updateStatus(statusArg)
            setStatus(statusArg)
            setAnchorEl(null)
        } else {
            setAnchorEl(null)
        }
    }
    return (<><IconButton
        onClick={handleClick}
        style={{ color: iconColors[status] }}
        size="large"
    >
        <Brightness1Icon />
    </IconButton>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose('none')}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem onClick={handleClose('online')}>Online</MenuItem>
            <MenuItem onClick={handleClose('away')}>Away</MenuItem>
            <MenuItem onClick={handleClose('busy')}>Busy</MenuItem>
            <MenuItem onClick={handleClose('offline')}>Offline</MenuItem>

        </Menu></>)
}

const UniversalSidebarFooter = function (props) {
    const buttons = {
        people: [() => {
            props.setMenu('user')
        }, <ListAltIcon />],
        friends: [() => {
            props.setMenu('friend')
        }, <GroupIcon />],
        notifications: [() => {
            props.setMenu('thread')
        }, <WatchIcon />],
        servers: [() => {
            props.setMenu('servers')
        }, <HolidayVillageIcon />],
        community: [() => {
            props.setMenu('community');
            props.AS.nav.ops.setPage('chat')
            if (props.AS.activeServer === props.userData.id) {
                props.AS.nav.ops.changeActiveServer(props.AS.nav.state.mostRecentNonUserServer)
            }
        }, <SpeakerNotesIcon />],
        communitySettings: [() => {
            props.setMenu('manage')
            props.AS.nav.ops.setPage('manage')
        }, <SettingsApplicationsIcon />],
        debug: [() => {
            props.setMenu('debug')
        }, <MoreHorizIcon />],
        personalProfile: [() => {
            props.AS.nav.ops.changeToProfilePageWithStateClear(props.userData.id)
        }, <AvatarCustom
            types={['iconAvatar']}
            variant="circle"
            alt={props.userData?.userName}
            src={props.userData?.pictureId}
        />]
    }

    return (
        <div>
            {props.renderButtons.map((buttonData) => {
                if (buttonData !== "status") {
                    return (
                        <IconButton
                            color="primary"
                            onClick={buttons[buttonData][0]}
                            size="large"
                        >
                            {buttons[buttonData][1]}
                        </IconButton>)
                } else {
                    return <StatusMenu {...props} />
                }
            })}
        </div>
    )
}

export default UniversalSidebarFooter