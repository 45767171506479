import { IconButtonCustom, PaperCustom, StyledDiv, vDH } from "../../Styled";
import TimelineProfile from "../CommunityNav/TimelineProfile";
import { ButtonGroup, useTheme } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useEffect, useState } from "react";
import { withAS } from '../../../_contexts/withAppState'

const ProfileNavPre = (props) => {
    const serverData = props.chatServers[props.AS.activeServer]

    // props.AS.mobileMode
    const serverId = props.AS.activeServer
    const { palette } = useTheme()
    const [tabValue, setTabValue] = useState(0)
    const [firstTime, setFirstTime] = useState(false)
    const profileString = serverData.profile
    const isOwner = serverData.ownerId === props.userId
    const serverName = serverData.name
    let userData = {}
    const borderColor = "1px solid rgba(64 64 64 / 60%)"
    let isMember = false
    let isDM = serverData.serverMeta.dm
    if (isDM) {
        userData = props.allUsers[serverId]
    }
    let numOfUsers = serverData.users.length
    if (!props.AS.nav.state.isGuest) {
        isMember = serverData.users.map((user) => user.id).includes(props.userId)
    }
    return (
        <StyledDiv types={['profileNav']} style={{
            flex: '1 1 auto',
            height: 'auto',
        }}>
            <div style={{
                height:
                    ((window.screen.width / window.screen.height) < (4 / 3))
                        ? `calc(${vDH(85)} - 6.4rem)`
                        : vDH(85),
            }}>
                <TimelineProfile serverName={serverName} otherUser={"err"}
                    numOfUsers={numOfUsers}
                    userData={userData}
                    profileString={profileString}
                    server={serverId}
                    isMember={isMember}
                    serverData={serverData}
                    isDM={isDM}
                    isGuest={props.AS.nav.state.isGuest}
                    isOwner={isOwner}
                    openModalWithData={props.AS.modals.ops.openModalWithData}
                    setRedirect={props.setRedirect}
                    desktop={props?.desktop} />

                {/* <StyledDiv types={['profileButtonNavIconContainer']}
                    style={{ justifyContent: 'flex-start', paddingLeft: '50px' }}>
                    <StyledDiv types={['profileButtonNavIcons']}>
                        <ButtonGroup variant="text"
                            aria-label="text button group">
                            {!props.AS.nav.state.isGuest ?
                                <IconButtonCustom
                                    types={['profileChatButton']}
                                    color="error"
                                    onClick={() => {
                                        props.AS.nav.ops.changeOffProfilePage()
                                    }}
                                    size="large"
                                    sx={{}}
                                >
                                    <ArrowBackIcon />
                                </IconButtonCustom> : null}
                        </ButtonGroup>
                    </StyledDiv>
                </StyledDiv> */}
            </div>

        </StyledDiv>
    )
    //            </PaperCustom>
}

export const ProfileNav = withAS(ProfileNavPre)