import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {statePropMapper} from '../../../redux/process/selectorProcess'
import {connect} from 'react-redux'
import {dispatchMappers} from '../../../redux/process/actionProcess'
import {RA1, RS1} from '../../../redux/actions'
import Backdrop from '@mui/material/Backdrop'
import {globalTheme} from '../../../theme/_globalTheme'
import React, {useEffect} from 'react'
import ServerModal from './ServerModal'
import ChannelModal from './ChannelModal'
import CropModal from './CropModal'
import {CircularProgress, Fade, Modal} from '@mui/material'
import CreateAppointmentModal from './CreateAppointmentModal'
import CancelAppointmentModal from './CancelAppointmentModal'
import CancelSubscriptionModal from './CancelSubscriptionModal'
import ModifyAppointmentModal from './ModifyAppointmentModal'
import BookAppointmentModal from './BookAppointmentModal'
import ChangeModal from './ChangeModal'
import ErrorModal from './ErrorModal'
import AddRoleModal from './AddRoleModal'
import ThreadImageViewer from './ThreadImageViewer'
import ThreadReplyViewer from './ThreadReplyViewer'
import ApproveAppointmentModal from './ApproveAppointmentModal'
import PaymentModal from './PaymentModal'
import CreateProductModal from './CreateProductModal'
import {BoxCustom, BackdropCustom} from '../Styled'
import UpdateServerNameModal from './UpdateServerNameModal'
import ModifyProfileModal from "./ModifyProfileModal";
import WelcomeInfoModal from "./Info/Welcome";
import RedirectAuthModal from "./RedirectAuthModal";
import SetupPaymentsModal from "./Info/SetupPayments";
import VideoSettingsModal from "./VideoSettingsModal";
import {withAS} from "../../_contexts/withAppState";
import useState from "react-usestateref";
import BackoutOfAppointmentModal from "./BackoutOfAppointmentModal";
import AlphaCreateInviteModal from "./AlphaCreateInviteModal";
import BugReportModal from "./BugReport";
import GPTAppointmentModal from './GPTAppointmentModal'
import ConfirmGPTModal from "./ConfirmGPTModal";

const mapDispatchToProps = dispatchMappers([RA1._appState.closeModalMenu])
const makeMapStateToProps = statePropMapper([
    RS1.store('_appState', '_appState'),
    RS1.store('users', 'users'),
    RS1.store('_userId', '_userId'),
])
const modalMenus = (props) => {

    return {
        welcome: <WelcomeInfoModal {...props} />,
        setupPayments: <SetupPaymentsModal {...props} />,
        redirectAuthModal: <RedirectAuthModal {...props} />,
        addRole: <AddRoleModal {...props} />,
        error: <ErrorModal {...props} />,
        server: <ServerModal {...props} />,
        bugReport: <BugReportModal {...props} />,
        gptConfirm: <ConfirmGPTModal {...props} />,
        updateServerName: <UpdateServerNameModal {...props} />,
        channel: <ChannelModal userId={props._userId} {...props} />,
        alphaCreateInvite: <AlphaCreateInviteModal userId={props._userId} {...props} />,
        modifyProfile: <ModifyProfileModal {...props} />,
        crop: <CropModal {...props} />,
        change: <ChangeModal {...props} />,
        createAppointment: <CreateAppointmentModal {...props} />,
        createProduct: <CreateProductModal {...props} />,
        paymentModal: <PaymentModal {...props} />,
        cancelSubscription: <CancelSubscriptionModal {...props} />,
        cancelAppointment: <CancelAppointmentModal {...props} />,
        backoutOfAppointment: <BackoutOfAppointmentModal {...props} />,
        modifyAppointment: <ModifyAppointmentModal {...props} />,
        threadimgviewer: <ThreadImageViewer {...props} />,
        threadreplyviewer: <ThreadReplyViewer {...props} />,
        bookAppointment: <BookAppointmentModal {...props}/>,
        approveAppointment: <ApproveAppointmentModal {...props}/>,
        videoSettingsModal: <VideoSettingsModal {...props} />,
        gptAppointment: <GPTAppointmentModal {...props}/>
    }
}

const ModalMenuV2 = (props) => {
    const [progress, setProgress] = useState(false)
    useEffect(() => {
        const timer = setTimeout(() => {
            if (progress) {
                setProgress(false)
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [progress])

    const closeModalMenu = props.AS.modals.ops.closeModal
    const modalProps = {
        _activeChannel: props.AS.activeChannel,
        _activeServer: props.AS.activeServer,
        server: props.AS.activeServer,
        serverId: props.AS.activeServer,
        closeModalMenu,
        setProgress, ...props
    }
    const isGuest = props.users[props._userId].userName === "guest123456789"
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={!!props.AS.modals.state.modalIsOpen}
            onClose={closeModalMenu}
            closeAfterTransition
            BackdropComponent={BackdropCustom}
            BackdropProps={{timeout: 500}}>
            <Fade in={!!props.AS.modals.state.modalIsOpen}>
                <div>
                    <div style={progress ? {
                        display: "none",
                    } : null}>
                        {props.AS.modals.state.modalType !== "info" ?
                            <><BoxCustom style={{
                                width: 'auto',
                            }} types={['modalMenu']}>
                                {!isGuest ? modalMenus(modalProps)[props.AS.modals.state.modal]
                                    : modalMenus(modalProps)["redirectAuthModal"]
                                }
                            </BoxCustom></>
                            :
                            <div>
                                {modalMenus(modalProps)[props.AS.modals.state.modal]}
                            </div>
                        }
                    </div>
                    <div style={!progress ? {display: "none"} : null}>
                        <BoxCustom style={{width: 'auto', backgroundColor: "rgba(255, 255, 255, 0.1)"}}
                                   types={['modalMenu']}>
                            <CircularProgress/>
                        </BoxCustom>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(ModalMenuV2))
