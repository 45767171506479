import {DEPLOYMENT_STAGE, DEVELOPMENT_SERVER_ID, FRONTEND_URL} from "../../constants/env";


let signInSuccessUrl = "/auth2"

let registerSuccessUrl = "/chat2"

if (["alpha", "beta"].includes(DEPLOYMENT_STAGE)) {
    signInSuccessUrl = "/auth2"
}

if (["alpha", "beta"].includes(DEPLOYMENT_STAGE)) {
    registerSuccessUrl = "chat2/?s=" + DEVELOPMENT_SERVER_ID+"&ft=true"
}

export {signInSuccessUrl,registerSuccessUrl}