import { useEffect } from 'react'
import { RS1 } from "../../../../redux/actions"
import { statePropMapper } from "../../../../redux/process/selectorProcess"
import { connect } from 'react-redux'

const makeMapStateToProps = statePropMapper([
    RS1.getUserMeta('userMeta'),
    RS1.getUser('userData'),
    RS1.getServerAbstractRoles('serverAbstractRoles'),
])

const ThreadRetriever = (props) => {

    useEffect(() => {
        props.setTmpUser({ ...props.userData })
        props.setTmpUserMeta({ ...props.userMeta })
        props.setTmpServerAbstractRoles({ ...props.serverAbstractRoles })

        props.setUserColor(props.serverAbstractRoles.find(
            (role) => role.id === props.userMeta.roles[0]
        ).perms.color)

    }, [])

    return null
}

export default connect(makeMapStateToProps, null)(ThreadRetriever)