import React, { useContext, useEffect, useState, useRef } from 'react'
import { RA1, RS1 } from '../../../../redux/actions.js'
import { connect } from 'react-redux'
import ChannelLink from './ChannelLink/index.js'
import {
    statePropMapper,
    statePropMappers,
} from '../../../../redux/process/selectorProcess.js'
import AddIcon from '@mui/icons-material/Add'
import { dispatchMappers } from '../../../../redux/process/actionProcess.js'
import withMaterialDnD from '../../../_wrappers/withMaterialDnD/index.js'
import withMenu from '../../../_wrappers/withMenu/index.js'
import ChannelLinkMenu from './ChannelLink/ChannelLinkMenu/index.js'
import {
    ListItemCustom, ListItemIconCustom,
    ListItemTextCustom,
    PaperCustom,
    StyledDiv,
    TypographyCustom,
    vDH,
} from '../../Styled'
import HomeIcon from '@mui/icons-material/Home'
import { withDesignAndState } from '../../../_contexts/withDesignAndState'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import BackgroundFlair from '../../ChatWindow/BackgroundFlair'
import { BASE_URL, FRONTEND_URL, FULL_FRONTEND_URL, SITE_URL } from '../../../../constants/env'
import { withAS } from "../../../_contexts/withAppState";
import { useAdaptiveDisplay } from "../../../../hs/adaptive";
import ProfileLink from "./ChannelLink/profileLink";
import List from "@mui/material/List";

const mapDispatchToProps = dispatchMappers([
    RA1._appState.closeModalMenu,
    RA1._appState.changePage,
    RA1._appState.changeToDM,
    RA1._appState.openModalMenu,
    SA1.updateChannelOrder,
])
const makeMapStateToProps = statePropMapper([
    RS1.getVisibleChannels('channels'),
    RS1.store('_appState', '_appState'),
    RS1.getServer('serverData'),
    RS1.getOtherUser('otherUser'),
    RS1.store('_userId', '_userId'),
    RS1.getUserBooleanPerm('isAdmin', ['isAdmin']),
    RS1.getUserBooleanPerm('isOwner', ['isOwner'])
])

const DnDChannelList = withMenu(withMaterialDnD(ChannelLink), ChannelLinkMenu)

const SpecialServerButton = function (props) {
    return (
        <ListItemCustom
            types={['specialServerButton']}
            key={props.id}
            onClick={props.clickAction}
        >
            <HomeIcon />
            <ListItemTextCustom
                types={['specialServerButton']}
                id={props.id}
                primary={props.label}
            />
        </ListItemCustom>
    )
}

const AddNewChannelButton = (props) => {
    return (<ListItemCustom
        key={"addNewChannelButton"}
        types={['channelActive']}
        button
        activeid={"123"}
        listitemid={"456"}
        onClick={() => props.AS.modals.ops.openModal("channel")}
        style={{
            margin: '15px 0px'
        }}>
        <ListItemTextCustom
            id={"ANC123"}
            primary={"Add New Channel"}
        />
        <ListItemIconCustom
            types={['channel']}>
            <AddIcon />
            {/*{props.channelMeta.channelMeta.premium ?*/}
            {/*    <WorkspacePremiumIcon size={"small"}*/}
            {/*                          style={{*/}
            {/*                              color:*/}
            {/*                                  (props.channel === props.AS.activeChannel) ?*/}
            {/*                                      theme.palette.text.primary :*/}
            {/*                                      theme.palette.text.secondary*/}
            {/*                          }} /> :*/}
            {/*    null}*/}
            {/*<LayersIcon*/}
            {/*    onMouseEnter={() => setThreadIconHovered(true)}*/}
            {/*    onMouseLeave={() => setThreadIconHovered(false)}*/}
            {/*    size={"small"} />*/}
        </ListItemIconCustom>
    </ListItemCustom>)
}
const CommunityNav = function (props) {
    const displaySettings = useAdaptiveDisplay()
    let channelList = []
    let [channels, setChannels] = useState(props.channels)
    let isHome = props._userId === props.server
    let normalChannelIds = isHome
        ? [props.serverData.generalId]
        : [
            props.serverData.notificationsId,
            props.serverData.profileId,
            props.serverData.logId,
            props.serverData?.hiddenId,
            props.serverData.configId,
        ]
    const [refreshId, setRefreshId] = useState(0)
    const sidebarRef = useRef(null)
    const [sidebarWidth, setSidebarWidth] = useState(null)
    channels = channels.filter((e) => !normalChannelIds.includes(e))
    //channelType: isHome ? "dm" : "channel",
    channelList = channels.map((id, index) => ({
        key: id,
        id: id,
        channel: id,
        channelType: 'channel',
        index: index,
        activeChannel: props.channel,
        setMenu: props.setMenu,
        setSelectedChannel: props.setSelectedChannel,
        activeServer: props.server,
    }))

    function handleOnDragEnd(resultData) {
        let localChannels = [...channels]
        let source = localChannels[resultData.source.index]
        let target = localChannels[resultData.destination.index]
        if (resultData.source.index !== resultData.destination.index) {
            props.dispatch.server.updateChannelOrder(
                props.server,
                source,
                target
            )
            let shift =
                resultData.source.index < resultData.destination.index ? 1 : 0
            localChannels.splice(
                resultData.destination.index + shift,
                0,
                source
            )
            localChannels = localChannels.filter((channelId, index) => {
                return !(
                    index !== resultData.destination.index + shift &&
                    channelId === source
                )
            })
            setChannels(localChannels)
        }
    }

    // #
    useEffect(() => {
        if (refreshId < 3) {
            setRefreshId((refreshId) => refreshId + 1)
            setSidebarWidth(sidebarRef.current.offsetWidth)
        }
    }, [refreshId])

    useEffect(() => {
        setChannels(props.channels)
        setRefreshId(0)
    }, [props.channels])
    const changePageToThreads = () => props.AS.nav.ops.setPage('threads')
    let serverName = props?.otherUser?.userName ? props.otherUser.userName : props.serverData.name

    // // TODO: TEST CODE REMOVE
    // console.warn("SIDEBAR SIDEBAR")
    // console.log(props._appState?.openMenu)
    // // # test code end

    return [
        <div key={"C1325232632"} style={{ width: '100%' }} ref={sidebarRef}></div>
        ,
        <StyledDiv
            key={"C1325232"}
            types={['sidebarTopInternalHeader', 'flairNavSidebarHeader']}
            className={'sidebarTopInternalHeader'}
            style={{
                background: '#00000000'
            }}
        >
            <BackgroundFlair
                sidebarWidth={sidebarWidth}
                server={props.server}
                setMenu={props.setMenu}
                changePage={props.changePage}
                desktop={props?.desktop}
            />
            <TypographyCustom
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    maxWidth: '100%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    paddingBottom: '0.5em',
                    zIndex: 1004,
                }}
                types={['communityHeader']}
                variant="h5">
                {props.otherUser?.userName
                    ? props.serverData.name
                    : props.serverData.name}
            </TypographyCustom>
        </StyledDiv>,
        <StyledDiv
            key={"C132"}
            types={['sidebarTopInternalList']}
            className={'sidebarTopInternalList'}>
            <div style={{ paddingTop: '6px' }} />
            <List style={{ width: "100%", paddingBottom: 0 }}><ProfileLink key={"profileLink"}
                serverId={props.serverData.id} />
            </List>
            <StyledDiv types={['sidebarTopInternalScroller']}>
                <DnDChannelList
                    list={channelList}
                    listStyle={{height: vDH(51)}}
                    dynamicProps={{selectItem: () => true}}
                    droppableId={'channelList'}
                    menuId={'channelListMenu'}
                    mockPadding={<div style={{ height: '1em' }}></div>}
                    useDnD={!props.AS.mobileMode}
                    dragCondition={(listProps, index) => false}
                    handleOnDragEnd={(result) => handleOnDragEnd(result)}
                    bottomComponent={props.isOwner || props.isAdmin ? <AddNewChannelButton AS={props.AS} /> : null}
                />
            </StyledDiv>
        </StyledDiv>,
    ]
}

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(withDesignAndState(withAS(CommunityNav)))
