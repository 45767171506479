import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import './lastrow.css'

const useStyles = makeStyles({
    footerbutton: {
        clipPath: 'polygon(100% 0%, 12% 0%, 0% 100%, 88% 100%)',

        height: '5em',
        lineHeight: '0',
        marginRight: '0.6em',

        borderRadius: 0,
        padding: '2.8em',
        color: '#fff',
        textTransform: 'none',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#fdc',
        },
        ['@media (pointer: none), (pointer: coarse)']: {
            fontSize: '1em',
            height: '1em',
        },
    },
})

const LastRow = function (props) {
    const classes = useStyles()
    const history = useHistory()

    return (
        <div className="row last">
            <div className="column first text-box">
                <div>
                    <Button
                        className={classes.footerbutton}
                        onClick={() => {
                            history.push('/chat2')
                        }}
                    >
                        Chat Page
                    </Button>
                </div>
                <div>
                    <Button
                        className={classes.footerbutton}
                        onClick={() => {
                            history.push('/auth2')
                        }}
                    >
                        Auth Page
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default LastRow
