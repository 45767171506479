import React, { useEffect } from 'react'
import Popover from '@mui/material/Popover'
import { ImageList, ImageListItem } from '@mui/material'
import withChatMessaging from '../../../_wrappers/withChatMessaging/index.js'
import { on, off } from '../../../../events.js'
import { StyledDiv, IconButtonCustom, TextFieldCustom, vDH } from '../../Styled'
import { statePropMapper } from '../../../../redux/process/selectorProcess'
import { RA1, RS1 } from '../../../../redux/actions'
import { dispatchMappers } from '../../../../redux/process/actionProcess'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import { connect } from 'react-redux'
import { useSendFile } from '../../../../hs/hooks'
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ProductButton from './ProductButton/index.js'
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SendIcon from '@mui/icons-material/Send';
import IconButton from "@mui/material/IconButton";
import ClearIcon from '@mui/icons-material/Clear';
// import EditNoteIcon from '@mui/icons-material/EditNote';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { withAS } from '../../../_contexts/withAppState/index.js'
import { CreateFan } from './CreateFan/index.js'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'
import ImageIcon from '@mui/icons-material/Image';


//TODO: TEMPORARY FIX
import { useTheme } from '@emotion/react'
import { hexToFormattedRgb } from '../../../../theme/util.js'

const specialLog = (props) => {
    console.log('%c ' + props, 'background: #222; color: #ff6767')
}

const makeMapStateToProps = statePropMapper([
    RS1.store('imageStore', 'imageStore'),
    RS1.store('_userId', '_userId'),
])
const mapDispatchToProps = dispatchMappers([
    RA1.client.error,
    RA1.imageStore.clear,
    RA1.imageStore.addImage,
])

const { useState, useRef } = require('react')

const UseFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {
        htmlElRef.current && htmlElRef.current.focus()
    }

    return [htmlElRef, setFocus]
}


const MockMobileNav = (props) => {
    return (
        <div ref={props.navBarRef}
            style={{
                position: 'absolute',
                bottom: '-3.2em',
                width: '100%',
                left: 0,
            }}>
            <StyledDiv
                types={['mobileNavOuter']}
                style={{
                    height: '4rem',
                    zIndex: props?.isActive ? 4001 : 0,

                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    padding: '5px 0px',
                }}>

                <StyledDiv style={{
                    flex: 'fit-content',
                    flexGrow: 0,
                    flexShrink: 1,
                    display: 'flex',
                    padding: '10px',
                }}>
                    <IconButtonCustom
                        types={['mobileNavButton', 'mobileNavAlign']}
                        onClick={props.handleImgClick}
                        style={{
                            padding: '0 8px',
                            maxWidth: '50px',
                        }}>
                        <ImageIcon />
                    </IconButtonCustom>

                    <IconButtonCustom
                        types={['mobileNavButton', 'mobileNavAlign']}
                        onClick={() => {
                        }}
                        style={{
                            padding: '0 8px',
                            maxWidth: '50px',
                        }}>
                        <SentimentSatisfiedAltIcon />
                    </IconButtonCustom>
                </StyledDiv>

                <StyledDiv style={{
                    flex: 'auto',
                }}>
                    {props.writeToThread && !props.singleThread
                        ? <div style={{ padding: '4px', paddingLeft: '0px' }}>
                            <TextFieldCustom
                                types={['inputContainerTextField']}
                                value={props.threadTitle}
                                label=""
                                placeholder={'Thread Title'}
                                size={'small'}
                                onChange={props.handleThreadTitleChange}
                            />
                        </div>
                        : null}
                </StyledDiv>

                <StyledDiv style={{
                    flex: 'fit-content',
                    flexGrow: 0,
                    flexShrink: 1,
                    display: 'flex',
                }}>
                    <IconButtonCustom
                        types={['mobileNavButton', 'mobileNavAlign']}
                        color="error"
                        onClick={() => {
                            props.handleSubmit()
                        }}>
                        <SendIcon />
                    </IconButtonCustom>
                </StyledDiv>
            </StyledDiv>
        </div>
    )
}

const ImagePreview = function (props) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [items, setItems] = useState([])
    const anchorRef = useRef()

    const open = Boolean(anchorEl)

    const handlePopoverOpen = () => {
        setAnchorEl(anchorRef.current)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    useEffect(() => {
        setAnchorEl(anchorRef.current)
    }, [anchorRef])

    useEffect(() => {
        let newList = []
        for (const i of props.images) {
            newList.push(
                <ImageListItem key={i.url}>
                    <img src={i.url} />
                </ImageListItem>
            )
        }
        setItems(newList)
    }, [props.images])

    return (
        <div />
    )
}

const ReplyName = function (props) {
    return <b style={{ color: '#8A8A8A' }}>{props.userName}</b>
}

const ChatInputContainer = function (props) {

    const fileInputRef = useRef()

    const [navBarActive, setNavBarActive] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [threadTitle, setThreadTitle] = useState('')
    const [isActive, setIsActive] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [editIds, setEditIds] = useState({ mId: '', sId: '' })
    const [productData, setProductData] = useState({})
    const [currentlyUploading, setCurrentlyUploading] = useState(false);
    const [items, setItems] = useState(null);
    const [fanMenu, setFanMenu] = useState(false)
    const [reply, setReply] = useState({
        isReply: false,
        hasReplies: [],
        message: '',
        user: '',
        edited: false,
        deleted: false,
        attachment: false,
        mData: {
            uId: '',
            mId: '',
            cId: '',
            sId: '',
        },
    })

    //TODO: TEMP TESTING CODE REMOVE LATER
    const theme = useTheme()

    const { sendFiles, storeFile, clearFiles, hasFiles, blobLinks } = props
    const [textBoxRef, setTextBoxFocus] = UseFocus()

    const handleThreadTitleChange = (e) => {
        setThreadTitle(e.target.value)
    }

    async function handleSubmit() {
        // TODO: preview of product as attachment
        // TODO: create product, get product id, use product id when submitting message

        if (!isEditing && !currentlyUploading) {
            let fileToken = ''
            if (hasFiles()) {
                setCurrentlyUploading(true);
                fileToken = await sendFiles()
                setCurrentlyUploading(false);
            }

            if (Object.keys(productData).length) {
                props.submitPaidMessageWrapped(
                    inputValue,
                    reply,
                    fileToken,
                    productData
                )
            } else {
                if (props.threadView) {
                    if (props.singleThread) {
                        props.submitMessageWrapped(
                            inputValue,
                            reply,
                            fileToken,
                            false,
                            props.threadId,
                            ''
                        )
                    } else {
                        props.submitMessageWrapped(
                            inputValue,
                            reply,
                            fileToken,
                            true,
                            '',
                            threadTitle
                        )
                        props.triggerThreadModeReload()
                    }
                } else {
                    props.submitMessageWrapped(inputValue, reply, fileToken)
                }
            }
        } else {
            props.editMessageWrapped(editIds.sId, editIds.mId, inputValue)
            setIsEditing(false)
            setEditIds({ mId: '', sId: '' })
        }
        if (reply.isReply) {
            stopReply()
        }
        setThreadTitle('')
        setInputValue('')
        closeFile()
    }

    function onEdit(data) {
        props.handleChangeWrapped(data.detail.message, setInputValue)
        setIsEditing(true)
        setEditIds({ mId: data.detail.mId, sId: data.detail.sId })
        stopReply()
        setTextBoxFocus()
    }

    function stopEdit() {
        setIsEditing(false)
        setEditIds({ mId: '', sId: '' })
    }

    function onQuote(data) {
        props.handleChangeWrapped(data.detail.message, setInputValue)
        setTextBoxFocus()
    }

    function onReply(data) {
        setReply(data.detail)
        stopEdit()
        setTextBoxFocus()
    }

    const handleImgClick = event => {
        fileInputRef.current.click();
    }

    function onFile(data) {
        if (fanMenu) {
            setFanMenu(!fanMenu)
        }
        if (data.target.files && data.target.files[0]) {
            storeFile(data.target.files[0], data.target.files[0].name)
        }
        setTextBoxFocus()
        data.target.value = null
    }

    function closeFile() {
        clearFiles()
    }

    function stopReply() {
        setReply({
            isReply: false,
            hasReplies: [],
            message: '',
            user: '',
            edited: false,
            deleted: false,
            attachment: false,
            mData: {
                uId: '',
                mId: '',
                cId: '',
                sId: '',
            },
        })
    }

    function imageContainer() {

    }

    useEffect(() => {
        off('setChatInput:edit', (data) => onEdit(data))
        off('setChatInput:quote', (data) => onQuote(data))
        off('setChatInput:reply', (data) => onReply(data))
        on('setChatInput:edit', (data) => onEdit(data))
        on('setChatInput:quote', (data) => onQuote(data))
        on('setChatInput:reply', (data) => onReply(data))

        return () => {
            props.AS.nav.ops.setHideNavBar(false)
        }
    }, [])

    useEffect(() => {
        specialLog('(Rendering...)')

        setIsActive(document.activeElement === textBoxRef.current || navBarActive)
        props.AS.nav.ops.setHideNavBar(document.activeElement === textBoxRef.current || navBarActive)
        if (document.activeElement === textBoxRef.current && props.AS.mobileMode) {
            setNavBarActive(true)
        }

    }, [document.activeElement])

    useEffect(() => {
        if (!currentlyUploading) {
            // setTextBoxFocus();
        }
    }, [currentlyUploading])

    useEffect(() => {
        let newList = []
        for (const i of blobLinks) {
            newList.push(
                <StyledDiv types={['outerInputContainerImageButton']}>
                    {/* <div class="imageBarContainerDiv"> */}
                    <img src={i.url} className="fixImageOpacity" />
                    <label style={{
                        position: "absolute",
                        top: "30px",
                        maxWidth: "225px",
                        overflow: "hidden"
                    }}>{i.filename}</label>
                    {/* </div> */}
                </StyledDiv>
            )
        }
        setItems(newList)
    }, [blobLinks])

    return (
        <StyledDiv id='textContainer' types={[
            'inputContainerDiv',
            (props.threadView &&
                props.singleThread &&
                props.writeToThread &&
                ((window.screen.width / window.screen.height) < (4 / 3)))
                ? 'blogSingleThreadMobile'
                : null,
        ]}
            style={props.threadView ? props.singleThread
                ? {
                    zIndex: ((window.screen.width / window.screen.height) < (4 / 3)) ? '1005' : 'auto',
                    backgroundImage:
                        'linear-gradient(to bottom,rgba(' +
                        hexToFormattedRgb(theme.palette.background.primary) +
                        ', 0) 5%, rgba(' +
                        hexToFormattedRgb(theme.palette.background.primary) +
                        ', 1) 75%)',
                }
                : {
                    paddingBottom: '10px',

                    //TODO: TEMPORARY FIX
                    backgroundImage:
                        'linear-gradient(to bottom,rgba(' +
                        hexToFormattedRgb(theme.palette.background.primary) +
                        ', 0) 0%, rgba(' +
                        hexToFormattedRgb(theme.palette.background.primary) +
                        ', 1) 40%)',
                    minHeight: ((window.screen.width / window.screen.height) < (4 / 3))
                        ? props.writeToThread
                            ? '10dvh'
                            : vDH(7)
                        : vDH(6)
                }
                : {}}>

            {reply.isReply ?
                <StyledDiv style={{
                    position: 'sticky',
                    top: 0,
                    marginTop: (blobLinks?.length > 0 && blobLinks.length < 4) ?
                        '-17.5em' :
                        '-1.5em',
                    zIndex: 99999,
                    paddingLeft: (blobLinks?.length > 0 && blobLinks.length < 4) ?
                        '1em' :
                        0,
                }}>
                    <span style={{ color: '#5A5A5A' }}>Replying to: </span>
                    <ReplyName userName={reply.user} />
                    <IconButton
                        size="small"
                        onClick={() => stopReply()}
                        style={{ transform: 'scale(0.8)' }}>
                        <ClearIcon />
                    </IconButton>
                </StyledDiv>
                : null
            }

            <StyledDiv types={['imageUploadContainer']} haslinks={"" + (blobLinks?.length > 0)}>
                {blobLinks?.length > 0 ?
                    items : null
                }
                {blobLinks?.length > 0 && blobLinks.length < 4 ?
                    <StyledDiv types={["outerInputContainerImageButton"]}>
                        <label className="cursor-handle">
                            <AddCircleOutlineIcon style={{ fontSize: "200%" }} />
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={(e) => onFile(e)}
                                accept="image/*"
                            />
                        </label>
                    </StyledDiv> : null}
            </StyledDiv>

            {/* <StyledDiv blah="rabbit">
                <IconButtonCustom onClick={() => {
                    props.AS.nav.ops.goBackHistory()
                }}>
                    <ArrowBackIcon />
                </IconButtonCustom>
            </StyledDiv> */}


            <StyledDiv types={['inputContainerOutside']}
                id='textContainer'
                className='highlight position-setter'
                style={{
                    maxHeight: props.writeToThread &&
                        !props.singleThread &&
                        ((window.screen.width / window.screen.height) < (4 / 3)) ? '105px' : '65px',
                    bottom: props.writeToThread &&
                        !props.singleThread &&
                        ((window.screen.width / window.screen.height) < (4 / 3)) ? 'calc(100% - 7.7dvh)' : 'calc(100% - 4.7dvh)'
                    // 'calc(100% - 4.7dvh)' if mobile & props.writeToThread & !props.singlethread & 
                }}>
                <StyledDiv types={['flankInputDiv']}
                    style={{
                        width:
                            ((window.screen.width / window.screen.height) < (4 / 3)) && isActive
                                ? '0px'
                                : props?.showEdit
                                    ? '100px'
                                    : '50px',
                    }}
                    showEdit={(props.threadView || props.singeThread)
                        && props.writeToThread}>
                    {/*TODO: PORT TO BOTTOM NAV BAR */}

                    {!props.AS.mobileMode && props.threadView && props.showBlogIcon ?
                        <IconButtonCustom types={['markdownButton']} onClick={() => {
                            props.setBlogView(view => !view)
                        }} style={{ marginLeft: '0px' }}>
                            <EditIcon color="error" style={{ fontSize: "30px" }} />
                        </IconButtonCustom> : null}

                    {/* {!isEditing && !currentlyUploading ? (
                        <label className="cursor-handle">
                            <AddCircleOutlineIcon color="error" style={{ fontSize: "30px" }} />
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={(e) => onFile(e)}
                                accept="image/*"
                            />
                        </label>
                    ) : null} */}
                    {!isActive || !props.AS.mobileMode
                        ? <IconButtonCustom onClick={() => {
                            props.AS.nav.ops.goBackHistory()
                        }}>
                            <ArrowBackIcon color="error" />
                        </IconButtonCustom>
                        : null}

                    {currentlyUploading ? (
                        <CircularProgress style={{ width: "25px", height: "25px" }} />
                    ) : null}
                </StyledDiv>

                <StyledDiv types={['middleInputDiv']}
                    style={{
                        flexDirection: 'row',
                        marginLeft: props.writeToThread
                            && !props.singleThread
                            && !props.AS.mobileMode
                            ? '1.3em'
                            : 0,
                    }}>
                    {props.writeToThread && !props.singleThread && !props.AS.mobileMode
                        // && !((window.screen.width / window.screen.height) < (4 / 3))
                        ? (
                            <div style={{ padding: '4px', paddingLeft: '0px' }}>
                                <TextFieldCustom
                                    types={['inputContainerTextField']}
                                    value={threadTitle}
                                    label=""
                                    placeholder={'Thread Title'}
                                    size={'small'}
                                    onChange={handleThreadTitleChange}
                                />
                            </div>
                        ) : null}
                    <TextFieldCustom
                        types={['inputContainerTextField']}
                        id="outlined-multiline-static"
                        variant="outlined"
                        multiline
                        label=""
                        placeholder={props.threadView && !props.singeThread ? 'Thread Message' : ''}
                        maxRows={2}
                        inputRef={textBoxRef}
                        onClick={() => { setTextBoxFocus() }}
                        size={'small'}
                        fullWidth={true}
                        value={inputValue}
                        onChange={(e) => {
                            if (!currentlyUploading) {
                                props.handleChangeWrapped(e.target.value, setInputValue)
                            }
                        }
                        }
                        onKeyDown={(e) => {
                            if (e.keyCode === 13 && !e.shiftKey) {
                                if (!currentlyUploading) {
                                    e.preventDefault()
                                    handleSubmit()
                                }
                            }
                            if (e.keyCode === 27) {
                                stopEdit()
                                stopReply()
                                closeFile()
                            }
                        }}
                        disabled={currentlyUploading}
                    />
                </StyledDiv>

                {/* <IconButtonCustom types={['mobileNavButton']}
                    style={{ transform: 'scale(1.4)' }}
                    onClick={() => { setFanMenu(!fanMenu) }}>
                    {fanMenu
                        ? <ArrowCircleDownIcon style={{ color: 'inherit' }} />
                        : <AddCircleIcon style={{ color: 'inherit' }} />}
                </IconButtonCustom> */}

                <StyledDiv types={['flankInputDiv']}
                    style={{
                        width:
                            ((window.screen.width / window.screen.height) < (4 / 3)) && isActive
                                ? '0px'
                                : props?.showEdit
                                    ? '100px'
                                    : '50px',
                    }}>

                    {!isActive || !props.AS.mobileMode
                        ? <IconButtonCustom types={['mobileNavButton']}
                            style={{ transform: 'scale(1.4)', zIndex: 2001 }}
                            onClick={(e) => {
                                if (props.AS.mobileMode) {
                                    setFanMenu(!fanMenu)
                                } else {
                                    handleImgClick(e)
                                }
                            }}>
                            {fanMenu
                                ? <ArrowCircleDownIcon style={{ color: 'inherit' }} />
                                : <AddCircleIcon style={{ color: 'inherit' }} />}
                        </IconButtonCustom>
                        : null}
                    {props.mobileMode && !navBarActive
                        ? null
                        : <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={(e) => onFile(e)}
                            accept="image/*" />}
                    {/* {!isActive ? (
                        <label className="cursor-handle">
                            <AddCircleOutlineIcon color="error" style={{ fontSize: "30px" }} />
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={(e) => onFile(e)}
                                accept="image/*"
                            />
                        </label>
                    ) : null} */}
                </StyledDiv>
            </StyledDiv>
            {props.AS.mobileMode
                ? <CreateFan fanMenu={fanMenu}
                    onFile={onFile}
                    fileInputRef={fileInputRef}
                    handleImgClick={handleImgClick}
                    setFanMenu={setFanMenu} />
                : null}

            {isActive && props.AS.mobileMode
                ? <MockMobileNav
                    setIsActive={setIsActive}
                    textBoxRef={textBoxRef}
                    setTextBoxFocus={setTextBoxFocus}
                    threadTitle={threadTitle}
                    handleThreadTitleChange={handleThreadTitleChange}
                    handleSubmit={handleSubmit}
                    mobileMode={props.AS.mobileMode}
                    navBarActive={navBarActive}
                    setNavBarActive={setNavBarActive}
                    handleImgClick={handleImgClick}
                    {...props} />
                : null}

            {isActive && props.AS.mobileMode
                ? <div style={{
                    position: 'absolute',
                    width: '100vw',
                    height: '90dvh',
                    top: '-90dvh',
                    left: 0,
                    zIndex: 1,
                }} onClick={() => {
                    setNavBarActive(false)
                    setIsActive(false)
                    props.AS.nav.ops.setHideNavBar(false)
                }}>
                </div>
                : null}
        </StyledDiv >
    )
}

export default withChatMessaging(
    connect(makeMapStateToProps, mapDispatchToProps)(withAS(ChatInputContainer))
)
