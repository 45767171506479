import {monoActions} from './reducers/monostate/mono.js'
import {abstractRoleActions} from './reducers/abstractRoles.js'
import {appStateActions} from './reducers/appState.js'
import {cacheMonitorActions} from './reducers/cacheMonitor.js'
import {channelActions} from './reducers/channels.js'
import {calendarActions} from './reducers/calendar'
import {channelWindowActions} from './reducers/channelWindow.js'
import {chatInputActions} from './reducers/chatInput.js'
import {apiActions} from './reducers/api.js'
import {chatServesActions} from './reducers/chatServers.js'
import {clientSliceActions} from './reducers/client.js'
import {messagesActions} from './reducers/messages.js'
import {serverActions} from './reducers/server.js'
import {userMetaActions} from './reducers/userMeta.js'
import {userActions} from './reducers/users.js'
import {userSettingsActions} from './reducers/userSettings.js'
import {otherMonoActions} from './reducers/actions.js'
import {nonMonoSlices} from './reducers/index.js'
import {notificationsActions} from './reducers/notifications.js'
import {selectorMeta} from './constants/selectorMeta.js'
import {
    combineActions,
    createActionFormatters,
} from './cache/combineActions.js'
import {createSelectorReferences} from './process/selectorRefrenceCreator.js'
import {managementActions} from './reducers/management'
import {imageStoreActions} from './reducers/imageStore'
import {threadActions} from './reducers/threads'
import {videoActions} from "./reducers/video.js";
import {productsActions} from "./reducers/product";
import {integrationActions} from "./reducers/integrations";

const allMonoActions = monoActions.concat(otherMonoActions)

export const dispatchActions = combineActions(
    [
        ['abstractRole', abstractRoleActions],
        ['appState', appStateActions],
        ['_appState', appStateActions],
        ['api', apiActions],
        ['cacheMonitor', cacheMonitorActions],
        ['channels', channelActions],
        ['calendar', calendarActions],
        ['products', productsActions],
        ['channelWindows', channelWindowActions],
        ['management', managementActions],
        ['chatInputs', chatInputActions],
        ['integrations', integrationActions],
        ['imageStore', imageStoreActions],
        ['chatServers', chatServesActions],
        ['client', clientSliceActions],
        ['notifications', notificationsActions],
        ['messages', messagesActions],
        ['server', serverActions],
        ['threads', threadActions],
        ['userMetas', userMetaActions],
        ['users', userActions],
        ['userSettings', userSettingsActions],
        ["videoCall", videoActions]
    ],
    allMonoActions
)

export const RS1 = createSelectorReferences(selectorMeta)
export const RA1 = createActionFormatters(nonMonoSlices, allMonoActions)
export const initializeStore = (content) => {
    return {
        type: 'server/auth',
        data: content,
    }
}
export const getProfileContent = (content) => {
    return {
        type: 'server/mb',
        data: content,
    }
}
