import {useEffect, useState} from 'react'
import {
    makeRequest,
    createUploadToken,
    uploadFile,
} from '../filehost_microservice_api/index.js'

const errorFormat = (errorMethod, errorName, sev) => ({
    actionType: 'client/error',
    method: errorMethod,
    type: 'internal',
    errs: [
        {
            fieldError: false,
            message: errorName,
            severity: sev,
        },
    ],
})

export function useSocketRetrieve(dispatch, responses, apiId, emitId, process) {
    const [response, setResponse] = useState({})
    useEffect(() => {
        if (typeof responses === 'object') {
            Object.keys(responses).map((key) => {
                if (responses[key].hasOwnProperty('_trace')) {
                    if (
                        apiId === responses[key]._trace.apiId &&
                        emitId === responses[key]._trace.emitId
                    ) {
                        if (process) {
                            setResponse(process(responses[key]))
                        } else {
                            setResponse(responses[key])
                        }
                    }
                }
            })
        }
    }, [responses])
    const request = (...args) => {
        args.push(apiId)
        args.push(emitId)
        dispatch(...args)
    }
    return [request, response]
}

export function useBlockModal(AS, confirmationFunction) {
    const [count, setCount] = useState(0)
    useEffect(() => {
        if (count > 0) {
            AS.modals.ops.blockModal()
        }
    }, [count])

    useEffect(() => {
        if (AS.useBlockedFunction && count > 0) {
            confirmationFunction()
            AS.modals.ops.unblockModal()
            setCount(0)
        }
    }, [count, AS.useBlockedFunction, confirmationFunction])

    return () => {
        setCount(count + 1)
    }
}

export function useFields(initState) {
    const [formState, setFormState] = useState(initState)
    const fieldChangeCreator = (data) => (id) => (e) => {
        setFormState((currentFormState) => {
                let newFormState = {...currentFormState,...data}
                if (e?._isAMomentObject) {
                    newFormState[id] = e
                } else {
                    newFormState[id] = e.target.value
                }
                return newFormState
            }
        )
    }
    const fieldChange = fieldChangeCreator(formState)
    return [formState, setFormState, null, fieldChange]
}

export function useFormMonitor(formState, fn) {
    useEffect(() => {
        Object.keys(formState).forEach((key) => {
            fn(key)
        })
    }, [formState])
    return null
}

export function usePages(array, numberPerPage) {
    const [pageNumber, setPageNumber] = useState(1)
    const totalPages = Math.ceil(array.length / numberPerPage)
    const lowerBound = (pageNumber - 1) * numberPerPage
    const upperBound = Math.min(pageNumber * numberPerPage, array.length)
    const truncatedArray = array.slice(lowerBound, upperBound)
    return [(e, value) => {
        setPageNumber(value)
    }, totalPages, truncatedArray]
}


export function useSendFile(
    userId,
    locationId,
    imageStore,
    imageStoreDispatch,
    imageStoreClear,
    errorDispatch
) {
    const [blobLinks, setBlobLinks] = useState([])

    const sendFiles = async function () {
        try {
            let uploadToken = await makeRequest(
                [userId, locationId],
                createUploadToken
            )
            for (const i of imageStore[locationId]) {
                if (i !== null) {
                    try {
                        console.log('Uploading file... ' + i.filename)
                        let uploadedImage = await makeRequest(
                            [userId, uploadToken.data, i.filename, i.image],
                            uploadFile
                        )
                    } catch (e) {
                        console.log(e)
                        errorDispatch(
                            errorFormat(
                                'fileUploadError',
                                'Error uploading file: Response with an error code of ' +
                                error.statusCode.toString() +
                                ': ' +
                                error.data,
                                3
                            )
                        )
                        imageStoreClear(locationId)
                        setBlobLinks([])
                    }
                } else {
                    errorDispatch(
                        errorFormat(
                            'Redux error',
                            'Unknown redux error, check the console.',
                            3
                        )
                    )
                    console.log('Redux memory error? ' + i)
                    console.log(i)
                }
            }
            imageStoreClear(locationId)
            setBlobLinks([])
            return uploadToken.data
        } catch (error) {
            console.log(error)
            errorDispatch(
                errorFormat(
                    'fileUploadError',
                    'Error uploading file: Response with an error code of ' +
                    error.statusCode.toString() +
                    ': ' +
                    error.data,
                    3
                )
            )
            imageStoreClear(locationId)
            setBlobLinks([])
        }
    }

    const storeFile = (image, filename) => {
        let imageUrl = (window.URL ? URL : webkitURL).createObjectURL(image)
        setBlobLinks((blobLinks) => [
            ...blobLinks,
            {filename: filename, url: imageUrl},
        ])
        let reader = new FileReader()
        reader.onload = function () {
            var arrayBuffer = this.result
            var array = new Uint8Array(arrayBuffer)
            //keeps the location of the file
            imageStoreDispatch(locationId, array, filename)
        }
        reader.readAsArrayBuffer(image)
    }

    const clearFiles = () => {
        imageStoreClear(locationId)
        setBlobLinks([])
    }

    const hasFiles = () => {
        return (
            !(
                typeof imageStore[locationId] === 'undefined' ||
                imageStore[locationId] === null
            ) && imageStore[locationId].length > 0
        )
    }

    return [sendFiles, storeFile, clearFiles, hasFiles, blobLinks]
}

export function useAPI(fieldChange, props) {
    const updateAPI = props?.dispatch?.api?.updateAPI
    useEffect(() => {
        //Clear the API response store on initialization
        if (props?.formMethod) {
            props.dispatch.api.updateAPI('socket', props.formMethod, {
                loaded: false,
            })
        }
    }, [])
    return [
        {
            fieldChange: fieldChange,
            errorData: props?.errorData ? props.errorData : () => () => null,
            errorStatus: props?.errorStatus
                ? props.errorStatus
                : () => () => false,
            method: props?.formMethod ? props.formMethod : 'none',
        },
        updateAPI,
    ]
}
