import {StyledDiv} from "../Styled";
import React from "react";

export const EmptyComponent = (props)=>{
    return (
        // <StyledDiv style={{height:"100vh", width:"100%"}} types={['backgroundFlairEmpty']}>
        <h1 style={{ position: 'absolute', left: '35%' }}>
            {props.text}
        </h1>
    // {/*</StyledDiv>*/}
    )
}