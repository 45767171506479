import { createCachedSlice } from '../cache/createCachedSlice.js'

const imageStoreSlice = createCachedSlice({
    name: 'imageStore',
    initialState: {},
    reducers: {
        addImage: {
            cached: false,
            parameters: ['location', 'image', 'filename'],
            description: '',
            reduce: (state, action) => {
                const { location, image, filename } = action.payload
                if (state.hasOwnProperty(location)) {
                    let localImageStore = state[location]
                    return {
                        ...state,
                        [location]: [
                            ...localImageStore,
                            { filename: filename, image: image },
                        ],
                    }
                } else {
                    return {
                        ...state,
                        [location]: [
                            {
                                filename: filename,
                                image: image,
                            },
                        ],
                    }
                }
            },
        },
        clear: {
            cached: false,
            parameters: ['location'],
            description: '',
            reduce: (state, action) => {
                const { location } = action.payload
                let imageId = location
                return {
                    ...state,
                    [imageId]: [],
                }
            },
        },
    },
})

export const imageStoreActions = imageStoreSlice.actions
export const imageStore = imageStoreSlice.reducer
export { imageStoreSlice }
