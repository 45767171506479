import { createSlice } from '@reduxjs/toolkit'
import { updateCacheVersions } from '../reducers/cacheMonitor.js'

export const createCachedSlice = function (options) {
    //#TODO: Implement Cache policy.
    let cachedReducers = {}
    let cachedExtraReducers = {}
    let parameters = {}
    for (const key in options.reducers) {
        if (options.reducers.hasOwnProperty(key)) {
            if (options.reducers[key].cached) {
                cachedReducers[key] = (state, action) => {
                    let cR = options.reducers[key]
                    action.asyncDispatch(
                        updateCacheVersions({
                            orderedCacheKeys: cR.keyOrder,
                            keyContainer: cR.hasOwnProperty('keyContainer')
                                ? cR.keyContainer(action)
                                : action.payload,
                        })
                    )
                    return options.reducers[key](state, action)
                }
            } else {
                cachedReducers[key] = (state, action) => {
                    return options.reducers[key].reduce(state, action)
                }
            }
            if (options.reducers[key].hasOwnProperty('parameters')) {
                parameters[key] = {}
                parameters[key] = options.reducers[key]['parameters']
            }
        }
    }
    for (const key in options.extraReducers) {
        if (options.extraReducers.hasOwnProperty(key)) {
            if (options.extraReducers[key].cached) {
                cachedExtraReducers[key] = (state, action) => {
                    let cR = options.extraReducers[key]
                    action.asyncDispatch(
                        updateCacheVersions({
                            orderedCacheKeys: cR.keyOrder,
                            keyContainer: cR.hasOwnProperty('keyContainer')
                                ? cR.keyContainer(action)
                                : action.payload,
                        })
                    )
                    return options.extraReducers[key].reduce(state, action)
                }
            } else {
                cachedExtraReducers[key] = (state, action) => {
                    return options.extraReducers[key].reduce(state, action)
                }
            }
            // if (options.reducers[key].hasOwnProperty("parameters")) {
            //     parameters[key] = {}
            //     parameters[key] = options.reducers[key]["parameters"]
            // }
        }
    }
    const cachedSlice = createSlice({
        name: options.name,
        initialState: options.initialState,
        reducers: cachedReducers,
        extraReducers: cachedExtraReducers,
    })
    cachedSlice.parameters = parameters
    cachedSlice.cachedSlice = true
    return cachedSlice
}
