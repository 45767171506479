export const combineActions = function (sliceActionArray, nonSliceActions) {
    let dispatchActions = {}
    sliceActionArray.map((sliceActionData) => {
        dispatchActions[sliceActionData[0]] = {}
        for (const key in sliceActionData[1]) {
            if (sliceActionData[1].hasOwnProperty(key)) {
                dispatchActions[sliceActionData[0]][key] =
                    sliceActionData[1][key]
            }
        }
    })
    dispatchActions.other = {}
    nonSliceActions.map((actionData) => {
        dispatchActions.other[actionData[0]] = actionData[1]
    })
    return dispatchActions
}

export const createActionFormatters = function (sliceArray, nonSliceActions) {
    let dispatchActionFormatter = {}
    sliceArray.map((slice) => {
        dispatchActionFormatter[slice.name] = {}
        for (const key in slice.actions) {
            if (slice.actions.hasOwnProperty(key)) {
                dispatchActionFormatter[slice.name][key] = {
                    state: slice.name,
                    action: key,
                }
                dispatchActionFormatter[slice.name][key].formatter = (
                    ...args
                ) => {
                    if (slice.parameters[key].length === 0) {
                        return args[0]
                    } else {
                        let payload = {}
                        slice.parameters[key].map((argVal, index) => {
                            payload[argVal] = args[index]
                        })
                        return payload
                    }
                }
            }
        }
    })
    dispatchActionFormatter.other = {}
    nonSliceActions.map((actionData) => {
        dispatchActionFormatter.other[actionData[0]] = {
            state: 'other',
            action: actionData[0],
            formatter: (x) => x,
        }
    })
    return dispatchActionFormatter
}
