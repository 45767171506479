import React from 'react'
import { ButtonCustom } from '../../../../../Chat/Styled'
import { StyledDiv } from '../../../../../Chat/Styled'
import { dispatchMappers } from '../../../../../../redux/process/actionProcess'
import { RS1, RA1 } from '../../../../../../redux/actions'
import { connect } from 'react-redux'
import { statePropMapper } from '../../../../../../redux/process/selectorProcess'
import { embedProps, useTestData } from '../../util'
import { testData } from './test'

const makeMapStateToProps = statePropMapper([
    RS1.store('_activeChannel', '_activeChannel'),
    RS1.getServerAbstractRoles('serverAbstractRoles'),
])

const computedTestData = testData()

const SubscriptionDataLayer = (props) => {
    // const roleColor = serverAbstractRoles[roleId].perms.color
    let data = { ...props.product }
    // props.product.productMeta.roleId
    data = useTestData(props, data, computedTestData)

    // let roleColor = "#ff0000"
    let roleColor = props.serverAbstractRoles.find(role => role.id === data.productMeta.roleId).perms.color
    data.roleColor = roleColor


    return embedProps(props, data)
}

export default connect(makeMapStateToProps, null)(SubscriptionDataLayer)
