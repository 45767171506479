import { hexToFormattedRgb } from "../util"

export const ButtonCustomStyle = (mode, palette) => ({
    ButtonCustom: {
        defaultProps: {},
        propOverrides: {
            calendarButton: {
                variant: 'outlined',
                size: 'small',
                color: 'success',
            },
            calendarButtonAdmin: {
                sx: { p: '0px', px: '0px', minWidth: '1.2em', height: '1.5em' },
            },
            socketForm: {
                variant: 'outlined',
            },
        },
        styleOverrides:{"nothing":{}},altStyleOverrides: {
            root: {},
            channel: {},
            server: {},
            square: { borderRadius: '0px' },
            productButton: { flex: '0 1 min-content' },
            createOneTimeButton: {
                position: 'absolute',
                bottom: '0',
                left: '0',
            },
            timelineButton: {
                // borderRadius: '25px',
                paddingTop: '5px',
                paddingBottom: '5px',
                margin: '10px',
                textTransform: 'none',
                fontWeight: 'bold'
            },
            calendarButton: (props) => {
                let style = {
                    width: 'fit-content',
                    padding: '3px 7px',
                    whiteSpace: 'nowrap',
                    '&:hover': {}
                } // on hover: 1px solid palette.blah[palette.mode]
                // on hover: backgroundColor: rgba(${}, 0.04)
                if (props.status === 'default') {
                }
                else if (props.status === 'pending') {
                    style.border = `1px solid rgba(${hexToFormattedRgb(palette.error[palette.mode])}, 0.5)`
                    style.color = palette.error[palette.mode]
                    style['&:hover'] = {
                        border: `1px solid ${palette.error[palette.mode]}`,
                        backgroundColor: `rgba(${hexToFormattedRgb(palette.error[palette.mode])}, 0.4)`
                    }
                }
                else if (props.status === 'approved') {
                    style.border = `1px solid rgba(${hexToFormattedRgb(palette.success[palette.mode])}, 0.5)`
                    style.color = palette.success[palette.mode]
                    style['&:hover'] = {
                        border: `1px solid ${palette.success[palette.mode]}`,
                        backgroundColor: `rgba(${hexToFormattedRgb(palette.success[palette.mode])}, 0.4)`
                    }
                }
                else if (props.status === 'complete') {
                    style.border = `1px solid rgba(${hexToFormattedRgb(palette.primary[palette.mode])}, 0.5)`
                    style.color = palette.primary[palette.mode]
                    style['&:hover'] = {
                        border: `1px solid ${palette.primary[palette.mode]}`,
                        backgroundColor: `rgba(${hexToFormattedRgb(palette.primary[palette.mode])}, 0.4)`
                    }
                }
                return style
            },
            calendarButtonAdmin: {},
            socketForm: {
                // backgroundColor: mode.debugColor(7),
                // color: mode.debugColor(1)
            },
            user: {},
        },
    },
})
