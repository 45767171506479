import { useEffect } from 'react'
import { RS1, RA1 } from '../../../../../redux/actions'
import { connect } from 'react-redux'
import { statePropMapper } from '../../../../../redux/process/selectorProcess'
import { dispatchMappers } from '../../../../../redux/process/actionProcess'
import { ButtonCustom } from '../../../Styled'

const makeMapStateToProps = statePropMapper([
    RS1.getUserBooleanPerm('isOwner', ['isOwner']),
    RS1.store('_appState', '_appState'),
])

const mapDispatchToProps = dispatchMappers([RA1._appState.updateAppState])

const ProductButton = (props) => {
    const createProduct = () => {
        props.dispatch._appState.updateAppState({
            modalMenu: 'createProduct',
            modalMenuOpen: true,
            productData: {
                server: props.server,
                userId: props.userId,
                isOneTime: true,
            },
            isCreator: props.isCreator,
        })
    }

    useEffect(() => {
        if (props._appState?.createProductData) {
            props.setProductData({ ...props._appState.createProductData })
        }
    }, [props._appState])

    // TODO: is owner, is dm, is admin, ... etc.
    // allow as a reply.
    // don't show if editing.
    // #
    return (
        <div>
            {props.isOwner ? (
                <div>
                    <ButtonCustom
                        id={props.userId}
                        types={['square', 'createOneTimeButton']}
                        onClick={createProduct}
                    >
                        C
                    </ButtonCustom>
                </div>
            ) : (
                <div></div>
            )}
        </div>
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(ProductButton)
