import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {dispatchMappers} from '../../../../redux/process/actionProcess'
import {RA1} from '../../../../redux/actions'
import {SA1} from '../../../../hs/requestGlobals.mjs'
import {
    useAPI,
    useBlockModal,
    useFields,
    useFormMonitor,
} from '../../../../hs/hooks'
import HSInput from '../../../_reusable/Inputs/HSInput'
import {Divider} from '@mui/material'

import {styled} from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Masonry from '@mui/lab/Masonry'
import {withAS} from "../../../_contexts/withAppState";

const mapDispatchToProps = dispatchMappers([
    RA1.management.modifyRoleStruct,
    SA1.updateRoles,
    RA1._appState.blockModal,
])
const fieldChangeCustomizer = (e, customized) => [
    e,
    customized.roleId,
    customized.permName,
    customized.type,
    customized.index,
    customized.server,
]
const permToRoleDict = {
    bool: 'checkBoxSingle',
    string: '',
    color: 'color',
}

const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}))

const RoleSelector = (props) => {
    return (
        <>
            <HSInput
                types={['socketForm']}
                hidden={false}
                otherData={{}}
                key={'RAKEYX' + props.roleId + props.permName}
                typographyLabel={props.name}
                id={props.roleId + props.permName}
                label={props.description} // TODO: onhover, show rest of input..
                // socketFormInputHeader vs socketFormInputInnerBody
                // also, first div and second div inside socketFormInputBody
                // typographyTypes..
                // on hover reveal 2nd div & use animation to increase size of box?
                // on focus transition to different color?
                typographyTypes={{
                    inputHeader: [
                        'socketFormInputHeader',
                        'rolePermFormHeader',
                    ],
                    typography: ['socketForm'],
                    innerBody: [
                        'socketFormInputInnerBody',
                        'rolePermFormInner',
                    ],
                    inputBody: ['socketFormInputBody', 'rolePermFormBody'],
                }}
                value={props.value}
                fullWidth={false}
                colorFieldTypes={['colorRoleSelector']}
                customFieldChange={props.customFieldChange}
                customized={{
                    roleId: props.roleId,
                    permName: props.permName,
                    type: props.type,
                    index: props.index,
                    server: props.server,
                }}
                type={permToRoleDict[props.type]}
                {...props.inputProps}
            />
            {/* <Divider /> */}
        </>
    )
}

const RolePerms = (props) => {
    const [formState, setFormState, formStateRef, fieldChange] = useFields({})
    const [inputProps, updateAPI] = useAPI(fieldChange, props)
    const confirmationFn = () => props.dispatch.server.updateRoles(props.server, props.manager.roleStructModified, "rp1", "rp2")
    const blockModal = useBlockModal(props.AS, confirmationFn)

    useFormMonitor(formState, (key) => {
        props.dispatch.management.modifyRoleStruct(...formState[key])
        blockModal()
        let newFormState = {...formState}
        delete newFormState[key]
        setFormState(newFormState)
    })
    let activeRole = props.manager.selectedRole
    return (
        <div
            style={{
                height: 'auto',
                overflowY: 'auto',
                padding: '30px',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {props.manager.roleStructModified.rolePerms[activeRole].map(
                (perm, index) => {
                    return (
                        <RoleSelector
                            role={activeRole}
                            changeRolePermission={props.dispatch.management.modifyRoleStruct}
                            name={perm.name}
                            blockModal={blockModal}
                            customFieldChange={fieldChangeCustomizer}
                            permName={perm.permName}
                            value={perm.value}
                            type={perm.type}
                            index={index}
                            key={'ARGH' + perm.roleId + perm.permName}
                            roleId={perm.roleId}
                            server={props.server}
                            inputProps={inputProps}
                            description={perm.description}
                        />
                    )
                }
            )}
        </div>
    )
}

export default connect(null, mapDispatchToProps)(withAS(RolePerms))
