import {defaultColorsForUsers} from "../../hooks";

export const ListItemTextCustomStyle = (mode, palette) => ({
    ListItemTextCustom: {
        defaultProps: {},
        propOverrides: {
            serverLink: {
                primaryTypographyProps: {
                    variant: 'body1',
                },
                secondaryTypographyProps: {
                    color: palette.text.secondary,
                    variant: 'caption',
                    paddingLeft: '10px',
                },
            },
            channelLinkSelected: {
                primaryTypographyProps: {
                    display: 'block',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                },
                secondaryTypographyProps: {
                    color: palette.primary.main,
                    fontSize: '13px',
                    paddingLeft: '10px',
                },
            },
            subscriptionButtonText1: {
                primaryTypographyProps: {
                    display: 'block',
                    overflow: 'hidden',
                    color: palette.text.primary,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                },
                secondaryTypographyProps: {
                    color: palette.text.flair,
                    fontSize: '13px',
                    paddingLeft: '10px',
                },
            },
            channelLinkUnselected: {
                primaryTypographyProps: {
                    display: 'block',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                },
                secondaryTypographyProps: {
                    color: palette.text.flairSecondary,
                    fontSize: '13px',
                    paddingLeft: '10px',
                },
            },
            friendsNav: {
                primaryTypographyProps: {
                    display: 'block',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                },
                secondaryTypographyProps: {
                    color: palette.text.flair,
                    fontSize: '13px',
                    paddingLeft: '10px',
                },
            }
        },
        styleOverrides:{"nothing":{}},altStyleOverrides: {
            root: {},
            channel: {},
            server: {},
            serverLink: {
                '& p': {
                    maxWidth: '10em',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }
            },
            channelLink: {
                whiteSpace: 'nowrap',
                paddingLeft: '5px',
            },
            specialServerButton: {
                paddingLeft: '3px',
                paddingTop: '1px',
            },
            user: (props) => {
                if (props?.userdata) {
                    return {
                        color: defaultColorsForUsers(props.userdata.color,palette),
                        fontSize: '10px',
                    }
                } else {
                    return {}
                }
            },
            roleCategory: {
                color: palette.text.secondary,
                paddingLeft: '3px',
                fontSize: '10px',
            },
            friendsNav: {

            },
            profileButtonText: { color: palette.info.main },
        },
    },
})
