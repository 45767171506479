export const defaultRoles = ['Owner', 'Admin', 'Moderator', 'Everyone']
export const channelTypes = [
    'log',
    'config',
    'notifications',
    'general',
    'hidden',
]

export const permGlobals = {
    role: {
        mutablePerm: true,
        permType: 'role',
        description: 'Change the name of this role.',
        name: 'Role name',
        type: 'string',
        checkedPerm: false,
        errorCheck: '',
        modifiedBy: ['isAdmin', 'isModerator', 'canManageRoles'],
        defaults: {
            Owner: 'Owner',
            Admin: 'Admin',
            Moderator: 'Moderator',
            Created: 'Created',
            Everyone: 'Everyone',
        },
    },
    removable: {
        mutablePerm: false,
        permType: 'role',
        description: '',
        name: '',
        type: 'bool',
        checkedPerm: true,
        errorCheck: 'This permission is not mutable.',
        defaults: {
            Owner: true,
            Admin: false,
            Moderator: false,
            Everyone: true,
        },
    },
    color: {
        mutablePerm: true,
        permType: 'role',
        description: 'Choose the color for this role.',
        name: 'Role color',
        type: 'color',
        checkedPerm: false,
        errorCheck: '',
        modifiedBy: ['isAdmin', 'isModerator', 'canManageRoles'],
        defaults: {
            Owner: '#FF0000',
            Admin: '#0000FF',
            Moderator: '#ADD8E6',
            Everyone: '#a9a9a9',
        },
    },
    colorDisplayed: {
        mutablePerm: true,
        permType: 'role',
        description: 'Display role color.',
        name: 'Color displayed',
        type: 'bool',
        checkedPerm: false,
        errorCheck: '',
        modifiedBy: ['isAdmin', 'isModerator', 'canManageRoles'],
        defaults: {
            Owner: false,
            Admin: true,
            Moderator: true,
            Everyone: true,
        },
    },
    hideRole: {
        mutablePerm: true,
        permType: 'role',
        description: 'Hides the role in the frontend.',
        name: 'Hide Role',
        type: 'bool',
        checkedPerm: false,
        errorCheck: '',
        modifiedBy: ['isAdmin', 'isModerator', 'canManageRoles'],
        defaults: {
            Owner: true,
            Admin: false,
            Moderator: false,
            Everyone: false,
        },
    },
    realTimeChat: {
        mutablePerm: true,
        permType: 'role',
        description: 'Enable real-time chat for this role.',
        name: 'Real-time chat',
        type: 'bool',
        checkedPerm: true,
        errorCheck: 'Permission failure: User does not have real-time chat.',
        modifiedBy: ['isAdmin', 'isModerator', 'canManageRoles'],
        defaults: {
            Owner: true,
            Admin: false,
            Moderator: false,
            Everyone: false,
        },
    },
    displayRoleSeparately: {
        mutablePerm: true,
        permType: 'role',
        description: 'Displays this role separately from other users.',
        name: 'Display role separately.',
        type: 'bool',
        checkedPerm: false,
        errorCheck: '',
        modifiedBy: ['isAdmin', 'isModerator', 'canManageRoles'],
        defaults: {
            Owner: true,
            Admin: false,
            Moderator: false,
            Everyone: true,
        },
    },
    mentionable: {
        mutablePerm: true,
        permType: 'role',
        description: 'This role may be mentioned.',
        name: 'Mentionable',
        type: 'bool',
        checkedPerm: true,
        errorCheck: 'Permission failure: User is not mentionable.',
        modifiedBy: ['isAdmin', 'isModerator', 'canManageRoles'],
        defaults: {
            Owner: true,
            Admin: true,
            Moderator: true,
            Everyone: true,
        },
    },
    canMention: {
        mutablePerm: true,
        permType: 'role',
        description: 'This role has mentioning privileges.',
        name: 'Mentioning',
        type: 'bool',
        checkedPerm: true,
        errorCheck:
            'Permission failure: User does not have mentioning permissions.',
        modifiedBy: ['isAdmin', 'isModerator', 'canManageRoles'],
        defaults: {
            Owner: true,
            Admin: true,
            Moderator: true,
            Everyone: false,
        },
    },
    canCreateInvite: {
        mutablePerm: true,
        permType: 'role',
        description: 'This role can create server invites',
        name: 'Invites',
        type: 'bool',
        checkedPerm: true,
        errorCheck: 'Permission failure: User cannot create invites.',
        modifiedBy: ['isAdmin', 'isModerator', 'canManageRoles'],
        defaults: {
            Owner: true,
            Admin: true,
            Moderator: true,
            Everyone: true,
        },
    },
    viewable: {
        mutablePerm: true,
        permType: 'role',
        description: 'This messages of this role can be viewed.',
        name: 'Viewable',
        type: 'bool',
        checkedPerm: true,
        errorCheck: 'Permission failure: User messages cannot be viewed.',
        modifiedBy: ['isAdmin', 'isModerator', 'canManageRoles'],
        defaults: {
            Owner: true,
            Admin: true,
            Moderator: true,
            Everyone: true,
        },
    },
    canSendFiles: {
        mutablePerm: true,
        permType: 'role',
        description: 'This role can send files.',
        name: 'File sending',
        type: 'bool',
        checkedPerm: true,
        errorCheck: 'Permission failure: User cannot send files.',
        modifiedBy: ['isAdmin', 'isModerator', 'canManageRoles'],
        defaults: {
            Owner: true,
            Admin: true,
            Moderator: true,
            Everyone: true,
        },
    },
    canAddReactions: {
        mutablePerm: true,
        permType: 'role',
        description: 'This role can add reactions.',
        name: 'Add reactions',
        type: 'bool',
        checkedPerm: true,
        errorCheck: 'Permission failure: User cannot add reactions.',
        modifiedBy: ['isAdmin', 'isModerator', 'canManageRoles'],
        defaults: {
            Owner: true,
            Admin: true,
            Moderator: true,
            Everyone: true,
        },
    },
    canManageRoles: {
        mutablePerm: true,
        permType: 'role',
        description: 'This role can manage other roles.',
        name: 'Role management',
        type: 'bool',
        checkedPerm: true,
        errorCheck: 'Permission failure: User cannot manage roles.',
        modifiedBy: ['isAdmin', 'isModerator'],
        defaults: {
            Owner: true,
            Admin: true,
            Moderator: false,
            Everyone: false,
        },
    },
    canPostThreads: {
        mutablePerm: true,
        permType: 'role',
        description: 'This role can post threads.',
        name: 'Thread posting',
        type: 'bool',
        checkedPerm: true,
        errorCheck: 'Permission failure: User cannot post thread.',
        modifiedBy: ['canPostThreads'],
        defaults: {
            Owner: true,
            Admin: true,
            Moderator: true,
            Everyone: false,
        },
    },
    isModerator: {
        mutablePerm: true,
        permType: 'role',
        description:
            'Those with this role can kick and ban other users and delete messages.',
        name: 'Moderator.',
        type: 'bool',
        checkedPerm: true,
        errorCheck: 'Permission failure: User is not a moderator.',
        modifiedBy: ['isAdmin'],
        defaults: {
            Owner: true,
            Admin: true,
            Moderator: true,
            Everyone: false,
        },
    },
    isSubscriberRole: {
        mutablePerm: true,
        permType: 'role',
        description: 'This is a subscriber role.',
        name: 'Subscriber Role',
        type: 'bool',
        checkedPerm: true,
        errorCheck: 'Permission failure: User is not a subscriber.',
        modifiedBy: ['isAdmin'],
        defaults: {
            Owner: false,
            Admin: false,
            Moderator: false,
            Everyone: false,
        },
    },
    isAdmin: {
        mutablePerm: true,
        permType: 'role',
        description:
            'This user can fully manage the server and all lower roles',
        name: 'Admin',
        type: 'bool',
        checkedPerm: true,
        errorCheck: 'Permission failure: User is not an admin.',
        modifiedBy: ['isAdmin'],
        defaults: {
            Owner: true,
            Admin: true,
            Moderator: false,
            Everyone: false,
        },
    },
    isOwner: {
        mutablePerm: false,
        permType: 'role',
        description: 'This role belongs to the server owner.',
        name: 'Owner',
        type: 'bool',
        checkedPerm: true,
        errorCheck: 'Permission failure: User is not server owner.',
        modifiedBy: ['isOwner'],
        defaults: {
            Owner: true,
            Admin: false,
            Moderator: false,
            Everyone: false,
        },
    },
    canReadMessageHistory: {
        mutablePerm: true,
        permType: 'role',
        description: 'This role can read message history.',
        name: 'Message history',
        type: 'bool',
        checkedPerm: true,
        errorCheck: 'Permission failure: User cannot read message history.',
        modifiedBy: ['isAdmin', 'isModerator', 'canManageRoles'],
        defaults: {
            Owner: true,
            Admin: true,
            Moderator: true,
            Everyone: true,
        },
    },
    viewableToRoles: {
        mutablePerm: true,
        permType: 'channel',
        globalScope: false,
        falseOnBlock: false,
        description: 'This role can read message history.',
        shortDesc: 'Read messages',
        name: 'Real-time chat',
        primary: true,
        contains: 'role',
        flattenTo: 'viewable',
        checkedPerm: true,
        errorCheck: 'Permission failure: User cannot view channel.',
        db: {
            type: [String],
            required: [true, 'viewableToRoles attribute must not be null.'],
            default: [],
        },
        defaults: {
            config: ['OwnerRole'],
            notifications: ['OwnerRole', 'EveryoneRole'],
            log: ['OwnerRole'],
            general: ['OwnerRole', 'EveryoneRole'],
            hidden: [],
        },
    },
    writableToRoles: {
        mutablePerm: true,
        permType: 'channel',
        globalScope: false,
        falseOnBlock: true,
        description: 'This role can read message history.',
        shortDesc: 'Send messages',
        name: 'Real-time chat',
        primary: true,
        contains: 'role',
        flattenTo: 'writable',
        checkedPerm: true,
        errorCheck: 'Permission failure: User cannot write to channel.',
        db: {
            type: [String],
            required: [true, 'writableToRoles attribute must not be null.'],
        },
        defaults: {
            config: ['OwnerRole'],
            notifications: ['OwnerRole'],
            log: ['OwnerRole'],
            general: ['OwnerRole', 'EveryoneRole'],
            hidden: [],
        },
    },
    imageWritableToRoles: {
        mutablePerm: true,
        permType: 'channel',
        globalScope: false,
        falseOnBlock: true,
        description: 'This role can read message history.',
        shortDesc: 'Send images',
        name: 'Real-time chat',
        primary: true,
        contains: 'role',
        flattenTo: 'imageWritable',
        checkedPerm: true,
        errorCheck: 'Permission failure: User cannot send an image to channel.',
        db: {
            type: [String],
            required: [
                true,
                'imageWritableToRoles attribute must not be null.',
            ],
            default: [],
        },
        defaults: {
            config: ['OwnerRole'],
            notifications: ['OwnerRole'],
            log: ['OwnerRole'],
            general: ['OwnerRole', 'EveryoneRole'],
            hidden: [],
        },
    },
    linkWritableToRoles: {
        mutablePerm: true,
        permType: 'channel',
        globalScope: false,
        falseOnBlock: true,
        description: 'This role can read message history.',
        shortDesc: 'Send links',
        name: 'Real-time chat',
        primary: true,
        contains: 'role',
        flattenTo: 'linkWritable',
        checkedPerm: true,
        errorCheck: 'Permission failure: User cannot send a link to channel.',
        db: {
            type: [String],
            required: [
                true,
                'imageWritableToRoles attribute must not be null.',
            ],
            default: [],
        },
        defaults: {
            config: ['OwnerRole'],
            notifications: ['OwnerRole'],
            log: ['OwnerRole'],
            general: ['OwnerRole', 'EveryoneRole'],
            hidden: [],
        },
    },
    realTimeRoles: {
        mutablePerm: true,
        permType: 'channel',
        globalScope: false,
        description: 'This role can read message history.',
        shortDesc: 'Real-time chat',
        name: 'Real-time chat',
        primary: false,
        contains: 'role',
        checkedPerm: true,
        errorCheck:
            'Permission failure: User does not have real-time enabled for this channel.',
        db: {
            type: [String],
            required: [true, 'realTimeRoles attribute must not be null.'],
            default: [],
        },
        defaults: {
            config: ['OwnerRole'],
            notifications: ['OwnerRole'],
            log: ['OwnerRole'],
            general: [],
            hidden: [],
        },
    },
}
