import { dispatchActions } from '../actions.js'
import * as _ from 'underscore'

export const dispatchMappers = (dispatchOptionArray) => {
    let dispatchers = {}
    dispatchOptionArray.map((dispatchData) => {
        let act = dispatchData.action
        let sta = dispatchData.state
        let form
        let disFunction
        if (sta === 'server') {
            form = (...args) => ({
                handler: 'serverHandler',
                command: act,
                data: dispatchData.formatter(...args),
            })
        } else {
            form = dispatchData.formatter
        }
        let disAction = dispatchActions[sta][act]
        if (dispatchData.hasOwnProperty('debounce')) {
            disFunction = (dispatch) =>
                _.debounce(
                    (...args) => dispatch(disAction(form(...args))),
                    dispatchData.debounce
                )
        } else if (dispatchData.hasOwnProperty('throttle')) {
            disFunction = (dispatch) =>
                _.throttle(
                    (...args) => dispatch(disAction(form(...args))),
                    dispatchData.throttle
                )
        } else {
            disFunction =
                (dispatch) =>
                (...args) =>
                    dispatch(disAction(form(...args)))
        }
        dispatchers[sta + '/' + act] = [disFunction, sta, act]
    })
    const returnFunction = (dispatch) => {
        let dispatchActionsProcessed = {}
        for (const key in dispatchers) {
            if (dispatchers.hasOwnProperty(key)) {
                if (
                    dispatchActionsProcessed.hasOwnProperty(dispatchers[key][1])
                ) {
                    dispatchActionsProcessed[dispatchers[key][1]][
                        dispatchers[key][2]
                    ] = dispatchers[key][0](dispatch)
                } else {
                    dispatchActionsProcessed[dispatchers[key][1]] = {}
                    dispatchActionsProcessed[dispatchers[key][1]][
                        dispatchers[key][2]
                    ] = dispatchers[key][0](dispatch)
                }
            }
        }
        return { dispatch: dispatchActionsProcessed }
    }
    return returnFunction
}

//
