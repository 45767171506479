import React, {useState, useEffect} from 'react'
import {RA1} from '../../../../redux/actions.js'
import {connect} from 'react-redux'
import {statePropMapper} from '../../../../redux/process/selectorProcess.js'
// import { statePropMappers } from '../../../../redux/process/selectorProcess.js'
import {dispatchMappers} from '../../../../redux/process/actionProcess.js'
import withMaterialDnD from '../../../_wrappers/withMaterialDnD/index.js'
import withMenu from '../../../_wrappers/withMenu/index.js'
import ThreadLink from './ThreadLink/index.js'
import ThreadLinkMenu from './ThreadLink/ThreadLinkMenu/index.js'
import {StyledDiv, TypographyCustom} from '../../Styled'
import {RS1} from '../../../../redux/actions.js'
import {SA1} from '../../../../hs/requestGlobals.mjs'
import {useHistory, useLocation} from 'react-router-dom'
import {useQueryStrings} from '../../../../hooks/index.js'
import {JLog} from "../../../../hs/journey";
import {useSocketRetrieve} from '../../../../hs/hooks.js'
import {withAS} from '../../../_contexts/withAppState'
// import WatchedThreadCard from './WatchedThreadCard/index.js'

const mapDispatchToProps = dispatchMappers([
    RA1._appState.closeModalMenu,
    RA1._appState.changePage,
    RA1._appState.openModalMenu,
    SA1.watchThread,
    SA1.getThreadsWatched,
])
// const makeMapStateToProps = statePropMappers(
//     {
//         //getChannels: ["channels", []],
//         getChannels: ['channels', []],
//         getServer: ['serverData', []],
//     },
//     {
//         _userId: '_userId',
//         _loaded: '_loaded',
//     },
// )

// write selector?
// 
const makeMapStateToProps = statePropMapper([
    RS1.getThreads('threads'),
    RS1.getAllThreadsWatched('threadsWatched'),
    RS1.getSocketResponses('socketResponses'),
    RS1.getMessagesLength('mlength'),
])

const DnDThreadslList = withMenu(withMaterialDnD(ThreadLink), ThreadLinkMenu)

const ThreadWatcherNav = function (props) {

    let [getThreadsWatchedRequest, response] = useSocketRetrieve(
        props.dispatch.server.getThreadsWatched,
        props['socketResponses'],
        "getThreadsWatched",
        "getThreadsWatched")

    // return (
    //     <div>
    //         blahblah thread watcher
    //     </div>
    // )
    const [threadsNotifications, setThreadsNotifications] = useState({})
    const [selectedThread, setSelectedThread] = useState(null)
    const [threadsMetaData, setThreadsMetaData] = useState(null)

    const history = useHistory()
    let location = useLocation();

    const updateThreadNotifications = (threadsWatched, organizedThreads, reloadRequests) => {
        let newThreadNotifications = {}
        threadsWatched.map(watchedThread => {
            const threadId = watchedThread.threadId
            const oldReplies = watchedThread.threadReplies
            const newReplies = organizedThreads[threadId]?.threadReplies
            const lastReplyDate = organizedThreads[threadId]?.lastReplyDate

            if (!newReplies) {
                newThreadNotifications = threadsNotifications
                return threadsNotifications
            }

            let thisThreadNotifications = {
                prev: oldReplies,
                next: newReplies,
                noti: null
            }

            if ((newReplies > oldReplies) && (threadId !== props.AS.threads.state.selectedThread)) {
                thisThreadNotifications.noti = newReplies - oldReplies
            }
            if (lastReplyDate) {
                thisThreadNotifications.lastReplyDate = lastReplyDate
            }

            // if function called from non-socket-related useEffect,
            if (reloadRequests &&
                // and there are recorded notifications for this thread,
                threadsNotifications[threadId]?.noti &&
                // and there are new, not-yet-recoded notifications,
                (threadsNotifications[threadId]?.noti !== thisThreadNotifications.noti)) {
                // we hit the backend for thread data.
                // getThreadsWatchedRequest("")
            }

            newThreadNotifications[threadId] = thisThreadNotifications
        })
        return newThreadNotifications
    }

    useEffect(() => {
        console.warn("SOCKET THREAD REQUEST")
        getThreadsWatchedRequest("")
    }, [])
    useEffect(() => {
        if (response?.extra) {
            let newThreadNotifications = updateThreadNotifications(props.threadsWatched, response.extra, false)
            setThreadsNotifications(newThreadNotifications)
        }
    }, [response])

    useEffect(() => {
        const idsOfThreadsWatched = props.threadsWatched.map(thread => thread.threadId)

        let organizedThreads = {}
        props.threads.forEach(thread => {
            if (idsOfThreadsWatched.includes(thread._id)) {
                organizedThreads[thread._id] = thread.metaData
            }
        })

        let newThreadNotifications = updateThreadNotifications(props.threadsWatched, organizedThreads, true)
        setThreadsNotifications(newThreadNotifications)
    }, [props, props.threads, props.threadsWatched])


    useEffect(() => {
        if (location.search &&
            location.search.split('?')[1]) {
            if (location.search.split('?')[1].split('=')[1] &&
                location.search.split('?')[1].split('=')[0] === 'thread') {
                setSelectedThread(location.search.split('?')[1].split('=')[1])
                JLog.threads.log("LOCATION CHANGED")()
                JLog.threads.log(location.search.split('?')[1].split('=')[1])()
                JLog.threads.log(props)()
            }
        } else {
            JLog.threads.log("I'm the last one running! (threadwatcher)")()
            setSelectedThread(null)
        }
    }, [location])

    useEffect(() => {
        if (props._appState.openMenu !== "threads") {
            // TODO: Removed history.push -- broken with new query strings system.
            // Figure out proper solution + why this was implemented in the first place later.
            // let querystrings = history.location.search
            // history.push("/chat2")
        }
    }, [props._appState.openMenu])

    return (<DnDThreadslList
        list={props.threadsWatched}
        dynamicProps={{
            selectItem: () => true,
            notis: threadsNotifications,
            selectedThread: selectedThread
        }}
        droppableId={'friendList'}
        menuId={'friendListMenu'}
        useDnD={false}
        dragCondition={(listProps, index) => false}
        handleOnDragEnd={(result) => null}
    />)

}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(ThreadWatcherNav))
