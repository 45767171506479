import React, { useState } from 'react'
import { ButtonCustom, StyledDiv, TypographyCustom } from '../../Styled'
import { connect } from 'react-redux'
import { Card, CardContent, FormHelperText } from '@mui/material'
import { Pagination } from '@mui/lab'
import { usePages } from '../../../../hs/hooks'
import { withAS } from "../../../_contexts/withAppState";

const CalendarButton = function (props) {

    let adminViewMode = props.adminViewMode
    let helperText = props.appointment.length / 60000 + 'min'
    if (props.status === 'pending') {
        helperText = 'Pending'
    }
    return (
        <StyledDiv types={['calendarRowFlexCol']}>
            <StyledDiv types={['calendarButtonContainer']}>
                <ButtonCustom
                    types={['calendarButton']}
                    status={props.status}
                    onMouseEnter={() => {
                        props.setHoverAppointment(props.appointment)
                    }}
                    onMouseLeave={() => {
                        // props.setHoverAppointment({})
                    }}
                    onClick={() => {
                        props.setSelectedAppointment(props.appointment)
                        if (!adminViewMode) {
                            if (props.status === "default") {
                                props.AS.modals.ops.openModal('bookAppointment')
                            }
                            if (props.status === "approved") {
                                props.AS.modals.ops.openModal('backoutOfAppointment')
                            }
                        } else {
                            if (props.status === 'pending') {
                                props.AS.modals.ops.openModal('approveAppointment')
                            } else {
                                props.AS.modals.ops.openModal('cancelAppointment')
                            }
                        }
                    }}
                >
                    {props.name}
                </ButtonCustom>
                <FormHelperText>{helperText}</FormHelperText>
            </StyledDiv>
        </StyledDiv>
    )
}

const CalendarRow = function (props) {
    const [lowerBound, setLowerBound] = useState(0)
    const [setPageNumber, totalPages, truncatedArray] = usePages(props.dates, 3)

    if (props.dates.length > 0) {
        return (
            <StyledDiv types={['apptListProto']}>
                <Card className={'apptListCard'} sx={{ width: '90%', margin: '14px' }}>
                    <CardContent
                        style={{
                            padding: '0px',
                            paddingBottom: '10px',
                            paddingTop: '7px',
                        }}
                    >
                        <TypographyCustom
                            // gutterBottom
                            variant="h6"
                            component="div"
                            style={{ paddingLeft: '9px' }}
                        >
                            {props.calendarDate}
                        </TypographyCustom>
                        {/*<Typography*/}
                        {/*    variant="body2"*/}
                        {/*    color="text.secondary"*/}
                        {/*    style={{ paddingLeft: '11px' }}*/}
                        {/*>*/}
                        {/*    {props.weekday}*/}
                        {/*</Typography>*/}
                        <StyledDiv
                            types={['calendarRow']}
                            style={{
                                marginTop: '0px',
                                paddingTop: '0px',
                                marginBottom: '0px',
                                // background: "rgb(13 13 13)",
                            }}
                        >
                            {truncatedArray.map((date, index) => {
                                if (
                                    lowerBound <= index &&
                                    index < lowerBound + 5
                                ) {
                                    return (
                                        <CalendarButton
                                            key={date.id + index}
                                            AS={props.AS}
                                            openModalMenu={props.openModalMenu}
                                            appointment={date.appointment}
                                            status={date.status}
                                            setHoverAppointment={
                                                props.setHoverAppointment
                                            }
                                            setSelectedAppointment={
                                                props.setSelectedAppointment
                                            }
                                            adminViewMode={props.adminViewMode}
                                            name={date.id}
                                        />
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </StyledDiv>
                        <Pagination
                            count={totalPages}
                            onChange={setPageNumber}
                        />
                    </CardContent>
                </Card>
                {(props.dynamicIndex + 1 === props.listSize) ? <StyledDiv types={['apptCap']} /> : null}
            </StyledDiv>
        )
    }
    return null
}

export default withAS(CalendarRow)
