import HSInput from '../Inputs/HSInput'
import withAPI from '../../_wrappers/withAPI'
import React, {useEffect} from 'react'
import useState from 'react-usestateref'
import {ButtonCustom, StyledDiv, TypographyCustom} from '../../Chat/Styled'
import {ButtonGroup, Divider} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import {connect} from 'react-redux'
import {dispatchMappers} from '../../../redux/process/actionProcess'
import {RA1} from '../../../redux/actions'
import {useAPI, useFields} from '../../../hs/hooks'
import Purchase from '../../Chat/Payment/Customer/Purchase'

const SocketFormButtonIcon = (props) => {
    if ('none') {
        return null
    }
    if ('add') {
        return <AddIcon/>
    }
}
const mapDispatchToProps = dispatchMappers([RA1.api.updateAPI])

const SocketForm = function (props) {
    const [submit, setSubmit] = useState(false)
    const [operationSucceeded, setOperationSucceeded] = useState(false)
    const [handleSubmitCallback, setHandleSubmitCallback] = useState(0)
    const [page, setPage, pageRef] = useState(1)
    const [formType, setFormType] = useState(
        props.defaultFormType ? props.defaultFormType : null
    )
    const [onScreen, setOnScreen] = useState(
        props.initialScreen ? 'initial' : 'form'
    )
    const [formState, setFormState, formStateRef, fieldChange] = useFields(
        props.hasOwnProperty('formStateDefaults') ? props.formStateDefaults : {}
    )
    const [inputProps, updateAPI] = useAPI(fieldChange, props)
    // #ff
    useEffect(() => {

        return () => {
            updateAPI('socket', props.formMethod, {
                loaded: false,
                executedComplete: false,
            })
        }
    }, [])

    useEffect(() => {
        // console.log(props.successStatus(props.formMethod))
        if (props.successStatus(props.formMethod) && !operationSucceeded) {
            setOperationSucceeded(true)
            //Clears the API response store on success.
            updateAPI('socket', props.formMethod, {
                loaded: false,
                executedComplete: false,
            })
            //Executes callback
            if (props.hasOwnProperty("onSuccess")) {
                props?.onSuccess()
            }
        }
        if (props.errorStatus("none")(props.formMethod)) {
            if (props.hasOwnProperty("onError")) {
                props.onError()
            }
        }

    }, [props.successStatus(props.formMethod), props.errorStatus("none")(props.formMethod), operationSucceeded])

    useEffect(() => {
        if (handleSubmitCallback === 1) {
            handleSubmit()
        }
    }, [handleSubmitCallback])

    // #
    const requireErrorSingleField = (fieldName, requireError) => {
        return {
            fields: [fieldName],
            err: requireError,
            severity: 2,
        }
    }
    const errorGenerator = (fields) => {
        return {
            actionType: 'client/error',
            method: props.formMethod,
            type: 'validation',
            errs: fields,
        }
    }
    const handleSubmit = (e) => {
        if (e?.preventDefault) {
            e.preventDefault()
        }
        let missingFields = []
        props.formFields.forEach((fieldData) => {
            if (fieldData.require && !formState.hasOwnProperty(fieldData.id)) {
                missingFields.push(fieldData)
            }
        })
        if (missingFields.length === 0) {
            if (!props.submittedStatus(props.formMethod)) {
                //Puts a placeholder API response.
                updateAPI('socket', props.formMethod, {
                    actionType: 'client/submitted',
                    method: props.formMethod,
                })
                //Fires off the socket dispatch.
                props.formDispatch(formState)
                setOnScreen(
                    props.useConfirmationScreen ? 'confirmation' : 'form'
                )
            }
        } else {
            let errors = errorGenerator(
                missingFields.map((buttonData) =>
                    requireErrorSingleField(
                        buttonData.id,
                        buttonData.requireError
                    )
                )
            )
            updateAPI('socket', props.formMethod, errors)
        }
        setSubmit(true)
    }
    const buttonOperation = (buttonData) => {
        if (buttonData.increasePage) {
            return () => setPage(pageRef.current + 1)
        } else if (buttonData.decreasePage) {
            return () => setPage(pageRef.current - 1)
        } else if (buttonData.isSubmitButton) {
            return (e) => {
                if (buttonData.hasOwnProperty('mutateFormState')) {
                    e.preventDefault()
                    setFormState(buttonData.mutateFormState(formState))
                    setHandleSubmitCallback(1)
                } else {
                    e.preventDefault()
                    handleSubmit()
                }
            }
        } else if (buttonData.isFormTypeButton) {
            return () => {
                if (buttonData.hasOwnProperty('mutateFormState')) {
                    setFormState(buttonData.mutateFormState(formState))
                }
                setFormType(buttonData.formType)
                setOnScreen('form')
            }
        } else if (buttonData.isCloseButton) {
            return props.onClose
        } else {
            return buttonData.onClick
        }
    }
    const inputIsHidden = (fieldData) => {
        let hideIfCase = fieldData?.hideIf ? !fieldData.hideIf(formState) : true
        return !(
            (fieldData.page === page || (!fieldData.page && page === 1)) &&
            hideIfCase
        )
    }
    if (!operationSucceeded) {
        return (
            <StyledDiv types={['socketForm']}>
                <StyledDiv types={['socketFormHeader']}>
                    <TypographyCustom types={['socketForm']} variant={'h5'}>
                        {props.formTypography.label}
                    </TypographyCustom>
                </StyledDiv>

                {onScreen === 'form' ? (
                    <StyledDiv
                        types={['socketFormDescription']}
                        variant={'subtitle1'}
                        style={{width: 250}}
                    >
                        {props.description}
                    </StyledDiv>
                ) : null}

                {/*<StyledDiv types={["socketFormDivider"]} hidden={!(onScreen === "form")}>*/}
                {/*    <Divider/>*/}
                {/*</StyledDiv>*/}

                {onScreen === 'initial' ? (
                    <StyledDiv types={['socketFormButton']}>
                        <ButtonGroup
                            variant="contained"
                            aria-label="outlined primary button group"
                        >
                            {props.formButtons.map((buttonData) => {
                                let vis = buttonData?.initialButton
                                if (!vis) {
                                    return null
                                } else {
                                    return (
                                        <ButtonCustom
                                            key={buttonData.label + 'SF'}
                                            types={['socketForm']}
                                            onClick={buttonOperation(
                                                buttonData
                                            )}
                                        >
                                            <SocketFormButtonIcon
                                                icon={buttonData.icon}
                                            />
                                            {buttonData.label}
                                        </ButtonCustom>
                                    )
                                }
                            })}
                        </ButtonGroup>
                    </StyledDiv>
                ) : null}

                {onScreen === 'form' ? (
                    <form onSubmit={(e) => handleSubmit(e)}>
                        {/*<Divider style={{paddingBottom:"10px"}}/>*/}
                        {props.formFields.map((fieldData) => {
                            //TODO: StyledDiv socketFormInput?
                            //TODO: purchase input should be its own page.
                            if (fieldData.type === 'payment') {
                                return (
                                    <Purchase
                                        updateAPI={updateAPI}
                                        submit={submit}
                                        backButton={props.backButton}
                                        product={fieldData.product}
                                        isCreator={fieldData.isCreator}
                                    />
                                )
                            }

                            return (
                                <div hidden={inputIsHidden(fieldData)}>
                                    <StyledDiv
                                        types={['socketFormInput']}
                                        key={fieldData.id + 'SD'}
                                        hidden={inputIsHidden(fieldData)}
                                    >
                                        <HSInput
                                            types={['socketForm']}
                                            hidden={inputIsHidden(fieldData)}
                                            otherData={fieldData}
                                            key={fieldData.id}
                                            typographyLabel={
                                                fieldData.typographyLabel
                                            }
                                            id={fieldData.id}
                                            label={fieldData.label}
                                            type={fieldData.type}
                                            {...inputProps}
                                        />
                                    </StyledDiv>
                                </div>
                            )
                        })}
                        <Divider style={{paddingTop: '10px'}}/>
                    </form>
                ) : null}

                {onScreen === 'confirmation' ? (
                    <div>{props?.confirmationMessage ? props.confirmationMessage : ""}</div>
                ) : null}
                {/*<StyledDiv types={["socketFormDivider"]} hidden={!(onScreen === "form")}>*/}
                {/*    <Divider/><*/}
                {/*    /StyledDiv>*/}

                {onScreen === 'form' ? (
                    <StyledDiv types={['socketFormButton']}>
                        <ButtonGroup
                            variant="contained"
                            aria-label="outlined primary button group"
                        >
                            {props.formButtons.map((buttonData) => {
                                let vis = true
                                if (props.useMultipleFormPages) {
                                    if (
                                        buttonData.hasOwnProperty(
                                            'onlyShowOnPages'
                                        )
                                    ) {
                                        vis = buttonData?.onlyShowOnPages
                                            ? buttonData.onlyShowOnPages.includes(
                                                page
                                            )
                                            : false
                                    }
                                    if (
                                        buttonData.hasOwnProperty(
                                            'initialButton'
                                        )
                                    ) {
                                        vis = !buttonData.initialButton
                                    }
                                }
                                if (vis) {
                                    return (
                                        // <div key={buttonData.label + "SFA"} hidden={!vis}>
                                        <ButtonCustom
                                            hidden={!vis}
                                            key={buttonData.label + 'SF'}
                                            types={['socketForm']}
                                            onClick={buttonOperation(
                                                buttonData
                                            )}
                                        >
                                            <SocketFormButtonIcon
                                                icon={buttonData.icon}
                                            />
                                            {buttonData.label}
                                        </ButtonCustom>
                                        // </div>
                                    )
                                } else {
                                    return null
                                }
                            })}
                        </ButtonGroup>
                    </StyledDiv>
                ) : null}
            </StyledDiv>
        )
    } else {
        return (
            <StyledDiv types={['socketFormSuccess']}>
                {props.successMessage}
            </StyledDiv>
        )
    }
}

export default withAPI(connect(null, mapDispatchToProps)(SocketForm), 'socket')
