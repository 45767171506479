import React, { useEffect } from 'react'
import useState from 'react-usestateref'
import { connect } from 'react-redux'
import { dispatchMappers } from '../../../redux/process/actionProcess'
import { statePropMapper } from '../../../redux/process/selectorProcess'
import { RA1, RS1 } from '../../../redux/actions'
import { ButtonCustom, vDH } from '../Styled'
import Customer from './Customer'
import Creator from './Creator'

const makeMapStateToProps = statePropMapper([
    RS1.store('_appState', '_appState'),
    //TODO: may need _activeServer and _activeChannel for recording the transaction on the backend.
])

const mapDispatchToProps = dispatchMappers([RA1.api.updateAPI])

const PaymentPage = function (props) {
    const [currentPage, setCurrentPage] = useState(null)

    //TODO: Need a way to tell if user is owner or not
    //TODO: need verification here on whether or not user is in the server
    //      and for creator definitely need backend verification

    switch (currentPage) {
        case 'customer':
            return <Customer serverId={props.server} />
        case 'creator':
            return <Creator serverId={props.server} />
        default:
            return (
                <div
                    style={{
                        fontSize: '1.7em',
                        height: vDH(80),
                        display: 'flex',
                        flexDirection: 'column',
                        flexFlow: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <ButtonCustom
                        types={[]}
                        onClick={() => setCurrentPage('creator')}
                    >
                        Creator
                    </ButtonCustom>
                    <br />
                    <ButtonCustom
                        types={[]}
                        onClick={() => setCurrentPage('customer')}
                    >
                        Customer
                    </ButtonCustom>
                </div>
            )
    }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(PaymentPage)
