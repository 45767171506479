import React, {useState} from 'react'
import {SA1} from '../../../../hs/requestGlobals.mjs'
import {connect} from 'react-redux'
import {RS1} from '../../../../redux/actions'
import {ButtonCustom, StyledDiv, TypographyCustom} from '../../Styled'
import {statePropMapper} from '../../../../redux/process/selectorProcess'
import {dispatchMappers} from '../../../../redux/process/actionProcess'
import {withAS} from "../../../_contexts/withAppState";

const makeMapStateToProps = statePropMapper([
    RS1.getServer('serverData'),
    RS1.store('integrations', 'integrations'),
    RS1.store('user', '_userId'),
])
const mapDispatchToProps = dispatchMappers([
    SA1.flipNativeIntegration,
])

const IntegrationManagementManagement = (props) => {
    const [emojiKey, setEmojiKey] = useState(0)
    const sendFunction = (fileToken) => {
        // props.dispatch.server.addEmoji(props.server, 'blah', fileToken)
    }
    const intKeys = Object.keys(props.integrations.nativeIntegrationsData)
    const intData = props.integrations.nativeIntegrationsData
    return (
        <StyledDiv types={['managementPageOuterDiv', '_FVH']}>
            <TypographyCustom gutterBottom variant="h4" component="div">
                Integration Management
            </TypographyCustom>
            <div>
                {intKeys.map((key, index) =>
                    (<div key={key + "integration" + index}>
                        <div>
                            label: {intData[key].label},
                        </div>
                        <div>
                            description: {intData[key].description}
                        </div>
                        <div>
                            <ButtonCustom
                                onClick={() => props.dispatch.server.flipNativeIntegration(props.AS.activeServer, key, {})}>flip
                            </ButtonCustom>
                        </div>
                    </div>)
                )}
            </div>
        </StyledDiv>
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(IntegrationManagementManagement))
