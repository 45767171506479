export function createSelectorReferences(selectorMeta) {
    let referenceObject = {
        store: (propName, key) => ({
            isStore: true,
            propName: propName,
            key: key,
        }),
    }
    for (const key in selectorMeta)
        if (selectorMeta.hasOwnProperty(key)) {
            if (selectorMeta[key].type === 'memoized') {
                referenceObject[key] = (propName, argList) => ({
                    isStore: false,
                    key: key,
                    propName: propName,
                    argList: argList,
                })
            }
        }
    return referenceObject
}
