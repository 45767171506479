import React, { useRef } from "react";
import { render } from "react-dom";
import {
    AutoSizer,
    CellMeasurer,
    CellMeasurerCache,
    createMasonryCellPositioner,
    // Masonry
} from "react-virtualized";
import { StyledMasonry } from "./styles";
import ImageMeasurer from "react-virtualized-image-measurer";
import useState from "react-usestateref";
import ThreadCard from "../ThreadCard";
import { vDH } from "../../Styled";

const keyMapper = (item, index) => item.pic || index;

const columnWidth = 300;
const defaultHeight = 250;
const defaultWidth = columnWidth;

// Default sizes help Masonry decide how many images to batch-measure
let cache = new CellMeasurerCache({
    defaultHeight,
    defaultWidth,
    fixedWidth: true
});

// Our masonry layout will use 3 columns with a 10px gutter between
const cellPositionerConfig = {
    cellMeasurerCache: cache,
    columnCount: 4,
    columnWidth,
    spacer: 10
};

let cellPositioner = createMasonryCellPositioner(cellPositionerConfig);

const MasonryComponent = ({
    itemsWithSizes,
    setSelectedThread,
    threadsArray,
    threadsWatched,
    server,
    setSingleThreadPage,
    setRef,
    passedHeight,
    passedWidth
}) => {
    const cellRenderer = ({ index, key, parent, style }) => {
        const { item, size } = itemsWithSizes[index];
        const thread = item
        return (
            <CellMeasurer cache={cache} index={index} key={key} parent={parent}>
                <div style={style}>
                    <ThreadCard key={index}
                        setSelectedThread={setSelectedThread}
                        setSingleThreadPage={setSingleThreadPage}
                        index={index}
                        thread={thread}
                        server={server}
                        threadsArray={threadsArray}
                        threadsWatched={threadsWatched}
                        userId={thread.uId}
                        selectedThread={thread.id}
                        style={{ border: '1px solid lime' }}
                    // dimensions={{
                    //     height: style.height,
                    //     width: style.width
                    // }}
                    />
                </div>
            </CellMeasurer>
        );
    };

    return (
        <StyledMasonry
            cellCount={itemsWithSizes.length}
            cellMeasurerCache={cache}
            cellPositioner={cellPositioner}
            cellRenderer={cellRenderer}
            height={passedHeight}
            width={passedWidth}
            keyMapper={keyMapper}
            overscanByPixels={2000}
            ref={setRef}
        />
    );
};

const FullMasonryComponent = (props) => {
    // const [state, setState] = useState({images: noCacheList})
    const masonryRef = useRef(null)

    // this shows how to significantly change the input array, if items will be only appended this recalculation is not needed
    // const shorten = (mRef) => {
    //     cache.clearAll();
    //     cellPositioner.reset(cellPositionerConfig);
    //     mRef.clearCellPositions();
    //     setState({images: [...this.state.images.slice(1)]});
    // };

    const adjustScreen = (args, columnCount) => {
        cache._defaultWidth = Math.floor((args.width - 30) / columnCount) - 15

        cache.clearAll()
        cellPositioner.reset({
            columnCount: columnCount,
            columnWidth: Math.floor((args.width - 30) / columnCount) - 15,
            spacer: 15
        });
        masonryRef.current.clearCellPositions()
    }

    return (
        <div style={{
            height: vDH(98),
            width: '100%'
        }}>
            {/*<button onClick={() => shorten(masonryRef)}>Resize</button>*/}
            <AutoSizer
                onResize={args => {

                    let AR = args.width / args.height

                    let columnCount = 4
                    columnCount = (AR < 1.2 && AR >= 0.9) ? 3 : columnCount
                    columnCount = (AR < 0.9 && AR >= 0.7) ? 2 : columnCount
                    columnCount = (AR < 0.7 && AR >= 0.0) ? 1 : columnCount

                    adjustScreen(args, columnCount)
                }}
            >
                {({ width, height }) => {
                    // TODO: Every time window resized, this executes

                    let actualWidth = width
                    let actualHeight = height
                    if (process.env.NODE_ENV === 'test') {
                        actualWidth = window.innerWidth
                        actualHeight = window.innerHeight
                    }

                    return (<ImageMeasurer
                        items={props.items}
                        image={item => item?.pic ? item.pic : 'https://picsum.photos/200'}
                        keyMapper={keyMapper}
                        onError={(error, item, src) => {
                            console.error(
                                "Cannot load image",
                                src,
                                "for item",
                                item,
                                "error",
                                error
                            );
                        }}
                        style={{
                            height: vDH(98),
                            width: '100%'
                        }}
                        defaultHeight={defaultHeight}
                        defaultWidth={defaultWidth}
                    >
                        {({ itemsWithSizes }) => {
                            return (
                                <MasonryComponent
                                    style={{
                                        height: vDH(98),
                                        width: '100%'
                                    }}
                                    threadsWatched={props.threadsWatched}
                                    setRef={masonryRef}
                                    passedHeight={actualHeight}
                                    passedWidth={actualWidth}
                                    server={props.server}
                                    itemsWithSizes={itemsWithSizes}
                                    setSingleThreadPage={props.setSingleThreadPage}
                                    setSelectedThread={props.setSelectedThread}
                                    threadsArray={props.threadsArray}
                                />
                            )
                        }}
                    </ImageMeasurer>
                    )
                }}</AutoSizer></div>)
}


export default FullMasonryComponent