export const testData = () => {

    let fakePROPS = {
        "type": "user",
        "userData": {
            "id": "591616193875768320",
            "status": "offline",
            "profileId": "591616196371379205",
            "pictureId": "",
            "userName": "testest614",
            "color": "#FF0000",
            "primaryRole": "591616397794440199",
            "roles": [
                "591616397794440199"
            ]
        },
        "userId": "591616193875768320",
        "user": {
            "id": "591616193875768320",
            "status": "busy",
            "profileId": "591616196371379205",
            "pictureId": "",
            "userName": "testest614"
        }
    }


    let id = fakePROPS.userData.id
    let status = fakePROPS.user.status
    let name = fakePROPS.userData.userName
    let avatar = fakePROPS.user.pictureId
    let flair = {
        server: 'AK50 Community',
        title: 'Supporter'
    }
    let color = "#FF0000"

    return { id, status, name, avatar, flair, color }
}