import React from 'react'
import {
    ListItemCustom,
    ListItemTextCustom,
    StyledDiv,
    TypographyCustom,
} from '../../Styled'
import { connect } from 'react-redux'
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined'
import { dispatchMappers } from '../../../../redux/process/actionProcess'
import { RA1 } from '../../../../redux/actions'
import { useHistory } from 'react-router-dom'
import { SA1 } from "../../../../hs/requestGlobals.mjs";
import { streams } from "../../Stream/stream-controller.js";
import { withAS } from "../../../_contexts/withAppState";
import { FRONTEND_URL, FULL_FRONTEND_URL } from "../../../../constants/env";
import { withDesignAndState } from "../../../_contexts/withDesignAndState";

const mapDispatchToProps = dispatchMappers([
    SA1.createGuest, RA1._appState.changePage, RA1.notifications.resetNotifications])

const ManagementButton = (props) => {
    return (
        <ListItemCustom
            types={['specialServerButton']}
            key={'K' + props.label + 'ButtonKey'}
            onClick={props.onClick}
        >
            {props.children}
            &nbsp;
            <ListItemTextCustom
                types={['specialServerButton']}
                id={'ID' + props.label + 'Button'}
                primary={props.label}
            />
        </ListItemCustom>
    )
}

const UserManagementNav = (props) => {
    const history = useHistory()
    return [
        <StyledDiv
            types={['sidebarTopInternalHeader', 'rightSidebarHeader']}
            className={'sidebarTopInternalHeader'}
        >
            <TypographyCustom types={['generalHeader', 'rightSidebarHeader']} variant="h5">
                Management
            </TypographyCustom>
        </StyledDiv>

        ,

        <StyledDiv
            types={['sidebarTopInternalMiddle']}
            className={'sidebarTopInternalMiddleUpper'}
        >
            <ManagementButton
                label={'Settings'}
                onClick={() => {
                    props.AS.nav.ops.setPage('accountManagement')
                }}
            >
                <SettingsApplicationsOutlinedIcon />
            </ManagementButton>
            <ManagementButton
                label={'Logout'}
                onClick={() => {
                    props.authFunctions.logout(() => {
                        window.location = FRONTEND_URL + "auth2"
                    })
                }}
            >
                <SettingsApplicationsOutlinedIcon />
            </ManagementButton>
            <ManagementButton
                label={'Report Bug'}
                onClick={() => {
                    props.AS.modals.ops.openModal("bugReport")
                }}
            >
                <SettingsApplicationsOutlinedIcon />
            </ManagementButton>
            {props.adminOrAbove || true ? <>
                <TypographyCustom types={['communityHeader']} variant="h5">
                    Developer Menu
                </TypographyCustom>
                <ManagementButton
                    label={'DEV: Join Without Invite'}
                    onClick={() => {
                        let sourceId = prompt("DEBUG: Please enter the source id of the user room");
                        if (!(sourceId == null || sourceId == "")) {
                            streams.startCallWithoutInvite(sourceId, "userSession");
                        }
                    }
                    }
                >
                    <SettingsApplicationsOutlinedIcon />
                </ManagementButton>
                <ManagementButton
                    label={'DEV: Join With Invite'}
                    onClick={() => {
                        let invite = prompt("DEBUG: Please enter the full invite code");
                        if (!(invite == null || invite == "")) {
                            let sourceId = invite.split(":")[0];
                            let finalInvite = invite.split(":")[1];
                            streams.startCallWithInvite(sourceId, finalInvite);
                        }
                    }}
                >
                    <SettingsApplicationsOutlinedIcon />
                </ManagementButton>
                <ManagementButton
                    label={'DEV: Reset Notifications'}
                    onClick={() => {
                        props.dispatch.notifications.resetNotifications()
                    }}
                >
                    <SettingsApplicationsOutlinedIcon />
                </ManagementButton>
                <ManagementButton
                    label={'DEV: Create Guest Account'}
                    onClick={() => {
                        props.dispatch.server.createGuest('abc')
                    }}
                >
                    <SettingsApplicationsOutlinedIcon />
                </ManagementButton>
                <ManagementButton
                    label={'DEV: Change Theme'}
                    onClick={props.switchStyleMode}
                >
                    <SettingsApplicationsOutlinedIcon />
                </ManagementButton>
                <ManagementButton
                    label={'DEV: Create Alpha Invite'}
                    onClick={() => {
                        props.AS.modals.ops.openModal('alphaCreateInvite')
                    }}
                >
                    <SettingsApplicationsOutlinedIcon />
                </ManagementButton>
            </> : null}
        </StyledDiv>

        ,

        <StyledDiv
            types={['sidebarTopInternalMiddleLowest']}
            className={'sidebarTopInternalMiddleLowest'}
        ></StyledDiv>

        // <button onClick={() => {
        //     let sourceId = prompt("DEBUG: Please enter the source id of the user room");
        //     if (!(sourceId == null || sourceId == ""))
        //         streams.startCallWithoutInvite(sourceId, "userSession");
        // }
        // }> Join Without Invite</button>
        // <button onClick={() => {
        //     let invite = prompt("DEBUG: Please enter the full invite code");
        //     if (!(invite == null || invite == "")) {
        //         let sourceId = invite.split(":")[0];
        //         let finalInvite = invite.split(":")[1];
        //         streams.startCallWithInvite(sourceId, finalInvite);
        //     }
        // }}> Join With Invite
        // </button>
    ]
}

export default connect(null, mapDispatchToProps)(withDesignAndState(withAS(UserManagementNav)))
