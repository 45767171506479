import { hexToFormattedRgb } from './util'

export const globals = (mode, palette, animations, typography) => ({
    _flex: { display: 'flex' },
    _centeredVH: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '10px',
    },
    _FVH: {
        height: '100vh',
        '@media (max-aspect-ratio: 4/3)': {
            height: '100dvh'
        }
    },
    _minFVH: {
        minHeight: '100vh',
        '@media (max-aspect-ratio: 4/3)': {
            minHeight: '100dvh'
        }
    },
    _sidebarPaddingLeft: { paddingLeft: mode.leftBarPadding },
    _sidebarPaddingRight: { paddingRight: mode.leftBarPadding },
    _bottomRightHover: {
        background: 'rgba(76, 175, 80, 0)',
        position: 'absolute',
        bottom: "0",
        right: "0"
    },
    _bottomLeftHover: {
        background: 'rgba(76, 175, 80, 0)',
        position: 'absolute',
        bottom: "0",
        left: "0"
    },
    // _topRightHover: {
    //     background: 'rgba(76, 175, 80, 0)',
    //     position: 'absolute',
    //     top: "0",
    //     right: "0"
    // },
    _bottomRightHoverHigher: {
        background: 'rgba(76, 175, 80, 0)',
        position: 'sticky',
        bottom: '100px',
        width: 'inherit',
        maxWidth: 'inherit',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: '0px',
    },
    _topRightHover: {
        background: 'rgba(76, 175, 80, 0)',
        position: 'absolute',
        top: "20px",
        left: "0px",
        zIndex: "3000"
    },
    _sidebarPaddingTop: { marginTop: '8px' },
    _carrot: { color: 'blue', backgroundColor: 'orange' },
    _fadedBackgroundImage: {
        backgroundImage:
            'url(' +
            'https://gifts.worldwildlife.org/gift-center/Images/large-species-photo/large-Frog-photo.jpg' +
            ')',
        // backgroundSize: "300px 130px",
        backgroundRepeat: 'no-repeat',
        WebkitMaskImage:
            'radial-gradient(farthest-corner at 100% 0%, rgba(0, 0, 0, 1) 10%, rgba(255, 255, 255, 0) 70%)',
    }
})

export let scrollbarConfig = (palette) => {
    return {
        '&::-webkit-scrollbar': {
            width: 14,
        },
        '&::-webkit-scrollbar-track': {
            // background: "rgba(0,0,0,.4)",
            // color:"green",
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            background: palette?.thread?.border
                ? palette.background.scroll
                : 'rgba(' +
                hexToFormattedRgb(palette.background.scroll) +
                ',.1)',
            // clipPath: palette?.thread?.border && "100% 0%, 0% 0%, 6% 100%, 70% 90%",
            // color:"green",
            // outline: "1px solid slategrey",

            // border: "3px solid blue",
            borderRadius: 0,
        },
    }
}

export let listConfiguration = (maxHeight, palette) => ({
    ...scrollbarConfig(palette),
    ...{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        overflowX: 'hidden',
        height: 'auto',
        maxHeight: maxHeight,
    },
})
