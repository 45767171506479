import React from "react"

import { StyledDiv, ButtonCustom, TypographyCustom, AvatarCustom, ListItemAvatarCustom } from "../../../../../Chat/Styled"
import { Style } from "@mui/icons-material"

import { Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material"

// import { ButtonCustom } from '../../../Chat/Styled'
// import { StyledDiv } from '../../../Chat/Styled'
// import { dispatchMappers } from '../../../../redux/process/actionProcess'
// import { RA1 } from '../../../../redux/actions'
// import { connect } from 'react-redux'

const UserCard = (props) => {
    return (
        <StyledDiv className="recolor-item"
            types={[
                'generalCard',
                (props.compressed) ?
                    (props.desktop) ? 'compressedDesktop' : 'compressedCard' :
                    'fullCard',
            ]}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                // textAlign: 'center',
                padding: '1.3rem',
                paddingRight: '2em',
            }}>
            <StyledDiv style={{
                marginRight: '1.5em',
            }}>
                <ListItemAvatarCustom types={['user']}
                    status={props.status}>
                    <AvatarCustom
                        sx={{
                            aspectRatio: '1 / 1',
                            height: (props.compressed) ? '2em' : '4em',
                            width: 'auto'
                        }}
                        src={props.avatar} />
                </ListItemAvatarCustom>
            </StyledDiv>
            <StyledDiv style={{
                height: 'calc(1.25rem * 4)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: props.compressed ? 'center' : 'space-between'
            }}>
                <TypographyCustom
                    variant={props.compressed ? "h6" : "h5"}
                    types={['userCardUserName']}
                    userColor={props?.userColor}>
                    {props.name}
                </TypographyCustom>

                {(props.compressed) ?
                    null
                    :
                    (!props?.flair) ?
                        <StyledDiv types={['userFlair', 'oneLineClamp']}>
                            This user has no flair.
                        </StyledDiv>
                        :
                        <StyledDiv types={['userFlair']} style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <TypographyCustom variant="p"
                                style={{
                                    fontSize: '0.7rem'
                                }}>
                                {props.flair.server}
                            </TypographyCustom>

                            <TypographyCustom variant="p"
                                style={{
                                    fontSize: '0.9rem'
                                }}>
                                <span>{props.flair.title}</span>
                            </TypographyCustom>
                        </StyledDiv>
                }

            </StyledDiv>
        </StyledDiv>
    )
}

const UserCardDisplay = function (props) {

    // Every card display must have n different modes.
    // Which mode is rendered is determined by a switch.
    // However, these modes must be the same across all cards.
    // Suggested modes: full-desktop, half-desktop, compressed-desktop, full, compressed

    // #
    switch (props.displayMode) {
        case 'fullDesktop':
            return <UserCard desktop={true} {...props} />
        case 'halfDesktop':
            return <UserCard desktop={true} {...props} />
        case 'compressedDesktop':
            return <UserCard desktop={true} compressed={true} {...props} />
        case 'full': // mobile
            return <UserCard desktop={false} {...props} />
        default:
            return <UserCard desktop={false} compressed={true} {...props} />
    }
}

export const userCardPackage = {
    requirements: ['displayMode', 'image', 'title', 'data'],
    CardDisplay: UserCardDisplay
}