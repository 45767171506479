import React from "react";
import { useTheme } from "@mui/material";
import { statePropMapper } from "../../redux/process/selectorProcess";
import { RS1 } from "../../redux/actions";
import { withAS } from "../_contexts/withAppState";
import { connect } from "react-redux";


import { IconButtonCustom } from "./Styled";

// threads:
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import LayersIcon from '@mui/icons-material/Layers';
// search:
import SearchIcon from '@mui/icons-material/Search';
// settings:
import SettingsIcon from '@mui/icons-material/Settings';

const makeMapStateToProps = statePropMapper([RS1.store('_loaded', '_loaded'), RS1.store('_userId', '_userId'), RS1.store('chatServers', 'chatServers'), RS1.store('channels', 'channels'),])
const TopBar = (props) => {
    const theme = useTheme()
    let firstText = ""
    let secondText = ""
    let thirdText = ""
    console.log("EHIA")
    console.log(props)
    let serverName = props.chatServers[props.AS.activeServer].name
    let channelName = props.channels[props.AS.activeChannel].channelName
    switch (props.AS.nav.state.page) {
        case "profile":
            firstText = serverName
            secondText = "Timeline"
            break;
        case "chat":
            if (channelName !== "Profile") {
                firstText = serverName + "//" + channelName
                secondText = "Channel"
            }
            break;
        case "threads":
            if (channelName !== "Profile") {
                firstText = serverName + "//" + channelName
                secondText = "Channel[Thread View]"
            }
            break;
        case "manage":
            firstText = serverName
            secondText = "Community Management"
            break;
    }
    if (props.leftBar) {
        switch (props.AS.nav.state.menuL) {
            case "community":
                secondText = "Community Channels"
                break;
            case "servers":
                firstText = "Communities"
                secondText = ""
                break;
            case "profile":
                firstText = serverName
                secondText = "Community Profile"
                break;
            case "debug":
                firstText = serverName
                secondText = "User Settings"
                break;
        }
    }
    if (props.rightBar) {

    }
    return (
        <div style={{
            position: "absolute",
            zIndex: 200000,
            backgroundColor: theme.palette.background.secondary,
            borderBottom: "1px solid " + theme.palette.background.paper,
            width: "100dvw",
            height: "3.2em",
            padding: '7px 20px',

            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
        }}>
            <IconButtonCustom
                types={[props.page === 'threads' && 'mobileNavButton', 'mobileNavAlign']}
                style={{
                    padding: '8px 0px', paddingRight: '1rem',

                    flex: '3rem', flexGrow: 0, flexShrink: 0,
                }}
                onClick={() => {
                    if (props.page === 'profile') {
                        props.AS.nav.ops.changeOffProfilePage()
                    }
                    setTimeout(() => {
                        if (props.page !== 'threads') {
                            props.AS.nav.ops.channelButtonClicked(props.AS.activeChannel, true)
                        } else if (props.page === 'threads') {
                            props.AS.nav.ops.channelButtonClicked(props.AS.activeChannel, false)
                        }
                    }, 20)

                }}>

                {/* <span style={{ fontSize: '0.7rem' }}>threads</span> */}
                {props.page === 'threads' ? <LayersIcon /> : <LayersOutlinedIcon />}

            </IconButtonCustom>
            <span style={{
                flex: 'auto',
                flexGrow: 1,
                flexShrink: 0,

                maxWidth: '60vw',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',

                lineHeight: "1.0"

            }}>
                <div>
                    <div style={{ height: "fit-content" }}>
                        <span style={{
                            maxWidth: '16em',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            fontSize: '1.2em'

                        }}>
                            {firstText}
                        </span>
                    </div>
                    <div style={{ height: "fit-content" }}>
                        <span style={{
                            color: theme.palette.text.flair,
                            maxWidth: '16em',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            fontSize: '.7em'

                        }}>
                            {secondText}
                        </span>
                    </div>
                </div>
            </span>

            <IconButtonCustom
                types={[
                    // props.page === 'manage' && 'mobileNavButton',
                    'mobileNavAlign']}
                style={{
                    padding: '8px 0px', transform: 'scale(0.7)', width: '2rem',

                    flex: '2rem', flexGrow: 0, flexShrink: 0,
                }}
                onClick={() => {

                    props.AS.nav.ops.setMenuL('manage')
                    props.AS.nav.ops.setPage('manage')

                    if (props.leftMenu !== 0) {
                        props.setMobileNavThreadWatcher(false)
                    }

                    if (!props.leftBar) {
                        // open right bar
                        props.setLeftBar(true)
                        if (props.rightBar) {
                            props.setRightBar(false)
                            props.swapToDrawer('left')()
                        } else {
                            props.toggleDrawer('left', true)()
                        }
                    }
                }}>
                <SettingsIcon />
                {/* <span style={{ fontSize: '0.7rem', color: 'transparent', }}>.</span> */}
            </IconButtonCustom>

            <IconButtonCustom
                types={[ //TODO: search === true &&
                    // false && 'mobileNavButton',
                    'mobileNavAlign']}
                style={{
                    padding: '8px 0px',

                    flex: '3rem', flexGrow: 0, flexShrink: 0,
                }}
                onClick={() => {
                    if (props.rightMenu !== 0) {
                        // set right menu to 0
                        props.setRightMenu(0)
                        props.setMobileNavThreadWatcher(false)
                        if (props?.setMobileNavTabValue) {
                            props.setMobileNavTabValue(0)
                        }
                    }

                    if (!rightBar) {
                        // open right bar
                        props.setRightBar(true)
                        if (props.leftBar) {
                            props.setLeftBar(false)
                            props.swapToDrawer('right')()
                        } else {
                            props.toggleDrawer('right', true)()
                        }
                    }
                }}>
                <SearchIcon />
                {/* <span style={{ fontSize: '0.7rem' }}>search</span> */}
            </IconButtonCustom>
        </div>
    )
}

export default connect(makeMapStateToProps, null)(withAS(TopBar))