import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { dispatchMappers } from '../../../../../../redux/process/actionProcess'
import { statePropMapper } from '../../../../../../redux/process/selectorProcess'
import { RA1, RS1 } from '../../../../../../redux/actions'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { ButtonCustom } from '../../../../Styled'

//TODO: use types to style TypographyCustom and ButtonCustom; use options(?) to style PaymentElement.
//    - certain payment methods redirect to an intermediate page to authorize payment.
//        if the user fails to authorize it, they'll be redirected back and the PaymentIntent will have
//        a status requires_payment_method. Try to recollect payment if this happens.
//    - integrate PaymentMethods, allow customers to opt out of them.

const makeMapStateToProps = statePropMapper([
    RS1.store('_appState', '_appState'),
])

const PaymentForm = function (props) {
    // #
    const stripe = useStripe()
    const elements = useElements()

    // #
    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    // #
    const isButtonDisabled = () => isLoading || !stripe || !elements

    const handleSubmit = async (e) => {
        // TODO:
        // ask customers if they want to save their info using payment methods apy for subscriptions or later payments
        // create-customer, email: emailInput.value
        // name?
        // address?
        // ... etc

        // backend, stripe.customers.create..

        // then, at subscription, form post to backend w/ customerId and priceId....
        // but we store these things on the backend.

        // then on backend, create `subscription` with status `incomplete` by using
        // `payment_behavior=default_incomplete`. Then return the `client_secret` from response.

        // then on the frontend, use stripe Elements to collect payment info, then stripe.confirmPayment.

        // then, listen for the webhook.. process webhook sent by stripe...
        // triggered when internal state of stripe changes, like subscriptions making new invoices...
        // set up http handler to accept POST request containing webhook event, & verify signature of the event (????)

        // then set default payment method...? need a webhook consumer to listen for `invoice.payment_succeeded` event for new
        // subscriptions and set the default payment method........

        // cancel subscription... backend stripe.subscriptions.del( subscriptionId )

        // execute when HOC is submitted

        if (stripe && elements) {
            setIsLoading(true)

            console.warn(elements)
            const { paymentIntent, error } = await stripe.confirmPayment({
                elements,
                redirect: 'if_required',
                confirmParams: {
                    return_url: 'http://localhost:3000/', //TODO: url redirect
                },
            })

            if (paymentIntent) {
                setMessage('Payment succeeded.')
                props.onSuccess()
            } else if (
                error?.type === 'card_error' ||
                error?.type === 'validation_error'
            ) {
                setMessage(error.message)
            } else {
                setMessage('An unexpected error was encountered.')
            }

            setIsLoading(false)
        }
    }

    // #
    useEffect(() => {
        const clientSecret = props._appState?.clientSecret

        let secretInUrl = new URLSearchParams(window.location.search).get(
            'payment_intent_client_secret'
        )

        let paymentWasAttempted = () => clientSecret === secretInUrl

        if (stripe && paymentWasAttempted()) {
            stripe
                .retrievePaymentIntent(clientSecret)
                .then(({ paymentIntent }) => {
                    switch (paymentIntent.status) {
                        case 'succeeded':
                            setMessage('Payment succeeded.')
                            props.onSuccess()
                            break
                        case 'processing':
                            setMessage('Payment is still processing.')
                            break
                        case 'requires_payment_method':
                            setMessage('Payment failed, please try again.')
                            break
                        default:
                            setMessage('Something went wrong.')
                            break
                    }
                })
        }
    }, [stripe])

    useEffect(() => {
        if (props.submit) {
            handleSubmit()
        }
    }, [props.submit])

    // #
    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement />
            <br />

            {/* <ButtonCustom
                disabled={isButtonDisabled()}
                types={[]}
                onClick={handleSubmit}>
                pls click me
            </ButtonCustom><br /><br /> */}

            <div id="payment-message">{message}</div>
            <br />
        </form>
    )
}

export default connect(makeMapStateToProps, null)(PaymentForm)
