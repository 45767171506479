export const loadOneThread = ({selectedThread, props}) => () => {
    props.dispatch.server.updateSession(
        'loadSingleThread',
        props.server,
        props.channel,
        null,
        '',
        '',
        selectedThread
    )
}