import React, { useEffect, useState } from 'react'
import ManagementBarLink from './ManagementBarLink/index.js'
import { topRoleRank } from '../../Util/index.js'
import AddIcon from '@mui/icons-material/Add'
import {
    ListItemCustom,
    ListItemTextCustom,
    StyledDiv,
    TypographyCustom,
    vDH,
} from '../../Styled'
import withMaterialDnD from '../../../_wrappers/withMaterialDnD'
import { statePropMapper } from '../../../../redux/process/selectorProcess'
import { RA1, RS1 } from '../../../../redux/actions'
import { dispatchMappers } from '../../../../redux/process/actionProcess'
import { connect } from 'react-redux'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import SellIcon from '@mui/icons-material/Sell'
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined'
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined'
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined'
import DoNotStepOutlinedIcon from '@mui/icons-material/DoNotStepOutlined'
import { withAS } from "../../../_contexts/withAppState";

const DnDRoleList = withMaterialDnD(ManagementBarLink)
const mapDispatchToProps = dispatchMappers([
    RA1._appState.openModalMenu,
    SA1.changeRoleOrder,
    RA1.management.handleGeneralRoleDrag,
    RA1.management.clearRoleOrderData,
    RA1.management.initManagement,
    RA1.management.setSelectedRole,
])
const makeMapStateToProps = statePropMapper([
    RS1.getServer('serverData'),
    RS1.getServerManager('manager'),
])

const ManagementButton = (props) => {
    return (
        <ListItemCustom
            types={['specialServerButton']}
            key={'K' + props.label + 'ButtonKey'}
            onClick={props.onClick}
        >
            {props.children}
            &nbsp;
            <ListItemTextCustom
                types={['specialServerButton']}
                id={'ID' + props.label + 'Button'}
                primary={props.label}
            />
        </ListItemCustom>
    )
}

const ManagementBar = (props) => {
    const [roleOrderCallback, setRoleOrderCallback] = useState(false)
    useEffect(() => {
        if (props.server) {
            props.dispatch.management.initManagement(props.server)
        }
    }, [props.serverData.version])
    useEffect(() => {
        if (roleOrderCallback === true) {
            const modifiedRoleIds = props.manager.modifiedRoleLinks.map(
                (role) => role.id
            )
            props.dispatch.server.changeRoleOrder(props.server, modifiedRoleIds)
        }
        props.dispatch.management.clearRoleOrderData(props.server)
        setRoleOrderCallback(false)
    }, [roleOrderCallback])
    useEffect(() => {
        if (props.manager?.rolesModified) {
            setRoleOrderCallback(true)
        }
    }, [props.manager])

    if (props?.manager?.initialized) {
        let topUserRank = topRoleRank(
            props.manager.userMeta.roles,
            props.manager.roleStruct.roleDict,
            props.manager.roleLinks.length
        )
        return [
            <StyledDiv
                types={['sidebarTopInternalHeader', 'rightSidebarHeader']}
                className={'sidebarTopInternalHeader'}
            >
                <TypographyCustom types={['generalHeader', 'rightSidebarHeader']} variant="h5">
                    Management
                </TypographyCustom>
            </StyledDiv>

            ,

            <StyledDiv
                types={['sidebarTopInternalMiddle']}
                className={'sidebarTopInternalMiddleUpper'}
                style={{
                    height: 'fit-content',
                    maxHeight: vDH(40),
                    overflowY: 'visible',
                }}>
                <ManagementButton
                    label={'Settings'}
                    onClick={() => props.AS.setManagementPage('settings')}
                >
                    <SettingsApplicationsOutlinedIcon />
                </ManagementButton>
                <ManagementButton
                    label={'Emoji'}
                    onClick={() => props.AS.setManagementPage('emoji')}
                >
                    <EmojiEmotionsOutlinedIcon />
                </ManagementButton>
                <ManagementButton
                    label={'Products'}
                    onClick={() => props.AS.setManagementPage('products')}
                >
                    <SellIcon />
                </ManagementButton>
                <ManagementButton
                    label={'Integrations'}
                    onClick={() => props.AS.setManagementPage('integrations')}
                >
                    <IntegrationInstructionsOutlinedIcon />
                </ManagementButton>
                <ManagementButton
                    label={'Bans'}
                    onClick={() => props.AS.setManagementPage('bans')}
                >
                    <DoNotStepOutlinedIcon />
                </ManagementButton>
                <ManagementButton
                    label={'Add a role'}
                    onClick={() => {
                        props.AS.modals.ops.openModal('addRole')
                    }}
                >
                    <AddIcon />
                </ManagementButton>
            </StyledDiv>

            ,

            <StyledDiv
                types={['sidebarTopInternalMiddleLowest']}
                className={'sidebarTopInternalMiddleLowest'}
            ></StyledDiv>

            ,

            <StyledDiv
                types={['sidebarTopInternalList']}
                className={'sidebarTopInternalList'}
            >
                <div style={{ paddingTop: '8px' }} />
                <StyledDiv types={['sidebarTopInternalScroller']}>
                    <DnDRoleList
                        list={props.manager.roleLinks}
                        key={props.manager?.serverData.version}
                        dynamicProps={{
                            roleDict: props.manager.roleStruct.roleDict,
                            server: props.server,
                            activePage: props.manager?.managementPage,
                            activeRole: props.manager?.selectedRole,
                            changeActivePage:
                                props.AS.setManagementPage,
                            changeActiveRole:
                                props.dispatch.management.setSelectedRole,
                        }}
                        droppableId={'roleList'}
                        menuId={'roleListMenu'}
                        useDnD={false}
                        dragCondition={(listProps, index) =>
                            index <= topUserRank
                        }
                        handleOnDragEnd={(result) =>
                            props.dispatch.management.handleGeneralRoleDrag(
                                props.server,
                                result,
                                topUserRank
                            )
                        }
                    />
                    <div style={{ height: '1em' }}></div>
                </StyledDiv>
            </StyledDiv>
        ]
    } else {
        return null
    }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(ManagementBar))
