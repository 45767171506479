import React from 'react'
import {connect} from 'react-redux'
import {RA1} from '../../../../redux/actions.js'
import {SA1} from '../../../../hs/requestGlobals.mjs'
import {dispatchMappers} from '../../../../redux/process/actionProcess.js'
import SocketForm from '../../../_reusable/SocketForm/SocketForm'

const mapDispatchToProps = dispatchMappers([
    RA1.api.updateAPI,
    SA1.createServer,
])

const ServerModal = function (props) {
    return (
        <SocketForm
            useConfirmationScreen={false}
            formMethod={'createServer'}
            formDispatch={({serverName, primaryCommunity}) => {
                props.dispatch.server.createServer(serverName, primaryCommunity, 'createServer', 'createServer')
            }}
            socketForm
            onClose={props.closeModalMenu}
            onSuccess={props.closeModalMenu}
            description={'You may create a community.'}
            formFields={[
                {
                    id: 'serverName',
                    typographyLabel: 'Community Name',
                    label: '',
                    require: true,
                    page: 0,
                },
                {
                    id: 'primaryCommunity',
                    type: 'checkBoxSingle',
                    // hideIf: (formState) =>
                    //     formState.premiumChannel !== true,
                    typographyLabel: '',
                    label: 'Primary Community',
                    require: false,
                    page: 1,
                }
            ]}
            formButtons={[
                {
                    label: 'Create',
                    icon: 'add',
                    isSubmitButton: true,
                    isCloseButton: false,
                },
            ]}
            formTypography={{label: 'Create Community'}}
            successMessage={'Community Created.'}
        />
    )
}

export default connect(null, mapDispatchToProps)(ServerModal)
