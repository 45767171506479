import { scrollbarConfig } from '../../globals'

export const ThreadMessageStyleOverrides = (mode, palette) => {
    return {
        singleThreadOuterWrapper: {
            display: 'flex',
            flexDirection: 'row',
        },
        singleThreadWrapper: {
            borderLeft:
                palette?.thread?.border && `1px solid ${palette.text.flair}`,
        },
        threadReplyModal: {
            '& > *': { border: '1px solid cyan' }
        },
        singleThreadOuter: {
            width: '100%',
            overflowY: 'hidden',
            overflowX: 'hidden',
            transform: 'translate(0, 0)',
        },
        singleThread: {
            display: 'flex',
            flexDirection: 'column',
            height: '95vh',

            '@media (max-aspect-ratio: 4/3)': {
                height: '95dvh',
            }
        },
        singleThreadInner: {
            height: 'fit-content',
        },

        threadMessageWrapper: {
            width: 'fit-content',
            marginBottom: '1em',
            paddingBottom: '1.5em',
            borderBottom: '1px solid ' + (palette?.thread?.border ? palette.background.primary : palette.background.secondary),
        },
        threadSingleLineDiv: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '10px',
        },
        //======================//
        // FLEXBOX CHAT DISPLAY //
        //======================//
        threadUsername: {
            color: palette.text.primary,
        },
        threadFlexboxOuterDiv: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            paddingLeft: '10px',
        },
        threadFexboxInnerColumns: {
            flex: '0 1 10%',
            width: '10%',
            justifyContent: 'flex-start',
            padding: '7px',
            paddingTop: '0px',
            paddingBottom: '0px',
            display: 'flex column',
        },
        threadFlexboxColumnText: {
            flex: '0 1 10vw',
            width: '40vw',
            wordBreak: 'break-word',
        },
        threadFlexboxColumnHeader: {
            flex: '1 1 auto',
            display: 'flex',
            width: '40vw',
            flexDirection: 'row',
            justifyContent: 'space-between',
            color: palette.primary.main,
            whiteSpace: 'nowrap'
        },
        threadFlexboxColumnImg: { flex: '1 1 0 auto', width: '7.8vw' },

        threadViewScrollDiv: {
            position: 'absolute',
            height: '98vh',
            overflow: 'auto',
            display: 'flex',

            '@media (max-aspect-ratio: 4/3)': {
                height: '98dvh',
            },
            ...scrollbarConfig(palette),
        },
        threadDecorBordered: {
            border: '1px solid ' + (palette?.thread?.border ?
                palette.text.flair :
                palette.background.secondary)
        },
        threadTooltipHeader: {
            // textDecoration: 'underline',
            fontSize: '1.1em',
            color: palette.text.primary,
            fontWeight: 'bold'
        }
    }
}
