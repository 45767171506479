import React, { useState, useEffect, useRef } from "react";
import MDEditor from '@uiw/react-md-editor';
import { StyledDiv, IconButtonCustom, TextFieldCustom, TypographyCustom, vDH } from "../Styled";
import { MarkdownContainer } from "../../_reusable/Markdown";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import EditNoteIcon from '@mui/icons-material/EditNote';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import { statePropMapper } from "../../../redux/process/selectorProcess";
import { RA1, RS1 } from "../../../redux/actions";
import { dispatchMappers } from "../../../redux/process/actionProcess";
import withChatMessaging from "../../_wrappers/withChatMessaging";
import { connect } from "react-redux";
import { useTheme } from "@mui/material";
import moment from 'moment'

import {
    MessageHeader,
} from "../../_reusable/Cards/UniversalCard/util";

const testVal = "# This is my article\n" +
    "\n" +
    "## Section 1:\n" +
    "\n" +
    "waf awf awf waf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awf\n" +
    "\n" +
    "## Section 2:\n" +
    "\n" +
    "waf awf awf waf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awf\n" +
    "\n" +
    "\n" +
    "## Section 3:\n" +
    "\n" +
    "waf awf awf waf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awfwaf awf awf"

const makeMapStateToProps = statePropMapper([
    RS1.store('imageStore', 'imageStore'),
    RS1.getServerUsersOb('users'),
    RS1.store('_userId', '_userId'),
])
const mapDispatchToProps = dispatchMappers([
    RA1.client.error,
    RA1.imageStore.clear,
    RA1.imageStore.addImage,
])

function BlogInput(props) {
    const theme = useTheme()

    const previewDivRef = useRef(null);
    const editorDivRef = useRef(null);

    const [inputValue, setInputValue] = useState('')
    const [displayValue, setDisplayValue] = useState('')
    const [threadTitle, setThreadTitle] = useState('')
    const [currentlyUploading, setCurrentlyUploading] = useState(false);
    const [userObj, setUserObj] = useState()
    const { sendFiles, storeFile, clearFiles, hasFiles, blobLinks } = props
    const [reply, setReply] = useState({
        isReply: false,
        hasReplies: [],
        message: '',
        user: '',
        edited: false,
        deleted: false,
        attachment: false,
        mData: {
            uId: '',
            mId: '',
            cId: '',
            sId: '',
        },
    })

    function closeFile() {
        clearFiles()
    }

    async function handleSubmit() {
        // TODO: preview of product as attachment
        // TODO: create product, get product id, use product id when submitting message

        if (!currentlyUploading) {
            let fileToken = ''
            if (hasFiles()) {
                setCurrentlyUploading(true);
                fileToken = await sendFiles()
                setCurrentlyUploading(false);
            }
            props.submitMessageWrapped(
                inputValue,
                reply,
                fileToken,
                true,
                '',
                threadTitle,
                true
            )
            props.triggerThreadModeReload()
        }
        setThreadTitle('')
        setInputValue('')
        closeFile()
    }

    const handleThreadTitleChange = (e) => {
        setThreadTitle(e.target.value)
    }

    function onFile(data) {
        // TODO: add imgUpload_1 link in markdown
        if (data.target.files && data.target.files[0]) {
            storeFile(data.target.files[0], data.target.files[0].name)
            if (blobLinks.length > 0) {
                let newInputValue = inputValue + `\n\n![imgUpload_${blobLinks.length}](${data.target.files[0].name})`
                setInputValue(newInputValue)
            }
        }
        data.target.value = null
    }
    const handlePreviewScroll = scroll => {
        // if (scroll.target.scrollTop <=
        //     previewDivRef.current.children[0].clientHeight) {
        //     return null
        // }
        // editorDivRef
        //     .current
        //     .children[0]
        //     .children[0]
        //     .children[1]
        //     .children[0].scrollTop =
        //     scroll.target.scrollTop
        //     - previewDivRef.current.children[0].clientHeight;
    }
    const handleEditorScroll = scroll => {
        // previewDivRef.current.scrollTop =
        //     scroll.target.scrollTop
        //     + previewDivRef.current.children[0].clientHeight;
    }

    useEffect(() => {
        if (editorDivRef
            ?.current
            ?.children[0]
            ?.children[0]
            ?.children[1]
            ?.children[0]
            ?.scrollTop >=
            (editorDivRef
                ?.current
                ?.children[0]
                ?.children[0]
                ?.children[1]
                ?.children[0]
                ?.children[0]
                ?.clientHeight - 200)) {
            previewDivRef.current.scrollTop =
                previewDivRef
                    ?.current
                    ?.children[0]
                    ?.clientHeight
        }
    }, [displayValue])

    useEffect(() => {
        let tmpInputValue = inputValue.split('\n')
        tmpInputValue.forEach((line, index) => {
            if (line.startsWith('![imgUpload_')) {
                let [fileIdx, filename] = line.split('![imgUpload_')[1].split(/[\]\(\)\"\ ]/).filter(i => i)
                let newFileLink = line.split(filename)
                if (blobLinks[fileIdx]?.filename === filename) {
                    tmpInputValue[index] = newFileLink.join(blobLinks[fileIdx].url)
                }
                else {
                    tmpInputValue[index] = newFileLink.join('error')
                }
            }
        })

        tmpInputValue = tmpInputValue.join('\n')
        setDisplayValue(tmpInputValue)

    }, [blobLinks, inputValue])

    useEffect(() => {
        setUserObj(props.users[props._userId])
    }, [])

    useEffect(() => {
        // Set the editor to contain the editor scroll handler
        editorDivRef.current
            .children[0]
            .children[0]
            .children[1]
            .children[0]
            .onscroll = handleEditorScroll

        // Link the editor to our ref
        editorDivRef.current
            .children[0]
            .children[0]
            .children[1]
            .children[0]
            .ref = editorDivRef
    }, [])

    return (
        <StyledDiv id='textContainer' types={['blogInputContainerDiv']}>
            <StyledDiv types={['blogInputMarkdownContainer']}>
                <StyledDiv types={['blogInputMarkdownEditor']} className="container">
                    <StyledDiv types={['blogInputTop']}>
                        <div className="blogInputMarkdownPreview"
                            style={{
                                '--left-margin': '0px',
                                '--total-fake-margin': 'calc(2 * var(--left-margin))',
                                '--preview-width': 'calc(100%-var(--total-fake-margin))',
                                width: '100%',
                                wordWrap: "break-word",
                                height: vDH(50),
                                overflow: "scroll",
                                overflowX: "hidden",
                                padding: '0% 20%',
                            }} onScroll={handlePreviewScroll}
                            ref={previewDivRef}>
                            {/* <StyledDiv className={'blahblah weird'}
                                types={['blogInputMarkdownEditorPreview']}
                                > */}
                            <StyledDiv types={['flexboxColumnHeader']}>

                                <div style={{
                                    marginTop: '1em',
                                    maxWidth: '100%',
                                    maxHeight: 'auto',
                                    objectFit: 'contain',
                                }}>
                                    <img
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: 'auto',
                                            objectFit: 'contain',
                                            border:
                                                theme.palette?.thread?.border &&
                                                `1px solid ${theme.palette.text.flair}`,
                                            borderRadius: '15px 0px 15px 0px',

                                            width: '100%',
                                            height: '100%',
                                        }}
                                        src={blobLinks.length
                                            ? blobLinks[0].url
                                            : 'https://picsum.photos/seed/540327602/800/800?blur=3'}
                                    />
                                </div>

                                <TypographyCustom
                                    variant="h3" types={['blogTitle']}>
                                    {threadTitle
                                        ? threadTitle
                                        : 'Your post\'s title will go here.'}
                                </TypographyCustom>

                                <StyledDiv types={['blogPostHeader']}>

                                    <MessageHeader
                                        user={userObj}
                                        message={'blhbal'}
                                        fullMessageDate={moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a')}
                                        desktop={true}
                                        blogPost={true} />
                                </StyledDiv>

                            </StyledDiv>
                            <MarkdownContainer message={displayValue} />
                            {/* </StyledDiv> */}
                        </div>
                        <StyledDiv types={['blogInputUtilityBar']}>

                            {/* {!isEditing && !currentlyUploading ? ( */}
                            <label className="cursor-handle" style={{
                                margin: '0px 10px', padding: '8px'
                            }}>
                                <AddCircleOutlineIcon color="error" style={{}} />
                                <input
                                    type="file"
                                    // ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={(e) => onFile(e)}
                                    accept="image/*"
                                />
                            </label>
                            {/* ) : null} */}

                            {/* {currentlyUploading ? ( */}
                            {/* <CircularProgress style={{ width: "25px", height: "25px" }} /> */}
                            {/* ) : null} */}

                            <StyledDiv types={['blogInputMarkdownButton']}>
                                <IconButtonCustom types={['markdownButton', 'nohover']} onClick={() => {
                                    props.setBlogView(view => !view)
                                }}>
                                    <EditIcon color="error" />
                                </IconButtonCustom>
                            </StyledDiv>

                            <TextFieldCustom
                                types={['inputContainerTextField']}
                                value={threadTitle} // TODO: THIS
                                label=""
                                placeholder={'Thread Title'}
                                size={'small'}
                                fullWidth={true}
                                style={{ height: 'fit-content' }}
                                onChange={handleThreadTitleChange}
                            />

                            <StyledDiv types={['blogInputMarkdownButton']}>
                                <IconButtonCustom types={['markdownButton', 'nohover']} onClick={handleSubmit}>
                                    <SendIcon color="error" />
                                </IconButtonCustom>
                            </StyledDiv>

                        </StyledDiv>
                    </StyledDiv>

                    <div ref={editorDivRef}>
                        <StyledDiv types={['blogPostOverrides']}>
                            <MDEditor
                                preview={'edit'}
                                value={inputValue}
                                onScroll={handleEditorScroll}
                                onChange={setInputValue}
                            />
                        </StyledDiv>
                    </div>
                    {/*<MDEditor.Markdown source={value} style={{ whiteSpace: 'pre-wrap' }} />*/}
                </StyledDiv>
            </StyledDiv>
        </StyledDiv>
    );
}

export default withChatMessaging(
    connect(makeMapStateToProps, mapDispatchToProps)(BlogInput)
)
