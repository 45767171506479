import React from 'react'
import ProductCard from '../../../_reusable/Cards/ProductCard/ProductCard'

const ProductPreview = function (props) {
    // #
    return (
        <div
            style={{
                border: '1px solid gray',
                backgroundColor: 'gray',
                marginTop: '1%',
                marginBottom: '1%',
                paddingTop: '1%',
                paddingBottom: '1%',
                paddingLeft: '0.6cm',
            }}
        >
            <div>{props.name}</div>
            <div>{props.desc}</div>
            {/* TODO: implement img <div>{props.icon}</div> */}
            {/* TODO: this isn't necessary actually... <div>{props.type}</div> */}
            <div>{props.price}</div>

            <ProductCard {...props} />
        </div>
    )
}

export default ProductPreview
