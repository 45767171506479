import { createCachedSlice } from '../cache/createCachedSlice.js'
import {
    addNewMessage,
    messageLoadResponse,
    openBlockedModalMenu,
    searchResultsClient,
    topLoadClient,
    updateThreadOrder,
} from './actions'
import { updateCacheVersions } from './cacheMonitor.js'
import { current } from '@reduxjs/toolkit'
import { parseMessage } from "../../Components/Chat/ChatWindow/_lib/messageParser";

const fullParse = (message) => {
    let messagePre = { ...message }
    messagePre.data = parseMessage(messagePre.message)
    messagePre.likedByUser = message?.reply?.likedBy ?
        message.reply.likedBy.includes(window.userId) :
        false
    return messagePre
}

const message = createCachedSlice({
    name: 'messages',
    initialState: {},
    reducers: {
        addMessage: {
            cached: false,
            reduce: (state, action) => {
                const { id } = action.payload
                return {
                    ...state,
                    [id]: action.payload,
                }
            },
        },
        replaceMessage: {
            cached: false,
            reduce: (state, action) => {
                const { message } = action.payload
                const newState = { ...state }
                newState[message._id] = fullParse(message)
                return newState
            },
        },
        addMessages: {
            cached: false,
            reduce: (state, action) => {
                let newState = { ...state }
                for (let key in action.payload) {
                    if (action.payload.hasOwnProperty(key)) {
                        newState[key] = fullParse(action.payload[key])
                    }
                }
                return newState
            },
        },
        deleteMessage: {
            cached: false,
            reduce: (state, action) => {
                let id = action.payload.messageId
                let message = { ...state[id] }
                if (!(typeof message === 'undefined' || message === null)) {
                    message.message = '- deleted message -'
                    message.deleted = true
                    if (action.payload.replies.length > 0) {
                        let return_data = {}
                        for (const item of action.payload.replies) {
                            return_data[item] = { ...state[item] }
                            return_data[item].reply = { ...state[item].reply }
                            if (
                                typeof return_data[item] != 'undefined' ||
                                return_data[item] != null
                            ) {
                                return_data[item].reply.message =
                                    ' - message deleted -'
                                return_data[item].reply.deleted = true
                            }
                        }
                        return {
                            ...state,
                            ...return_data,
                            [id]: message,
                        }
                    } else {
                        return {
                            ...state,
                            [id]: message,
                        }
                    }
                } else {
                    return { ...state }
                }
            },
        },
        editMessage: {
            cached: false,
            reduce: (state, action) => {
                let id = action.payload.messageId
                let message = { ...state[id] }
                if (!(typeof message === 'undefined' || message === null)) {
                    message.message = action.payload.message
                    message.edited = true
                    if (action.payload.replies.length > 0) {
                        let return_data = {}
                        for (const item of action.payload.replies) {
                            return_data[item] = { ...state[item] }
                            return_data[item].reply = { ...state[item].reply }
                            if (
                                typeof return_data[item] != 'undefined' ||
                                return_data[item] != null
                            ) {
                                return_data[item].reply.message =
                                    action.payload.message
                                return_data[item].reply.edited = true
                            }
                        }
                        return {
                            ...state,
                            ...return_data,
                            [id]: fullParse(message),
                        }
                    } else {
                        return {
                            ...state,
                            [id]: fullParse(message),
                        }
                    }
                } else {
                    return { ...state }
                }
            },
        },
    },
    extraReducers: {
        [addNewMessage]: {
            cached: false,
            keyOrder: ['serversId', 'channelId'],
            keyContainer: (action) => {
                return {
                    serverId: action.payload.message.sId,
                    channelId: action.payload.message.cId,
                }
            },
            reduce: (state, action) => {
                const id = action.payload.message._id
                const { threadOrder, threadHeadUpdate } = action.payload
                let correction = {}
                if (threadHeadUpdate) {
                    const threadHead = { ...state[threadHeadUpdate.threadId] }
                    const metaData = { ...threadHead.metaData }
                    metaData.threadReplies = threadHeadUpdate.replies
                    if (threadHead.hasOwnProperty('lastReplyDate')) {
                        metaData.threadDate = threadHead.lastReplyDate
                    }
                    threadHead.metaData = metaData
                    correction[threadHead._id] = threadHead
                }
                if (threadOrder) {
                    const channelId = action.payload.message.cId
                    action.asyncDispatch(
                        updateThreadOrder({
                            channelId,
                            threadOrder,
                        })
                    )
                    return {
                        ...state,
                        ...correction,
                        [id]: fullParse(action.payload.message),
                    }
                } else {
                    return {
                        ...state,
                        [id]: fullParse(action.payload.message),
                    }
                }
            },
        },
        [topLoadClient]: {
            cached: false,
            reduce: (state, action) => {
                const { list } = action.payload
                let newState = { ...state }
                list.forEach((message) => {
                    newState[message._id] = fullParse(message)
                })
                return newState
            },
        },
        [searchResultsClient]: {
            cached: false,
            reduce: (state, action) => {
                const { list } = action.payload
                let newState = { ...state }
                list.forEach((message) => {
                    newState[message._id] = fullParse(message)
                })
                return newState
            },
        },
        [messageLoadResponse]: {
            cached: false,
            reduce: (state, action) => {
                const { list } = action.payload
                let newState = { ...state }
                list.forEach((message) => {
                    newState[message._id] = fullParse(message)
                })
                return newState
            },
        },
    },
})

export const messagesActions = message.actions
export const addMessages = message.actions.addMessages
export const addMessage = message.actions.addMessage
// export const editMessage = message.actions.editMessage
export const messages = message.reducer
export const messagesSlice = message
