import React, { useState } from 'react'
import HSInput from '../../_reusable/Inputs/HSInput.js'
import Button from '@mui/material/Button'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth.js'
import { useHistory } from 'react-router'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { StyledDiv, TypographyCustom } from '../../Chat/Styled'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { signInSuccessUrl } from "../url";
import { SIGNIN, SIGNUP } from "../../../constants/env";
import { ButtonCustom } from "../../Chat/Styled";
import { InputLabel } from '@mui/material'


const uiConfig = {
    // Popup sign-in flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    // signInSuccessUrl: signInSuccessUrl
}

const Login = function (props) {
    const history = useHistory()
    const [formState, setFormState] = useState({})
    const [errorData, setErrorData] = useState({})
    const fieldChange = props.fieldChangeCreator(setFormState, formState)
    const [loginError, setLoginError] = useState('')

    function lostPassword() {
        if (props.auth.auth) {
            props.API('login')(formState)
        }
    }

    function firebaseLogin(e) {
        e.preventDefault()
        props.authFunctions.login(
            formState.email,
            formState.password,
            () => {
                window.location = signInSuccessUrl
            },
            (err) => {
                console.log(err.code)
                switch (err.code) {
                    case "auth/invalid-email":
                        setErrorData({
                            errorStatus: () => () => true,
                            errorData: () => () => "Email and password combination is incorrect."
                        })
                        break;
                    case "auth/user-not-found":
                        setErrorData({
                            errorStatus: () => () => true,
                            errorData: () => () => "Email and password combination is incorrect."
                        })
                        break;
                    case "auth/wrong-password":
                        setErrorData({
                            errorStatus: () => () => true,
                            errorData: () => () => "Email and password combination is incorrect."
                        })
                        break;
                    case "auth/too-many-requests":
                        setErrorData({
                            errorStatus: () => () => true,
                            errorData: () => () => "Too many failed sign-in attempts. Please try again shortly."
                        })
                        break;
                }
            }
        )
    }

    let inputProps = {
        fieldChange: fieldChange,
        errorData: props.errorData,
        errorStatus: props.errorStatus,
        method: 'login',
    }
    return (
        <StyledDiv types={['signupFlexboxOuter', 'signupContainer']}>
            <StyledDiv>
                <TypographyCustom variant="h4" component="h4">
                    Sign in
                </TypographyCustom>
            </StyledDiv>

            <StyledDiv types={['signupFirebaseButtons']}>
                <div style={{ textAlign: 'left' }}>
                    <StyledFirebaseAuth
                        uiConfig={uiConfig}
                        firebaseAuth={firebase.auth()}
                    />
                </div>
            </StyledDiv>

            <StyledDiv>
                <form
                    onSubmit={(e) => {
                        firebaseLogin(e)
                    }}>
                    <HSInput
                        id={'email'}
                        typographyLabel={'Email'}
                        label={'Email'}
                        {...{ ...inputProps, ...errorData }}
                    />

                    <HSInput
                        id={'password'}
                        typographyLabel={'Password'}
                        label={'Password'}
                        type={'password'}
                        {...inputProps}
                    />
                </form>

            </StyledDiv>
            <StyledDiv types={['signupFlexboxOuter', 'signupButtons']}>

                <StyledDiv types={[['signupAdjacentButtons']]}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => firebaseLogin(e)}>
                        {SIGNIN}
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            props.changePage('onboard')
                        }}>
                        {SIGNUP}
                    </Button>
                </StyledDiv>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        props.changePage('lostPassword')
                    }}
                    style={{
                        width: 'fit-content'
                    }}>
                    Forgot password
                </Button>

                <ButtonCustom
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                        window.location = window.location.origin + "/"
                    }}
                >
                    Cancel
                </ButtonCustom>

            </StyledDiv>
        </StyledDiv>
    )
}

export default Login
