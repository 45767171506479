import React from 'react'
import {connect} from 'react-redux'
import {ListItemCustom, ListItemTextCustom} from '../../../Styled'
import {withAS} from "../../../../_contexts/withAppState";
import {dispatchMappers} from "../../../../../redux/process/actionProcess";
import {RA1, RS1} from "../../../../../redux/actions";
import {statePropMapper} from "../../../../../redux/process/selectorProcess";


const mapDispatchToProps = dispatchMappers([RA1.notifications.clearNotifications])
const makeMapStateToProps = statePropMapper([])

const DirectMessageLink = function (props) {
    const handleClick = () => {
        props.AS.nav.ops.setPage('dm')
        props.AS.nav.ops.setDMData({
            otherUserId: props.otherUserId,
            channel: props.channel,
            profileCheck: false
        })
        props.dispatch.notifications.clearNotifications(props.channel)
    }
    return (
        <ListItemCustom
            // types={["channel", "selectable"]}
            types={['channelActive']}
            key={props.channel}
            button
            listitemid={props.channel}
            activeid={props.AS.nav.state?.dmData?.channel}
            onClick={handleClick}
        >
            <ListItemTextCustom
                id={"DML" + props.dynamicIndex}
                primary={props?.otherUserData?.userName}
                secondary={props.relationshipData.status}
            />
        </ListItemCustom>
        //types={[channelType]}
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(DirectMessageLink))
