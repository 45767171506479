import { useState, useEffect } from "react"

// >>reference
// >greentext/quote
// !!!header
// `code`
// {link:http://google.com}

// \> -ignored, backslash is not added

// latex?

export const Parser = (props) => {

    const [parsedText, setParsedText] = useState([])

    // for each line:
    // greentexts begin a line with > and end with a newline.
    // headers begin a line with !!! and end with !!! and a newline.

    // for each character:
    // code begins and ends with ` & every symbol inside is ignored.
    // links begin with {, then have some text, then at the first colon the url begins. At the first } the url ends.
    // references begin at any point with >> and end with any whitespace.
    const parseLines = (lines) => {

        let parsedLines = []

        lines.forEach(line => {

            if (line.slice(0, 3) === '!!!' && line.slice(line.length - 3, line.length) === '!!!') {
                // header things
            }
            else if (line.slice(0, 2) !== '>>') {

            }


        })
    }

    const parseTokens = (text) => {
        
        text.forEach(token => {})

    }

    useEffect(() => {
        let text = props.text.split('')
        parseTokens(text)
        setParsedText(text)
    }, [])
    return (
        <div>
            {props.text}
        </div>
    )
}