import { createCachedSlice } from '../cache/createCachedSlice.js'

const thread = createCachedSlice({
    name: 'threads',
    initialState: {},
    reducers: {
        updateThreadOrder: {
            cached: false,
            parameters: ['channelId', 'threadOrder'],
            description: '',
            reduce: (state, action) => {
                const { channelId, threadOrder } = action.payload
                let oldServerState = { ...state[channelId] }
                oldServerState.activeThreads = threadOrder
                return {
                    ...state,
                    [channelId]: oldServerState,
                }
            },
        },
        addThread: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                //Payload arguments
                const { id, content } = action.payload
                return {
                    ...state,
                    [id]: action.payload,
                }
            },
        },
        addThreads: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                //Payload arguments
                let newState = { ...state }
                for (let key in action.payload) {
                    if (action.payload.hasOwnProperty(key)) {
                        newState[key] = action.payload[key]
                    }
                }
                return newState
            },
        },
    },
})

const threadSlice = thread
const threadActions = thread.actions
const addThreads = thread.actions.addThreads
const threads = thread.reducer
export { threadSlice, addThreads, threadActions, threads }
