import React, { useEffect, useState } from 'react'
import 'react-contexify/dist/ReactContexify.css'
import { statePropMappers } from '../../../redux/process/selectorProcess.js'
import { withAuth } from '../../_contexts/withAuth/index.js'
import { dispatchMappers } from '../../../redux/process/actionProcess.js'
import { SA1 } from '../../../hs/requestGlobals.mjs'
import { hsDebounce } from '../../../redux/cache/index.js'
import { RA1 } from '../../../redux/actions.js'
import { connect } from 'react-redux'
const { postFileAPI } = require('../../../filehost_microservice_api/index.js')

const makeMapStateToProps = statePropMappers(
    {
        getChatInput: ['chatInput', []],
    },
    {
        _appState: 'appState',
        _loaded: '_loaded',
    }
)
const mapDispatchToProps = dispatchMappers([
    SA1.addMessage,
    SA1.editMessage,
    SA1.createPaidMessage,
    RA1.chatInputs.clear,
    hsDebounce(RA1.chatInputs.addChatInput, 1000),
])

const withChatMessaging = function (WrappedComponent) {
    let Wrapper = function (props) {
        const [freezeAction, setFreezeAction] = useState(false)
        const submitPaidMessageWrapped = function (
            message,
            reply,
            fileToken,
            { name, description, icon, type, price },
            isNewThread = false,
            threadId = 'none'
        ) {
            props.dispatch.server.createPaidMessage(
                props.server,
                props.channel,
                message,
                fileToken,
                reply,
                name,
                description,
                icon,
                type,
                price
            )
            props.dispatch.chatInputs.clear(props.channel)
        }
        const submitMessageWrapped = function (
            message,
            reply,
            fileToken,
            isNewThread = false,
            threadId = 'none',
            threadTitle = '',
            blogPost
        ) {
            props.dispatch.server.addMessage(
                props.server,
                props.channel,
                message,
                fileToken,
                reply,
                isNewThread,
                threadId,
                threadTitle,
                props.writeToProfile,
                props.writeToThread,
                blogPost
            )
            props.dispatch.chatInputs.clear(props.channel)
            return null
        }
        const editMessageWrapped = function (sId, mId, message) {
            props.dispatch.server.editMessage(sId, mId, message)
            props.dispatch.chatInputs.clear(props.channel)
        }
        const { dispatch, ...other } = props
        const handleChangeWrapped = function (message, setInputValue) {
            setInputValue(message)
            props.dispatch.chatInputs.addChatInput(props.channel, message)
        }
        return (
            <WrappedComponent
                handleChangeWrapped={handleChangeWrapped}
                submitPaidMessageWrapped={submitPaidMessageWrapped}
                submitMessageWrapped={submitMessageWrapped}
                editMessageWrapped={editMessageWrapped}
                freezeAction={freezeAction}
                {...other}
            />
        )
    }
    return connect(makeMapStateToProps, mapDispatchToProps)(withAuth(Wrapper))
}

export default withChatMessaging
