import React from 'react'
import {withAS} from "../../../../_contexts/withAppState";

const ProfileNavFooter = function (props) {

    return (
        <div>
            {/*<div style={{width: '48px'}}></div>*/}
        </div>
    )
}
export default withAS(ProfileNavFooter)
