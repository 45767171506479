import React from 'react'
import { connect } from 'react-redux'
import { statePropMappers } from '../../../../../redux/process/selectorProcess.js'
import { dispatchMappers } from '../../../../../redux/process/actionProcess.js'
import { RA1 } from '../../../../../redux/actions.js'
import { ListItemIcon, ListItemText } from '@mui/material'
import { ListItemCustom, ListItemIconCustom, ListItemTextCustom } from '../../../Styled'
import { withAS } from "../../../../_contexts/withAppState";
import useState from "react-usestateref";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import LayersIcon from '@mui/icons-material/Layers';
import { useTheme } from '@emotion/react'

const mapDispatchToProps = dispatchMappers([
    RA1._appState.openModalMenu,
])

const makeMapStateToProps = statePropMappers(
    {
        getChannel: ['channelMeta', []],
        getDMChannelPlusUserData: ['dataDM', []],
    },
    {
        _loaded: '_loaded',
        _appState: '_appState',
    }
)

const ChannelLink = function (props) {
    const theme = useTheme()
    const [threadIconHovered, setThreadIconHovered] = useState(false)
    if (props.profile === true) {
        return (<ListItemCustom
            // types={["channel", "selectable"]}
            types={['profileBtn', 'channelActive']}
            key={"profileButtonTwa"}
            button
            onClick={() => null}
            listitemid={"profile11"}
            activeid={"profile22"}
        >
            <ListItemTextCustom
                id={"profileButtonText"}
                types={['profileButtonText']}
                primary={"Profile"}
                secondary={"Community Profile"}
            />
        </ListItemCustom>)
    }
    const handleClick = () => {
        props.AS.nav.ops.channelButtonClicked(props.channel, threadIconHovered)
    }

    let channelType = 'channelLinkUnselected'
    if (props.channel === props.AS.activeChannel) {
        channelType = 'channelLinkSelected'
    }
    return (
        <ListItemCustom
            // types={["channel", "selectable"]}
            types={['channelActive']}
            key={props.channel}
            button
            onClick={handleClick}
            listitemid={props.channel}
            premium={""+props.channelMeta.channelMeta.premium}
            activeid={props.AS.activeChannel}>
            <ListItemTextCustom
                id={props.channel}
                primary={props.channelMeta.channelName.trim().length ? props.channelMeta.channelName : "-"}
                secondary={props.channelMeta.channelTopic}
                types={[channelType]}
            />
            <ListItemIconCustom
                types={['channel']}>
                {props.channelMeta.channelMeta.premium ?
                    <WorkspacePremiumIcon size={"small"}
                        style={{
                            color:
                                (props.channel === props.AS.activeChannel) ?
                                    theme.palette.text.primary :
                                    theme.palette.text.secondary
                        }} /> :
                    null}
                <LayersIcon
                    onMouseEnter={() => setThreadIconHovered(true)}
                    onMouseLeave={() => setThreadIconHovered(false)}
                    size={"small"} />
            </ListItemIconCustom>
        </ListItemCustom>
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(ChannelLink))
