export const getImages = ({props}) => (imgidx) => {
    let images = []
    let imgIndex = 0
    props.threadMsgs.map((message, index) => {
        if (message?.files?.length !== 0) {
            if (imgidx > index) {
                imgIndex += 1;
            }
            message.files.forEach((file) => {
                images.push(message.files)
            })
        }
    })
    return {images, imgIndex}
}