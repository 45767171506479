import React from 'react'
import {connect} from 'react-redux'
import {RA1, RS1} from '../../../../redux/actions.js'
import {SA1} from '../../../../hs/requestGlobals.mjs'
import {dispatchMappers} from '../../../../redux/process/actionProcess.js'
import SocketForm from '../../../_reusable/SocketForm/SocketForm'
import {statePropMapper} from '../../../../redux/process/selectorProcess'

const makeMapStateToProps = statePropMapper([
    RS1.getServer('serverData'),
    RS1.getCalendar('calendar'),
])
const mapDispatchToProps = dispatchMappers([
    RA1._appState.closeModalMenu,
    SA1.gptAppointment,
])

const GPTAppointmentModal = function (props) {
    const dispatchMethod = ({nextInput}) => {
        props.setProgress(true)
        props.dispatch.server.gptAppointment(
            props.AS.activeServer,
            nextInput,
            [],
            'gptAppointment',
            'gptAppointment'
        )
    }
    let formFields = [
        {
            id: 'nextInput',
            typographyLabel: 'lorem ipsum'
        },
    ]

    return (
        <SocketForm
            socketForm
            formMethod={'gptAppointment'}
            formDispatch={dispatchMethod}
            onClose={props.closeModalMenu}
            onSuccess={() => props.setProgress(false)}
            onError={() => props.setProgress(false)}
            description={'ChatGPT Request Test'}
            formFields={formFields}
            formButtons={[
                {label: 'Send', icon: 'add', isSubmitButton: true}, {label: 'Cancel', isCloseButton: true}
            ]}
            formTypography={{label: 'ChatGPT Calendar Management'}}
            successMessage={'triggered'}
        />
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(GPTAppointmentModal)
