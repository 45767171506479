import React, { useEffect, useState } from 'react'
import withMaterialDnD from '../../_wrappers/withMaterialDnD'
import CalendarRow from './CalendarLink'
import GenericCard from '../../_reusable/Cards/UniversalCard'
import { StyledDiv, TypographyCustom, ButtonCustom } from '../Styled'
import { connect } from 'react-redux'
import { statePropMapper } from '../../../redux/process/selectorProcess'
import { RA1, RS1 } from '../../../redux/actions'
import { dispatchMappers } from '../../../redux/process/actionProcess'
import { SA1 } from '../../../hs/requestGlobals.mjs'
import SimpleChat from "../SimpleChat";
import { Fab } from '@mui/material'

import ChatIcon from '@mui/icons-material/Chat';
import ListIcon from '@mui/icons-material/List';
import AddIcon from "@mui/icons-material/Add";

const makeMapStateToProps = statePropMapper([RS1.getCalendar('calendar'), RS1.store("_userId", "_userId")])
const mapDispatchToProps = dispatchMappers([
    RA1._appState.openModalMenu,
    RA1.calendar.setSelectedAppointment,
    SA1.getAppointments,
])

function padTo2Digits(num) {
    return num.toString().padStart(2, '0')
}

function formatDate(date) {
    return [
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
        date.getFullYear(),
    ].join('/')
}

const AppointmentListCard = (props) => {
    let appointments = props?.dates

    return <GenericCard
        // appointments={appointments}
        dates={props?.dates}

        sortByTitle={props.sortByTitle}
        calendar={props.calendar}
        productId={props?.productId}
        gptTag={props?.gptTag}
        day={props?.day}

        server={props?.server}

        adminViewMode={props.adminViewMode}
        openModalMenu={props?.openModalMenu}

        setHoverAppointment={props?.setHoverAppointment}
        setSelectedAppointment={props?.setSelectedAppointment}

        cardType={'appointment'}
        displayMode={'fullTotalDesktop'}
        cardTestMode={false}
    />
}

// const CalendarList = withMaterialDnD()
// const CalendarList = withMaterialDnD(CalendarRow)
const CalendarList = withMaterialDnD(AppointmentListCard)
const CalendarChat = function (props) {

    const [chatView, setChatView] = useState(false)

    const [adminSelectedAppointment, setAdminSelectedAppointment] =
        useState(true)
    const [adminViewMode, setAdminViewMode] = useState(props.isOwner)
    const [filter, setFilter] = useState('')
    const [hoverAppointment, setHoverAppointment] = useState(true)
    const [appointmentList, setAppointmentList] = useState([])
    const openModalMenu = props.dispatch._appState.openModalMenu
    const setSelectedAppointment = (...args) => {
        if (adminViewMode) {
            props.dispatch.calendar.setSelectedAppointment(...args)
            setAdminSelectedAppointment(...args)
        } else {
            props.dispatch.calendar.setSelectedAppointment(...args)
        }
    }

    const createAppointmentList = (filter) => {
        let appointmentData = Object.keys(props.calendar.appointmentsByDay).map(
            (key) => {
                let formattedDayData = { dates: [], uniqueDayLabel: key }
                props.calendar.appointmentsByDay[key].map((timestamp) => {
                    let appointment = {
                        ...props.calendar.appointmentsByInitTimestamp[
                        timestamp
                        ],
                    }
                    let appointmentDate = new Date(timestamp)
                    let appointmentEndTimestamp = appointment.length + timestamp
                    let appointmentEnd = new Date(appointmentEndTimestamp)
                    let otherOptions = {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                    }
                    let options = { hour: '2-digit', minute: '2-digit' }
                    appointment.startTimeFormatted =
                        appointmentDate.toLocaleTimeString('en-us', options)
                    let status = 'default'
                    if (adminViewMode) {
                        if (appointment.booked && appointment.approved) {
                            status = 'approved'
                        }
                        if (appointment.booked && !appointment.approved) {
                            status = 'pending'
                        }
                    }
                    appointment.endTimeFormatted =
                        appointmentEnd.toLocaleTimeString('en-us', options)
                    let x = appointmentDate
                        .toLocaleTimeString('en-us', otherOptions)
                        .split(',')
                    formattedDayData.day =
                        x[0] + x[1] + ' (' + formatDate(appointmentDate) + ')'
                    formattedDayData.weekday = x[0]
                    formattedDayData.month = x[1]
                    formattedDayData.calendarDate = formatDate(appointmentDate)
                    switch (filter) {
                        case 'default':
                            if (status === 'default') {
                                formattedDayData.dates.push({
                                    id: appointment.startTimeFormatted,
                                    appointment: appointment,
                                    status: status,
                                    date: appointmentDate,
                                })
                            }
                            break
                        case 'pending':
                            if (status === 'pending') {
                                formattedDayData.dates.push({
                                    id: appointment.startTimeFormatted,
                                    appointment: appointment,
                                    status: status,
                                    date: appointmentDate,
                                    weekday: x[0],
                                    month: x[1],
                                    calendarDate: formatDate(appointmentDate),
                                })
                            }
                            break
                        case 'approved':
                            if (status === 'approved') {
                                formattedDayData.dates.push({
                                    id: appointment.startTimeFormatted,
                                    appointment: appointment,
                                    status: status,
                                    date: appointmentDate,
                                    weekday: x[0],
                                    month: x[1],
                                    calendarDate: formatDate(appointmentDate),
                                })
                            }
                            break
                        case 'complete':
                            if (status === 'complete') {
                                formattedDayData.dates.push({
                                    id: appointment.startTimeFormatted,
                                    appointment: appointment,
                                    status: status,
                                    date: appointmentDate,
                                    weekday: x[0],
                                    month: x[1],
                                    calendarDate: formatDate(appointmentDate),
                                })
                            }
                            break
                        default:
                            if (!adminViewMode) {
                                if (!appointment.booked && !appointment.approved) {
                                    formattedDayData.dates.push({
                                        id: appointment.startTimeFormatted,
                                        appointment: appointment,
                                        status: status,
                                        date: appointmentDate,
                                        weekday: x[0],
                                        month: x[1],
                                        calendarDate: formatDate(appointmentDate),
                                    })
                                } else {
                                    if (appointment.bookedByUserId === props._userId) {
                                        formattedDayData.dates.push({
                                            id: appointment.startTimeFormatted,
                                            appointment: appointment,
                                            status: 'approved',
                                            date: appointmentDate,
                                            weekday: x[0],
                                            month: x[1],
                                            calendarDate: formatDate(appointmentDate),
                                        })
                                    }
                                }
                            } else {
                                formattedDayData.dates.push({
                                    id: appointment.startTimeFormatted,
                                    appointment: appointment,
                                    status: status,
                                    date: appointmentDate,
                                    weekday: x[0],
                                    month: x[1],
                                    calendarDate: formatDate(appointmentDate),
                                })
                            }
                            break
                    }
                })
                return formattedDayData
            }
        )
        setAppointmentList(appointmentData)
    }
    useEffect(() => {
        props.dispatch.server.getAppointments(props.serverId)
    }, [])
    useEffect(() => {
        if (props.calendar?.requiresReload) {
            props.dispatch.server.getAppointments(props.serverId)
        }
        if (props.calendar?.loaded) {
            createAppointmentList(filter)
        }
    }, [props.calendar, adminViewMode, filter])

    useEffect(() => {
        // if (props.calendar?.requiresReload) {
        // props.dispatch.server.getAppointments(props.serverId)
        // }
        // if (props.calendar?.loaded) {
        //     createAppointmentList(filter)
        // }
    }, [props.calendar])
    return (
        <StyledDiv types={['calendarContainer']} chatView={chatView}>
            <StyledDiv types={['calendarContent']}>
                <StyledDiv types={['calendarContentLeft']}>
                    <StyledDiv types={['calendarContentLeftList']} chatView={chatView}>
                        {chatView ?
                            <SimpleChat serverId={props.serverId}
                                setChatView={setChatView}
                                chatView={chatView}
                                simpleChat={true} />
                            : <StyledDiv types={['calendarContentScrollable']}>
                                <CalendarList
                                    list={appointmentList}
                                    dynamicProps={{
                                        adminViewMode,
                                        openModalMenu,
                                        listSize: appointmentList.length,
                                        setSelectedAppointment,
                                        setHoverAppointment,
                                        calendar: props.calendar,
                                        sortByTitle: false,
                                        server: props.serverId,
                                    }}
                                    droppableId={'calendarList'}
                                    menuId={'calendarListMenu'}
                                    useDnD={false} />
                            </StyledDiv>
                        }
                        {!props?.isOwner ? null :
                            <StyledDiv style={{
					   position: 'absolute',
					   bottom: (chatView) ? '1em' : '2em',
					   right: 'calc(2em - 2px)',
					   display: 'flex',
					   flexDirection: 'column',
					   // justifyContent: 'flex-end',
					   alignItems: 'flex-end',
				       }}>
                                <Fab
                                    color="primary"
                                    aria-label="add"
                                    size="small"
                                    style={{ marginBottom: '1em' }}
                                    onClick={() => props.openModalMenu('createAppointment')}
                                >
                                    <AddIcon />
                                </Fab>
                                <Fab
                                    color="primary"
                                    aria-label="add"
                                    size="small"
                                    style={{}}
                                    onClick={() => setChatView(!chatView)}
                                >
                                    {chatView ? <ListIcon /> : <ChatIcon />}
                                </Fab>
                            </StyledDiv>
                        }
                    </StyledDiv>
                </StyledDiv>
            </StyledDiv>
        </StyledDiv >
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(CalendarChat)
