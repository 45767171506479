import { createCachedSlice } from '../cache/createCachedSlice.js'
import { checkPartialEquality } from '../../Components/Chat/Util'
import { permGlobals } from '../../hs/permGlobals'
import {
    addChatServer,
    addChatServerPartial,
    modifyChatServer,
} from './chatServers'
import { blockModalRoleStruct, initManagement } from './actions'

const buildChannelPermTemplate = function () {
    let newChannelPermTemplate = {}
    for (const key in permGlobals) {
        if (permGlobals.hasOwnProperty(key)) {
            if (permGlobals[key].permType === 'channel') {
                if (!permGlobals[key].globalScope) {
                    newChannelPermTemplate[key] = permGlobals[key]
                }
            }
        }
    }
    return newChannelPermTemplate
}

const buildRoleStruct = function (
    _abstractRoles,
    serverData,
    _permDescriptions
) {
    let abstractRoles = { ..._abstractRoles }
    let roles = [...serverData.roles]
    let permDescriptions = { ..._permDescriptions }
    let rolePerms = {}
    let scopedRolesChanged = {}
    let roleDict = {}
    let permArray = []
    let role = {}
    let temp = []
    roles.map((roleId) => {
        scopedRolesChanged[roleId] = false
    })
    roles.map((roleId) => {
        permArray = []
        role = abstractRoles[roleId]
        roleDict[roleId] = role
        //We convert role permissions into an array
        let keys = Object.keys(role.perms)
        keys.forEach(function (key) {
            permArray.push([key, role.perms[key]])
        })
        //We use this array to generate the permission selector component.
        let permName = ''
        temp = permArray.map((permission) => {
            if (permDescriptions[permission[0]]['mutablePerm']) {
                permName = permission[0]
                return {
                    name: permDescriptions[permission[0]]['name'],
                    description: permDescriptions[permission[0]]['description'],
                    permName: permission[0],
                    value: role.perms[permName],
                    type: permDescriptions[permission[0]]['type'],
                    roleId: roleId,
                }
            }
        })
        rolePerms[roleId] = temp.filter(function (x) {
            return x !== undefined
        })
    })
    return {
        roleDict: roleDict,
        rolePerms: rolePerms,
        rolesChanged: scopedRolesChanged,
    }
}

const managementCachedSlice = createCachedSlice({
    name: 'management',
    initialState: {},
    reducers: {
        clearRoleOrderData: {
            cached: false,
            parameters: ['serverId'],
            description: '',
            reduce: (state, action) => {
                let { serverId } = action.payload
                let managementState = { ...state[serverId] }
                managementState.modifiedRoleLinks = null
                managementState.rolesModified = false
                return { ...state, [serverId]: managementState }
            },
        },
        setSelectedRole: {
            cached: false,
            parameters: ['serverId', 'selectedRole'],
            description: '',
            reduce: (state, action) => {
                let { selectedRole, serverId } = action.payload
                let managementState = { ...state[serverId] }
                managementState.selectedRole = selectedRole
                return { ...state, [serverId]: managementState }
            },
        },
        setManagementPage: {
            cached: false,
            parameters: ['serverId', 'page'],
            description: '',
            reduce: (state, action) => {
                let { page, serverId } = action.payload
                let managementState = { ...state[serverId] }
                managementState.managementPage = page
                return { ...state, [serverId]: managementState }
            },
        },
        buildOldChannelPermissions: {
            cached: false,
            parameters: ['serverId', 'channelData'],
            description: '',
            reduce: (state, action) => {
                let { channelData, serverId } = action.payload
                let managementState = { ...state[serverId] }
                let { channelPermTemplate } = managementState
                let oldChannelPermissionsTemp = {}
                let channelPermissionChangesTemp = {}
                for (const key in channelPermTemplate) {
                    if (channelPermTemplate.hasOwnProperty(key)) {
                        oldChannelPermissionsTemp[key] = channelData[key]
                        channelPermissionChangesTemp[key] = {}
                    }
                }
                managementState.oldChannelPermissions =
                    oldChannelPermissionsTemp
                managementState.newChannelPermissions = {
                    ...oldChannelPermissionsTemp,
                }
                managementState.channelPermissionChanges =
                    channelPermissionChangesTemp
                return { ...state, [serverId]: managementState }
            },
        },
        modifyRoleStruct: {
            cached: false,
            parameters: [
                'changeValue',
                'roleId',
                'permName',
                'type',
                'index',
                'serverId',
            ],
            description: '',
            reduce: (state, action) => {
                let { changeValue, roleId, permName, type, index, serverId } =
                    action.payload
                let managementState = { ...state[serverId] }
                let { roleStructModified } = managementState
                let scopedRoleStruct = JSON.parse(
                    JSON.stringify(roleStructModified)
                )
                let scopedRolesChanged = JSON.parse(
                    JSON.stringify(roleStructModified.rolesChanged)
                )
                scopedRolesChanged[roleId] = true
                switch (type) {
                    case 'string':
                        scopedRoleStruct.rolesChanged = scopedRolesChanged
                        scopedRoleStruct.roleDict[roleId].perms[permName] =
                            changeValue
                        scopedRoleStruct.rolePerms[roleId][index].value =
                            changeValue
                        break
                    case 'bool':
                        scopedRoleStruct.roleDict[roleId].perms[permName] =
                            !scopedRoleStruct.roleDict[roleId].perms[permName]
                        scopedRoleStruct.rolePerms[roleId][index].value =
                            !scopedRoleStruct.rolePerms[roleId][index].value
                        scopedRoleStruct.rolesChanged = scopedRolesChanged
                        break
                    case 'color':
                        scopedRoleStruct.rolesChanged = scopedRolesChanged
                        scopedRoleStruct.roleDict[roleId].perms[permName] =
                            changeValue
                        scopedRoleStruct.rolePerms[roleId][index].value =
                            changeValue
                        break
                    default:
                        break
                }
                managementState.roleStructModified = scopedRoleStruct
                managementState.rolesChanged = true
                //action.asyncDispatch(blockModalRoleStruct({roleStruct: scopedRoleStruct}))
                return { ...state, [serverId]: managementState }
            },
        },
        changeChannelPermissions: {
            cached: false,
            parameters: ['serverId', 'perm', 'role'],
            description: '',
            reduce: (state, action) => {
                let { serverId, perm, role } = action.payload
                let managementState = { ...state[serverId] }
                let { channelPermissionChanges, newChannelPermissions } =
                    managementState
                let channelPermissionChangesLocal = {
                    ...channelPermissionChanges,
                }
                let newChannelPermissionsTemp = { ...newChannelPermissions }
                let newChannelPermissionsForRole = [
                    ...newChannelPermissionsTemp[perm],
                ]
                if (!newChannelPermissionsForRole.includes(role)) {
                    newChannelPermissionsForRole.push(role)
                    const old = { ...channelPermissionChangesLocal[perm] }
                    old[role] = 'add'
                    channelPermissionChangesLocal[perm] = old
                } else {
                    newChannelPermissionsForRole =
                        newChannelPermissionsForRole.filter(
                            (item) => item !== role
                        )
                    const old = { ...channelPermissionChangesLocal[perm] }
                    old[role] = 'remove'
                    channelPermissionChangesLocal[perm] = old
                }
                newChannelPermissionsTemp[perm] = newChannelPermissionsForRole
                managementState.newChannelPermissions =
                    newChannelPermissionsTemp
                managementState.channelPermissionChanges =
                    channelPermissionChangesLocal
                return { ...state, [serverId]: managementState }
            },
        },
        handleGeneralRoleDrag: {
            cached: false,
            parameters: ['serverId', 'result', 'topUserRank'],
            description: '',
            reduce: (state, action) => {
                let { serverId, result, topUserRank } = action.payload
                let managementState = { ...state[serverId] }
                let { roleLinks } = managementState
                if (!result.destination) return
                const items = Array.from(roleLinks)
                const [reorderedItem] = items.splice(result.source.index, 1)
                items.splice(result.destination.index, 0, reorderedItem)
                const reorderedRoles = items.map((item) => item.id)
                const oldRoleOrder = roleLinks.map((item) => item.id)
                const rolesLength = oldRoleOrder.length
                const validMove = checkPartialEquality(
                    reorderedRoles,
                    oldRoleOrder,
                    topUserRank
                )
                if (
                    validMove &&
                    reorderedRoles[rolesLength - 1] ===
                        oldRoleOrder[rolesLength - 1]
                ) {
                    managementState.modifiedRoleLinks = items
                    managementState.rolesModified = true
                }
                return { ...state, [serverId]: managementState }
            },
        },
        initManagement: {
            cached: false,
            parameters: ['serverId'],
            description: '',
            reduce: (state, action) => {
                const newState = { ...state }
                const { serverId } = action.payload
                if (!newState.hasOwnProperty(serverId)) {
                    const {
                        abstractRoles,
                        serverData,
                        _permDescriptions,
                        userMeta,
                    } = action.payload.asyncExtra
                    const roleStruct = buildRoleStruct(
                        abstractRoles,
                        serverData,
                        _permDescriptions
                    )
                    const newChannelPermTemplate = buildChannelPermTemplate()
                    const roleArray = []
                    for (const key in roleStruct.roleDict) {
                        if (roleStruct.roleDict.hasOwnProperty(key)) {
                            roleArray.push(roleStruct.roleDict[key])
                        }
                    }
                    let roleLinks = roleArray.map((role) => {
                        return {
                            key: role.id,
                            name: role.role,
                            id: role.id,
                            isRoleLink: true,
                            linkLabel: role.id,
                            linkName: role.role,
                        }
                    })
                    let serverManagement = {
                        init: 1,
                        initialized: true,
                        managementPage: 'settings',
                        version: serverData.version,
                        channelPermTemplate: newChannelPermTemplate,
                        roleStructModified: roleStruct,
                        roleArray,
                        roleLinks,
                        roleStruct,
                        userMeta,
                        serverData,
                    }
                    return { ...state, [serverId]: serverManagement }
                } else {
                    const {
                        abstractRoles,
                        serverData,
                        _permDescriptions,
                        userMeta,
                    } = action.payload.asyncExtra
                    let managementState = { ...state[serverId] }
                    const roleStruct = buildRoleStruct(
                        abstractRoles,
                        serverData,
                        _permDescriptions
                    )
                    if (managementState.version !== serverData.version) {
                        const newChannelPermTemplate =
                            buildChannelPermTemplate()
                        const roleArray = []
                        for (const key in roleStruct.roleDict) {
                            if (roleStruct.roleDict.hasOwnProperty(key)) {
                                roleArray.push(roleStruct.roleDict[key])
                            }
                        }
                        let roleLinks = roleArray.map((role) => {
                            return {
                                key: role.id,
                                name: role.role,
                                id: role.id,
                                isRoleLink: true,
                                linkLabel: role.id,
                                linkName: role.role,
                            }
                        })

                        let serverManagement = {
                            ...managementState,
                            version: serverData.version,
                            channelPermTemplate: newChannelPermTemplate,
                            roleStructModified: roleStruct,
                            roleArray,
                            roleLinks,
                            roleStruct,
                            userMeta,
                            serverData,
                        }
                        return { ...state, [serverId]: serverManagement }
                    } else {
                        return { ...state }
                    }
                }
            },
        },
    },
    extraReducers: {
        [modifyChatServer]: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                const newState = { ...state }
                // if (!newState.hasOwnProperty(serverId)) {
                //     action.asyncDispatch(initManagement(""))
                // }
                return { ...state }
            },
        },
        [addChatServer]: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                let { id } = action.payload
                const newState = { ...state }
                if (newState.hasOwnProperty(id)) {
                    action.asyncDispatch(initManagement({ serverId: id }))
                }
                return { ...state }
            },
        },
        [addChatServerPartial]: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                return { ...state }
            },
        },
    },
})
export const managementActions = managementCachedSlice.actions
export const management = managementCachedSlice.reducer
export const managementSlice = managementCachedSlice
