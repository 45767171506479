import IconButton from '@mui/material/IconButton'
import React from 'react'
import { RA1 } from '../../../../../redux/actions.js'
import { connect } from 'react-redux'
import { Tooltip } from '@mui/material'
import {
    ArrowForward,
    DoNotDisturb,
    Group,
    GroupAdd,
    Outbox,
} from '@mui/icons-material'
import { dispatchMappers } from '../../../../../redux/process/actionProcess.js'

const mapDispatchToProps = dispatchMappers([RA1._appState.updateAppState])

const FriendNavFooter = function (props) {
    return (
        <div>
            <Tooltip
                placement="top"
                title={"Friends List"}>
                <IconButton
                    color="primary"
                    onClick={() => {
                        props.setFriendNavMode('friends')
                    }}
                    size="large">
                    <Group />
                </IconButton>
            </Tooltip>

            <Tooltip
                placement="top"
                title={"Friend Requests"}>
                <IconButton
                    color="primary"
                    onClick={() => {
                        props.setFriendNavMode('requests')
                    }}
                    size="large"
                >
                    <GroupAdd />
                </IconButton>
            </Tooltip>


            <Tooltip
                placement="top"
                title={"Pending Requests"}>
                <IconButton
                    color="primary"
                    onClick={() => {
                        props.setFriendNavMode('pending')
                    }}
                    size="large"
                >
                    <Outbox />
                </IconButton>
            </Tooltip>


            <Tooltip
                placement="top"
                title={"Blocked Users"}>
                <IconButton
                    color="primary"
                    onClick={() => {
                        props.setFriendNavMode('blocked')
                    }}
                    size="large"
                >
                    <DoNotDisturb />
                </IconButton>
            </Tooltip>

            {/* <IconButton
                color="primary"
                onClick={() => {
                    props.setMenu('user')
                }}
                size="large"
            >
                <ArrowForward />
            </IconButton> */}
        </div>
    )
}

export default connect(null, mapDispatchToProps)(FriendNavFooter)
