import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RA1, RS1 } from '../../../../redux/actions.js'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import { dispatchMappers } from '../../../../redux/process/actionProcess.js'
import SocketForm from '../../../_reusable/SocketForm/SocketForm'
import { statePropMapper } from '../../../../redux/process/selectorProcess'
import useState from 'react-usestateref'
import { withAS } from "../../../_contexts/withAppState";

const makeMapStateToProps = statePropMapper([
  RS1.store('_appState', '_appState'),
  RS1.store("videoCalls", "videoCalls")
])

const mapDispatchToProps = dispatchMappers([
  RA1.api.updateAPI,
  RA1.videoCall.setVideoDevice,
  RA1.videoCall.setAudioDevice,
  // RA1._appState.updateAppState
])

function convertToList(deviceList) {
  let newList = [];
  for (const device of deviceList) {
    newList.push({ name: device.label, value: device })
  }
  return newList;
}

const VideoSettingsModal = function (props) {
  return (
    <SocketForm
      formDispatch={(args) => {
        // props.dispatch._appState.updateAppState({
        //   videoSelection: args.videoDeviceSelector,
        //   audioSelection: args.audioDeviceSelector
        // })
        props.AS.video.ops.setVideoStreamOptions({
          videoSelection: args.videoDeviceSelector,
          audioSelection: args.audioDeviceSelector
        })
        props.closeModalMenu();
      }}
      socketForm
      description={'Use the following form to select your video and audio device'}
      formFields={[
        {
          id: 'videoDeviceSelector',
          type: 'selectGroup',
          list: convertToList(props.videoCalls.videoDevices),
          defaultIndex: 0,
          formatter: (obj) => obj?.name,
          typographyLabel: "Video Device"
        },
        {
          id: 'audioDeviceSelector',
          type: 'selectGroup',
          list: convertToList(props.videoCalls.audioDevices),
          defaultIndex: 0,
          formatter: (obj) => obj?.name,
          typographyLabel: "Audio Device"
        }
      ]}
      formButtons={[
        {
          label: 'Apply',
          formType: 'open',
          isSubmitButton: true
        },
      ]}
      formTypography={{ label: 'Video Settings' }}
    />
  )
}


export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(withAS(VideoSettingsModal))
