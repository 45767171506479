import React, {useEffect, useState} from 'react'
import {StyledDiv, TypographyCustom} from '../../Styled'
import {connect} from 'react-redux'
import {statePropMapper} from '../../../../redux/process/selectorProcess'
import {RS1} from '../../../../redux/actions'
import withMaterialDnD from '../../../_wrappers/withMaterialDnD'
import NotificationLink from './NotificationLink'
import Box from "@mui/system/Box";
import {Tab, Tabs} from "@mui/material";
import DirectMessageLink from "./DirectMessageLink/DirectMessageLink";
import ThreadWatcherNav from "../ThreadWatcherNav";

const makeMapStateToProps = statePropMapper([
    RS1.store('messages', 'messages'),
    RS1.store('users', 'users'),
    RS1.store('chatServers', 'chatServers'),
    RS1.store('users', 'users'),
    RS1.store('channels', 'channels'),
    RS1.store('_userId', '_userId'),
    RS1.store('notifications', 'notifications'),
])

const DirectMessageList = withMaterialDnD(DirectMessageLink)
const NotificationList = withMaterialDnD(NotificationLink)

const getDMChannels = (channelsFull, serverData, users, userId) => {
    let dataList = []
    let normalChannelIds = [
        serverData.logId,
        serverData.notificationsId,
        serverData.configId,
        serverData.profileId,
        serverData.generalId,
        serverData.hiddenId,
    ]
    let channels = serverData.channels.filter(
        (e) => !normalChannelIds.includes(e)
    )
    channels.map((id, index) => {
            dataList.push({
                key: id,
                channel: id,
                otherUserId: channelsFull[id].channelMeta?.users.filter(id => (id !== userId))[0],
                otherUserData: users[channelsFull[id].channelMeta?.users.filter(id => (id !== userId))[0]],
                relationshipData: channelsFull[id].channelMeta,
                channelType: 'dm',
                index: index,
            })
        }
    )
    return dataList
}

const compare = (a, b) => {
    if (a.isPriority && !b.isPriority) {
        return true
    }
    if (a.isPriority && a.isPriority) {
        return (a.newestCreatedAtDate > b.newestCreatedAtDate)
    }
    if (a.type === "dm" && b.type === "dm") {
        return (a.newestCreatedAtDate > b.newestCreatedAtDate)
    }
}
const deserializeNotification = (notificationMessage) => {
    const deserializedNotification = {}
    const arr = notificationMessage.split(":")
    deserializedNotification["type"] = arr[0]
    arr.forEach((subString) => {
        if (subString.includes("[") && subString.includes("]")) {
            const key = subString.split("[")[0]
            deserializedNotification[key] = subString.split("[")[1].split("]")[0]
        }
    })
    return deserializedNotification
}

const buildNotificationArray = (notificationStore, users, messages, channels, userId) => {
    let fullNotificationStructure = []
    const notificationKeys = Object.keys(notificationStore)
    notificationKeys.forEach((key) => {
        if (notificationStore[key].hasOwnProperty("lastCheckedDate")) {
            if (channels[key]?.channelMeta?.dm) {
                let dmData = {
                    ...notificationStore[key],
                    type: "dm",
                    channel: key,
                    otherUserId: channels[key].channelMeta?.users.filter(id => (id !== userId))[0],
                    otherUserData: users[channels[key].channelMeta?.users.filter(id => (id !== userId))[0]],
                }
                fullNotificationStructure = [...fullNotificationStructure, dmData]
            } else {
                if (notificationStore[key].priorityMessages.length > 0) {
                    notificationStore[key].priorityMessages.forEach((priorityMessage) => {
                        const deserializedNot = deserializeNotification(priorityMessage.mes)
                        let notificationData = {
                            ...priorityMessage,
                            idKey: key,
                            isPriority: true,
                            deserializedMessage: deserializedNot,
                            type: deserializedNot.type,
                        }
                        fullNotificationStructure = [...fullNotificationStructure, notificationData]
                    })
                }
            }
        }
    })
    fullNotificationStructure.sort((a, b) => compare(a, b) ? -1 : 1);
    return fullNotificationStructure
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const NotificationsNav = (props) => {
    const [tabValue, setTabValue] = useState(0)
    const [processedNotifications, setProcessedNotifications] = useState([])
    const [allDMConversations, setAllDMConversations] = useState([])

    //UNREAD NOTIFICATIONS, READ NOTIFICATIONS
    useEffect(() => {
        const preProcessedNotifications = buildNotificationArray(
            props.notifications.notificationStore,
            props.users,
            props.messages,
            props.channels,
            props._userId
        )
        setProcessedNotifications(preProcessedNotifications)
        setAllDMConversations(getDMChannels(props.channels, props.chatServers[props._userId], props.users, props._userId))
    }, [])
    useEffect(() => {
        const preProcessedNotifications = buildNotificationArray(
            props.notifications.notificationStore,
            props.users,
            props.messages,
            props.channels,
            props._userId
        )
        setProcessedNotifications(preProcessedNotifications)
        setAllDMConversations(getDMChannels(props.channels, props.chatServers[props._userId], props.users, props._userId))
    }, [props.notifications.version])

    return [
        <>
            {props.AS.mobileMode ? null : <StyledDiv
                types={['sidebarTopInternalHeader', 'rightSidebarHeader']}
                className={'sidebarTopInternalHeader'}>
                <TypographyCustom types={['generalHeader', 'rightSidebarHeader']} variant="h5">
                    Updates
                </TypographyCustom>
            </StyledDiv>}
            <div style={{display: 'flex', flexDirection: 'column',}}>
                <Box sx={{width: '100%'}}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={tabValue} onChange={(event, newValue) => setTabValue(newValue)}
                              aria-label="community features">
                            <Tab label="DMs"
                                 sx={{textTransform: "none"}} {...a11yProps(0)} />
                            <Tab label="Updates"
                                 sx={{textTransform: "none"}} {...a11yProps(1)} />
                            <Tab label="Favorites"
                                 sx={{textTransform: "none"}} {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                </Box>
            </div>
        </>
        ,
        <StyledDiv
            types={['sidebarTopInternalList']}
            className={'sidebarTopInternalList'}
        >
            <div style={{paddingTop: '6px'}}/>
            <StyledDiv types={['sidebarTopInternalScroller']}>
                {tabValue === 0 ?
                    <DirectMessageList
                        list={allDMConversations}
                        dynamicProps={{selectItem: () => true}}
                        droppableId={'notificationList'}
                        menuId={'channelListMenu'}
                        useDnD={false}
                    /> : null}
                {tabValue === 1 ?
                    <NotificationList
                        list={processedNotifications}
                        dynamicProps={{selectItem: () => true}}
                        droppableId={'notificationList'}
                        menuId={'channelListMenu'}
                        useDnD={false}
                    /> : null}
                {tabValue === 2 ?
                    <ThreadWatcherNav {...props} /> : null}
            </StyledDiv>
        </StyledDiv>
    ]
}

export default connect(makeMapStateToProps, null)(NotificationsNav)
