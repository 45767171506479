import {addNewMessage, setInitialNotifications} from './actions'
import {addChatServer, addChatServers} from './chatServers'
import {
    allMonoSlices,
    setTemp,
    trackedServerListSlice,
} from './monostate/mono'
import {updateAppState} from './appState'
import {normalize} from 'normalizr'
import {session2} from './schema/schemaProcess.js'
import {serverActions} from './server.js'
import {addMessages} from './messages.js'
import {addUsers} from './users.js'
import {addAbstractRoles} from './abstractRoles.js'
import {addUserMetas} from './userMeta.js'
import {addChannels} from './channels.js'
import {addChannelWindows} from './channelWindow.js'
import {createCachedSlice} from '../cache/createCachedSlice.js'
import {apiActions} from './api'
import updateDual from './updateDual'
import {addNativeIntegrationKeys} from "./integrations";

const loadMethods2 = {
    users: addUsers,
    userMetas: addUserMetas,
    abstractRoles: addAbstractRoles,
    messages: addMessages,
    channelWindows: addChannelWindows,
    channels: addChannels,
    servers: addChatServers,
}

//Old
// const loadMethods2 = {
//     "messages": addMessages,
//     "channelWindows": addChannelWindows,
//     "channels": addChannels,
//     "userMetas": addUserMetas,
//     "abstractRoles": addAbstractRoles,
//     "users": addUsers,
// }

export const batchLoad = function (action, data) {
    for (const outerKey in loadMethods2) {
        if (loadMethods2.hasOwnProperty(outerKey)) {
            if (data.hasOwnProperty(outerKey)) {
                action.asyncDispatch(loadMethods2[outerKey](data[outerKey]))
            }
        }
    }
}
let USERID
export const clientSlice = createCachedSlice({
    name: 'client',
    initialState: [],
    reducers: {
        getProfile: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                action.asyncDispatch(setTemp(action.payload.profile))
                action.asyncDispatch(
                    updateAppState({
                        modalMenu: 'none',
                        openMenu: 'main',
                        invLinkClicked: true,
                        modalMenuOpen: false,
                    })
                )
                return [...state, action.payload]
            },
        },
        error: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                if (action.payload.type === 'internal') {
                    let severity = action.payload.severity
                    action.asyncDispatch(
                        updateAppState({
                            errorSeverity: severity,
                            modalMenu: 'error',
                            invLinkClicked: false,
                            modalMenuOpen: true,
                        })
                    )
                    action.asyncDispatch(setTemp(action.payload))
                } else {
                    let severity = 0
                    action.payload.errs.map((err) => {
                        severity = Math.max(severity, err.severity)
                    })
                    action.asyncDispatch(
                        apiActions.updateAPI({
                            key: 'socket',
                            responseKey: action.payload?.method,
                            data: action.payload,
                        })
                    )
                    action.asyncDispatch(
                        updateAppState({
                            errorSeverity: severity,
                            modalMenu: 'error',
                            invLinkClicked: false,
                            modalMenuOpen: true,
                        })
                    )
                    action.asyncDispatch(setTemp(action.payload))
                }
                return [...state, action.payload]
            },
        },
        success: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                action.asyncDispatch(
                    apiActions.updateAPI({
                        key: 'socket',
                        responseKey: action.payload?.method,
                        data: action.payload,
                    })
                )
                return [...state, action.payload]
            },
        },
        landing: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                let newAction = {}
                newAction.type = action.payload.actionType
                newAction.payload = action.payload
                if (
                    newAction.payload?._trace?.emitId &&
                    newAction.payload?._trace?.apiId &&
                    newAction.payload?._trace?.apiMethod
                ) {
                    action.payload.operationStatus = 'success'
                    action.asyncDispatch(
                        apiActions.updateAPI({
                            key: 'socket',
                            responseKey: action.payload._trace.apiMethod,
                            data: action.payload,
                        })
                    )
                }
                action.asyncDispatch(newAction)
                //#TODO: Remove me (I'm test code)
                let {asyncExtra, ...restOfPayload} = action.payload
                return [...state, restOfPayload]
            },
        },
        deadEnd: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                return [...state, action.payload]
            },
        },
        updateRequest: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                if (action.payload.type !== 'serverReloadWithMeta') {
                    action.asyncDispatch(
                        serverActions['updateSession']({
                            handler: 'serverHandler',
                            command: 'updateSession',
                            data: action.payload,
                        })
                    )
                } else {
                    if (action.payload?.requiredFor === USERID) {
                        action.asyncDispatch(
                            serverActions['updateSession']({
                                handler: 'serverHandler',
                                command: 'updateSession',
                                data: action.payload,
                            })
                        )
                    }
                }
                return [...state]
            },
        },
        processUpdate: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                switch (action.payload.type) {
                    // case "updateFlowPartial":
                    //     primaryUpdateFlow(action, "partial")
                    //     return [...state, action.payload]
                    // case "updateFlow":
                    //     primaryUpdateFlow(action, "full")
                    //     return [...state, action.payload]
                    case 'updateDual':
                        updateDual.monadUpdate(action, 'doesNothingCurrently')
                        return [...state]
                    default:
                        return [...state]
                }
            },
        },
        loadSession: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                if (action.payload.data !== null) {
                    let data = normalize(action.payload.data, session2).entities
                    USERID = action.payload.data.userId
                    window.userId = USERID
                    const sessionData = {...data['session'][true]}
                    delete data.session
                    batchLoad(action, data)
                    sessionData['servers'] = action.payload.data.Servers.map(
                        (server) => server.id
                    )
                    for (const key in allMonoSlices) {
                        if (
                            allMonoSlices.hasOwnProperty(key) &&
                            key !== 'loaded'
                        ) {
                            action.asyncDispatch(
                                allMonoSlices[key].actions.set(sessionData[key])
                            )
                        }
                    }
                    action.asyncDispatch(
                        updateAppState({
                            modalMenu: 'none',
                            openMenu: 'chat',
                            modalMenuOpen: false,
                            updateId: 0,
                            pageChangeBlocked: false,
                            blockedModal: 'none',
                            roleStruct: {},
                        })
                    )
                    //Set temporary store to empty object
                    action.asyncDispatch(setTemp({}))
                    //Initialize notifications
                    action.asyncDispatch(
                        setInitialNotifications({
                            notificationStore: sessionData.notificationStore,
                            messages: data.messages,
                        })
                    )
                    //Add native integration keys
                    action.asyncDispatch(
                        addNativeIntegrationKeys(action.payload?.data?.nativeIntegrations)
                    )
                    //Flag all mono slices as loaded
                    action.asyncDispatch(
                        allMonoSlices['loaded'].actions.set(
                            sessionData['loaded']
                        )
                    )
                    return [...state, action.payload.meta]
                }
            },
        },
    },
    extraReducers: {
        [addNewMessage]: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                return [...state, action.type]
            },
        },
    },
})

export const clientSliceActions = clientSlice.actions
export const loadSession = clientSlice.actions.loadSession
export const client = clientSlice.reducer
