import { trigger } from "../../../events.js"

let startCallWithInvite = (sourceId, invite) => {
  trigger("startVideoCall:openInvite", {sourceId: sourceId, invite: invite});
}

let startCallWithoutInvite = (sourceId, type) => {
  trigger("startVideoCall:noInvite", {sourceId: sourceId, type: type});
}

export const streams = {startCallWithInvite: startCallWithInvite, startCallWithoutInvite: startCallWithoutInvite};
