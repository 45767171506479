import { useEffect } from 'react'

export const HeightIndicator = function (props) {
    useEffect(() => {
        if (props.isBottom && props.isVisibile) {
            props.flip('bottom', 0, 'bottomOp')
        }

        if (props.isTop && props.isVisibile) {
            props.flip('top', 0, 'topOp')
        }

        // if (props.isBottom && props.isVisibile) {
        //     if(props.reverseMode){
        //         props.flip("top", 0, "topOp")
        //     }else{
        //         props.flip("bottom", 0, "bottomOp")
        //     }
        // }
        //
        // if (props.isTop && props.isVisibile) {
        //     if(props.reverseMode){
        //         props.flip("bottom", 0, "bottomOp")
        //     }else{
        //         props.flip("top", 0, "topOp")
        //     }
        // }
    }, [props.isVisibile, props.isBottom, props.isTop, props.rowIndex])
    return null
}
