import { createAction } from '@reduxjs/toolkit'

export const changeToDM = createAction('_appState/changeToDM')
export const updateCatalog = createAction('_appState/updateCatalog')
export const updateSession = createAction('server/updateSession')
export const clearNotifications = createAction(
    'notifications/clearNotifications'
)
export const updateThreadOrder = createAction('channels/updateThreadOrder')
export const updateRecentServers = createAction('_appState/updateRecentServers')
export const blockModalRoleStruct = createAction(
    '_appState/blockModalRoleStruct'
)
export const openBlockedModalMenu = createAction(
    '_appState/openBlockedModalMenu'
)
export const updateAPI = createAction('updateAPI')
export const updateNotificationsChannel = createAction(
    'notifications/updateNotificationsChannel'
)
export const addNewMessage = createAction('messages/addNewMessage')
export const editMessage = createAction('messages/editMessage')
export const batchLoadAction = createAction('batchLoad')
export const topLoadClient = createAction('messages/topLoad')
export const searchResultsClient = createAction('messages/searchResults')
export const messageLoadResponse = createAction('messages/messageLoad')
export const initManagement = createAction('management/initManagement')
export const topLoadClientFollowup = createAction('messages/topLoadFollowup')
export const updateSessionRequest = createAction('server/updateSession')
export const setInitialNotifications = createAction(
    'notifications/setInitialNotifications'
)
export const otherMonoActions = [
    ['addNewMessage', addNewMessage],
    ['topLoadClient', topLoadClient],
]
