import React from 'react'
import {connect} from 'react-redux'
import {RA1, RS1} from '../../../../redux/actions.js'
import {SA1} from '../../../../hs/requestGlobals.mjs'
import {dispatchMappers} from '../../../../redux/process/actionProcess.js'
import SocketForm from '../../../_reusable/SocketForm/SocketForm'
import {statePropMapper} from "../../../../redux/process/selectorProcess";

const makeMapStateToProps = statePropMapper([RS1.store('calendar','calendar')])


const mapDispatchToProps = dispatchMappers([
    RA1.api.updateAPI,
    SA1.gptConfirm,
])

const ConfirmGPTModal = function (props) {
    let log = props.calendar?.gptLog ? props.calendar.gptLog : []
    let actions = props.calendar?.actions ? props.calendar.actions : []
    return (
        <SocketForm
            useConfirmationScreen={false}
            formMethod={'gptConfirm'}
            formDispatch={({}) => {
                props.dispatch.server.gptConfirm(props.AS.activeServer, actions, log,'gptConfirm','gptConfirm')
            }}
            socketForm
            onClose={props.closeModalMenu}
            onSuccess={props.closeModalMenu}
            description={'You may create a community.'}
            formFields={[]}
            formButtons={[
                {
                    label: 'Confirm',
                    icon: 'add',
                    isSubmitButton: true,
                    isCloseButton: false,
                },
                {
                    label: 'Cancel',
                    icon: 'add',
                    isSubmitButton: false,
                    isCloseButton: true,
                },
            ]}
            formTypography={{label: 'Confirm Changes'}}
            successMessage={'GPT Changes Confirmed.'}
        />
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(ConfirmGPTModal)
