import React from 'react'
import { connect } from 'react-redux'
import { SA1 } from '../../../../../hs/requestGlobals.mjs'
import { RA1 } from '../../../../../redux/actions.js'
import {
    ListItemCustom,
    ListItemSecondaryActionCustom,
    IconButtonCustom,
    ListItemTextCustom
} from '../../../Styled'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close'
import BlockIcon from '@mui/icons-material/Block'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import ListItemText from '@mui/material/ListItemText'
import { Tooltip } from '@mui/material'
import { withAS } from '../../../../_contexts/withAppState'
import { statePropMappers } from '../../../../../redux/process/selectorProcess.js'
import { dispatchMappers } from '../../../../../redux/process/actionProcess.js'

const mapDispatchToProps = dispatchMappers([
    RA1._appState.changePage,
    RA1._appState.openModalMenu,
    RA1._appState.changeToDM,
    SA1.removeFriend,
    SA1.addFriend,
])

const makeMapStateToProps = statePropMappers(
    {
        getChannel: ['channelMeta', []],
        getDMChannelPlusUserData: ['dataDM', []],
    },
    {
        _loaded: '_loaded',
        _userId: '_userId',
    }
)

const BlockedButton = function (props) {
    return (
        <ListItemSecondaryActionCustom>

            <Tooltip placement="top" title={"Unblock"}
                style={{ flex: '0 1 auto' }}>
                <span style={{ width: '2em', padding: 0, margin: 0 }}>
                    <IconButtonCustom
                        types={['friendsNav']}
                        edge="end"
                        aria-label="Unblock"
                        onClick={() =>
                            props.dispatch.server.addFriend(
                                props.otherUserId,
                                props.otherUserId,
                                false,
                                true
                            )
                        }
                        size="large"
                    >
                        <RemoveCircleIcon />
                    </IconButtonCustom>
                </span>
            </Tooltip>
        </ListItemSecondaryActionCustom>
    )
}
const FriendsButton = function (props) {
    return (
        <ListItemSecondaryActionCustom
            types={['friendsNav']}>

            <Tooltip placement="top" title={"Direct Messages"}
                style={{ flex: '0 1 auto' }}>
                <span style={{ width: '2em', padding: 0, margin: 0 }}>
                    <IconButtonCustom
                        types={['friendsNav']}
                        edge="end"
                        aria-label="Direct Messages"
                        onClick={() => {
                            props.AS.nav.ops.setPage('dm')
                            props.AS.nav.ops.setDMData({
                                otherUserId: props._userId,
                                channel: props.channelMeta.id,
                                profileCheck: false
                            })
                        }}
                        size="small">
                        <EmailIcon />
                    </IconButtonCustom>
                </span>
            </Tooltip>
            <Tooltip placement="top" title={"Remove Friend"}>
                <span style={{ width: '2em', padding: 0, margin: 0 }}>
                    <IconButtonCustom
                        types={['friendsNav']}
                        edge="end"
                        aria-label="Remove Friend"
                        onClick={() =>
                            props.dispatch.server.removeFriend(props.otherUserId)
                        }
                        size="small">
                        <CloseIcon />
                    </IconButtonCustom>
                </span>
            </Tooltip>
            <Tooltip placement="top" title={"Block User"}>
                <span style={{ width: '2em', padding: 0, margin: 0 }}>
                    <IconButtonCustom
                        types={['friendsNav']}
                        edge="end"
                        aria-label="Block User"
                        onClick={() =>
                            props.dispatch.server.addFriend(
                                props.otherUserId,
                                props.otherUserId,
                                true,
                                false
                            )
                        }
                        size="small">
                        <BlockIcon />
                    </IconButtonCustom>
                </span>
            </Tooltip>
        </ListItemSecondaryActionCustom>
    )
}
const RequestsButton = function (props) {
    return (
        <ListItemSecondaryActionCustom>
            <Tooltip placement="top" title={"Accept Request"}
                style={{ flex: '0 1 auto' }}>
                <span style={{ width: '2em', padding: 0, margin: 0 }}>
                    <IconButtonCustom
                        types={['friendsNav']}
                        edge="end"
                        aria-label="Accept Request"
                        onClick={() =>
                            props.dispatch.server.addFriend(
                                props.otherUserId,
                                props.otherUserId,
                                false,
                                false
                            )
                        }
                        size="small"
                    >
                        <Icon className="fa fa-plus-circle" />
                    </IconButtonCustom>
                </span>
            </Tooltip>
        </ListItemSecondaryActionCustom>
    )
}
const PendingButton = function (props) {
    return (
        <ListItemSecondaryAction>
            <Tooltip placement="top" title={"Revoke Request"}
                style={{ flex: '0 1 auto' }}>
                <span style={{ width: '2em', padding: 0, margin: 0 }}>
                    <IconButtonCustom
                        types={['friendsNav']}
                        edge="end"
                        aria-label="Revoke Request"
                        onClick={() =>
                            props.dispatch.server.addFriend(
                                props.otherUserId,
                                props.otherUserId,
                                false,
                                false,
                                true
                            )
                        }
                        size="small"
                    >
                        <Icon className="fa fa-plus-circle" />
                    </IconButtonCustom>
                </span>
            </Tooltip>
        </ListItemSecondaryAction>
    )
}
const FriendLink = function (props) {
    let channelMeta = props.channelMeta
    if (props.channel === props.activeChannel) {
    }
    if (
        props.channelType === 'dm' &&
        channelMeta.logMeta.channelName === 'DM'
    ) {
        let includesUser = channelMeta.channelMeta.friends.includes(
            props._userId
        )
        let sizeCond = channelMeta.channelMeta.friends.length === 1
        let blockedBy = channelMeta.channelMeta.blockedBy === props._userId
        let cond = props.currentMode === props.entryType
        let otherConditions = {
            friends: props.channelMeta.channelMeta.status === 'friends',
            requests:
                !includesUser &&
                sizeCond &&
                props.channelMeta.channelMeta.status === 'friendRequest',
            pending:
                includesUser &&
                sizeCond &&
                props.channelMeta.channelMeta.status === 'friendRequest',
            blocked:
                includesUser &&
                props.channelMeta.channelMeta.status === 'blocked',
        }
        if (cond && otherConditions[props.entryType]) {
            let otherUserArray = props.channelMeta.channelMeta.users.filter(
                (e) => e !== props._userId
            )
            let otherUserId = otherUserArray[0]
            let endButton = {
                friends: <FriendsButton otherUserId={otherUserId} {...props} />,
                requests: (
                    <RequestsButton otherUserId={otherUserId} {...props} />
                ),
                pending: <PendingButton otherUserId={otherUserId} {...props} />,
                blocked: <BlockedButton otherUserId={otherUserId} {...props} />,
            }
            return (
                <ListItemCustom
                    key={props.key + props.entryType + 'LAT'}
                    types={['friendsNav']}
                    button={props.entryType === 'friends'}
                    onClick={() => {
                        // if (!props._appState.pageChangeBlocked) {
                        //     props.dispatch._appState.changePage("chat")
                        //     props.dispatch.other.setActiveChannel({channelId: props.channel, serverId: props._activeServer})
                        //
                        // } else {
                        //     props.dispatch._appState.openModalMenu(props._appState.blockedModal)
                        // }
                    }}>
                    {/*<ListItemAvatar>*/}
                    {/*    <Avatar>*/}
                    {/*        <FolderIcon/>*/}
                    {/*    </Avatar>*/}
                    {/*</ListItemAvatar>*/}

                    <ListItemTextCustom
                        types={['friendsNav']}
                        id={props.key + props.entryType + 'LIT'}
                        primary={props.dataDM.otherUser.userName}
                    />

                    {/* <div>AAAAAAAAAA</div> */}
                    <div>
                        {endButton[props.entryType]}
                    </div>


                </ListItemCustom>
            )
        } else {
            return null
        }
    } else {
        return null
    }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(FriendLink))
