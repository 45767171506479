import React, { useCallback, useState } from 'react'
import Cropper from 'react-easy-crop'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Slider } from '@mui/material'

const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', (error) => reject(error))
        image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
        image.src = url
    })

function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation)

    return {
        width:
            Math.abs(Math.cos(rotRad) * width) +
            Math.abs(Math.sin(rotRad) * height),
        height:
            Math.abs(Math.sin(rotRad) * width) +
            Math.abs(Math.cos(rotRad) * height),
    }
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
async function getCroppedImg(
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        return null
    }

    const rotRad = getRadianAngle(rotation)

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
        image.width,
        image.height,
        rotation
    )

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth
    canvas.height = bBoxHeight

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
    ctx.rotate(rotRad)
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(-image.width / 2, -image.height / 2)

    // draw rotated image
    ctx.drawImage(image, 0, 0)

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height
    )

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0)

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');
    // As a blob
    return new Promise((resolve, reject) => {
        canvas.toBlob((file) => {
            resolve([file, URL.createObjectURL(file)])
        }, 'image/jpeg')
    })
}

const CropperComponent = function (props) {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])
    const showCroppedImage = useCallback(async () => {
        try {
            const [file, url] = await getCroppedImg(
                props.imageUrl,
                croppedAreaPixels,
                rotation
            )
            let oFile = new File([file], 'emoji.jpeg', {
                type: 'image/jpeg',
                lastModified: Date.now(),
            })
            props.cropAndUpdate(oFile)
        } catch (e) {}
    }, [croppedAreaPixels, rotation])
    return (
        <div>
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    height: 200,
                    background: '#333',
                }}
            >
                <Cropper
                    image={props.imageUrl}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={props.aspectRat}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />
            </div>
            <div
                style={{
                    padding: 16,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flex: '1',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="overline">Zoom</Typography>
                    <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        aria-labelledby="Zoom"
                        style={{
                            padding: '22px 0px',
                            marginLeft: 32,
                        }}
                        onChange={(e, zoom) => setZoom(zoom)}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flex: '1',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="overline">Rotation</Typography>
                    <Slider
                        value={rotation}
                        min={0}
                        max={360}
                        step={1}
                        aria-labelledby="Rotation"
                        style={{
                            padding: '22px 0px',
                            marginLeft: 32,
                        }}
                        onChange={(e, rotation) => setRotation(rotation)}
                    />
                </div>
                <Button
                    onClick={showCroppedImage}
                    style={{ width: '100px' }}
                    variant="contained"
                    color="primary"
                >
                    Crop
                </Button>
            </div>
        </div>
    )
}

export default CropperComponent
