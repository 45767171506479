import React from "react"

import { StyledDiv, ButtonCustom, TypographyCustom, AvatarCustom, ListItemCustom } from "../../../../../Chat/Styled"
import { Style } from "@mui/icons-material"

import { Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material"

// import { ButtonCustom } from '../../../Chat/Styled'
// import { StyledDiv } from '../../../Chat/Styled'
// import { dispatchMappers } from '../../../../redux/process/actionProcess'
// import { RA1 } from '../../../../redux/actions'
// import { connect } from 'react-redux'

const CommunityCard = function (props) {
    return (
        <ListItemCustom key={props.serverMeta.key}

            itemselectid={props.serverMeta.key}
            activeid={props.activeServer}

            types={[
                'selectable',
                'server',
                'generalCard',
                'fullCard',
                (props.compressed && !props.desktop) ? 'compressedCard' : 'community',
                props.half ? 'communityHalfPadding' : 'communityPadding',
                (props.compressed && !props.desktop) ? 'communityCompressed' : '',
                (props.desktop && props.compressed) ? 'tighterCurves' : ''
            ]}
            style={props.half ? {
                padding: '0.4em',
                borderRadius: '50px 0% 0% 50px',
                border: 'none',
                margin: '15px 5px',
                width: 'auto',
            } : {}}

            onClick={() => {
                props.setMenu('community')
                props.setPage('chat')
                props.changeActiveServer(props?.serverMeta?.id)
            }}>
            {/* <StyledDiv className="recolor-item"
                types={[
                    'generalCard',
                    'fullCard',
                    (props.compressed && !props.desktop) ? 'compressedCard' : 'community',
                    props.half ? 'communityHalfPadding' : 'communityPadding',
                    (props.compressed && !props.desktop) ? 'communityCompressed' : '',
                    (props.desktop && props.compressed) ? 'tighterCurves' : ''
                ]} style={props.half ? {
                    padding: '0.2em 0.35em',
                    borderRadius: '30px 0% 0% 30px',
                    border: 'none',
                    margin: '15px 5px',
                    width: 'auto',
                } : {}}> */}
            {props.compressed ? null :
                <StyledDiv style={{
                    marginRight: '1.5em',
                }}>
                    {/* '& .MuiSvgIcon-root': { transform: 'scale(.7)' } */}
                    <AvatarCustom
                        sx={{
                            aspectRatio: '1 / 1',
                            height: props.half ? '2.5em' : '4em',
                            width: 'auto'
                        }}
                        alt={props.name}
                        src={props.avatar} />
                </StyledDiv>
            }
            <StyledDiv types={[
                props.compressed ?
                    'communityTextCompressed' :
                    'communityText']}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '100%',
                }}>
                <TypographyCustom variant="h6" style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '1.5em'
                }}>
                    {props.name}
                </TypographyCustom>

                {props.half || props.compressed || props.compressedDesktop ?
                    null :
                    <StyledDiv types={['communityTable']}>
                        <Table size='small' style={{
                            padding: 0,
                            margin: '5px 0px',
                        }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{
                                        borderBottom: '0px',
                                        padding: '0px',
                                        paddingRight: '20px',
                                        fontSize: '0.8rem'
                                    }}>
                                        Users
                                    </TableCell>
                                    <TableCell style={{
                                        borderBottom: '0px',
                                        padding: '0px',
                                        paddingRight: '20px',
                                        fontSize: '0.8rem'
                                    }}>
                                        Threads
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={{
                                        borderBottom: '0px',
                                        padding: '0px',
                                        paddingRight: '20px',
                                        fontSize: '0.8rem'
                                        // TODO: round these numbers to K's, M's, etc.
                                    }}>
                                        {props.users}
                                    </TableCell>
                                    <TableCell sx={{
                                        borderBottom: '0px',
                                        padding: '0px',
                                        paddingRight: '20px',
                                        fontSize: '0.8rem'
                                        // TODO: round these numbers to K's, M's, etc.
                                    }}>
                                        {props.threads}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </StyledDiv>
                }

                <StyledDiv>
                    <TypographyCustom
                        types={['communityOwner']}
                        variant="p"
                        ownerColor={props.ownerColor}
                        style={{
                            fontSize: '1em',
                        }}>
                        Owned By <b>{props.owner}</b>
                    </TypographyCustom>
                </StyledDiv>

            </StyledDiv>
            {/* </StyledDiv> */}
        </ListItemCustom>
    )
}


const CommunityCardDisplay = function (props) {

    // Every card display must have n different modes.
    // Which mode is rendered is determined by a switch.
    // However, these modes must be the same across all cards.
    // Suggested modes: full-desktop, half-desktop, compressed-desktop, full, compressed

    // #
    switch (props.displayMode) {
        case 'fullDesktop':
            return <CommunityCard
                desktop={true}
                {...props} />
        case 'halfDesktop':
            return <CommunityCard
                desktop={true}
                half={true}
                {...props} />
        case 'compressedDesktop':
            return <CommunityCard
                desktop={true}
                compressed={true}
                {...props} />
        case 'full': // mobile
            return <CommunityCard
                desktop={false}
                {...props} />
        default:
            return <CommunityCard
                desktop={false}
                compressed={true}
                {...props} />
    }
}

export const communityCardPackage = {
    requirements: ['displayMode', 'image', 'title', 'data'],
    CardDisplay: CommunityCardDisplay
}