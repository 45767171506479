import {createCachedSlice} from '../cache/createCachedSlice.js'

function padTo2Digits(num) {
    return num.toString().padStart(2, '0')
}

function formatDate(date) {
    return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
    ].join('/')
}

function daysIntoYear(date) {
    return (
        (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
            Date.UTC(date.getFullYear(), 0, 0)) /
        24 /
        60 /
        60 /
        1000
    )
}

function uniqueDay(date) {
    let present = new Date(Date.now())
    let currentYear = present.getFullYear()
    let x = daysIntoYear(date)
    if (x < 10) {
        return currentYear + '00' + x
    } else if (x < 100) {
        return currentYear + '0' + x
    } else {
        return currentYear + '' + x
    }
}

const calendarCachedSlice = createCachedSlice({
    name: 'calendar',
    initialState: {},
    reducers: {
        setSelectedAppointment: {
            cached: false,
            parameters: ['appointment'],
            description: '',
            reduce: (state, action) => {
                const {appointment} = action.payload
                const serverId = appointment.serverId
                const updatedState = {...state[appointment.serverId]}
                updatedState.selected = appointment
                return {...state, [serverId]: updatedState}
            },
        },
        calendarChanged: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                const {serverId} = action.payload
                const updatedState = {...state[serverId]}
                updatedState.requiresReload = true
                return {...state, [serverId]: updatedState, gptLog: action?.payload?.feLog, actions: action?.payload?.actions}
            },
        },
        updateAppointments: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                const newState = {...state}
                const {appointments, serverId} = action.payload
                newState[serverId] = {
                    requiresReload: false,
                    loaded: true,
                    appointments: appointments,
                    appointmentsByDay: {},
                    appointmentsByTitle: {
                        products: {},
                        gpttags: {none: []},
                    },
                    appointmentsByInitTimestamp: {},
                }
                appointments.forEach((appt) => {
                    let apptDate = new Date(appt.initialDate)
                    let apptTimestamp = apptDate.getTime()
                    let uniqueDayLabel = uniqueDay(apptDate)
                    newState[serverId].appointmentsByInitTimestamp[
                        apptTimestamp
                        ] = appt

                    if (appt.isProduct) { // If product, sort by products.
                        if (!(newState[serverId].appointmentsByTitle.products
                            .hasOwnProperty(appt.productId))
                        ) {
                            newState[serverId].appointmentsByTitle.products[appt.productId] = []
                        }
                        newState[serverId].appointmentsByTitle.products[appt.productId].push(appt)
                    } else if (appt?.gptTag) { // If not product, sort by gptTags.
                        if (!(newState[serverId].appointmentsByTitle.gpttags
                            .hasOwnProperty(appt.gptTag))
                        ) {
                            newState[serverId].appointmentsByTitle.gpttags[appt.gptTag] = []
                        }
                        newState[serverId].appointmentsByTitle.gpttags[appt.gptTag].push(appt)
                    } else { // if no gptTag, put in miscellany / 'none' gptTag
                        newState[serverId].appointmentsByTitle.gpttags.none.push(appt)
                    }


                    if (
                        newState[serverId].appointmentsByDay.hasOwnProperty(
                            uniqueDayLabel
                        )
                    ) {
                        newState[serverId].appointmentsByDay[
                            uniqueDayLabel
                            ].push(apptTimestamp)
                    } else {
                        newState[serverId].appointmentsByDay[uniqueDayLabel] = [
                            apptTimestamp,
                        ]
                    }
                })
                Object.keys(newState[serverId].appointmentsByDay).forEach(
                    (key) => {
                        newState[serverId].appointmentsByDay[key] =
                            newState[serverId].appointmentsByDay[key].sort()
                    }
                )
                return newState
            },
        },
    },
})
export const calendarActions = calendarCachedSlice.actions
export const calendar = calendarCachedSlice.reducer
export const calendarSlice = calendarCachedSlice
