import {addNewMessage, editMessage, messageLoadResponse, topLoadClient} from './actions'
import { List } from 'immutable'
import Immutable from 'immutable'
import { serverActions } from './server.js'
import { createCachedSlice } from '../cache/createCachedSlice.js'

const channelWindow = createCachedSlice({
    name: 'channelWindows',
    initialState: {},
    reducers: {
        addChannelWindow: {
            cached: false,
            parameters: [],
            reduce: (state, action) => {
                //Payload arguments
                const { id } = action.payload
                return {
                    ...state,
                    [id]: action.payload,
                }
            },
        },
        scrollDispatch: {
            cached: false,
            parameters: ['serverId', 'channelId', 'direction', 'postShift'],
            reduce: (state, action) => {
                return { ...state }
            },
        },
        mergeOtherIntoMain: {
            cached: false,
            parameters: ['channelId', 'otherMessages'],
            reduce: (state, action) => {
                const { channelId, otherMessages } = action.payload
                let oldWindow = { ...state[channelId] }
                oldWindow.messages = otherMessages
                return {
                    ...state,
                    [channelId]: oldWindow,
                }
            },
        },
        addChannelWindows: {
            cached: false,
            parameters: [],
            reduce: (state, action) => {
                //Payload arguments
                let newState = { ...state }
                for (let key in action.payload) {
                    if (action.payload.hasOwnProperty(key)) {
                        if (
                            !newState.hasOwnProperty(key) ||
                            !newState[key].dataLoaded
                        ) {
                            newState[key] = action.payload[key]
                        }
                    }
                }
                return newState
            },
        },
        storeChannelWindowData: {
            cached: false,
            parameters: [
                'channelId',
                'channelWindowDataMain',
                'channelWindowDataOther',
                'mode',
            ],
            reduce: (state, action) => {
                //Arguments
                const {
                    channelId,
                    channelWindowDataMain,
                    channelWindowDataOther,
                    mode,
                } = action.payload
                const oldWindow = { ...state[channelId] }
                oldWindow.storedChannelWindowDataMain = channelWindowDataMain
                oldWindow.storedChannelWindowDataOther = channelWindowDataOther
                oldWindow.dataStored = true
                oldWindow.activeMode = mode
                return {
                    ...state,
                    [channelId]: oldWindow,
                }
            },
        },
    },
    extraReducers: {
        [editMessage]: {
            cached: false,
            parameters: ['message'],
            reduce: (state, action) => {
                const { cId } = action.payload
                // const { uId, cId, mId } = message
                let oldWindow = { ...state[cId] }
                oldWindow.updateId += 1
                oldWindow.updates = oldWindow.updates.concat([
                    {
                        type: 'editMessage',
                //         messageId: mId,
                //         messageUserId: uId,
                    },
                ])
                // // let newMessages = List([mId])
                // oldWindow.messages = oldWindow.messages.concat(newMessages)
                // oldWindow.allMessages =
                //     oldWindow.allMessages.concat(newMessages)
                oldWindow.updateRequired = true
                return {
                    ...state,
                    [cId]: oldWindow,
                }
            },
        },
        [addNewMessage]: {
            cached: false,
            parameters: ['message'],
            reduce: (state, action) => {
                const { message } = action.payload
                const { uId, cId, mId } = message
                let oldWindow = { ...state[cId] }
                oldWindow.updateId += 1
                oldWindow.updates = oldWindow.updates.concat([
                    {
                        type: 'addMessageNewSingle',
                        messageId: mId,
                        messageUserId: uId,
                    },
                ])
                let newMessages = List([mId])
                oldWindow.messages = oldWindow.messages.concat(newMessages)
                oldWindow.allMessages =
                    oldWindow.allMessages.concat(newMessages)
                oldWindow.updateRequired = true
                return {
                    ...state,
                    [cId]: oldWindow,
                }
            },
        },
        [messageLoadResponse]: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                const {
                    channelId,
                    serverId,
                    messageId,
                    list,
                    regionMode,
                    clientInstruction,
                } = action.payload
                let oldWindow = { ...state[channelId] }
                oldWindow.updateId += 1
                oldWindow.updateRequired = true
                let messageIds = list.map((message) => message._id)
                let incomingMessages = Immutable.List(messageIds)
                let modifiedField =
                    regionMode === 'main' ? 'messages' : 'messagesOther'
                oldWindow.allMessages = oldWindow.allMessages.concat(messageIds)
                switch (clientInstruction) {
                    case 'newOther':
                        oldWindow.switchId = oldWindow.updateId
                        oldWindow.messagesOther = incomingMessages
                        oldWindow.messagesOtherMeta = {
                            messageId: messageId,
                            clientInstruction: 'newOther',
                        }
                        oldWindow.updates = oldWindow.updates.concat([
                            { type: 'doNothing', list: messageIds },
                        ])
                        break
                    case 'topScroll':
                        if (incomingMessages.length !== 0) {
                            oldWindow[modifiedField] = incomingMessages.concat(
                                oldWindow.messages
                            )
                            oldWindow.updates = oldWindow.updates.concat([
                                { type: 'addMessagesOld', list: messageIds },
                            ])
                        } else {
                            oldWindow.updates = oldWindow.updates.concat([
                                { type: 'setTopMessage', list: messageIds },
                            ])
                        }
                        break
                    case 'bottomScroll':
                        oldWindow[modifiedField] =
                            oldWindow.messages.concat(incomingMessages)
                        oldWindow.updates = oldWindow.updates.concat([
                            { type: 'addMessagesNew', list: messageIds },
                        ])
                        break
                }
                return {
                    ...state,
                    [channelId]: oldWindow,
                }
            },
        },
    },
})

export const channelWindowSlice = channelWindow
export const channelWindowActions = channelWindow.actions
export const scrollDispatch = channelWindow.actions.scrollDispatch
export const addChannelWindows = channelWindow.actions.addChannelWindows
export const addChannelWindow = channelWindow.actions.addChannelWindow
export const updateChWin = channelWindow.actions.updateChWin
export const channelWindows = channelWindow.reducer
