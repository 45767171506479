import React, { useEffect, useState } from 'react'
import { withAS } from "../../../../_contexts/withAppState";
import { dispatchMappers } from "../../../../../redux/process/actionProcess";
import { SA1 } from "../../../../../hs/requestGlobals.mjs";
import { RA1, RS1 } from "../../../../../redux/actions";
import { connect } from 'react-redux'
import { statePropMapper } from "../../../../../redux/process/selectorProcess";
import List from "@mui/material/List";
import { StyledDiv, ListItemCustom, ListItemIconCustom, ListItemTextCustom, TypographyCustom } from "../../../Styled";
import CancelIcon from '@mui/icons-material/Cancel';
// import ViewListIcon from "@mui/icons-material/ViewList";

const makeMapStateToProps = statePropMapper([
    RS1.store('_appState', '_appState'),
    RS1.store('_userSettings', '_userSettings'),
    RS1.getServer('serverData'),
    RS1.getServerProductsSubscriptionsOwned("userSubscriptions", "userSubscriptions"),
    RS1.getServerProductsSubscriptions("subscriptions", "subscriptions")
])
const mapDispatchToProps = dispatchMappers([
    SA1.getProductsByServer,
    SA1.getTransactions,
    SA1.getServerTransactions,
])

const SubscriptionButton = function (props) {

    let price = props.product.price
    const priceLen = props.product.price.length
    const displayPrice = "$" + price.slice(0, priceLen - 2) + "." + price.slice(-2)

    // const [transaction, setTransaction] = useState(null)

    const transaction = props.transactions.filter(
        (transaction) =>
            (transaction.product.id === props.product.id) &&
            (transaction.transactionStatus === 'active'))[0]

    if (transaction) {
        return (
            <><ListItemCustom
                types={['ownedSubscriptionEntry']}
                key={props.key}>

                <StyledDiv
                    types={['ownedSubscriptionData']}>
                    <ListItemTextCustom
                        id={props.index + "SBLITC"}
                        primary={props.product.name}
                        secondary={displayPrice}
                        types={['subscriptionButtonText1']} />

                    <ListItemTextCustom
                        primary={'First Payment'}
                        secondary={(new Date(transaction?.createdAt)).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
                        types={[]} />

                    <ListItemTextCustom
                        primary={'Last Payment'}
                        secondary={(new Date(transaction?.transactionMeta?.last_payment_date)).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
                        types={[]} />
                </StyledDiv>

                <ListItemIconCustom
                    button
                    onClick={() => {
                        props.openModalWithData('cancelSubscription', {
                            subscriptionId: transaction.id,
                            isCreator: false,
                        })
                    }}
                    types={['subscriptionCancel']}>
                    <CancelIcon size={"medium"} />


                </ListItemIconCustom>
            </ListItemCustom></>
        )
    } else {
        return null
    }
}

const SubscriptionManagementList = function (props) {

    // TODO: subscriptions should not appear as owned when they have been cancelled.
    const ownedSubs = props.subscriptions.filter((sub) => props.userSubscriptions.includes(sub.id))

    // TODO: IMMEDIATELY:
    // TODO: IMMANENT DANGER:
    // TODO: ADDRESS BY 22:00 ON 4/28/2023
    // TODO: If a transaction appears that is a subscription and is not included in user subscriptions, that's obviously a massive flaw!

    if (ownedSubs.length) {
        return (
            <List>
                <TypographyCustom types={['communityHeader']}>
                    {props.serverData.name}</TypographyCustom>

                {ownedSubs.map((product, index) =>
                    <div id={"sub_product" + index} key={"sub_product" + index}>
                        <SubscriptionButton
                            product={product}
                            transactions={props.transactions}
                            index={index}
                            {...props} />
                    </div>)}

            </List>
        )
    } else {
        return null
    }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(SubscriptionManagementList))
