import React from "react";
import Paper from "@mui/material/Paper";
import { MessageLeft, MessageRight } from "./message";
import { TextInput } from "./textInput";
import { useSocketRetrieve } from "../../../hs/hooks";
import { statePropMapper } from "../../../redux/process/selectorProcess";
import { RA1, RS1 } from "../../../redux/actions";
import { dispatchMappers } from "../../../redux/process/actionProcess";
import { SA1 } from "../../../hs/requestGlobals.mjs";
import { connect } from "react-redux";
import { StyledDiv } from "../Styled";
import { withAS } from "../../_contexts/withAppState";

import { Fab } from "@mui/material";
import ChatIcon from '@mui/icons-material/Chat';
import ListIcon from '@mui/icons-material/List';

const makeMapStateToProps = statePropMapper([
    RS1.store('_activeServer', '_activeServer'),
    RS1.store('calendar', 'calendar'),
    RS1.getSocketResponses('socketResponses'),
])

const mapDispatchToProps = dispatchMappers([
    SA1.gptAppointment,
    SA1.gptConfirm,
])


const SimpleChat = function (props) {
    let [gptAppointmentRequest, response] = useSocketRetrieve(props.dispatch.server.gptAppointment, props['socketResponses'], "gptAppointment", "gptAppointment")
    let [gptConfirmRequest, confirmResponse] = useSocketRetrieve(props.dispatch.server.gptConfirm, props['socketResponses'], "gptConfirm", "gptConfirm")
    let log = props.calendar?.gptLog ? props.calendar.gptLog : []
    const handleSubmit = (input) => {
        if (confirmResponse?.feLog != null) {
            gptAppointmentRequest(props.serverId, input, log, new Date().getTimezoneOffset())
        }
        else {
            gptAppointmentRequest(props.serverId, input, log, new Date().getTimezoneOffset())
        }
    }
    const parser = (action) => {
        let obj = {}
        let queue = action.slice(0, -1).split('(')
        obj.function = queue[0]
        for (const qs of queue[1].split(', ')) {
            if (qs.split('=')[1][0] === '"') {
                obj[qs.split('=')[0]] = qs.split('=')[1].slice(1, -1)
            } else {
                obj[qs.split('=')[0]] = parseInt(qs.split('=')[1])
            }
        }
        return obj
    }
    return (
        <StyledDiv types={['virtualAssistantChat', 'calendarContentScrollable']}>
            <StyledDiv types={['virtualAssistantMessages']}>
                <MessageLeft message="How may I assist you?" />
                {
                    log.map((x, index) => {
                        if (x.role == "system") { }
                        else if (x.content === "Calendar has been updated with those changes.") { }
                        else if (x.content === "Those changes were unwanted and thus have been discarded.") { }
                        else if (x.role == "user") {
                            return <MessageRight message={x.content} />
                        } else if (x.role == "assistant" && !x.content.startsWith('OP')) {
                            return <MessageLeft message={x.content} />
                        } else {
                            let modStr = x.content.replace('OP\n', '').replace('OP ', '')
                            let actions = modStr.split('\n').filter((x) => x.length !== null && x.length > 0).map(parser)
                            let bigS = ''
                            let argray = []
                            for (const a of actions) {
                                if (a.function === "create_appt") {
                                    argray = argray.concat(['Create Appointment?'])
                                    let d = new Date(a.date)
                                    argray = argray.concat([`ID: ${a.id}`])
                                    argray = argray.concat([`Desc: ${a.desc}`])
                                    argray = argray.concat([`Date: ${d.toDateString()}`])
                                    argray = argray.concat([`Time: ${d.toLocaleTimeString('en-US')}`])
                                    argray = argray.concat([`Length: ${a.duration} min`])
                                } else if (a.function === "change_duration") {
                                    argray = argray.concat(['Change Length?'])
                                    argray = argray.concat([`ID: ${a.id}`])
                                    argray = argray.concat([`New Length: ${a.new_duration} min`])
                                } else if (a.function === "change_date") {
                                    argray = argray.concat(['Change Date?'])
                                    let d = new Date(a.new_date)
                                    argray = argray.concat([`ID: ${a.id}`])
                                    argray = argray.concat([`New Date: ${a.new_date}`])
                                } else if (a.function === "change_desc") {
                                    argray = argray.concat(['Change Description?'])
                                    let d = new Date(a.new_date)
                                    argray = argray.concat([`ID: ${a.id}`])
                                    argray = argray.concat([`New Description: ${a.new_desc}`])
                                } else if (a.function === "change_id") {
                                    argray = argray.concat(['Change ID?'])
                                    argray = argray.concat([`ID: ${a.id}`])
                                    argray = argray.concat([`New ID: ${a.new_id}`])
                                } else if (a.function === "delete_appt") {
                                    argray = argray.concat(['Delete Appointment?'])
                                    argray = argray.concat([`ID: ${a.id}`])
                                }
                            }
                            bigS = bigS.substring(0, bigS.length - 1)
                            return <MessageLeft
                                isLast={index === log.length - 1}
                                message={bigS}
                                isConfirm={true} 
                                AS={props.AS}
                                array={argray}
                                gptConfirmRequest={gptConfirmRequest}
                                appointmentResponse={response}
                                {...props} />
                        }
                    })
                }
            </StyledDiv>
            <TextInput handleSubmit={handleSubmit}
                style={{ position: 'absolute' }}
                setChatView={props?.setChatView}
                chatView={props?.chatView}
                simpleChat={props?.simpleChat} />

            {/* {props?.simpleChat ?
                <StyledDiv style={{
                    display: 'flex',
                    justifyContent: 'flex-end',

                    width: '100%',
                }}>
                    <Fab
                        color="primary"
                        aria-label="add"
                        size="small"
                        style={{}}
                        onClick={() => props?.setChatView(!props?.chatView)}
                    >
                        <ListIcon />
                    </Fab>
                </StyledDiv> :
                null
            } */}
        </StyledDiv>
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(SimpleChat))