import React from "react"

import { StyledDiv, ButtonCustom, TypographyCustom, PaperCustom } from "../../../../../Chat/Styled"
import { Style, Subscript } from "@mui/icons-material"
import { textAlign } from "@mui/system"

// import { ButtonCustom } from '../../../Chat/Styled'
// import { StyledDiv } from '../../../Chat/Styled'
// import { dispatchMappers } from '../../../../redux/process/actionProcess'
// import { RA1 } from '../../../../redux/actions'
// import { connect } from 'react-redux'

const parsePrice = (price) => {
    let outPrice = (price).toString()
    outPrice = `$${price.substring(0, price.length - 2)}.${price.slice(-2)}`
    return outPrice
}

const SubscriptionCard = (props) => {
    return (
        <PaperCustom className="recolor-item"
            types={[
                'generalCard',
                'fullCard',

                (props?.compressedDesktop) ? 'compressedDesktop' :
                    (props?.compressed) ? 'compressedCard' :
                        'subscriptionPadding',
                'subscription',
                (props?.half) ? 'tighterCurves' : 'subscriptionFull',
                (props?.compressed) ? 'subscriptionCompressed' : '']}>
            <StyledDiv
                types={['threadCardTop',
                    'subscriptionTopRow',
                    (props?.half) ? 'subscriptionHalf' : ''
                ]} half={true}>
                <StyledDiv types={['subscriptionRolename']} roleColor={props.roleColor}>
                    <TypographyCustom variant="h5"
                        style={{ lineHeight: '2rem' }}>
                        {props.productMeta.metaString}
                    </TypographyCustom>
                </StyledDiv>

                <StyledDiv>
                    {props.name}
                </StyledDiv>
            </StyledDiv>

            <StyledDiv types={['threadCardBottom',
                'subscriptionBottomRow',
                (props?.half) ? 'subscriptionHalf' : ''
            ]} half={true}>
                <StyledDiv>
                    <StyledDiv types={['twoLineClamp']}
                        style={{ textAlign: 'left' }}>
                        <TypographyCustom variant="span"
                            sx={{
                                fontSize: '0.85rem',
                                lineHeight: '1rem',
                            }}>
                            {props.desc}
                        </TypographyCustom>
                    </StyledDiv>
                    <StyledDiv types={['subscriptionPriceBtn']}>
                        <StyledDiv>
                            {props?.compressed || props?.compressedDesktop ?
                                <></> :
                                <ButtonCustom
                                    variant="contained"
                                    sx={{
                                        height: '1.5rem',
                                        textTransform: 'none',
                                    }}>
                                    Subscribe
                                    {/* {parsePrice(props.price)}/mo */}
                                </ButtonCustom>
                            }
                        </StyledDiv>
                    </StyledDiv>
                </StyledDiv>
            </StyledDiv>
        </PaperCustom>
    )
}

const SubscriptionCardDisplay = function (props) {

    // Every card display must have n different modes.
    // Which mode is rendered is determined by a switch.
    // However, these modes must be the same across all cards.
    // Suggested modes: full-desktop, half-desktop, compressed-desktop, full, compressed

    // #
    switch (props.displayMode) {
        case 'fullDesktop':
            return <SubscriptionCard
                desktop={true}
                {...props} />
        case 'halfDesktop':
            return <SubscriptionCard
                desktop={true}
                half={true}
                {...props} />
        case 'compressedDesktop':
            return <SubscriptionCard
                compressedDesktop={true}
                half={true}
                desktop={true}
                {...props} />
        case 'full': // mobile
            return <SubscriptionCard
                desktop={false}
                {...props} />
        default:
            return <SubscriptionCard
                compressed={true}
                desktop={false}
                half={true}
                {...props} />
    }
}

export const subscriptionCardPackage = {
    requirements: ['displayMode', 'roleColor', 'name', 'desc', 'price',],
    CardDisplay: SubscriptionCardDisplay
}