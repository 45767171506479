import { createSlice, createAction } from '@reduxjs/toolkit'
import { createCachedSlice } from '../cache/createCachedSlice.js'

const user = createCachedSlice({
    name: 'users',
    initialState: {},
    reducers: {
        updateStatus: {
            cached: false,
            parameters: ['userId', 'status'],
            description: '',
            reduce: (state, action) => {
                const { userId, status } = action.payload
                const oldUserState = { ...state[userId] }
                oldUserState.status = status
                return { ...state, [userId]: oldUserState }
            },
        },
        addUser: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                const { id, content } = action.payload
                return {
                    ...state,
                    [id]: action.payload,
                }
            },
        },
        addUsers: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                let newState = { ...state }
                for (let key in action.payload) {
                    if (action.payload.hasOwnProperty(key)) {
                        newState[key] = action.payload[key]
                    }
                }
                return newState
            },
        },
    },
})

const userSlice = user
const userActions = user.actions
const addUsers = user.actions.addUsers
const users = user.reducer
export { userSlice, addUsers, users, userActions }
