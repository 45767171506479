import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack.js'
import AddIcon from '@mui/icons-material/Add'
import React from 'react'
import { dispatchMappers } from '../../../../../redux/process/actionProcess.js'
import { RA1 } from '../../../../../redux/actions.js'
import { connect } from 'react-redux'
import { withAS } from "../../../../_contexts/withAppState";

const mapDispatchToProps = dispatchMappers([RA1._appState.updateAppState])

const ServerNavFooter = function (props) {
    return (
        <div>
            <div style={{ width: '48px' }}></div>
            <div style={{ width: '48px' }}></div>
        </div>
    )
}

export default connect(null, mapDispatchToProps)(withAS(ServerNavFooter))
