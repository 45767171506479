import React, {useState} from 'react'
import {Menu, Item, Separator, Submenu} from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import {connect} from 'react-redux'
import {statePropMappers} from '../../../../../redux/process/selectorProcess.js'
import {withAS} from "../../../../_contexts/withAppState";

const makeMapStateToProps = statePropMappers(
    {
        getUserMeta: ['userMeta', []],
        getUserTopRank: ['userTopRank', []],
        getServerAbstractRoles: ['serverAbstractRoles', []],
    },
    {}
)

function UserMenu(props) {
    const addFriendVisible = props.userMeta.id !== props.clientUserMeta.id
    const userRoles = props.userMeta.roles
    const modPerms =
        (props.isAdmin || props.isModerator) &&
        props.clientTopRank < props.userTopRank
    const roleManagementPerms =
        (props.canManageRoles || props.isAdmin) &&
        props.clientTopRank <= props.userTopRank
    const roleLinks = props.serverAbstractRoles.map((role, index) => {
        if (index > props.clientTopRank) {
            if (userRoles.includes(role.id)) {
                return (
                    <Item
                        key={role.id + '2'}
                        onClick={(e) =>
                            props.handleItemClick(
                                e,
                                props,
                                'removeRoleFromUser',
                                role.id
                            )
                        }
                    >
                        <b>{role.role}</b>
                    </Item>
                )
            } else {
                return (
                    <Item
                        key={role.id + '3'}
                        onClick={(e) =>
                            props.handleItemClick(
                                e,
                                props,
                                'addRoleToUser',
                                role.id
                            )
                        }
                    >
                        {role.role}
                    </Item>
                )
            }
        } else {
            if (userRoles.includes(role.id)) {
                return (
                    <Item key={role.id + '4'} disabled>
                        <b>{role.role}</b>
                    </Item>
                )
            } else {
                return (
                    <Item key={role.id + '5'} disabled>
                        {role.role}
                    </Item>
                )
            }
        }
    })
    if (props.selectedUser !== null) {
        //#TODO: Add check to see if user is already friend
        return (
            <Menu id={props.menuId} style={{zIndex:"30000"}}>
                {addFriendVisible ? (
                    <Item onClick={(e) => props.handleItemClick(e, props, 'addFriend', null)}>
                        Add Friend
                    </Item>
                ) : null}
                {props.userMeta.id !== props.clientUserMeta.id ? (
                    <Item onClick={(e) => props.handleItemClick(e, props, 'block', null)}>
                        Block
                    </Item>
                ) : null}
                {props.userMeta.id !== props.clientUserMeta.id ? (
                    <Item onClick={(e) => props.AS.nav.ops.changeToProfilePageWithStateClear(props.userMeta.id.split("_")[0])}>
                        View Profile
                    </Item>
                ) : null}
                <Separator/>
                {modPerms ? (
                    <Item onClick={(e) => props.handleItemClick(e, props, 'kick', null)}>
                        Kick User
                    </Item>
                ) : null}
                {modPerms ? (
                    <Item
                        onClick={(e) => props.handleItemClick(e, props, 'ban', null)}>
                        Ban User
                    </Item>
                ) : null}
                {roleManagementPerms ? (
                    //horizontalDisplay={"left"} defaultArrowDirection={"right"}>
                    <Submenu label="Roles">{roleLinks}</Submenu>
                ) : (
                    <Item disabled>Roles</Item>
                )}
            </Menu>
        )
    }
    return <Menu id={props.menuId}>.</Menu>
}

export default connect(makeMapStateToProps, null)(withAS(UserMenu))
