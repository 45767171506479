import React, { useState, useEffect } from 'react'
import { RS1, RA1 } from '../../../../../redux/actions'
import { SA1 } from '../../../../../hs/requestGlobals.mjs'
import { statePropMapper } from '../../../../../redux/process/selectorProcess'
import { dispatchMappers } from '../../../../../redux/process/actionProcess'
import { connect } from 'react-redux'
import { ButtonCustom } from '../../../Styled'
import ProductFields from './ProductFields'

const makeMapStateToProps = statePropMapper([])

const mapDispatchToProps = dispatchMappers([
    SA1.createProduct,
    SA1.editProduct,
    SA1.deleteProduct,
])

const ModifyProduct = function (props) {
    // TODO: when a form is submitted, it should send an api call
    // to the backend to modify the product.

    // #
    const [fields, setFields] = useState({
        name: '',
        desc: '',
        icon: '',
        type: '',
        price: '',
    })

    // #
    const handleSubmit = async (e) => {

        switch (props.formType) {
            case 'edit':
                props.dispatch.server.editProduct(
                    props.serverId,
                    props.product.id,
                    fields.name,
                    fields.desc,
                    fields.icon,
                    fields.type,
                    fields.price
                )
                break

            case 'create': //TODO: get server id props.serverId
                props.dispatch.server.createProduct(
                    props.serverId,
                    fields.name,
                    fields.desc,
                    fields.icon,
                    fields.type,
                    productPrice,
                    ''
                )
                break

            case 'delete':
                //TODO: make this a popup on the product list page instead.
                props.dispatch.server.deleteProduct(
                    props.serverId,
                    props.product.id
                )
                break

            default:
                break
        }

        props.backButton()
    }

    const fieldChange = (field, data) => {
        //TODO: do regex on backend.
        let writeData = data !== props.product[field] ? data : ''
        setFields({ ...fields, [field]: writeData })
    }

    // #
    useEffect(() => { }, [fields])

    // #
    return (
        <div style={{ margin: '5%' }}>
            <div style={{ float: 'right' }}>
                <ButtonCustom types={[]} onClick={props.backButton}>
                    Back
                </ButtonCustom>
            </div>

            <form onSubmit={handleSubmit}>
                <ProductFields
                    fieldChange={fieldChange}
                    formType={props.formType}
                    product={props.product}
                />

                <ButtonCustom onClick={handleSubmit}>
                    {props.buttonText}
                </ButtonCustom>
                <br />
            </form>
        </div>
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(ModifyProduct)
