import React, {useEffect, useState} from "react";
import {StyledDiv, PaperCustom} from "./Styled";
import SidebarLeft from "./SidebarLeft";
import SidebarRight from "./SidebarRight";
import {ChatWindowMenu} from "./chatWindowMenu";
import MediaQuery from "react-responsive";
import {Fade, SwipeableDrawer} from "@mui/material";
import {useAdaptiveDisplay} from "../../hs/adaptive";
import {MicroblogLayout} from "./Microblog";
import MobileNav from "./MobileNav";
import TopBar from "./TopBar";

// import { Paper } from "@mui/material";

export function LayoutSwitch(props) {
    const [redirect, setRedirect] = useState(null)

    useEffect(() => {
        console.log(props.AS)
    }, [props.AS])

    if (props.page !== "profile") {
        return (<>
            <StyledDiv types={['chatLayout', '_FVH']}
                       style={(props.desktop) ? {} : {
                           height: 'calc(100dvh - 3.5em)',
                           zIndex: props.AS.nav.state.hideNavBar ? 2007 : 0,
                       }}>
                <div ref={props.windowRef}/>
                <ChatWindowMenu
                    activeServer={props.activeServer}
                    activeChannel={props.activeChannel}
                    userId={props.userId}
                    AS={props.AS}
                    dmMeta={props.dmMeta}
                    mode={props.page}
                    desktop={props?.desktop}
                />
            </StyledDiv>
        </>)
    } else {
        if (props.page === "profile") {
            return (<>
                <StyledDiv types={['chatLayout', '_FVH']}>
                    <div ref={props.windowRef}/>
                    <MicroblogLayout
                        AS={props.AS}
                        activeServer={props.AS.activeServer}
                        server={props.AS.activeServer}
                        activeChannel={props.AS.activeChannel}
                        userId={props.userId}
                        dmMeta={props.dmMeta}
                        viewingMode={props.viewingMode}
                        chatServers={props.chatServers}
                        setRedirect={setRedirect}
                        openModalMenu={props.AS.modals.ops.openModal}
                        openInfoModal={props.AS.modals.ops.openInfoModal}
                        allUsers={props.allUsers}
                        setActiveServer={props.AS.nav.ops.changeActiveServer}
                        page={props.page}
                        getProductsByServer={props.getProductsByServer}
                        desktop={props?.desktop}
                    />
                </StyledDiv>
            </>)
        } else {
            return null
        }
    }
}


export function ChatLayout(props) {
    const displaySettings = useAdaptiveDisplay()
    const [state, setState] = React.useState({
        left: false,
        right: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        //     return;
        // }

        setState({...state, [anchor]: open});
    };
    const swapToDrawer = (anchor) => (event) => {
        setState({...state, ['right']: anchor === 'right', ['left']: anchor === 'left'});
    };

    const [width, setWidth] = useState('20%')
    useEffect(() => {
        setWidth('20%')
        setTimeout(() => {
            setWidth("25%");
        }, 500)
    }, [props.page])

    const [mobileNavThreadWatcher, setMobileNavThreadWatcher] = useState(false)
    const [mobileNavTabValue, setMobileNavTabValue] = useState(0)

    const [leftBar, setLeftBar] = useState(false)
    const [rightBar, setRightBar] = useState(false)
    const [rightMenu, setRightMenu] = useState(0)

    return (
        <>
            <MediaQuery minAspectRatio={"16 / 9"}>
                {/* <div style={{
                position: 'absolute',
                left: 'auto',
                right: 'auto',
                aspectRatio: '16/9'
            }}> */}
                <StyledDiv types={['secondaryColor']} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <StyledDiv types={['chatWrapper', 'secondaryColor']}
                        // side={'left'}
                               style={{
                                   // width: '100%',
                                   height: '100%',
                                   aspectRatio: '16/9'
                                   // aspectRatio: '4/3'
                               }}>
                        <StyledDiv types={['sidebarLayout']} menu={props.AS.nav.state.menuL}
                                   style={{
                                       maxWidth: props.AS.nav.state.menuL === 'profile' ? '500px' : '300px'
                                   }} desktop={true}>
                            <Fade key={props.AS.nav.state.menuL} in={true}>
                                <div>
                                    <SidebarLeft
                                        server={props.activeServer}
                                        channel={props.activeChannel}
                                        userId={props.userId}
                                        allLandingPageProps={props}
                                        desktop={true}
                                    />
                                </div>
                            </Fade>
                        </StyledDiv>

                        <LayoutSwitch windowRef={props.windowRef} desktop={true} {...props} />

                        <StyledDiv types={['sidebarLayout', 'secondaryColor']}
                                   style={{
                                       maxWidth: props.AS.nav.state.menuL === 'profile' ? '360px' : '300px'
                                   }}
                                   side={'right'} desktop={true}>
                            <SidebarRight
                                key={props.activeChannel}
                                server={props.activeServer}
                                userId={props.userId}
                                channel={props.activeChannel}
                                desktop={true}
                            />
                        </StyledDiv>

                    </StyledDiv>
                    {/* </div> */}
                </StyledDiv>
            </MediaQuery>
            <MediaQuery maxAspectRatio={"16 / 9"} minAspectRatio={"4 / 3"}>
                {/* <div style={{
                    position: 'absolute',
                    left: 'auto',
                    right: 'auto',
                    aspectRatio: '16/9'
                }}> */}
                <StyledDiv types={['secondaryColor']} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <StyledDiv types={['chatWrapper', 'secondaryColor']}
                               style={{
                                   width: '100%',
                                   height: '100%',
                               }}>
                        <StyledDiv types={['sidebarLayout']} menu={props.AS.nav.state.menuL}
                                   style={{
                                       maxWidth: props.AS.nav.state.menuL === 'profile' ? '500px' : '300px'
                                   }} desktop={true}>
                            <Fade key={props.AS.nav.state.menuL} in={true}>
                                <div>
                                    <SidebarLeft
                                        server={props.activeServer}
                                        channel={props.activeChannel}
                                        userId={props.userId}
                                        allLandingPageProps={props}
                                        desktop={true}
                                    />
                                </div>
                            </Fade>
                        </StyledDiv>

                        <LayoutSwitch desktop={true} windowRef={props.windowRef} {...props} />

                        <StyledDiv types={['sidebarLayout', 'secondaryColor']}
                                   style={{
                                       maxWidth: props.AS.nav.state.menuL === 'profile' ? '360px' : '300px'
                                   }}
                                   side={'right'} desktop={true}>
                            <SidebarRight
                                key={props.activeChannel}
                                server={props.activeServer}
                                userId={props.userId}
                                channel={props.activeChannel}
                                desktop={true}
                            />
                        </StyledDiv>

                    </StyledDiv>
                    {/* </div> */}
                </StyledDiv>
            </MediaQuery>

            <MediaQuery maxAspectRatio={"4 / 3"}>
                <div>
                    <TopBar
                        server={props.activeServer}
                        page={props.page}
                        toggleDrawer={toggleDrawer}
                        swapToDrawer={swapToDrawer}
                        setMobileNavTabValue={setMobileNavTabValue}
                        setMobileNavThreadWatcher={setMobileNavThreadWatcher}
                        leftBar={leftBar}
                        setLeftBar={setLeftBar}
                        rightBar={rightBar}
                        setRightBar={setRightBar}
                        rightMenu={rightMenu}
                        setRightMenu={setRightMenu}/>
                    <StyledDiv types={['chatWrapper']} style={{}}>
                        <SwipeableDrawer
                            style={{height: '96dvh'}}
                            onOpen={toggleDrawer('left', true)}
                            onClose={toggleDrawer('left', false)}
                            open={leftBar}
                            // disableBackdropTransition={!iOS}
                            // disableDiscovery={iOS}
                            anchor={'left'}
                        >
                            <StyledDiv types={['sidebarLayout']} desktop={false}>
                                <SidebarLeft
                                    server={props.activeServer}
                                    channel={props.activeChannel}
                                    userId={props.userId}
                                    allLandingPageProps={props}
                                    desktop={false}
                                />
                            </StyledDiv>
                        </SwipeableDrawer>

                        <LayoutSwitch desktop={false} windowRef={props.windowRef} {...props} />

                        <SwipeableDrawer
                            style={{height: '96dvh'}}
                            onOpen={toggleDrawer('right', true)}
                            onClose={toggleDrawer('right', false)}
                            open={rightBar}
                            // disableBackdropTransition={!iOS}
                            // disableDiscovery={iOS}
                            anchor={'right'}
                        >
                            <StyledDiv types={['sidebarLayout']} desktop={false}>
                                <Fade key={props.AS.nav.state.menuR + props.activeChannel + props.activeServer}
                                      in={true}>
                                    <div>
                                        <SidebarRight
                                            key={props.activeChannel}
                                            server={props.activeServer}
                                            userId={props.userId}
                                            channel={props.activeChannel}
                                            allLandingPageProps={props}
                                            mobileNavThreadWatcher={mobileNavThreadWatcher}
                                            mobileNavTabValue={mobileNavTabValue}
                                            desktop={false}
                                        />
                                    </div>
                                </Fade>
                            </StyledDiv>
                        </SwipeableDrawer>

                    </StyledDiv>

                    <MobileNav
                        server={props.activeServer}
                        page={props.page}
                        toggleDrawer={toggleDrawer}
                        swapToDrawer={swapToDrawer}
                        setMobileNavTabValue={setMobileNavTabValue}
                        setMobileNavThreadWatcher={setMobileNavThreadWatcher}

                        leftBar={leftBar}
                        setLeftBar={setLeftBar}
                        rightBar={rightBar}
                        setRightBar={setRightBar}
                        rightMenu={rightMenu}
                        setRightMenu={setRightMenu}/>
                </div>
            </MediaQuery>
        </>
    )
}