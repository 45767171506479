export const ListItemAvatarCustomStyle = (mode, palette) => ({
    ListItemAvatarCustom: {
        defaultProps: {},
        styleOverrides:{"nothing":{}},altStyleOverrides: {
            root: {},
            user: (props) => {
                let borderColor = ""
                if (props?.types.includes('user')) {
                    switch (props.status) {
                        case 'online':
                            borderColor = 'green'
                            break
                        case 'busy':
                            borderColor = 'red'
                            break
                        case 'away':
                            borderColor = 'yellow'
                            break
                        default: // case 'offline'
                            borderColor = 'grey'
                            break
                    }
                }
                return {
                    '& > *': { border: '2px solid ' + borderColor },
                    minWidth: '35px'
                }
            },
        },
    },
})
