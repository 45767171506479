// import * as React from 'react'
import React, { useState, useEffect, useRef } from 'react'
import { Box, useTheme } from '@mui/material'
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import { statePropMapper } from '../../../redux/process/selectorProcess'
import { dispatchMappers } from '../../../redux/process/actionProcess'
import { RS1, RA1 } from '../../../redux/actions'
import { connect } from 'react-redux'
import ChatInputContainer from '../ChatWindow/ChatInputContainer'
import SingleThread from './SingleThread'
import FullMasonryComponent from "./Masonry";
import { useHistory, useLocation } from 'react-router-dom'
import withContentDropzone from "../../_wrappers/withContentDropzone";
import withReactVirtualized from "../../_wrappers/withReactVirtualized";
import GenericCard from "../../_reusable/Cards/UniversalCard";
import { useVirtualizedControls } from "../../_wrappers/withReactVirtualized/utilityHooks";
import { EmptyComponent } from "../EmptyComponent";
import BlogInput from "../ChatInputs/BlogInput";
import { BottomRightHover, TopRightHover } from "../../_design/BottomHover";
import { ButtonCustom, vDH } from "../Styled";

const makeMapStateToProps = statePropMapper([
    RS1.getUserMeta('userMeta'),
    RS1.getUser('userData'),
    RS1.store('imageStore', 'imageStore'),
    RS1.getServerAbstractRoles('serverAbstractRoles'),
])

const mapDispatchToProps = dispatchMappers([
    RA1.imageStore.addImage,
    RA1.imageStore.clear,
    RA1.client.error,
])

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '10px',
    textAlign: 'center',
    borderRadius: 0,
}))

const TimelineMessage = (props) => {
    let user = props?.passdownProps?.threadSystem.users[props.componentProps.uId]
    const mes = props.componentProps
    return <GenericCard
        message={props.componentProps}
        user={user}
        product={null}
        innerColumnsStyle={null}
        replies={props?.componentProps?.metaData?.threadReplies}
        reactions={mes?.reply?.likedBy ? mes?.reply?.likedBy.length : 0}
        highlights={0}
        quotes={0}
        push={props.passdownProps.AS.push}
        cardType={'timelineMsg'}
        timelineOps={props.passdownProps?.threadSystem}
        displayMode={props.passdownProps?.desktop ? 'fullTotalDesktop' : 'fullTotal'}
        cardTestMode={false}
        likedByUser={mes?.likedByUser}
    />
}
const VirtualizedTimelineMessageList = withReactVirtualized(TimelineMessage)

const Splitter = (props) => {
    const virtualizedList = useRef(null)
    const controls = useVirtualizedControls(virtualizedList)
    if (props.standardThreadView || props.forceThreadView) {
        return <FullMasonryComponent
            style={{
                height: ((window.screen.width / window.screen.height) < (4 / 3)) ? 'calc(100dvh - 3em)' : '100vh',
                width: '100%',
                zIndex: '1003',
            }}
            server={props.server}
            items={props.threadSystem.threadPics}
            threadsWatched={props.threadSystem.threadsWatched}
            setSingleThreadPage={props.threadSystem.setSingleThreadPage}
            setSelectedThread={props.threadSystem.setSelectedThread}
            threadsArray={props.threadSystem.threads}
        />
    } else {
        return (
            <VirtualizedTimelineMessageList
                listData={props.threadSystem.threadsImmutable}
                virtualizedList={virtualizedList}
                height={((window.screen.width / window.screen.height) < (4 / 3)) ? 'calc(100dvh - 3em)' : '100vh'}
                passdownProps={{
                    threadSystem: props.threadSystem,
                    AS: props.AS,
                    forceThreadView: props.forceThreadView,
                    desktop: props?.desktop
                }}
                style={{
                    width: "100%",
                    marginTop: `calc(${vDH(3)} + 3em)`,
                    marginBottom: '5em',
                    margin: '3em',
                    height: "fit-content",
                    zIndex: '1003'
                }}
                desktop={props?.desktop}
                dummyMessage={<div style={{ height: '5em' }}></div>}
                selectItem={() => {
                }}
            />
        )
    }
}

const ThreadView = (props) => {
    const history = useHistory()
    const [forceThreadView, setForceThreadView] = useState(false)
    let location = useLocation()
    const theme = useTheme()
    if (props.threadSystem.singleThreadPage === false) {
        return (<>
            {/*{!props.standardThreadView ? <TopRightHover>*/}
            {/*    <ButtonCustom onClick={() => setForceThreadView(old => !old)}>*/}
            {/*        Thread View*/}
            {/*    </ButtonCustom>*/}
            {/*</TopRightHover> : null}*/}
            <div style={{
                height: ((window.screen.width / window.screen.height) < (4 / 3)) ? `calc(${vDH(100)} - 6.4em)` : vDH(100),
                width: '100%',
                marginTop: ((window.screen.width / window.screen.height) < (4 / 3)) ? '3.2em' : null,
                transform: 'translate(0, 0)',
                // padding: '10px',
                backgroundColor: theme.palette.background.primary,
            }}>
                {
                    (props.threadSystem.threads.length !== 0) ?
                        <Splitter forceThreadView={forceThreadView}
                            style={{ height: vDH(100), width: '100%' }}{...props} /> :
                        <EmptyComponent text={"No threads"} />

                }
                <div style={{
                    position: 'absolute',
                    bottom: '10px',
                    display: 'flex',
                    width: '100%',
                    zIndex: '1004',
                }}>
                    <div style={{ alignSelf: 'flex-start' }}>
                        {props.threadSystem.blogView ? <BlogInput
                            server={props.server}
                            channel={props.channel}
                            user={props.userId}
                            sendFiles={props.sendFiles}
                            clearFiles={props.clearFiles}
                            hasFiles={props.hasFiles}
                            storeFile={props.storeFile}
                            blobLinks={props.blobLinks}
                            writeToThread={props.standardThreadView}
                            writeToProfile={!props.standardThreadView}
                            threadView={true}
                            singleThread={false}
                            // showBlogIcon={true}
                            setBlogView={props.threadSystem.setBlogView}
                            triggerThreadModeReload={props.threadSystem.triggerThreadModeReload}
                        /> :
                            <ChatInputContainer
                                server={props.server}
                                triggerThreadModeReload={props.threadSystem.triggerThreadModeReload}
                                channel={props.channel}
                                user={props.userId}
                                sendFiles={props.sendFiles}
                                clearFiles={props.clearFiles}
                                hasFiles={props.hasFiles}
                                storeFile={props.storeFile}
                                blobLinks={props.blobLinks}
                                writeToThread={props.standardThreadView}
                                writeToProfile={!props.standardThreadView}
                                threadView={true}
                                singleThread={false}
                                showBlogIcon={props.standardThreadView}
                                setBlogView={props.threadSystem.setBlogView}
                            />}
                    </div>
                </div>
            </div>
        </>
        )
    } else {
        if (props.threadSystem.threadMsgs.size > 0) {
            return (
                <SingleThread
                    threadSystem={props.threadSystem}
                    standardThreadView={props.standardThreadView}
                    server={props.server}
                    channel={props.channel}
                    userId={props.userId}
                    sendFiles={props.sendFiles}
                    clearFiles={props.clearFiles}
                    hasFiles={props.hasFiles}
                    AS={props.AS}
                    storeFile={props.storeFile}
                    blobLinks={props.blobLinks}
                    desktop={props?.desktop}
                // {...props}
                />
            )
        } else {
            return null
        }
    }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withContentDropzone('chat')(ThreadView))
