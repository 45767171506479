import React from 'react'
import Button from "@mui/material/Button";
import { TextFieldCustom } from "../Styled";
import SendIcon from '@mui/icons-material/Send';


export const TextInput = (props) => {
    return (
        <form noValidate autoComplete="off" onSubmit={(e) => {
            e.preventDefault()
            props.handleSubmit(e.target[0].value)
        }} style={{
            position: 'relative',
            width: '100%',
        }}>
            <TextFieldCustom
                id="standard-text"
                //label="メッセージを入力"
                size={'small'}
                types={['virtualAssistantChat']}
                style={{
                    width: 'calc(100%)',
                    position: 'absolute',
                    // bottom: '1em',
                }}
            //margin="normal"
            />
        </form>
    )
}

/*
            <Button variant="contained" color="primary">
                <SendIcon/>
            </Button>
*/