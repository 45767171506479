import { styled } from '@mui/material/styles'
import ListItem from '@mui/material/ListItem'
import { useContext } from 'react'
import { ThemeContext } from '@emotion/react'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { Link } from '@mui/icons-material'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Checkbox, FormControlLabel,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
} from '@mui/material'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import { Grid } from 'react-virtualized'
import { Menu } from 'react-contexify'
import { Badge } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import { Paper } from '@mui/material'
import ChatMessage from "../ChatWindow/ChatMessage";

const ev = (x, props) => (typeof x === 'function' ? x(props) : x)

export const resolver = (name) => (props, styles) => {
    // let resolutionOrder = []
    let resolutionOrder = [props.theme.components[name].altStyleOverrides['root']]
    if (props?.types) {
        props.types.map((x) => {
            props.theme.globals?.[x]
                ? resolutionOrder.push(ev(props.theme.globals[x], props))
                : null
            props.theme.components?.[name]?.altStyleOverrides?.[x] ? resolutionOrder.push(ev(props.theme.components[name].altStyleOverrides[x], props)) : null
        })
    }
    return resolutionOrder
}

export const modifyProps = (theme, options, props) => {
    let defaultProps = { ...theme?.components?.[options?.name]?.defaultProps }
    if (props?.types) {
        props.types.map((x) => {
            if (theme?.components?.[options?.name]?.propOverrides?.[x]) {
                defaultProps =
                    theme?.components?.[options?.name]?.propOverrides?.[x]
            }
        })
    }
    return defaultProps
}

const mStyled = (Component, options) => (operation) => {
    let modifiedComponent = (props) => {
        const theme = useContext(ThemeContext)
        let defaultProps = modifyProps(theme, options, props)
        return <Component {...defaultProps} {...props} />
    }
    return styled(modifiedComponent, options)(operation)
}

const OptionsMaker = (name) => ({
    name: name,
    slot: 'Root',
    overridesResolver: resolver(name),
})

const FDiv = (props) => {
    return (<div style={props.style} className={props.className}>{props.children}</div>)
}
const p = (props) => null
export const GridCustom = mStyled(Grid, OptionsMaker('GridCustom'))(p)
export const PaperCustom = mStyled(Paper, OptionsMaker('PaperCustom'))(p)
export const ListItemCustom = mStyled(ListItem, OptionsMaker('ListItemCustom'))(p)
export const ListItemTextCustom = mStyled(ListItemText, OptionsMaker('ListItemTextCustom'))(p)
export const ListItemSecondaryActionCustom = mStyled(ListItemSecondaryAction, OptionsMaker('ListItemSecondaryActionCustom'))(p)
export const ButtonCustom = mStyled(Button, OptionsMaker('ButtonCustom'))(p)
export const TypographyCustom = mStyled(Typography, OptionsMaker('TypographyCustom'))(p)
export const AvatarCustom = mStyled(Avatar, OptionsMaker('AvatarCustom'))(p)
export const IconButtonCustom = mStyled(IconButton, OptionsMaker('IconButtonCustom'))(p)
export const TextFieldCustom = mStyled(TextField, OptionsMaker('TextFieldCustom'))(p)
export const BoxCustom = mStyled(Box, OptionsMaker('BoxCustom'))(p)
export const LinkCustom = mStyled(Link, OptionsMaker('LinkCustom'))(p)
export const ListItemButtonCustom = mStyled(ListItemButton, OptionsMaker('ListItemButtonCustom'))(p)
export const ListItemAvatarCustom = mStyled(ListItemAvatar, OptionsMaker('ListItemAvatarCustom'))(p)
export const ListItemIconCustom = mStyled(ListItemIcon, OptionsMaker('ListItemIconCustom'))(p)
export const StyledDiv = mStyled(FDiv, OptionsMaker('StyledDiv'))(p)
export const AccordionCustom = mStyled(Accordion, OptionsMaker('AccordionCustom'))(p)
export const AccordionSummaryCustom = mStyled(AccordionSummary, OptionsMaker('AccordionSummaryCustom'))(p)
export const AccordionDetailsCustom = mStyled(AccordionDetails, OptionsMaker('AccordionDetailsCustom'))(p)
export const MenuCustom = mStyled(Menu, OptionsMaker('MenuCustom'))(p)
export const StyledB = mStyled('b', OptionsMaker('StyledB'))(p)
export const BadgeCustom = mStyled(Badge, OptionsMaker('BadgeCustom'))(p)
export const BackdropCustom = mStyled(Backdrop, OptionsMaker('BackdropCustom'))(p)
export const CheckboxCustom = mStyled(Checkbox, OptionsMaker('CheckboxCustom'))(p)
export const FormControlLabelCustom = mStyled(FormControlLabel, OptionsMaker('FormControlLabelCustom'))(p)
export const ChatMessageCustom = mStyled(ChatMessage, OptionsMaker('ChatMessageCustom'))(p)

export const vDH = (number) => {
    if ((window.screen.width / window.screen.height) < (4 / 3)) {
        return number + 'dvh'
    } return number + 'vh'
}