function updateLocalCache(
    obj,
    orderedCacheKeysTEMP,
    orderedCacheKeys,
    versions,
    result,
    key
) {
    if (orderedCacheKeysTEMP.length > 0) {
        let first = orderedCacheKeysTEMP.shift()
        if (first in obj) {
            versions.push(obj[first].v)
            updateLocalCache(
                obj[first],
                orderedCacheKeysTEMP,
                orderedCacheKeys,
                versions,
                result,
                key
            )
        } else {
            obj[first] = { v: 0 }
            versions.push(0)
            updateLocalCache(
                obj[first],
                orderedCacheKeysTEMP,
                orderedCacheKeys,
                versions,
                result,
                key
            )
        }
        return null
    } else {
        let cacheVersion = { result: result }
        orderedCacheKeys.map((key, index) => {
            cacheVersion[key] = versions[index]
        })
        obj[key] = cacheVersion
    }
}

function updateVersions(obj, orderedCacheKeysTEMP, orderedCacheKeys) {
    if (orderedCacheKeysTEMP.length > 0) {
        let first = orderedCacheKeysTEMP.shift()
        if (first in obj) {
            obj[first].v = obj[first].v + 1
            updateVersions(obj[first], orderedCacheKeysTEMP, orderedCacheKeys)
        } else {
            obj[first] = { v: 0 }
            updateVersions(obj[first], orderedCacheKeysTEMP, orderedCacheKeys)
        }
        return null
    } else {
        return null
    }
}

function validate(
    obj,
    orderedCacheKeysTEMP,
    versions,
    key,
    validationCondition
) {
    if (orderedCacheKeysTEMP.length > 0) {
        let first = orderedCacheKeysTEMP.shift()
        if (first in obj) {
            versions[first] = obj[first].v
            return validate(
                obj[first],
                orderedCacheKeysTEMP,
                versions,
                key,
                validationCondition
            )
        } else {
            return true
        }
    } else {
        return validationCondition(obj, key, versions)
    }
}

function getCachedResult(obj, orderedCacheKeysTEMP, key) {
    if (orderedCacheKeysTEMP.length > 0) {
        let first = orderedCacheKeysTEMP.shift()
        if (first in obj) {
            return getCachedResult(obj[first], orderedCacheKeysTEMP, key)
        } else {
            console.log('Cache failure')
            return null
        }
    } else {
        if (key in obj) {
            return obj[key].result
        } else {
            console.log('Cache failure')
            return null
        }
    }
}

export const cache = {
    cacheMem: {},
    update: (action) => {
        let { orderedCacheKeysIds, result, key } = action.payload
        console.log('- updating data cache: ' + key)
        let orderedCacheIdsTemp = [...orderedCacheKeysIds]
        let firstKey = orderedCacheKeysIds[0]
        let versions = []
        if (firstKey in cache.cacheMem) {
            updateLocalCache(
                cache.cacheMem,
                orderedCacheIdsTemp,
                orderedCacheKeysIds,
                versions,
                result,
                key
            )
        } else {
            let localCacheObject = {}
            updateLocalCache(
                localCacheObject,
                orderedCacheIdsTemp,
                orderedCacheKeysIds,
                versions,
                result,
                key
            )
            cache.cacheMem[firstKey] = localCacheObject[firstKey]
        }
    },
    versionUpdate: (action) => {
        console.log(
            '%c - cache version update',
            'background: #222; color: #bada55'
        )
        let { orderedCacheKeys, keyContainer } = action.payload
        let orderedCacheKeysIds = orderedCacheKeys.map(
            (key) => keyContainer[key]
        )
        let orderedCacheIdsTemp = [...orderedCacheKeysIds]
        updateVersions(cache.cacheMem, orderedCacheIdsTemp, orderedCacheKeysIds)
    },
    isUpdateRequired: (
        orderedCacheKeysIds,
        key,
        validationCondition = cache._defaultValidationCondition
    ) => {
        let versions = {}
        let orderedCacheIdsTemp = [...orderedCacheKeysIds]
        return validate(
            cache.cacheMem,
            orderedCacheIdsTemp,
            versions,
            key,
            validationCondition
        )
    },
    getCacheData: (orderedCacheKeysIds, key) => {
        let orderedCacheIdsTemp = [...orderedCacheKeysIds]
        return getCachedResult(cache.cacheMem, orderedCacheIdsTemp, key)
    },
    _defaultValidationCondition: (obj, key, versions) => {
        if (!obj.hasOwnProperty(key)) {
            return true
        }
        for (const otherKey in versions) {
            if (versions.hasOwnProperty(otherKey)) {
                if (versions[otherKey] !== obj[key][otherKey]) {
                    return true
                }
            }
        }
        return false
    },
}
