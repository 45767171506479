import {createCachedSlice} from '../cache/createCachedSlice.js'
import {addNewMessage, changeToDM, clearNotifications} from './actions'
import {setActiveChannel} from './monostate/mono'

const nonPriorityTypes = ["dm", "atRole", "atUser"]
const ensureArrayInit = (json, field) => {
    json[field] = json[field] ? json[field] : []
}
const messageUpdate = (notificationStore, type, channelId, mes, msgString) => {
    if (nonPriorityTypes.includes(type)) {
        notificationStore[channelId].messages = [...notificationStore[channelId].messages,
            {
                mId: mes.mId,
                type: type,
                mes: mes.message,
                msgString: msgString,
            }]
    } else {
        notificationStore[channelId].priorityMessages = [...notificationStore[channelId].priorityMessages,
            {
                mId: mes.mId,
                type: type,
                mes: mes.message,
                msgString: msgString,
            }]
    }
}
const updateNotificationForChannel = (notificationStore, mes) => {
    let mesSplit = mes.message.split(':')
    let added = false
    let channelId = mesSplit[1]
    let type = mesSplit[0]
    let msgString = mesSplit[3] ? mesSplit[3] : 'na'
    let createdAtDate = new Date(mes.createdAt).getTime()
    let currentDate = new Date().getTime()
    let lastCheckedDate = notificationStore?.[channelId]?.lastCheckedDate
        ? notificationStore[channelId].lastCheckedDate
        : currentDate
    if (!notificationStore?.[channelId]) {
        added = true
        notificationStore[channelId] = {}
        notificationStore[channelId].lastCheckedDate = lastCheckedDate
        notificationStore[channelId].oldestCreatedAtDate = createdAtDate
        notificationStore[channelId].newestCreatedAtDate = createdAtDate
        notificationStore[channelId].num = 1
        notificationStore[channelId].priorityMessages = []
        notificationStore[channelId].messages = []
        notificationStore[channelId].olderMessages = []
        messageUpdate(notificationStore, type, channelId, mes, msgString)
    } else {
        notificationStore[channelId] = {...notificationStore[channelId]}
    }
    ensureArrayInit(notificationStore[channelId], "priorityMessages")
    ensureArrayInit(notificationStore[channelId], "messages")
    ensureArrayInit(notificationStore[channelId], "olderMessages")
    if (((createdAtDate > lastCheckedDate) || !nonPriorityTypes.includes(type)) && !added) {
        added = true
        if (createdAtDate > notificationStore[channelId].newestCreatedAtDate) {
            notificationStore[channelId].num += 1
            notificationStore[channelId].newestCreatedAtDate = createdAtDate
        }
        messageUpdate(notificationStore, type, channelId, mes, msgString)
    } else {
        if (!added) {
            notificationStore[channelId].olderMessages = [
                ...notificationStore[channelId]?.olderMessages,
                {
                    mId: mes.mId,
                    mes: mes.message,
                    type: type,
                    msgString: msgString,
                },
            ]
        }
    }
    return notificationStore
}

const notification = createCachedSlice({
    name: 'notifications',
    initialState: {notificationStore: {}, notificationsInitialized: false},
    reducers: {
        clearNotifications: {
            cached: false,
            parameters: ['notificationDomainId'],
            description: '',
            reduce: (state, action) => {
                const {notificationDomainId} = action.payload
                let notificationStore = {...state.notificationStore}
                if (notificationStore.hasOwnProperty(notificationDomainId)) {
                    let currentDate = new Date().getTime()
                    let notificationChecker = {
                        ...notificationStore[notificationDomainId],
                    }
                    notificationChecker.num = 0
                    notificationChecker.date = currentDate
                    notificationChecker.messages = []
                    notificationStore[notificationDomainId] =
                        notificationChecker
                }
                return {
                    ...state,
                    notificationStore,
                    version: state.version + 1,
                }
            },
        },
        resetNotifications: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                return {
                    notificationStore: {},
                    notificationsInitialized: true,
                    version: 0,
                }
            }
        },
        setInitialNotifications: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                //In the first part of this function, we build the active channel notifications
                console.log("Notifications Init")
                console.log(action.payload.notificationStore)
                let notificationStore = {...action.payload.notificationStore}
                if (
                    action.payload?.messages &&
                    Object.keys(action.payload.messages).length > 0
                ) {
                    Object.keys(action.payload.messages).map((key) => {
                        if (
                            action.payload.messages[key]?.metaData?.type ===
                            'notification'
                        ) {
                            console.log(
                                '%c (NOTIFICATION LOG' + ')',
                                'backgroundColor: #000; color: #FFF', action.payload.messages[key]
                            )
                            let mes = action.payload.messages[key]
                            notificationStore = updateNotificationForChannel(
                                notificationStore,
                                mes
                            )
                        }
                    })
                    //#TODO: Sort notification messages
                } else {
                    return {...state}
                }
                return {
                    notificationStore: notificationStore,
                    notificationsInitialized: true,
                    version: 0,
                }
            },
        },
        updateNotificationsChannel: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                const {channelId} = action.payload
                let notificationStore = {...state.notificationStore}
                notificationStore[channelId] = {date: Date.now(), num: 0}
                return {
                    ...state,
                    notificationStore,
                    version: state.version + 1,
                }
            },
        },
    },
    extraReducers: {
        [addNewMessage]: {
            cached: false,
            parameters: ['message'],
            reduce: (state, action) => {
                const {channelId, isNotification, notificationData, message} =
                    action.payload
                let userId = action.payload.asyncExtra.clientUserId
                let notificationStore = {...state.notificationStore}
                if (message?.metaData?.type === 'notification') {
                    notificationStore = updateNotificationForChannel(
                        notificationStore,
                        message
                    )
                    return {
                        notificationsInitialized: true,
                        notificationStore,
                        version: state.version + 1,
                    }
                } else return {...state}
            },
        },
        [setActiveChannel]: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                if (typeof action.payload === 'string') {
                    action.asyncDispatch(
                        clearNotifications({
                            notificationDomainId: action.payload,
                        })
                    )
                } else {
                    const {channelId, serverId} = action.payload
                    action.asyncDispatch(
                        clearNotifications({notificationDomainId: channelId})
                    )
                }
                return {...state}
            },
        },
        [changeToDM]: {
            cached: false,
            parameters: ['serverId', 'channelId', 'profileCheck'],
            description: '',
            reduce: (state, action) => {
                const {profileCheck} = action.payload
                if (!profileCheck) {
                    if (typeof action.payload === 'string') {
                        action.asyncDispatch(
                            clearNotifications({
                                notificationDomainId: action.payload,
                            })
                        )
                    } else {
                        const {channel, server} = action.payload
                        action.asyncDispatch(
                            clearNotifications({
                                notificationDomainId: channel,
                            })
                        )
                    }
                }
                return {...state}
            },
        },
    },
})

const notificationsSlice = notification
const notificationsActions = notification.actions
const notifications = notification.reducer
export {notifications, notificationsActions, notificationsSlice}
