import React from "react"

import { StyledDiv, ButtonCustom, TypographyCustom, IconButtonCustom, BadgeCustom } from "../../../../../Chat/Styled"
import { Style } from "@mui/icons-material"
import { textAlign } from "@mui/system"

import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star';
import { MessageHeader, TextContainer } from "../../util"
import {getUserColors} from "../../../../../../hooks";

// import { ButtonCustom } from '../../../Chat/Styled'
// import { StyledDiv } from '../../../Chat/Styled'
// import { dispatchMappers } from '../../../../redux/process/actionProcess'
// import { RA1 } from '../../../../redux/actions'
// import { connect } from 'react-redux'

const TopOverlay = (props) => {
    let color = getUserColors(props?.user?.color)
    return (
        <StyledDiv types={['threadCardContainer']}
            thread={props.thread}>
            <StyledDiv types={['threadCardTop']}>
                <StyledDiv types={['threadCardTitle']} desktop={props.desktop}>
                    {props.thread.text}
                    <br />
                </StyledDiv>
                <StyledDiv types={['threadCardOP']}
                    userColor={color}
                    desktop={props.desktop}>
                    by <b> {props.user?.userName} </b> <br />
                    on <span> {props.thread.date} </span>
                </StyledDiv>
            </StyledDiv>
        </StyledDiv>
    )
}

const BottomOverlay = (props) => {
    return (
        <StyledDiv types={['threadCardBottom']} half={props.half}>
            <div>
                {(props.lastReply._id !== props.thread.id) ?
                    <StyledDiv types={['threadCardLastReply']}>
                        Last reply <br />
                        <span>
                            {new Date(props.lastReply.createdAt)
                                .toLocaleDateString('en-EN', {
                                    day: '2-digit',
                                    year: 'numeric',
                                    month: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                })}
                        </span>
                    </StyledDiv> : <div></div>
                }

                <StyledDiv types={['threadCardButtons']}>
                    {props.thread.replies}
                    <IconButtonCustom
                        color="primary"
                        size="small">
                        <ChatBubbleOutlineIcon />
                    </IconButtonCustom>
                    <IconButtonCustom
                        color="primary"
                        size="small"
                        onClick={props.watchThatThread}>
                        {(props.threadsWatched.filter(threadData => threadData.threadId === props.thread.id).length) ?
                            <StarIcon />
                            :
                            <StarBorderIcon />
                        }

                    </IconButtonCustom>
                </StyledDiv>
            </div>
        </StyledDiv>
    )
}

const ThreadMessageFull = (props) => {
    return (
        <StyledDiv types={[
            (props.fullMsg) ? null : 'generalCard',
            'fullCard',
            'messageCard',
            (props.fullMsg) ? 'featurefulCard' : null,
            'bordered']}>

            <TopOverlay
                thread={props.thread}
                user={props.user}
                desktop={false} />

            <BottomOverlay
                lastReply={props.lastReply}
                thread={props.thread}
                threadsWatched={props.threadsWatched}
                desktop={false} />

            <img src={`${props.thread.pic}`} />

        </StyledDiv>
    )
}

const ThreadMessageCardDisplay = function (props) {
    let color = getUserColors(props?.user?.color)
    let threadDateTime = new Date(props.lastReply.createdAt)
    let hoursFromLastReply = Math.abs(
        Date.now - threadDateTime
    ) / 36e5
    let lastReplyDisplay

    if (hoursFromLastReply < 24) {
        lastReplyDisplay = threadDateTime.toLocaleDateString('en-EN', {
            hour: '2-digit',
            minute: '2-digit'
        })
    } else {
        lastReplyDisplay = threadDateTime.toLocaleDateString('en-EN', {
            day: '2-digit',
            year: 'numeric',
            month: '2-digit',
        })
    }


    // .toLocaleDateString('en-EN', {
    //     day: '2-digit',
    //     year: 'numeric',
    //     month: '2-digit',
    //     hour: '2-digit',
    //     minute: '2-digit'
    // })

    // Every card display must have n different modes.
    // Which mode is rendered is determined by a switch.
    // However, these modes must be the same across all cards.
    // Suggested modes: full-desktop, half-desktop, compressed-desktop, full, compressed

    // #
    switch (props.displayMode) {
        case 'fullTotalDesktop':
            return (
                <ThreadMessageFull
                    thread={props.thread}
                    user={props.user}
                    lastReply={props.lastReply}
                    threadsWatched={props.threadsWatched}
                    fullMsg={true}
                    desktop={false}
                />
            )
        case 'fullDesktop':
            return (
                <ThreadMessageFull
                    thread={props.thread}
                    user={props.user}
                    lastReply={props.lastReply}
                    threadsWatched={props.threadsWatched}
                    fullMsg={true}
                    desktop={false}
                />
            )
        case 'halfDesktop':
            return (
                <StyledDiv className="recolor-item"
                    types={['generalCard', 'messageCard', 'halfCard']}>
                    <StyledDiv types={['cardImage', 'halfImage']}>
                        <img src={props.thread.pic} />
                    </StyledDiv>
                    <StyledDiv types={['halfText']}>

                        <StyledDiv types={['threadCardTop']}
                            half={true}
                            style={{ backgroundColor: 'transparent' }}>
                            <StyledDiv types={['cardTitle', 'titleCompressed']}>
                                {props.thread.text}
                            </StyledDiv>
                            <StyledDiv types={['halfData', 'threadCardOP']}
                                userColor={color}
                                desktop={props.desktop}>
                                by <b> {props.user?.userName} </b> <br />
                                on <span> {props.thread.date} </span>
                            </StyledDiv>
                        </StyledDiv>

                        <BottomOverlay
                            lastReply={props.lastReply}
                            thread={props.thread}
                            threadsWatched={props.threadsWatched}
                            desktop={true}

                            half={true} />

                    </StyledDiv>
                </StyledDiv>
            )
        case 'compressedDesktop':
            return (
                <StyledDiv className="recolor-item"
                    types={['generalCard', 'messageCard', 'compressedDesktop']}>
                    <StyledDiv types={['threadCardTop', 'threadCardTopCompressed']}
                        // half={true}
                        style={{ backgroundColor: 'transparent' }}>

                        <StyledDiv types={['threadCompressedText']}>
                            <StyledDiv types={['cardTitle', 'titleCompressed', 'threadCardTitleCompressed']}>
                                {props.thread.text}
                            </StyledDiv>
                            <StyledDiv types={['threadCardButtons', 'threadButtonsCompressed']}>
                                {props.thread.replies}
                                <IconButtonCustom
                                    color="primary"
                                    size="small"
                                    types={['threadCompressed']}>
                                    <ChatBubbleOutlineIcon sx={{ fontSize: '1em', }} />
                                </IconButtonCustom>
                                <IconButtonCustom
                                    color="primary"
                                    size="small"
                                    types={['threadCompressed']}
                                    onClick={props.watchThatThread}>
                                    {(props.threadsWatched.filter(threadData => threadData.threadId === props.thread.id).length) ?
                                        <StarIcon sx={{ fontSize: '1em', }} />
                                        :
                                        <StarBorderIcon sx={{ fontSize: '1em', }} />
                                    }
                                </IconButtonCustom>
                            </StyledDiv>
                        </StyledDiv>
                        <StyledDiv types={['threadCompressedText', 'threadDataRight']}>

                            <StyledDiv types={['threadCardOP']}
                                userColor={color}
                                desktop={true}>
                                by <b> {props.user?.userName} </b> <br />
                            </StyledDiv>


                            {(props.lastReply._id !== props.thread.id) ?
                                <StyledDiv types={['threadCardLastReply']} >
                                    Last reply {' '}
                                    <span>
                                        {new Date(props.lastReply.createdAt)
                                            .toLocaleDateString('en-EN', {
                                                day: '2-digit',
                                                year: 'numeric',
                                                month: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            })}
                                    </span>
                                </StyledDiv> : <div></div>
                            }
                        </StyledDiv>
                    </StyledDiv>

                    {/* <BottomOverlay
                            lastReply={props.lastReply}
                            thread={props.thread}
                            threadsWatched={props.threadsWatched}
                            desktop={true}

                            half={true} /> */}

                </StyledDiv>
            )
        case 'fullTotal': // mobile
            return (
                <ThreadMessageFull
                    thread={props.thread}
                    user={props.user}
                    lastReply={props.lastReply}
                    threadsWatched={props.threadsWatched}
                    fullMsg={true}
                    desktop={false}
                />
            )
        case 'full':
            return (
                <ThreadMessageFull
                    thread={props.thread}
                    user={props.user}
                    lastReply={props.lastReply}
                    threadsWatched={props.threadsWatched}
                    fullMsg={false}
                    desktop={false}
                />
            )
        default:
            return (
                <StyledDiv className="recolor-item"
                    types={['generalCard', 'compressedCard', 'compressedThread']}>
                    <StyledDiv types={['threadCardTop', 'threadCardLeft']}
                        half={true}
                        style={{
                            backgroundColor: 'transparent',
                        }}>
                        <StyledDiv types={['cardTitle', 'titleCompressed', 'threadCardTitleCompressed']} userColor={color}>
                            {props.thread.text} by <b> {props.user?.userName} </b>
                        </StyledDiv>
                    </StyledDiv>
                    <StyledDiv types={['threadCardRight']}>
                        {props.thread.replies}
                        <IconButtonCustom
                            color="primary"
                            size="small">
                            <BadgeCustom
                                variant={'dot'}
                                // invisible={!props.noti}
                                color="secondary">
                                <ChatBubbleOutlineIcon />
                            </BadgeCustom>
                        </IconButtonCustom>
                    </StyledDiv>
                </StyledDiv>
            )
    }
}

export const threadMessageCardPackage = {
    requirements: ['displayMode', 'image', 'title', 'data'],
    CardDisplay: ThreadMessageCardDisplay
}