import {addNewMessage, searchResultsClient} from './actions'
import {createCachedSlice} from '../cache/createCachedSlice.js'

const updateAppStateFunction = function (state, action) {
    const newState = {...action.payload}
    let oldState = {...state}
    for (const key in newState) {
        if (newState.hasOwnProperty(key)) {
            oldState[key] = newState[key]
        }
    }
    oldState.updateId = oldState.updateId + 1
    return oldState
}
const appState = createCachedSlice({
    name: '_appState',
    initialState: {alerts: 0, recentServers: [], modalType: 'none', directives: []},
    reducers: {
        updateRecentServers: {
            cached: false,
            parameters: ['serverId'],
            description: '',
            reduce: (state, action) => {
                let {recentServers} = state
                let recentServersLocal = [...recentServers]
                let {serverId} = action.payload
                if (!recentServers.includes(serverId)) {
                    recentServersLocal.push(serverId)
                    if (recentServers.length > 5) {
                        recentServersLocal.shift()
                    }
                }
                action.payload = {recentServers: recentServersLocal}
                return updateAppStateFunction(state, action)
            },
        },
        setInvLinkToFalse: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                let payload = {invLinkClicked: false}
                action.payload = payload
                return updateAppStateFunction(state, action)
            },
        },
        postAppStateDirective: {
            cached: false,
            parameters: ['directive'],
            description: '',
            reduce: (state, action) => {
                let oldState = {...state}
                let directives = [...oldState.directives,action.payload]
                return {...oldState, directives: directives}
            },
        },
        clientSecret: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                let payload = {clientSecret: action.payload.clientSecret}
                action.payload = payload
                return updateAppStateFunction(state, action)
            },
        },
        accountLinkUrl: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                let payload = {accountLinkUrl: action.payload.accountLinkUrl}
                action.payload = payload
                return updateAppStateFunction(state, action)
            },
        },
        clearAccountLinkUrl: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                let payload = {accountLinkUrl: null}
                action.payload = payload
                return updateAppStateFunction(state, action)
            },
        },
        updateCatalog: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                let payload = {catalog: action.payload.catalog}
                action.payload = payload
                return updateAppStateFunction(state, action)
            },
        },
        blockModal: {
            cached: false,
            parameters: ['storeFn'],
            description: '',
            reduce: (state, action) => {
                //The storeFn will possibily execute when the modal is unblocked.
                action.payload = {
                    pageChangeBlocked: true,
                    blockedModal: 'change',
                    rolesChanged: true,
                }
                return updateAppStateFunction(state, action)
            },
        },
        blockModalRoleStruct: {
            cached: false,
            parameters: ['roleStruct'],
            description: '',
            reduce: (state, action) => {
                let {roleStruct} = action.payload
                let payload = {
                    pageChangeBlocked: true,
                    blockedModal: 'change',
                    rolesChanged: true,
                    roleStruct: roleStruct,
                }
                action.payload = payload
                return updateAppStateFunction(state, action)
            },
        },
        changePage: {
            cached: false,
            parameters: ['page'],
            description: '',
            reduce: (state, action) => {
                //ARGS
                const {page} = action.payload
                let payload = {
                    openMenu: page,
                }
                action.payload = payload
                return updateAppStateFunction(state, action)
            },
        },
        changeToDM: {
            cached: false,
            parameters: ['server', 'channel', 'profileCheck'],
            description: '',
            reduce: (state, action) => {
                const {profileCheck} = action.payload
                if (!profileCheck) {
                    const {server, channel} = action.payload
                    let newPayload = {
                        openMenu: 'dm',
                        dmMeta: {serverId: server, channelId: channel},
                    }
                    action.payload = newPayload
                    return updateAppStateFunction(state, action)
                } else {
                    const {server, channel} = action.payload
                    let newPayload = {
                        openMenu: 'userProfilePage',
                        profileMeta: {server: server, channel: channel},
                    }
                    action.payload = newPayload
                    return updateAppStateFunction(state, action)
                }
            },
        },
        openModalMenu: {
            cached: false,
            parameters: ['modalMenu'],
            description: '',
            reduce: (state, action) => {
                //Arguments
                const {modalMenu} = action.payload
                action.payload.modalMenuOpen = true
                return updateAppStateFunction(state, action)
            },
        },
        openInfoModal: {
            cached: false,
            parameters: ['modalMenu'],
            description: '',
            reduce: (state, action) => {
                //Arguments
                const {modalMenu} = action.payload
                action.payload.modalMenuOpen = true
                action.payload.modalType = "info"
                return updateAppStateFunction(state, action)
            },
        },
        openBlockedModalMenu: {
            cached: false,
            parameters: ['modalMenu', 'followUpAction'],
            description: '',
            reduce: (state, action) => {
                //Arguments
                const {modalMenu, followUpAction} = action.payload
                action.payload.modalMenuOpen = true
                return updateAppStateFunction(state, action)
            },
        },
        closeModalMenu: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                //No arguments
                let payload = {
                    modalMenu: 'none',
                    modalType: 'none',
                    modalMenuOpen: false,
                    followUpAction: null,
                }
                action.payload = payload
                return updateAppStateFunction(state, action)
            },
        },
        closeBlockedModalMenu: {
            cached: false,
            parameters: ['executeStoreFn'],
            description: '',
            reduce: (state, action) => {
                //The parameter determines if the action middleware executes the stored function.
                let {followUpAction} = state
                action.payload = {
                    modalMenu: 'none',
                    modalMenuOpen: false,
                    pageChangeBlocked: false,
                    followUpAction: null,
                }
                action.asyncDispatch(JSON.parse(followUpAction))
                return updateAppStateFunction(state, action)
            },
        },
        updateAppState: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                return updateAppStateFunction(state, action)
            },
        },
    },
    extraReducers: {
        [addNewMessage]: {
            cached: false,
            parameters: ['message'],
            reduce: (state, action) => {
                const userId = action.payload.asyncExtra.userMetaId
                const {message} = action.payload
                const {uId, cId, mId} = message
                let newState = {...state}
                // conditions:
                // user is scrolled to bottom of page,
                // new message in currently focused channel,
                //TODO: ( or new message is in currently focused dm )
                // chat is the currently focused menu,
                let messageInFocus =
                    cId === action.payload.asyncExtra._activeChannel &&
                    'chat' === action.payload.asyncExtra._appState.openMenu &&
                    'bottom' === action.payload.asyncExtra.scrollDefault

                if (!(uId === userId || messageInFocus)) {
                    ++newState.alerts
                    // newState.alerts.alertsArray.push(cId);
                }

                return newState
            },
        },
        [searchResultsClient]: {
            cached: false,
            reduce: (state, action) => {
                const {list, serverId} = action.payload
                let messageIds = []
                list.forEach((message) => {
                    messageIds.push(message._id)
                })
                let payloadNew = {
                    searchResults: {},
                }
                payloadNew.searchResults[serverId] = {
                    messages: list,
                    messageIds: messageIds,
                }
                action.payload = payloadNew
                return updateAppStateFunction(state, action)
            },
        },
    },
})

const appStateSlice = appState
const appStateActions = appState.actions
const openModalMenu = appState.actions.openModalMenu
const updateAppState = appState.actions.updateAppState
const postAppStateDirective = appState.actions.postAppStateDirective
const closeModalMenu = appState.actions.closeModalMenu
const _appState = appState.reducer
export {
    openModalMenu,
    appStateSlice,
    postAppStateDirective,
    updateAppState,
    closeModalMenu,
    _appState,
    appStateActions,
}
