import ChatWindowLoader from "./ChatWindow/loader";
import {StyledDiv, vDH} from "./Styled";
import CalendarChat from "./ChatCalendar";
import ManageSelfPage from "./ManageSelf";
import PaymentPage from "./Payment";
import ManagementPage from "./ManageServer";
import ThreadViewLoader from "./ThreadView/loader";
import React, {useEffect} from "react";

export function ChatWindowMenu(props) {
    // if (props.AS.nav.state.multiStageTransition) {
    //     return <StyledDiv style={{height: "100vh", width: "100vh"}}/>
    // }
    switch (props.mode) {
        case 'chat':
            if (props.activeServer !== props.userId) {
                return (
                    <ChatWindowLoader
                        key={props.activeChannel}
                        inputEnabled={true}
                        passedHeight={vDH(89)}
                        server={props.activeServer}
                        userId={props.userId}
                        menuId="message-menu"
                        reverseMode={false}
                        userProfilePage={false}
                        channel={props.activeChannel}
                        storeLocation={props.activeChannel}
                        user={props.userId}
                        desktop={props?.desktop}
                    />
                )
            } else {
                return <div/>
            }
        case 'dm':
            return (
                <ChatWindowLoader
                    key={props.AS.nav.state.dmData.channel}
                    inputEnabled={true}
                    passedHeight={vDH(89)}
                    server={props.userId}
                    userId={props.userId}
                    reverseMode={false}
                    userProfilePage={false}
                    menuId="message-menu"
                    channel={props.AS.nav.state.dmData.channel}
                    storeLocation={props.activeChannel}
                    user={props.userId}
                    desktop={props?.desktop}
                />
            )
        case 'userProfilePage':
            return (
                <ChatWindowLoader
                    key={props.AS.activeServer}
                    inputEnabled={true}
                    passedHeight={vDH(89)}
                    server={props.AS.activeServer}
                    userId={props.userId}
                    reverseMode={true}
                    userProfilePage={true}
                    menuId="message-menu"
                    channel={props.AS.activeChannel}
                    storeLocation={props.AS.activeChannel}
                    user={props.userId}
                    desktop={props?.desktop}
                />
            )
        case 'calendar':
            if (props.activeServer !== props.userId) {
                return (
                    <StyledDiv types={['chatWindowDiv']}>
                        <CalendarChat serverId={props.activeServer}/>
                    </StyledDiv>
                )
            } else {
                return <div/>
            }
        case 'stream':
            return (
                <ChatWindowLoader
                    key={props.activeChannel}
                    inputEnabled={true}
                    passedHeight={vDH(89)}
                    server={props.activeServer}
                    userId={props.userId}
                    reverseMode={false}
                    menuId="message-menu"
                    channel={props.activeChannel}
                    storeLocation={props.activeChannel}
                    user={props.userId}
                    desktop={props?.desktop}
                />
            )
        case 'accountManagement':
            return <ManageSelfPage userId={props.userId}/>
        case 'manage':
            if (props.activeServer !== props.userId) {
                return (<StyledDiv types={['chatWindowDiv']}>
                    <ManagementPage
                        userId={props.userId}
                        server={props.activeServer}
                    />
                </StyledDiv>)
            } else {
                return <div/>
            }
        case 'threads':
            if (props.activeServer !== props.userId) {
                return (
                    <ThreadViewLoader
                        key={props.activeChannel}
                        server={props.activeServer}
                        userId={props.userId}
                        standardThreadView={true}
                        user={props.userId}
                        desktop={props?.desktop}
                        storeLocation={props.activeChannel}
                        channel={props.activeChannel}
                    />
                )
            } else {
                return <div/>
            }
        default:
            return null
    }
}