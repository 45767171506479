import React, { useEffect, useState } from 'react'
import ThreadView from './index'
import withThreads from "../../_wrappers/withThreads";
import { withAS } from "../../_contexts/withAppState";

const ThreadSystem = withAS(withThreads(ThreadView))

const ThreadViewLoader = (props) => {
    return <ThreadSystem {...props} />
}

export default ThreadViewLoader