import ImageDropzone from '../../../_reusable/ImageDropzone'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { RA1, RS1 } from '../../../../redux/actions'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import { IntegrableAccordionWrap } from '../../../Integrable/Accordion'
import { dispatchMappers } from '../../../../redux/process/actionProcess'
import { StyledDiv, TypographyCustom } from '../../Styled'
import SubscriptionManagementList from './SubscriptionManagementList'
import { withAS } from '../../../_contexts/withAppState'
import { statePropMapper } from '../../../../redux/process/selectorProcess'
import {FULL_FRONTEND_URL} from "../../../../constants/env";

const mapDispatchToProps = dispatchMappers([
    RA1._appState.changePage,
    SA1.addUserAvatar,
    SA1.getUserTransactions,
])

const makeMapStateToProps = statePropMapper([
    RS1.store('chatServers', 'chatServers'),
    RS1.store('_servers', '_servers'),
    RS1.store('_userSettings', '_userSettings'),
])

const UserSettingsPage = (props) => {

    const userAvatarSendFunction = (fileToken) => {
        props.dispatch.server.addUserAvatar(fileToken)
    }

    const [avatarKey, setAvatarKey] = useState(0)

    const serversUserIn = props._servers
    const [currentServer, setCurrentServer] = useState(0)
    const [transactions, setTransactions] = useState([])

    // Together, these useEffects iterate through the servers that the user is in and grab all the transactions with
    // the subscription type associated with the user for each server. 

    // This first useEffect simply gets the user transactions associated with the "currentServer"
    useEffect(() => {
        if (currentServer <= serversUserIn.length) {
            props.dispatch.server.getUserTransactions(serversUserIn[currentServer], true, null)
        }

    }, [currentServer])

    // Then this second useEffect grabs those transactions from the userSettings in the store, appends them to the
    // list of transactions, and increments currentServer.
    // This tells the previous useEffect that all the transactions have been received for the currentServer and it
    // has a green light to receive the transactions for the next server.
    // Without this functionality, a user could only access the transactions associated with the server in the most recent
    // getUserTransactions request.
    useEffect(() => {
        let transactionsCopy = [...transactions]

        if (props?._userSettings?.transactions?.length) {
            props._userSettings.transactions.forEach((transaction) => {
                if (!transactionsCopy.length ||
                    !transactionsCopy.map((transaction) => transaction.id).includes(transaction.id)) {
                    transactionsCopy.push(transaction)
                }
            })
            setTransactions(transactionsCopy)
            setCurrentServer(currentServer + 1)
        }

    }, [props._userSettings.transactions])

    return (
        <StyledDiv>
            <IntegrableAccordionWrap name={'User Avatar'}>
                <ImageDropzone
                    key={'userAvatar' + avatarKey}
                    label={''}
                    description={'Drag and drop a new user avatar'}
                    storeLocation={'Avatar' + props.userId}
                    fileName={'serverAvatar'}
                    sendFunction={userAvatarSendFunction}
                    user={props.userId}
                    completeOperation={setAvatarKey}
                    defaultImage={FULL_FRONTEND_URL+'placeholder-image.jpg'}
                    aspectRat={4 / 4}
                    useCropper={true}
                />
            </IntegrableAccordionWrap>

            {/*<IntegrableAccordionWrap name={'Appointments'}>*/}
            {/*</IntegrableAccordionWrap>*/}

            <IntegrableAccordionWrap name={'Subscriptions'}>
                {serversUserIn.map((server, index) => {
                    return (
                        <SubscriptionManagementList
                            key={'sub_server_' + index}
                            transactions={transactions}
                            serverData={props.chatServers[server]}
                            userId={props.userId}
                            openModalWithData={props.AS.modals.ops.openModalWithData}
                            server={server} />
                    )
                })}
            </IntegrableAccordionWrap>

        </StyledDiv>
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(UserSettingsPage))
