import { useEffect, useState, useRef } from 'react'
// import ChatInputContainer from '../../ChatWindow/ChatInputContainer'
import { RS1 } from '../../../../../redux/actions'
import { SA1 } from '../../../../../hs/requestGlobals.mjs'
// import { statePropMapper } from '../../../../redux/process/selectorProcess'
import { dispatchMappers } from '../../../../../redux/process/actionProcess'
import { connect } from 'react-redux'
import ThreadMessage from '../ThreadMessage'
// import ThreadMessage from './ThreadMessage'
import withReactVirtualized from '../../../../_wrappers/withReactVirtualized'
// import SingleThreadMenu from './SingleThreadMenu'
// import { useContextMenu } from 'react-contexify'
import Immutable from 'immutable'
// import { TopRightHover, BottomRightHover } from '../../../_design/BottomHover'
import { useTheme } from '@mui/material'
import { ButtonCustom, StyledDiv, vDH } from '../../../Styled'
import { statePropMapper } from '../../../../../redux/process/selectorProcess'
import { JLog } from "../../../../../hs/journey";
// import { IconButtonCustom } from '../../Styled'
// import StarIcon from '@mui/icons-material/Star'
// import StarBorderIcon from '@mui/icons-material/StarBorder'

const makeMapStateToProps = statePropMapper([
    RS1.getSelectedThread('threadBeeps')
])

const makeDispatchToProps = dispatchMappers([
    SA1.deleteMessage,
])

const VirtualizedThreadMessageList = withReactVirtualized(ThreadMessage)

const SingleThreadMessageList = (props) => {
    // #
    const theme = useTheme()
    const [threadMsgs, setThreadMsgs] = useState([])
    const [height, setHeight] = useState(0)
    const [repliedMsgHeight, setRepliedMsgHeight] = useState(0)
    const [repliedMsg, setRepliedMsg] = useState(null)
    const heightRef = useRef(null)
    const repliedMsgRef = useRef(null)

    // #
    const getImgs = (imgidx) => {
        let images = []
        let imgIndex = 0
        props.threadMsgs.map((message, index) => {
            if (message?.files?.length != 0) {
                if (imgidx > index) {
                    imgIndex += 1;
                }
                message.files.forEach((file) => {
                    images.push(message.files)
                })
            }
        })
        return { images, imgIndex }
    }

    // #
    const threadBeepMngr = () => {
        let newThreadMsgs = []
        // JLog.threads.log(props.threadBeeps.filter(reply => reply?.reply?.isReply && reply.reply.mData.mId === props.threadMsgs[0].mId))()

        setRepliedMsg(null)
        props?.threadMsgs?.forEach((message, index) => {
            let msgCopy = {}
            msgCopy.key = (index === 0) ? 0 : index + 1
            msgCopy.index = (index === 0) ? 0 : index + 1
            msgCopy.msgIndx = (index === 0) ? 0 : index + 1
            msgCopy.userId = message.uId
            msgCopy.message = message
            msgCopy.getImgs = getImgs
            msgCopy.server = props.server
            msgCopy.theme = theme
            msgCopy.isModal = true
            msgCopy.numMessages = props.threadMsgs.length
            msgCopy.replies = props.threadBeeps.filter(reply => reply?.reply?.isReply && reply.reply.mData.mId === message.mId)
            msgCopy.repliedTo = props.threadBeeps.filter(replied => message?.reply?.isReply && replied.mId === message.reply.mData.mId)
            newThreadMsgs.push(msgCopy)
        })

        // JLog.threads.log(newThreadMsgs)()

        setThreadMsgs(Immutable.List(newThreadMsgs))
    }

    // useEffect(threadBeepMngr, [])

    useEffect(threadBeepMngr, [props.threadBeeps, props.threadMsgs])

    useEffect(() => {
        setHeight(0)
        // JLog.threads.log("repliedMsg from threadMsgs[0]")()
        // JLog.threads.log(props.threadMsgs[0]?.reply)()
        if (props.threadMsgs[0]?.reply?.isReply && props.threadMsgs.length > 1) {
            let msg = props.threadBeeps.filter(beep => beep?.mId === props.threadMsgs[0].reply.mData.mId)[0]
            setRepliedMsg({
                key: 1,
                index: 1,
                msgIndx: 1,
                userId: msg.uId,
                message: msg,
                getImgs: getImgs,
                server: msg.sId,
                theme: theme,
                isModal: true,
                replies: [],
                repliedTo: props.threadBeeps.filter(replied => msg?.reply?.isReply && replied.mId === msg.reply.mData.mId),
                isOP: true,
            })
        }
    }, [props.threadBeeps, props.threadMsgs])

    useEffect(() => {
        // JLog.threads.log("logging height")()
        // JLog.threads.log(heightRef)()
        // JLog.threads.log(height)()
        if (height < 700 && heightRef?.current?.clientHeight) {
            setHeight(heightRef.current.clientHeight)
        }
    }, [heightRef?.current?.clientHeight, height])

    useEffect(() => {
        if (repliedMsgHeight !== repliedMsgRef?.current?.clientHeight) {
            setRepliedMsgHeight(heightRef.current.clientHeight)
        }
    }, [repliedMsgRef?.current?.clientHeight, repliedMsgHeight])

    // 700 - repliedMsgHeight

    // #
    if (threadMsgs.size > 7) {
        return (
            <StyledDiv types={['singleThread']} style={{
                width: '42vw', height: 'fit-content', paddingTop: '2em',
                paddingLeft: (props.threadMsgs.length === 1) ? '1em' : 0
            }}>
                <div ref={heightRef} style={{ position: "absolute", height: "100%" }}></div>
                {repliedMsg && <div ref={repliedMsgRef}><ThreadMessage {...repliedMsg} /></div>}
                <div style={{
                    width: '100%',
                    backgroundColor: theme.palette.background.primary,
                    paddingBottom: (props.threadMsgs.length === 1) ? 0 : '1em',
                    borderRadius: '8px 0px 8px 8px',
                }}>
                    <VirtualizedThreadMessageList
                        listData={threadMsgs}
                        height={700 - repliedMsgHeight}
                        passdownProps={{
                            isModal: true
                        }}
                        style={{
                            marginTop: `calc(${vDH(3)} + 3em)`,
                            backgroundColor: theme.palette.background.primary,
                            marginBottom: '5em',
                            margin: '3em',
                            marginLeft: 0,
                            height: "fit-content"
                        }}
                        selectItem={() => { }}
                    />
                </div>
            </StyledDiv >
        )
    }
    else if (threadMsgs.size > 0) {
        // if the number of replies is greater than a certain value, virt
        // if it is under that value, render them all, then check the height of the div.
        // if the height is over 748, virt.
        // otherwise, map

        if (height >= 700) {
            return (
                <StyledDiv types={['singleThread']}
                    style={{
                        width: '42vw', height: 'fit-content', paddingTop: '2em',
                        paddingLeft: (props.threadMsgs.length === 1) ? '1em' : 0
                    }}>
                    <div ref={heightRef} style={{ position: "absolute", height: "100%" }}></div>
                    {repliedMsg && <div ref={repliedMsgRef}><ThreadMessage {...repliedMsg} /></div>}
                    <div style={{
                        width: '100%',
                        backgroundColor: theme.palette.background.primary,
                        paddingBottom: (props.threadMsgs.length === 1) ? 0 : '1em',
                        borderRadius: '8px 0px 8px 8px',
                    }}>
                        <VirtualizedThreadMessageList
                            listData={threadMsgs}
                            height={700 - repliedMsgHeight}
                            style={{
                                marginTop: `calc(${vDH(3)} + 3em)`,
                                backgroundColor: theme.palette.background.primary,
                                marginBottom: '5em',
                                border: '1px solid red',
                                marginLeft: (props.threadMsgs.length === 1) ? '1em' : 0,
                                height: "fit-content",
                                paddingBottom: '1em',
                                marginRight: (props.threadMsgs.length === 1) ? '1em' : 0,
                            }}
                            selectItem={() => { }}
                        />
                    </div>
                </StyledDiv>
            )
        } else {
            return (
                <StyledDiv types={['singleThread']} style={{
                    width: 'fit-content',
                    height: 'fit-content',
                    paddingTop: (props.threadMsgs.length === 1) ? 0 : '2em',
                    backgroundColor: (props.threadMsgs.length === 1) ? theme.palette.background.primary : 'none',
                    paddingLeft: (props.threadMsgs.length === 1) ? '1em' : 0,
                    borderRadius: '8px',
                }}>
                    <div ref={heightRef} style={{ position: "absolute", height: "100%" }}></div>
                    {repliedMsg && <div ref={repliedMsgRef}><ThreadMessage {...repliedMsg} /></div>}
                    <div style={{
                        backgroundColor: theme.palette.background.primary,
                        paddingBottom: (props.threadMsgs.length === 1) ? 0 : '1em',
                        marginRight: (props.threadMsgs.length === 1) ? '1em' : 0,
                        borderRadius: '8px',
                    }}>
                        {threadMsgs.toJS().map(message => (
                            <ThreadMessage {...message} />
                        ))}
                    </div>
                </StyledDiv>
            )
        }
    } else {
        return (<div ref={heightRef}>No messages!</div>)
    }
}


export default connect(
    makeMapStateToProps,
    makeDispatchToProps
)(SingleThreadMessageList)
