import { useEffect, useState, useRef } from 'react'
import { Box, useTheme } from '@mui/material'
import { statePropMapper } from '../../../../redux/process/selectorProcess'
import { RS1 } from '../../../../redux/actions'
import { connect } from 'react-redux'
import { StyledDiv } from '../../Styled'
import { IconButtonCustom } from '../../Styled'
import { BadgeCustom } from '../../Styled'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star';
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import { dispatchMappers } from '../../../../redux/process/actionProcess'
import { JLog } from "../../../../hs/journey";

const makeMapStateToProps = statePropMapper([
    RS1.getUserMeta('userMeta'),
    RS1.getUser('userData'),
    RS1.getSelectedThread('threadMsgs'),
    RS1.store('_activeChannel', '_activeChannel'),
])

const makeDispatchToProps = dispatchMappers([SA1.watchThread])

const ThreadCard = (props) => {
    const theme = useTheme()

    const userNameColor = theme.palette.text.secondary
    const dateColor = theme.palette.text.flair
    const lastReply = props.threadMsgs.at(-1)
    const watchThatThread = () => {

        let unwatch = false
        const idsOfThreadsWatched = props.threadsWatched.map(thread => thread.threadId)

        if (idsOfThreadsWatched.includes(props.thread.id)) {
            unwatch = true
        }

        props.dispatch.server.watchThread(
            props.server,
            props._activeChannel,
            unwatch,
            props.selectedThread)
    }

    const selectThread = () => {
        JLog.threads.log("BALHBUAL")()
        props.setSelectedThread(
            props.threadsArray[props.index].mId
        )
        props.setSingleThreadPage(true)
    }
    return (
        <div style={{
            position: 'relative',
            border:
                theme.palette?.thread
                    ?.border &&
                `1px solid ${theme.palette.text.flair}`,
        }}>
            <div style={{
                width: '100%',
                position: 'absolute',
                backgroundImage: 'url(' + props.thread.pic + ')',
                backgroundPosition: 'top',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}>
                <StyledDiv types={['threadCardTop']}
                    half={false}
                    onClick={selectThread}>
                    <span style={{
                        fontSize: '1.3em',
                        whiteSpace: 'nowrap',
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        {props.thread.text} <br />
                    </span>
                    <span style={{ fontSize: '0.7em', textAlign: 'right' }}>
                        by <b style={{ color: userNameColor.startsWith('rgba') ? dateColor : userNameColor }}>
                            {props.userData?.userName}</b> <br />
                        on <span style={{ color: dateColor }}>{props.thread.date}</span>
                    </span>
                </StyledDiv>
            </div >

            <StyledDiv types={['threadCardBottom']} half={false}>
                <div>
                    {(lastReply._id !== props.thread.id) ?
                        <div>
                            Last reply <br />
                            <span style={{ color: dateColor }}>{
                                new Date(lastReply.createdAt)
                                    .toLocaleDateString('en-EN', {
                                        day: '2-digit',
                                        year: 'numeric',
                                        month: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}</span>
                        </div> : <div></div>
                    }
                    <div style={{ fontSize: '1em' }}>
                        {props.thread.replies}
                        <IconButtonCustom
                            color="primary"
                            size="small">
                            <ChatBubbleOutlineIcon />
                        </IconButtonCustom>
                        <IconButtonCustom
                            color="primary"
                            size="small"
                            onClick={watchThatThread}>
                            {(props.threadsWatched.filter(threadData => threadData.threadId === props.thread.id).length) ?
                                <StarIcon />
                                :
                                <StarBorderIcon />
                            }

                        </IconButtonCustom>
                    </div>
                </div>
            </StyledDiv>
            <img src={`${props.thread?.pic ? props.thread.pic : 'https://picsum.photos/200'}`}
                onClick={selectThread}
                alt={"awd"}
                style={{
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                    display: 'block',
                    width: '100%',
                }} />
        </div >
    )
}

export default connect(makeMapStateToProps, makeDispatchToProps)(ThreadCard)