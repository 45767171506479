import React from 'react'
import {connect} from 'react-redux'
import {RA1, RS1} from '../../../../redux/actions.js'
import {SA1} from '../../../../hs/requestGlobals.mjs'
import {dispatchMappers} from '../../../../redux/process/actionProcess.js'
import SocketForm from '../../../_reusable/SocketForm/SocketForm'
import {statePropMapper} from '../../../../redux/process/selectorProcess'

const makeMapStateToProps = statePropMapper([])

const mapDispatchToProps = dispatchMappers([
    RA1.api.updateAPI,
    SA1.updateServerName,
])

const UpdateServerNameModal = function (props) {
    return (
        <SocketForm
            useConfirmationScreen={false}
            formMethod={'updateServerName'}
            formDispatch={({serverName, serverSubtitle}) => {
                //console.log(obj)
                props.dispatch.server.updateServerName(
                    props.AS.activeServer,
                    serverName,
                    serverSubtitle,
                    'updateServerName',
                    'updateServerName'
                )

            }}
            socketForm
            onClose={props.closeModalMenu}
            onSuccess={props.closeModalMenu}
            description={'Would you like to update your server name?'}
            formFields={[
                {
                    id: 'serverName',
                    typographyLabel: 'Server Name',
                    label: '',
                    require: true,
                    page: 0,
                },
                {
                    id: 'serverSubtitle',
                    typographyLabel: 'Subtitle',
                    label: '',
                    require: true,
                    page: 0,
                },
            ]}
            formButtons={[
                {
                    label: 'Update',
                    icon: 'add',
                    isSubmitButton: true,
                    isCloseButton: false,
                },
            ]}
            formTypography={{label: 'Update server name'}}
            successMessage={'Server name updated.'}
        />
    )
}

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(UpdateServerNameModal)
