import React, { useEffect } from 'react'
import { useContextMenu } from 'react-contexify'

const { useState } = require('react')

const withMenu = function (WrappedComponent, MenuComponent) {
    return function (props) {
        const [lastEvent, setLastEvent] = useState(null)
        const [selectedItem, setSetSelectedItem] = useState(null)
        const { show } = useContextMenu({ id: props.menuId })
        function handleItemClick({ event, props, triggerEvent, data }) {
        }
        function selectItem(e, item) {
            e.preventDefault()
            const lastEvent = { ...e }
            setLastEvent(lastEvent)
            setSetSelectedItem(item)
        }

        useEffect(() => {
            lastEvent !== null ? show(lastEvent) : null
        }, [lastEvent])

        return (
            <>
                <MenuComponent
                    menuId={props.menuId}
                    handleItemClick={handleItemClick}
                    selectedItem={selectedItem}
                    {...props}
                />
                <WrappedComponent selectItem={selectItem} {...props} />
            </>
        )
    }
}

export default withMenu
