import React from 'react'
import {connect} from 'react-redux'
import {RA1, RS1} from '../../../../redux/actions.js'
import {SA1} from '../../../../hs/requestGlobals.mjs'
import {dispatchMappers} from '../../../../redux/process/actionProcess.js'
import SocketForm from '../../../_reusable/SocketForm/SocketForm'
import {statePropMapper} from '../../../../redux/process/selectorProcess'

const makeMapStateToProps = statePropMapper([])

const mapDispatchToProps = dispatchMappers([RA1.api.updateAPI, RA1._appState.closeModalMenu])

const ModifyAppointmentModal = function (props) {
    return (
        <SocketForm
            updateAPI={props.dispatch.api.updateAPI}
            formMethod={'modifyAppointment'}
            formDispatch={({channelName}) => {
                //props.dispatch.server.createChannel(props.AS.activeServer, channelName)
            }}
            onSuccess={() => null}
            formFields={[{id: 'channelName', label: 'Channel Name'}]}
            formButton={{label: 'Modify'}}
            formTypography={{label: 'Modify Appointment'}}
        />
    )
}

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(ModifyAppointmentModal)
