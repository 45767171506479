import { PaperCustom } from './Components/PaperCustom'
import { MuiCard } from './Components/MuiCard'
import { ListItemCustom } from './Components/ListItemCustom'
import { ListButtomCustomStyle } from './Components/ListButtonCustom'
import { ListItemSecondaryActionCustomStyle } from './Components/ListItemSecondaryActionCustom'
import { AccordionCustomStyle } from './Components/AccordionCustom'
import { ListItemAvatarCustomStyle } from './Components/ListItemAvatarCustom'
import { ListItemTextCustomStyle } from './Components/ListItemTextCustom'
import { IconButtonCustomStyle } from './Components/IconButtonCustom'
import { ButtonCustomStyle } from './Components/ButtonCustom'
import { TypographyCustomStyle } from './Components/TypographyCustom'
import { StyledDivStyle } from './Components/StyledDiv/StyledDiv'
import { AvatarCustomStyle } from './Components/AvatarCustom'
import { MuiFormHelperTextStyle } from './Components/MuiFormHelperText'
import { TextFieldCustomStyle } from './Components/TextFiledCustom'
import { GridCustom } from './Components/GridCustom'
import { BoxCustomStyle } from './Components/BoxCustom'
import { MenuCustomStyle } from './Components/MenuCustom'
import { StyledBStyle } from './Components/StyledB'
import { BadgeCustomStyle } from './Components/BadgeCustom'
import { BackdropCustomStyle } from './Components/BackdropCustom'
import { ListItemIconCustomStyle } from "./Components/ListItemIconCustom";
import { CheckboxCustomStyle } from "./Components/CheckboxCustom";
import { FormControlLabelCustomStyle } from "./Components/FormControlLabelCustom";

export const components = (mode, palette, animations, typography) => ({
  ...StyledDivStyle(mode, palette, animations, typography),
  ...PaperCustom(mode, palette, animations, typography),
  ...ListItemIconCustomStyle(mode, palette, animations, typography),
  ...MuiCard(mode, palette, animations, typography),
  ...ListItemCustom(mode, palette, animations, typography),
  ...ListItemSecondaryActionCustomStyle(mode, palette, animations, typography),
  ...ListButtomCustomStyle(mode, palette, animations, typography),
  ...AccordionCustomStyle(mode, palette, animations, typography),
  ...CheckboxCustomStyle(mode, palette, animations, typography),
  ...FormControlLabelCustomStyle(mode, palette, animations, typography),
  ...ListItemAvatarCustomStyle(mode, palette, animations, typography),
  ...ListItemTextCustomStyle(mode, palette, animations, typography),
  ...IconButtonCustomStyle(mode, palette, animations, typography),
  ...ButtonCustomStyle(mode, palette, animations, typography),
  ...TypographyCustomStyle(mode, palette, animations),
  typography,
  ...AvatarCustomStyle(mode, palette, animations, typography),
  ...MuiFormHelperTextStyle(mode, palette, animations, typography),
  ...TextFieldCustomStyle(mode, palette, animations, typography),
  ...GridCustom(mode, palette, animations, typography),
  ...BoxCustomStyle(mode, palette, animations, typography),
  ...MenuCustomStyle(mode, palette, animations, typography),
  ...StyledBStyle(mode, palette, animations, typography),
  ...BadgeCustomStyle(mode, palette, animations, typography),
  ...BackdropCustomStyle(mode, palette, animations, typography),
})

// first attempt:
// 1. export const animations with argument keyframes
// 2. import animations in globalTheme
// 3. add a new argument 'animations',to components
// 4. pass 'animations' to components.

export const animations = (keyframes) => {
  const animations = {
    cureAll: (argument, from, to) => {
      let blah = keyframes`
              from {
                ${argument[0]}: ${argument[1]} ${from} ${argument[2]};
              }
              to {
                ${argument[0]}: ${argument[1]} ${to} ${argument[2]};
              }
            `
      return blah
    },
    borderAnimation: (from, to) => {
      return keyframes`
              from {
                border-radius: ${from};
              }
              to {
                border-radius: ${to};
              }
            `
    },
  }
  return animations
}
