export const AvatarCustomStyle = (mode, palette) => ({
    AvatarCustom: {
        defaultProps: { sx: { width: '10', height: '10' } },
        styleOverrides: { "nothing": {} }, altStyleOverrides: {
            root: {},
            channel: {},
            smallAvatar: (props) => ({
                width: props.theme.spacing(6),
                height: props.theme.spacing(6),
                backgroundColor: palette.primary.main
            }),
            iconAvatar: (props) => ({
                width: props.theme.spacing(3),
                height: props.theme.spacing(3),
                backgroundColor: palette.primary.main
            }),
            timelineAvatarDesktop: (props) => ({
                width: props.theme.spacing(7),
                height: props.theme.spacing(7),
                backgroundColor: palette.primary.main
            }),
            timelineAvatar: (props) => ({
                width: props.theme.spacing(5),
                height: props.theme.spacing(5),
                backgroundColor: palette.primary.main
            }),
            profileAvatar: (props) => ({
                width: props.theme.spacing(36),
                height: 'auto',
                // height: props.theme.spacing(36),
                backgroundColor: palette.primary.main
            }),
            mobileNavAvi: {
                borderRadius: '6px 0px 6px 0px',
                border: `1px solid ${palette.text.flair}`
            },
            sidebarAvatar: {
                width: 33,
                height: 33,
                backgroundColor: palette.primary.main
            },
            curvedBorder: {
                borderRadius: '36px 0px 36px 0px',
                border: '1px solid #444'
            },
            blogPostImg: {
                height: '30px',
                width: '30px',
            },
            server: {},
            user: {},
        },
    },
})
