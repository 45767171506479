import React, { useCallback, useEffect, useState } from 'react'
import { dispatchMappers } from '../../../../redux/process/actionProcess'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import { connect } from 'react-redux'
import { statePropMapper } from '../../../../redux/process/selectorProcess'
import { RA1, RS1 } from '../../../../redux/actions'
import { ButtonCustom, StyledDiv, TypographyCustom } from '../../Styled'
import { useSocketRetrieve } from '../../../../hs/hooks'
import { IntegrableTable } from '../../../Integrable/Table'

const makeMapStateToProps = statePropMapper([
    RS1.getSocketResponses('socketResponses'),
])
const mapDispatchToProps = dispatchMappers([
    SA1.getBanDescriptions,
    SA1.unbanUser,
])
const BanManagement = (props) => {
    let [loaded, setLoaded] = useState(false)
    let [ver, setVer] = useState(0)
    let [users, setUsers] = useState({})
    let [banDescriptions, setBanDescriptions] = useState({})
    let [bans, setBans] = useState([])

    let [getBanDescriptions, response] = useSocketRetrieve(
        props.dispatch.server.getBanDescriptions,
        props['socketResponses'],
        'banManagement',
        'banDescriptions'
    )

    const retrieveBanDescriptions = () => {
        getBanDescriptions(props.server)
    }
    useEffect(retrieveBanDescriptions, [ver])
    useEffect(retrieveBanDescriptions, [])
    useEffect(() => {
        let userData = {}
        if (response.hasOwnProperty('users')) {
            setBanDescriptions(response.banDescriptions)
            response.users.forEach((user) => {
                userData[user.id] = user
            })
            setBans(response.bans)
            setUsers(userData)
            setLoaded(true)
        }
    }, [response])
    if (loaded) {
        return (
            <StyledDiv types={['managementPageOuterDiv', '_FVH']}>
                <TypographyCustom gutterBottom variant="h4" component="div">
                    Ban Management
                </TypographyCustom>
                <IntegrableTable
                    tableName={'Bans'}
                    rowHeaders={['Username:', 'Description:', 'Banned By:', '']}
                    rowData={bans}
                    tableFormatter={[
                        (banId) => users[banId].userName,
                        (banId) => banDescriptions[banId].reason,
                        (banId) =>
                            users[banDescriptions[banId].bannedBy].userName,
                        (banId) => (
                            <ButtonCustom
                                onClick={() => {
                                    props.dispatch.server.unbanUser(
                                        banId,
                                        props.server
                                    )
                                    setVer((ver) => ver + 1)
                                }}
                            >
                                Unban
                            </ButtonCustom>
                        ),
                    ]}
                />
            </StyledDiv>
        )
    } else {
        return (
            <StyledDiv types={['managementPageOuterDiv', '_FVH']}>
                <TypographyCustom gutterBottom variant="h4" component="div">
                    Ban Management
                </TypographyCustom>
            </StyledDiv>
        )
    }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(BanManagement)
