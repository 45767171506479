import React, {useState} from 'react'
import HSInput from '../../_reusable/Inputs/HSInput.js'
import {API} from '../../../hs/api.js'
import Button from '@mui/material/Button'
import {useHistory} from 'react-router-dom'
import BackButton from '../BackButton'
import {registerSuccessUrl} from "../url";
import {SIGNUP} from "../../../constants/env";
import {CircularProgress} from "@mui/material";
import {BoxCustom, ButtonCustom} from "../../Chat/Styled";
import {StyledDiv, TypographyCustom} from '../../Chat/Styled'
import BackToAuthButton from "../BackToAuthButton";

const defaultSignUpForm = {
    dob: '2001-01-01',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirmation: '',
    username: '',
    provider: false,
    inviteCode: '',
}

const Onboard = function (props) {
    const history = useHistory()
    const [formState, setFormState] = useState(defaultSignUpForm)
    const [showProgress, setShowProgress] = useState(false)
    const fieldChange = props.fieldChangeCreator(setFormState, formState)

    function registerAccount() {
        let submissionData = {...formState}
        submissionData.firebaseSignIn = !!props.auth.auth
        if (submissionData.firebaseSignIn) {
            submissionData.email = props.auth.email
        }
        setShowProgress(true)
        props
            .API('registerAccount')(submissionData)
            .then((res) => {
                props.responseHandler(
                    res,
                    () => {
                        props.authFunctions.login(
                            submissionData.email,
                            submissionData.password,
                            () => {
                                window.location = registerSuccessUrl
                            },
                            (error) => setShowProgress(false)
                        )
                    },
                    (error) => setShowProgress(false)
                )
            })
    }

    let inputProps = {
        fieldChange: fieldChange,
        errorData: props.errorData,
        errorStatus: props.errorStatus,
        method: 'registerAccount',
    }
    if (showProgress) {
        return (
            <BoxCustom style={{width: 'auto', backgroundColor: "rgba(255, 255, 255, 0.1)"}} types={['modalMenu']}>
                <CircularProgress/>
            </BoxCustom>)
    }
    return (
        <StyledDiv types={['signupFlexboxOuter', 'signupContainer']}>
            <StyledDiv>
                <TypographyCustom variant="h4" component="h4">
                    Sign up
                </TypographyCustom>
            </StyledDiv>
            <StyledDiv>
                <form>
                    <HSInput
                        id={'username'}
                        typographyLabel={'Username'}
                        label={'Username'}
                        value={formState.username}
                        {...inputProps}
                    />
                    <HSInput
                        id={'password'}
                        typographyLabel={'Password'}
                        label={'Password'}
                        type={'password'}
                        value={formState.password}
                        {...inputProps}
                    />
                    <HSInput
                        id={'passwordConfirmation'}
                        typographyLabel={'Password Confirmation'}
                        label={'Password Confirmation'}
                        type={'password'}
                        value={formState.passwordConfirmation}
                        {...inputProps}
                    />
                    <HSInput
                        id={'firstName'}
                        typographyLabel={'First Name'}
                        label={'First Name'}
                        value={formState.firstName}
                        {...inputProps}
                    />
                    <HSInput
                        id={'lastName'}
                        typographyLabel={'Last Name'}
                        label={'Last Name'}
                        value={formState.lastName}
                        {...inputProps}
                    />
                    {props.auth.auth ? (
                        <HSInput
                            id={'email'}
                            typographyLabel={'Email'}
                            label={'Email'}
                            disabled={'true'}
                            value={props.auth.email}
                            {...inputProps}
                        />
                    ) : (
                        <HSInput
                            id={'email'}
                            typographyLabel={'Email'}
                            label={'Email'}
                            value={formState.email}
                            {...inputProps}
                        />
                    )}
                    <br/>
                    {process.env.NODE_ENV === "production" ? <HSInput
                        id={'inviteCode'}
                        typographyLabel={'Invite Code'}
                        label={'Invite Code'}
                        value={formState.inviteCode}
                        {...inputProps}
                    /> : null}
                    <br/>
                    <HSInput
                        id={'dob'}
                        label={'Birthday'}
                        type={'date'}
                        {...inputProps}
                    />
                </form>
            </StyledDiv>

            {/* BUTTONS */}
            <StyledDiv types={['signupFlexboxOuter', 'signupButtons']}>

                <StyledDiv types={[['signupAdjacentButtons']]}>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => {
                            registerAccount()
                        }}
                    >
                        {SIGNUP}
                    </Button>
                    <BackToAuthButton/>
                </StyledDiv>

                <ButtonCustom
                    variant="contained"
                    color="primary"
                    onClick={(e) => {
                        if (props.auth?.auth) {
                            props.authFunctions.logout(props.forceReload)
                        }
                        window.location = window.location.origin + "/"
                    }}
                >
                    Cancel
                </ButtonCustom>

            </StyledDiv>
        </StyledDiv>
    )
}

export default Onboard
