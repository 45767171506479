export const GridCustom = (mode, palette) => ({
    GridCustom: {
        defaultProps: {},
        propOverrides: {},
        styleOverrides:{"nothing":{}},altStyleOverrides: {
            root: {},
            styledGrid: {
                '&::-webkit-scrollbar': {
                    width: "none",
                },
                '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: 'rgba(0,0,0,.2)',
                    outline: '1px solid slategrey',
                    borderRadius: 7,
                },
            },
        },
        infoModal: { background: palette.background.default },
        chatWindowStyledGrid: {
            '&::-webkit-scrollbar': {
                width: "none",
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
                background: 'rgba(0,0,0,.2)',
                outline: '1px solid slategrey',
                borderRadius: 7,
            },
        },
    },
})
