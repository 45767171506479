import Immutable, {List, update} from 'immutable'
import {store} from '../../../../redux/store'

const specialLog = (props, other) => {
    console.log(
        '%c (Region Controller Event: ' + props + ')',
        'background: #222; color: #7aa7dd',
        other
    )
}

export class regionControllerClass {
    constructor(
        id,
        messageList,
        meta,
        mode,
        serverId,
        channelId,
        rowIndexRef,
        dispatcher,
        serverDispatcher,
        messageLoader,
        setRegionMode,
        callbackHandler,
        reverseMode
    ) {
        this.endInitialization = this.endInitialization.bind(this)
        this.maxRegionSize = reverseMode ? 30 : 100
        this.expectedMidpoint = reverseMode ? 15 : 50
        this.userId = id
        this.serverId = serverId
        this.channelId = channelId
        this.initialized = false
        this.gridInitialized = false
        this.usePlaceholders = false
        this.mode = mode
        this.meta = meta
        this.rowIndexRef = rowIndexRef
        this.dispatcher = dispatcher
        this.serverDispatcher = serverDispatcher
        this.messageLoader = messageLoader
        this.callback = callbackHandler
        this.setRegionMode = setRegionMode
        this.newestMessage = ''
        this.oldestMessage = ''
        this.reverseMode = reverseMode
        this.regions = {main: {}, other: {}}
        let listContainer = {}
        listContainer[mode] = Immutable.List(messageList)
        this.lists = listContainer
    }

    prepareRegionFresh(mode) {
        this.regions[mode] = {
            version: 0,
            firstInit: false,
            fullyLoadedDown: false,
            fullyLoadedUp: false,
            storedScrollDataValid: false,
            bottomVisibleRow: 0,
            topScroll: 0,
            scrollMode: 'none',
            scrollDefault: 'bottom',
            bottomLockRemoval: 2,
            bottomLock: true,
            topLock: false,
            topLockRemoval: 0,
        }
    }

    _computeDerivedQuantities(preserveIndexes) {
        let list = this.lists[this.mode]
        let hasEnoughMessages = list.size > this.maxRegionSize
        let activeMessages
        if (!preserveIndexes) {
            activeMessages = list.slice(
                0,
                hasEnoughMessages ? this.maxRegionSize : list.size
            )
            this.regions[this.mode].topMessageIndex = 0
            this.regions[this.mode].bottomMessageIndex = activeMessages.size
        } else {
            activeMessages = list.slice(
                this.regions[this.mode].topMessageIndex,
                this.regions[this.mode].bottomMessageIndex
            )
        }
        this.regions[this.mode].activeMessages = activeMessages
        this.regions[this.mode].midpoint = hasEnoughMessages
            ? this.expectedMidpoint
            : list.size - 10
        this.regions[this.mode].size = activeMessages.size
        this.regions[this.mode].hasEnoughMessages = hasEnoughMessages
        this.regions[this.mode].allMessagesSize = list.size
        this.regions[this.mode].topMessageLoaded = list.get(0)
        this.regions[this.mode].bottomMessageLoaded = list.get(list.size - 1)
        this.regions[this.mode].fullyLoadedBoth =
            this.regions[this.mode].fullyLoadedUp &&
            this.regions[this.mode].fullyLoadedDown
    }

    initJumpToStoredLocation() {
        let reg = this.regions[this.mode]
        if (!this.meta.dataStored) {
            this.shiftRegionNewest(this.endInitialization)
        } else {
            if (reg.scrollDefault === 'bottom') {
                this.shiftRegionNewest(this.endInitialization)
            } else {
                specialLog('shiftToRowIndex')
                this.callback({
                    callbackType: 'shiftToRowIndex',
                    rowIndex: this.reverseMode ? 0 : reg.bottomVisibleRow,
                    alignmentData: this.reverseMode ? 'start' : 'end',
                    otherCallback: this.endInitialization,
                })
            }
        }
    }

    storeBottomVisibleRow(rowIndex) {
        let reg = this.regions[this.mode]
        reg.bottomVisibleRow = rowIndex
    }

    initialization() {
        if (this.meta.dataStored === true) {
            specialLog('initialization', {
                ...this.meta.storedChannelWindowDataOther,
            })
            //We start by initializing the main region
            this.regions['main'] = {
                ...this.meta.storedChannelWindowDataMain,
                firstInit: false,
            }
            this.newestMessage = this.regions['main'].bottomMessageLoaded
            this._computeDerivedQuantities(true)
            //Then we initialize the other region, if needed
            if (this.meta.activeMode === 'other') {
                this.mode = 'other'
                this.regions['other'] = {
                    ...this.meta.storedChannelWindowDataOther,
                    firstInit: false,
                }
                this.lists['other'] = this.meta.messagesOther
                this._computeDerivedQuantities(true)
            }
        } else {
            this.prepareRegionFresh('main')
            this._computeDerivedQuantities(false)
            this.newestMessage = this.regions['main'].bottomMessageLoaded
        }
    }

    activeRegion(mode) {
        return this.regions[mode]
    }

    changeActiveRegion(meta, setRegionMode, setBlockScrollOps) {
        if (meta.messagesOtherMeta.clientInstruction === 'newOther') {
            this.mode = 'other'
            this.lists['other'] = Immutable.List(meta.messagesOther)
            this.prepareRegionFresh('other')
            this._computeDerivedQuantities(false)
            let reg = this.regions[this.mode]
            reg.firstInit = true
            setBlockScrollOps(true)
            setRegionMode('other')
            reg.scrollDefault = 'scroll'
            this.shiftToMessage(meta.messagesOtherMeta.messageId)
        }
    }

    shiftToMessage(messageId) {
        specialLog('shiftToMessage')
        let reg = this.regions[this.mode]
        let list = this.lists[this.mode]
        let key = -1
        list.map((otherMessageId, index) => {
            if (otherMessageId === messageId) {
                key = index
            }
        })
        if (key === -1) {
            this.messageLoader(
                this.serverId,
                this.channelId,
                messageId,
                'center',
                'other',
                'newOther'
            )
            return null
        }
        let topMessageIndex, bottomMessageIndex
        if (key > this.expectedMidpoint) {
            topMessageIndex = key - this.expectedMidpoint
        } else {
            topMessageIndex = 0
        }
        if (key + this.expectedMidpoint < list.size) {
            if (
                key + this.expectedMidpoint < this.maxRegionSize &&
                list.size > this.maxRegionSize
            ) {
                bottomMessageIndex = this.maxRegionSize
            } else {
                bottomMessageIndex = key + this.expectedMidpoint
            }
        } else {
            bottomMessageIndex = list.size
        }
        let relativeIndex = key - topMessageIndex
        reg.topMessageIndex = topMessageIndex
        reg.bottomMessageIndex = bottomMessageIndex
        reg.activeMessages = list.slice(
            reg.topMessageIndex,
            reg.bottomMessageIndex
        )
        reg.storedScrollDataValid = false
        // reg.bottomLockRemoval = 2
        this.callback({
            callbackType: 'shiftToRowIndex',
            rowIndex: this.reverseMode ? 0 : relativeIndex,
            alignmentData: 'center',
        })
    }

    shiftRegionOlder(units) {
        specialLog('shiftRegionOlder')
        let reg = this.regions[this.mode]
        let list = this.lists[this.mode]
        const oldTMI = reg.topMessageIndex
        if (oldTMI - units >= 0) {
            reg.topMessageIndex -= units
            reg.bottomMessageIndex -= units
            reg.activeMessages = list.slice(
                reg.topMessageIndex,
                reg.bottomMessageIndex
            )
            reg.storedScrollDataValid = false
            this.callback({
                callbackType: 'shiftToRowIndex',
                rowIndex: reg.midpoint + 1,
                alignmentData: this.reverseMode ? 'end' : 'start',
            })
        } else if (oldTMI > 0) {
            reg.topMessageIndex = 0
            reg.bottomMessageIndex = Math.min(
                this.maxRegionSize,
                reg.allMessagesSize
            )
            reg.activeMessages = list.slice(
                reg.topMessageIndex,
                reg.bottomMessageIndex
            )
            this.callback({
                callbackType: 'shiftToRowIndex',
                rowIndex: reg.midpoint + 1,
                alignmentData: this.reverseMode ? 'end' : 'start',
            })
        }
    }

    shiftRegionNewer(units) {
        specialLog('shiftRegionNewer')
        let reg = this.regions[this.mode]
        let list = this.lists[this.mode]
        const oldBMI = reg.bottomMessageIndex
        if (oldBMI + units < reg.allMessagesSize) {
            reg.topMessageIndex += units
            reg.bottomMessageIndex += units
            reg.activeMessages = list.slice(
                reg.topMessageIndex,
                reg.bottomMessageIndex
            )
            reg.storedScrollDataValid = false
            this.callback({
                callbackType: 'shiftToRowIndex',
                rowIndex: this.reverseMode ? reg.midpoint : reg.midpoint + 1,
                alignmentData: this.reverseMode ? 'start' : 'end',
            })
        } else if (oldBMI < reg.allMessagesSize) {
            reg.topMessageIndex = Math.max(
                0,
                reg.allMessagesSize - this.maxRegionSize
            )
            reg.bottomMessageIndex = reg.allMessagesSize
            reg.activeMessages = list.slice(
                reg.topMessageIndex,
                reg.bottomMessageIndex
            )
            this.callback({
                callbackType: 'shiftToRowIndex',
                rowIndex: this.reverseMode ? reg.midpoint : reg.midpoint + 1,
                alignmentData: this.reverseMode ? 'end' : 'end',
            })
        }
    }

    shiftRegionNewest(otherCallback) {
        specialLog('shiftRegionNewest')
        let list = this.lists[this.mode]
        let reg = this.regions[this.mode]
        reg.storedScrollDataValid = false
        reg.topMessageIndex =
            reg.allMessagesSize > this.maxRegionSize
                ? reg.allMessagesSize - this.maxRegionSize
                : 0
        reg.bottomMessageIndex = reg.allMessagesSize
        reg.activeMessages = list.slice(
            reg.topMessageIndex,
            reg.bottomMessageIndex
        )
        reg.size = reg.activeMessages.size
        reg.bottomLockRemoval = 2
        reg.bottomLock = true
        reg.scrollDefault = 'bottom'
        this.callback({
            callbackType: 'shiftToRowIndex',
            otherCallback: otherCallback,
            rowIndex: this.reverseMode ? 0 : reg.bottomMessageIndex + 1,
            alignmentData: this.reverseMode ? 'end' : 'start',
        })
    }

    shiftRegionOldest() {
        specialLog('shiftRegionOldest')
        let list = this.lists[this.mode]
        let reg = this.regions[this.mode]
        reg.storedScrollDataValid = false
        reg.topMessageIndex = 0
        reg.bottomMessageIndex =
            reg.allMessagesSize > this.maxRegionSize
                ? this.maxRegionSize
                : reg.allMessagesSize
        reg.activeMessages = list.slice(
            reg.topMessageIndex,
            reg.bottomMessageIndex
        )
        reg.size = reg.activeMessages.size
    }

    setOldestMessage() {
        let AR = this.regions[this.mode]
        this.oldestMessage = AR.topMessageLoaded
    }

    endInitialization() {
        let AR = this.regions[this.mode]
        AR.firstInit = true
    }

    storeChannelWindowData() {
        this.dispatcher.storeChannelWindowData(
            this.channelId,
            {...this.regions.main},
            {...this.regions.other},
            this.mode
        )
    }

    addMessagesOld(updateMeta) {
        let reg = this.regions[this.mode]
        reg.storedScrollDataValid = false
        let newList = Immutable.List(updateMeta.list)
        this.lists[this.mode] = newList.concat(this.lists[this.mode])
        this.regions[this.mode].topMessageIndex += updateMeta.list.length
        this.regions[this.mode].bottomMessageIndex += updateMeta.list.length
        this._computeDerivedQuantities(true)
        this.shiftRegionOlder(this.expectedMidpoint)
    }

    addMessagesNew(updateMeta) {
        let reg = this.regions[this.mode]
        reg.storedScrollDataValid = false
        let newList = Immutable.List(updateMeta.list)
        this.lists[this.mode] = this.lists[this.mode].concat(newList)
        this._computeDerivedQuantities(true)
        this.shiftRegionNewer(this.expectedMidpoint)
    }

    editMessage(updateMeta) {
        // let reg = this.regions[this.mode]
        // reg.storedScrollDataValid = false
        // let newList = Immutable.List(updateMeta.list)
        // this.lists[this.mode] = this.lists[this.mode].concat(newList)
        this._computeDerivedQuantities(true)
        // this.shiftRegionNewer(this.expectedMidpoint)
        this.callback({
            callbackType: 'refresh',
        })
    }

    addMessageNewSingle(updateMeta, defer) {
        if (this.mode === 'other' && updateMeta.messageUserId === this.userId) {
            this.adaptiveRegionSwitch(null, false)
        }
        let reg = this.regions['main']
        let cond =
            reg.bottomLockRemoval > 0 ||
            updateMeta.messageUserId === this.userId
        reg.storedScrollDataValid = false
        this.lists['main'] = this.lists['main'].push(updateMeta.messageId)
        this.newestMessage = updateMeta.messageId
        this._computeDerivedQuantities(true)
        if (cond) {
            this.shiftRegionNewest(null)
        } else {
            //doNothing, ig?
        }
    }

    adaptiveRegionSwitch(messageId, doMerge) {
        specialLog('adaptiveRegionSwitch')
        this.mode = 'main'
        if (doMerge) {
            let mainList = this.lists['main']
            let otherList = this.lists['other']
            let newOther = Immutable.List([])
            otherList.map((messageId) => {
                if (!mainList.includes(messageId)) {
                    newOther = newOther.push(messageId)
                }
            })
            this.lists['main'] = newOther.concat(mainList)
            this.regions['main'].bottomMessageIndex =
                this.regions['other'].bottomMessageIndex
            this.regions['main'].topMessageIndex =
                this.regions['other'].topMessageIndex
            this._computeDerivedQuantities(true)
            this.dispatcher.mergeOtherIntoMain(this.lists['main'])
            this.shiftRegionNewer(this.expectedMidpoint)
        } else {
            this.shiftRegionNewest(null)
        }
        this.regions['main'].firstInit = true
        this.setRegionMode('main')
    }

    processUpdateData(updatePackage) {
        if (updatePackage) {
            let updateMeta = updatePackage.updates[updatePackage.updateId - 1]
            updateMeta.type === 'addMessageNewSingle'
                ? this.addMessageNewSingle(updateMeta, false)
                : null
            updateMeta.type === 'addMessagesOld'
                ? this.addMessagesOld(updateMeta, false)
                : null
            updateMeta.type === 'addMessagesNew'
                ? this.addMessagesNew(updateMeta, false)
                : null
            updateMeta.type === 'setTopMessage' ?
                this.setOldestMessage() :
                null
            updateMeta.type === 'editMessage'
                ? this.editMessage()
                : null
        }
    }

    handleTopScroll(AR) {
        specialLog('topScrollHit!')
        if (AR.topMessageIndex === 0) {
            if (AR.topMessageLoaded !== this.oldestMessage) {
                this.messageLoader(
                    this.serverId,
                    this.channelId,
                    AR.topMessageLoaded,
                    'topOlder',
                    this.mode,
                    'topScroll'
                )
            }
        } else {
            this.shiftRegionOlder(this.expectedMidpoint)
        }
    }

    handleBottomScroll(AR) {
        if (AR.bottomMessageIndex === AR.allMessagesSize) {
            AR.scrollDefault = 'bottom'
            if (
                this.mode === 'other' &&
                AR.bottomMessageLoaded > this.regions['main'].topMessageLoaded
            ) {
                this.adaptiveRegionSwitch(AR.bottomMessageLoaded, true)
            } else {
                if (AR.bottomMessageLoaded !== this.newestMessage) {
                    this.serverDispatcher.messageLoad(
                        this.serverId,
                        this.channelId,
                        AR.bottomMessageLoaded,
                        'bottomNewer',
                        this.mode,
                        'bottomScroll'
                    )
                }
            }
        } else {
            if (
                this.mode === 'other' &&
                AR.bottomMessageLoaded > this.regions['main'].topMessageLoaded
            ) {
                this.adaptiveRegionSwitch(AR.bottomMessageLoaded, true)
            } else {
                this.shiftRegionNewer(this.expectedMidpoint)
            }
        }
    }

    updateScrollStatus({updateType, newScrollTop}) {
        specialLog('updateScrollStatus')
        let AR = this.regions[this.mode]
        if (AR.firstInit) {
            if (updateType === 'top') {
                if (this.reverseMode) {
                    this.handleBottomScroll(AR)
                } else {
                    AR.bottomLockRemoval > 0
                        ? (AR.bottomLockRemoval -= 1)
                        : null
                    this.handleTopScroll(AR)
                }
            }
            if (updateType === 'scroll') {
                if (AR.bottomLockRemoval === 0) {
                    AR.storedScrollDataValid = true
                    AR.bottomVisibleRow = this.rowIndexRef.current
                    AR.topScroll = newScrollTop
                    AR.scrollMode = updateType
                    AR.scrollDefault = updateType
                } else {
                    AR.bottomLockRemoval > 0 ? (AR.bottomLockRemoval -= 1) : 0
                }
            }
            if (updateType === 'bottom') {
                if (this.reverseMode) {
                    AR.bottomLockRemoval > 0
                        ? (AR.bottomLockRemoval -= 1)
                        : null
                    this.handleTopScroll(AR)
                } else {
                    this.handleBottomScroll(AR)
                }
            }
        }
    }
}
