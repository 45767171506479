import React from "react"

import { StyledDiv } from "../../../../../Chat/Styled"
import { Style } from "@mui/icons-material"

// import { ButtonCustom } from '../../../Chat/Styled'
// import { StyledDiv } from '../../../Chat/Styled'
// import { dispatchMappers } from '../../../../redux/process/actionProcess'
// import { RA1 } from '../../../../redux/actions'
// import { connect } from 'react-redux'

const TestCardDisplay = function (props) {

    // Every card display must have n different modes.
    // Which mode is rendered is determined by a switch.
    // However, these modes must be the same across all cards.
    // Suggested modes: full-desktop, half-desktop, compressed-desktop, full, compressed

    // #
    switch (props.displayMode) {
        case 'fullDesktop':
            return (
                <StyledDiv types={['generalCard', 'fullCard', 'desktopFull']}>

                    <StyledDiv types={['cardTitle']}>
                        {props.title}
                    </StyledDiv>

                    <StyledDiv types={['desktopInner']}>
                        <StyledDiv types={['cardImage', 'desktopImgFull']}>
                            <img src={props.image} />
                        </StyledDiv>

                        <StyledDiv types={['cardData', 'desktopDataFull']}>
                            {props.data}
                        </StyledDiv>
                    </StyledDiv>

                </StyledDiv>
            )
        case 'halfDesktop':
            return (
                <StyledDiv types={['generalCard', 'halfCard']}>
                    <StyledDiv types={['cardImage', 'halfImage']}>
                        <img src={props.image} />
                    </StyledDiv>
                    <StyledDiv types={['halfText']}>
                        <StyledDiv types={['cardTitle', 'titleCompressed']}>{props.title}</StyledDiv>
                        <StyledDiv types={['halfData']}>{props.data}</StyledDiv>
                    </StyledDiv>
                </StyledDiv>
            )
        case 'compressedDesktop':
            return (
                <StyledDiv types={['generalCard', 'compressedDesktop']}>
                    <StyledDiv types={['cardTitle', 'titleCompressed']}>{props.title}</StyledDiv>
                    <StyledDiv types={['dataCompressed']}>{props.data}</StyledDiv>
                </StyledDiv>
            )
        case 'full': // mobile
            return (
                <StyledDiv types={['generalCard', 'fullCard', 'mobileFull']}>
                    <StyledDiv types={['cardTitle']}>{props.title}</StyledDiv>
                    <StyledDiv types={['cardIma ge', 'mobileImgFull']}>
                        <img src={props.image} />
                    </StyledDiv>
                    <StyledDiv types={['cardData']}>{props.data}</StyledDiv>
                </StyledDiv>
            )
        default:
            return (
                <StyledDiv types={['generalCard', 'compressedCard']}>
                    <StyledDiv types={['cardTitle', 'titleCompressed']}>{props.title}</StyledDiv>
                    <StyledDiv types={['dataCompressed']}>{props.data}</StyledDiv>
                </StyledDiv>
            )
    }
}

export const testCardPackage = {
    requirements: ['displayMode', 'image', 'title', 'data'],
    CardDisplay: TestCardDisplay
}