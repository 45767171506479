import React from 'react'
import {connect} from 'react-redux'
import {RA1, RS1} from '../../../../redux/actions.js'
import {SA1} from '../../../../hs/requestGlobals.mjs'
import {dispatchMappers} from '../../../../redux/process/actionProcess.js'
import SocketForm from '../../../_reusable/SocketForm/SocketForm'
import {statePropMapper} from '../../../../redux/process/selectorProcess'

const makeMapStateToProps = statePropMapper([])
const mapDispatchToProps = dispatchMappers([SA1.modifyProfile])

const ModifyProfileModal = function (props) {
    const dispatchMethod = ({profileString}) => {
        props.dispatch.server.modifyProfile(
            props.AS.modals.state.modalData.serverId,
            profileString,
            'modifyProfile',
            'modifyProfile'
        )
    }
    return (
        <SocketForm
            formMethod={'modifyProfile'}
            formDispatch={dispatchMethod}
            socketForm
            onClose={props.closeModalMenu}
            onSuccess={props.closeModalMenu}
            description={'Would you like to change your profile description?'}
            formFields={[
                {id: 'profileString', typographyLabel: 'Profile Description', type: 'multiline'},
            ]}
            formButtons={[
                {label: 'Modify', icon: 'add', isSubmitButton: true},
            ]}
            formTypography={{label: 'Modify Profile Description'}}
            successMessage={'Profile Modified'}
        />
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(ModifyProfileModal)
