import { createSlice } from '@reduxjs/toolkit'
import { createCachedSlice } from '../cache/createCachedSlice.js'

const abstractRole = createCachedSlice({
    name: 'abstractRole',
    initialState: {},
    reducers: {
        addAbstractRole: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                const { id } = action.payload
                return {
                    ...state,
                    [id]: action.payload,
                }
            },
        },
        addAbstractRoles: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                let newState = { ...state }
                for (let key in action.payload) {
                    if (action.payload.hasOwnProperty(key)) {
                        newState[key] = action.payload[key]
                    }
                }
                return newState
            },
        },
    },
})

const abstractRolesSlice = abstractRole
const abstractRoleActions = abstractRole.actions
const addAbstractRoles = abstractRole.actions.addAbstractRoles
const abstractRoles = abstractRole.reducer
export {
    abstractRolesSlice,
    addAbstractRoles,
    abstractRoles,
    abstractRoleActions,
}
