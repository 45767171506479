import react from 'react'
import SocketForm from '../../../_reusable/SocketForm/SocketForm'
import { statePropMapper } from '../../../../redux/process/selectorProcess'
import { connect } from 'react-redux'
import { RA1, RS1 } from '../../../../redux/actions'
import { dispatchMappers } from '../../../../redux/process/actionProcess'

const makeMapStateToProps = statePropMapper([
    RS1.store('_appState', '_appState'),
])

const mapDispatchToProps = dispatchMappers([RA1._appState.closeModalMenu])

const PaymentModal = function (props) {
    const modalData = props.AS.modals.state.modalData
    return (
        <SocketForm
            // prelim menu on first click?
            initialScreen={false}
            // formState keeps track of all parameters submitted, this sets default params.
            formStateDefaults={{}}
            // unfinished confirmation screen before submit
            useConfirmationScreen={true}
            // new key "shop payment element on xyz page"

            // added key "backButton"
            // also "onSuccess"

            // if more screens are needed for advanced settings
            useMultipleFormPages={true}
            // executes on confirmation screen
            confirmationFunction={(obj) => {}}
            // name of api method
            formMethod={'stripePayment'}
            // operation done when form submitted  --  only use when mutating form state
            formDispatch={(obj) => {}}
            // probably unneeded
            socketForm
            // function executes when socket form is closed
            onClose={() => {
                props.closeModalMenu()
                // appState closeModalMenu
            }}
            // execute extra function on success
            onSuccess={() => {
                // TODO: if onetime, reload page on close
            }} //props.dispatch._appState.closeModalMenu()
            // text in the form
            description={''}
            // most important! part, fields 4 form,
            formFields={[
                {
                    id: 'payment', // key where var is stored in form state
                    type: 'payment', // hs input used
                    typographyLabel: '',
                    requireError: 'Please enter payment information',
                    product: modalData.selectedProduct,
                    isCreator: modalData.isCreator,
                    require: true,
                    page: 1, // which page it appears on
                },
            ]}
            formButtons={[
                {
                    label: 'Purchase',
                    icon: 'add',
                    isSubmitButton: true,
                    isCloseButton: false,
                },
                {
                    label: 'Report',
                    icon: 'add',
                    types: ['square'],
                    isSubmitButton: false,
                    isCloseButton: false,
                },
                {
                    label: 'Back',
                    icon: 'add',
                    types: ['square'],
                    isSubmitButton: false,
                    isCloseButton: true,
                },
            ]}
            formTypography={{ label: 'Purchase' }}
            successMessage={'Payment succeeded!'}
        />
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(PaymentModal)
