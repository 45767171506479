import React, { useState, useEffect } from 'react'
import { dispatchMappers } from '../../../../redux/process/actionProcess'
import { statePropMapper } from '../../../../redux/process/selectorProcess'
import { RS1 } from '../../../../redux/actions'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import { connect } from 'react-redux'
import ProductList from '../ProductList'
import ModifyProduct from './ModifyProduct'
import DefaultError from '../ErrorPage'
import AddPaymentCreds from './AddPaymentCreds'

const makeMapStateToProps = statePropMapper([
    RS1.store('_appState', '_appState'),
])
const mapDispatchToProps = dispatchMappers([SA1.getProductsByServer])

const Creator = function (props) {
    // #
    const [catalog, setCatalog] = useState(null)
    const [currentPage, setCurrentPage] = useState('products')
    const [selectedProduct, setSelectedProduct] = useState({})
    const [reloadCatalog, setReloadCatalog] = useState(false)

    // #
    const buttonClicked = (newPage) => (productClicked) => {
        if (newPage === 'products') {
            setReloadCatalog(true)
        }

        setCurrentPage(newPage)

        if (productClicked) {
            setSelectedProduct(productClicked)
        } else {
            setSelectedProduct({})
        }
    }

    // #
    useEffect(() => {
        if (!catalog || !props._appState?.catalog || reloadCatalog) {
            props.dispatch.server.getProductsByServer(props.serverId)
            setReloadCatalog(false)
        }
    }, [catalog, props?._appState?.catalog, reloadCatalog])

    useEffect(() => {
        if (props._appState?.catalog != catalog) {
            setCatalog(props._appState.catalog)
        }
    }, [props._appState?.catalog])

    // #
    switch (currentPage) {
        case 'products':
            // TODO: Only call this if catalog not stored in _appState or in state
            // I think that !catalog means this is a new page, and
            // !_appstate.catalog means we haven't received the catalog from the backend.

            return (
                <ProductList
                    types={[]}
                    listType={'creator'}
                    catalog={catalog}
                    addPaymentCreds={buttonClicked('payment')}
                    addProduct={buttonClicked('create')}
                    deleteProduct={buttonClicked('delete')}
                    onClick={buttonClicked('edit')}
                />
            )

        case 'edit':
            // setReloadCatalog(true)
            return (
                <ModifyProduct
                    serverId={props.serverId}
                    formType={'edit'}
                    buttonText={'Edit Product'}
                    backButton={buttonClicked('products')}
                    product={selectedProduct}
                />
            )

        case 'create':
            // setReloadCatalog(true)
            return (
                <ModifyProduct
                    serverId={props.serverId}
                    formType={'create'}
                    buttonText={'Create Product'}
                    backButton={buttonClicked('products')}
                    product={selectedProduct}
                />
            )

        case 'delete':
            // setReloadCatalog(true)
            return (
                <ModifyProduct
                    serverId={props.serverId}
                    formType={'delete'}
                    buttonText={'Delete Product'}
                    backButton={buttonClicked('products')}
                    product={selectedProduct}
                />
            )

        case 'payment':
            return <AddPaymentCreds backButton={buttonClicked('products')} />

        default:
            return (
                <DefaultError message={'We encountered an unexpected error.'} />
            )
    }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(Creator)
