export const ListItemSecondaryActionCustomStyle = (mode, palette) => ({
    ListItemSecondaryActionCustom: {
        defaultProps: {},
        styleOverrides:{"nothing":{}},altStyleOverrides: {
            root: {},
            friendsNav: {
                position: 'relative',
                transform: 'none',
                right: 'auto',
                top: 'auto',

                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start'
            },
        },
    },
})
