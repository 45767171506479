import React, { useState, useEffect } from 'react'
import { dispatchMappers } from '../../../../redux/process/actionProcess'
import { statePropMapper } from '../../../../redux/process/selectorProcess'
import { RS1 } from '../../../../redux/actions'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import { connect } from 'react-redux'
import ProductList from '../ProductList'
import Purchase from './Purchase'
import DefaultError from '../ErrorPage'
import SuccessPage from './SuccessPage'
import Transactions from './Transactions'

const makeMapStateToProps = statePropMapper([
    RS1.store('_appState', '_appState'),
])

const mapDispatchToProps = dispatchMappers([SA1.getProductsByServer])

const Customer = function (props) {
    // #
    const [catalog, setCatalog] = useState(null)
    const [currentPage, setCurrentPage] = useState('products')
    const [selectedProduct, setSelectedProduct] = useState({})
    const [reloadCatalog, setReloadCatalog] = useState(false)

    // #
    const buttonClicked = (newPage) => (productClicked) => {
        if (newPage === 'products') {
            setReloadCatalog(true)
        }

        setCurrentPage(newPage)

        if (productClicked) {
            setSelectedProduct(productClicked)
        } else {
            setSelectedProduct({})
        }
    }

    const returnToChat = () => {
        // TODO: want to get active channel & put them back there if this button is clicked
    }

    const reportProduct = (e) => {
        //TODO: popup report form
        console.log('report product ' + e.target.id)
    }

    // #
    useEffect(() => {
        // TODO: Only call this if catalog not stored in _appState or in state
        // I think that !catalog means this is a new page, and
        // !_appstate.catalog means we haven't received the catalog from the backend.
        if (!catalog || !props._appState?.catalog || reloadCatalog) {
            props.dispatch.server.getProductsByServer(props.serverId)
            setReloadCatalog(false)
        }
    }, [])

    useEffect(() => {
        if (props._appState?.catalog != catalog) {
            setCatalog(props._appState.catalog)
        }
    }, [catalog, props._appState?.catalog, reloadCatalog])

    // #
    switch (currentPage) {
        case 'products':
            return (
                <ProductList
                    types={[]}
                    catalog={catalog}
                    listType={'customer'}
                    subscriptions={buttonClicked('transactions')}
                    reportProduct={reportProduct}
                    onClick={buttonClicked('purchase')}
                />
            )

        case 'purchase':
            return (
                <Purchase
                    backButton={buttonClicked('products')}
                    onSuccess={buttonClicked('success')}
                    product={selectedProduct}
                />
            )

        case 'transactions':
            return <Transactions backButton={buttonClicked('products')} />

        case 'success':
            return (
                <SuccessPage
                    returnToChat={returnToChat}
                    returnToShop={buttonClicked('products')}
                />
            )

        default:
            return (
                <DefaultError message={'We encountered an unexpected error.'} />
            )
    }
}

export default connect(makeMapStateToProps, mapDispatchToProps)(Customer)
