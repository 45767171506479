import React from 'react'
import './firstrow.css'
import { homePageText } from "../../homePageText";

// dynamically configure style before rendering object in return statement
// how to use em & vh etc
// find react component to deal with that
// something that monitors for when page is resized

const FirstRow = function (props) {
    return (
        <div className="row first">
            <div className="column first text-box">
                <div className="intro contents">
                    {/*<span id="tagline">{homePageText.h1}</span>*/}
                    {/*<br />*/}
                    <div id="desc">
                        {/* {homePageText.t1} */}
                        <span>Welcome</span><br />
                        <span>欢迎</span><br />
                        <span> مرحبا </span><br />
                        <span>स्वागत</span><br />
                        <span>Bienvenue</span><br />
                        <span>שלום</span><br />
                        {/* <span> أهلًا و سهلًا </span> */}
                    </div>
                </div>
            </div>

            <div className="rightfloat">
                <div className="inner">

                    This is where you will learn, play, meet, <br />
                    and create. Build your online tribe, or join others <br />
                    in theirs. Monetize your digital presence, sell your time, <br />
                    or learn how to cook the most delicious shakshuka from your <br />
                    favorite Moroccan grandma-turned-online-chef. We are not <br />
                    building “yet-another-social-media-platform”; our aim was to<br />
                    build a human platform, where you engage as your heart desires.

                    {/* <div class="tagline">
                        PAA, a human platform; <br />
                        <div class="rightalign">
                            engage as your heart desires.</div>
                    </div> */}
                </div>
            </div>

            <div className="column second gradient"></div>
        </div>
    )
}

export default FirstRow
