import React from "react"

import { StyledDiv, ButtonCustom, TypographyCustom, PaperCustom } from "../../../../../Chat/Styled"
import { Style } from "@mui/icons-material"
import { textAlign } from "@mui/system"
import { FormHelperText } from "@mui/material"
import { Pagination } from "@mui/lab"
import { usePages } from "../../../../../../hs/hooks"

// import { ButtonCustom } from '../../../Chat/Styled'
// import { StyledDiv } from '../../../Chat/Styled'
// import { dispatchMappers } from '../../../../redux/process/actionProcess'
// import { RA1 } from '../../../../redux/actions'
// import { connect } from 'react-redux'

const AppointmentButton = function (props) {

    let helperText = props.length / 60000 + 'min'
    if (props.status === 'pending') {
        helperText = 'Pending'
    }

    let datetime = new Date(props.initialDate)

    let time = datetime.toLocaleTimeString("en-US", {
        hour: '2-digit',
        minute: '2-digit'
    })
    // Get time from props.initialDate
    // Then, description?
    // then somehow - 3 at a time..

    return (
        <StyledDiv types={[
            'calendarRowFlexCol',
            props.desktop ? '' : 'apptButtonMobile'
        ]}>
            <StyledDiv types={[
                'calendarButtonContainer',
                'apptButtonMobile'
            ]}>
                <FormHelperText sx={{
                    maxWidth: '74px',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflowX: 'hidden',
                    minHeight: 'calc(1.66 * 0.75rem)'
                }}>{props.name}</FormHelperText>
                <ButtonCustom
                    types={['calendarButton']}
                    status={props.status}
                    onMouseEnter={() => {
                        props.setHoverAppointment(props.appointment)
                    }}
                    onMouseLeave={() => {
                        props.setHoverAppointment({})
                    }}
                    onClick={() => props.handleApptSelection({ ...props })}
                >
                    {time}
                </ButtonCustom>
                <FormHelperText>{helperText}</FormHelperText>
            </StyledDiv>
        </StyledDiv>
    )
}

const AppointmentList = (props) => {
    const [setPageNumber, totalPages, truncatedArray] = usePages(props.appointments, 3)
    if (truncatedArray.length > 0) {
        return (
            <PaperCustom types={[
                'generalCard',
                'fullCard',
                'apptList',
                'tighterCurves',
            ]}
                style={{
                    textAlign: 'center',
                    // padding: '1.3rem 2rem',
                    padding: '0rem 5% 15px',
                }}>
                <StyledDiv types={['apptFullTitle', 'twoLineClamp', 'apptListTitle']}>
                    <TypographyCustom variant="h5"
                        style={{
                            lineHeight: '2rem',
                        }}>
                        {props.title}
                    </TypographyCustom>
                </StyledDiv>
                <StyledDiv types={[
                    'calendarRow',
                    'apptFullData',
                    'apptListData',
                    props.desktop ? '' : 'apptFullColumn'
                ]}>
                    <StyledDiv types={['apptButtons']}>
                        {truncatedArray.map((appt, idx) =>
                            <AppointmentButton
                                key={idx}
                                idx={idx}
                                desktop={props.desktop}
                                adminViewMode={props.adminViewMode}
                                AS={props.AS}
                                setHoverAppointment={props.setHoverAppointment}
                                openModalMenu={props.openModalMenu}
                                setSelectedAppointment={props.setSelectedAppointment}
                                serverId={props.server}
                                handleApptSelection={props.handleApptSelection}
                                {...appt} />
                        )}
                    </StyledDiv>
                </StyledDiv>

                <StyledDiv types={['apptListPagination']}>
                    <Pagination
                        count={totalPages}
                        onChange={setPageNumber} />
                </StyledDiv>
            </PaperCustom>
        )
    } else {
        return null
    }
}

const AppointmentCard = (props) => {

    let fullApptDate = new Date(props.appt.initialDate).toLocaleString("en-US", {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    })
    let fullApptTime = new Date(props.appt.initialDate).toLocaleTimeString("en-US", {
        hour: '2-digit',
        minute: '2-digit'
    })

    return (
        <StyledDiv types={[
            'generalCard',
            'fullCard',
            props.half ? 'apptHalfCard' : 'apptFullCard',
            props.half ? 'tighterCurves' : '',
        ]}>
            <StyledDiv types={[
                'apptFullTitle',
                'twoLineClamp',
                props.half ? 'apptHalfInnerPadding' : ''
            ]}>
                <TypographyCustom variant="h5"
                    style={{
                        fontSize: '1.3rem'
                    }}>
                    {props.title}
                </TypographyCustom>
            </StyledDiv>
            <StyledDiv types={[
                'apptFullData',
                props.half ? 'apptHalfInnerPadding' : ''
            ]}>
                <StyledDiv types={['apptFullDatetime']}>
                    <TypographyCustom variant="p"
                        style={{
                            fontSize: '0.8em',
                            lineHeight: '1.7em',
                        }}>
                        {fullApptDate}<br />
                        {fullApptTime}
                    </TypographyCustom>
                </StyledDiv>
                <StyledDiv types={['apptFullButton']}>
                    <ButtonCustom
                        variant="contained"
                        sx={{
                            width: 'fit-content',
                            height: '1.5rem'
                        }}>
                        {props?.product ?
                            props.product.price :
                            'book'}
                    </ButtonCustom>
                </StyledDiv>
            </StyledDiv>
        </StyledDiv>
    )
}

const AppointmentCardDisplay = function (props) {

    // Every card display must have n different modes.
    // Which mode is rendered is determined by a switch.
    // However, these modes must be the same across all cards.
    // Suggested modes: full-desktop, half-desktop, compressed-desktop, full, compressed

    let fullApptDate = ''
    let fullApptTime = ''

    // #
    switch (props.displayMode) {
        case 'fullDesktop':
            return <AppointmentCard {...props} desktop={true} half={false} />
        case 'fullTotalDesktop':
            return <AppointmentList {...props} desktop={true} fullMsg={true} />
        case 'halfDesktop':
            return <AppointmentCard {...props} desktop={true} half={true} />
        case 'compressedDesktop':
            fullApptDate = new Date(props.appt.initialDate).toLocaleString("en-US", {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            })
            fullApptTime = new Date(props.appt.initialDate).toLocaleTimeString("en-US", {
                hour: '2-digit',
                minute: '2-digit'
            })
            return (
                <StyledDiv types={['generalCard', 'compressedDesktop', 'compressedAppt']}>
                    <StyledDiv types={['threadCardTop', 'threadCardLeft']}
                        half={true}
                        style={{
                            backgroundColor: 'transparent',
                        }}>
                        <StyledDiv types={[
                            'cardTitle',
                            'titleCompressed',
                            'threadCardTitleCompressed']}>
                            {props.title}
                        </StyledDiv>
                    </StyledDiv>
                    <StyledDiv types={['threadCardRight', 'apptDateTimeCompressed']}>
                        <TypographyCustom variant="p"
                            style={{
                                fontSize: '0.8em',
                                lineHeight: '1.7em',
                                display: 'block',
                                textAlign: 'right',
                            }}>
                            {fullApptDate}<br />
                            {fullApptTime}
                        </TypographyCustom>
                    </StyledDiv>
                </StyledDiv>
            )
        case 'full': // mobile
            return <AppointmentCard {...props} desktop={false} />
        case 'fullTotal': // mobile
            return <AppointmentList {...props} desktop={false} fullMsg={true} />
        default:
            fullApptDate = new Date(props.appt.initialDate).toLocaleString("en-US", {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            })
            fullApptTime = new Date(props.appt.initialDate).toLocaleTimeString("en-US", {
                hour: '2-digit',
                minute: '2-digit'
            })
            return (
                <StyledDiv types={['generalCard', 'compressedCard', 'compressedAppt']}>
                    <StyledDiv types={['threadCardTop', 'threadCardLeft']}
                        half={true}
                        style={{
                            backgroundColor: 'transparent',
                        }}>
                        <StyledDiv types={[
                            'cardTitle',
                            'titleCompressed',
                            'threadCardTitleCompressed']}>
                            {props.title}
                        </StyledDiv>
                    </StyledDiv>
                    <StyledDiv types={['threadCardRight', 'apptDateTimeCompressed']}>
                        <TypographyCustom variant="p"
                            style={{
                                fontSize: '0.8em',
                                lineHeight: '1.7em',
                                display: 'block',
                                textAlign: 'right',
                            }}>
                            {fullApptDate}<br />
                            {fullApptTime}
                        </TypographyCustom>
                    </StyledDiv>
                </StyledDiv>
            )
    }
}

export const appointmentCardPackage = {
    requirements: ['displayMode', 'image', 'title', 'data'],
    CardDisplay: AppointmentCardDisplay
}