import React, { useState } from 'react'
import { Menu, Item, Separator, Submenu } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import { connect } from 'react-redux'
import { statePropMappers } from '../../../../../redux/process/selectorProcess.js'
import { useHistory } from "react-router-dom";

import { trigger } from '../../../../../events.js'
import { SA1 } from '../../../../../hs/requestGlobals.mjs'
import { RS1 } from '../../../../../redux/actions.js'
import { dispatchMappers } from '../../../../../redux/process/actionProcess.js'
import { statePropMapper } from '../../../../../redux/process/selectorProcess.js'

// const makeMapStateToProps = statePropMappers(
//     {
//         getUserMeta: ['userMeta', []],
//         getUserTopRank: ['userTopRank', []],
//         getServerAbstractRoles: ['serverAbstractRoles', []],
//     },
//     {}
// )

const makeMapStateToProps = statePropMapper([
    RS1.getUserBooleanPerm('isAdmin', ['isAdmin']),
    RS1.getUserBooleanPerm('isModerator', ['isModerator']),
    RS1.getServerUsersOb('users'),
    RS1.store('_userId', '_userId'),
])

const makeDispatchToProps = dispatchMappers([
    SA1.deleteMessage,
])

function SingleThreadMenu(props) {
    if (props?.message?.message && !props.message.message.deleted) {
        const hasDeletePerm =
            props.isModerator ||
            props.isAdmin ||
            props._userId === props.message.message?.uId

        const hasEditPerm = props._userId === props.message.message?.uId
        let user = props.users[props.message.message?.uId]

        return (
            <Menu id={props.menuId}>

                {hasDeletePerm ? (
                    <Item
                        onClick={() => {
                            props.dispatch.server.deleteMessage(
                                props.message.message.sId,
                                props.message.message.mId
                            )
                        }}
                    >
                        Delete Message
                    </Item>) : null}
                {hasEditPerm ? (
                    <Item
                        onClick={() => {
                            trigger('setChatInput:edit', {
                                sId: props.message.message.sId,
                                mId: props.message.message.mId,
                                message: props.message.message.message,
                            })
                        }}
                    >
                        Edit Message
                    </Item>
                ) : null}
                <Item onClick={props.handleItemClick}>Add to Highlights</Item>

            </Menu>
        )
    } else { return null }
}

export default connect(makeMapStateToProps, makeDispatchToProps)(SingleThreadMenu)
