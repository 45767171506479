import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RA1, RS1 } from '../../../../redux/actions.js'
import 'react-contexify/dist/ReactContexify.css'
import { withAuth } from '../../../_contexts/withAuth/index.js'
import {
    statePropMapper,
    statePropMappers,
} from '../../../../redux/process/selectorProcess.js'
import { dispatchMappers } from '../../../../redux/process/actionProcess.js'
import withMenu from '../../../_wrappers/withMenu/index.js'
import withMaterialDnD from '../../../_wrappers/withMaterialDnD/index.js'
import ServerNavMenu from './ServerNavMenu/index.js'
import {
    ListItemCustom,
    ListItemIconCustom,
    ListItemTextCustom,
    StyledDiv,
    TypographyCustom
} from '../../Styled'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import GenericCard from '../../../_reusable/Cards/UniversalCard/index.js'
import AddIcon from "@mui/icons-material/Add";
import { withAS } from "../../../_contexts/withAppState";
import { DEVELOPMENT_SERVER_ID } from "../../../../constants/env";

const makeMapStateToProps = statePropMapper([
    RS1.getOrderedServerList('orderedServerList'),
    RS1.getServer('serverData'),
    RS1.store('_servers', '_servers'),
    RS1.getUserMeta('userMeta'),
    RS1.store('chatServers', 'chatServers'),
    RS1.store('_loaded', '_loaded'),
    RS1.store('_userId', '_userId'),
])
const mapDispatchToProps = dispatchMappers([
    RA1._appState.updateAppState,
    SA1.updateServerOrder,
])

const ServerCard = (props) => {
    return <GenericCard
        server={props?.server}
        userId={props?.ownerId}
        setMenu={props?.setMenu}
        activeServer={props?.activeServer}
        isDM={props?.isDM}
        serverMeta={props}
        cardType={'community'}
        displayMode={'halfDesktop'}
        cardTestMode={false}
    />
}

const DnDServerList = withMenu(withMaterialDnD(ServerCard), ServerNavMenu)

const CreateCommunityButton = (props) => {
    return (<ListItemCustom
        types={['channelActive']}
        button
        activeid={"123"}
        listitemid={"456"}
        onClick={() => props.AS.modals.ops.openModal("server")}
    >
        <ListItemTextCustom
            id={"ANC123"}
            primary={"Create Community"}
        />
        <ListItemIconCustom
            types={['channel']}>
            <AddIcon />
            {/*{props.channelMeta.channelMeta.premium ?*/}
            {/*    <WorkspacePremiumIcon size={"small"}*/}
            {/*                          style={{*/}
            {/*                              color:*/}
            {/*                                  (props.channel === props.AS.activeChannel) ?*/}
            {/*                                      theme.palette.text.primary :*/}
            {/*                                      theme.palette.text.secondary*/}
            {/*                          }} /> :*/}
            {/*    null}*/}
            {/*<LayersIcon*/}
            {/*    onMouseEnter={() => setThreadIconHovered(true)}*/}
            {/*    onMouseLeave={() => setThreadIconHovered(false)}*/}
            {/*    size={"small"} />*/}
        </ListItemIconCustom>
    </ListItemCustom>)
}

const buildServerProps = (id, chatServers, activeServer, setMenu) => ({
    key: id,
    id: id,
    server: id,
    user: chatServers[id].ownerId,
    ownerId: chatServers[id].ownerId,
    name: chatServers[id].name,
    users: chatServers[id].users,
    isDM: false,
    activeServer: activeServer,
    setMenu: setMenu,
})

function ServersNav(props) {
    const MENU_ID = 'server-menu'
    const [initialized, setInitialized] = useState(false)
    const [serverLinkProps, setServerLinkProps] = useState([])
    const [servers, setServers] = useState(props._servers)
    const userServer = props.chatServers[props._userId]
    useEffect(() => {
        if (!initialized) {
            if (props.auth.auth === true && props.orderedServerList) {
                buildServerList(props.orderedServerList)
                setInitialized(true)
            }
        }
    }, [initialized, props.orderedServerList])

    useEffect(() => {
        if (props.auth.auth === true && props.orderedServerList) {
            buildServerList(props.orderedServerList)
        }
    }, [props.orderedServerList])

    function buildServerList(allServers) {
        let serverList = allServers.map((id) => {
            if (props.chatServers[id]?.dm === false && id !== userServer.primaryServer && id !== DEVELOPMENT_SERVER_ID) {
                return buildServerProps(id, props.chatServers, props.server, props.setMenu)
            }
        })
        const serverListFiltered = serverList.filter(function (x) {
            return x !== undefined
        })
        setServerLinkProps(serverListFiltered)
    }

    function handleOnDragEnd(resultData) {
        if (!resultData.destination) return
        let localServers = [...servers]
        let source = localServers[resultData.source.index]
        let target = localServers[resultData.destination.index]
        if (resultData.source.index !== resultData.destination.index) {
            props.dispatch.server.updateServerOrder(source, target)
            let shift =
                resultData.source.index < resultData.destination.index ? 1 : 0
            localServers.splice(resultData.destination.index + shift, 0, source)
            localServers = localServers.filter((serverId, index) => {
                return !(
                    index !== resultData.destination.index + shift &&
                    serverId === source
                )
            })
            setServers(localServers)
        }
    }

    if (initialized) {
        //TODO: style DnDServerList in globalTheme
        return [

            <StyledDiv
                types={['sidebarTopInternalHeader', 'rightSidebarHeader']}
                className={'sidebarTopInternalHeader'}>
                <TypographyCustom types={['generalHeader', 'rightSidebarHeader']} variant="h5">
                    Communities
                </TypographyCustom>
            </StyledDiv>

            ,

            <StyledDiv
                types={['sidebarTopInternalList']}
                className={'sidebarTopInternalList'}
            >
                <StyledDiv types={['sidebarTopInternalScroller']}>
                    {userServer?.primaryServer ?
                        <StyledDiv types={['bottomSmallerBorder']}>
                            <ServerCard
                                bottomSmallerBorder={true}
                                {...buildServerProps(
                                    userServer?.primaryServer,
                                    props.chatServers,
                                    props.server,
                                    props.setMenu)} />
                        </StyledDiv>
                        : null}
                    <StyledDiv types={['bottomSmallerBorder']}>
                        <ServerCard
                            bottomSmallerBorder={true}
                            {...buildServerProps(
                                DEVELOPMENT_SERVER_ID,
                                props.chatServers,
                                props.server,
                                props.setMenu)} />
                    </StyledDiv>
                    <DnDServerList
                        list={serverLinkProps}
                        dynamicProps={{ selectItem: () => true }}
                        droppableId={'serverList'}
                        menuId={MENU_ID}
                        useDnD={true}
                        dragCondition={(listProps, index) => false}
                        handleOnDragEnd={(result) => handleOnDragEnd(result)}
                        bottomComponent={<CreateCommunityButton AS={props.AS} />}
                    />
                    {/* TODO: Find a better fix. */}
                    {serverLinkProps.length <= 0 ? <CreateCommunityButton AS={props.AS} /> : null}
                    <div style={{ height: '1em' }}></div>
                </StyledDiv>
            </StyledDiv>
        ]
    } else {
        return null
    }
}

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(withAuth(withAS(ServersNav)))
