import React from "react"

import { Style } from "@mui/icons-material"

import {
    StyledDiv,
    ButtonCustom,
    TypographyCustom,
    IconButtonCustom,
    BadgeCustom,
    AvatarCustom,
    ListItemButtonCustom
} from "../../../../../Chat/Styled"
import {
    AvatarContainer,
    MessageHeader,
    TextContainer,
    ReplyHeader,
    ImageContainer,
    TimelineButtons
} from "../../util"
import { getUserColors } from "../../../../../../hooks";

const TimelineMessageFull = (props) => {
    let color = getUserColors(props?.user?.color)
    if (props.message.deleted) {
        return <div
            style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            Deleted Message
        </div>
    }
    return (
        <StyledDiv
            types={[
                'recolorItem',
                (props.fullMsg) ? '' : 'generalCard',
                'fullCard',
                'messageCard',
                (props.fullMsg) ? 'featurefulCard' : 'tighterCurves',
                'timelinePadding']}
            style={(props.fullMsg) ?
                (props.isReply) ? {
                    '--hundred-px-two-em': 'calc(20px + 2em)',
                    width: 'calc(100% - 20px)',
                } : {
                    width: 'calc(100% - 20px)'
                } : {}}
            isReply={props.isReply}
            fullMsg={props.fullMsg}
            desktop={props?.desktop}>

            {(props.fullMsg &&
                props?.message?.reply?.isReply) ? (
                <ReplyHeader
                    userName={props.message.reply.user}
                    message={props.message.reply.message}
                    edited={props.message.reply.edited}
                    deleted={props.message.reply.deleted}
                    messageObj={props?.message}
                // replyClicked={props?.replyClicked}
                />
            ) : null}

            <StyledDiv types={['flexboxOuterDiv', 'timelineOuter']}>
                <StyledDiv types={[
                    'timelineInner',
                    (!props.isReply && props.fullMsg) ?
                        'timelineOP' :
                        null]}>
                    <StyledDiv types={['flexboxInnerAvi']}
                        onClick={() => {
                            props.push("/p/?s=" + props.message.uId.split("_")[0])
                        }}>

                        <AvatarContainer
                            message={props.message}
                            user={props.user}
                            push={props.push}
                            desktop={props?.desktop} />

                    </StyledDiv>
                    <StyledDiv types={['flexboxInnerColumns', 'timelineColumns']} fullMsg={props?.fullMsg}>
                        <StyledDiv types={['flexboxColumnHeader']}>

                            <MessageHeader
                                user={props.user}
                                message={props.message}
                                fullMessageDate={props.fullMessageDate}
                                desktop={props.desktop} />

                        </StyledDiv>
                        <StyledDiv types={[
                            'flexboxColumnText',
                            'timelineMessageBody',
                            'fixTimelineMsgBody']}
                            style={props.innerColumnsStyle}>
                            <TextContainer
                                key={props.message.mId + 'E'}
                                mId={props.message.mId + 'EA'}
                                useProductCard={
                                    props.message?.metaData?.productId &&
                                    props?.message?.message === 'hidden'
                                }

                                fullMsg={props.fullMsg}
                                messageStyling={props.messageStyling}
                                edited={props.message.edited}
                                product={{ ...props.product }}
                                desktop={props.desktop}
                                half={false} />
                        </StyledDiv>

                        {props.fullMsg ?
                            <StyledDiv types={['flexboxColumnImg']}>
                                <ImageContainer
                                    files={props.message.files}
                                    urls={props.urls}
                                    message={props.message}
                                    half={false} />
                            </StyledDiv>
                            : null
                        }
                        {props.fullMsg ?
                            <TimelineButtons
                                isReply={props.isReply}
                                likedByUser={props?.likedByUser}
                                fullMsg={props.fullMsg}
                                tId={props.tId} mId={props.message.mId}
                                timelineOps={props.timelineOps}
                                replies={props.replies}
                                reactions={props.reactions}
                                highlights={props.highlights}
                                quotes={props.quotes}
                                desktop={props.desktop}
                                replyToMessage={props.replyToMessage} />
                            : null
                        }
                    </StyledDiv>
                </StyledDiv>

                {!props.fullMsg ?
                    <TimelineButtons
                        isReply={props.isReply}
                        likedByUser={props?.likedByUser}
                        fullMsg={props.fullMsg}
                        tId={props.tId} mId={props.message.mId}
                        timelineOps={props.timelineOps}
                        replies={props.replies}
                        reactions={props.reactions}
                        highlights={props.highlights}
                        quotes={props.quotes}
                        desktop={props.desktop}
                        replyToMessage={props.replyToMessage} />
                    : null
                }

            </StyledDiv>
        </StyledDiv>
    )
}


const TimelineMessageCardDisplay = function (props) {

    // Every card display must have n different modes.
    // Which mode is rendered is determined by a switch.
    // However, these modes must be the same across all cards.
    // Suggested modes: full-desktop, half-desktop, compressed-desktop, full, compressed

    // #
    switch (props.displayMode) {
        case 'fullTotalDesktop':
            return (
                <TimelineMessageFull
                    desktop={true}
                    fullMsg={true}
                    {...props} />
            )
        case 'fullDesktop':
            return (
                <TimelineMessageFull
                    desktop={true}
                    fullMsg={false}
                    {...props} />
            )
        case 'halfDesktop':
            return (
                <StyledDiv className="recolor-item"
                    types={['generalCard',
                        'messageCard',
                        'halfCard']}>
                    <StyledDiv types={['flexboxOuterDiv', 'timelineOuter', 'halfCardWidth']}>
                        <StyledDiv types={['timelineInner', 'halfCardWidth']}>
                            <StyledDiv types={['cardImage', 'halfImage']}>
                                <ImageContainer
                                    files={props.message.files}
                                    urls={props.urls}
                                    message={props.message}
                                    half={true} />
                            </StyledDiv>
                            <StyledDiv types={['flexboxInnerColumns', 'timelineColumns', 'halfCardWidth', 'halfText']}>
                                <StyledDiv types={['flexboxColumnHeader']}>
                                    <MessageHeader
                                        user={props.user}
                                        message={props.message}
                                        fullMessageDate={props.fullMessageDate}
                                        desktop={true}
                                        half={true} />
                                </StyledDiv>
                                <StyledDiv types={[
                                    'flexboxColumnText',
                                    'timelineMessageBody',
                                    'fixTimelineMsgBody']}
                                    style={props.innerColumnsStyle}>
                                    <TextContainer
                                        key={props.message.mId + 'E'}
                                        mId={props.message.mId + 'EA'}
                                        useProductCard={
                                            props.message?.metaData?.productId &&
                                            props.message.message === 'hidden'}
                                        fullMsg={false}
                                        messageStyling={props.messageStyling}
                                        edited={props.message.edited}
                                        product={{ ...props.product }}
                                        desktop={true}
                                        half={true} />
                                </StyledDiv>
                                <TimelineButtons
                                    timelineOps={props.timelineOps}
                                    replies={props.replies}
                                    tId={props.tId} mId={props.message.mId}
                                    reactions={props.reactions}
                                    highlights={props.highlights}
                                    quotes={props.quotes}
                                    desktop={true}
                                    half={true} />
                            </StyledDiv>
                        </StyledDiv>

                    </StyledDiv>
                </StyledDiv>
            )
        case 'compressedDesktop':
            return (
                <StyledDiv className="recolor-item"
                    types={['generalCard',
                        'compressedDesktop',
                        'compressedTimeline']}>
                    <StyledDiv types={['flexboxOuterDiv', 'timelineOuter', 'halfCardWidth']}>
                        <StyledDiv types={['timelineInner', 'halfCardWidth']}>
                            <StyledDiv types={['flexboxInnerColumns', 'timelineColumns', 'halfCardWidth', 'halfText']}>
                                <StyledDiv types={['flexboxColumnHeader']}>
                                    <MessageHeader
                                        user={props.user}
                                        message={props.message}
                                        fullMessageDate={props.fullMessageDate}
                                        desktop={false}
                                        half={false}
                                        compressed={true} />
                                </StyledDiv>
                                <StyledDiv types={[
                                    'flexboxColumnText',
                                    'timelineMessageBody',
                                    'fixTimelineMsgBody']}
                                    style={props.innerColumnsStyle}>
                                    <TextContainer
                                        key={props.message.mId + 'E'}
                                        mId={props.message.mId + 'EA'}
                                        useProductCard={
                                            props.message?.metaData?.productId &&
                                            props.message.message === 'hidden'}
                                        fullMsg={false}
                                        messageStyling={props.messageStyling}
                                        edited={props.message.edited}
                                        product={{ ...props.product }}
                                        desktop={false}
                                        half={true} />
                                </StyledDiv>
                                <TimelineButtons
                                    tId={props.tId} mId={props.message.mId}
                                    timelineOps={props.timelineOps}
                                    replies={props.replies}
                                    reactions={props.reactions}
                                    highlights={props.highlights}
                                    quotes={props.quotes}
                                    desktop={false}
                                    half={false}
                                    compressed={true} />
                            </StyledDiv>
                        </StyledDiv>

                    </StyledDiv>
                </StyledDiv>
            )
        case 'fullTotal': // mobile
            return (
                <TimelineMessageFull
                    desktop={false}
                    fullMsg={true}
                    {...props} />
            )
        case 'full':
            return (
                <TimelineMessageFull
                    desktop={false}
                    fullMsg={false}
                    {...props} />
            )
        default:
            return (
                <StyledDiv className="recolor-item"
                    types={['generalCard',
                        'compressedCard',
                        'compressedTimeline']}>
                    <StyledDiv types={['flexboxOuterDiv', 'timelineOuter', 'halfCardWidth']}>
                        <StyledDiv types={['timelineInner', 'halfCardWidth']}>
                            <StyledDiv types={['flexboxInnerColumns', 'timelineColumns', 'halfCardWidth', 'halfText']}>
                                <StyledDiv types={['flexboxColumnHeader']}>
                                    <MessageHeader
                                        user={props.user}
                                        message={props.message}
                                        fullMessageDate={props.fullMessageDate}
                                        desktop={false}
                                        half={false}
                                        compressed={true} />
                                </StyledDiv>
                                <StyledDiv types={[
                                    'flexboxColumnText',
                                    'timelineMessageBody',
                                    'fixTimelineMsgBody']}
                                    style={props.innerColumnsStyle}>
                                    <TextContainer
                                        key={props.message.mId + 'E'}
                                        mId={props.message.mId + 'EA'}
                                        useProductCard={
                                            props.message?.metaData?.productId &&
                                            props.message.message === 'hidden'}
                                        fullMsg={false}
                                        messageStyling={props.messageStyling}
                                        edited={props.message.edited}
                                        product={{ ...props.product }}
                                        desktop={false}
                                        half={true} />
                                </StyledDiv>
                                <TimelineButtons
                                    replies={props.replies}
                                    reactions={props.reactions}
                                    highlights={props.highlights}
                                    timelineOps={props.timelineOps}
                                    quotes={props.quotes}
                                    tId={props.tId} mId={props.message.mId}
                                    desktop={false}
                                    half={false}
                                    compressed={true} />
                            </StyledDiv>
                        </StyledDiv>

                    </StyledDiv>
                </StyledDiv>
            )
    }
}

export const timelineMessageCardPackage = {
    requirements: ['displayMode',
        'message',
        'fullMessageDate',
        'messageStyling',
        'user',
        'product',
        'innerColumnsStyle',
        'urls',
        'replies',
        'reactions',
        'highlights',
        'quotes',
        'push'
    ],
    CardDisplay: TimelineMessageCardDisplay
}