import React, { useEffect, useState } from 'react'
import { StyledDiv, AvatarCustom, TypographyCustom, vDH } from '../../../Styled'
import { DEVELOPMENT_SERVER_ID } from "../../../../../constants/env";
import { Avatar } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Edit, MeetingRoom, Email, PersonAdd, Person, Twitter, Facebook, Google, Reddit } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import { ButtonCustom } from '../../../Styled';
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from '@mui/material';
import BackgroundFlair from '../../../ChatWindow/BackgroundFlair';
import { withAS } from "../../../../_contexts/withAppState";

const AvatarContainer = function (props) {
    let imgUrl = `https://picsum.photos/seed/${props.server}/800/800?blur=3`
    if (props.isDM) {
        if (props.userData.pictureId !== "") {
            imgUrl = props.userData.pictureId
        }
    } else if (props.serverData?.avatarId !== 'none') {
        imgUrl = props.serverData?.avatarId
    }

    return (
        // <div style={{height:"200px"}}>
        <StyledDiv types={['singleLineDiv', 'profileAviBorder']}
            style={{
                flexDirection: 'column',
                justifyContent: 'center',
                minHeight: props?.mobileMode ? vDH(1) : vDH(20),
                width: props?.mobileMode ? '50%' : '70%',
            }}>

            {/* <Avatar
                sx={{width: '14vw', height: '14vw'}}
                variant={'square'}
                src={`https://picsum.photos/seed/${props.server}/800/800?blur=3`} /> */}

            <AvatarCustom
                sx={{ flex: '0 0 auto', width: '100%', height: 'auto' }}
                types={['profileAvatar', 'curvedBorder']}
                variant={'square'}
                alt={props?.user?.userName ? props?.user?.userName : 'error'} // TODO: FIX
                src={imgUrl}
                style={{
                    borderRadius: props?.mobileMode
                        ? '21px 0px 21px 0px'
                        : '36px 0px 36px 0px',
                }}
            />

        </StyledDiv>
        // </div>
    )
}

const TimelineProfile = function (props) {
    const theme = useTheme()
    // TODO:
    // [ , ] display banner
    // [x, ] display profile picture
    // [x, ] display name
    // [x, ] display bio
    // [x, ] display communities user is in?
    // [x, ] display website?
    // [x, ] display stats (num. posts, replies, images, etc)
    // [ , ] have banner scroll up but profile stay static
    return (
        <StyledDiv
            types={['sidebarTopInternalHeader']}
            style={{
                position: 'relative', marginBottom: 20, zIndex: 1003,
                paddingTop: '20px',
                // borderBottom: '1px solid #444'
            }}
        >
            <div>
                <StyledDiv
                    types={['profileFlair']}
                    bannerId={props.serverData.bannerId}
                    desktop={props?.desktop}>

                    {props.serverData.bannerId ?
                        <div></div> :
                        <StyledDiv types={['backgroundFlairDefault']}></StyledDiv>
                    }
                </StyledDiv>
            </div>
            <StyledDiv types={['flexboxOuterDiv', 'sidebarTimelineProfile']}
                style={{ zIndex: '1003' }}>

                <StyledDiv types={['flexboxInnerAvi', 'profileAvi']}>
                    <AvatarContainer
                        user={props.server === DEVELOPMENT_SERVER_ID ? null : props?.otherUser}
                        mobileMode={props.AS.mobileMode}
                        userData={props.userData}
                        isDM={props.isDM}
                        serverData={props.serverData}
                        server={props.server} />
                </StyledDiv>

                <StyledDiv types={['flexboxInnerColumns', 'profileColumns']}>
                    <StyledDiv types={['flexboxColumnHeader', 'profileHeader']}>
                        <TypographyCustom types={['communityHeader']} variant="h4"
                            style={{
                                maxWidth: (props?.desktop) ? 'calc(500px - 2em)' : 'calc(100vw - 2em)',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                transform: props.AS.mobileMode ? 'scale(0.75)' : 'scale(1)',
                            }}>
                            {props.isDM ? props?.userData?.userName : props.serverName}
                        </TypographyCustom>
                    </StyledDiv>
                    {/*<StyledDiv types={['flexboxColumnText', 'profileSite']}>*/}
                    {/*    <div>(TBD: Bio (Char Length?))</div>*/}
                    {/*    /!* <TextContainer key={message.mId + "E"} mId={message.mId + "EA"} useProductCard={(message?.metaData?.productId && message.message === "hidden")}*/}
                    {/*        messageStyling={messageStyling} edited={props.message.edited} product={{ ...product }} /> *!/*/}
                    {/*</StyledDiv>*/}
                    {/*<StyledDiv types={['flexboxColumnImg']}>*/}
                    {/*    <div>(TBD: Community Flair)</div>*/}
                    {/*    /!* <ImageContainer files={message.files} urls={props.urls} message={message} /> *!/*/}
                    {/*</StyledDiv>*/}

                </StyledDiv>

                <div style={{
                    width: '35%',
                    display: 'flex',
                    justifyContent: 'space-around',
                    color: theme.palette.text.secondary,
                    paddingTop: '5px',
                    paddingBottom: '20px',
                }}>
                    <Google />
                    <Reddit />
                    <Facebook />
                    <Twitter />
                </div>

                {props.isOwner || (props.server === DEVELOPMENT_SERVER_ID) ? null :
                    <StyledDiv style={{
                        flex: '1 0 auto',
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '5px'
                    }}>

                        <ButtonCustom
                            types={['timelineButton']}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                            }}
                            size="large">
                            {(true) ? 'Follow' : 'Unfollow'}
                        </ButtonCustom>

                        {(!props.isGuest) ? (!props.isMember) && (!props.isDM) ?
                            <ButtonCustom
                                types={['timelineButton']}
                                sx={{ padding: 0 }}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    props.AS.nav.ops.joinServer(props.server)
                                }}
                                size="large"
                                style={{ flex: '0 0 fit-content' }}>
                                Join
                            </ButtonCustom> :
                            <ButtonCustom
                                types={['timelineButton']}
                                sx={{ padding: 0 }}
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                }}
                                size="large"
                                style={{ flex: '0 0 fit-content' }}>
                                Leave
                            </ButtonCustom> : <ButtonCustom
                                types={['timelineButton']}
                                sx={{ padding: 0 }}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    //props.setRedirect("/chat2/?join=" + props.server)
                                }}
                                size="large"
                                style={{ flex: '0 0 fit-content' }}>
                            Join
                        </ButtonCustom>}
                    </StyledDiv>}


                {/* <StyledDiv types={['flexboxColumnText', 'profileStats']}> */}
                {/*{!props.isDM ? <StyledDiv>*/}
                {/*    <TableContainer>*/}
                {/*        <Table sx={{ width: "64%" }} aria-label="profile stats">*/}
                {/*            <TableHead>*/}
                {/*                <TableRow>*/}
                {/*                    <TableCell align="center">*/}
                {/*                        <TypographyCustom variant="body2">*/}
                {/*                            Followers*/}
                {/*                        </TypographyCustom>*/}
                {/*                    </TableCell>*/}
                {/*                    <TableCell align="center">*/}
                {/*                        <TypographyCustom variant="body2">*/}
                {/*                            Members*/}
                {/*                        </TypographyCustom>*/}
                {/*                    </TableCell>*/}
                {/*                    <TableCell align="center">*/}
                {/*                        <TypographyCustom variant="body2">*/}
                {/*                            Subs*/}
                {/*                        </TypographyCustom>*/}
                {/*                    </TableCell>*/}
                {/*                </TableRow>*/}
                {/*            </TableHead>*/}
                {/*            <TableBody>*/}
                {/*                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>*/}
                {/*                    <TableCell align="center">0</TableCell>*/}
                {/*                    <TableCell align="center">{props.numOfUsers}</TableCell>*/}
                {/*                    <TableCell align="center">0</TableCell>*/}
                {/*                </TableRow>*/}
                {/*            </TableBody>*/}
                {/*        </Table>*/}
                {/*    </TableContainer>*/}
                {/*</StyledDiv> : null}*/}
                {/* <TextContainer key={message.mId + "E"} mId={message.mId + "EA"} useProductCard={(message?.metaData?.productId && message.message === "hidden")}
                            messageStyling={messageStyling} edited={props.message.edited} product={{ ...product }} /> */}
                {/* </StyledDiv> */}
                {/*{props.isGuest || !props.isMember? <Button><AddIcon/>Join Community</Button> :null}*/}
                {/*<StyledDiv types={['flexboxColumnText', 'profileBio']}>*/}
                {/*    Guntuber, 2A Activist, YT here: <Link href="https://www.youtube.com/theakguy1974">youtube.com/theakguy1974</Link>*/}
                {/*    /!* <TextContainer key={message.mId + "E"} mId={message.mId + "EA"} useProductCard={(message?.metaData?.productId && message.message === "hidden")}*/}
                {/*            messageStyling={messageStyling} edited={props.message.edited} product={{ ...product }} /> *!/*/}
                {/*</StyledDiv>*/}

                <div>
                    <div style={{
                        width: '100%',
                        padding: props.AS.mobileMode
                            ? '20px 60px 10px'
                            : "20px 40px 10px 40px",
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'space-between',
                    }}>

                        {props.server === DEVELOPMENT_SERVER_ID ?
                            <TypographyCustom variant="body1" style={{
                                wordWrap: "break-word",
                                textAlign: 'left',
                                fontSize: props.AS.mobileMode ? '12px' : '16px'
                            }}>Welcome
                                to the PAA development server!</TypographyCustom>
                            : <TypographyCustom variant="body1" style={{
                                wordWrap: "break-word",
                                maxWidth: '100%',
                                overflow: 'hidden',
                                textAlign: 'left',
                                fontSize: props.AS.mobileMode ? '12px' : '16px'
                            }}>
                                {props.profileString}
                            </TypographyCustom>}
                        {props.isOwner ?
                            <div style={{
                                marginTop: '1em',
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                            }}>
                                <IconButton color="primary" size="small" sx={{ padding: '1px', width: 'fit-content', }}
                                    onClick={() => {
                                        props.AS.modals.ops.openModalWithData('modifyProfile', { serverId: props.server })
                                    }}>
                                    <Edit style={{ transform: 'scale(0.8)' }} />
                                </IconButton>
                            </div> : null}
                        {/*{props.isOwner|| !props.isMember? <Button><EditIcon/></Button> :null}*/}
                    </div>

                </div>

            </StyledDiv>
        </StyledDiv>
    )
}

export default withAS(TimelineProfile)
