import React, { useState, useEffect } from 'react'
import { ButtonCustom, TypographyCustom } from '../../Styled'
import DefaultError from '../ErrorPage'
import { CreatorButtons, CreatorItem } from './CreatorItem'
import { CustomerButtons, CustomerItem } from './CustomerItem'

const TopRightButtons = (props) => {
    switch (props.listType) {
        case 'creator':
            return (
                <CreatorButtons
                    types={props.types}
                    addPaymentCreds={props.addPaymentCreds}
                    addProduct={props.addProduct}
                />
            )

        case 'customer':
            return <CustomerButtons subscriptions={props.subscriptions} />

        default:
            return (
                <DefaultError message="Sorry, an unexpected error occurred." />
            )
    }
}

const ListOfProducts = (props) => {
    //TODO: handle this more elegantly.
    if (!props.catalog) {
        return <div>Loading...</div>
    }

    switch (props.listType) {
        case 'creator':
            return props.catalog.map((product) => {
                return (
                    <CreatorItem
                        product={product}
                        types={props.types}
                        deleteProduct={() => props.deleteProduct(product)}
                        onClick={() => props.onClick(product)}
                    />
                )
            })
        case 'customer':
            return props.catalog.map((product) => {
                return (
                    <CustomerItem
                        product={product}
                        types={props.types}
                        reportProduct={props.reportProduct}
                        onClick={() => props.onClick(product)}
                    />
                )
            })
        default:
            return (
                <DefaultError message="Sorry, an unexpected error occurred." />
            )
    }
}

const ProductList = function (props) {
    // #
    return (
        <div style={{ margin: '5%' }}>
            <div style={{ float: 'right', display: 'fixed' }}>
                <TopRightButtons
                    listType={props.listType}
                    types={props.types}
                    subscriptions={props.subscriptions}
                    addPaymentCreds={props?.addPaymentCreds}
                    addProduct={props?.addProduct}
                />
            </div>
            <br />

            <TypographyCustom types={[]} variant={'h6'}>
                TODO: Name of Server goes Here
            </TypographyCustom>
            <br />

            <div style={{ marginLeft: '0.6cm' }}>
                <ListOfProducts
                    listType={props.listType}
                    catalog={props.catalog}
                    types={props.types}
                    deleteProduct={props.deleteProduct}
                    reportProduct={props.reportProduct}
                    onClick={props.onClick}
                />
            </div>
        </div>
    )
}
export default ProductList
