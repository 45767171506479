import React from 'react'
import { ButtonCustom } from '../../../../../Chat/Styled'
import { StyledDiv } from '../../../../../Chat/Styled'
import { dispatchMappers } from '../../../../../../redux/process/actionProcess'
import { RS1, RA1 } from '../../../../../../redux/actions'
import { SA1 } from '../../../../../../hs/requestGlobals.mjs'
import { connect } from 'react-redux'
import { statePropMapper } from '../../../../../../redux/process/selectorProcess'
import { embedProps, useTestData } from '../../util'
import { testData } from './test'
import { withAS } from '../../../../../_contexts/withAppState'

const computedTestData = testData()

const makeMapStateToProps = statePropMapper([
    RS1.store('_activeChannel', '_activeChannel'),
    // RS1.getCalendar('calendar'),
    RS1.getServerProducts('serverProducts'),
])

const mapDispatchToProps = dispatchMappers([
    // RA1._appState.openModalMenu,
    RA1.calendar.setSelectedAppointment,
    // SA1.getAppointments,
])

// new client state -> products


const AppointmentDataLayer = (props) => {
    const handleApptSelection = (appointment) => {
        props.setSelectedAppointment(appointment)
        if (!props.adminViewMode) {
            if (appointment.status === "default") {
                props.AS.modals.ops.openModal('bookAppointment')
            }
            if (appointment.status === "approved") {
                props.AS.modals.ops.openModal('backoutOfAppointment')
            }
        } else {
            if (appointment.status === 'pending') {
                props.AS.modals.ops.openModal('approveAppointment')
            } else {
                props.AS.modals.ops.openModal('cancelAppointment')
            }
        }
    }

    let data = {
        title: '', appointments: [],
        adminViewMode: props?.adminViewMode,

        openModalMenu: props?.openModalMenu,

        setHoverAppointment: props?.setHoverAppointment,
        setSelectedAppointment: props?.setSelectedAppointment,
        handleApptSelection: handleApptSelection,
    }

    if (props?.calendar && props?.serverProducts) {
        if (props?.sortByTitle) {
            if (props?.productId) {

                data.appointments = props.calendar.appointmentsByTitle.products[props.productId]
                let product = props.serverProducts.find(product => product.id === props.productId)
                data.title = product.name

            } else if (props?.gptTag) {
                data.appointments = props.calendar.appointmentsByTitle.gpttags[props.gptTag]
                data.title = data.appointments[0].description
            } else {
                data.appointments = props.calendar.appointmentsByTitle.gpttags.none
                data.title = "Other"
            }
        }
        else {
            data.appointments = []
            props.dates.forEach(date => data.appointments.push({
                name: date.appointment.description,
                status: date.status,
                ...date.appointment
            }))
            data.title = props.day.split('(')[0]
            // data = {
            //     title: props.day, // need to get title from date
            //     appointments: props.calendar.appointmentsByDay[props.uniqueDayLabel]
            // }
        }
    }

    data = useTestData(props, data, computedTestData)

    return embedProps(props, data)
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(AppointmentDataLayer))
