import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {RA1, RS1} from '../../../../redux/actions.js'
import {SA1} from '../../../../hs/requestGlobals.mjs'
import {dispatchMappers} from '../../../../redux/process/actionProcess.js'
import SocketForm from '../../../_reusable/SocketForm/SocketForm'
import {statePropMapper} from '../../../../redux/process/selectorProcess'
import useState from "react-usestateref";

const makeMapStateToProps = statePropMapper([
    RS1.getServerAbstractRoles('serverAbstractRoles'),
    RS1.getCalendar('calendar'),
    RS1.store("_appState", "_appState")
])
const mapDispatchToProps = dispatchMappers([
    RA1.api.updateAPI,
    RA1._appState.updateAppState,
    SA1.bookAppointment,
])

const BookAppointmentModal = function (props) {
    const [isProduct, setIsProduct] = useState(false)
    useEffect(() => {
        if (props.calendar.selected.isProduct) {
            setIsProduct(true)
        }
    }, [])
    const productData = props.calendar.selected
    return (
        <SocketForm
            initialScreen={false}
            useConfirmationScreen={true}
            useMultipleFormPages={true}
            confirmationFunction={(obj) => {
            }}
            formMethod={'bookAppointment'}
            formDispatch={(obj) => {
                if (isProduct) {
                    let selectedProduct = null
                    const productId = props.calendar.selected.productId
                    props._appState.catalog.map((product) => {
                        if (product.id === productId) {
                            selectedProduct = product
                        }
                    })
                    if (selectedProduct !== null) {
                        props.AS.modals.ops.openModalWithData('paymentModal', {
                            additionalProductMetadata: productData,
                            selectedProduct: selectedProduct,
                            isCreator: false,
                        })
                    }
                } else {
                    obj.calendarId = props?.calendar?.selected?._id
                    obj.serverId = props.server
                    props.dispatch.server.bookAppointment(
                        obj.serverId,
                        obj.calendarId,
                        obj.bookingNotes
                    )
                }

            }}
            socketForm
            onClose={props.closeModalMenu}
            onSuccess={() => null}
            description={'Would you like to book this appointment?'}
            formFields={[
                {
                    id: 'bookingNotes',
                    typographyLabel: 'Leave a note:',
                    type: 'multiline',
                    page: 1,
                },
            ]}
            formButtons={[
                {
                    label: 'Book',
                    icon: 'add',
                    isSubmitButton: true,
                    isCloseButton: false,
                },
                {
                    label: 'Cancel',
                    icon: 'add',
                    isSubmitButton: false,
                    isCloseButton: true,
                },
            ]}
            formTypography={{label: 'Book appointment'}}
            successMessage={'Appointment booked.'}
        />
    )
}

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(BookAppointmentModal)
