import React, {useEffect, useState} from 'react'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AppsIcon from '@mui/icons-material/Apps'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import BuildIcon from '@mui/icons-material/Build'
import EventIcon from '@mui/icons-material/Event'
import {dispatchMappers} from '../../../redux/process/actionProcess.js'
import {RA1, RS1} from '../../../redux/actions.js'
import {connect} from 'react-redux'
import ServerNavFooter from './ServersNav/ServerNavFooter/index.js'
import DebugNav from './DebugNav'
import CommunityNav from './CommunityNav/index.js'
import ServersNav from './ServersNav/index.js'
import ManagementBar from './ManagementNav/index.js'
import CommunityNavFooter from './CommunityNav/CommunityFooter'
import ChannelConfigNav from './ChannelConfigNav'
import ChannelConfigFooter from './ChannelConfigNav/ChannelConfigFooter'
import {statePropMapper} from '../../../redux/process/selectorProcess'
import {AvatarCustom, StyledDiv, IconButtonCustom} from '../Styled'
import NotificationsNav from '../SidebarRight/NotificationsNav'
import UserManagementNav from './UserManagementNav'
import {withAuth} from '../../_contexts/withAuth'
import {withAS} from "../../_contexts/withAppState";
import {ProfileNav} from "./ProfileNav";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import ProfileNavFooter from "./ProfileNav/ProfileFooter";
import UniversalSidebarFooter from "../UniversalSidebarFooter";

const makeMapStateToProps = statePropMapper([
    RS1.store('_appState', '_appState'),
    RS1.store('_userId', '_userId'),
    RS1.getUserBooleanPerm('isAdmin', ['isAdmin']),
    RS1.getUserBooleanPerm('isOwner', ['isOwner']),
    RS1.store('allUsers', 'users'),
])
const mapDispatchToProps = dispatchMappers([RA1._appState.changePage])
const SidebarLeftHeader = function (props) {
    return (
        <div>
        </div>
    )
}

const SidebarLeft = function (props) {
    const [activeServer, setActiveServer] = useState(props.server)
    const [selectedChannel, setSelectedChannel] = useState(null)
    const [currentComponentVersion, setCurrentComponentVersion] = useState(
        props._appState['sidebarLeftVersion'] ?
            props._appState['sidebarLeftVersion'] : 0)
    const setMenu = props.AS.nav.ops.setMenuL
    const menu = props.AS.nav.state.menuL
    let DisplayedMenu = null
    let DisplayedFooter = null
    const changePage = props.AS.nav.ops.setPage
    if (props._appState?.['sidebarLeftVersion']) {
        if (props._appState['sidebarLeftVersion'] !== currentComponentVersion) {
            setMenu('community')
            setCurrentComponentVersion(props._appState['sidebarLeftVersion'])
        }
    }

    if (menu === 'channelConfig') {
        let {channel, ...otherProps} = props
        DisplayedMenu = (
            <ChannelConfigNav
                channel={selectedChannel}
                selectedChannel={selectedChannel}
                {...otherProps}
            />
        )
        DisplayedFooter = (
            <ChannelConfigFooter
                changePage={changePage}
                setMenu={setMenu}
            />
        )
    }
    if (menu === 'community') {
        DisplayedMenu = (
            <CommunityNav
                otherUserId={props.userId}
                setSelectedChannel={setSelectedChannel}
                setMenu={setMenu}
                changePage={changePage}
                adminOrAbove={props.isAdmin || props.isOwner}
                {...props}
            />
        )
        DisplayedFooter = (
            <CommunityNavFooter userId={props._userId} server={props.server} changePage={changePage} setMenu={setMenu}/>
        )
    } else if (menu === 'servers') {
        DisplayedMenu = <ServersNav setMenu={setMenu} {...props} />
        DisplayedFooter = (
            <ServerNavFooter
                changePage={changePage}
                setMenu={setMenu}
                adminOrAbove={props.isAdmin || props.isOwner}
            />
        )
    } else if (menu === 'profile') {
        DisplayedMenu = <ProfileNav desktop={props?.desktop} setMenu={setMenu} {...props.allLandingPageProps} />
        // DisplayedFooter = (
        //     <ProfileNavFooter
        //         changePage={changePage}
        //         setMenu={setMenu}
        //         adminOrAbove={props.isAdmin || props.isOwner}
        //     />
        // )
    } else if (menu === 'debug') {
        DisplayedMenu = <UserManagementNav {...props} />
        DisplayedFooter = (
            <SidebarLeftHeader
                changePage={changePage}
                setMenu={setMenu}
                adminOrAbove={props.isAdmin || props.isOwner}
            />
        )
    } else if (menu === 'manage') {
        DisplayedMenu = <ManagementBar {...props} />
        DisplayedFooter = (
            <SidebarLeftHeader
                changePage={changePage}
                setMenu={setMenu}
                adminOrAbove={props.isAdmin || props.isOwner}
            />
        )
    }
    return (
        <StyledDiv types={['sidebarWhole']} side={'left'} menu={menu}>
            {DisplayedMenu}
            {!props.AS.nav.state.isGuest ?
                <>
                    <StyledDiv
                        types={['sidebarFooter']}
                        displayedFooter={DisplayedFooter}
                        menu={menu}
                        desktop={props?.desktop}
                        style={{
                            // borderRadius: menu === 'profile' ? '0px 55px 0px 0px' : '0px 25px 0px 0px',
                            zIndex: 1003,
                        }}>
                        {DisplayedFooter}
                    </StyledDiv>
                    <StyledDiv types={['sidebarBottomMenu']} menu={menu} desktop={props?.desktop}>
                        <UniversalSidebarFooter
                            AS={props.AS}
                            setMenu={setMenu}
                            userData={props.allUsers[props._userId]}
                            renderButtons={["personalProfile", "servers", "community", "debug"]}
                            adminOrAbove={props.isAdmin || props.isOwner}
                        />
                    </StyledDiv>
                </> : null}
        </StyledDiv>
    )
}

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(withAuth(withAS(SidebarLeft)))
