import { useHistory } from 'react-router-dom'
import React from 'react'
import { withAuth } from '../../../_contexts/withAuth'
import { requestResponseGlobals, SA1 } from '../../../../hs/requestGlobals.mjs'
import withAPI from '../../../_wrappers/withAPI'
import { connect } from 'react-redux'
import { dispatchMappers } from '../../../../redux/process/actionProcess'
import { statePropMappers } from '../../../../redux/process/selectorProcess'
import { withDesignAndState } from '../../../_contexts/withDesignAndState'
import { ButtonCustom, TypographyCustom, vDH } from '../../Styled'
import { streams } from "../../Stream/stream-controller.js";
import { withAS } from "../../../_contexts/withAppState";

const makeMapStateToProps = statePropMappers(
    {},
    {
        _appState: '_appState',
        _activeServer: '_activeServer',
        _activeChannel: '_activeChannel',
        _loaded: '_loaded',
        _userId: '_userId',
        _servers: '_servers',
    }
)

let SAArray = []
for (const key in SA1) {
    if (SA1.hasOwnProperty(key)) {
        SAArray.push(SA1[key])
    }
}
const mapDispatchToProps = dispatchMappers(SAArray)

const prepareArguments = function (oldArgs, oldProps) {
    let newArgs = []
    oldArgs.map((arg) => {
        switch (arg) {
            case '%S':
                newArgs.push(oldProps._activeServer)
                break
            case '%C':
                newArgs.push(oldProps._activeChannel)
                break
            default:
                newArgs.push(arg)
        }
    })
    return newArgs
}

const RestAPILink = function (props) {
    return (
        <ButtonCustom
            types={['debug']}
            onClick={() => {
                if (props.defaultMode === 'api') {
                    props.oldProps.API(props.name)(...props.debugArguments)
                } else if (props.defaultMode === 'socket') {
                    //#TODO: Fix this
                    const dispatchArgs = prepareArguments(
                        props.debugArguments,
                        props.oldProps
                    )
                    props.oldProps.dispatch.server[props.name](...dispatchArgs)
                }
            }}
        >
            {props.name}
        </ButtonCustom>
    )
}
const makeList = (Component) => (props) => {
    const ComponentList = props.list.map((listProps) => (
        <Component {...{ ...listProps, ...props.dynamicProps }} />
    ))
    return <ul className={props.ulClassName}>{ComponentList}</ul>
}

const RestAPILinks = makeList(RestAPILink)

const DebugNav = function (props) {
    const history = useHistory()
    const apiDebugEnabledActions = []
    const socketDebugEnabledActions = []
    for (const key in requestResponseGlobals) {
        if (requestResponseGlobals.hasOwnProperty(key)) {
            if (requestResponseGlobals[key].generalDebug) {
                if (requestResponseGlobals[key].defaultMode === 'api') {
                    apiDebugEnabledActions.push({
                        name: key,
                        arguments: requestResponseGlobals[key].arguments,
                        defaultMode: requestResponseGlobals[key].defaultMode,
                        debugArguments:
                            requestResponseGlobals[key].generalDebugArgs,
                        oldProps: props,
                    })
                } else if (
                    requestResponseGlobals[key].defaultMode === 'socket'
                ) {
                    socketDebugEnabledActions.push({
                        name: key,
                        arguments: requestResponseGlobals[key].arguments,
                        defaultMode: requestResponseGlobals[key].defaultMode,
                        debugArguments:
                            requestResponseGlobals[key].generalDebugArgs,
                        oldProps: props,
                    })
                }
            }
        }
    }
    return (
        <div style={{ overflow: 'scroll', height: vDH(85) }}>
            <div style={{ height: vDH(5), textAlign: 'center' }}>
                <TypographyCustom variant="h6" types={['generalHeader']}>
                    Legacy/React
                </TypographyCustom>
            </div>
            {/*<ChatManagement/>*/}
            <a
                className="navbar-item"
                onClick={() =>
                    props.authFunctions.logout(() => {
                        props.AS.push('/home')
                    })
                }
            >
                Log out.
            </a>
            <a
                className="navbar-item"
                onClick={() => {
                    props.switchStyleMode()
                }}
            >
                Switch style
            </a>
            <div style={{ height: vDH(5), margin: 20, textAlign: 'center' }}>
                <TypographyCustom variant="h6" types={['generalHeader']}>
                    Debug-Enabled REST API
                </TypographyCustom>
            </div>
            <div>
                {/*<RestAPILinks list={apiDebugEnabledActions} />*/}
            </div>
            <div style={{ height: vDH(5), margin: 20, textAlign: 'center' }}>
                <TypographyCustom variant="h6" types={['generalHeader']}>
                    Debug-Enabled Socket API
                </TypographyCustom>
            </div>
            <div>
                <RestAPILinks list={socketDebugEnabledActions} />
            </div>
            <button onClick={() => {
                let sourceId = prompt("DEBUG: Please enter the source id of the user room");
                if (!(sourceId == null || sourceId == "")) {
                    streams.startCallWithoutInvite(sourceId, "userSession");
                }
            }
            }> Join Video Without Invite</button>
            <button onClick={() => {
                let invite = prompt("DEBUG: Please enter the full invite code");
                if (!(invite == null || invite == "")) {
                    let sourceId = invite.split(":")[0];
                    let finalInvite = invite.split(":")[1];
                    streams.startCallWithInvite(sourceId, finalInvite);
                }
            }}> Join Video With Invite</button>
        </div>
    )
}

export default withDesignAndState(
    withAuth(
        withAPI(
            connect(makeMapStateToProps, mapDispatchToProps)(withAS(DebugNav)),
            'debug'
        )
    )
)
