import React, { useState } from 'react'
import { Menu, Item, Separator, Submenu } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import { connect } from 'react-redux'
import { MenuCustom } from '../../../../Styled'

function ChannelLinkMenu(props) {
    if (1 + 1 === 2) {
        return (
            <MenuCustom id={props.menuId}>
                <Item
                    onClick={() => {
                        props.selectedItem.list.setSelectedChannel(
                            props.selectedItem.key
                        )
                        props.selectedItem.list.setMenu('channelConfig')
                    }}
                >
                    Configure Channel
                </Item>
            </MenuCustom>
        )
    } else {
        return null
    }
}

export default ChannelLinkMenu
