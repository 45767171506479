import { styled } from '@mui/material/styles'
import { Masonry } from 'react-virtualized'
import { scrollbarConfig } from '../../../../theme/globals'

export const StyledMasonry = styled(Masonry)(({ theme }) =>
({
    padding: '10px',
    paddingBottom: '6em',
    ...scrollbarConfig(theme.palette)
})
)