import React from 'react'
import { ButtonCustom, TypographyCustom, vDH } from '../../../Styled'

const SuccessPage = function (props) {
    return (
        // TODO: dont use inline styles
        <div
            style={{
                fontSize: '1.7em',
                height: vDH(80),
                display: 'flex',
                flexDirection: 'column',
                flexFlow: 'center',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <TypographyCustom types={[]} variant={'h6'}>
                Payment succeeded.
            </TypographyCustom>
            <br />

            <div>
                <ButtonCustom types={[]} onClick={props.returnToChat}>
                    Return to Chat
                </ButtonCustom>

                <ButtonCustom types={[]} onClick={props.returnToShop}>
                    Back to Shop{' '}
                    {/*TODO: need better name for this than "shop" */}
                </ButtonCustom>
            </div>
        </div>
    )
}

export default SuccessPage
