export const FormControlLabelCustomStyle = (mode, palette, animations, typography) => ({
    FormControlLabelCustom: {
        defaultProps: {},
        styleOverrides:{"nothing":{}},altStyleOverrides: {
            root: (props) => {
                if (props.checked) {
                    return {color: palette.text.primary}
                } else {
                    return {color: palette.text.secondary}
                }
            },
        }
    },
})
