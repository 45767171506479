
export const ResizeBar = (props) => {

    return (
        <div onMouseDown={e => props.resizeVideoDiv(e)}
            style={{
                width: "100%",
                cursor: "ns-resize",
                padding: "4px 0 0",
                borderTop: "4px solid #0000",
                position: "absolute",
                // top: 0,
                right: '40%',
                left: 0,
                bottom: 0,
                zIndex: 100,
                backgroundColor: "#f4f7f9"
            }} />
    )
}