const http = require('http-browserify')

//port and hostname
const hostname = 'wilo-filehost.dev'
// filehost microservice port
const port = 443

function getRequest(headers, callback) {
    const options = {
        hostname: hostname,
        port: port,
        method: 'GET',
        headers: headers,
        protocol: 'https:'
    }

    const req = http.request(options, (res) => {
        let resData = ''

        res.on('data', (d) => {
            resData += d
        })

        res.on('end', () => {
            callback(res.statusCode, resData)
            return
        })
    })

    req.on('error', (error) => {
        callback(0, 'Local Request Error, internet connection lost?')
    })

    req.end()
}

function postRequest(headers, callback, postData) {
    const options = {
        hostname: hostname,
        port: port,
        method: 'POST',
        headers: headers,
        protocol: 'https:'
    }

    const req = http.request(options, (res) => {
        let resData = ''

        res.on('data', (d) => {
            resData += d
        })

        res.on('end', () => {
            callback(res.statusCode, resData)
            return
        })
    })

    req.on('error', (error) => {
        callback(0, 'Local Request Error, internet connection lost?')
    })

    req.end(postData)
}

export async function createUploadToken(userId, locationid, callback) {
    let headers = {
        userId: userId,
        locationId: locationid,
    }
    getRequest(headers, callback)
}

export async function uploadFile(
    userId,
    uploadToken,
    filename,
    file,
    callback
) {
    let headers = {
        userId: userId,
        uploadToken: uploadToken,
        filename: filename,
    }
    postRequest(headers, callback, file)
}

export const makeRequest = (args, requestFunction) => {
    return new Promise((resolve, reject) => {
        requestFunction(...args, (statusCode, data) =>
            resolve([statusCode, data])
        )
    }).then((incomingData) => {
        if (incomingData[0] === 200) {
            return { statusCode: incomingData[0], data: incomingData[1] }
        } else {
            throw { statusCode: incomingData[0], data: incomingData[1] }
        }
    })
}

// module.exports = {
//     createUploadToken: createUploadToken,
//     uploadFile: uploadFile,
//     makeRequest: makeRequest
// }

// export {makeRequest, createUploadToken, uploadFile}
