//severity levels
//0 - Minor. Do not log on frontend.
//1 - Minor. Console log on frontend.
//2 - Moderate. Console log on frontend.
//3 - Severe. Console log on frontend. Report to adminLog.
//4 - Severe. Pop Modal on frontend. Report to adminLog.

//Errors of severity 3 and 4 should be automatically prevented on the frontend.
//Errors 3-4 thus likely indicate some level of fuzzing,
//TODO: Standardize session updates

import {compiledGlobals} from "./copyGlobalsHere.js";

let standardSplitLogStrategyPartOne = (err, res1, res2) => {
    let [error, logString, channelMeta, serverMeta] = res1
    return [
        true,
        logString,
        serverMeta.id,
        serverMeta.logId,
        serverMeta.ownerId,
    ]
}

let standardSplitLogStrategyPartTwo = (err, res1, res2) => {
    let [error, logString, channelMeta, serverMeta] = res2
    return [
        true,
        logString,
        serverMeta.id,
        serverMeta.logId,
        serverMeta.ownerId,
    ]
}

let standardLoggingStrategy = (error, logString, serverId, serverLogMeta) => {
    return [
        true,
        logString,
        serverLogMeta.id,
        serverLogMeta.logId,
        serverLogMeta.ownerId,
    ]
}

export const requestResponseGlobals = compiledGlobals

export const buildRequestDispatchActions = function () {
    let requestDispatchActions = {}
    for (const key in requestResponseGlobals) {
        if (requestResponseGlobals.hasOwnProperty(key)) {
            requestDispatchActions[key] = {
                cached: false,
                parameters: [],
                description: '',
                reduce: (state, action) => {
                    return [...state, action]
                },
            }
        }
    }
    return requestDispatchActions
}

const specialLog = (props, other) => {
    console.log(
        '%c (Dispatch Event: ' + props + ')',
        'background: #222; color: #7777ff',
        other
    )
}

export const buildRequestDispatchFunctions = function () {
    let requestDispatchFunctions = {}
    for (const key in requestResponseGlobals) {
        if (requestResponseGlobals.hasOwnProperty(key)) {
            if (requestResponseGlobals[key].defaultMode === 'socket') {
                let argumentData = requestResponseGlobals[key].arguments
                requestDispatchFunctions[key] = {
                    state: 'server',
                    action: key,
                    formatter: function () {
                        let payload = {}
                        specialLog(key, argumentData)
                        for (let i = 0; i < argumentData.length; i++) {
                            payload[argumentData[i].argName] = arguments[i]
                        }
                        if (arguments.length > argumentData.length - 1) {
                            payload['apiId'] = arguments[argumentData.length]
                        }
                        if (arguments.length > argumentData.length) {
                            payload['emitId'] =
                                arguments[argumentData.length + 1]
                        }
                        return payload
                    },
                }
            }
        }
    }
    return requestDispatchFunctions
}

export const buildCommandFormatter = function () {
    let requestDispatchActions = {}
    for (const key in requestResponseGlobals) {
        if (requestResponseGlobals.hasOwnProperty(key)) {
            requestDispatchActions[key] = (data) => {
                return {
                    handler: 'serverHandler',
                    command: key,
                    data: data,
                }
            }
        }
    }
    return requestDispatchActions
}

export const buildResponseGlobalsAPI = function () {
    let responseGlobalsAPI = {}
    for (const key in requestResponseGlobals) {
        if (requestResponseGlobals.hasOwnProperty(key)) {
            if (requestResponseGlobals[key].defaultMode === 'api') {
                responseGlobalsAPI[key] = {
                    defaultMode: requestResponseGlobals[key].defaultMode,
                    apiPath: requestResponseGlobals[key].apiPath,
                    handler: requestResponseGlobals[key].handler,
                    field: requestResponseGlobals[key].field,
                }
            }
        }
    }
    return responseGlobalsAPI
}

export const socketActions = buildRequestDispatchFunctions()
export const SA1 = socketActions
export const requestGlobalsAPI = buildResponseGlobalsAPI()
export const commandFormatter = buildCommandFormatter()
export const requestDispatchActions = buildRequestDispatchActions()
