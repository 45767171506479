import React, { useEffect, useCallback, useState, useRef } from "react";

import Grid from "@mui/material/Grid";

import { StyledDiv, ButtonCustom } from '../../Styled/index.js';

// icons
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import IconButton from "@mui/material/IconButton";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import HeadsetOffIcon from '@mui/icons-material/HeadsetOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SettingsIcon from '@mui/icons-material/Settings';

export const StreamControls = (props) => {
    return (
        <div style={{
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            position: "absolute",
            left: 0,
            top: 0,
            display: props.toolsVisible ? 'block' : 'none'
        }}>
            <Grid container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                style={{
                    height: "100%",
                    pointerEvents: "none"
                }}
            >
                <Grid container item xs={1}
                    direction="row"
                    justifyContent="center"
                    style={{
                        pointerEvents: "all"
                    }}>
                    <IconButton
                        color="default"
                        onClick={
                            () => {
                                props.AS.modals.ops.openModal("videoSettingsModal")
                            }
                        }
                    >
                        <SettingsIcon style={{ fontSize: "150%", color: "white" }} />
                    </IconButton>
                    <IconButton
                        color="default"
                        onClick={props.addNewInvite}
                    >
                        <AddCircleOutlineIcon style={{ fontSize: "150%", color: "white", display: (props.videoCalls.callPerms.usesInviteTokens && props.videoCalls.callPerms.canCreateInvitesWithPerms.result) ? "block" : "hidden" }} />
                    </IconButton>
                </Grid>
                <Grid container item xs={5}
                    direction="row"
                    justifyContent="center"
                    style={{
                        pointerEvents: "all"
                    }}>
                    <IconButton
                        color="default"
                        onClick={props.toggleStreaming}
                    >
                        {props.streaming
                            ? <VideocamIcon style={{ fontSize: "150%", color: "#47AECA" }} />
                            : <VideocamIcon style={{ fontSize: "150%", color: "white" }} />}
                    </IconButton>
                    <IconButton
                        color="default"
                        onClick={props.toggleScreenSharing}
                    >
                        {props.screenSharing
                            ? <PersonalVideoIcon style={{ fontSize: "150%", color: "#47AECA" }} />
                            : <PersonalVideoIcon style={{ fontSize: "150%", color: "white" }} />}
                    </IconButton>
                    <IconButton
                        color="default"
                        onClick={props.toggleMute}
                    >
                        {props.muted
                            ? <MicOffIcon style={{ fontSize: "150%", color: "white" }} />
                            : <MicIcon style={{ fontSize: "150%", color: "white" }} />}
                    </IconButton>
                    <IconButton
                        color="default"
                        onClick={props.toggleDeafen}
                    >
                        {props.deafen
                            ? <HeadsetOffIcon style={{ fontSize: "150%", color: "white" }} />
                            : <HeadphonesIcon style={{ fontSize: "150%", color: "white" }} />}
                    </IconButton>
                    <IconButton
                        color="error"
                        onClick={props.endCall}
                    >
                        <PhoneDisabledIcon style={{ fontSize: "150%" }} />
                    </IconButton>
                </Grid>
                <Grid container item xs={1}
                    direction="row"
                    justifyContent="flex-end"
                    style={{
                        pointerEvents: "all",
                        maxWidth: '10em',
                        minWidth: '7em',
                    }}
                >

                    <StyledDiv style={{ textAlign: 'right', marginRight: '1em', width: '100%' }}>
                        Push to Talk <br />
                        <ButtonCustom
                            // color="default"
                            onClick={props.togglePushToTalk} // TODO: toggle push to talk
                            style={{
                                fontSize: '1em'
                            }}>
                            {props.pushToTalk ? 'Disable' : 'Enable'}
                        </ButtonCustom>
                    </StyledDiv>

                    <IconButton
                        color="default"
                        onClick={() => {
                            if (props.errorMessage) {
                                alert("Fullscreen is not supported by this browser!");
                                return;
                            }
                            if (props.isFullscreen) {
                                props.handleExitFullscreen();
                                //trigger("videoCallFullScreenEnd", {});
                            } else {
                                props.setIsFullscreen();
                                //trigger("videoCallFullScreenBegin", {});
                            }
                            return;
                        }}
                    >
                        {props.isFullscreen
                            ? <FullscreenExitIcon
                                style={{ fontSize: "165%", color: "white" }} />
                            : <FullscreenIcon
                                style={{ fontSize: "165%", color: "white" }} />}
                    </IconButton>
                </Grid>
            </Grid>
        </div>
    )
}