import { store } from '../store.js'
import { updateCache } from '../reducers/cacheMonitor.js'

export const hsThrottle = function (actionMeta, throttleTime) {
    actionMeta.throttle = throttleTime
    return actionMeta
}
export const hsDebounce = function (actionMeta, debounceTime) {
    actionMeta.debounce = debounceTime
    return actionMeta
}

export const cacheUpdate = function (
    orderedCacheKeys,
    orderCacheKeyIds,
    result,
    label
) {
    store.dispatch(
        updateCache({
            orderedCacheKeys: orderedCacheKeys,
            orderedCacheKeysIds: orderCacheKeyIds,
            result: result,
            key: label,
        })
    )
}
