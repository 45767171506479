import React, { useState } from 'react'
import { Menu, Item, Separator, Submenu } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import { connect } from 'react-redux'
import { MenuCustom } from '../../../../Styled'

function FriendLinkMenu(props) {
    if (1 + 1 === 2) {
        return (
            <MenuCustom id={props.menuId}>
                <Item
                    onClick={() => {
                        props.selectedItem.list.setSelectedChannel(
                            props.selectedItem.key
                        )
                        props.selectedItem.list.setMenu('channelConfig')
                    }}
                >
                    Okay so. yes{' '}
                </Item>
                {/*<Item onClick={props.handleItemClick}>Quote</Item>*/}
                {/*<Separator/>*/}
                {/*<Item disabled>Disabled</Item>*/}
                {/*<Separator/>*/}
                {/*<Submenu label="React" horizontalDisplay={"default"} defaultArrowDirection={"right"}>*/}
                {/*    <Item onClick={props.handleItemClick}>Sub Item 1</Item>*/}
                {/*    <Item onClick={props.handleItemClick}>Sub Item 2</Item>*/}
                {/*</Submenu>*/}
            </MenuCustom>
        )
    } else {
        return null
    }
}

export default FriendLinkMenu
