import React, { useEffect, useState } from 'react'
import DesignAndStateContext from './context'
import { fontsAndColors, globalThemeNew } from '../../../theme/_globalTheme'
import { createTheme } from '@mui/material/styles'
import { useMediaQuery } from 'react-responsive'

const initTheme = createTheme(globalThemeNew(fontsAndColors[1]()))
const defaultThemeState = window.localStorage.getItem('defaultThemeState') ? parseInt(window.localStorage.getItem('defaultThemeState')) : 1

const designAndStateProvider = (Component) => {
    function WithDesignAndState(props) {
        const [version, setVersion] = useState(0)
        const [themeIndex, setThemeIndex] = useState(defaultThemeState)
        const [theme, setTheme] = useState(initTheme)
        const switchStyleMode = () => {
            let newIndex = (themeIndex + 1) % fontsAndColors.length
            setThemeIndex(newIndex)
            window.localStorage.setItem('defaultThemeState',""+newIndex)
            setTheme(createTheme(globalThemeNew(fontsAndColors[newIndex]())))
        }
        let newViewingMode = {}
        newViewingMode.isDesktopOrLaptop = useMediaQuery({
            query: '(min-width: 1224px)',
        })
        newViewingMode.isBigScreen = useMediaQuery({
            query: '(min-width: 1824px)',
        })
        newViewingMode.isTabletOrMobile = useMediaQuery({
            query: '(max-width: 1224px)',
        })
        newViewingMode.isPortrait = useMediaQuery({
            query: '(orientation: portrait)',
        })
        newViewingMode.isRetina = useMediaQuery({
            query: '(min-resolution: 2dppx)',
        })
        useEffect(() => {
            function handleResize() {
                setVersion((version) => version + 1)
            }
            setTheme(createTheme(globalThemeNew(fontsAndColors[defaultThemeState]())))
            window.addEventListener('resize', handleResize)
        }, [])

        return (
            <DesignAndStateContext.Provider
                value={{
                    switchStyleMode: switchStyleMode,
                    viewingMode: newViewingMode,
                    updateAppState: () => ({}),
                }}
            >
                <Component theme={theme} {...props} />
            </DesignAndStateContext.Provider>
        )
    }

    return WithDesignAndState
}

const withDesignAndState = (Component) => (props) =>
    (
        <DesignAndStateContext.Consumer>
            {(value) => (
                <Component
                    {...props}
                    viewingMode={value.viewingMode}
                    switchStyleMode={value.switchStyleMode}
                />
            )}
        </DesignAndStateContext.Consumer>
    )

export { designAndStateProvider, withDesignAndState }
