import { inputLabelClasses } from '@mui/material'
import { scrollbarConfig } from '../globals'

export const TextFieldCustomStyle = (mode, palette) => ({
    TextFieldCustom: {
        defaultProps: {},
        propOverrides: {
            socketForm: {
                size: 'small',
                // sx: {
                //     "& .MuiFilledInput-input": {color: "red"}
                // },
                // InputLabelProps: {style: {color: 'red'}}
            },
        },
        styleOverrides:{"nothing":{}},altStyleOverrides: {
            root: {},
            channel: {},
            // wiloDemo: (props) => {
            //     if (props.types.includes("wiloDemo")) {
            //         return {animationName: props.animationTrigger ? "example" : "none", animationDuration: "4s"}
            //     } else {
            //         return {}
            //     }
            // },
            inputContainerTextField: {

                backdropFilter: 'blur(2px)',
                borderRadius: '5px',
                background: palette.background.secondary,
                '&:focus-within': {
                    color: palette.text.primary,
                    background: palette.background.secondary,
                },
                '&>* textarea': {
                    color: palette.text.primary,
                },
                '& > div': {
                    padding: '6.5px 14px',
                    '& > input': { padding: '0px' },
                },
            },
            virtualAssistantChat: {
                backgroundColor: palette.background.primary,
                borderRadius: '1000px',
                '& > div': {
                    borderRadius: '1000px',
                },
            },
            searchBarTextField: {
                background: palette.action.selected,
                overflow: 'hidden',
                '& .Mui-focused': {
                    input: {
                        color: '#00d',
                        caretColor: '#00d',
                        opacity: '1',
                        width: '100%',
                    },
                },
                input: {
                    color: '#707',
                    caretColor: '#707',
                    opacity: '0',
                    width: '0%',
                    transition:
                        'color 0.2s linear, caretColor 0.2s linear, opacity 0.2s linear, width 0.2s linear',
                },
                label: {
                    marginTop: '1px',
                    marginLeft: '-5px',
                    color: '#707',
                    width: '12.5em',
                    opacity: '1',
                    textOverflow: 'clip',
                    overflow: 'hidden',
                    fontStyle: 'italic',
                    transition:
                        'color 0.2s linear, width 0.2s steps(22), opacity 0.2s ease-in-out',
                    [`&.${inputLabelClasses.focused}`]: {
                        color: '#00d',
                        width: '0em',
                        opacity: '0',
                    },
                },
            },
            server: {},
            user: {},
            socketForm: {
                // input: {color: 'blue'}
            },
        },
    },
})
