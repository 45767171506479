import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {RA1, RS1} from '../../../../redux/actions.js'
import {SA1} from '../../../../hs/requestGlobals.mjs'
import {dispatchMappers} from '../../../../redux/process/actionProcess.js'
import SocketForm from '../../../_reusable/SocketForm/SocketForm'
import {statePropMapper} from '../../../../redux/process/selectorProcess'
import useState from 'react-usestateref'
import {withAS} from "../../../_contexts/withAppState";

const makeMapStateToProps = statePropMapper([
    RS1.getServerAbstractRoles('serverAbstractRoles'),
    RS1.getServer("serverData"),
    RS1.store('_appState', '_appState'),

])

const mapDispatchToProps = dispatchMappers([
    RA1.api.updateAPI,
    SA1.createAppointment,
    SA1.getProductsByServer,
    RA1._appState.openInfoModal
])

const timeIntervals = [
    {value: 10, name: 10},
    {value: 15, name: 15},
    {value: 20, name: 20},
    {value: 25, name: 25},
    {value: 30, name: 30},
    {value: 45, name: 45},
    {value: 60, name: 60},
    {value: 90, name: 90},
]
//awf
const recurringOptions = [
    {value: 'daily', name: 'Daily'},
    {value: 'bdaily', name: 'Business Days'},
    {value: 'weekly', name: 'Weekly'},
]

const CreateAppointmentModal = function (props) {
    const [paidMode, setPaidMode] = useState(false)
    const [roleListData, setRoleListData] = useState([])
    useEffect(() => {
        const [owner, ...nonOwnerRoles] = props.serverAbstractRoles
        setRoleListData(
            nonOwnerRoles.map((role) => ({
                checkBoxText: role.role,
                checkBoxId: 'ROLE' + role.id,
            }))
        )
    }, [])
    useEffect(() => {
        const [owner, ...nonOwnerRoles] = props.serverAbstractRoles
        setRoleListData(
            nonOwnerRoles.map((role) => ({
                checkBoxText: role.role,
                checkBoxId: role.id,
            }))
        )
    }, [props.serverAbstractRoles])

    useEffect(() => {
        props.dispatch.server.getProductsByServer(props.AS.activeServer)
    }, [])
    let paymentSelector = []
    let calendarProducts = []
    let calendarProductsParsed = []
    const catalog = props?._appState?.catalog
    if (Array.isArray(catalog)) {
        catalog.map((product) => {
            if (product.type === "calendar") {
                calendarProductsParsed.push({name: product.name, value: product.id})
                calendarProducts.push(product)
            }
        })
    }
    if (paidMode) {
        paymentSelector.push({
            id: 'productId',
            type: 'selectGroup',
            typographyLabel: 'Product',
            formatter: (obj) => obj?.name,
            defaultIndex: 0,
            list: calendarProductsParsed,
            requireError: 'Please choose paid thing',
            require: true,
            page: 1,
        })
    }
    return (
        <SocketForm
            initialScreen={true}
            formStateDefaults={{
                recurring: 'daily',
                length: timeIntervals[4].value,
            }}
            useConfirmationScreen={true}
            useMultipleFormPages={true}
            confirmationFunction={(obj) => {
            }}
            formMethod={'createAppointment'}
            formDispatch={(obj) => {
                let copy = {...obj}
                const timeString = new Date(copy.time).toTimeString()
                copy.date = copy.calendar.map((calObj) => {
                    // The replace all is required for safari compatability
                    return new Date(calObj.str.replaceAll(".", "/") + " " + timeString)
                })
                copy.serverId = props.AS.activeServer
                if (!copy.isProduct) {
                    copy.productId = ''
                }
                if (!copy.hasOwnProperty('requiresApproval')) {
                    copy['requiresApproval'] = false
                }

                //#TODO Clean me up:

                copy.recurring = ''
                if (!copy.hasOwnProperty('privateAppointment')) {
                    copy['privateAppointment'] = false
                    copy['restrictToRoles'] = []
                } else {
                    if (copy['privateAppointment'] === false) {
                        copy['restrictToRoles'] = []
                    }
                }
                copy.length = copy.length * 60000
                props.dispatch.server.createAppointment(
                    copy.serverId,
                    copy.date,
                    copy.length,
                    copy.description,
                    copy.recurring,
                    copy.privateAppointment,
                    copy.restrictToRoles,
                    copy.isProduct,
                    copy.productId,
                    copy.requiresApproval,
                    ''
                )
            }}
            confirmationMessage={"Appointments added."}
            socketForm
            onClose={() => null}
            onSuccess={() => null}
            description={'You may create a new timeslot for an appointment.'}
            formFields={[
                {
                    id: 'calendar',
                    type: 'calendar',
                    typographyLabel: 'Cal thing',
                    requireError: 'Please choose dates',
                    require: true,
                    page: 1,
                },
                {
                    id: 'time',
                    type: 'time',
                    typographyLabel: 'Time',
                    requireError: 'Please choose a time.',
                    require: true,
                    page: 1,
                },
                {
                    id: 'length',
                    type: 'selectGroup',
                    typographyLabel: 'Length',
                    formatter: (obj) => obj?.name + ' minutes',
                    defaultIndex: 4,
                    list: timeIntervals,
                    requireError: 'Please choose an appointment length',
                    require: true,
                    page: 1,
                },
                {
                    id: 'description',
                    typographyLabel: 'Description:',
                    type: 'multiline',
                    page: 1,
                },
                ...paymentSelector,
                {
                    id: 'privateAppointment',
                    type: 'checkBoxSingle',
                    label: 'Restrict Availabilities To Roles',
                    typographyLabel: '',
                    require: false,
                    page: 2,
                },
                {
                    id: 'restrictToRoles',
                    type: 'checkBoxGroup',
                    hideIf: (formState) =>
                        formState.privateAppointment !== true,
                    typographyLabel: 'Restrict availability to roles:',
                    defaultIndex: 4,
                    list: roleListData,
                    require: false,
                    page: 2,
                },
            ]}
            formButtons={[
                {
                    label: 'Open',
                    icon: 'add',
                    mutateFormState: (formState) => {
                        let oldFormState = {...formState}
                        oldFormState.isProduct = false
                        return oldFormState
                    },
                    formType: 'open',
                    isFormTypeButton: true,
                    initialButton: true,
                },
                {
                    label: 'Paid',
                    icon: 'add',
                    mutateFormState: (formState) => {
                        if (props.serverData.stripeEnabled) {
                            if (calendarProducts.length === 0) {
                                props.AS.modals.ops.openInfoModal("setupPayments")
                            } else {
                                let oldFormState = {...formState}
                                setPaidMode(true)
                                oldFormState.isProduct = true
                                return oldFormState
                            }
                        } else {
                            props.AS.modals.ops.openInfoModal("setupPayments")
                        }
                    },
                    formType: 'paid',
                    isFormTypeButton: true,
                    initialButton: true,
                },
                {
                    label: 'Create',
                    icon: 'add',
                    isSubmitButton: true,
                    isCloseButton: false,
                },
                {
                    label: 'Advanced',
                    icon: 'add',
                    increasePage: true,
                    onlyShowOnPages: [1],
                },
                {
                    label: 'Back',
                    icon: 'add',
                    decreasePage: true,
                    onlyShowOnPages: [2],
                },
            ]}
            formTypography={{label: 'Create Appointment'}}
            successMessage={'Appointment created.'}
        />
    )
}

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(withAS(CreateAppointmentModal))
