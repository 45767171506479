import { requestResponseGlobals } from './requestGlobals.mjs'
import axios from 'axios'
import { store } from '../redux/store.js'
import { apiActions } from '../redux/reducers/api.js'
import { SITE_URL } from '../constants/env'

export const buildRequestAPIDispatchFunctions = function () {
    let requestAPIDispatchFunctions = {}
    for (const key in requestResponseGlobals) {
        if (requestResponseGlobals.hasOwnProperty(key)) {
            if (requestResponseGlobals[key].defaultMode === 'api') {
                let argumentData = requestResponseGlobals[key].arguments
                let apiPath = requestResponseGlobals[key].apiPath
                requestAPIDispatchFunctions[key] = (subKey) => (authData) =>
                    async function (...args) {
                        let payload = { ...authData }
                        if (argumentData.length > 1 && args.length === 1) {
                            if (!(args[0] instanceof FormData)) {
                                payload = args[0]
                                payload.id = authData?.id
                                payload.token = authData?.token
                            } else {
                                payload = args[0]
                                payload.append('id', authData?.id)
                                payload.append('token', authData?.token)
                            }
                        } else {
                            for (let i = 0; i < args.length; i++) {
                                payload[argumentData[i].argName] = args[i]
                            }
                        }
                        let resData = await axios
                            .post(SITE_URL + 'api/' + apiPath, payload)
                            .then((res) => {
                                store.dispatch(
                                    apiActions.updateAPI({
                                        key: subKey,
                                        responseKey: key,
                                        data: res.data,
                                    })
                                )
                                return res.data
                            })
                            .catch((e) => {
                                console.log(e)
                                console.log(e)
                                store.dispatch(
                                    apiActions.updateAPI({
                                        key: subKey,
                                        responseKey: key,
                                        data: e.response.data,
                                    })
                                )
                                return e.response.data
                            })
                        return resData
                    }
            }
        }
    }
    return requestAPIDispatchFunctions
}

export const buildRequestDispatchActions = function () {
    let requestDispatchActions = {}
    for (const key in requestResponseGlobals) {
        if (requestResponseGlobals.hasOwnProperty(key)) {
            requestDispatchActions[key] = {
                cached: false,
                parameters: [],
                description: '',
                reduce: (state, action) => {
                    return [...state, action]
                },
            }
        }
    }
    return requestDispatchActions
}

export const API = buildRequestAPIDispatchFunctions()
