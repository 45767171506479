import React, {useEffect, useState} from 'react'
import {statePropMapper} from '../../../redux/process/selectorProcess.js'
import {dispatchMappers} from '../../../redux/process/actionProcess.js'
import {SA1} from '../../../hs/requestGlobals.mjs'
import {RA1, RS1} from '../../../redux/actions.js'
import {connect} from 'react-redux'
import Immutable from "immutable";
import {watchThread} from "./Operations/watchThread";
import {getImages} from "./Operations/getImages";
import {useTheme} from "@mui/material";
import {loadOneThread} from "./Operations/loadOneThread";
import {getThreadPropertiesFn} from "./Operations/getThreadProperties";
import {useUpdateWatchedThreads} from "./Hooks/useUpdateWatchedThreads";
import {JLog} from "../../../hs/journey";

const makeMapStateToProps = statePropMapper([
    RS1.getThreads('threads'),
    RS1.getAllThreadsWatched('threadsWatched'),
    RS1.store('_appState', '_appState'),
    RS1.getSelectedThread('threadMsgs'),
    RS1.getThreadsWatched('threadsWatched'),
    RS1.getServerUsersOb('users'),
    RS1.getMessagesLength('mlength'),
])

const mapDispatchToProps = dispatchMappers([SA1.updateSession, SA1.likeMessage, SA1.watchThread])

const defaultArray = Immutable.List([])
const withThreads = function (WrappedComponent) {
    const Wrapper = function (props) {
        const [ver, setVer] = useState(0)
        const [threadMsgs, setThreadMsgs] = useState([])
        const [blogView, setBlogView] = useState(false)
        const [selectedThreadMsg, setSelectedThreadMsg] = useState({})
        const [threadPics, setThreadPics] = useState([])
        const [threadsArray, setThreadsArray] = useState([])
        const [threadsArrayImmutable, setThreadsArrayImmutable] = useState(defaultArray)
        const theme = useTheme()
        useUpdateWatchedThreads({
            threadsWatched: props.threadsWatched,
            selectedThread: props.AS.threads.state.selectedThread,
            props
        })
        const watchThatThread = watchThread({props})
        const getImgs = getImages({props})
        const loadSingleThread = loadOneThread({selectedThread: props.AS.threads.state.selectedThread, props})
        const getThreadProperties = getThreadPropertiesFn(threadsArray)

        const triggerThreadModeReload = () => {
            setBlogView(false)
            setTimeout(() => {
                props.dispatch.server.updateSession(
                    'loadThreads',
                    props.server,
                    props.channel,
                    null,
                    '',
                    ''
                ), 750
            })
            setTimeout(() => {
                setVer(ver => ver + 1)
            }, 1500)
        }

        useEffect(() => {
            if (props.threads.includes('reload')) {
                props.dispatch.server.updateSession(
                    'loadThreads',
                    props.server,
                    props.channel,
                    null,
                    '',
                    ''
                )
            } else {
                setVer(ver => ver + 1)
            }
            return () => {
                // if(!props.AS.nav.state.multiStageTransition) {
                //     props.AS.threads.ops.setSelectedThread(null)
                //     props.AS.threads.ops.setSingleThreadPage(false)
                // }
            }
        }, [])

        useEffect(() => {
            let newThreadMsgs = []
            if (props.threadMsgs && props.AS.threads.state.singleThreadPage) {
                props.threadMsgs.forEach((message, index) => {
                    let msgCopy = {}
                    msgCopy.key = index
                    msgCopy.index = index
                    msgCopy.msgIndx = index
                    msgCopy.userId = message.uId
                    msgCopy.message = message
                    msgCopy.getImgs = getImgs
                    msgCopy.server = props.server
                    msgCopy.theme = theme
                    msgCopy.replies = props.threadMsgs.filter(reply => reply?.reply?.isReply && reply.reply.mData.mId === message.mId)
                    msgCopy.repliedTo = props.threadMsgs.filter(replied => message?.reply?.isReply && replied.mId === message.reply.mData.mId)
                    newThreadMsgs.push(msgCopy)
                })
                setThreadMsgs(Immutable.List(newThreadMsgs))
            }
        }, [props.threadMsgs, props.AS.threads.state.singleThreadPage, props.AS.threads.state.selectedThread])

        useEffect(() => {
            setThreadsArray(props.threads.filter(msg => msg !== 'reload'))
            setThreadsArrayImmutable(Immutable.List(props.threads.filter(msg => msg !== 'reload')))
            // setVer((ver) => ver + 1)
        }, [props.threads,ver])

        useEffect(loadSingleThread, [props.AS.threads.state.selectedThread])
        useEffect(async () => {
            if (!props.AS.threads.state.singleThreadPage) {
                let {threadImgs} = getThreadProperties()
                setThreadPics(threadImgs)
            }
        }, [props.AS.threads.state.singleThreadPage, threadsArray])

        const {channelWindow, channelData, threads, ...otherProps} = props
        const likeMessage = (tId) => {
            props.dispatch.server.likeMessage(props.server, props.channel, tId)
        }
        let selectedIsWatched
        if (props.threadsWatched) {
            selectedIsWatched = props.threadsWatched.filter(threadData => threadData.threadId === props.AS.threads.state.selectedThread).length
        }
        const threadSystem = {
            threads: threadsArray,
            threadsImmutable: threadsArrayImmutable,
            likeMessage: likeMessage,
            threadPics: threadPics,
            blogView,
            setBlogView,
            threadMsgs: threadMsgs,
            singleThreadPage: props.AS.threads.state.singleThreadPage,
            selectedThread: props.AS.threads.state.selectedThread,
            selectedThreadMsg: selectedThreadMsg,
            watchThatThread: watchThatThread,
            setSelectedThreadMsg: setSelectedThreadMsg,
            setSingleThreadPage: props.AS.threads.ops.setSingleThreadPage,
            setSelectedThread: props.AS.threads.ops.setSelectedThread,
            users: props.users,
            triggerThreadModeReload: triggerThreadModeReload,
            threadsWatched: props.threadsWatched,
            selectedThreadIsWatched: !!selectedIsWatched,
            getThreadProperties: getThreadProperties
        }
        // JLog.threads.log("--Thread System--")()
        // JLog.threads.log(threadSystem)()
        return (
            <WrappedComponent
                key={'K0' + props.channel + ver}
                updateId={props._appState.updateId}
                threadSystem={threadSystem}
                {...otherProps}
            />)
    }
    return connect(makeMapStateToProps, mapDispatchToProps)(Wrapper)
}

export default withThreads
