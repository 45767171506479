import React, { useCallback, useEffect, useRef, useState } from 'react'
import withContentDropzone from '../_wrappers/withContentDropzone'
import { statePropMapper } from '../../redux/process/selectorProcess'
import { RA1, RS1 } from '../../redux/actions'
import { dispatchMappers } from '../../redux/process/actionProcess'
import { SA1 } from '../../hs/requestGlobals.mjs'
import { connect } from 'react-redux'
import { Card, CardContent } from '@mui/material'
import Typography from '@mui/material/Typography'
import CropperComponent from './CropperComponent'
import { preprocessImage } from '../../hs/imagePreprocess'
import Button from '@mui/material/Button'
import { useTheme } from '@mui/material'

const makeMapStateToProps = statePropMapper([
    RS1.store('imageStore', 'imageStore'),
])
const mapDispatchToProps = dispatchMappers([
    RA1._appState.updateAppState,
    RA1.imageStore.addImage,
    RA1.imageStore.clear,
    RA1.client.error,
])

const DropZone = (props) => {
    let theme = useTheme()
    let [cropped, setCropped] = useState(false)
    let [operationCompleted, setOperationCompleted] = useState(false)
    let fileURL = null
    if (props.blobLinks.length > 0) {
        fileURL = props.blobLinks[0].url
    }
    const uploadInputRef = useRef(null)
    let cropAndUpdate = useCallback(
        async (modifiedFile) => {
            props.clearFiles()
            props.storeFile(modifiedFile, modifiedFile.name)
            setCropped(true)
        },
        [cropped]
    )
    useEffect(() => {
        if (operationCompleted) {
            const timer = setTimeout(() => {
                props.completeOperation((old) => old + 1)
            }, 100)
            return () => clearTimeout(timer)
        }
    }, [operationCompleted])
    return (
        <>
            <Card sx={{
                background: theme.palette.background.primary,
                margin: '14px'
            }}>
                <CardContent
                    style={{
                        padding: '0px',
                        paddingBottom: '10px',
                        paddingTop: '15px',
                    }}
                >
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        style={{ paddingLeft: '9px' }}
                    >
                        {props.label}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        style={{ paddingLeft: '11px' }}
                    >
                        {props.description}
                    </Typography>
                    <div>
                        {fileURL ? (
                            <div>
                                {props.useCropper && !cropped ? (
                                    <CropperComponent
                                        imageUrl={fileURL}
                                        aspectRat={props.aspectRat}
                                        cropAndUpdate={cropAndUpdate}
                                    />
                                ) : (
                                    <div>
                                        <div>
                                            <img
                                                style={{
                                                    padding: '30px',
                                                    height: '200px',
                                                }}
                                                src={fileURL}
                                                alt={fileURL}
                                            />
                                        </div>
                                        <Button
                                            onClick={async () => {
                                                let fileToken
                                                if (props.hasFiles()) {
                                                    fileToken =
                                                        await props.sendFiles()
                                                }
                                                props.sendFunction(fileToken)
                                                setOperationCompleted(true)
                                            }}
                                            variant="contained"
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                <div>
                                    <img
                                        style={{
                                            padding: '30px',
                                            height: '100px',
                                        }}
                                        src={props.defaultImage}
                                        alt={props.defaultImage}
                                    />
                                </div>
                                <input
                                    ref={uploadInputRef}
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={(e) => {
                                        props.storeFile(
                                            e.target.files[0],
                                            e.target.files[0].name
                                        )
                                    }}
                                />
                                <div style={{ padding: '10px' }}>
                                    <Button
                                        onClick={() =>
                                            uploadInputRef.current &&
                                            uploadInputRef.current.click()
                                        }
                                        variant="contained"
                                    >
                                        Upload
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </CardContent>
            </Card>
        </>
    )
}

const DropZoneWrapped = withContentDropzone('default')(DropZone)

const ImageDropzone = (props) => {
    useEffect(() => {
        return () => {
            //props.manager.clearImageData()
        }
    }, [])
    return (
        <DropZoneWrapped
            imageId={'blah'}
            imageType={'bargh'}
            preprocess={preprocessImage}
            {...props}
        />
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(ImageDropzone)
