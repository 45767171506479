import React from 'react'
import './secondrow.css'
import { homePageText } from "../../homePageText";
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';

const useStyles = makeStyles({
    signupbutton: {
        fontSize: '1em',

        // clipPath: 'polygon(100% 0%, 12% 0%, 0% 100%, 88% 100%)',

        // height: '3em',
        lineHeight: '0',
        marginRight: '0.6em',

        borderRadius: "6vw 0% 6vw 0%",

        padding: '2em',
        color: '#fff',
        textTransform: 'none',
        '&:hover': {
            color: '#fff',
            backgroundColor: '#fdc',
        },
        ['@media (pointer: none), (pointer: coarse)']: {
            width: 'min(70vw, 12em)',
            marginTop: 'min(10vw, 10vh)',
            fontSize: '0.7em',
            lineHeight: '1.4em',
            height: '5em',

            borderRadius: "60px 0% 60px 0%",

            marginRight: '0',
        },
    },
})

const SecondRow = function (props) {
    const classes = useStyles()
    const history = useHistory()

    return (
        <div className="row second">
            <div className="column first text-box">
                <span id="demotext">
                    &emsp;
                    Intro video coming soon!<br />
                </span>
            </div>
            <div className="column button">
                <Button
                    className={classes.signupbutton}
                    onClick={() => {
                        history.push('/auth2')
                    }}>
                        Come on in!
                </Button>
            </div>
        </div>
    )
}

export default SecondRow
