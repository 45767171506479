export const StyledBStyle = (mode, palette) => ({
    StyledB: {
        defaultProps: {},
        propOverrides: {
            phatFont: {
                height: 40,
            },
        },
        styleOverrides:{"nothing":{}},altStyleOverrides: {
            root: {
                fontSize: 10,
                color: 'red',
            },
            phatFont: {
                fontSize: 14,
                background: 'blue',
            },
        },
    },
})
