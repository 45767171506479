import { requestDispatchActions } from '../../hs/requestGlobals.mjs'
import { createCachedSlice } from '../cache/createCachedSlice.js'

export const serverSlice = createCachedSlice({
    name: 'server',
    initialState: [],
    reducers: requestDispatchActions,
})

export const serverActions = serverSlice.actions
export const server = serverSlice.reducer
