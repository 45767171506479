import React, { useEffect, useCallback, useState, useRef } from "react";
import { preloadScript } from 'opentok-react';
import usePortal from 'react-useportal';
import { on, off } from "../../../events.js";

// Icons:

// state & dispatch:
import { statePropMapper } from "../../../redux/process/selectorProcess.js";
import { dispatchMappers } from "../../../redux/process/actionProcess.js";
import { connect } from "react-redux";
import { SA1 } from "../../../hs/requestGlobals.mjs";
import { RA1, RS1 } from "../../../redux/actions.js";

// etc:
import { withAS } from "../../_contexts/withAppState/index.js";
import { vDH } from '../Styled/index.js';
import { useTheme } from '@mui/material'

// components:
import { VideoRendererMain } from "./VideoRendererMain/index.js";
import { StreamControls } from "./StreamControls/index.js";
import { VideoErrorDiv } from "./VideoErrorDiv/index.js";
import { ResizeBar } from "./ResizeBar/index.js";

import '@vonage/video-publisher/video-publisher.js';
import '@vonage/video-subscribers/video-subscribers.js';
import '@vonage/screen-share/screen-share.js';

const sesh = {
  apiKey: "46627352"
}

const makeMapStateToProps = statePropMapper([
  RS1.store("videoCalls", "videoCalls"),
  RS1.getOwnUsername("ownUsername"),
])

const mapDispatchToProps = dispatchMappers([
  SA1.addInvite,
  SA1.destroyInvite,
  SA1.disconnectCall,
  SA1.joinWithInvite,
  SA1.joinWithoutInvite,
  SA1.queryInvites,
  RA1.videoCall.resetToDefaultState,
  RA1.videoCall.addVideoDevices,
  RA1.videoCall.setVideoDevice,
  RA1.videoCall.addAudioDevices,
  RA1.videoCall.setAudioDevice
])

function useFullscreenStatus(elRef) {
  const [isFullscreen, setIsFullscreen] = React.useState(
    document[getBrowserFullscreenElementProp()] != null
  );

  const setFullscreen = () => {
    if (elRef.current == null) return;

    elRef.current
      .requestFullscreen()
      .then(() => {
        setIsFullscreen(document[getBrowserFullscreenElementProp()] != null);
      })
      .catch((err) => {
        console.log(err)
        setIsFullscreen(false);
      });
  };

  React.useLayoutEffect(() => {
    document.onfullscreenchange = () => {
      setIsFullscreen(document[getBrowserFullscreenElementProp()] != null);
    }

    document.onwebkitfullscreenchange = () => {
      setIsFullscreen(document[getBrowserFullscreenElementProp()] != null);
    }

    return () => {
      document.onfullscreenchange = undefined;
      document.onwebkitfullscreenchange = undefined;
    }
  });

  return [isFullscreen, setFullscreen];
}

function getBrowserFullscreenElementProp() {
  if (typeof document.fullscreenElement !== "undefined") {
    return "fullscreenElement";
  } else if (typeof document.mozFullScreenElement !== "undefined") {
    return "mozFullScreenElement";
  } else if (typeof document.msFullscreenElement !== "undefined") {
    return "msFullscreenElement";
  } else if (typeof document.webkitFullscreenElement !== "undefined") {
    return "webkitFullscreenElement";
  } else {
    alert("fullscreen element is not supported by this browser!");
    throw new Error("fullscreenElement is not supported by this browser");
  }
}

function toggleElementCSSClasses(id, fromClass, toClass) {
  let toggleItem = document.getElementById(id);

  if (toggleItem.classList.contains(fromClass)) {
    toggleItem.classList.remove(fromClass);
  }

  if (!toggleItem.classList.contains(toClass)) {
    toggleItem.classList.add(toClass);
  }
}


/*
<OTPublisher
                          properties={{
                              width: "100%",
                              height: props.fullscreen ? "90vh" : "45vh",
                              videoSource: props.screenSharing ? 'screen' : props.selectedVideoId,
                              publishAudio: props.audio,
                              publishVideo: props.streaming || props.screenSharing,
                              style: {
                                archiveStatusDisplayMode: "off",
                                buttonDisplayMode: "off",
                                nameDisplayMode: "off"
                              }
                          }} session={props.session}
                          onError={(e) => console.log(e)}/>
*/

const StreamPage = function (props) {
  const theme = useTheme()
  const [visible, setVisible] = useState(false);
  const [toolsVisible, setToolsVisible] = useState(false);
  const fullscreenDiv = useRef(null)

  const [pushToTalk, setPushToTalk] = useState(false)

  const [muted, setMuted] = useState(false);
  const [deafen, setDeafen] = useState(false);
  const [streaming, setStreaming] = useState(false);
  const [screenSharing, setScreenSharing] = useState(false);

  const [audioInputDevices, setAudioInputDevices] = useState([]);
  const [videoInputDevices, setVideoInputDevices] = useState([]);
  const [validatedDevices, setValidatedDevices] = useState(false);

  const [selectedVideoId, setSelectedVideoId] = useState("");
  const [selectedAudioId, setSelectedAudioId] = useState("");

  const [inSession, setInSession] = useState(false);
  const [session, setSession] = useState(null);
  const [currentJoinToken, setCurrentJoinToken] = useState("");
  const [counter, setCounter] = useState(0);

  const [lastTimeout, setLastTimeout] = useState(null);
  const [resetModalTimeout, setResetModalTimeout] = useState(null);

  const [lastErrorContent, setLastErrorContent] = useState("");

  const [streamOptions, setStreamOptions] = useState({ audio: true, video: true })
  const [streamObject, setStreamObject] = useState(null)
  const [restartCall, setRestartCall] = useState(false)


  // TODO: REFACTORING

  const publisher = useRef(null);
  const subscribers = useRef(null);
  const screenshare = useRef(null);

  const [newSessionId, setNewSessionId] = useState(null);
  const [token, setTokenId] = useState(null);
  const [newSession, setNewSession] = useState(null)

  const toggleVideo = () => {
    console.log(publisher)
    console.log(subscribers)
    publisher.current.toggleVideo();
  };

  const toggleAudio = () => {
    console.log(publisher)
    console.log(subscribers)
    publisher.current.toggleAudio();
  };


  // END REFACTORING CODE


  const [videoElementHeight, setVideoElementHeight] = useState(vDH(45))
  const increaseVideoSize = () => {
    console.log(videoElementHeight)
    let currentVideoElementHeight = parseInt(videoElementHeight.substring(0, videoElementHeight.length - 2))
    console.log(currentVideoElementHeight)
    setVideoElementHeight(vDH((currentVideoElementHeight + 5).toString()))
  }
  const decreaseVideoSize = () => {
    console.log(videoElementHeight)
    let currentVideoElementHeight = parseInt(videoElementHeight.substring(0, videoElementHeight.length - 2))
    console.log(currentVideoElementHeight)
    setVideoElementHeight(vDH((currentVideoElementHeight - 5).toString()))
  }

  const { Portal } = usePortal({
    onPortalClick({ portal }) {
      portal.current.style.cssText = `
        height: ${videoElementHeight},
      `
    },
    bindTo: document && document.getElementById('topLevelChat')
  });

  let isFullscreen, setIsFullscreen;
  let errorMessage;

  try {
    [isFullscreen, setIsFullscreen] = useFullscreenStatus(fullscreenDiv);
  } catch (e) {
    errorMessage = true;
    isFullscreen = false;
    setIsFullscreen = undefined;
  }

  const handleExitFullscreen = () => document.exitFullscreen();

  function onCall(sessionId, joinToken) {

    if (inSession !== true) {

      setTokenId(joinToken)
      setNewSessionId(newSessionId)

      const OT = window.OT;

      // if (!validatedDevices) {
      //   const permissions = navigator.mediaDevices.getUserMedia(streamOptions).then(function (stream) {
      //     stream.getTracks().forEach(x => x.stop());
      //     OT.getDevices(devices);
      //   })
      //   console.log(permissions)
      //   setValidatedDevices(true);
      // }

      let sessionHelper = OT.initSession(sesh.apiKey, sessionId);
      console.log(sessionHelper);
      // setSession(sessionHelper);
      setNewSession(sessionHelper)

      setVisible(true);
      props.AS.video.ops.enableVideoOverlay();
      setInSession(true);
      // setCurrentJoinToken(joinToken);

      publisher.current.session = session;
      publisher.current.token = token;
      subscribers.current.session = session;
      subscribers.current.token = token;
      screenshare.current.session = session;
      screenshare.current.token = token;
    } else {
      //already in a session!
    }
  }

  function startWithInvite(data) {
    props.dispatch.server.joinWithInvite(data.detail.sourceId, data.detail.invite);
  }

  function startWithoutInvite(data) {
    props.dispatch.server.joinWithoutInvite(data.detail.sourceId, data.detail.type);
    console.log("Called start without invite?");
  }

  function endCall() {
    setAudioInputDevices([]);
    setVideoInputDevices([]);
    setSelectedAudioId("");
    setSelectedVideoId("");
    setInSession(false);
    setVisible(false);
    setScreenSharing(false);
    setStreaming(false);
    if (isFullscreen) {
      handleExitFullscreen();
    }
    setCurrentJoinToken("");

    const permissions = navigator.mediaDevices.getUserMedia(streamOptions).then(function (stream) {
      stream.getTracks().forEach(x => x.stop());
    })
    props.AS.video.ops.disableVideoOverlay();
    props.dispatch.videoCall.resetToDefaultState();
    props.dispatch.server.disconnectCall(props.videoCalls.sourceId);
  }

  const mouseMove = e => {
    setToolsVisible(true);
    if (lastTimeout != null) {
      clearTimeout(lastTimeout);
    }
    let timeoutId = setTimeout(() => setToolsVisible(false), 1000 * 2.5);
    setLastTimeout(timeoutId)
  }

  function toggleMute() {
    mouseMove();
    setMuted(!muted);
    publisher.current.toggleAudio()
  }

  function toggleStreaming() {
    mouseMove();
    setStreaming(!streaming);
    publisher.current.toggleStreaming()
  }


  function togglePushToTalk() {
    mouseMove()
    if (!pushToTalk) {
      setPushToTalk(true)
    } else {
      setPushToTalk(false)
    }
  }

  // TODO: keyCode === userMeta.pushToTalk.keyCode
  function downHandler({ key }) {
    if (key === 'Control') {
      setMuted(false)
    }
  }

  function upHandler({ key }) {
    if (key === 'Control') {
      setMuted(true)
    }
  }

  useEffect(() => {
    if (pushToTalk) {
      window.addEventListener('keydown', downHandler)
      window.addEventListener('keyup', upHandler)
      return () => {
        window.removeEventListener('keydown', downHandler)
        window.removeEventListener('keyup', upHandler)
      }
    }
  }, [pushToTalk]);

  function toggleDeafen() {
    mouseMove();
    setDeafen(!deafen);
  }

  function toggleScreenSharing() {
    mouseMove();
    setScreenSharing(!screenSharing);
  }

  function devices(data1, data2) {
    let audioDevices = [];
    let videoDevices = [];
    for (const element of data2) {
      if (element.kind === "audioInput") {
        setAudioInputDevices(audioInputDevices => [...audioInputDevices, { deviceId: element.deviceId, label: element.label }]);
        if (element.deviceId === "default") {
          setSelectedAudioId(element.deviceId);
          props.dispatch.videoCall.setAudioDevice(element);
        }
        audioDevices.push(element)
      } else if (element.kind === "videoInput") {
        setVideoInputDevices(videoInputDevices => [...videoInputDevices, { deviceId: element.deviceId, label: element.label }]);
        if (selectedVideoId === "") {
          setSelectedVideoId(element.deviceId);
          props.dispatch.videoCall.setVideoDevice(element);
        }
        videoDevices.push(element)
      }
    }

    props.dispatch.videoCall.addVideoDevices(videoDevices);
    props.dispatch.videoCall.addAudioDevices(audioDevices);
  }

  function addNewInvite() {
    props.dispatch.server.addInvite(props.videoCalls.sourceId, "publisher", 1);
  }

  function handleModalDisplay(message) {
    if (resetModalTimeout != null) {
      clearTimeout(resetModalTimeout);
      setResetModalTimeout(null);
      toggleElementCSSClasses("videoErrorDiv", "fade-in-modal", "invisible-modal");
    }
    setLastErrorContent(message);
    toggleElementCSSClasses("videoErrorDiv", "invisible-modal", "fade-in-modal");
    let resetModal = setTimeout(() => {
      toggleElementCSSClasses("videoErrorDiv", "fade-in-modal", "invisible-modal");
      setResetModalTimeout(null);
    }, 1000 * 4);
  }

  function childErrorHandler(data) {
    if (data.detail.errorEvent === "videoError") {
      setStreaming(false);
      setScreenSharing(false);
      handleModalDisplay(data.detail.message);
    }
  }

  useEffect(() => {
    off("startVideoCall:openInvite");
    off("startVideoCall:noInvite");
    off("VideoCall:ChildErrorHandler");
    on("startVideoCall:openInvite", startWithInvite);
    on("startVideoCall:noInvite", startWithoutInvite);
    // on("VideoCall:ChildErrorHandler", childErrorHandler);
    console.log("component unmounted and remounted...");
    toggleElementCSSClasses("videoErrorDiv", "fade-in-modal", "invisible-modal");
  }, [])


  useEffect(() => {
    if (props.videoCalls.joinToken != '') {
      console.log("_______________________________________________________________")
      console.log("recieved token: ");
      console.log(props.videoCalls.joinToken)
      console.log(counter)
      setCounter(counter + 1);
      onCall(props.videoCalls.sessionId, props.videoCalls.joinToken);
    }
  }, [props.videoCalls.callStarter])

  // TODO: constraint


  function gotStream(stream) {
    // window.stream = stream; // make stream available to console
    // videoElement.srcObject = stream;
  }

  // audioInputSelect.onchange = start;
  // audioOutputSelect.onchange = changeAudioDestination;

  // videoSelect.onchange = start;

  useEffect(() => {
    if (props.AS?.video?.state?.videoStreamOptions) {

      if (streamObject && props.videoCalls.joinToken != '') {
        streamObject.getTracks().forEach(track => {
          track.stop();
        });

        const audioSource = props.AS.video.state.videoStreamOptions.audioSelection.deviceId;
        const videoSource = props.AS.video.state.videoStreamOptions.videoSelection.deviceId;

        let newStreamOptions = {
          audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
          video: { deviceId: videoSource ? { exact: videoSource } : undefined }
        }

        setStreamOptions(newStreamOptions)

        const newStreamObject = navigator.mediaDevices.getUserMedia(newStreamOptions).then((stream) => {
          setStreamObject(stream)
        })

        setRestartCall(false)
      }
    }
  }, [props.AS?.video?.state?.videoStreamOptions])

  useEffect(() => {
    console.warn("STREAM OBJECT CHANGED")
    console.log(streamObject)
    if (streamObject) {
      let audioTrack = streamObject.getAudioTracks()
      if (audioTrack && audioTrack[0]) {
        console.log(audioTrack)
      }
    }
  }, [streamObject])

  useEffect(() => {
    if (props.videoCalls.lastRecievedInvite != "") {
      let host = window.location.hostname;
      let invite = "https://paa-frontend-staging.azurewebsites.net/chat2?vid="
      if (host === "localhost") {
        invite = "http://localhost:3000/chat2?vid="
      }
      alert("recieved a new invite: " + invite + props.videoCalls.sourceId + ":" + props.videoCalls.lastRecievedInvite);
    }
  }, [props.videoCalls.recievedInvite]);


  const resizeVideoDiv = (e) => {
    if (e.preventDefault) e.preventDefault();
    document.addEventListener("mouseup", resizeVideoDivMouseUp, true);
    document.addEventListener("mousemove", resizeVideoDivMouseMove, true);
  };

  const resizeVideoDivMouseUp = () => {
    document.removeEventListener("mouseup", resizeVideoDivMouseUp, true);
    document.removeEventListener("mousemove", resizeVideoDivMouseMove, true);
  };

  const minDrawerHeight = 0;
  const maxDrawerHeight = 500000000;

  const resizeVideoDivMouseMove = useCallback((e) => {
    const newHeight = e.clientY - document.body.offsetTop;
    if (newHeight > minDrawerHeight && newHeight < maxDrawerHeight) {
      setVideoElementHeight(newHeight);
    }
  }, []);

  return (
    <div style={{
      position: 'absolute',
      zIndex: '1005',
      top: 0,
    }}>
      <script src="https://static.opentok.com/v2/js/opentok.min.js"></script>

      <Portal>

        <div
          ref={fullscreenDiv}
          onMouseMove={mouseMove}
          onMouseLeave={() => { setToolsVisible(false) }}
          onMouseEnter={() => { setToolsVisible(true) }}
          style={{
            background: theme.palette.background.paper,
            height: videoElementHeight,
            left: props.windowRef?.current?.getBoundingClientRect()?.left,
            width: props.windowRef?.current?.clientWidth,
            position: "absolute",
            padding: "0px 10px 0px 10px",
            zIndex: 10,
            display: ("" + visible) === "true" ? 'block' : 'none'
          }}>

          {/* <div
      style={{
        background: "#080808",
        height: "45vh",
        left: props.windowRef.current.getBoundingClientRect().left,
        width: props.windowRef.current.clientWidth,
        position: "absolute",
        padding: "0px 10px 0px 10px",
        zIndex: 10,
        display: visible ? 'block' : 'none'
      }}
    > */}

          {/* {validatedDevices && session !== null
            ? <VideoRendererMain
              fullscreen={isFullscreen}
              screenSharing={screenSharing}
              streaming={streaming}
              playingVideo={screenSharing || streaming}
              audio={!muted}
              videoCalls={props.videoCalls}
              session={session}
              sessionActive={inSession}
              currentJoinToken={currentJoinToken}
              ownUsername={props.ownUsername}
              streamOptions={streamOptions}
              setStreamObject={setStreamObject}
            />
            : null} */}


          <video-publisher width="360px" height="240px" ref={publisher}></video-publisher>

          <screen-share start-text="start" stop-text="stop" width="300px" height="240px" ref={screenshare}></screen-share>

          <video-subscribers width="360px" height="240px" ref={subscribers}></video-subscribers>

          <button onClick={toggleVideo}>
            toggle Video
          </button>
          <button onClick={toggleAudio}>
            toggle Audio
          </button>

          <StreamControls
            toolsVisible={toolsVisible}
            AS={props.AS}
            videoCalls={props.videoCalls}
            toggleStreaming={toggleStreaming}
            toggleScreenSharing={toggleScreenSharing}
            screenSharing={screenSharing}
            toggleMute={toggleMute}
            toggleDeafen={toggleDeafen}
            endCall={endCall}
            addNewInvite={addNewInvite}
            togglePushToTalk={togglePushToTalk}
            pushToTalk={pushToTalk}
            errorMessage={errorMessage}
            isFullscreen={isFullscreen}
            handleExitFullscreen={handleExitFullscreen}
            setIsFullscreen={setIsFullscreen}
            streaming={streaming}
            muted={muted}
            deafen={deafen}
          />

          <VideoErrorDiv
            lastErrorContent={lastErrorContent}
          />

          <ResizeBar resizeVideoDiv={resizeVideoDiv} />

        </div>
      </Portal>
    </div>
  )
}

export default preloadScript(connect(makeMapStateToProps, mapDispatchToProps)(withAS(StreamPage)));
