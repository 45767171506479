import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

const TextGradient = function (props) {
    const gradientStyle = `
    .peachy {
        animation: spin 15s linear infinite;
        transform-origin: center;
    }
    .nightsky-mask {
        animation: pulse 20s ease-in infinite alternate;
        transform-origin: center;
    }
    @keyframes spin {
        0% { transform: rotateZ(0deg) scale3d(2,2,0) }
        100% { transform: rotateZ(-360deg) scale3d(2,2,0)  }
    }
    @keyframes pulse {
        0% { transform: scale(1) rotate(0deg); opacity: 1 }
        80% { transform: scale(1.5) rotate(20deg); opacity: 1 }
        100% { transform: scale(1.7) rotate(20deg); opacity: 0 }
    }`

    const rightGrad = <div></div>

    const gradientSvg = (
        <svg
            className="testsvg"
            preserveAspectRatio="none"
            viewBox="0 0 1 1"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <style>{gradientStyle}</style>

            <defs>
                <radialGradient id="nightsky">
                    <stop offset="0.5" stop-color="#000000" stop-opacity="0" />
                    <stop offset="0.7" stop-color="#001c4c" stop-opacity="1" />
                </radialGradient>
                <linearGradient id="peachy">
                    <stop offset=".3" stop-color="#ff7000"></stop>
                    <stop offset=".6" stop-color="#ff246d"></stop>
                </linearGradient>
            </defs>

            <g>
                <rect
                    className="peachy"
                    x="0"
                    y="0"
                    width="1"
                    height="1"
                    fill="url(#peachy)"
                ></rect>
                <ellipse
                    cx="0"
                    cy="0.6"
                    rx="1.8"
                    ry="1.1"
                    className="nightsky-mask"
                    fill="url('#nightsky')"
                />
            </g>
        </svg>
    )

    function getStaticMarkup(svgComponent) {
        return renderToStaticMarkup(svgComponent)
    }

    function escapeForUrl(svgComponent) {
        let svgMarkup = getStaticMarkup(svgComponent)
        return encodeURIComponent(svgMarkup)
    }

    function generateSvgBackground(svgComponent) {
        let htmlString = renderToStaticMarkup(svgComponent)
        let escapedSvgUri = encodeURIComponent(htmlString)
        return escapedSvgUri
    }

    const backgroundGradientStyle = `
        .text-box {
            background-image: url("data:image/svg+xml;utf8,${generateSvgBackground(
                gradientSvg
            )}");
            background-attachment: fixed;
            background-size: cover;
            // background-size: 100vw 100vh;
        }
    `

    return <style>{backgroundGradientStyle}</style>
}

export default TextGradient
