import { theme } from 'react-contexify'

export const MenuCustomStyle = (mode, palette) => ({
    MenuCustom: {
        defaultProps: {
            theme: mode.mode === 'dark' ? theme.dark : theme.light,
        },
        styleOverrides:{"nothing":{}},altStyleOverrides: {
            root: {},
            modalMenu: {},
        },
    },
})
