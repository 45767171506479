import React, { useEffect, useState } from 'react'
import { withAuth } from '../_contexts/withAuth/index.js'
import withAPI from '../_wrappers/withAPI/index.js'
import Onboard from './Onboard/index.js'
import LostPassword from './LostPassword/index.js'
import Login from './Login/index.js'
import { useHistory } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import centeredContent from '../_design/centeredContent'
import { connect } from "react-redux";
import { statePropMapper } from "../../redux/process/selectorProcess";
import { RS1 } from "../../redux/actions";
import { withAS } from "../_contexts/withAppState";
import { StyledDiv } from '../Chat/Styled/index.js'

const makeMapStateToProps = statePropMapper([
    RS1.store('_loaded', '_loaded'),
])


const Auth = function (props) {
    const history = useHistory()
    const [page, setPage] = useState('login')
    const [fbAuth, setFbAuth] = useState(false)
    const [isInitialized, setIsInitialized] = useState(false)
    const [isOnboarded, setIsOnboarded] = useState(true)
    const [formState, setFormState] = useState({})
    const fieldChange = props.fieldChangeCreator(setFormState, formState)
    const inputProps = {
        fieldChange: fieldChange,
        errorData: props.errorData,
        errorStatus: props.errorStatus,
        method: 'login',
    }
    if (props.auth.auth && !fbAuth) {
        setFbAuth(true)
    }
    useEffect(() => {
        if (fbAuth === true) {
            if (props._loaded) {
                console.error(props)
                history.push('/chat2')
                setIsInitialized(true)
            } else {
                setIsOnboarded(false)
                if (props.AS.nav.state.page === "signUp") {
                    setPage('onboard')
                }
                if (props.AS.nav.state.page === "signIn") {
                    setPage('login')
                }
            }
        } else {
            if (props.auth.auth) {
                props.AS.nav.ops.setPage("chat")
            } else {
                if (props.AS.nav.state.page === "signUp") {
                    setPage('onboard')
                }
                if (props.AS.nav.state.page === "signIn") {
                    setPage('login')
                }
            }
            setIsInitialized(true)
        }
    }, [props._loaded, fbAuth])

    useEffect(() => {
        if (isOnboarded === false) {
            setPage('onboard')
        }
    }, [isOnboarded])

    if (isInitialized) {
        return (
            <StyledDiv types={['signupFlexboxOuter', 'signupOuter']}>
                {page === 'login' ? (
                    <Login changePage={setPage} {...props} />
                ) : null}
                {page === 'onboard' ? <Onboard {...props} /> : null}
                {page === 'lostPassword' ? <LostPassword {...props} /> : null}
            </StyledDiv>
        )
    } else {
        return (
            <StyledDiv
                types={['_minFVH']}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                }}>
                <CircularProgress />
            </StyledDiv>
        )
    }
}

// const AuthWithLayout = centeredContent(connect(makeMapStateToProps, null)(Auth), '', '15vh')
const AuthWithLayout = connect(makeMapStateToProps, null)(Auth)

export default withAuth(withAPI(withAS(AuthWithLayout), 'auth'))
