import React, { useState } from 'react'
import { Menu, Item, Separator, Submenu } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.css'
import { connect } from 'react-redux'
import { dispatchMappers } from '../../../../../redux/process/actionProcess'
import { SA1 } from '../../../../../hs/requestGlobals.mjs'
import { MenuCustom } from '../../../Styled'

const mapDispatchToProps = dispatchMappers([SA1.leaveServer])

function ServerNavMenu(props) {
    return (
        <MenuCustom id={props.menuId}>
            <Item
                onClick={() => {
                    props.dispatch.server.leaveServer(
                        props.selectedItem.key,
                        'deleteME'
                    )
                }}
            >
                Leave Server
            </Item>
            <Item onClick={props.handleItemClick}>Privacy</Item>
        </MenuCustom>
    )
}

export default connect(null, mapDispatchToProps)(ServerNavMenu)
