import React from 'react'
import SampleDataLayer from './DataLayers/SampleDataLayer'
import MessageDataLayer from './DataLayers/MessageDataLayer'
import AppointmentDataLayer from './DataLayers/AppointmentDataLayer'
import SubscriptionDataLayer from './DataLayers/SubscriptionDataLayer'
import CommunityDataLayer from './DataLayers/CommunityDataLayer'
import UserDataLayer from './DataLayers/UserDataLayer'

import { testCardPackage } from './Displays/TestDisplay'
import { appointmentCardPackage } from './Displays/AppointmentDisplay'
import { chatMessageCardPackage } from './Displays/ChatMessageDisplay'
import { communityCardPackage } from './Displays/CommunityDisplay'
import { userCardPackage } from './Displays/UserDisplay'
import { threadMessageCardPackage } from './Displays/ThreadMessageDisplay'
import { subscriptionCardPackage } from './Displays/SubscriptionDisplay'
import { timelineMessageCardPackage } from './Displays/TimelineMessageDisplay'
import { blogMessageCardPackage } from './Displays/BlogMessageDisplay'

// # Images in Sidebar, Images in Chat, Text in Chat, etc.
const UniversalCardDisplay = {
    test: testCardPackage,

    appointment: appointmentCardPackage,
    community: communityCardPackage,
    user: userCardPackage,

    chat: chatMessageCardPackage,
    thread: threadMessageCardPackage,
    timeline: timelineMessageCardPackage,
    blog: blogMessageCardPackage,

    subscription: subscriptionCardPackage,
}

const createCard = (Component, displayTemplate) => (props) => {
    const { requirements, CardDisplay } = UniversalCardDisplay[displayTemplate]

    return (
        <Component requirements={requirements} {...props}>
            <CardDisplay />
        </Component>
    )
}

export const UniversalCardObject = {
    sample: createCard(SampleDataLayer, 'test'),

    appointment: createCard(AppointmentDataLayer, 'appointment'),
    community: createCard(CommunityDataLayer, 'community'),
    user: createCard(UserDataLayer, 'user'),

    chatMsg: createCard(MessageDataLayer, 'chat'),
    threadMsg: createCard(MessageDataLayer, 'thread'),
    timelineMsg: createCard(MessageDataLayer, 'timeline'),
    blogMsg: createCard(MessageDataLayer, 'blog'),

    subscription: createCard(SubscriptionDataLayer, 'subscription'),

    // chatMsg: createCard(MessageDataLayer, 'chat'),
    // threadMsg: createCard(MessageDataLayer, 'thread'),
    // chatMsg: createCard(MessageDataLayer, 'chat'),
}

const GenericCard = function (props) {
    // Universal controllers required for every possible card can be put in this component.
    const Card = UniversalCardObject[props.cardType]
    return <Card displayMode={props.displayMode} {...props} />
}

export default GenericCard
