import {createSlice, createAction} from '@reduxjs/toolkit'
import {createCachedSlice} from '../cache/createCachedSlice.js'

const integrations = createCachedSlice({
    name: 'integrations',
    initialState: {nativeIntegrationsData: {}, integrationData: {}},
    reducers: {
        addNativeIntegrationKeys: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                const integrationKeys = action.payload
                return {
                    ...state, nativeIntegrationsData: integrationKeys
                }
            },
        },
        handleIntegrationData: {
            cached: false,
            parameters: [],
            description: '',
            reduce: (state, action) => {
                const {id, content} = action.payload
                return {
                    ...state,
                }
            },
        },
    },
})

export const integrationsSlice = integrations
export const integrationActions = integrations.actions
export const addNativeIntegrationKeys = integrations.actions.addNativeIntegrationKeys
export const handleIntegrationData = integrations.actions.handleIntegrationData
export const integrationsReducer = integrations.reducer
