import IconButton from '@mui/material/IconButton'
import React from 'react'
import { RA1 } from '../../../../../redux/actions.js'
import { connect } from 'react-redux'
import {
    ArrowForward,
    DoNotDisturb,
    Group,
    GroupAdd,
    Outbox,
} from '@mui/icons-material'
import { dispatchMappers } from '../../../../../redux/process/actionProcess.js'

const mapDispatchToProps = dispatchMappers([RA1._appState.updateAppState])

const ThreadWatcherNavFooter = function (props) {
    return (
        <div>
            {/* <IconButton
                color="primary"
                onClick={() => {
                    props.setMenu('user')
                }}
                size="large"
            >
                <ArrowForward />
            </IconButton> */}
        </div>
    )
}

export default connect(null, mapDispatchToProps)(ThreadWatcherNavFooter)
