import React, { useEffect } from 'react'
import { RA1, RS1 } from '../../../../redux/actions.js'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import { statePropMapper } from '../../../../redux/process/selectorProcess.js'
import { dispatchMappers } from '../../../../redux/process/actionProcess.js'
import { SearchHeader } from './SearchHeader'
import { Popover } from '@mui/material'
import useState from 'react-usestateref'
import ChatMessage from '../../ChatWindow/ChatMessage'
import { parseMessage } from '../../ChatWindow/_lib/messageParser.js'
import { ClickAwayListener } from '@mui/material'
import SearchDisplay from './SearchDisplay/index.js'
import { ListItemCustom, StyledDiv, TextFieldCustom } from '../../Styled'

const makeRenderRowMessage = (users) => {
    return function renderRowMessage(props) {
        let messageData = parseMessage(props.data[props.index].message)
        let messageStyling = messageData.styling
        let messageUrls = messageData.urls
        let user = users[props.data[props.index].uId]
        return (
            <ListItemCustom
                style={props.style}
                key={props.index}
                component="div"
                disablePadding
                onClick={() => { }}
            >
                <ChatMessage
                    oldStyle={{}}
                    server={props.server}
                    message={props.data[props.index].message}
                    hightlightMessage={false}
                    messageStyling={messageStyling}
                    urls={messageUrls}
                    user={user}
                />
            </ListItemCustom>
        )
    }
}

const mapDispatchToProps = dispatchMappers([
    RA1._appState.closeModalMenu,
    RA1._appState.changePage,
    RA1._appState.openModalMenu,
    SA1.messageSearch,
    SA1.messageLoad,
])
const makeMapStateToProps = statePropMapper([
    // RS1.getChannels("channels"),
    RS1.getServer('serverData'),
    RS1.store('_appState', '_appState'),
    RS1.store('users', 'users'),
    RS1.store('channels', 'channels'),
    RS1.store('_userId', '_userId'),
])

const SearchNav = function (props) {
    const [mode, setMode] = useState('search')
    const [searchBarDisabled, setSearchBarDisabled] = useState(false)
    const setSearchBarState = (value) => {
        setSearchBarDisabled(value)
    }

    const [inputValue, setInputValue] = useState('')
    const [submittedValue, setSubmittedValue] = useState('')
    const [searchConditions, setSearchConditions] = useState({})
    const [messages, setMessages, messagesRef] = useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [visibleMessages, setVisibleMessages, visibleMessagesRef] =
        useState(null)
    const [messageRange, setMessageRange, messageRangeRef] = useState([0, 10])
    const [conditionToDelete, setConditionToDelete] = useState('')
    const [clearSuggestions, setClearSuggestions] = useState(false)
    const [currentCondition, setCurrentCondition] = useState('')
    const [currentConditionPartialInput, setCurrentConditionPartialInput] =
        useState('')

    const loadMessageOperation = (messageId) =>
        props.dispatch.server.messageLoad(
            props.server,
            props.channel,
            messageId,
            'center',
            'other',
            'newOther'
        )

    const updateMessageRange = (direction) => {
        if (direction === 'older') {
            if (messagesRef.current[0] > 0) {
            }
        } else {
            if (messagesRef.current[1] < messagesRef.current.length) {
            }
        }
    }
    const addSearchCondition = (conditionPrefix, conditionValue) => {
        setSearchConditions((searchConditions) => {
            let newConditions = { ...searchConditions }
            newConditions[conditionPrefix] = conditionValue
            return newConditions
        })
        setClearSuggestions(true)
    }

    const handleSubmit = (e, inputString, conditions) => {
        props.dispatch.server.messageSearch(
            props.server,
            props.channel,
            inputString,
            conditions
        )
        setSubmittedValue(inputString)
        setSearchConditions({})
        setInputValue('')
        setMode('results')
    }
    useEffect(() => {
        if (clearSuggestions === true) {
            setInputValue(inputValue.replace(conditionToDelete, ''))
            setCurrentCondition('')
            setCurrentConditionPartialInput('')
            setClearSuggestions(false)
        }
    }, [clearSuggestions])

    useEffect(() => {
        if (props._appState?.searchResults?.[props.server]?.messages) {
            setMessages(props._appState.searchResults[props.server].messages)
        }
    }, [props._appState])

    const handleChange = (e) => {
        if (typeof e.target.value === 'string') {
            setAnchorEl(e.currentTarget)
            let matchingConditions = [
                ...e.target.value.matchAll('([^\\s]*)(:)([^\\s]*)'),
            ]
            if (
                e.target.value.slice(-1) === ' ' &&
                matchingConditions.length > 0
            ) {
                setConditionToDelete(matchingConditions[0][0])
                setClearSuggestions(true)
            } else {
                setInputValue(e.target.value)
            }
            if (matchingConditions.length > 0) {
                setCurrentCondition(matchingConditions[0][1])
                setCurrentConditionPartialInput(matchingConditions[0][3])
            } else {
                setCurrentCondition('')
                setCurrentConditionPartialInput('')
            }
        }
    }
    let displayedConditions = []
    for (const key in searchConditions) {
        if (searchConditions.hasOwnProperty(key)) {
            displayedConditions.push(
                <div>
                    {key}:{searchConditions[key]}
                </div>
            )
        }
    }

    return [
        <div>
            <SearchHeader
                name={props.serverData.name}
                serverId={props.server}
                changePage={props.dispatch._appState.changePage}
            />
        </div>

        ,

        <StyledDiv types={['searchBarOuterDiv']}>
            <TextFieldCustom
                types={['searchbar']}
                disabled={searchBarDisabled}
                id="outlined-static"
                fullWidth={true}
                value={inputValue}
                margin="dense"
                size="small"
                inputProps={{ style: { padding: 10 } }}
                label={'looking for something?'}
                InputLabelProps={{
                    disableAnimation: false,
                    shrink: false,
                }}
                onChange={(e) => handleChange(e)}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                        handleSubmit(e, inputValue, searchConditions)
                    }
                }}
            />
            <Popover
                open={currentCondition !== ''}
                anchorEl={anchorEl}
                onClose={() => { }}
                onKeyDown={(e) => {
                    if (e.keyCode === 27) {
                        setCurrentCondition('')
                        setCurrentConditionPartialInput('')
                    }
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <ClickAwayListener
                    onClickAway={(e) => {
                        setCurrentCondition('')
                        setCurrentConditionPartialInput('')
                    }}
                >
                    <div>
                        <Button
                            onClick={() => {
                                setClearSuggestions(true)
                            }}
                        >
                            Clear
                        </Button>
                        {/*<SearchSuggestionsDiv prefix={currentCondition}*/}
                        {/*    partialInput={currentConditionPartialInput}*/}
                        {/*    addCondition={addSearchCondition}*/}
                        {/*    {...props} />*/}
                    </div>
                </ClickAwayListener>
            </Popover>

            {displayedConditions}
        </StyledDiv>

        ,

        <div>
            <SearchDisplay
                searchBarDisabled={searchBarDisabled}
                setSearchBarDisabled={setSearchBarDisabled}
                mode={mode}
                messages={messages}
                // props only used by SearchResults
                loadMessageOp={loadMessageOperation}
                setMode={setMode}
                submittedValue={submittedValue}
                users={props.users}
            />
        </div>
    ]
}

export default connect(makeMapStateToProps, mapDispatchToProps)(SearchNav)
