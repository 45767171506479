const makeStatusGradient = (background, status) => {
    return {
        background:
            'linear-gradient(150deg, ' + background + ' 70%, ' + status + ')',
        borderRadius: '0px 0px 50px 0px',
    }
}

export const ListButtomCustomStyle = (mode, palette) => ({
    ListItemButtonCustom: {
        defaultProps: { dense: true },
        styleOverrides:{"nothing":{}},altStyleOverrides: {
            root: {
                paddingTop: '3px',
                paddingBottom: '3px',
            },
            user:{
                height:"42px"
            },
            roleCategory: {
                paddingTop: '7px',
                paddingBottom: '7px',
            },
        },
    },
})
