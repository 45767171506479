import {createCachedSlice} from '../cache/createCachedSlice.js'
import {addNewMessage, searchResultsClient, updateCatalog} from "./actions";

const productsSlicePre = createCachedSlice({
    name: 'products',
    initialState: {},
    reducers: {
        addImage: {
            cached: false,
            parameters: ['channelId', 'image', 'url'],
            description: '',
            reduce: (state, action) => {
                // const { channelId, message } = action.payload
                return {}
            },
        },
    },
    extraReducers: {
        [updateCatalog]: {
            cached: false,
            parameters: [],
            reduce: (state, action) => {
                let newState = {...state}
                let {serverId, catalog} = action.payload
                newState[serverId] = catalog
                return newState
            },
        }
    },
})

export const productsActions = productsSlicePre.actions
export const products = productsSlicePre.reducer
export const productsSlice = productsSlicePre
