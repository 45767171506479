import React from 'react'
import GeneralManagement from './GeneralManagement'
import RolePerms from './RolePerms'
import Log from './Log'
import IntegrationsManagement from './IntegrationsManagement'
import Config from './Config'
import EmojiManagement from './Emoji'
import { statePropMapper } from '../../../redux/process/selectorProcess'
import { RS1 } from '../../../redux/actions'
import { connect } from 'react-redux'
import Products from './Products'
import BanManagement from './Bans'
import {withAS} from "../../_contexts/withAppState";

const makeMapStateToProps = statePropMapper([RS1.getServerManager('manager')])

const ManagementPage = (props) => {
    switch (props.AS.managementPage) {
        case 'settings':
            return <GeneralManagement {...props} />
        case 'emoji':
            return <EmojiManagement {...props} />
        case 'log':
            return <Log {...props} />
        case 'config':
            return <Config {...props} />
        case 'products':
            return <Products {...props} />
        case 'bans':
            return <BanManagement {...props} />
        case 'roles':
            return <RolePerms {...props} />
        case 'integrations':
            return <IntegrationsManagement {...props} />
        default:
            return <GeneralManagement {...props} />
    }
}

export default connect(makeMapStateToProps, null)(withAS(ManagementPage))
