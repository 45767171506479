import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import './headerbuttons.css'
import {SIGNIN, SIGNUP} from "../../../../constants/env";
import {withAS} from "../../../_contexts/withAppState";

const useStyles = makeStyles({
    menubutton: {
        clipPath: 'polygon(100% 0%, 12% 0%, 0% 100%, 88% 100%)',

        height: '5em',
        marginRight: '0.4em',
        marginLeft: '0.3em',

        borderRadius: 0,
        padding: '0.8em',
        paddingRight: '1.1em',
        color: '#fff',
        textTransform: 'none',

        '&:hover': {
            color: '#fff',
            backgroundColor: '#fdc',
        },
        ['@media (pointer: none), (pointer: coarse)']: {
            lineHeight: '1',
            fontSize: '0.5em',
        },
    },
    buttonText: {
        top: 0,
        marginLeft: '0.5em',
    },
})

const HeaderButtons = function (props) {
    const classes = useStyles()
    const history = useHistory()

    return (
        <div className="header-buttons">
            <Button
                className={classes.menubutton}
                variant="text"
                onClick={() => {
                    props.AS.nav.ops.setPage('signUp')
                    history.push('/auth2')
                }}
            >
                <span className={classes.buttonText}>{SIGNUP}</span>
            </Button>

            <div className="button-separator"></div>

            <Button
                className={classes.menubutton}
                variant="text"
                onClick={() => {
                    props.AS.nav.ops.setPage('signIn')
                    history.push('/auth2')
                }}
            >
                <span className={classes.buttonText}>{SIGNIN}</span>
            </Button>
        </div>
    )
}

export default withAS(HeaderButtons)
