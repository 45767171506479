import React, { useEffect } from 'react'
import useState from 'react-usestateref'
import { connect } from 'react-redux'
import { dispatchMappers } from '../../../../../redux/process/actionProcess'
import { statePropMapper } from '../../../../../redux/process/selectorProcess'
import { RA1, RS1 } from '../../../../../redux/actions'
import { SA1 } from '../../../../../hs/requestGlobals.mjs'
// import PaymentForm from "./PaymentForm";
import { ButtonCustom, TypographyCustom, vDH } from '../../../Styled'
import ProductCard from '../../../../_reusable/Cards/ProductCard/ProductCard'
import ElementPayment from './PaymentElement'
import { useTheme } from '@mui/material'
import { rgbaToOpaque } from '../../../../../theme/util'
import SuccessPage from '../SuccessPage'
import { updateAPI } from '../../../../../redux/reducers/actions'
import { withAS } from "../../../../_contexts/withAppState";

//TODO: Make ABSOLUTELY SURE that we're getting a new, clean PaymentIntent EVERY TIME we click "buy"
// on the product list.
//TODO: may need _activeServer and _activeChannel to record what the transaction is for on the backend.
//TODO: utilize options.appearance={theme:'stripe'}

const makeMapStateToProps = statePropMapper([
    RS1.store('_appState', '_appState'),
])

const mapDispatchToProps = dispatchMappers([SA1.createPaymentIntent])

const Purchase = function (props) {
    // #
    const [succeeded, setSucceeded] = useState(false)
    const [options, setOptions, optionsRef] = useState(null)
    const theme = useTheme()
    const palette = theme.palette

    // #
    const reportProduct = () => { }
    const onSuccess = () => {
        setSucceeded(true)
        props.updateAPI('socket', 'stripePayment', {
            actionType: 'client/success',
        })
        if (props.onSuccess) {
            props.onSuccess()
        }
    }

    // #
    // TODO: if the product is a subscription, this must behave differently.
    // if it's a subscription, the payment element must be created first. Then, a payment method must be created using
    // the payment element, after which that payment method must be attached to the customer and used to create the
    // subscription. Only then may the client secret be used to confirm the payment.
    // TODO: learn how to create a payment method with payment elements.
    useEffect(() => {
        // create client secret on first render

        // TODO: TEST CODE REMOVE
        console.warn("////////// NEW PAYMENT INTENT MODAL DATA ////////")
        console.log(props.AS.modals.state.modalData)
        // test code end

        // props.AS.modals.state.modalData.selectedProduct.type === 'calendar'
        // props.AS.modals.state.modalData.additionalProductMetadata._id

        props.dispatch.server.createPaymentIntent(
            props.product.id,
            props.AS.activeServer,
            props.AS.activeChannel,
            props.AS.modals.state.modalData
        )
    }, [])

    useEffect(() => {
        // grab client secret when it's available, put it in options for Elements
        if (props._appState?.clientSecret) {

            // # test code end
            // let textColor = palette.text.primary.slice(0, 3) == "rgb" ?
            //     rgbaToOpaque(palette.text.primary) :


            setOptions({
                clientSecret: props._appState.clientSecret,
                appearance: {
                    theme: 'stripe',
                    variables: {
                        colorBackground: palette.background.primary,
                        colorText: rgbaToOpaque(palette.text.primary),
                        colorTextPlaceholder: palette.text.flair,
                        colorDanger: palette.error[palette.mode],
                        fontFamily: 'Ideal Sans, system-ui, sans-serif',
                        spacingUnit: '2px',
                        borderRadius: '0px 0px 70px 0px',

                        // variables: {
                        //     colorBackground: palette.background.primary,
                        //     colorText: rgbaToHex(palette.text.primary),
                        //     colorTextPlaceholder: palette.text.flair,
                        //     colorDanger: palette.error[palette.mode],
                        //     fontFamily: 'Ideal Sans, system-ui, sans-serif',
                        //     spacingUnit: '2px',
                        //     borderRadius: '0px 0px 70px 0px',
                        // },
                    },
                },
            })
        }
    }, [props._appState])

    //#

    // TODO: backbutton should reload chat if it's a onetime
    // TODO: otherwise.. close the modal menu? show success screen?

    // case 'success':
    // return (

    //     )

    // TODO: option to give as gift to someone else in the server
    return (
        <div style={{ minWidth: vDH(60) }}>
            <div style={{ margin: '5%' }}>
                <ProductCard inBuyMenu={true} {...props.product} />
                <br />

                {props?.isCreator ? (
                    <div>This is where the payment element would go.</div>
                ) : (
                    <div>
                        {optionsRef.current?.clientSecret ? (
                            <ElementPayment
                                submit={props.submit}
                                options={options}
                                onSuccess={onSuccess}
                            />
                        ) : (
                            <div></div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(Purchase))
