
export const CardStyleOverrides = (mode, palette, animations, typography) => {
    return {

        cardTestBackground: {
            backgroundColor: palette.background.primary,
            height: '200vh',
            '@media (max-aspect-ratio: 4/3)': {
                height: '200dvh'
            }
        },

        threadBlogImg: { marginTop: '15px' },
        blogPostHeader: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        blogPostImg: (props) => ({
            margin: '0px 10px 0px 0px',
            width: props?.desktop ? '30px' : '15px',
            height: props?.desktop ? '30px' : '15px',
        }),
        blogPostUsername: {
            margin: 0,
        },

        blogSingleThreadMobile: {
            bottom: '3em',
        },

        bottomSmallerBorder: {
            '& > div': {
                // '--border-width-initial': 'calc(100% - 182px)',
                // 50px
                '--image-condtainer-width': 'calc(7.8vw + 50px)', //'calc(32px + 7.8vw)',
                '--border-width-initial': 'calc(100% - 100px)',
                // '--border-width-no-img-initial': 'calc(var(--border-width-initial) - 32px)',
                '--border-width-no-img': 'calc(var(--border-width-initial) - 7.8vw)',
                '--border-width-image': 'calc(var(--border-width-initial))',

                '&:after': {
                    content: '""',
                    height: '1px',
                    width: 'calc(90% - 50px)',

                    position: 'absolute',
                    left: 'calc(5% + 50px)',
                    bottom: 0,

                    background: palette.background.flair,
                },
            }
        },

        threadBorder: (props) => ({

            // '--border-width-initial': 'calc(100% - 182px)',
            // 50px
            '--image-condtainer-width': 'calc(7.8vw + 50px)', //'calc(32px + 7.8vw)',
            '--border-width-initial': 'calc(100% - 100px)',
            // '--border-width-no-img-initial': 'calc(var(--border-width-initial) - 32px)',
            '--border-width-no-img': 'calc(var(--border-width-initial) - 7.8vw)',
            '--border-width-image': 'calc(var(--border-width-initial))',

            '&:after': {
                content: '""',
                height: '1px',
                width: props?.hasFiles ? 'var(--border-width-image)' : 'var(--border-width-no-img)',

                position: 'absolute',
                // left: 'calc(124px)',
                left: props?.hasFiles ? '50px' : 'var(--image-condtainer-width)',
                bottom: 0,

                backgroundColor: palette.info.dark,
            },
        }),

        timelineOP: { // fake border - used to have dynamic border height/width
            // '--border-width-initial': 'calc(100% - 110px)',
            // '--border-width-initial': 'calc(100% - 182px)',
            // '--border-width-final': 'calc(var(--border-width-initial) - 3.2em)',

            // '&:after': {
            //     content: '""',
            //     height: '1px', //You can change this if you want smaller/bigger borders
            //     width: 'var(--border-width-final)',

            //     position: 'absolute',
            //     left: 'calc(124px + 1.6em)',
            //     bottom: 0, // If you want to set a smaller height and center it, change this value

            //     backgroundColor: palette.text.secondary, // The color of your border
            // },
        },
        timelineImg: { paddingTop: '15px' },
        timelineReply: {
            paddingLeft: '3em',
            border: '1px solid magenta',
        },
        generalCard: {
            // opacity: 0.5,
            backgroundColor: palette.background.secondary,
            borderBottom: '1px solid ' + palette.text.secondary,
        },
        blogMessageContainer: {
            padding: '0% 13%',
            background: 'transparent',
        },

        blogMessageMobile: {
            padding: '0% 4%',
            background: 'transparent',
        },
        blogMobilePaddingLeft: {
            paddingLeft: 0
        },
        apptListProto: {
            '& .apptListCard': {
                backgroundColor: palette.background.primary,
            },
        },
        apptList: {
            backgroundColor: palette.background.primary,
        },
        apptFullCard: {
            textAlign: 'center',
            padding: '1.3rem 2rem',
            backgroundColor: palette.background.primary,
        },
        apptHalfCard: {
            textAlign: 'center',
            padding: '0.1rem 2rem'
        },
        apptHalfInnerPadding: { paddingTop: '0.5em', paddingBottom: '0.5em' },
        apptDateTimeCompressed: { color: palette.text.flair },

        apptListTitle: {
            paddingBottom: '1.3rem'
        }, // 1.3rem
        apptListData: {
            paddingTop: '0rem',
            display: 'flex',
            justifyContent: 'center',
            background: 'transparent',
        },
        apptButtons: {
            width: '252px',
            background: 'transparent',
            display: 'flex',
            flexDirection: 'row',
        },
        apptListPagination: { '& ul': { display: 'flex', justifyContent: 'space-between' } },

        compressedAppt: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            textAlign: 'left',
            padding: '2em',
        },

        apptFullTitle: {
            width: '100%',
            padding: '0.5em',
            paddingBottom: '1.3em',
            borderBottom: '1px solid ' + palette.text.primary,
        },
        apptFullData: {
            display: 'flex',
            flexDirection: 'row',
            padding: '0.2rem 0px',
            paddingTop: '1.3em',
        },
        apptFullDatetime: {
            flex: '0 1 50%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'left',
        },
        apptFullButton: {
            flex: '0 1 50%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        apptFullColumn: {
            display: 'flex',
            flexDirection: 'column'
        },
        apptButtonMobile: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            '& p': {
                textAlign: 'center',
            }
        },

        fullCard: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',

            padding: '1em',
            borderRadius: "60px 0% 60px 0%",
        },
        cardTitle: {
            flex: '0 0 auto',
            textAlign: 'center',
            marginBottom: '0.5em',
            fontSize: '1.5em',
            color: palette.text.primary,
        },
        threadCardTitleCompressed: (props) => ({
            fontSize: '1.1em',
            '& > b': {
                color: props?.userColor && props.userColor.startsWith('rgba') ?
                    palette.text.flair :
                    props.userColor
            },
        }),
        cardImage: {
            flex: '1 0 auto',
        },
        cardData: {
            flex: '0 0 auto',
            marginTop: '0.5em',
            color: palette.text.secondary
        },

        mobileFull: {
            margin: '1vw',
            width: '98vw',
        },
        mobileImgFull: {
            width: 'calc(98vw-1em)',
            '& > img': {
                width: '100%',
                border:
                    palette?.thread?.border &&
                    `2px solid ${palette.text.flair}`,
            },
        },

        compressedCard: {
            position: 'absolute',
            top: '0',
            width: '100vw',
            height: '5em',
            padding: '0.5em',

            display: 'flex',
            flexDirection: 'row',
        },
        compressedThread: {
            justifyContent: 'space-between',
            alignItems: 'center',
            textAlign: 'left',
            padding: '2em',
        },
        titleCompressed: {
            textAlign: 'left',
            marginBottom: '0.1em',

            height: '1.5em',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        dataCompressed: {
            height: '2em',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },

        halfCard: {
            borderRadius: "25px 0% 25px 0%",
            height: '6.5em',
            width: 'calc(100% - 0.4em)',
            margin: '0.2em',
            padding: '0.5em',
            display: 'flex',
            justifyContent: 'flex-start',
        },
        halfImage: {
            flex: '0 0 5.5em',
            width: 'fit-content',
            '& > img': {
                objectFit: 'cover',
                height: '5.5em',
                width: '5.5em',
                borderRadius: "20px 0% 20px 0%",
                border:
                    palette?.thread?.border &&
                    `2px solid ${palette.text.flair}`,
            },
        },
        halfText: {
            flex: '0 1 auto',
            overflow: 'auto',
            height: '100%',
            width: '100%',
            paddingLeft: '0.7em',
            alignItems: 'flex-start',
            paddingRight: '5px'
        },
        halfData: {
            height: '3em',
            display: '-webkit-box',
            '-webkit-line-clamp': '2',
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },

        compressedDesktop: {
            height: '5em',
            width: '100%',
            padding: '0.5em',
            paddingLeft: '1.5em',
            paddingRight: '1.5em',
            borderRadius: "25px 0% 25px 0%",
            border: '1px solid ' + palette.text.secondary,
        },

        desktopFull: {
            paddingLeft: '2em',
            paddingRight: '2em',
            height: 'fit-content',
            width: 'fit-content',

            display: 'flex',
            flexDirection: 'column',
        },
        desktopImgFull: {
            display: 'inline-block',
            '& > img': {
                verticalAlign: 'top',
                width: '100%',
                marginBottom: '4.5em',
                maxHeight: 'calc(75vh - 3em)',
                border:
                    palette?.thread?.border &&
                    `2px solid ${palette.text.flair}`,
                '@media (max-aspect-ratio: 4/3)': {
                    maxHeight: 'calc(75dvh - 3em)'
                }
            },
        },
        desktopDataFull: {
            position: 'absolute',
            bottom: '0.5em',
            left: 0,
            width: 'inherit',
            height: '3em',
            display: '-webkit-box',
            '-webkit-line-clamp': '2',
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        desktopInner: {
            display: 'inline-block',
            position: 'relative',
        },



        messageCard: {
            textAlign: 'left',
            padding: '0px',
            overflow: 'hidden',
            position: 'relative'
        },
        bordered: {
            border: palette?.thread?.border ?
                `1px solid ${palette.text.flair}` :
                `none`,
        },
        tighterCurves: { borderRadius: "30px 0% 30px 0%" },
        timelinePadding: (props) => ({
            // '--left-right-margin': 'calc(10px)',
            '--left-right-padding': props?.desktop ? 'calc(40px + 4em)' : '0px',
            '--reply-extra': props?.desktop ? '2em' : '1.5em',
            '--reply-padding': 'calc(var(--left-right-padding) + var(--reply-extra))',

            background: palette.background.secondary,
            paddingTop: '7px',
            paddingLeft: props?.fullMsg ?
                props?.isReply ? 'var(--reply-padding)'
                    : 'var(--left-right-padding)'
                : '15px',

            paddingRight: props?.fullMsg ?
                props?.isReply ? 'var(--left-right-padding)'
                    : 'var(--left-right-padding)'
                : '3px',

            paddingBottom: '0px',

            margin: props?.fullMsg ?
                '5px 10px'
                : null,
            borderRadius: '5px',
        }),
        chatMsgPadding: { paddingRight: '1em' },


        threadCardContainer: (props) => ({
            width: '100%',
            position: 'absolute',
            backgroundImage: props?.thread?.pic ? 'url(' + props.thread.pic + ')' : 'none',
            backgroundPosition: 'top',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }),

        chatCardAvi: { marginLeft: '0px' },
        chatAviThreadMargin: { marginTop: '14px' },

        twoLineClamp: {
            paddingBottom: 'none',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        oneLineClamp: {
            // height: '1.7rem',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            paddingBottom: '0px',
        },

        messageTextStyle: (props) => ({
            overflowWrap: 'break-word',
            whiteSpace: 'pre-wrap',
            fontSize: (props.desktop) ? '16px' : '12px',
        }),

        threadCardTop: (props) => ({
            width: (props.half) ? 'auto' : '100%',
            padding: (props.half) ? '6px 0px' : '8px 12px',
            // backdropFilter: 'blur(5px)',
            backgroundColor: palette.background.primary + 'cc',
            display: 'flex',
            justifyContent: 'space-between',
        }),
        threadCardTopCompressed: {
            height: '100%',
            flex: '1 0 auto',
        },
        threadCardLeft: {
            flex: '1 0 auto'
        },
        threadCardRight: {
            flex: '0 1 fit-content',
            '& div': { flex: '0 1 fit-content' },
        },

        threadDataRight: {
            fontSize: '12px',
            textAlign: 'right',
        },

        threadCompressedText: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },

        threadCardBottom: (props) => ({
            width: '100%',
            position: (props.half) ? 'relative' : 'absolute',
            // backgroundImage: 'url(' + props.thread.pic + ')',
            // backgroundPosition: 'bottom',
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: 'cover',
            bottom: '0px',

            '& > div': {
                backgroundColor: (props.half) ? 'transparent' : palette.background.primary + 'bb',
                padding: (props.half ? '0px ' : '8px 12px'),

                // transition: 'backdrop-filter 0.2s'
                backdropFilter: 'blur(0px)',
                // transform: 'translate3d(0, 0, 0)',
                // transition: 'transform 0.5s ease',
                // backgroundColor: '#000c',
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '12px'
            },
        }),

        threadCompressedLeft: {
            fontSize: '0.7em',
        },
        threadCardLastReply: {
            '& > span': {
                color: palette.text.flair
            }
        },
        threadCardButtons: {
            fontSize: '1em', paddingRight: '1em'
        },
        threadButtonsCompressed: { fontSize: '0.7em' },
        threadCardButtonsCompressed: {
            transform: 'scale(0.7)',
            padding: '0px',
        },

        threadCardTitle: {
            fontSize: '20px',
            paddingLeft: '1em'
        },
        threadCardOP: (props) => ({
            fontSize: '12px',
            textAlign: 'right',

            '& > b': {
                color: props?.userColor && props.userColor.startsWith('rgba') ?
                    palette.text.flair :
                    props.userColor
            },
            '& > span': {
                color: palette.text.flair,
            },
        }),

        cardImgContainer: {
            width: '100%',
            '& > img': {
                width: '100%'
            },
        },

        subscriptionCompressed: {
            borderRadius: '0px',
            border: 'none',

            flexDirection: 'column',
        },

        featurefulCard: {
            borderRadius: '0px',
            border: 'none'
        },

        halfCardWidth: {
            width: '100%',
            padding: '0px',
        },

        timelineHalf: {
            width: '100%',
            paddingTop: '0px'
        },
        compressedTimeline: { paddingTop: '0.25em' },
        compressedHeader: { marginBottom: '0px' },

        fixTimelineMsgBody: {
            '& > *': { paddingBottom: '0px' },
        },

        chatMessageHeader: (props) => ({
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '& > *': { flex: '0 1 auto' },
        }),
        chatMsgUsername: { marginRight: '1em' },
        chatMsgDate: {
            marginBottom: '2px',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'right'
        },

        subscription: {
            flexDirection: 'column',
        },
        subscriptionPadding: {
            padding: '1.1rem'
        },
        subscriptionFull: {
            textAlign: 'center',
            padding: '1.5rem 2rem',
            '& > div': {
                paddingLeft: '0.7em',
                paddingRight: '0.7em'
            },
        },
        subscriptionTopRow: {
            borderBottom: '1px solid ' + palette.text.primary,
            paddingBottom: '0.6em',
            backgroundColor: 'transparent',
        },
        subscriptionHalf: {
            padding: '0px',
            margin: '0px',
            border: 'none',
        },
        subscriptionBottomRow: {
            paddingTop: '0.6em',
            marginTop: '0.4rem',
            '& > div': {
                display: 'flex', alignItems: 'center'
            }
        },
        subscriptionRolename: (props) => ({
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            color: props.roleColor
        }),
        subscriptionPriceBtn: {
            display: 'flex',
            flexDirection: 'row',
            padding: '0.2rem 0px',
            '& > div': {
                flex: '0 1 100%',
            },
        },

        community: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        communityTable: {
            color: palette.text.secondary,
            '& > table > thead > tr > th': {
                color: palette.text.secondary
            },
            '& > table > tbody > tr > td': {
                color: palette.text.primary
            },
        },
        communityHalfPadding: { paddingLeft: '1em' },
        communityPadding: {
            paddingLeft: '2em',
            paddingRight: '2em',
        },
        communityText: {
            display: 'flex',
            flexDirection: 'column',
        },
        communityTextCompressed: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
        communityCompressed: { borderRadius: '0px' },

        userFlair: {
            color: palette.text.secondary,
            '& > span > span': { color: palette.text.flair }
        },
    }
}
