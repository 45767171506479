import { styled } from '@mui/material/styles'
import { TextField, ListItem } from '@mui/material'
import { useContext } from 'react'
import { ThemeContext } from '@emotion/react'
import ChatMessage from '../Chat/ChatWindow/ChatMessage'

const ev = (x, props) => (typeof x === 'function' ? x(props) : x)

export const resolver = (props, styles) => {
    let resolutionOrder = [styles.root]
    if (props?.types) {
        props.types.map((x) => {
            props.theme.globals?.[x]
                ? resolutionOrder.push(ev(props.theme.globals[x], props))
                : null
            styles?.[x] ? resolutionOrder.push(ev(styles[x], props)) : null
        })
    }
    return resolutionOrder
}
const mStyled = (Component, options) => (operation) => {
    let modifiedComponent = (props) => {
        const theme = useContext(ThemeContext)
        let defaultProps = theme?.components?.[options?.name]?.defaultProps
        return <Component {...defaultProps} {...props} />
    }
    return styled(modifiedComponent, options)(operation)
}

export const ChatMessageCustom = styled(ChatMessage, {
    name: 'ChatMessageCustom',
    slot: 'Root',
    overridesResolver: resolver,
})((props) => null)
