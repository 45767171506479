import { keyframes } from '@mui/styled-engine'
import { inputLabelClasses } from '@mui/material'
import { components, animations } from './components'
import { globals } from './globals'
import { createTheme } from '@mui/material/styles'
import { hexToFormattedRgb } from './util'
import _ from 'lodash'

const DEBUG_STYLE = false
const debugColor = (x) => 'rgb(' + x * 20 + ',' + x * 20 + ',' + x * 20 + ')'

export const debugMode = () => {
    let mode = {
        mode: 'light',
        debugStyle: true,
        debugColor: debugColor,
        background: {
            secondary: '#aaaaaa',
            primary: '#cccccc',
            opposite: '#000000',
        },
        text: { cardPrimary: '#000' },
        primary: { light: '#000000', main: '#000000' },
        error: { light: '#000000', main: '#000000' },
        success: { light: '#000000', main: '#000000' },
        info: { light: '#000000', main: '#000000' },
    }
    mode.leftBarPadding = '14px'
    return { palette: mode, typography: {} }
}

export const blueModeFontsAndColors = () => {
    let mode = {
        mode: 'light',
        debugColor: debugColor,
        background: {
            paper: '#cccccc',
            secondary: '#E4ECF2', //'#ECF0Fa',
            primary: '#f1f3f1', //'#eeeeee',
            opposite: '#999999',
            scroll: '#777777',
            default: '#ffffff',
            flair: '#1877F2',
        },
        text: {
            primary: 'rgba(0,0,0,0.85)',
            secondary: 'rgba(0,0,0,0.38)',
            cardPrimary: 'rgba(0,0,0,0.85)',
            flair: '#1877F2',
            flairSecondary: '#77A7FF',
        },
        info: { light: '#0c23cf', main: '#000', dark: '#D8E3F6' },
        primary: { light: '#088', main: '#65676B', dark: '#77A7FF' },
        error: { light: '#f83', main: '#1877F2', dark: '#f08' },
        success: { light: '#0a6', main: '#1877F2', dark: '#80f' },
    }
    mode.leftBarPadding = '14px'
    return { palette: mode, typography: {} }
}

export const grayModeFontsAndColors = () => {
    let mode = {
        debugStyle: DEBUG_STYLE,
        mode: 'dark',
        debugColor: debugColor,
        background: {
            paper: '#111214',
            default: '#2B2D31',
            secondary: '#29292B', //  1px solid primary
            primary: '#313338', // 
            opposite: '#ffffff',
            scroll: '#787a80',
            flair: '#383A40',
        }, // #3A3C40
        text: {
            primary: 'rgba(255,255,255,0.85)',
            secondary: 'rgba(255,255,255,0.28)',
            cardPrimary: 'rgba(255,255,255,0.85)',
            flair: '#5865F2',
            flairSecondary: '#3a429c', // #F23F42
        },
        info: { light: '#F23F42', main: '#fff', dark: '#2B2D31' },
        primary: { light: '#FFFFFF', main: '#B5BAC1', dark: '#383A40' },
        error: { light: '#0ff', main: '#B5BAC1', dark: '#b4023d' },
        success: { light: '#80f', main: '#5865F2', dark: '#004596' },
    }
    mode.leftBarPadding = '14px'
    return { palette: mode, typography: {} }
}

export const redModeFontsAndColors = () => {
    let mode = {
        mode: 'light',
        debugColor: debugColor,
        background: {
            paper: '#cccccc',
            secondary: '#f8f8f8',
            primary: '#ffffff',
            opposite: '#999999',
            scroll: '#777777',
            default: '#E9E9E9',
            flair: '#E60023',
            flairSecondary: 'rgba(230, 0, 35, 0.3)',
        },
        text: {
            primary: 'rgba(0,0,0,0.85)',
            secondary: 'rgba(0,0,0,0.38)',
            cardPrimary: 'rgba(0,0,0,0.85)',
            flair: '#111111',
            flairSecondary: '#666666',
        },
        info: { light: '#E60023', main: '#fff', dark: '#cccccc' },
        primary: { light: '#ff0', main: '#5F5F5F', dark: '#111111' },
        error: { light: '#f80', main: '#5F5F5F', dark: '#f08' },
        success: { light: '#8f0', main: '#E60023', dark: '#80f' },
    }
    mode.leftBarPadding = '14px'
    return { palette: mode, typography: {} }
}

export const joyceHighContrast = () => {
    let mode = {
        debugStyle: DEBUG_STYLE,
        // debugColor: (x) => "rgb(" + (x * 20) + "," + (x * 20) + "," + (x * 20) + ")",
        mode: 'dark',
        debugColor: debugColor,
        background: {
            paper: '#111',
            default: '#000',
            secondary: '#000',
            primary: '#000',
            opposite: '#fff',
            scroll: '#0f8', // spring green
        },
        text: {
            primary: '#fff',
            secondary: '#0ff', // cyan
            cardPrimary: '#f08',
            flair: '#f08',
            flairSecondary: '#3f3', // apple ][ green
        },
        thread: { border: true },
        secondary: { main: '#0ff' },
        info: { light: '#fb0', main: '#f08', dark: '#03b' }, // terminal amber; bsod blue
        primary: { light: '#f08', main: '#0ff', dark: '#f08' },
        error: { light: '#f08', main: '#f08', dark: '#f00' },
        success: { light: '#f08', main: '#f08', dark: '#0f0' },
    }
    mode.leftBarPadding = '14px'
    return { palette: mode, typography: {} }
}

export const fontsAndColors = [
    joyceHighContrast,
    blueModeFontsAndColors,
    grayModeFontsAndColors,
    redModeFontsAndColors,
]
let globalTheme = {}
//    debugMode,
export const globalThemeNew = (mode) => {
    let defaultTheme = createTheme({ palette: { mode: mode.palette.mode } })
    let defaultThemePalette = defaultTheme.palette
    let defaultThemeTypography = defaultTheme.typography
    let modifiedThemePalette = _.merge(defaultThemePalette, mode.palette)
    let modifiedThemeTypography = _.merge(
        defaultThemeTypography,
        mode.typography
    )
    let theme = {
        globals: globals(
            mode,
            modifiedThemePalette,
            animations(keyframes),
            modifiedThemeTypography),
        components: components(
            mode,
            modifiedThemePalette,
            animations(keyframes),
            modifiedThemeTypography
        ),
        palette: {
            ...modifiedThemePalette,
        },
        modifiedThemeTypography: { ...modifiedThemeTypography },
    }
    globalTheme = theme
    return theme
}
globalThemeNew(blueModeFontsAndColors())
export { globalTheme }
