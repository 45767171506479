export const AccordionCustomStyle = (mode, palette) => ({
    AccordionCustom: {
        defaultProps: {},
        styleOverrides:{"nothing":{}},altStyleOverrides: {
            root: {},
            sidebarAccordion: {
                marginLeft: '8px',
                marginRight: '8px',
                marginTop: '3px',
                backgroundColor: mode.debugStyle
                    ? mode.debugColor(6)
                    : palette.mode.secondary,
            },
        },
    },
})
