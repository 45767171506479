import { createStore, applyMiddleware } from 'redux'
//import {composeWithDevTools} from 'redux-devtools-extension';
import createSocketIoMiddleware from 'redux-socket.io'
import io from 'socket.io-client'
import { combinedReducer } from './reducers'
import { openBlockedModalMenu } from './reducers/actions'
import { WS_URL } from '../constants/env'

const blockingCases = [
    '_appState/changePage',
    '_activeChannel/set',
    '_activeServer/set',
    'management/setManagementPage',
    'management/setSelectedRole',
]
let TESTMODE_SUPPRESS = false

const singleFunctionStore = {
    current: () => {},
}

const asyncDispatchMiddleware = (store) => (next) => (action) => {
    let syncActivityFinished = false
    let actionQueue = []

    function flushQueue() {
        actionQueue.forEach((a) => {
            store.dispatch(a)
        }) // flush queue
        actionQueue = []
    }

    if (typeof action?.payload === 'object' && action.payload !== null) {
        if (action.payload.hasOwnProperty('storeFn')) {
            singleFunctionStore.current = action.payload.storeFn
        }
    }
    if (typeof action?.payload === 'object' && action.payload !== null) {
        if (action.payload.hasOwnProperty('executeStoreFn')) {
            if (action.payload.executeStoreFn) {
                singleFunctionStore.current()
            } else {
                singleFunctionStore.current = () => {}
            }
        }
    }
    if (!TESTMODE_SUPPRESS) {
        if(action.type.includes('server/')){
            console.log(
                '%c (Redux event:' + action.type + ')',
                'background: #222; color: #aa3333'
            )
        }else{
            console.log(
                '%c (Redux event:' + action.type + ')',
                'background: #222; color: #bada55'
            )
        }
    }
    if (action?.type === 'messages/addNewMessage') {
        let asyncExtra = {}
        const oldStore = store.getState()
        asyncExtra.clientUserId = oldStore._userId
        asyncExtra.userMetaId = oldStore._userId //#TODO: Rename this
        asyncExtra._activeChannel = oldStore._activeChannel
        asyncExtra._appState = oldStore._appState
        // asyncExtra.scrollDefault = oldStore.channelWindows[action.payload.message.cId].scrollDefault;
        action.payload.asyncExtra = asyncExtra
    }
    if (action?.type === 'management/initManagement') {
        let asyncExtra = {}
        const oldStore = store.getState()
        asyncExtra.userMetaId = oldStore._userId + '_' + action.payload.serverId
        asyncExtra.userMeta = oldStore.userMetas[asyncExtra.userMetaId]
        asyncExtra.clientUserId = oldStore._userId
        asyncExtra._userId = oldStore._userId //#TODO: Rename this
        asyncExtra._activeChannel = oldStore._activeChannel
        asyncExtra._activeServer = oldStore._activeServer
        asyncExtra._temp = oldStore._temp
        asyncExtra.abstractRoles = oldStore.abstractRoles
        asyncExtra._permDescriptions = oldStore._permDescriptions
        asyncExtra.serverData = oldStore.chatServers[action.payload.serverId]
        action.payload.asyncExtra = asyncExtra
    }
    if (action?.type === 'client/processUpdate') {
        const oldStore = store.getState()
        let asyncExtra = {
            _activeServer: oldStore._activeServer,
            _activeChannel: oldStore._activeChannel,
            _appState: oldStore._appState,
            userServer: oldStore.chatServers[oldStore._userId],
        }
        if (action?.payload?.serverId) {
            asyncExtra.userMetaId =
                oldStore._userId + '_' + action.payload.serverId
            asyncExtra.userMetaInServer =
                oldStore.userMetas[
                    oldStore._userId + '_' + action.payload.serverId
                ]
            asyncExtra.serverData =
                oldStore.chatServers[action.payload.serverId]
        }
        if (action?.payload?.channelId) {
            asyncExtra.channelData = oldStore.channels[action.payload.channelId]
        }
        action.payload = { ...action.payload }
        action.payload.asyncExtra = asyncExtra
    }

    function asyncDispatch(asyncAction) {
        actionQueue = actionQueue.concat([asyncAction])

        if (syncActivityFinished) {
            flushQueue()
        }
    }

    //Handle blocking operations
    if (blockingCases.includes(action?.type)) {
        const oldStore = store.getState()
        let _appState = oldStore._appState
        if (_appState.pageChangeBlocked) {
            let openModalMenuAction = openBlockedModalMenu({
                modalMenu: _appState.blockedModal,
                followUpAction: JSON.stringify(action),
            })
            const actionWithAsyncDispatch = Object.assign(
                {},
                openModalMenuAction,
                { asyncDispatch }
            )
            next(actionWithAsyncDispatch)
            syncActivityFinished = true
            flushQueue()
        } else {
            const actionWithAsyncDispatch = Object.assign({}, action, {
                asyncDispatch,
            })
            next(actionWithAsyncDispatch)
            syncActivityFinished = true
            flushQueue()
        }
    } else {
        const actionWithAsyncDispatch = Object.assign({}, action, {
            asyncDispatch,
        })
        next(actionWithAsyncDispatch)
        syncActivityFinished = true
        flushQueue()
    }
}

// let socket = io('ws://' + document.location.host.split(":")[0] + ':8080');
let socket = io(WS_URL, {
    // WARNING: in that case, there is no fallback to long-polling
    transports: ['websocket'], // or [ "websocket", "polling" ] (the order matters)
})
let socketIoMiddleware = createSocketIoMiddleware(socket, 'server/')
// const store = createStore(combinedReducer, composeWithDevTools(
//     applyMiddleware(socketIoMiddleware, asyncDispatchMiddleware),
//     // other store enhancers if any
// ));
const store = createStore(
    combinedReducer,
    applyMiddleware(socketIoMiddleware, asyncDispatchMiddleware)
    // other store enhancers if any
)
console.log("STORE CREATED")
console.log(store.getState())


store.subscribe(() => {
    if (!TESTMODE_SUPPRESS) {
        // console.log('%c ' + action.type, 'background: #222; color: #bada55')
        console.log(
            '%c (New client state)',
            'background: #222; color: #98a833',
            store.getState()
        )
    }
})
// http://127.1.1.1/
const createTestStore = () => {
    //let socket = io('ws://' + document.location.host.split(":")[0] + ':8080');
    let socket = io("ws://localhost:8080/")
    let socketIoMiddleware = createSocketIoMiddleware(socket, 'server/')
    TESTMODE_SUPPRESS = true
    // const store = createStore(combinedReducer, composeWithDevTools(
    //     applyMiddleware(socketIoMiddleware, asyncDispatchMiddleware),
    const store = createStore(
        combinedReducer,
        applyMiddleware(socketIoMiddleware, asyncDispatchMiddleware)
    )
    // other store enhancers if any

    store.subscribe(() => {
        // console.log('new client state', store.getState());
    })
    return store
}
export { store, createTestStore }
