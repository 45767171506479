import React, {useEffect} from 'react'
import {statePropMapper} from '../../../redux/process/selectorProcess.js'
import {connect} from 'react-redux'
import {RA1, RS1} from '../../../redux/actions.js'
import {dispatchMappers} from '../../../redux/process/actionProcess'
import {SA1} from '../../../hs/requestGlobals.mjs'
import useState from "react-usestateref";
import {Alert} from "@mui/lab";
import Button from "@mui/material/Button";
import {streams} from "../Stream/stream-controller";
import {withAS} from "../../_contexts/withAppState";
import {fieldExtract} from "../../../hs/util";

const mapDispatchToProps = dispatchMappers([
    RA1._appState.changeToDM,
    RA1._appState.updateAppState,
    SA1.updateNotifications,
    SA1.getProductsByServer,
])
const makeMapStateToProps = statePropMapper([
    RS1.store('notifications', 'notifications'),
    RS1.store('_appState', '_appState'),
    RS1.store('_servers', '_servers'),
    RS1.getServer('serverData'),
    RS1.store('_userId', '_userId'),
    RS1.store('_temp', '_temp'),
])

function removeProp(obj, propToDelete) {
    for (var property in obj) {
        if (typeof obj[property] == 'object' && !Array.isArray(obj[property])) {
            delete obj.property
            let newJsonData = removeProp(obj[property], propToDelete)
            obj[property] = newJsonData
        } else {
            if (propToDelete.includes(property)) {
                delete obj[property]
            }
        }
    }
    return obj
}

const specialLog = (props, other1, other2) => {
    console.log(
        '%c (Notification Update: ' + props + ')',
        'background: #222; color: #CBC3E3',
        other1
    )
}


const handlePriorityNotifications = (nStore, nExec, setNExec, activateN) => {
    const notifKeys = Object.keys(nStore)
    for (const key of notifKeys) {
        if (nStore[key]?.priorityMessages) {
            for (const priority of nStore[key]["priorityMessages"]) {
                const commandStr = priority.mes
                switch (priority.type) {
                    case "creatorPaidAppointmentBooked":
                        if (!nExec.includes(commandStr)) {
                            const currentTime = Date.now()
                            const appointmentTime = parseInt(fieldExtract(commandStr, "time"))
                            if (((appointmentTime - 60 * 1000) < currentTime) && currentTime < (appointmentTime + 3600 * 1000)) {
                                activateN({
                                    type: "creatorPaidAppointmentBooked",
                                    text: "You have an ongoing appointment",
                                    buttonOne: "join",
                                    buttonOneAction: () => streams.startCallWithoutInvite(commandStr.split(":")[2], "appointments")
                                })
                                setNExec(array => [...array, commandStr])
                            }
                        }
                        break;
                    case "clientPaidAppointmentBooked":
                        if (!nExec.includes(commandStr)) {
                            const currentTime = Date.now()
                            const appointmentTime = parseInt(fieldExtract(commandStr, "time"))
                            if (((appointmentTime - 60 * 1000) < currentTime) && currentTime < (appointmentTime + 3600 * 1000)) {
                                activateN({
                                    type: "clientPaidAppointmentBooked",
                                    text: "You have an ongoing appointment",
                                    buttonOne: "join",
                                    buttonOneAction: () => streams.startCallWithoutInvite(commandStr.split(":")[2], "appointments")
                                })
                                setNExec(array => [...array, commandStr])
                            }
                        }
                        break;
                }
            }
        }
    }
}


const handleEventNotifications = (notificationStore) => {
}

const handleExpiredNotifications = (notificationStore) => {
}


const Hidden = (props) => {
    const [notificationsExecuted, setNotificationsExecuted] = useState([])
    const [activeInfoNotification, setActiveInfoNotification] = useState(false)
    const [activeInfoNotificationData, setActiveInfoNotificationData] = useState({})
    const [displayError, setDisplayError] = useState(false)
    const activateNotification = (notificationData) => {
        setActiveInfoNotification(true)
        setActiveInfoNotificationData(notificationData)
    }
    useEffect(() => {
        if (props._servers) {
            props._servers.forEach((serverId) => {
                props.dispatch.server.getProductsByServer(serverId)
            })
        }
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            handlePriorityNotifications(props.notifications.notificationStore, notificationsExecuted, setNotificationsExecuted, activateNotification)
            handleEventNotifications(props.notifications.notificationStore, notificationsExecuted, setNotificationsExecuted, activateNotification)
            handleExpiredNotifications(props.notifications.notificationStore, notificationsExecuted, setNotificationsExecuted, activateNotification)
            let cleanedUpNotificationStore = removeProp(
                JSON.parse(
                    JSON.stringify(props.notifications.notificationStore)
                ),
                ['olderMessages', 'messages', 'priorityMessages']
            )
            console.log(cleanedUpNotificationStore)
            specialLog(
                'sending...',
                props.notifications.notificationStore,
                cleanedUpNotificationStore
            )
            props.dispatch.server.updateNotifications(
                cleanedUpNotificationStore
            )
        }, 60*5000)
        return () => clearInterval(interval)
    }, [props.notifications, notificationsExecuted])
    // useEffect(() => {
    //     if (props._activeServer === props._userId) {
    //         props.dispatch._appState.changeToDM(props._userId, props.serverData.profileId, true)
    //     }
    // }, [props._activeServer])
    const errorMessage = props?._temp?.errs?.[0]?.message ? props?._temp?.errs?.[0]?.message : props?._temp?.errs?.[0]?.err
    return (
        <div style={{position: "absolute", zIndex: 1000000, width: "100vw"}}>{activeInfoNotification ?
            <Alert severity="info"
                   action={
                       <>
                           {activeInfoNotificationData.buttonOne ?
                               <Button color="inherit" size="small"
                                       onClick={activeInfoNotificationData.buttonOneAction}>
                                   {activeInfoNotificationData.buttonOne}
                               </Button> : null}
                           <Button onClick={() => setActiveInfoNotification(false)} color="inherit" size="small">
                               Close
                           </Button>

                       </>
                   }
            >{activeInfoNotificationData.text}
            </Alert> : null}
            {props.AS.nav.state.displayErrorBanner ?
                <Alert severity="error"
                >{errorMessage}
                </Alert> : null}
        </div>
    )
}

export default connect(makeMapStateToProps, mapDispatchToProps)(withAS(Hidden))
