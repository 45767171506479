import { createSlice, createAction } from '@reduxjs/toolkit'
import { updateNotificationsChannel, updateRecentServers } from '../actions'

const monoStates = [
    'activeChannel',
    'activeServer',
    'inServers',
    'lastUpdateType',
    'loaded',
    'permDescriptions',
    'serverIDList',
    'servers',
    'trackedServerList',
    'userId',
]

const activeChannelSlice = createSlice({
    name: '_activeChannel',
    initialState: false,
    reducers: {
        set: (state, action) => {
            if (typeof action.payload=== "undefined") {
                return state
            }
            if (typeof action.payload === 'string') {
                action.asyncDispatch(
                    updateNotificationsChannel({ channelId: action.payload })
                )
                return action.payload
            } else {
                action.asyncDispatch(
                    updateNotificationsChannel({
                        channelId: action.payload.channelId,
                    })
                )
                if (typeof action.payload.channelId === "undefined") {
                    return state
                }
                return action.payload.channelId
            }
        },
    },
})

const activeServerSlice = createSlice({
    name: '_activeServer',
    initialState: false,
    reducers: {
        set: (state, action) => {
            if (typeof action.payload === 'string') {
                action.asyncDispatch(
                    updateRecentServers({ serverId: action.payload })
                )
                return action.payload
            } else {
                action.asyncDispatch(
                    updateRecentServers({ serverId: action.payload.serverId })
                )
                return action.payload.serverId
            }
        },
    },
})

const lastUpdateTypeSlice = createSlice({
    name: '_lastUpdateType',
    initialState: false,
    reducers: {
        set: (state, action) => {
            return action.payload
        },
    },
})

const loadedSlice = createSlice({
    name: '_loaded',
    initialState: false,
    reducers: {
        set: (state, action) => {
            return action.payload
        },
    },
})

const guestIdSlice = createSlice({
    name: '_guestId',
    initialState: false,
    reducers: {
        set: (state, action) => {
            return action.payload
        },
    },
})

const permDescriptionsSlice = createSlice({
    name: '_permDescriptions',
    initialState: false,
    reducers: {
        set: (state, action) => {
            return action.payload
        },
    },
})
const serverIDListSlice = createSlice({
    name: '_serverIDList',
    initialState: false,
    reducers: {
        set: (state, action) => {
            return action.payload
        },
    },
})
const serversSlice = createSlice({
    name: '_servers',
    initialState: false,
    reducers: {
        set: (state, action) => {
            return action.payload
        },
    },
})
const trackedServerListSlice = createSlice({
    name: '_trackedServerList',
    initialState: false,
    reducers: {
        set: (state, action) => {
            return action.payload
        },
    },
})
const userIdSlice = createSlice({
    name: '_userId',
    initialState: false,
    reducers: {
        set: (state, action) => {
            return action.payload
        },
    },
})
const inServersSlice = createSlice({
    name: '_inServers',
    initialState: false,
    reducers: {
        set: (state, action) => {
            return action.payload
        },
    },
})
const tempSlice = createSlice({
    name: '_temp',
    initialState: {},
    reducers: {
        set: (state, action) => {
            return action.payload
        },
    },
})

const allMonoSlices = {
    userId: userIdSlice,
    guestId: guestIdSlice,
    lastUpdateType: lastUpdateTypeSlice,
    activeChannel: activeChannelSlice,
    activeServer: activeServerSlice,
    trackedServerList: trackedServerListSlice,
    permDescriptions: permDescriptionsSlice,
    servers: serversSlice,
    inServers: inServersSlice,
    loaded: loadedSlice,
}

const setServers = serversSlice.actions.set
const setTemp = tempSlice.actions.set
const setActiveServer = activeServerSlice.actions.set
const setActiveChannel = activeChannelSlice.actions.set

const _temp = tempSlice.reducer
const _userId = userIdSlice.reducer
const _lastUpdateType = lastUpdateTypeSlice.reducer
const _activeChannel = activeChannelSlice.reducer
const _guestId = guestIdSlice.reducer
const _activeServer = activeServerSlice.reducer
const _trackedServerList = trackedServerListSlice.reducer
const _permDescriptions = permDescriptionsSlice.reducer
const _loaded = loadedSlice.reducer
const _serverIDList = serverIDListSlice.reducer
const _servers = serversSlice.reducer
const _inServers = inServersSlice.reducer

export {
    setTemp,
    setActiveServer,
    setActiveChannel,
    allMonoSlices,
    setServers,
    _temp,
    _userId,
    _loaded,
    _activeChannel,
    _servers,
    _guestId,
    _serverIDList,
    _lastUpdateType,
    _activeServer,
    _permDescriptions,
    _inServers,
    _trackedServerList,
    userIdSlice,
    lastUpdateTypeSlice,
    activeChannelSlice,
    activeServerSlice,
    trackedServerListSlice,
    guestIdSlice,
    permDescriptionsSlice,
    loadedSlice,
    serverIDListSlice,
    serversSlice,
    inServersSlice,
}
export const monoActions = [
    ['setTemp', setTemp],
    ['setActiveServer', setActiveServer],
    ['setServers', setServers],
    ['setActiveChannel', setActiveChannel],
]
