export const testData = () => {

    let appointmentList = [
        {
            "dates": [
                {
                    "id": "03:45 AM",
                    "appointment": {
                        "_id": "597772374034701313",
                        "appointmentCompleted": false,
                        "approved": false,
                        "booked": false,
                        "restrictToRoles": [],
                        "enabled": true,
                        "creationId": "597772374034701312",
                        "userId": "591616193875768320",
                        "serverId": "591616397794440192",
                        "productId": "",
                        "initialDate": "2023-07-10T07:45:00.000Z",
                        "description": "ueoaia",
                        "length": 2700000,
                        "isProduct": false,
                        "requiresApproval": false,
                        "recurring": "",
                        "privateAppointment": false,
                        "version": 0,
                        "gptTag": "",
                        "createdAt": "2023-07-08T12:54:00.043Z",
                        "__v": 0,
                        "id": "597772374034701313",
                        "startTimeFormatted": "03:45 AM",
                        "endTimeFormatted": "04:30 AM"
                    },
                    "status": "default",
                    "date": "2023-07-10T07:45:00.000Z",
                    "weekday": "Monday",
                    "month": " Jul 10",
                    "calendarDate": "07/10/2023"
                },
                {
                    "id": "05:44 AM",
                    "appointment": {
                        "_id": "597732214492453889",
                        "appointmentCompleted": false,
                        "approved": false,
                        "booked": false,
                        "restrictToRoles": [],
                        "enabled": true,
                        "creationId": "597732214492453888",
                        "userId": "591616193875768320",
                        "serverId": "591616397794440192",
                        "productId": "592437850657836032",
                        "initialDate": "2023-07-10T09:44:00.000Z",
                        "description": "ueao",
                        "length": 2700000,
                        "isProduct": true,
                        "requiresApproval": false,
                        "recurring": "",
                        "privateAppointment": false,
                        "version": 0,
                        "gptTag": "",
                        "createdAt": "2023-07-08T10:14:25.263Z",
                        "__v": 0,
                        "id": "597732214492453889",
                        "startTimeFormatted": "05:44 AM",
                        "endTimeFormatted": "06:29 AM"
                    },
                    "status": "default",
                    "date": "2023-07-10T09:44:00.000Z",
                    "weekday": "Monday",
                    "month": " Jul 10",
                    "calendarDate": "07/10/2023"
                },
                {
                    "id": "04:40 PM",
                    "appointment": {
                        "_id": "597772449473453057",
                        "appointmentCompleted": false,
                        "approved": false,
                        "booked": false,
                        "restrictToRoles": [],
                        "enabled": true,
                        "creationId": "597772449473453056",
                        "userId": "591616193875768320",
                        "serverId": "591616397794440192",
                        "productId": "",
                        "initialDate": "2023-07-10T20:40:00.000Z",
                        "description": "htnshnt",
                        "length": 5400000,
                        "isProduct": false,
                        "requiresApproval": false,
                        "recurring": "",
                        "privateAppointment": false,
                        "version": 0,
                        "gptTag": "",
                        "createdAt": "2023-07-08T12:54:18.029Z",
                        "__v": 0,
                        "id": "597772449473453057",
                        "startTimeFormatted": "04:40 PM",
                        "endTimeFormatted": "06:10 PM"
                    },
                    "status": "default",
                    "date": "2023-07-10T20:40:00.000Z",
                    "weekday": "Monday",
                    "month": " Jul 10",
                    "calendarDate": "07/10/2023"
                },
                {
                    "id": "08:21 PM",
                    "appointment": {
                        "_id": "597772524568271873",
                        "appointmentCompleted": false,
                        "approved": false,
                        "booked": false,
                        "restrictToRoles": [],
                        "enabled": true,
                        "creationId": "597772524568271872",
                        "userId": "591616193875768320",
                        "serverId": "591616397794440192",
                        "productId": "",
                        "initialDate": "2023-07-11T00:21:00.000Z",
                        "description": "ueaoi",
                        "length": 1500000,
                        "isProduct": false,
                        "requiresApproval": false,
                        "recurring": "",
                        "privateAppointment": false,
                        "version": 0,
                        "gptTag": "",
                        "createdAt": "2023-07-08T12:54:35.933Z",
                        "__v": 0,
                        "id": "597772524568271873",
                        "startTimeFormatted": "08:21 PM",
                        "endTimeFormatted": "08:46 PM"
                    },
                    "status": "default",
                    "date": "2023-07-11T00:21:00.000Z",
                    "weekday": "Monday",
                    "month": " Jul 10",
                    "calendarDate": "07/10/2023"
                }
            ],
            "uniqueDayLabel": "2023191",
            "day": "Monday Jul 10 (07/10/2023)",
            "weekday": "Monday",
            "month": " Jul 10",
            "calendarDate": "07/10/2023"
        },
        {
            "dates": [
                {
                    "id": "05:00 PM",
                    "appointment": {
                        "_id": "591661311324419073",
                        "appointmentCompleted": false,
                        "approved": false,
                        "booked": false,
                        "restrictToRoles": [],
                        "enabled": true,
                        "creationId": "591661311324419072",
                        "userId": "591616193875768320",
                        "serverId": "591616397794440192",
                        "productId": "",
                        "initialDate": "2023-06-27T21:00:00.000Z",
                        "description": "Design Discussion",
                        "length": 1800000,
                        "isProduct": false,
                        "requiresApproval": false,
                        "recurring": "",
                        "privateAppointment": false,
                        "version": 0,
                        "gptTag": "design_discussion_tuesday",
                        "createdAt": "2023-06-21T16:10:49.155Z",
                        "__v": 0,
                        "id": "591661311324419073",
                        "startTimeFormatted": "05:00 PM",
                        "endTimeFormatted": "05:30 PM"
                    },
                    "status": "default",
                    "date": "2023-06-27T21:00:00.000Z",
                    "weekday": "Tuesday",
                    "month": " Jun 27",
                    "calendarDate": "06/27/2023"
                }
            ],
            "uniqueDayLabel": "2023178",
            "day": "Tuesday Jun 27 (06/27/2023)",
            "weekday": "Tuesday",
            "month": " Jun 27",
            "calendarDate": "06/27/2023"
        },
        {
            "dates": [
                {
                    "id": "05:00 PM",
                    "appointment": {
                        "_id": "591661319855633409",
                        "appointmentCompleted": false,
                        "approved": false,
                        "booked": false,
                        "restrictToRoles": [],
                        "enabled": true,
                        "creationId": "591661319855633408",
                        "userId": "591616193875768320",
                        "serverId": "591616397794440192",
                        "productId": "",
                        "initialDate": "2023-06-28T21:00:00.000Z",
                        "description": "Design Discussion",
                        "length": 1800000,
                        "isProduct": false,
                        "requiresApproval": false,
                        "recurring": "",
                        "privateAppointment": false,
                        "version": 0,
                        "gptTag": "design_discussion_wednesday",
                        "createdAt": "2023-06-21T16:10:51.188Z",
                        "__v": 0,
                        "id": "591661319855633409",
                        "startTimeFormatted": "05:00 PM",
                        "endTimeFormatted": "05:30 PM"
                    },
                    "status": "default",
                    "date": "2023-06-28T21:00:00.000Z",
                    "weekday": "Wednesday",
                    "month": " Jun 28",
                    "calendarDate": "06/28/2023"
                }
            ],
            "uniqueDayLabel": "2023179",
            "day": "Wednesday Jun 28 (06/28/2023)",
            "weekday": "Wednesday",
            "month": " Jun 28",
            "calendarDate": "06/28/2023"
        },
        {
            "dates": [
                {
                    "id": "05:00 PM",
                    "appointment": {
                        "_id": "591661323341100033",
                        "appointmentCompleted": false,
                        "approved": false,
                        "booked": false,
                        "restrictToRoles": [],
                        "enabled": true,
                        "creationId": "591661323341100032",
                        "userId": "591616193875768320",
                        "serverId": "591616397794440192",
                        "productId": "",
                        "initialDate": "2023-06-29T21:00:00.000Z",
                        "description": "Design Discussion",
                        "length": 1800000,
                        "isProduct": false,
                        "requiresApproval": false,
                        "recurring": "",
                        "privateAppointment": false,
                        "version": 0,
                        "gptTag": "design_discussion_thursday",
                        "createdAt": "2023-06-21T16:10:52.020Z",
                        "__v": 0,
                        "id": "591661323341100033",
                        "startTimeFormatted": "05:00 PM",
                        "endTimeFormatted": "05:30 PM"
                    },
                    "status": "default",
                    "date": "2023-06-29T21:00:00.000Z",
                    "weekday": "Thursday",
                    "month": " Jun 29",
                    "calendarDate": "06/29/2023"
                }
            ],
            "uniqueDayLabel": "2023180",
            "day": "Thursday Jun 29 (06/29/2023)",
            "weekday": "Thursday",
            "month": " Jun 29",
            "calendarDate": "06/29/2023"
        },
        {
            "dates": [
                {
                    "id": "05:00 PM",
                    "appointment": {
                        "_id": "591661327577347073",
                        "appointmentCompleted": false,
                        "approved": false,
                        "booked": false,
                        "restrictToRoles": [],
                        "enabled": true,
                        "creationId": "591661327577347072",
                        "userId": "591616193875768320",
                        "serverId": "591616397794440192",
                        "productId": "",
                        "initialDate": "2023-06-30T21:00:00.000Z",
                        "description": "Design Discussion",
                        "length": 1800000,
                        "isProduct": false,
                        "requiresApproval": false,
                        "recurring": "",
                        "privateAppointment": false,
                        "version": 0,
                        "gptTag": "design_discussion_friday",
                        "createdAt": "2023-06-21T16:10:53.030Z",
                        "__v": 0,
                        "id": "591661327577347073",
                        "startTimeFormatted": "05:00 PM",
                        "endTimeFormatted": "05:30 PM"
                    },
                    "status": "default",
                    "date": "2023-06-30T21:00:00.000Z",
                    "weekday": "Friday",
                    "month": " Jun 30",
                    "calendarDate": "06/30/2023"
                }
            ],
            "uniqueDayLabel": "2023181",
            "day": "Friday Jun 30 (06/30/2023)",
            "weekday": "Friday",
            "month": " Jun 30",
            "calendarDate": "06/30/2023"
        },
    ]

    let PROPS = {
        "serverId": "591616397794440192",
        "isMember": true,
        "isGuest": false,
        "isOwner": true,
        "calendar": {
            "requiresReload": false,
            "loaded": true,
            "appointments": [
                {
                    "_id": "591661311324419073",
                    "appointmentCompleted": false,
                    "approved": false,
                    "booked": false,
                    "restrictToRoles": [],
                    "enabled": true,
                    "creationId": "591661311324419072",
                    "userId": "591616193875768320",
                    "serverId": "591616397794440192",
                    "productId": "",
                    "initialDate": "2023-06-27T21:00:00.000Z",
                    "description": "Design Discussion",
                    "length": 1800000,
                    "isProduct": false,
                    "requiresApproval": false,
                    "recurring": "",
                    "privateAppointment": false,
                    "version": 0,
                    "gptTag": "design_discussion",
                    "createdAt": "2023-06-21T16:10:49.155Z",
                    "__v": 0,
                    "id": "591661311324419073"
                },
                {
                    "_id": "591661319855633409",
                    "appointmentCompleted": false,
                    "approved": false,
                    "booked": false,
                    "restrictToRoles": [],
                    "enabled": true,
                    "creationId": "591661319855633408",
                    "userId": "591616193875768320",
                    "serverId": "591616397794440192",
                    "productId": "",
                    "initialDate": "2023-06-28T21:00:00.000Z",
                    "description": "Design Discussion",
                    "length": 1800000,
                    "isProduct": false,
                    "requiresApproval": false,
                    "recurring": "",
                    "privateAppointment": false,
                    "version": 0,
                    "gptTag": "design_discussion",
                    "createdAt": "2023-06-21T16:10:51.188Z",
                    "__v": 0,
                    "id": "591661319855633409"
                },
                {
                    "_id": "591661323341100033",
                    "appointmentCompleted": false,
                    "approved": false,
                    "booked": false,
                    "restrictToRoles": [],
                    "enabled": true,
                    "creationId": "591661323341100032",
                    "userId": "591616193875768320",
                    "serverId": "591616397794440192",
                    "productId": "",
                    "initialDate": "2023-06-29T21:00:00.000Z",
                    "description": "Design Discussion",
                    "length": 1800000,
                    "isProduct": false,
                    "requiresApproval": false,
                    "recurring": "",
                    "privateAppointment": false,
                    "version": 0,
                    "gptTag": "design_discussion",
                    "createdAt": "2023-06-21T16:10:52.020Z",
                    "__v": 0,
                    "id": "591661323341100033"
                },
                {
                    "_id": "591661327577347073",
                    "appointmentCompleted": false,
                    "approved": false,
                    "booked": false,
                    "restrictToRoles": [],
                    "enabled": true,
                    "creationId": "591661327577347072",
                    "userId": "591616193875768320",
                    "serverId": "591616397794440192",
                    "productId": "",
                    "initialDate": "2023-06-30T21:00:00.000Z",
                    "description": "Design Discussion",
                    "length": 1800000,
                    "isProduct": false,
                    "requiresApproval": false,
                    "recurring": "",
                    "privateAppointment": false,
                    "version": 0,
                    "gptTag": "design_discussion",
                    "createdAt": "2023-06-21T16:10:53.030Z",
                    "__v": 0,
                    "id": "591661327577347073"
                }
            ],
            "appointmentsByDay": {
                "2023178": [
                    1687899600000
                ],
                "2023179": [
                    1687986000000
                ],
                "2023180": [
                    1688072400000
                ],
                "2023181": [
                    1688158800000
                ]
            },
            "appointmentsByTitle": {
                "products": {},
                "gpttags": {
                    "none": [],
                    "design_discussion": [
                        {
                            "_id": "591661311324419073",
                            "appointmentCompleted": false,
                            "approved": false,
                            "booked": false,
                            "restrictToRoles": [],
                            "enabled": true,
                            "creationId": "591661311324419072",
                            "userId": "591616193875768320",
                            "serverId": "591616397794440192",
                            "productId": "",
                            "initialDate": "2023-06-27T21:00:00.000Z",
                            "description": "Design Discussion",
                            "length": 1800000,
                            "isProduct": false,
                            "requiresApproval": false,
                            "recurring": "",
                            "privateAppointment": false,
                            "version": 0,
                            "gptTag": "design_discussion_tuesday",
                            "createdAt": "2023-06-21T16:10:49.155Z",
                            "__v": 0,
                            "id": "591661311324419073"
                        },
                        {
                            "_id": "591661319855633409",
                            "appointmentCompleted": false,
                            "approved": false,
                            "booked": false,
                            "restrictToRoles": [],
                            "enabled": true,
                            "creationId": "591661319855633408",
                            "userId": "591616193875768320",
                            "serverId": "591616397794440192",
                            "productId": "",
                            "initialDate": "2023-06-28T21:00:00.000Z",
                            "description": "Design Discussion",
                            "length": 1800000,
                            "isProduct": false,
                            "requiresApproval": false,
                            "recurring": "",
                            "privateAppointment": false,
                            "version": 0,
                            "gptTag": "design_discussion_wednesday",
                            "createdAt": "2023-06-21T16:10:51.188Z",
                            "__v": 0,
                            "id": "591661319855633409"
                        },
                        {
                            "_id": "591661323341100033",
                            "appointmentCompleted": false,
                            "approved": false,
                            "booked": false,
                            "restrictToRoles": [],
                            "enabled": true,
                            "creationId": "591661323341100032",
                            "userId": "591616193875768320",
                            "serverId": "591616397794440192",
                            "productId": "",
                            "initialDate": "2023-06-29T21:00:00.000Z",
                            "description": "Design Discussion",
                            "length": 1800000,
                            "isProduct": false,
                            "requiresApproval": false,
                            "recurring": "",
                            "privateAppointment": false,
                            "version": 0,
                            "gptTag": "design_discussion_thursday",
                            "createdAt": "2023-06-21T16:10:52.020Z",
                            "__v": 0,
                            "id": "591661323341100033"
                        },
                        {
                            "_id": "591661327577347073",
                            "appointmentCompleted": false,
                            "approved": false,
                            "booked": false,
                            "restrictToRoles": [],
                            "enabled": true,
                            "creationId": "591661327577347072",
                            "userId": "591616193875768320",
                            "serverId": "591616397794440192",
                            "productId": "",
                            "initialDate": "2023-06-30T21:00:00.000Z",
                            "description": "Design Discussion",
                            "length": 1800000,
                            "isProduct": false,
                            "requiresApproval": false,
                            "recurring": "",
                            "privateAppointment": false,
                            "version": 0,
                            "gptTag": "design_discussion_friday",
                            "createdAt": "2023-06-21T16:10:53.030Z",
                            "__v": 0,
                            "id": "591661327577347073"
                        }
                    ]
                }
            },
            "appointmentsByInitTimestamp": {
                "1687899600000": {
                    "_id": "591661311324419073",
                    "appointmentCompleted": false,
                    "approved": false,
                    "booked": false,
                    "restrictToRoles": [],
                    "enabled": true,
                    "creationId": "591661311324419072",
                    "userId": "591616193875768320",
                    "serverId": "591616397794440192",
                    "productId": "",
                    "initialDate": "2023-06-27T21:00:00.000Z",
                    "description": "Design Discussion",
                    "length": 1800000,
                    "isProduct": false,
                    "requiresApproval": false,
                    "recurring": "",
                    "privateAppointment": false,
                    "version": 0,
                    "gptTag": "design_discussion_tuesday",
                    "createdAt": "2023-06-21T16:10:49.155Z",
                    "__v": 0,
                    "id": "591661311324419073"
                },
                "1687986000000": {
                    "_id": "591661319855633409",
                    "appointmentCompleted": false,
                    "approved": false,
                    "booked": false,
                    "restrictToRoles": [],
                    "enabled": true,
                    "creationId": "591661319855633408",
                    "userId": "591616193875768320",
                    "serverId": "591616397794440192",
                    "productId": "",
                    "initialDate": "2023-06-28T21:00:00.000Z",
                    "description": "Design Discussion",
                    "length": 1800000,
                    "isProduct": false,
                    "requiresApproval": false,
                    "recurring": "",
                    "privateAppointment": false,
                    "version": 0,
                    "gptTag": "design_discussion_wednesday",
                    "createdAt": "2023-06-21T16:10:51.188Z",
                    "__v": 0,
                    "id": "591661319855633409"
                },
                "1688072400000": {
                    "_id": "591661323341100033",
                    "appointmentCompleted": false,
                    "approved": false,
                    "booked": false,
                    "restrictToRoles": [],
                    "enabled": true,
                    "creationId": "591661323341100032",
                    "userId": "591616193875768320",
                    "serverId": "591616397794440192",
                    "productId": "",
                    "initialDate": "2023-06-29T21:00:00.000Z",
                    "description": "Design Discussion",
                    "length": 1800000,
                    "isProduct": false,
                    "requiresApproval": false,
                    "recurring": "",
                    "privateAppointment": false,
                    "version": 0,
                    "gptTag": "design_discussion_thursday",
                    "createdAt": "2023-06-21T16:10:52.020Z",
                    "__v": 0,
                    "id": "591661323341100033"
                },
                "1688158800000": {
                    "_id": "591661327577347073",
                    "appointmentCompleted": false,
                    "approved": false,
                    "booked": false,
                    "restrictToRoles": [],
                    "enabled": true,
                    "creationId": "591661327577347072",
                    "userId": "591616193875768320",
                    "serverId": "591616397794440192",
                    "productId": "",
                    "initialDate": "2023-06-30T21:00:00.000Z",
                    "description": "Design Discussion",
                    "length": 1800000,
                    "isProduct": false,
                    "requiresApproval": false,
                    "recurring": "",
                    "privateAppointment": false,
                    "version": 0,
                    "gptTag": "design_discussion_friday",
                    "createdAt": "2023-06-21T16:10:53.030Z",
                    "__v": 0,
                    "id": "591661327577347073"
                }
            }
        },
        "_userId": "591616193875768320",
        "dispatch": {
            "_appState": {},
            "calendar": {},
            "server": {}
        }
    }

    let serverProducts = [
        {
            "id": "592437850657836032",
            "name": "Test Appt.",
            "desc": "This is a test appt.",
            "icon": "aa",
            "type": "calendar",
            "price": "1519"
        }
    ]

    let sortByTitle = false
    let productId = null
    let gptTag = 'design_discussion'
    let uniqueDayLabel = null
    let dates = appointmentList[0].dates
    let day = "idk"

    let data = {
        title: '',
        appointments: [],

        adminViewMode: true,

        openModalMenu: () => { },

        setHoverAppointment: () => { },
        setSelectedAppointment: () => { },
        handleApptSelection: () => { },
    }

    if (sortByTitle) {
        if (productId) {

            data.appointments = PROPS.calendar.appointmentsByTitle.products[productId]
            let product = serverProducts.find(product => product.id === productId)
            data.title = product.name

        } else if (gptTag) {
            data.appointments = PROPS.calendar.appointmentsByTitle.gpttags[gptTag]
            data.title = data.appointments[0].description
        } else {
            data.appointments = PROPS.calendar.appointmentsByTitle.gpttags.none
            data.title = "Other"
        }
    }
    else {
        data.appointments = []
        dates.forEach(date => data.appointments.push({ name: date.appointment.description, ...date.appointment }))
        data.title = day
        // data = {
        //     title: props.day, // need to get title from date
        //     appointments: props.calendar.appointmentsByDay[props.uniqueDayLabel]
        // }
    }

    data.appt = PROPS.calendar.appointments[0]

    return { ...data }

}