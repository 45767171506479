import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RA1, RS1 } from '../../../../redux/actions.js'
import { SA1 } from '../../../../hs/requestGlobals.mjs'
import { dispatchMappers } from '../../../../redux/process/actionProcess.js'
import SocketForm from '../../../_reusable/SocketForm/SocketForm.js'
import { statePropMapper } from '../../../../redux/process/selectorProcess.js'

const makeMapStateToProps = statePropMapper([
])

const mapDispatchToProps = dispatchMappers([RA1.api.updateAPI, RA1._appState.closeModalMenu, SA1.deleteSubscription])

const CancelSubscriptionModal = function (props) {
    return (
        <SocketForm
            formMethod={'deleteSubscription'}
            formDispatch={() => {
                props.dispatch.server.deleteSubscription(
                    props.AS.modals.state.modalData.subscriptionId,
                    'deleteSubscription',
                    'deleteSubscription')
            }}
            socketForm
            onClose={props.closeModalMenu}
            onSuccess={() => null}
            description={''}
            formFields={[]}
            formButtons={[
                {
                    label: 'Cancel Subscription',
                    onClick: () => {
                    },
                    icon: 'none',
                    isSubmitButton: true,
                    isCloseButton: false,
                },
                ,
                {
                    label: 'Exit',
                    onClick: () => {
                    },
                    icon: 'none',
                    isSubmitButton: false,
                    isCloseButton: true,
                },
            ]}
            formTypography={{ label: 'Cancel Subscription' }}
            successMessage={'Subscription cancelled.'}
        />
    )
}

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(CancelSubscriptionModal)
