export const PaperCustom = (mode, palette) => ({
    PaperCustom: {
        defaultProps: {},
        styleOverrides:{"nothing":{}},altStyleOverrides: {
            // Name of the slot
            root: {},
            sidebarPaper: {
                backgroundColor: palette.background.default,
                zIndex: 1300,
            },
            microblogPart: {
                flex: '0 0 20%',
                width: '20%',
                minWidth: '350px',
                zIndex: 1300,
                // maxWidth: '20%',
                // minWidth: '20%',
            },

            timelineBackButton: {
                zIndex: 1300,
                position: 'fixed',
                left: '1vw',
                top: '1.5em',
                backgroundColor: palette.background.primary,
                borderRadius: '100%'
            },

            generalCard: {
                backgroundImage: 'none',
                backgroundColor: palette.background.default,
            },
            fullCard: {
                border:
                    palette?.thread?.border &&
                    `2px solid ${palette.text.flair}`,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',

                padding: '1em',
                borderRadius: "60px 0% 60px 0%",
            },
            apptList: {
                margin: '1.5em',
                width: 'calc(100% - 3em)',
                // backgroundColor: palette.background.primary,
            },
            subscription: {
                // backgroundColor: palette.background.primary,
                flexDirection: 'column',
                margin: '1.5em',
                width: 'calc(100% - 3em)'
            },
            subscriptionPadding: {
                padding: '1.1rem 2rem'
            },
            subscriptionFull: {
                textAlign: 'center',
                padding: '1.5rem 2rem',
                '& > div': {
                    paddingLeft: '0.7em',
                    paddingRight: '0.7em'
                },
            },
            subscriptionCompressed: {
                borderRadius: '0px',
                border: 'none',

                flexDirection: 'column',
            },
            tighterCurves: { borderRadius: "36px 0px 36px 0px" },
        },
    },
})
