import React, {useState} from 'react'
import {RA1} from '../../../../redux/actions.js'
import {connect} from 'react-redux'
import {statePropMappers} from '../../../../redux/process/selectorProcess.js'
import {dispatchMappers} from '../../../../redux/process/actionProcess.js'
import withMaterialDnD from '../../../_wrappers/withMaterialDnD/index.js'
import withMenu from '../../../_wrappers/withMenu/index.js'
import FriendLink from './FriendLink/index.js'
import FriendLinkMenu from './FriendLink/FriendLinkMenu/index.js'
import {StyledDiv, TypographyCustom} from '../../Styled'
import {Tab, Tabs} from "@mui/material";

const mapDispatchToProps = dispatchMappers([
    RA1._appState.closeModalMenu,
    RA1._appState.changePage,
    RA1._appState.openModalMenu,
])
const makeMapStateToProps = statePropMappers(
    {
        //getChannels: ["channels", []],
        getChannels: ['channels', []],
        getServer: ['serverData', []],
    },
    {
        _userId: '_userId',
        _loaded: '_loaded',
    }
)
const DnDFriendlList = withMenu(withMaterialDnD(FriendLink), FriendLinkMenu)

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const FriendsNav = function (props) {
    const [tabValue, setTabValue] = useState(0)
    const [value, setValue] = useState(0)
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    let normalChannelIds = [
        props.serverData.logId,
        props.serverData.notificationsId,
        props.serverData.configId,
        props.serverData.generalId,
        props.serverData.hiddenId,
    ]
    let dataList = []
    let dataTypes = ['friends', 'requests', 'pending', 'blocked']
    if (props._loaded) {
        let channels = props.channels.filter(
            (e) => !normalChannelIds.includes(e)
        )
        channels.map((id, index) =>
            dataTypes.map((type) => {
                dataList.push({
                    key: id,
                    entryType: type,
                    channel: id,
                    currentMode: dataTypes[tabValue],
                    channelType: 'dm',
                    index: index,
                    server: props.server,
                })
            })
        )
    }

    return (<StyledDiv
        types={['sidebarTopInternalList']}
        className={'sidebarTopInternalList'}>
        <Tabs value={tabValue} onChange={(event, newValue) => setTabValue(newValue)}
              aria-label="community features">
            <Tab label="Friends"
                 sx={{textTransform: "none"}} {...a11yProps(0)} />
            <Tab label="Requests"
                 sx={{textTransform: "none"}} {...a11yProps(1)} />
            <Tab label="Pending"
                 sx={{textTransform: "none"}} {...a11yProps(2)} />
            <Tab label="Blocked"
                 sx={{textTransform: "none"}} {...a11yProps(3)} />
        </Tabs>
        <StyledDiv types={['sidebarTopInternalScroller']}>
            <DnDFriendlList
                list={dataList}
                dynamicProps={{selectItem: () => true}}
                droppableId={'friendList'}
                menuId={'friendListMenu'}
                useDnD={false}
                dragCondition={(listProps, index) => false}
                handleOnDragEnd={(result) => null}
            />
        </StyledDiv>
    </StyledDiv>)
}

export default connect(makeMapStateToProps, mapDispatchToProps)(FriendsNav)
